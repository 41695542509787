import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import PageNotFound from "../components/PageNotFound";
class PageNotFoundView extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Faqja nuk u gjet</title>
        </Helmet>
        <div style={{ width: 100 + "%" }}>
          <PageNotFound />
        </div>
      </Fragment>
    );
  }
}

export default PageNotFoundView;
