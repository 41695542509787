import React, { Component, Fragment } from "react";
import Request from "../../../api/Request";
import Url from "../../../api/Url";
// import "../../assets/css/frontend.css";

class UserData extends Component {
  constructor() {
    super();
    this.state = {
      role: "",
      firstName: "",
      lastName: "",
    };
  }
  componentDidMount() {
    Request.postRequest(Url.profileData).then((response) => {
      if (response.status === 200) {
        this.setState({
          role: response.data.role,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
        });
      }
    });
  }
  render() {
    var role = this.state.role;
    var first_name = this.state.firstName;
    var last_name = this.state.lastName;
    return (
      <Fragment>
        <div className="col-lg-2 aligment-profile">
          <div className="initials">
            <span>{first_name.charAt(0) + last_name.charAt(0)}</span>
          </div>
          <div className="member-details-profile">
            <h2 className="full-name">{first_name + " " + last_name}</h2>
            <h5 className="role">{role} </h5>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserData;
