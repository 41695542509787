import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import ResetPassword from "../../components/auth/ResetPassword";
import Web from "../../routes/Web";
import AppConfig from "../../services/AppConfig";
import HomepageView from "../opac/HomepageView";
import Auth from "./Auth";

class LoginView extends Component {
	render() {
		var isAuth = AppConfig.isLogged();
		if (isAuth === false) {
			return (
				<Fragment>
					<Helmet>
						<title>Ndrysho Fjalëkalimin</title>
					</Helmet>
					<div
						className="d-flex flex-wrap login-view"
						style={{ width: 100 + "%" }}
					>
						<Auth />
						<div className="col-md-6 right-auth-page">
							<ResetPassword />
						</div>
					</div>
				</Fragment>
			);
		} else {
			this.props.history.push(Web.homepage);
			return <HomepageView />;
		}
	}
}

export default withRouter(LoginView);
