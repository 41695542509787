import React, { Component, Fragment } from "react";
import Request from "../../../api/Request";
import Url from "../../../api/Url";
import AppConfig from "../../../services/AppConfig";
import loadingGif from "../../../assets/images/icons/loading.gif";
import Libra from "../../../assets/images/covers/Libra.svg";
import "../../../assets/css/opac_modules.css";
import { Link, withRouter } from "react-router-dom";
import Web from "../../../routes/Web";
import Messages from "../../../helpers/Messages";
class DigitizedBooks extends Component {
  constructor() {
    super();
    this.state = {
      dataDigitizedBooks: {
        digitizedBooks: [],
        loading: true,
      },
    };
  }

  componentDidMount() {
    this.digitizedBooks();
  }

  digitizedBooks = () => {
    let request = Url.digitizedBooks + 0;
    Request.getRequest(request).then((response) => {
      if (response) {
        if (response.status === 200) {
          this.setState({
            dataDigitizedBooks: {
              digitizedBooks: response.data.digitized_books,
              loading: false,
            },
          });
        }
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };
  render() {
    var digitizedBooks = "";
    if (this.state.dataDigitizedBooks.loading === true) {
      digitizedBooks = (
        <img className="m-auto d-block mb-5" src={loadingGif} alt="loading" />
      );
    } else {
      digitizedBooks = this.state.dataDigitizedBooks.digitizedBooks.map(
        (book, index) => {
          const Authors = book.biblio.authors.map((author) => {
            return author.name;
          });
          var cover = null;
          if (book.cover !== null) {
            cover = AppConfig.biblioCoversPath + book.cover;
          } else {
            cover = Libra;
          }
          return (
            <Fragment key={index}>
              <Link
                to={{
                  pathname: Web.detailed + book.id,
                  state: { prevUrl: this.props.history.location.pathname },
                }}
                className="book"
              >
                <img
                  className="digitized_img"
                  src={cover}
                  alt={book.biblio.title}
                />
                <div className="details">
                  <h3>{book.biblio.title}</h3>
                  <h2>{Authors.join("; ")}</h2>
                </div>
              </Link>
            </Fragment>
          );
        }
      );
    }

    return (
      <Fragment>
        {this.state.dataDigitizedBooks.digitizedBooks.length !== 0 && (
          <div className="row mx-0 digitized_books_module">
            <h1>Libra të digjituar</h1>
            <div className="digitized_books">{digitizedBooks}</div>
            {this.state.dataDigitizedBooks.digitizedBooks.length === 8 && (
              <Link
                to={Web.digitized_books}
                className="btn-front btn-news-r mt-4 mx-auto"
              >
                Shiko më shumë
              </Link>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

export default withRouter(DigitizedBooks);
