import React, { Component, Fragment } from "react";
import { useHistory } from "react-router-dom";
import Url from "../../../api/Url";
import Web from "../../../routes/Web";
import { withRouter } from "react-router-dom";
import Request from "../../../api/Request";
import Messages from "../../../helpers/Messages";
import AppConfig from "../../../services/AppConfig";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import LoadingGif from "../../../assets/images/icons/loading.gif";
class EditStaff extends Component {
  constructor() {
    super();
    this.state = {
      edit: {
        role_id: "",
        role_name: "",
        email: "",
        first_name: "",
        last_name: "",
        dob: "",
        gender: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        postal_code: "",
        membership_start: "",
        membership_end: "",
      },
      roles: {
        list: [],
      },
      form_errors: {
        error_list: [],
      },
      membership_end: "",
      selectedDob: {
        dob: "",
      },
      loading: true,
      btnLoading: false,
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    Request.getRequest(Url.userEdit + id).then((response) => {
      if (response.status === 200) {
        var dob = null;
        if (response.data.user.dob !== null) {
          dob = new Date(response.data.user.dob);
        }
        this.setState({
          edit: {
            role_id: response.data.user.role_id,
            role_name: response.data.user.role.name,
            email: response.data.user.email,
            first_name: response.data.user.first_name,
            last_name: response.data.user.last_name,
            dob: response.data.user.dob,
            gender: response.data.user.gender,
            phone: response.data.user.phone,
            address: response.data.user.address,
            city: response.data.user.city,
            state: response.data.user.state,
            postal_code: response.data.user.postal_code,
            membership_start: response.data.user.membership_start,
            membership_end: response.data.months,
          },
          roles: {
            list: response.data.roles,
          },
          selectedDob: {
            dob: dob,
          },
          loading: false,
        });
      } else if (response.status === 404) {
        this.props.history.goBack();
        // this.props.history.push(Web.listAcquisition);
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  }

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    var selectLabel;
    if (e !== null) {
      selectValue = e.value;
      selectLabel = e.label;
    } else {
      selectValue = "";
      selectLabel = "";
    }
    let newForm = {
      ...this.state.edit,
      [key]: selectValue,
      ["role_name"]: selectLabel,
    };
    this.setState({
      edit: newForm,
    });
  };

  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.edit,
      [key]: e.target.value,
    };
    this.setState({
      edit: newForm,
    });
  };

  handleChangeMembership = (key) => (e) => {
    this.setState({
      membership_end: e.target.value,
    });
  };

  handleChangeD = (key) => (e) => {
    var newForm;
    if (e === null) {
      newForm = {
        ...this.state.edit,
        [key]: null,
      };
    } else {
      newForm = {
        ...this.state.edit,
        [key]: moment(e).format("DD-MM-yyyy"),
      };
    }

    let newForm1 = {
      ...this.state.selectedDob,
      [key]: e,
    };
    this.setState({
      selectedDob: newForm1,
      edit: newForm,
    });
  };

  update = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const id = this.props.match.params.id;
    Request.patchRequest(Url.updateUser + id, this.state.edit).then(
      (response) => {
        this.setState({
          btnLoading: false,
        });
        if (response.status === 200) {
          this.props.history.goBack();
          Messages.swalSuccess(response.data.success);
        } else if (response.status === 404) {
          this.props.history.goBack();
          Messages.swalError(response.data.error);
        } else {
          this.setState({
            form_errors: {
              error_list: response.data,
            },
          });
        }
      }
    );
  };

  changeMembership = (e) => {
    e.preventDefault();
    const id = this.props.match.params.id;
    Request.patchRequest(Url.changeMembership + id, {
      membership_end: this.state.membership_end,
    }).then((response) => {
      if (response.status === 200) {
        this.props.history.goBack();
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 404) {
        this.props.history.goBack();
        Messages.swalError(response.data.error);
      } else {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
        });
      }
    });
  };

  render() {
    var roles = "";
    if (!this.state.loading) {
      roles = this.state.roles.list.map((item) => {
        return { value: item.id, label: item.name };
      });
    }
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div className="invite-member">
            <p className="partial-title mb-5">Ndrysho Staf</p>

            <form onSubmit={this.update}>
              <input type="hidden" name="id" value="4" />
              <div className="row">
                <div className="col-12 col-md-4 flex-column col-form">
                  <div className="pb-4 mb-4 position-relative">
                    <label htmlFor="first_name">Emri:</label>
                    <input
                      placeholder="Shto Emrin"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.first_name
                          ? " form-error"
                          : "")
                      }
                      name="first_name"
                      type="text"
                      value={
                        this.state.edit.first_name !== null
                          ? this.state.edit.first_name
                          : ""
                      }
                      id="first_name"
                      onChange={this.handleChange("first_name")}
                    />
                    {this.state.form_errors.error_list.first_name && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.first_name[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-4">
                    <label htmlFor="last_name">Mbiemri:</label>
                    <input
                      placeholder="Shto Mbiemrin"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.last_name
                          ? " form-error"
                          : "")
                      }
                      name="last_name"
                      type="text"
                      value={
                        this.state.edit.last_name !== null
                          ? this.state.edit.last_name
                          : ""
                      }
                      id="last_name"
                      onChange={this.handleChange("last_name")}
                    />
                    {this.state.form_errors.error_list.last_name && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.last_name[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-4">
                    <label htmlFor="email">Email:</label>
                    <input
                      placeholder="Email"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.email
                          ? " form-error"
                          : "")
                      }
                      name="email"
                      type="text"
                      value={
                        this.state.edit.email !== null
                          ? this.state.edit.email
                          : ""
                      }
                      readOnly
                      id="email"
                    />
                    {this.state.form_errors.error_list.email && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.email[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-4">
                    <label htmlFor="dob">Ditëlindje:</label>
                    <DatePicker
                      placeholderText="Zgjidh datën"
                      name="dob"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale={sq}
                      dateFormat="dd-MM-yyyy"
                      selected={this.state.selectedDob.dob}
                      onChange={this.handleChangeD("dob")}
                      autoComplete="off"
                      className={
                        "date_input" +
                        (this.state.form_errors.error_list.dob
                          ? " form-error"
                          : "")
                      }
                    />
                    {this.state.form_errors.error_list.dob && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.dob[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-0">
                    <label htmlFor="role_id">Roli:</label>
                    <Select
                      className={
                        "basic-select" +
                        (this.state.form_errors.error_list.role_id
                          ? " form-error"
                          : "")
                      }
                      classNamePrefix="select_input"
                      name="role_id"
                      isClearable={true}
                      isSearchable={false}
                      placeholder="Përzgjidh"
                      value={[
                        {
                          value: this.state.edit.role_id,
                          label: this.state.edit.role_name,
                        },
                      ]}
                      options={roles}
                      onChange={this.handleChangeSelect("role_id")}
                    />
                    {this.state.form_errors.error_list.role_id && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.role_id[0]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 flex-column col-form">
                  <div className="pb-4 mb-4">
                    <label htmlFor="state">Shteti:</label>
                    <input
                      placeholder="Shto Shtetin"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.state
                          ? " form-error"
                          : "")
                      }
                      name="state"
                      type="text"
                      value={
                        this.state.edit.state !== null
                          ? this.state.edit.state
                          : ""
                      }
                      id="state"
                      onChange={this.handleChange("state")}
                    />

                    {this.state.form_errors.error_list.state && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.state[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-4">
                    <label htmlFor="city">Qyteti:</label>
                    <input
                      placeholder="Shto Qytetin"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.city
                          ? " form-error"
                          : "")
                      }
                      name="city"
                      type="text"
                      value={
                        this.state.edit.city !== null
                          ? this.state.edit.city
                          : ""
                      }
                      id="city"
                      onChange={this.handleChange("city")}
                    />
                    {this.state.form_errors.error_list.city && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.city[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-4">
                    <label htmlFor="address">Adresa:</label>
                    <input
                      placeholder="Shto Adresën"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.address
                          ? " form-error"
                          : "")
                      }
                      name="address"
                      type="text"
                      value={
                        this.state.edit.address !== null
                          ? this.state.edit.address
                          : ""
                      }
                      id="address"
                      onChange={this.handleChange("address")}
                    />
                    {this.state.form_errors.error_list.address && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.address[0]}
                      </span>
                    )}
                  </div>
                  <div className="pb-4 mb-4">
                    <label htmlFor="postal_code">Kodi Postar:</label>
                    <input
                      placeholder="Shto Kodin Postar"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.postal_code
                          ? " form-error"
                          : "")
                      }
                      name="postal_code"
                      type="number"
                      id="postal_code"
                      value={
                        this.state.edit.postal_code !== null
                          ? this.state.edit.postal_code
                          : ""
                      }
                      onChange={this.handleChange("postal_code")}
                    />
                    {this.state.form_errors.error_list.postal_code && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.postal_code[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-0">
                    <label htmlFor="phone">Telefon:</label>
                    <input
                      id="phone"
                      placeholder="Shto numrin e telefonit"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.phone
                          ? " form-error"
                          : "")
                      }
                      name="phone"
                      type="text"
                      value={
                        this.state.edit.phone !== null
                          ? this.state.edit.phone
                          : ""
                      }
                      onChange={this.handleChange("phone")}
                    />
                    {this.state.form_errors.error_list.phone && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.phone[0]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 d-flex flex-column col-form">
                  <div className="d-block mb-4">
                    <label htmlFor="gender" className="main-label">
                      Gjinia:
                    </label>

                    <div className="radio-buttons d-flex">
                      <div className="radio-button mr-2">
                        <label htmlFor="mashkull">Mashkull</label>
                        <input
                          className="form-check-input"
                          id="mashkull"
                          name="gender"
                          type="radio"
                          value="M"
                          checked={this.state.edit.gender === "M"}
                          onChange={this.handleChange("gender")}
                        />
                      </div>
                      <div className="radio-button ml-3">
                        <label htmlFor="femer">Femër</label>
                        <input
                          className="form-check-input"
                          id="femer"
                          name="gender"
                          type="radio"
                          value="F"
                          checked={this.state.edit.gender === "F"}
                          onChange={this.handleChange("gender")}
                        />
                      </div>
                      {this.state.form_errors.error_list.gender && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.gender[0]}
                        </span>
                      )}
                    </div>
                  </div>
                  {this.state.btnLoading === true ? (
                    <button className="btn-styled-2 mt-auto px-5 mb-4">
                      Duke Ndryshuar
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button className="btn-styled-2 mt-auto px-5 mb-4">
                      Ndrysho
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(EditStaff);
