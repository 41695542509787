import React, { Component,Fragment } from "react";
import Url from "../../../../api/Url";
import Request from "../../../../api/Request";
import Web from "../../../../routes/Web";
import Messages from "../../../../helpers/Messages";
import AppConfig from "../../../../services/AppConfig";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import moment from "moment";

class ProfileDetails extends Component {
  constructor() {
    super();
    this.state = {
      userDetails: {
        details:'',
        role: '',
        loading: false,
      },
    };
  }

  componentDidMount() {
    this.userDetails();
  }

  userDetails = () => {
    let request = Url.profileDetails;

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          userDetails: {
            details: response.data.user,
            role: response.data.user.role,
            loading: false,
          },
        });
      }
      else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  }



  render() {

      var userDetails = this.state.userDetails.details;
      var userRole = this.state.userDetails.role;

    return (
      <Fragment>
          <div className="tab-pane show active" id="user-profile" role="tabpanel" aria-labelledby="user-profile-tab">
            <div className="profile-bg">
              <p className="partial-title mb-5">Detaje Anëtari</p>

              <div className="mb-5 profile-widths">
                <div className="row ">
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-auto">Emër Mbiemër / Roli :</div>
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-user-auto"> {userDetails.first_name} /
                    {userDetails.last_name} / {userRole.name}
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-auto">Email :</div>
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-user-auto"> {userDetails.email}
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-auto">Cel :</div>
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-user-auto"> {userDetails.phone}</div>
                </div>
                <div className="row ">
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-auto">Datëlindje :</div>
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-user-auto">
                    {userDetails.dob && moment(userDetails.dob).format("DD-MM-yyy")}
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-auto">Gjinia :</div>
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-user-auto">
                    {userDetails.gender}
                  </div>
                </div>
                <div className="row ">
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-auto">Shteti / Qyteti :</div>
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-user-auto"> {userDetails.state} / {userDetails.city}</div>
                </div>
                <div className="row ">
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-auto">Adresa / Kodi postar :</div>
                  <div className="col-12 col-sm-12 col-lg-6 col-md-6 profile-details-user-auto">{userDetails.address} / {userDetails.postal_code}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </Fragment>
    );
  }
}

export default ProfileDetails;
