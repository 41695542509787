import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/auth.css';
import Web from '../../routes/Web';
import LogoWhite from '../../assets/images/logo/uniel-logo-white.svg';

class Auth extends Component {
  render() {
    return (
      <Fragment>
        <div className="col-md-6 left-auth-page">
          <div className="main-logo">
            <Link to={Web.homepage}>
              <img src={LogoWhite} alt="Logo Uniel" />
            </Link>
          </div>
          <div className="main-title">
            <h1>Biblioteka Juaj</h1>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Auth;
