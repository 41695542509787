import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Url from "../../../../../api/Url";
import Request from "../../../../../api/Request";
import Messages from "../../../../../helpers/Messages";
import AppConfig from "../../../../../services/AppConfig";
import libra from "../../../../../assets/images/covers/Libra.svg";
import Disertacione from "../../../../../assets/images/covers/Disertacione.svg";
import Periodike from "../../../../../assets/images/covers/Periodike.svg";
import Harta from "../../../../../assets/images/covers/Harta.svg";
import Doreshkrime from "../../../../../assets/images/covers/Doreshkrime.svg";
import Dokument from "../../../../../assets/images/covers/Dokument.svg";
import Artikuj from "../../../../../assets/images/covers/Artikuj.svg";
import Materiale_audio_vizuale from "../../../../../assets/images/covers/Materiale_audio-vizuale.svg";
import loadingGif from "../../../../../assets/images/icons/loading.gif";

class Marc extends Component {
  constructor() {
    super();
    this.state = {
      showMarc: {
        biblioitem: [],
        loading: true,
      },
      tab: {
        selected: "description",
      },
    };
  }

  componentDidMount() {
    this.biblioitemShow();
  }

  biblioitemShow = () => {
    const biblioitemID = this.props.match.params.id;
    Request.getRequest(Url.showBiblioitem + biblioitemID).then((response) => {
      if (response.status === 200) {
        this.setState({
          showMarc: {
            biblioitem: response.data.biblioitem,
            loading: false,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  checkCover = (cover, material_type) => {
    if (cover !== null) {
      return AppConfig.biblioCoversPath + cover;
    }
    if (cover === null) {
      switch (material_type) {
        case 2:
          return libra;
        case 6:
          return Disertacione;
        case 3:
          return Periodike;
        case 5:
          return Harta;
        case 4:
          return Doreshkrime;
        case 8:
          return Dokument;
        case 1:
          return Artikuj;
        case 7:
          return Materiale_audio_vizuale;
        default:
          return "";
      }
    }
  };

  checkContributors = (contributor) => {
    switch (contributor) {
      case "perkthyes":
        return "përkth.";
      case "redaktor":
        return "red.";
      case "recensues":
        return "rec.";
      case "pergatites":
        return "përg.";
      default:
        return "";
    }
  };

  render() {
    var marc = "";
    var biblioitem = this.state.showMarc.biblioitem;
    var frequence = this.state.showMarc.frequence;
    if (this.state.showMarc.loading === true) {
      marc = <img className="m-auto d-block" src={loadingGif} alt="loading" />;
    } else {
      var authors = biblioitem.biblio.authors.map((author, index) => {
        return (
          <Fragment key={index}>
            <div className="row mt-3">
              <div className="col-1 name-desc">100</div>
              <div className="col-11">
                <span className="name-item-a font-weight-bold">_a </span>
                <span className="name-item">{author.name}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-11">
                <span className="name-item-a font-weight-bold">_e </span>
                <span className="name-item">{author.pivot.type}</span>
              </div>
            </div>
          </Fragment>
        );
      });
      var authorsReversed = biblioitem.biblio.authors.map((author) => {
        return author.name.split(",").reverse().join(" ");
      });

      var keywords = biblioitem.biblio.keywords.map((keyword, index) => {
        return (
          <div key={index} className="row mt-3">
            <div className="col-1 name-desc">650</div>
            <div className="col-11">
              <span className="name-item-a font-weight-bold">_a </span>
              <span className="name-item">{keyword.name}</span>
            </div>
          </div>
        );
      });

      var geographicalterms = biblioitem.biblio.geographicalterms.map(
        (geographicalterm, index) => {
          return (
            <div key={index} className="row mt-3">
              <div className="col-1 name-desc">651</div>
              <div className="col-11">
                <span className="name-item-a font-weight-bold">_a </span>
                <span className="name-item">{geographicalterm.name}</span>
              </div>
            </div>
          );
        }
      );

      var contributors = biblioitem.contributors.map((contributor, index) => {
        return (
          <Fragment key={index}>
            <div className="row mt-3">
              <div className="col-1 name-desc">700</div>
              <div className="col-11">
                <span className="name-item-a font-weight-bold">_a </span>
                <span className="name-item">{contributor.name}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-11">
                <span className="name-item-a font-weight-bold">_e </span>
                <span className="name-item">{contributor.pivot.type}</span>
              </div>
            </div>
          </Fragment>
        );
      });

      var contributorReversed = biblioitem.contributors.map(
        (contributor, index) => {
          return (
            this.checkContributors(contributor.pivot.type) +
            " " +
            contributor.name.split(",").reverse().join(" ")
          );
        }
      );
      return (marc = (
        <div className="row mb-5 isbn">
          <div className="col-12 col-sm-4 d-none d-md-block image-holder text-left">
            <img
              src={this.checkCover(
                biblioitem.cover,
                biblioitem.biblio.materialtype.id
              )}
              alt="cover"
            />
          </div>
          <div className="col-12 col-md-8 description-holder">
            <div>
              {biblioitem.lccn !== null && (
                <div className="row">
                  <div className="col-1 name-desc">010</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">{biblioitem.lccn}</span>
                  </div>
                </div>
              )}
              {biblioitem.national_control_number !== null && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">016</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">
                      {biblioitem.national_control_number}
                    </span>
                  </div>
                </div>
              )}
              {biblioitem.isbn !== null && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">020</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">{biblioitem.isbn}</span>
                  </div>
                </div>
              )}
              {biblioitem.issn !== null && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">022</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">{biblioitem.issn}</span>
                  </div>
                </div>
              )}
              {biblioitem.biblio.language !== null && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">041</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">
                      {biblioitem.biblio.language.name}
                    </span>
                  </div>
                </div>
              )}
              {biblioitem.kdu !== null && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">080</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">{biblioitem.kdu}</span>
                  </div>
                </div>
              )}
              {authors !== null && <Fragment>{authors}</Fragment>}

              <Fragment>
                {biblioitem.biblio.title !== null && (
                  <div className="row mt-3">
                    <div className="col-1 name-desc">245</div>
                    <div className="col-11">
                      <span className="name-item-a font-weight-bold">_a </span>
                      <span className="name-item">
                        {biblioitem.biblio.title}
                      </span>
                    </div>
                  </div>
                )}
                {biblioitem.biblio.subtitle !== null && (
                  <div
                    className={
                      "row" + (biblioitem.biblio.title === null ? " mt-3" : "")
                    }
                  >
                    <div className="col-1 name-desc">
                      {biblioitem.biblio.title === null && "245"}
                    </div>
                    <div className="col-11">
                      <span className="name-item-a font-weight-bold">_b </span>
                      <span className="name-item">
                        {biblioitem.biblio.subtitle}
                      </span>
                    </div>
                  </div>
                )}
                {(authorsReversed.length > 0 ||
                  contributorReversed.length > 0) && (
                  <div
                    className={
                      "row" + (biblioitem.biblio.title === null ? " mt-3" : "")
                    }
                  >
                    <div className="col-1 name-desc">
                      {(biblioitem.biblio.title === null ||
                        biblioitem.biblio.subtitle === null) &&
                        "245"}
                    </div>
                    <div className="col-11">
                      <span className="name-item-a font-weight-bold">_c </span>
                      <span className="name-item">
                        {(authorsReversed.length > 0
                          ? authorsReversed.join("; ")
                          : "") +
                          (contributorReversed.length > 0 &&
                          authorsReversed.length <= 0
                            ? contributorReversed.join("; ")
                            : contributorReversed.length > 0 &&
                              authorsReversed.length > 0 &&
                              "; " + contributorReversed.join("; "))}
                      </span>
                    </div>
                  </div>
                )}
                {biblioitem.biblio.medium !== null && (
                  <div
                    className={
                      "row" +
                      (biblioitem.biblio.title === null ||
                      biblioitem.biblio.subtitle === null ||
                      authorsReversed.length <= 0 ||
                      contributorReversed.length <= 0
                        ? " mt-3"
                        : "")
                    }
                  >
                    <div className="col-1 name-desc">
                      {(biblioitem.biblio.title === null ||
                        biblioitem.biblio.subtitle === null) &&
                        "245"}
                    </div>
                    <div className="col-11">
                      <span className="name-item-a font-weight-bold">_h </span>
                      <span className="name-item">
                        {biblioitem.biblio.medium}
                      </span>
                    </div>
                  </div>
                )}
                {biblioitem.biblio.part_number !== null && (
                  <div
                    className={
                      "row" +
                      (biblioitem.biblio.title === null ||
                      biblioitem.biblio.subtitle === null ||
                      authorsReversed.length <= 0 ||
                      contributorReversed.length <= 0 ||
                      biblioitem.biblio.medium === null
                        ? " mt-3"
                        : "")
                    }
                  >
                    <div className="col-1 name-desc">
                      {(biblioitem.biblio.title === null ||
                        biblioitem.biblio.subtitle === null ||
                        authorsReversed.length <= 0 ||
                        contributorReversed.length <= 0 ||
                        biblioitem.biblio.medium === null) &&
                        "245"}
                    </div>
                    <div className="col-11">
                      <span className="name-item-a font-weight-bold">_n </span>
                      <span className="name-item">
                        {biblioitem.biblio.part_number}
                      </span>
                    </div>
                  </div>
                )}
                {biblioitem.biblio.part_name !== null && (
                  <div
                    className={
                      "row" +
                      (biblioitem.biblio.title === null ||
                      biblioitem.biblio.subtitle === null ||
                      authorsReversed.length <= 0 ||
                      contributorReversed.length <= 0 ||
                      biblioitem.biblio.medium === null ||
                      biblioitem.biblio.part_number === null
                        ? " mt-3"
                        : "")
                    }
                  >
                    <div className="col-1 name-desc">
                      {(biblioitem.biblio.title === null ||
                        biblioitem.biblio.subtitle === null ||
                        authorsReversed.length <= 0 ||
                        contributorReversed.length <= 0 ||
                        biblioitem.biblio.medium === null ||
                        biblioitem.biblio.part_number === null) &&
                        "245"}
                    </div>
                    <div className="col-11">
                      <span className="name-item-a font-weight-bold">_p </span>
                      <span className="name-item">
                        {biblioitem.biblio.part_name}
                      </span>
                    </div>
                  </div>
                )}
              </Fragment>
              {biblioitem.publication_number !== null && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">250</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">
                      {biblioitem.publication_number}
                    </span>
                  </div>
                </div>
              )}
              {biblioitem.place !== null && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">260</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">{biblioitem.place}</span>
                  </div>
                </div>
              )}
              {biblioitem.publisher !== null && (
                <div
                  className={"row" + (biblioitem.place === null ? " mt-3" : "")}
                >
                  <div className="col-1 name-desc">
                    {biblioitem.place === null && "260"}
                  </div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_b </span>
                    <span className="name-item">
                      {biblioitem.publisher.name}
                    </span>
                  </div>
                </div>
              )}
              {biblioitem.publication_year !== null && (
                <div
                  className={
                    "row" +
                    (biblioitem.place === null || biblioitem.publisher === null
                      ? " mt-3"
                      : "")
                  }
                >
                  <div className="col-1 name-desc">
                    {(biblioitem.place === null ||
                      biblioitem.publisher === null) &&
                      "260"}
                  </div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_c </span>
                    <span className="name-item">
                      {biblioitem.publication_year}
                    </span>
                  </div>
                </div>
              )}
              {biblioitem.pages !== null && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">300</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">{biblioitem.pages}</span>
                  </div>
                </div>
              )}
              {biblioitem.physical_details !== null && (
                <div
                  className={"row" + (biblioitem.pages === null ? " mt-3" : "")}
                >
                  <div className="col-1 name-desc">
                    {biblioitem.pages === null && "300"}
                  </div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_b </span>
                    <span className="name-item">
                      {biblioitem.physical_details}
                    </span>
                  </div>
                </div>
              )}
              {biblioitem.dimensions !== null && (
                <div
                  className={
                    "row" +
                    (biblioitem.pages === null ||
                    biblioitem.physical_details === null
                      ? " mt-3"
                      : "")
                  }
                >
                  <div className="col-1 name-desc">
                    {(biblioitem.pages === null ||
                      biblioitem.physical_details === null) &&
                      "300"}
                  </div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_c </span>
                    <span className="name-item">{biblioitem.dimensions}</span>
                  </div>
                </div>
              )}
              {frequence && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">310</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">{frequence.description}</span>
                  </div>
                </div>
              )}
              {biblioitem.price !== null && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">365</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_b </span>
                    <span className="name-item">{biblioitem.price}</span>
                  </div>
                </div>
              )}
              {biblioitem.currency && (
                <div
                  className={"row" + (biblioitem.price === null ? " mt-3" : "")}
                >
                  <div className="col-1 name-desc">
                    {biblioitem.price === null && "365"}
                  </div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_c </span>
                    <span className="name-item">{biblioitem.currency}</span>
                  </div>
                </div>
              )}
              {biblioitem.biblio.notes !== null && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">500</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">{biblioitem.biblio.notes}</span>
                  </div>
                </div>
              )}
              {biblioitem.biblio.abstract !== null && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">520</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">
                      {biblioitem.biblio.abstract}
                    </span>
                  </div>
                </div>
              )}
              {biblioitem.biblio.agerestriction !== null && (
                <div className="row mt-3">
                  <div className="col-1 name-desc">521</div>
                  <div className="col-11">
                    <span className="name-item-a font-weight-bold">_a </span>
                    <span className="name-item">
                      {biblioitem.biblio.agerestriction}
                    </span>
                  </div>
                </div>
              )}

              {keywords !== null && <Fragment>{keywords}</Fragment>}
              {geographicalterms !== null && (
                <Fragment>{geographicalterms}</Fragment>
              )}
              {contributors !== null && contributors}
            </div>
          </div>
        </div>
      ));
    }
    return <Fragment>{marc}</Fragment>;
  }
}

export default withRouter(Marc);
