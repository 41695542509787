import React, { Component, Fragment } from "react";
import Url from "../../../../api/Url";
import Request from "../../../../api/Request";
import Messages from "../../../../helpers/Messages";
import AppConfig from "../../../../services/AppConfig";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import Web from "../../../../routes/Web";
import { Link } from "react-router-dom";
import libra from "../../../../assets/images/covers/Libra.svg";
import Disertacione from "../../../../assets/images/covers/Disertacione.svg";
import Periodike from "../../../../assets/images/covers/Periodike.svg";
import Harta from "../../../../assets/images/covers/Harta.svg";
import Doreshkrime from "../../../../assets/images/covers/Doreshkrime.svg";
import Dokument from "../../../../assets/images/covers/Dokument.svg";
import Artikuj from "../../../../assets/images/covers/Artikuj.svg";
import Materiale_audio_vizuale from "../../../../assets/images/covers/Materiale_audio-vizuale.svg";

class SuggestedBooks extends Component {
  constructor() {
    super();
    this.state = {
      details: "",
      suggested_books: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.getProfileData();
  }

  getProfileData = () => {
    let request = Url.profile;

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          details: response.data.user,
          suggested_books: response.data.biblioitems,
          loading: false,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  checkCover = (cover, material_type) => {
    if (cover !== null) {
      return AppConfig.biblioCoversPath + cover;
    } else if (cover === null) {
      switch (material_type) {
        case 2:
          return libra;
        case 6:
          return Disertacione;
        case 3:
          return Periodike;
        case 5:
          return Harta;
        case 4:
          return Doreshkrime;
        case 8:
          return Dokument;
        case 1:
          return Artikuj;
        case 7:
          return Materiale_audio_vizuale;
        default:
          return "";
      }
    }
  };

  render() {
    var suggested_books = "";
    if (this.state.loading) {
      suggested_books = (
        <img className="m-auto d-block" src={loadingGif} alt="loading" />
      );
    } else {
      suggested_books = this.state.suggested_books.map(
        (suggested_book, index) => {
          const Authors = suggested_book.biblio.authors.map((author) => {
            return author.name;
          });
          return (
            <Link
              to={Web.detailed + suggested_book.id}
              className="suggested_book_body"
            >
              <div
                className="img_book"
                style={{
                  backgroundImage: `url("${this.checkCover(
                    suggested_book.cover,
                    suggested_book.biblio.materialtype.id
                  )}")`,
                }}
              ></div>
              <div className="book_details">
                <h1>{suggested_book.biblio.title}</h1>
                <span>{Authors.join("; ")}</span>
              </div>
            </Link>
          );
        }
      );
    }
    return (
      <Fragment>
        <div className="suggested_books_module row">
          <div className="col-lg-2"></div>
          <div className="col-md-12 col-lg-10 col-xl-10 col-12">
            <h1>Librat që mund t'ju interesojnë</h1>
            <div className="suggested_books">{suggested_books}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default SuggestedBooks;
