import React, { Component, Fragment } from "react";
import Pagination from "react-js-pagination";
import Paginate from "../../../helpers/Paginate";
import Select from "react-select";
import SearchFilter from "../../../helpers/SearchFilter";
import Request from "../../../api/Request";
import Messages from "../../../helpers/Messages";
import AppConfig from "../../../services/AppConfig";
import Url from "../../../api/Url";
import { Link } from "react-router-dom";
import TooltipComp from "../../../helpers/Tooltip";
import LoadingGif from "../../../assets/images/icons/loading.gif";
import noResult from "../../../assets/images/icons/no_result.svg";
import loadingGif from "../../../assets/images/icons/loading.gif";
import editIcon from "../../../assets/images/icons/edit_icon.svg";
import deleteIcon from "../../../assets/images/icons/delete_icon.svg";
import libra from "../../../assets/images/covers/Libra.svg";
import Disertacione from "../../../assets/images/covers/Disertacione.svg";
import Harta from "../../../assets/images/covers/Harta.svg";
import Artikuj from "../../../assets/images/covers/Artikuj.svg";
import Periodike from "../../../assets/images/covers/Periodike.svg";
import Materiale_audio_vizuale from "../../../assets/images/covers/Materiale_audio-vizuale.svg";
import Doreshkrime from "../../../assets/images/covers/Doreshkrime.svg";
import Web from "../../../routes/Web";

class SerialList extends Component {
  constructor() {
    super();
    this.state = {
      listSerials: {
        serials: [],
        loading: true,
      },
      serialData: {
        data: [],
        loading: true,
      },
      searchSerial: {
        search_text: "",
        type: "",
        error_list: [],
      },
      btnLoading: false,
      searched: false,
      pagination: Paginate.setPaginate(),
    };
  }
  componentDidMount() {
    this.serialsList();
  }

  serialsList = (hasSearch) => {
    let request = Url.listSerials;

    const searchParams = {
      search_text: this.state.searchSerial.search_text,
      type: this.state.searchSerial.type,
    };

    if (hasSearch) {
      this.setState({
        searched: true,
      });
      hasSearch.preventDefault();
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({ btnLoading: true });
    }

    Request.getRequest(request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          listSerials: {
            serials: response.data.serials.data,
            loading: false,
          },
          searchSerial: {
            ...this.state.searchSerial,
            error_list: [],
          },
          pagination: Paginate.setPaginateData(response.data.serials),
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          searchSerial: {
            ...this.state.searchSerial,
            error_list: response.data,
          },
          searched: false,
        });
      } else {
        this.setState({
          searched: false,
        });
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.searchSerial,
      [key]: selectValue,
    };
    this.setState({
      searchSerial: newForm,
    });
  };

  handleChangeInput = (key) => (e) => {
    let newForm = {
      ...this.state.searchSerial,
      [key]: e.target.value,
    };
    this.setState({
      searchSerial: newForm,
    });
  };

  deleteSerial = (e, id) => {
    Messages.swalConfirmDelete().then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(Url.deleteSerial + id).then((response) => {
          if (response.status === 200) {
            this.serialsList();
            Messages.swalSuccess(response.data.success);
          } else if (response.status === 404) {
            Messages.swalError(response.data.error);
          } else if (response.status === 405) {
            Messages.swalInfo(response.data.info);
          } else {
            Messages.swalError(AppConfig.serverError);
          }
        });
      }
    });
  };

  handlePageChange = (pageNumber) => {
    let request = Url.listSerials;
    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        search_text: this.state.searchSerial.search_text,
        type: this.state.searchSerial.type,
        page: pageNumber,
      };
    }
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listSerials: {
          serials: response.data.serials.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.serials),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  checkCover = (cover, material_type) => {
    if (cover !== null) {
      return AppConfig.biblioCoversPath + cover;
    }
    if (cover === null) {
      switch (material_type) {
        case 2:
          return libra;
        case 6:
          return Disertacione;
        case 3:
          return Periodike;
        case 5:
          return Harta;
        case 4:
          return Doreshkrime;
        case 1:
          return Artikuj;
        case 7:
          return Materiale_audio_vizuale;
        default:
          return "";
      }
    }
  };

  render() {
    var serialList = "";
    if (this.state.listSerials.loading) {
      serialList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      serialList = this.state.listSerials.serials.map((serial) => {
        const Authors = serial.biblioitem.biblio.authors.map((author) => {
          return author.name;
        });

        return (
          <tr
            key={serial.id}
            role="row"
            className="custom-dropdown dropdown-closed"
          >
            <td role="cell" className="pl-0 smaller-sc-title keep-visible">
              <div className="row bordered-tr bordered">
                <div className="col-lg-12">
                  <div className="kopertina">
                    <div className="d-inline-block kopertina1 image-vertical-aligment">
                      <img
                        src={this.checkCover(
                          serial.biblioitem.cover,
                          serial.biblioitem.biblio.materialtype_id
                        )}
                        alt="cover"
                      />
                    </div>
                    <div className="info-material d-inline-block">
                      <div className="tooltip-title title-link title-t">
                        <Link to={Web.showSerial + serial.id}>
                          {TooltipComp.tooltipDisabledMobile(
                            serial.id,
                            serial.biblioitem.biblio.title
                          )}
                        </Link>
                      </div>
                      <p className="autor mb-0">{Authors.join("; ")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td role="cell" data-label="Isbn/Issn" className="text-center ">
              {serial.biblioitem.isbn}
              {serial.biblioitem.issn && " / "}
              {serial.biblioitem.issn}
            </td>
            <td role="cell" data-label="Botues" className="text-center ">
              {serial.biblioitem.publisher !== null &&
                serial.biblioitem.publisher.name}
            </td>
            <td role="cell" data-label="Frekuenca" className="text-center ">
              {serial.frequence.description}
            </td>
            <td
              role="cell"
              data-label="Numri i serive"
              className="text-center "
            >
              {serial.series_count}
            </td>
            <td role="cell" data-label="Ndrysho" className="text-center">
              <Link to={Web.editSerial + serial.id}>
                <img src={editIcon} alt="Ndrysho" />
              </Link>
            </td>
            <td role="cell" data-label="Fshi" className="text-center pr-2">
              <button
                type="submit"
                className="btn-unstyled"
                onClick={(e) => this.deleteSerial(e, serial.id)}
              >
                <img src={deleteIcon} alt="Delete Icon" />
              </button>
            </td>
          </tr>
        );
      });
    }
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <p className="partial-title mb-5">Lista e Serialeve</p>
          <div className="search-item">
            <form onSubmit={this.serialsList}>
              <div className="row mb-5">
                <div className="col-12 col-sm-6 col-md-4 pb-4">
                  <input
                    placeholder="Kërko "
                    className={
                      "input-form-input" +
                      (this.state.searchSerial.error_list.search_text
                        ? " form-error"
                        : "")
                    }
                    name="search_text"
                    type="text"
                    onChange={this.handleChangeInput("search_text")}
                  />
                  {this.state.searchSerial.error_list.search_text && (
                    <span className="error-message left">
                      {this.state.searchSerial.error_list.search_text[0]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-4 pb-4">
                  <Select
                    className={
                      "basic-select" +
                      (this.state.searchSerial.error_list.type
                        ? " form-error"
                        : "")
                    }
                    classNamePrefix="select_input"
                    name="type"
                    isClearable={true}
                    placeholder="Përzgjidh"
                    onChange={this.handleChangeSelect("type")}
                    isSearchable={false}
                    options={[
                      { value: "title", label: "Titull" },
                      { value: "issn_isbn", label: "ISSN/ISBN" },
                    ]}
                  />
                  {this.state.searchSerial.error_list.type && (
                    <span className="error-message left">
                      {this.state.searchSerial.error_list.type[0]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  {this.state.btnLoading === true ? (
                    <button className="btn-styled-2 px-5" type="button">
                      Duke Kërkuar
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button className="btn-styled-2 px-5" type="submit">
                      Kërko
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="table-list1">
            <div id="list">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th
                      role="columnheader"
                      width="25%"
                      className="text-left pl-0 colored-th"
                    >
                      Titull/ Autor
                    </th>
                    <th
                      role="columnheader"
                      width="17%"
                      className="text-center colored-th"
                    >
                      Isbn/Issn
                    </th>
                    <th
                      role="columnheader"
                      width="17%"
                      className="text-center colored-th"
                    >
                      Botues
                    </th>
                    <th
                      role="columnheader"
                      width="12%"
                      className="text-center colored-th"
                    >
                      Frekuenca
                    </th>
                    <th
                      role="columnheader"
                      width="12%"
                      className="text-center colored-th"
                    >
                      Numri i serive
                    </th>
                    <th
                      role="columnheader"
                      width="12%"
                      className="text-center colored-th "
                    >
                      Ndrysho
                    </th>
                    <th
                      role="columnheader"
                      width="5%"
                      className="text-center colored-th pr-0"
                    >
                      Fshi
                    </th>
                  </tr>
                </thead>
                <tbody>{serialList}</tbody>
              </table>
              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listSerials.loading === false &&
                this.state.searched === true && (
                  <img
                    className="mx-auto d-block"
                    src={noResult}
                    alt="no result"
                  />
                )}

              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listSerials.loading === false &&
                this.state.searched === false && (
                  <p className="nothing--to--show">Asgjë për të shfaqur</p>
                )}
            </div>
          </div>
          <div className="container-fluid pt-5 pb-4">
            <div className="d-flex justify-content-center pagination-block">
              {this.state.pagination.totalPages > 1 && (
                <div className="d-flex justify-content-center pagination-block mb-5">
                  <div className="select-page">
                    <Pagination
                      activePage={this.state.pagination.activePage}
                      itemsCountPerPage={
                        this.state.pagination.itemsCountPerPage
                      }
                      totalItemsCount={this.state.pagination.totalItemsCount}
                      pageRangeDisplayed={
                        this.state.pagination.pageRangeDisplayed
                      }
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                  <div className="pagination-results">
                    Faqja: {this.state.pagination.activePage} -{" "}
                    {this.state.pagination.totalPages}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default SerialList;
