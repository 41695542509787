import React from "react";
import { Component, Fragment } from "react";
import Request from "../../../../api/Request";
import Url from "../../../../api/Url";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Messages from "../../../../helpers/Messages";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
class EditServer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serversList: {
        servers: {},
        loading: true,
      },
      updateServers: {
        serverName: "",
        serverUrl: "",
        portNumber: "",
        databaseName: "",
      },
      btnLoading: false,
      showModal: false,
      id: this.props.popupId,
      name: this.props.popupName,
      error_list: [],
      isError: false,
    };
  }

  isShowModal = (status) => {
    this.handleClose();
    this.setState({ showModal: status });
  };

  handleClose = () => {
    this.props.onPopupClose(false);
  };

  componentDidMount() {
    this.serverList();
  }

  serverList = () => {
    let request = Url.editServer;
    const id = this.props.popupId;

    Request.getRequest(request + id).then((response) => {
      if (response.status === 200) {
        this.setState({
          serversList: {
            servers: response.data.server,
            loading: false,
          },
          updateServers: {
            serverName: response.data.server.name,
            serverUrl: response.data.server.url,
            portNumber: response.data.server.port_number,
            databaseName: response.data.server.database_name,
          },
          error_list: [],
        });
      } else if (response.status === 404) {
        this.props.history.push(Web.z3950Server);
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  updateServer = (e) => {
    this.setState({
      btnLoading: true,
    });
    e.preventDefault();
    const id = this.props.popupId;

    let data = {
      name: this.state.updateServers.serverName,
      url: this.state.updateServers.serverUrl,
      port_number: this.state.updateServers.portNumber,
      database_name: this.state.updateServers.databaseName,
    };

    Request.patchRequest(Url.updateServer + id, data).then((res) => {
      this.setState({
        btnLoading: false,
      });
      if (res.status === 200) {
        this.handleClose();
        Messages.swalSuccess(res.data.success);
        this.setState({
          updateServers: {
            serverName: "",
            serverUrl: "",
            portNumber: "",
            databaseName: "",
          },
          error_list: [],
        });
      } else if (res.status === 422) {
        this.setState({
          error_list: res.data,
          isError: true,
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <Modal
          show={this.props.showModalPopup && !this.state.serversList.loading}
          onHide={this.handleClose}
          size="md"
          centered
        >
          <Modal.Header>
            <Modal.Title id="sign-in-title">Ndrysho Server</Modal.Title>
            <button
              className="btn-unstyled close-modal"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleClose}
            >
              ✕
            </button>
          </Modal.Header>
          <form onSubmit={this.updateServer} className="text-center">
            <Modal.Body>
              <div>
                <div className="m-0 row">
                  <div className="col-12">
                    <input
                      type="text"
                      name="name"
                      className={
                        "input-form-input mb-4" +
                        (this.state.isError === true &&
                        this.state.error_list.name
                          ? " form-error"
                          : "")
                      }
                      onChange={(e) => {
                        this.setState({
                          updateServers: {
                            ...this.state.updateServers,
                            serverName: e.target.value,
                          },
                        });
                      }}
                      defaultValue={this.state.updateServers.serverName}
                      placeholder="Server"
                    />
                    {this.state.isError === true && this.state.error_list.name && (
                      <span id="modal-edit" className="error-message left">
                        {this.state.error_list.name[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      name="url"
                      className={
                        "input-form-input mb-4" +
                        (this.state.isError === true &&
                        this.state.error_list.url
                          ? " form-error"
                          : "")
                      }
                      onChange={(e) =>
                        this.setState({
                          updateServers: {
                            ...this.state.updateServers,
                            serverUrl: e.target.value,
                          },
                        })
                      }
                      defaultValue={this.state.updateServers.serverUrl}
                      placeholder="Url"
                    />
                    {this.state.isError === true && this.state.error_list.url && (
                      <span id="modal-edit" className="error-message left">
                        {this.state.error_list.url[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      name="database_name"
                      className={
                        "input-form-input mb-4" +
                        (this.state.isError === true &&
                        this.state.error_list.database_name
                          ? " form-error"
                          : "")
                      }
                      onChange={(e) =>
                        this.setState({
                          updateServers: {
                            ...this.state.updateServers,
                            databaseName: e.target.value,
                          },
                        })
                      }
                      defaultValue={this.state.updateServers.databaseName}
                      placeholder="Emri i Databazës"
                    />
                    {this.state.isError === true &&
                      this.state.error_list.database_name && (
                        <span id="modal-edit" className="error-message left">
                          {this.state.error_list.database_name[0]}
                        </span>
                      )}
                  </div>
                  <div className="col-12">
                    <input
                      type="number"
                      name="port_number"
                      className={
                        "input-form-input mb-4" +
                        (this.state.isError === true &&
                        this.state.error_list.port_number
                          ? " form-error"
                          : "")
                      }
                      onChange={(e) =>
                        this.setState({
                          updateServers: {
                            ...this.state.updateServers,
                            portNumber: e.target.value,
                          },
                        })
                      }
                      defaultValue={this.state.updateServers.portNumber}
                      placeholder="Nr. i portës"
                    />
                    {this.state.isError === true &&
                      this.state.error_list.port_number && (
                        <span id="modal-edit" className="error-message left">
                          {this.state.error_list.port_number[0]}
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-12 d-flex m-0 p-0 justify-content-center">
                <div className="col-6 pl-0 pr-2 pr-sm-3 justify-content-center">
                  {this.state.btnLoading === true ? (
                    <button
                      className="btn-styled-2 w-100 text-center"
                      type="button"
                    >
                      Duke aprovuar
                      <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                            />
                    </button>
                  ) : (
                    <button
                      className="btn-styled-2 w-100 text-center"
                      type="submit"
                    >
                      Po
                    </button>
                  )}
                </div>
                <div className="col-6 pr-0 pl-2 pl-sm-3">
                  <button
                    className="btn-styled-1 w-100 text-center"
                    type="button"
                    onClick={this.handleClose}
                  >
                    Anullo
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </Fragment>
    );
  }
}

export default withRouter(EditServer);
