import React, { Component, Fragment } from "react";
import Url from "../../../api/Url";
import Web from "../../../routes/Web";
import { withRouter } from "react-router-dom";
import Request from "../../../api/Request";
import Messages from "../../../helpers/Messages";
import AppConfig from "../../../services/AppConfig";
import dateFormat from "dateformat";

class ShowAcquisition extends Component {
  constructor() {
    super();
    this.state = {
      acquistion: {
        details: "",
      },
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    Request.getRequest(Url.showAcquisition + id).then((response) => {
      if (response.status === 200) {
        this.setState({
          acquistion: {
            details: response.data.acquisition,
          },
        });
      } else if (response.status === 404) {
        this.props.history.push(Web.listAcquisition);
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  }

  render() {
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <p className="partial-title mb-5">Detaje të pasurimit</p>
          <div className="row mb-5">
            <div className="list-data col-12 col-md-8 d-flex">
              <ul>
                <li>
                  Titulli :{" "}
                  {this.state.acquistion.details.title
                    ? this.state.acquistion.details.title
                    : "-"}
                </li>
                <li>
                  Autori :{" "}
                  {this.state.acquistion.details.author
                    ? this.state.acquistion.details.author
                    : "-"}
                </li>
                <li>
                  Kopjet :{" "}
                  {this.state.acquistion.details.copies
                    ? this.state.acquistion.details.copies
                    : "-"}
                </li>
                <li>
                  Dhurues/ Shitës :{" "}
                  {this.state.acquistion.details.seller_donor
                    ? this.state.acquistion.details.seller_donor
                    : "-"}
                </li>
                <li>
                  Dt. kur pritet të vijnë :{" "}
                  {this.state.acquistion.details.expected_on
                    ? this.state.acquistion.details.expected_on !== null &&
                      dateFormat(
                        this.state.acquistion.details.expected_on,
                        "d-mm-yyyy"
                      )
                    : "-"}
                </li>
                <li>
                  Komente :{" "}
                  {this.state.acquistion.details.comments
                    ? this.state.acquistion.details.comments
                    : "-"}
                </li>
                <li>
                  Statusi :{" "}
                  {this.state.acquistion.details.status
                    ? this.state.acquistion.details.status
                    : "-"}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(ShowAcquisition);
