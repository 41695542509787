import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Url from "../../../../api/Url";
import Request from "../../../../api/Request";
import Messages from "../../../../helpers/Messages";
import AppConfig from "../../../../services/AppConfig";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import Paginate from "../../../../helpers/Paginate";
import SearchFilter from "../../../../helpers/SearchFilter";
import Pagination from "react-js-pagination";
import Web from "../../../../routes/Web";
import Select from "react-select";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
import noResult from "../../../../assets/images/icons/no_result.svg";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import CreateSerie from "./CreateSerie";
import EditSerie from "./EditSerie";
import TooltipComp from "../../../../helpers/Tooltip";

class EditSerial extends Component {
  constructor() {
    super();
    this.state = {
      serialData: {
        serial: [],
        approvedSeries: "",
        notApprovedSeries: "",
        loading: true,
      },
      serialList: {
        list: [],
        loading: true,
      },
      updateSerial: {
        biblioitem_id: "",
        frequence_id: "",
        start_subscription: "",
        end_subscription: "",
      },
      selectedDate: {
        start_subscription: "",
        end_subscription: "",
      },
      formError: [],
      searchSerial: {
        search_text: "",
        type: "",
      },
      btnLoading: false,
      btnAddLoading: false,
      errorListSearch: [],
      frequences: "",
      selectedFrequence: "",
      showResults: false,
      searched: false,
      pagination: Paginate.setPaginate(),
    };
  }

  componentDidMount() {
    this.editSerial();
    if (this.state.reRender === true) {
      this.editSerial();
    }
  }

  editSerial = () => {
    const serialId = this.props.match.params.id;
    let request = Url.editSerial + serialId;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          serialData: {
            serial: response.data.serial,
            approvedSeries: response.data.approvedSeries,
            notApprovedSeries: response.data.notApprovedSeries,
            loading: false,
          },
          updateSerial: {
            biblioitem_id: response.data.serial.biblioitem_id,
            frequence_id: response.data.serial.frequence.id,
            start_subscription: response.data.serial.start_subscription,
            end_subscription: response.data.serial.end_subscription,
          },
          selectedFrequence: {
            value: response.data.serial.frequence.id,
            label: response.data.serial.frequence.description,
          },
          selectedDate: {
            start_subscription: response.data.serial.start_subscription,
            end_subscription: response.data.serial.end_subscription,
          },
        });
        this.listFrequences();
      }
    });
  };

  listFrequences = () => {
    const serialId = this.props.match.params.id;
    let request = Url.editSerial + serialId;

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          frequences: response.data.frequences,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  reRenderList = () => {
    this.editSerial();
  };

  serialList = (hasSearch) => {
    const serialId = this.props.match.params.id;
    let request = Url.editSerial + serialId;
    const searchParams = {
      search_text: this.state.searchSerial.search_text,
      type: this.state.searchSerial.type,
    };
    if (hasSearch) {
      hasSearch.preventDefault();
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({ searched: true });
      this.setState({ btnLoading: true });
      Request.getRequest(request).then((response) => {
        this.setState({ btnLoading: false });
        if (response.status === 200) {
          this.setState({
            serialList: {
              list: response.data.materials.data,
              loading: false,
            },
            errorListSearch: [],
            pagination: Paginate.setPaginateData(response.data.materials),
          });
        } else if (response.status === 422) {
          this.setState({
            errorListSearch: response.data,
          });
        } else {
          Messages.swalError(AppConfig.serverError);
        }
      });
      this.setState({
        showResults: true,
      });
    }
  };

  handlePageChange = (pageNumber) => {
    const serialId = this.props.match.params.id;
    let request = Url.editSerial + serialId;
    var searchParams = {
      search_text: this.state.searchSerial.search_text,
      type: this.state.searchSerial.type,
      page: pageNumber,
    };
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        serialList: {
          list: response.data.materials.data,
        },
        pagination: Paginate.setPaginateData(response.data.materials),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  updateSerial = (e) => {
    e.preventDefault();
    this.setState({ btnAddLoading: true });
    const serialId = this.props.match.params.id;
    let request = Url.updateSerial + serialId;

    var EndSubsciption = "";

    if (this.state.updateSerial.end_subscription !== null) {
      EndSubsciption = {
        end_subscription: this.state.updateSerial.end_subscription,
      };
    }

    var serialUpdate = {
      biblioitem_id: this.state.updateSerial.biblioitem_id,
      frequence_id: this.state.updateSerial.frequence_id,
      start_subscription: this.state.updateSerial.start_subscription,
      ...EndSubsciption,
    };

    Request.patchRequest(request, serialUpdate).then((response) => {
      this.setState({ btnAddLoading: false });
      if (response.status === 200) {
        this.props.history.push(Web.listSerials);
        Messages.swalSuccess(response.data.success);
        this.setState({
          updateSerial: {
            biblioitem_id: "",
            frequence_id: "",
            start_subscription: "",
            end_subscription: "",
          },
          formError: [],
        });
      } else if (response.status === 405) {
        Messages.swalInfo(response.data.info);
      } else if (response.status === 422) {
        this.setState({
          formError: response.data,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  searchInput = (key) => (e) => {
    let newForm = {
      ...this.state.searchSerial,
      [key]: e.target.value,
    };
    this.setState({
      searchSerial: newForm,
    });
  };
  searchSelect = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.searchSerial,
      [key]: selectValue,
    };
    this.setState({
      searchSerial: newForm,
    });
  };
  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.updateSerial,
      [key]: e.target.value,
    };
    this.setState({
      updateSerial: newForm,
    });
  };
  handleChangeSelect = (key) => (e) => {
    var selectValue;
    var selectLabel;
    if (e !== null) {
      selectValue = e.value;
      selectLabel = e.label;
    } else {
      selectValue = "";
      selectLabel = "";
    }
    let newForm = {
      ...this.state.updateSerial,
      [key]: selectValue,
    };
    let newForm1 = {
      ...this.state.selectedFrequence,
      value: selectValue,
      label: selectLabel,
    };
    this.setState({
      updateSerial: newForm,
      selectedFrequence: newForm1,
    });
  };
  handleChangeD = (key) => (e) => {
    var value = null;
    if (e !== null) {
      value = moment(e).format("DD-MM-yyyy");
    }
    let newForm = {
      ...this.state.updateSerial,
      [key]: value,
    };
    let newForm1 = {
      ...this.state.selectedDate,
      [key]: e,
    };
    this.setState({
      selectedDate: newForm1,
      updateSerial: newForm,
    });
  };

  render() {
    var serialList;
    var serialData = this.state.serialData.serial;
    if (this.state.serialData.loading === true) {
      serialList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      var authors = serialData.biblioitem.biblio.authors.map((author) => {
        return author.name;
      });
      var selectedSerial = (
        <tr className="selected">
          <td role="cell" data-label="Titull">
            {TooltipComp.tooltipDisabledMobile(
              "selected_title",
              serialData.biblioitem.biblio.title
            )}
            <br />
            <span className="small-text">
              Bibliografia aktuale e ketij seriali
            </span>
          </td>
          <td role="cell" data-label="Autor" className="text-center">
            {authors.join("; ")}
          </td>
          <td role="cell" data-label="Isbn / Issn" className="text-center">
            {serialData.biblioitem.isbn ? serialData.biblioitem.isbn : "- "}
            {serialData.biblioitem.issn
              ? " / " + serialData.biblioitem.issn
              : " / -"}
          </td>
          <td role="cell" data-label="Botuesi" className="text-center">
            {serialData.biblioitem.publisher
              ? serialData.biblioitem.publisher.name
              : "-"}
          </td>
          <td role="cell" data-label="Përzgjidh" className="text-center">
            <input
              type="radio"
              name="biblioitem_id"
              value={serialData.biblioitem_id}
              onChange={this.handleChange("biblioitem_id")}
              defaultChecked="checked"
            />
          </td>
        </tr>
      );

      var afterLoad = (
        <Fragment>
          <CreateSerie
            SerialId={this.props.match.params.id}
            NotApprovedSerieId={this.state.serialData.notApprovedSeries[0].id}
            BiblioitemId={this.state.serialData.serial.biblioitem_id}
            onCreateSerieSubmit={this.reRenderList}
          />
          <EditSerie
            ApprovedSeries={this.state.serialData.approvedSeries}
            BiblioitemId={this.state.serialData.serial.biblioitem_id}
          />
        </Fragment>
      );

      var startSubscription = null;
      if (this.state.selectedDate.start_subscription !== null) {
        startSubscription = new Date(
          this.state.selectedDate.start_subscription
        );
      }
      var endSubscription = null;
      if (this.state.selectedDate.end_subscription !== null) {
        endSubscription = new Date(this.state.selectedDate.end_subscription);
      }
      var frequences = this.state.frequences;
      if (frequences) {
        var frequenceList = frequences.map((frequence) => {
          return { value: frequence.id, label: frequence.description };
        });
      }
      var selectedFrequence = this.state.selectedFrequence;
      if (
        this.state.showResults === true &&
        this.state.serialList.list !== undefined
      ) {
        serialList = this.state.serialList.list.map((serial) => {
          const authors = serial.biblio.authors.map((author) => {
            return author.name;
          });
          return (
            <tr key={serial.id}>
              <td role="cell" data-label="Titull">
                {TooltipComp.tooltipDisabledMobile(
                  "title" + serial.id,
                  serial.biblio.title
                )}
              </td>
              <td role="cell" data-label="Autor" className="text-center">
                {authors.join("; ")}
              </td>
              <td role="cell" data-label="Isbn / Issn" className="text-center">
                {serial.isbn ? serial.isbn : "- "}
                {serial.issn ? " / " + serial.issn : " / -"}
              </td>
              <td role="cell" data-label="Botuesi" className="text-center">
                {serial.publisher ? serial.publisher.name : "-"}
              </td>
              <td role="cell" data-label="Përzgjidh" className="text-center">
                <input
                  type="radio"
                  name="biblioitem_id"
                  value={serial.id}
                  onChange={this.handleChange("biblioitem_id")}
                  className={this.state.formError.biblioitem_id && "form-error"}
                />
              </td>
            </tr>
          );
        });
      }
    }
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="edit-serial">
            <p className="partial-title mb-5">Ndrysho Serial</p>
            <div className="search-item">
              <form onSubmit={this.serialList}>
                <div className="row mb-5">
                  <div className="col-12 col-sm-6 col-md-4 pb-4">
                    <input
                      placeholder="Kërko "
                      className={
                        "input-form-input" +
                        (this.state.errorListSearch.search_text
                          ? " form-error"
                          : "")
                      }
                      name="search_text"
                      type="text"
                      value={this.state.searchSerial.search_text}
                      onChange={this.searchInput("search_text")}
                    />
                    {this.state.errorListSearch.search_text && (
                      <span className="error-message left">
                        {this.state.errorListSearch.search_text[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 pb-4">
                    <Select
                      className={
                        "basic-select" +
                        (this.state.errorListSearch.type ? " form-error" : "")
                      }
                      classNamePrefix="select_input"
                      name="type"
                      placeholder="Përzgjidh"
                      onChange={this.searchSelect("type")}
                      isSearchable={false}
                      isClearable={true}
                      defaultInputValue={this.state.searchSerial.type}
                      options={[
                        { value: "title", label: "Titull" },
                        { value: "author", label: "Autor" },
                        { value: "issn_isbn", label: "ISSN/ISBN" },
                      ]}
                    />
                    {this.state.errorListSearch.type && (
                      <span className="error-message left">
                        {this.state.errorListSearch.type[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 col-md-4">
                    {this.state.btnLoading === true ? (
                      <button className="btn-styled-2 px-5" type="button">
                        Duke Kërkuar
                        <img
                          src={LoadingGif}
                          style={{
                            width: "18px",
                            margin: "-4px 0 0 7px",
                          }}
                        />
                      </button>
                    ) : (
                      <button className="btn-styled-2 px-5" type="submit">
                        Kërko
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div>
              <form onSubmit={this.updateSerial}>
                <div className="table-list mt-5">
                  <table role="table" className="w-100">
                    <thead>
                      <tr role="row">
                        <th
                          role="columnheader"
                          width="25%"
                          className="text-left"
                        >
                          Titull
                        </th>
                        <th
                          role="columnheader"
                          width="19.75%"
                          className="text-center"
                        >
                          Autor
                        </th>
                        <th
                          role="columnheader"
                          width="19.75%"
                          className="text-center"
                        >
                          Isbn / Issn
                        </th>
                        <th
                          role="columnheader"
                          width="19.75%"
                          className="text-center"
                        >
                          Botuesi
                        </th>
                        <th
                          role="columnheader"
                          width="15.75%"
                          className="text-center"
                        >
                          Përzgjidh
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedSerial}
                      {serialList}
                    </tbody>
                  </table>
                  {this.state.pagination.totalItemsCount === 0 &&
                    this.state.searchSerial.loading === false &&
                    this.state.showResults === true && (
                      <img
                        className="mx-auto d-block"
                        src={noResult}
                        alt="no result"
                      />
                    )}
                </div>
                {this.state.formError.biblioitem_id && (
                  <span className="error-message position-relative">
                    {this.state.formError.biblioitem_id}
                  </span>
                )}
                {this.state.pagination.totalPages > 1 && (
                  <div className="d-flex justify-content-center flex-wrap pagination-block mt-4 mb-5">
                    <div className="select-page">
                      <Pagination
                        activePage={this.state.pagination.activePage}
                        itemsCountPerPage={
                          this.state.pagination.itemsCountPerPage
                        }
                        totalItemsCount={this.state.pagination.totalItemsCount}
                        pageRangeDisplayed={
                          this.state.pagination.pageRangeDisplayed
                        }
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                    <div className="pagination-results">
                      Faqja:
                      {this.state.pagination.activePage} -{" "}
                      {this.state.pagination.totalPages}
                    </div>
                  </div>
                )}
                <div className="input-items mt-5">
                  <div className="row mb-5 align-items-end">
                    <div className="col-12 col-lg-3 col-md-4 pb-4">
                      <label htmlFor="frequence_id-button">
                        Përzgjidh frekuencën (310/a)
                      </label>
                      <Select
                        className={
                          "basic-select" +
                          (this.state.formError.frequence_id
                            ? " form-error"
                            : "")
                        }
                        classNamePrefix="select_input"
                        name="frequence_id"
                        isClearable={true}
                        placeholder="Përzgjidh"
                        onChange={this.handleChangeSelect("frequence_id")}
                        isSearchable={false}
                        options={frequenceList}
                        value={[selectedFrequence]}
                      />
                      {this.state.formError.frequence_id && (
                        <span className="error-message left">
                          {this.state.formError.frequence_id}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 pb-4">
                      <label htmlFor="start_subscription">
                        Data e fillimit të botimit
                      </label>
                      <DatePicker
                        placeholderText="Zgjidh datën"
                        name="start_subscription"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={startSubscription}
                        onChange={this.handleChangeD("start_subscription")}
                        autoComplete="off"
                        className={
                          "date_input" +
                          (this.state.formError.start_subscription
                            ? " form-error"
                            : "")
                        }
                      />
                      {this.state.formError.start_subscription && (
                        <span className="error-message left">
                          {this.state.formError.start_subscription}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 pb-4">
                      <label htmlFor="end_subscription">
                        Data e fundit e botimit
                      </label>
                      <DatePicker
                        placeholderText="Zgjidh datën"
                        name="end_subscription"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={endSubscription}
                        onChange={this.handleChangeD("end_subscription")}
                        autoComplete="off"
                        className={
                          "date_input" +
                          (this.state.formError.end_subscription
                            ? " form-error"
                            : "")
                        }
                      />
                      {this.state.formError.end_subscription && (
                        <span className="error-message left">
                          {this.state.formError.end_subscription}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-lg-3 col-md-4 pb-4 d-flex">
                      {this.state.btnAddLoading === true ? (
                        <button className="btn-styled-2 px-5">
                          Duke u Ruajtur
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                        </button>
                      ) : (
                        <button className="btn-styled-2 px-5">Ruaj</button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div>{afterLoad}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(EditSerial);
