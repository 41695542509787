import React, { Component, Fragment } from "react";
import "../../../assets/css/frontend.css";
import News from "./News";
import LatestBooks from "./LatestBooks";
import MostReadBooks from "./MostReadBooks";
import DigitizedBooks from "./DigitizedBooks";

class Homepage extends Component {
	render() {
		return (
			<Fragment>
				<div id="opac-homepage" className="bl_page">
					<MostReadBooks />
					<LatestBooks />
					<DigitizedBooks />
					<News />
				</div>
			</Fragment>
		);
	}
}

export default Homepage;
