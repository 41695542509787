import React, { Component, Fragment } from "react";
import Select from "react-select";
import right from "../../../../../assets/images/icons/arrow_right.svg";
import left from "../../../../../assets/images/icons/arrow_left.svg";
import Web from "../../../../../routes/Web";
import { Link, withRouter } from "react-router-dom";
import Url from "../../../../../api/Url";
import Request from "../../../../../api/Request";
import AppConfig from "../../../../../services/AppConfig";
import Messages from "../../../../../helpers/Messages";
import AuthorModal from "../AuthorModal";
import LoadingGif from "../../../../../assets/images/icons/loading.gif";

class biblioitem extends Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    let request = Url.createBiblioitem;
    Request.getRequest(request + id).then((response) => {
      if (response.status === 200) {
        this.setState({
          biblio_id: response.data.biblio_id,
          publishers: response.data.publishers,
          languages: response.data.languages,
          contributors: response.data.contributors,
        });
      } else if (response.status === 404) {
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  }
  constructor() {
    super();
    this.state = {
      biblioitem: {
        barcode: "",

        currency: "",
        dimensions: "",
        ean: "",
        isbn: "",
        issn: "",
        kdu: "",
        language_id: "",
        lccn: "",
        national_control_number: "",
        pages: "",
        physical_details: "",
        place: "",
        price: "",
        publication_number: "",
        publication_year: "",
        publisher_id: "",
        replacement_price: "",
      },
      btnLoading: false,
      cover: null,
      url: null,
      temporary_coverpath: "",
      temporary_urlpath: "",
      contributors: [],
      biblio_id: "",
      publishers: [],
      languages: [],
      addContributorPopup: false,
      selectedContributors: [],
      error_list: [],
      contributor_types: [
        { value: "redaktor", label: "Redaktor" },
        { value: "perkthyes", label: "Përkthyes" },
        { value: "recensues", label: "Recensues" },
        { value: "pergatites", label: "Përgatitës" },
        { value: "kontributor", label: "Person përgjegjës" },
      ],
    };
  }

  handleChangeInput = (e) => {
    this.setState({
      biblioitem: {
        ...this.state.biblioitem,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeSelect = (e, key) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    this.setState({
      biblioitem: {
        ...this.state.biblioitem,
        [key]: selectValue,
      },
    });
  };

  addContributorPopup = () => {
    this.setState({
      addContributorPopup: true,
    });
  };

  closeContributorPopup = (status) => {
    this.setState({ addContributorPopup: status });
  };

  removeContributor = (e, contributor) => {
    e.preventDefault();

    var authorrem = { id: contributor.id, name: contributor.name };
    this.setState({
      contributors: [...this.state.contributors, authorrem],
    });

    const inputs1 = this.state.selectedContributors;
    const removeIndex = inputs1.findIndex((item) => item.id === contributor.id);
    inputs1.splice(removeIndex, 1);
    this.setState({
      selectedContributors: inputs1,
    });
  };

  handleCallbackContributor = (dataAuthor, typeAuthor, control) => {
    var author = { id: dataAuthor.id, name: dataAuthor.name, type: typeAuthor };
    this.setState({
      selectedContributors: [...this.state.selectedContributors, author],
    });
    if (control === "old") {
      const inputs1 = this.state.contributors;
      const removeIndex = inputs1.findIndex(
        (item) => item.id === dataAuthor.id
      );
      inputs1.splice(removeIndex, 1);
      this.setState({
        contributors: inputs1,
      });
    }
  };

  fileSelectHandler = (e, type, temp) => {
    if (e.target.files[0]) {
      this.setState({
        [type]: e.target.files[0],
        [temp]: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  removeImage = (e, type, temp) => {
    this.setState({
      [type]: null,
      [temp]: "",
    });
  };

  biblioitem = (e, goto) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const id = this.props.match.params.id;
    const formData = new FormData();
    formData.append("biblio_id", id);
    formData.append(goto, 1);
    formData.append("barcode", this.state.biblioitem.barcode);
    formData.append("cover", this.state.cover === null ? "" : this.state.cover);
    formData.append("currency", this.state.biblioitem.currency);
    formData.append("dimensions", this.state.biblioitem.dimensions);
    formData.append("ean", this.state.biblioitem.ean);
    formData.append("isbn", this.state.biblioitem.isbn);
    formData.append("issn", this.state.biblioitem.issn);
    formData.append("kdu", this.state.biblioitem.kdu);
    formData.append("language_id", this.state.biblioitem.language_id);
    formData.append("lccn", this.state.biblioitem.lccn);
    formData.append(
      "national_control_number",
      this.state.biblioitem.national_control_number
    );
    formData.append("pages", this.state.biblioitem.pages);
    formData.append("physical_details", this.state.biblioitem.physical_details);
    formData.append("place", this.state.biblioitem.place);
    formData.append("price", this.state.biblioitem.price);
    formData.append(
      "publication_number",
      this.state.biblioitem.publication_number
    );
    formData.append("publication_year", this.state.biblioitem.publication_year);
    formData.append("publisher_id", this.state.biblioitem.publisher_id);
    formData.append(
      "replacement_price",
      this.state.biblioitem.replacement_price
    );
    formData.append("url", this.state.url === null ? "" : this.state.url);
    formData.append("url", this.state.url === null ? "" : this.state.url);

    this.state.selectedContributors.map((author, index) => {
      formData.append("contributors[" + index + "][author_id]", author.id);
      formData.append("contributors[" + index + "][type]", author.type);
    });

    Request.postRequest(Url.storeBiblioitem, formData).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          error_list: [],
        });
        if (goto === "back") {
          if (response.data.biblioitem_id !== "") {
            this.props.history.push(
              Web.editBiblio +
                response.data.biblio_id +
                "/" +
                response.data.biblioitem_id
            );
            Messages.swalSuccess(response.data.success);
          } else {
            this.props.history.push(Web.editBiblio + response.data.biblio_id);
            // Messages.swalSuccess(response.data.success);
          }
        } else {
          this.props.history.push(Web.createItem + response.data.biblioitem_id);
          Messages.swalSuccess(response.data.success);
        }
      } else if (response.status === 422) {
        this.setState({
          error_list: response.data,
        });
        if (response.data.error) {
          Messages.swalError(response.data.error);
        }
      }
    });
  };

  render() {
    if (this.state.languages.length !== 0) {
      var languages = this.state.languages.map((language) => {
        return { value: language.id, label: language.name };
      });
    }

    if (this.state.publishers.length !== 0) {
      var publisher = this.state.publishers.map((publisher) => {
        return { value: publisher.id, label: publisher.name };
      });
    }

    if (this.state.selectedContributors.length !== 0) {
      var selectedContributorsListName = this.state.selectedContributors.map(
        (contributor, index) => {
          return (
            <span key={index} className="tag-kyce mr-2">
              {contributor.name} / {contributor.type}
              <button
                type="button"
                className="ml-2 mr-1 pointer-li btn-unstyled x-button"
                onClick={(e) => this.removeContributor(e, contributor, index)}
              >
                ✕
              </button>
            </span>
          );
        }
      );
    }

    return (
      <Fragment>
        <div
          id="create-biblio-item"
          className="container-fluid small-offset-left"
        >
          <form>
            <div className="row biblio-shto m-0">
              <div className="col-7 col-sm-9 float-left partial-title p-0 mb-5">
                Shto Bibliografi
              </div>
              <div className="col-5 col-sm-3 p-0 d-flex">
                <div className="navigation">
                  <button
                    className="btn-unstyled"
                    name="back"
                    onClick={(e) => this.biblioitem(e, "back")}
                  >
                    <img src={left} alt="back" />
                  </button>
                  <span>2 / 3</span>
                  <button
                    className="btn-unstyled"
                    name="forward"
                    onClick={(e) => this.biblioitem(e, "forward")}
                  >
                    <img src={right} alt="forward" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-4 pb-4">
                <label htmlFor="ISBN">Isbn (020/a)</label>
                <input
                  type="text"
                  name="isbn"
                  placeholder="Isbn"
                  className={
                    "input-form-input" +
                    (this.state.error_list.isbn ? " form-error" : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.isbn && (
                  <span className="error-message left">
                    {this.state.error_list.isbn[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-md-6 mb-4 pb-4">
                <label htmlFor="Issn">Issn (022/a)</label>
                <input
                  type="text"
                  name="issn"
                  placeholder="Issn"
                  className={
                    "input-form-input" +
                    (this.state.error_list.issn ? " form-error" : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.issn && (
                  <span className="error-message left">
                    {this.state.error_list.issn[0]}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 mb-4 pb-4">
                <label htmlFor="Viti i Botimit">Viti i Botimit (260/c)</label>
                <input
                  type="number"
                  name="publication_year"
                  placeholder="Viti i Botimit"
                  className={
                    "input-form-input" +
                    (this.state.error_list.publication_year
                      ? " form-error"
                      : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.publication_year && (
                  <span className="error-message left">
                    {this.state.error_list.publication_year[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-md-4 mb-4 pb-4">
                <label htmlFor="Vendi i botimit">Vendi i botimit (260/a)</label>
                <input
                  type="text"
                  name="place"
                  placeholder="Vendi i botimit"
                  className={
                    "input-form-input" +
                    (this.state.error_list.place ? " form-error" : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.place && (
                  <span className="error-message left">
                    {this.state.error_list.place[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-md-4 mb-4 pb-4">
                <label htmlFor="Botuesi">Botuesi (260/b)</label>
                <Select
                  className={"basic-select"}
                  classNamePrefix={
                    "select_input" +
                    (this.state.error_list.publisher_id ? " form-error" : "")
                  }
                  name="publisher_id"
                  placeholder="Përzgjidh"
                  onChange={(e) => this.handleChangeSelect(e, "publisher_id")}
                  isSearchable={true}
                  options={publisher}
                  isClearable={true}
                  menuPlacement="auto"
                />
                {this.state.error_list.publisher_id && (
                  <span className="error-message left">
                    {this.state.error_list.publisher_id[0]}
                  </span>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-12 mb-4 pb-4 autor">
                <button
                  type="button"
                  className="modal-button"
                  onClick={this.addContributorPopup}
                >
                  Shto personat përgjegjës
                </button>{" "}
                <label htmlFor="Personat përgjegjës" style={{ width: "20px" }}>
                  (700/a)
                </label>
                <AuthorModal
                  types={this.state.contributor_types}
                  authors={this.state.contributors}
                  showPopup={this.state.addContributorPopup}
                  onPopupClose={this.closeContributorPopup}
                  parentCallback={this.handleCallbackContributor}
                  popupName={"Personat përgjegjës"}
                />
                <div className="tage w-100 borderi kontributor-tags-container tag-container-fake-input">
                  {selectedContributorsListName}
                </div>
              </div>
            </div>

            <div className="row align-items-end">
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="Ilustrime">Ilustrime</label>
                <input
                  type="text"
                  name="physical_details"
                  placeholder="Ilustrime"
                  className={
                    "input-form-input" +
                    (this.state.error_list.physical_details
                      ? " form-error"
                      : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.physical_details && (
                  <span className="error-message left">
                    {this.state.error_list.physical_details[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="Numri i faqeve">Numri i faqeve (300/a)</label>
                <input
                  type="text"
                  name="pages"
                  placeholder="Numri i faqeve"
                  className={
                    "input-form-input" +
                    (this.state.error_list.pages ? " form-error" : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.pages && (
                  <span className="error-message left">
                    {this.state.error_list.pages[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="Përmasat">Përmasat (300/c)</label>
                <input
                  type="text"
                  name="dimensions"
                  placeholder="Përmasat"
                  className={
                    "input-form-input" +
                    (this.state.error_list.dimensions ? " form-error" : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.dimensions && (
                  <span className="error-message left">
                    {this.state.error_list.dimensions[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="Lccn">Lccn (010/a)</label>
                <input
                  type="text"
                  name="lccn"
                  placeholder="Lccn"
                  className={
                    "input-form-input" +
                    (this.state.error_list.lccn ? " form-error" : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.lccn && (
                  <span className="error-message left">
                    {this.state.error_list.lccn[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="Shfletuesi">Shfletuesi</label>
                {this.state.url === null ? (
                  <div className="file_upload_div">
                    <input
                      placeholder="Shfletuesi"
                      className={
                        "file_upload_input" +
                        (this.state.error_list.url ? " form-error" : "")
                      }
                      accept="application/pdf"
                      onChange={(e) =>
                        this.fileSelectHandler(e, "url", "temporary_urlpath")
                      }
                      name="url"
                      type="file"
                      title=""
                    />
                    <div className="upload-button">+ Ngarko shfletuesin</div>
                  </div>
                ) : (
                  <div className="uploaded_file_div">
                    <div className="uploaded_file">{this.state.url.name}</div>
                    <button
                      type="button"
                      onClick={(e) =>
                        this.removeImage(e, "url", "temporary_urlpath")
                      }
                      className="remove-image btn-unstyled"
                    >
                      ✕
                    </button>
                  </div>
                )}
                {this.state.error_list.url && (
                  <span className="error-message left">
                    {this.state.error_list.url[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="kdu">Kdu (080/a)</label>
                <input
                  type="text"
                  name="kdu"
                  placeholder="Kdu"
                  className={
                    "input-form-input" +
                    (this.state.error_list.kdu ? " form-error" : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.kdu && (
                  <span className="error-message left">
                    {this.state.error_list.kdu[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="Gjuha">Gjuha (041/a)</label>
                <Select
                  className={"basic-select"}
                  classNamePrefix={
                    "select_input" +
                    (this.state.error_list.language_id ? " form-error" : "")
                  }
                  name="language_id"
                  placeholder="Përzgjidh"
                  onChange={(e) => this.handleChangeSelect(e, "language_id")}
                  isSearchable={true}
                  isClearable={true}
                  menuPlacement="auto"
                  options={languages}
                />
                {this.state.error_list.language_id && (
                  <span className="error-message left">
                    {this.state.error_list.language_id[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="Vëllimi">Vëllimi (250/a)</label>
                <input
                  type="text"
                  name="publication_number"
                  placeholder="Vëllimi"
                  className={
                    "input-form-input" +
                    (this.state.error_list.publication_number
                      ? " form-error"
                      : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.publication_number && (
                  <span className="error-message left">
                    {this.state.error_list.publication_number[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="Nr kontrollit">
                  Nr. i kontrollit kombëtar (016/a)
                </label>
                <input
                  type="text"
                  name="national_control_number"
                  placeholder="Numri i kontrollit kombëtar"
                  className={
                    "input-form-input" +
                    (this.state.error_list.national_control_number
                      ? " form-error"
                      : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.national_control_number && (
                  <span className="error-message left">
                    {this.state.error_list.national_control_number[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="Barkod">Barkod</label>
                <input
                  type="text"
                  name="barcode"
                  placeholder="Barkod"
                  className={
                    "input-form-input" +
                    (this.state.error_list.barcode ? " form-error" : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.barcode && (
                  <span className="error-message left">
                    {this.state.error_list.barcode[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="Çmimi">Çmimi (Lekë) (365/b)</label>
                <input
                  type="number"
                  name="price"
                  placeholder="Çmimi"
                  className={
                    "input-form-input" +
                    (this.state.error_list.price ? " form-error" : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.price && (
                  <span className="error-message left">
                    {this.state.error_list.price[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="Cmimi zevendesues">
                  Çmimi zëvendësues (Lekë)
                </label>
                <input
                  type="number"
                  name="replacement_price"
                  placeholder="Çmimi zëvëndësues"
                  className={
                    "input-form-input" +
                    (this.state.error_list.replacement_price
                      ? " form-error"
                      : "")
                  }
                  onChange={this.handleChangeInput}
                />
                {this.state.error_list.replacement_price && (
                  <span className="error-message left">
                    {this.state.error_list.replacement_price[0]}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                <label htmlFor="Kopertinë">Kopertinë</label>

                {this.state.cover === null ? (
                  <div className="file_upload_div">
                    <input
                      placeholder="Kopertinë"
                      className={
                        "file_upload_input" +
                        (this.state.error_list.cover ? " form-error" : "")
                      }
                      accept="image/*"
                      onChange={(e) =>
                        this.fileSelectHandler(
                          e,
                          "cover",
                          "temporary_coverpath"
                        )
                      }
                      name="cover"
                      type="file"
                      title=""
                    />
                    <div className="upload-button">+ Ngarko kopertinë</div>
                  </div>
                ) : (
                  <div className="uploaded_file_div">
                    <div className="uploaded_file">{this.state.cover.name}</div>
                    <button
                      type="button"
                      onClick={(e) =>
                        this.removeImage(e, "cover", "temporary_coverpath")
                      }
                      className="remove-image btn-unstyled"
                    >
                      ✕
                    </button>
                  </div>
                )}
                {this.state.error_list.cover && (
                  <span className="error-message left">
                    {this.state.error_list.cover[0]}
                  </span>
                )}
              </div>
            </div>
            <div className="row m-0">
              {this.state.btnLoading === true ? (
                <button className="btn-styled-2 ml-auto mb-5 px-5">
                  Duke u Ruajtur
                  <img
                    src={LoadingGif}
                    style={{
                      width: "18px",
                      margin: "-4px 0 0 7px",
                    }}
                  />
                </button>
              ) : (
                <button
                  className="btn-styled-2 ml-auto mb-5 px-5"
                  onClick={(e) => this.biblioitem(e, "forward")}
                >
                  Ruaj
                </button>
              )}
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(biblioitem);
