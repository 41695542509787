import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/opac/partials/Header";
import Footer from "../../components/opac/partials/Footer";
import SearchResults from "../../components/opac/SearchResults";


class SearchResultsView extends Component {

    constructor() {
        super();
        this.state={
            materialtype:'',
            materialName:'',
            searchtext:'',
        }
    }

    //get props from child (Header)
    getProps = (search_text,material_type,materialName) => {
        this.setState({
            searchtext:search_text,
            materialtype:material_type,
            materialName:materialName
        });
    }


  render() {

        //send this values to child  (SearchResults)
        var simpleSearchPropsValues = {
            'materialtype':this.state.materialtype,
            'materialName':this.state.materialName,
            'searchtext':this.state.searchtext
        };

    return (
      <Fragment>
        <Helmet>
          <title>Rezultatet e kërkimit</title>
        </Helmet>
        <div style={{ width: 100 + "%" }}>
          <Header
            roleUser={this.props.roles}
            search={true}
            simplePropsView={this.getProps}
          />
          <SearchResults simpleSearchProps={simpleSearchPropsValues}/>
          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default SearchResultsView;
