import React, { Component, Fragment } from "react";
import Select from "react-select";
import Paginate from "../../../../helpers/Paginate";
import Url from "../../../../api/Url";
import Request from "../../../../api/Request";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import noResult from "../../../../assets/images/icons/no_result.svg";
import SearchFilter from "../../../../helpers/SearchFilter";
import Pagination from "react-js-pagination";
import TooltipComp from "../../../../helpers/Tooltip";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
class Bibliography extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listBiblioitem: {
        biblioitems: [{}],
        loading: true,
      },
      filters: {
        materialtypes: [],
        authors: [],
        publishers: [],
        keywords: [{}],
        geoterms: [],
        languages: [],
      },
      search: {
        materialtype: "",
        author: "",
        publisher: "",
        keyword: "",
        geoterm: "",
        year: "",
        language: "",
      },
      searched: false,
      errors: {
        list: [],
      },
      btnLoading: false,
      btnAddLoading: false,
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.listBiblioitems();
  }

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.search,
      [key]: selectValue,
    };
    this.setState({
      search: newForm,
    });
  };

  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.search,
      [key]: e.target.value,
    };
    this.setState({
      search: newForm,
    });
  };

  listBiblioitems = (event) => {
    let request = Url.reportBibliography;

    if (event) {
      event.preventDefault();
      const searchParams = {
        materialtype: this.state.search.materialtype,
        author: this.state.search.author,
        publisher: this.state.search.publisher,
        keyword: this.state.search.keyword,
        geographicalterm: this.state.search.geoterm,
        year: this.state.search.year,
        language: this.state.search.language,
        bibliography: "",
      };
      this.setState({ searched: true });
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({ btnLoading: true });
    }

    Request.getRequest(request).then((result) => {
      this.setState({ btnLoading: false });
      if (result.status === 200) {
        this.setState({
          listBiblioitem: {
            biblioitems: result.data.biblioitems.data,
            loading: false,
          },
          filters: {
            materialtypes: result.data.materialtypes,
            authors: result.data.authors,
            publishers: result.data.publishers,
            keywords: result.data.keywords,
            geoterms: result.data.geographicalterms,
            languages: result.data.languages,
          },
          search: {
            ...this.state.search,
          },
          errors: {
            list: [],
          },
          pagination: Paginate.setPaginateData(result.data.biblioitems),
        });
      } else if (result.status === 422) {
        this.setState({
          errors: {
            list: result.data,
          },
          searched: false,
        });
      } else {
        this.setState({
          listBiblioitem: {
            biblioitems: [{}],
            loading: true,
          },
          searched: false,
        });
      }
    });
  };

  handlePageChange(pageNumber) {
    let request = Url.reportBibliography;
    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        materialtype: this.state.search.materialtype,
        author: this.state.search.author,
        publisher: this.state.search.publisher,
        keyword: this.state.search.keyword,
        geographicalterm: this.state.search.geoterm,
        year: this.state.search.year,
        bibliography: "",
        page: pageNumber,
      };
    }
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listBiblioitem: {
          biblioitems: response.data.biblioitems.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.biblioitems),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  download(e) {
    e.preventDefault();
    this.setState({ btnAddLoading: true });
    let request = Url.exportCatalog;

    var searchParams = {
      materialtype: this.state.search.materialtype,
      author: this.state.search.author,
      publisher: this.state.search.publisher,
      keyword: this.state.search.keyword,
      geographicalterm: this.state.search.geoterm,
      year: this.state.search.year,
      language: this.state.search.language,
      export_catalog: "",
    };

    request = SearchFilter.setFilterParams(searchParams, request);

    const link = document.createElement("a");
    Request.getRequestForDownloadExcel(request).then((response) => {
      this.setState({ btnAddLoading: false });
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.setAttribute("download", "ReportCatalog.xlsx");
      link.click();
    });
  }

  render() {
    var biblioitemsList = "";
    if (this.state.listBiblioitem.loading) {
      biblioitemsList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      var keywords = this.state.filters.keywords.map((item) => {
        return { value: item.id, label: item.name };
      });

      var materialtypes = this.state.filters.materialtypes.map((item) => {
        return { value: item.id, label: item.name };
      });

      var publishers = this.state.filters.publishers.map((item) => {
        return { value: item.id, label: item.name };
      });

      var authors = this.state.filters.authors.map((item) => {
        return { value: item.id, label: item.name };
      });

      var geoterms = this.state.filters.geoterms.map((item) => {
        return { value: item.id, label: item.name };
      });

      var languages = this.state.filters.languages.map((item) => {
        return { value: item.id, label: item.name };
      });

      biblioitemsList = this.state.listBiblioitem.biblioitems.map(
        (item, index) => {
          return (
            <tr key={item.id}>
              <td
                role="cell"
                data-label="LLoji i Materialit"
                className="title-borrowing"
              >
                {item.biblio.materialtype.name}
              </td>
              <td
                role="cell"
                data-label="Titull/ Autor"
                className="title-borrowing text-center"
              >
                {TooltipComp.tooltipDisabledMobile(
                  "-title-" + index,
                  item.biblio.title
                )}
                <br />
                {/* autoret */}
                {item.biblio["authors"].map((item, index, arr) => {
                  if (index === arr.length - 1) {
                    return item["name"];
                  } else {
                    return item["name"] + "; ";
                  }
                })}
              </td>
              <td role="cell" data-label="Botuesi" className="text-center">
                {item.publisher ? item.publisher.name : ""}
              </td>
              <td
                role="cell"
                data-label="Viti i botimit"
                className="text-center"
              >
                {item.publication_year}
              </td>
              <td
                role="cell"
                data-label="Nr. i kopjeve"
                className="text-center"
              >
                {item.items_count}
              </td>
              <td role="cell" data-label="Issn/Isbn" className="text-center">
                {item.isbn ? item.isbn : "-"} / {item.issn ? item.issn : "-"}
              </td>
              <td
                role="cell"
                data-label="Personat përgjegjës"
                className="text-center"
              >
                {item["contributors"].map((item, index, arr) => {
                  if (index === arr.length - 1) {
                    return item["name"];
                  } else {
                    return item["name"] + "; ";
                  }
                })}
              </td>
            </tr>
          );
        }
      );
    }
    return (
      <Fragment>
        <div id="report-catalog" className="table-detail-tabs mb-5">
          <div className="container-fluid small-offset-left">
            <div id="list-of-members">
              <div
                id="bibliografi"
                role="tabpanel"
                aria-labelledby="bibliografi-tab"
              >
                <form onSubmit={this.listBiblioitems}>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 pb-4">
                      <label className="label-paragraph">
                        Lloji i Materialit
                      </label>
                      <Select
                        className={
                          "basic-select" +
                          (this.state.errors.list.materialtype
                            ? " form-error"
                            : "")
                        }
                        classNamePrefix="select_input"
                        name="materialtype"
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Lloji i materialit"
                        options={materialtypes}
                        onChange={this.handleChangeSelect("materialtype")}
                      />
                      {this.state.errors.list.materialtype && (
                        <span className="error-message left">
                          {this.state.errors.list.materialtype[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 pb-4">
                      <label className="label-paragraph">Autori</label>
                      <Select
                        className={
                          "basic-select" +
                          (this.state.errors.list.author ? " form-error" : "")
                        }
                        classNamePrefix="select_input"
                        name="author"
                        isSearchable={true}
                        placeholder="Autori"
                        isClearable={true}
                        options={authors}
                        onChange={this.handleChangeSelect("author")}
                      />
                      {this.state.errors.list.author && (
                        <span className="error-message left">
                          {this.state.errors.list.author[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 pb-4">
                      <label className="label-paragraph">Botuesi</label>
                      <Select
                        className={
                          "basic-select" +
                          (this.state.errors.list.publisher
                            ? " form-error"
                            : "")
                        }
                        classNamePrefix="select_input"
                        name="publisher"
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Botuesi"
                        options={publishers}
                        onChange={this.handleChangeSelect("publisher")}
                      />
                      {this.state.errors.list.publisher && (
                        <span className="error-message left">
                          {this.state.errors.list.publisher[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 pb-4">
                      <label className="label-paragraph">Fjalë Kyçe</label>
                      <Select
                        className={
                          "basic-select" +
                          (this.state.errors.list.keyword ? " form-error" : "")
                        }
                        classNamePrefix="select_input"
                        name="keyword"
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Fjalë Kyçe"
                        options={keywords}
                        onChange={this.handleChangeSelect("keyword")}
                      />
                      {this.state.errors.list.keyword && (
                        <span className="error-message left">
                          {this.state.errors.list.keyword[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 pb-4">
                      <label className="label-paragraph">
                        Terma Gjeografik
                      </label>
                      <Select
                        className={
                          "basic-select" +
                          (this.state.errors.list.geographicalterm
                            ? " form-error"
                            : "")
                        }
                        classNamePrefix="select_input"
                        name="geoterm"
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Terma Gjeografik"
                        options={geoterms}
                        onChange={this.handleChangeSelect("geoterm")}
                      />
                      {this.state.errors.list.geographicalterm && (
                        <span className="error-message left">
                          {this.state.errors.list.geographicalterm[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 pb-4">
                      <label className="label-paragraph">Viti i Botimit</label>
                      <input
                        id="year"
                        className={
                          "input-form-input" +
                          (this.state.errors.list.year ? " form-error" : "")
                        }
                        placeholder="Shkruaj vitin"
                        name="year"
                        type="number"
                        onChange={this.handleChange("year")}
                      />
                      {this.state.errors.list.year && (
                        <span className="error-message left">
                          {this.state.errors.list.year[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 pb-4">
                      <label className="label-paragraph">Gjuha e botimit</label>
                      <Select
                        className={
                          "basic-select" +
                          (this.state.errors.list.language ? " form-error" : "")
                        }
                        classNamePrefix="select_input"
                        name="language"
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Gjuha e botimit"
                        options={languages}
                        onChange={this.handleChangeSelect("language")}
                      />
                      {this.state.errors.list.language && (
                        <span className="error-message left">
                          {this.state.errors.list.language[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-md-12 mb-4 mt-3 d-flex flex-wrap">
                      {this.state.btnLoading === true ? (
                        <button
                          id="filtro"
                          className="btn-styled-2 ml-0 mt-0 mb-3 px-5  mr-5"
                          name="bibliography"
                          type="button"
                        >
                          Duke Kërkuar
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                        </button>
                      ) : (
                        <button
                          id="filtro"
                          className="btn-styled-2 ml-0 mt-0 mb-3 px-5  mr-5"
                          name="bibliography"
                          type="submit"
                        >
                          Kërko
                        </button>
                      )}
                      {this.state.btnAddLoading === true ? (
                        <button
                          id="export_catalog"
                          className="btn-styled-2 ml-0 mt-0 mb-3 px-5  mr-5 export-bibliography"
                          name="export_catalog"
                          type="button"
                        >
                          Duke Eksportuar
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                        </button>
                      ) : (
                        <button
                          id="export_catalog"
                          className="btn-styled-2 ml-0 mt-0 mb-3 px-5  mr-5 export-bibliography"
                          name="export_catalog"
                          type="button"
                          onClick={(e) => this.download(e)}
                        >
                          Eksporto
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div className="table-list">
                <table role="table" className="w-100">
                  <thead role="rowgroup">
                    <tr role="row">
                      <th role="columnheader" className="text-left">
                        Lloji i Materialit
                      </th>
                      <th role="columnheader" className="text-center">
                        Titull/ Autor
                      </th>
                      <th role="columnheader" className="text-center">
                        Botues
                      </th>
                      <th role="columnheader" className="text-center">
                        Viti i botimit
                      </th>
                      <th role="columnheader" className="text-center">
                        Nr. i kopjeve
                      </th>
                      <th role="columnheader" className="text-center">
                        Isbn/Issn
                      </th>
                      <th role="columnheader" className="text-center">
                        Personat përgjegjës
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">{biblioitemsList}</tbody>
                </table>
              </div>
              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listBiblioitem.loading === false &&
                this.state.searched === true && (
                  <img
                    className="mx-auto d-block"
                    src={noResult}
                    alt="no result"
                  />
                )}

              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listBiblioitem.loading === false &&
                this.state.searched === false && (
                  <p className="nothing--to--show">Asgjë për të shfaqur</p>
                )}
            </div>
          </div>
          {this.state.pagination.totalPages > 1 && (
            <div className="d-flex justify-content-center flex-wrap pagination-block mt-4 mb-5">
              <div className="select-page">
                <Pagination
                  activePage={this.state.pagination.activePage}
                  itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                  totalItemsCount={this.state.pagination.totalItemsCount}
                  pageRangeDisplayed={this.state.pagination.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="pagination-results">
                Faqja:
                {this.state.pagination.activePage} -{" "}
                {this.state.pagination.totalPages}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default Bibliography;
