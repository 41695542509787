import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import Url from "../../../../../api/Url";
import Request from "../../../../../api/Request";
import Messages from "../../../../../helpers/Messages";
import AppConfig from "../../../../../services/AppConfig";
import Description from "./Description";
import Isbd from "./Isbd";
import MarcInfo from "./MarcInfo";
import xIcon from "../../../../../assets/images/icons/xIcon.svg";
import Accept from "../../../../../assets/images/icons/accept.svg";
import Marc from "./MarcInfo";
import moment from "moment";
import libra from "../../../../../assets/images/covers/Libra.svg";
import Disertacione from "../../../../../assets/images/covers/Disertacione.svg";
import Periodike from "../../../../../assets/images/covers/Periodike.svg";
import Harta from "../../../../../assets/images/covers/Harta.svg";
import Doreshkrime from "../../../../../assets/images/covers/Doreshkrime.svg";
import Dokument from "../../../../../assets/images/covers/Dokument.svg";
import Artikuj from "../../../../../assets/images/covers/Artikuj.svg";
import Materiale_audio_vizuale from "../../../../../assets/images/covers/Materiale_audio-vizuale.svg";
import loadingGif from "../../../../../assets/images/icons/loading.gif";
import printIcon from "../../../../../assets/images/icons/172530_print_icon.svg";
class ShowBiblioitem extends Component {
  constructor() {
    super();
    this.state = {
      showBiblioitem: {
        biblioitem: "",
        biblio: "",
        authors: [],
        materialtype: "",
        items: [],
        loading: true,
      },
      tab: {
        selected: "description",
      },
    };
  }

  componentDidMount() {
    this.biblioitemShow();
  }

  biblioitemShow = () => {
    const biblioitemID = this.props.match.params.id;
    Request.getRequest(Url.showBiblioitem + biblioitemID).then((response) => {
      if (response.status === 200) {
        this.setState({
          showBiblioitem: {
            biblioitem: response.data.biblioitem,
            biblio: response.data.biblioitem.biblio,
            items: response.data.biblioitem.items,
            authors: response.data.biblioitem.biblio.authors,
            materialtype: response.data.biblioitem.biblio.materialtype,
            loading: false,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  descriptionTab = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        selected: "description",
      },
    });
  };

  isbdTab = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        selected: "isbd",
      },
    });
  };

  marcTab = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        selected: "marc",
      },
    });
  };

  damagedData = (damaged) => {
    if (damaged === 0) {
      return xIcon;
    } else {
      return Accept;
    }
  };

  lostData = (lost) => {
    if (lost === 0) {
      return xIcon;
    } else {
      return Accept;
    }
  };

  checkCover = (cover, material_type) => {
    if (cover !== null) {
      return AppConfig.biblioCoversPath + cover;
    }
    if (cover === null) {
      switch (material_type) {
        case 2:
          return libra;
        case 6:
          return Disertacione;
        case 3:
          return Periodike;
        case 5:
          return Harta;
        case 4:
          return Doreshkrime;
        case 8:
          return Dokument;
        case 1:
          return Artikuj;
        case 7:
          return Materiale_audio_vizuale;
        default:
          return "";
      }
    }
  };

  checkBorrownigType = (borrowingType) => {
    switch (borrowingType) {
      case "salle":
        return "Sallë";
      case "normal":
        return "Normal";
      case "ipadisponueshem":
        return "I padisponueshëm";
      default:
        return "";
    }
  };

  render() {
    const biblioitem = this.state.showBiblioitem.biblioitem;
    const biblio = this.state.showBiblioitem.biblio;
    var items = this.state.showBiblioitem.items;
    const materialType = this.state.showBiblioitem.materialtype.name;
    const Authors = this.state.showBiblioitem.authors.map((author) => {
      return author.name;
    });
    var itemsData = items.map((item, index) => {
      return (
        <div
          key={index}
          className="col-12 col-sm-8 ml-0 ml-sm-5 info-acc mt-4 align-content-center"
        >
          <div className="mb-4">
            <div className="row item-attributes-summary mb-3">
              <div className="col-12">
                <div className="row pt-3">
                  <div className="col-12 col-sm-10 col-lg-4 col-xl-4 col-md-8">
                    <span>Kodifikimi: </span>
                    <span>{item.inventory_number}</span>
                  </div>
                  {AppConfig.getRole() !== "Administrator" && (
                    <div className="col-12 col-sm-10 col-lg-4 col-xl-4 col-md-8">
                      <span>Lloji i Huazimit: </span>
                      {item.borrowing_type
                        ? this.checkBorrownigType(item.borrowing_type)
                        : "-"}
                    </div>
                  )}
                  {AppConfig.getRole() === "Administrator" && (
                    <Fragment>
                      <div className="col-12 col-sm-10 col-md-8 col-lg-3 col-xl-3 offset-lg-1 offset-xl-1 ">
                        <span>
                          <img src={this.lostData(item.lost)} />
                        </span>
                        <span> Spastruar</span>
                      </div>
                      <div className="col-12 col-sm-10 col-lg-4 col-xl-4 col-md-8">
                        <span>Datë Spastrimi: </span>
                        <span>
                          {item.lost_date != null ? item.lost_date : null}
                        </span>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-sm-10 col-lg-4 col-xl-4 col-md-10">
                    <span>Nr.Vendi: </span>
                    <span>{item.place_number}</span>
                  </div>
                  {AppConfig.getRole() !== "Administrator" && (
                    <div className="col-12 col-sm-10 col-lg-4 col-xl-4 col-md-8">
                      <span>Numri i inventarit: </span>
                      {item.id}
                    </div>
                  )}
                  {AppConfig.getRole() === "Administrator" && (
                    <Fragment>
                      <div className="col-12 col-sm-10 col-md-10 col-lg-3 col-xl-3 offset-lg-1 offset-xl-1 ">
                        <span>
                          <img src={this.damagedData(item.damaged)} />
                        </span>
                        <span>Dëmtuar</span>
                      </div>
                      <div className="col-12 col-sm-10 col-lg-4 col-xl-4 col-md-10 ">
                        <span>Datë Dëmtimi: </span>
                        <span>
                          {item.damaged_date != null
                            ? moment(item.damaged_date).format("DD-MM-yyyy")
                            : null}
                        </span>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="row pb-0">
                  {AppConfig.getRole() === "Administrator" && (
                    <div className="col-12 col-sm-10 col-lg-4 col-xl-4 col-md-8">
                      <span>Lloji i Huazimit: </span>
                      {item.borrowing_type
                        ? this.checkBorrownigType(item.borrowing_type)
                        : "-"}
                    </div>
                  )}
                  {AppConfig.getRole() === "Administrator" && (
                    <div className="col-12 col-sm-10 col-lg-4 col-xl-4 col-md-8 offset-lg-4 offset-xl-4">
                      <span>Datë Rinovimi: </span>
                      <span>
                        {item.renewal_date != null
                          ? moment(item.renewal_date).format("DD-MM-yyyy")
                          : null}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="row pb-3">
                  {AppConfig.getRole() === "Administrator" && (
                    <div className="col-12 col-sm-10 col-lg-4 col-xl-4 col-md-8">
                      <span>Numri i inventarit: </span>
                      {item.id}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 pr-0 text-right">
                <a
                  href={
                    AppConfig.apiBaseUrl +
                    "catalog/item/generate-location/" +
                    item.id
                  }
                  target="_blank"
                  className="mr-3 btn-unstyled"
                >
                  <img width={25} height={25} src={printIcon} />
                </a>
                {item.borrowed == 0 && (
                  <span className="item-available pl-2 pr-2">E lirë</span>
                )}
                {item.borrowed == 1 && (
                  <span className="item-borrowed pl-2 pr-2">E huazuar</span>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
    var data = "";
    var cover = "";
    if (this.state.showBiblioitem.loading === true) {
      data = <img className="ml-5 d-block" src={loadingGif} alt="loading" />;
    } else {
      data = (
        <Fragment>
          <div className="col-12 mb-2 material-type">{materialType}</div>
          <div className="col-12 col-md-10 mb-2 material-title">
            {biblio.title +
              (biblio.subtitle !== null ? " : " + biblio.subtitle : "")}
          </div>
          <div className="col-12 material-author">{Authors.join("; ")}</div>
        </Fragment>
      );

      cover = (
        <div className="col-12 mb-4 d-block d-md-none image-holder text-center">
          <img
            src={this.checkCover(
              this.state.showBiblioitem.biblioitem.cover,
              this.state.showBiblioitem.materialtype.id
            )}
            alt="cover"
          />
        </div>
      );
    }
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="biblio-show" className="table-detail-tabs">
            <p className="partial-title mb-5">Shfaq Bibliografi</p>
            <div className="d-flex flex-column ml-md-5 ml-lg-0 flex-md-row align-items-center">
              <div className="row col-12 p-0 detailed-biblio-infohead">
                {data}
              </div>
            </div>
            {cover}
            <div className="mt-4 pt-4">
              <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                <li className="nav-item col-12 col-md-4 p-0">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="description"
                    aria-selected="true"
                    href="#"
                    onClick={(e) => this.descriptionTab(e)}
                  >
                    Pershkrimi
                  </a>
                </li>
                <li className="nav-item col-12 col-md-4 p-0">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="isbd"
                    aria-selected="true"
                    onClick={(e) => this.isbdTab(e)}
                  >
                    ISBD
                  </a>
                </li>
                <li className="nav-item col-12 col-md-4 p-0">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="marc"
                    aria-selected="true"
                    onClick={(e) => this.marcTab(e)}
                  >
                    Marc
                  </a>
                </li>
              </ul>
            </div>
            {this.state.tab.selected === "description" && (
              <Description></Description>
            )}
            {this.state.tab.selected === "isbd" && <Isbd></Isbd>}
            {this.state.tab.selected === "marc" && <Marc></Marc>}
          </div>
          {items.length !== 0 && (
            <div className="specimen-data mb-5 detailed-table1">
              <div className="py-4 detailed-biblio-infohead h-table">
                <div className="col-12 material-title">
                  Te dhëna për ekzemplarin
                </div>
              </div>

              {itemsData}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ShowBiblioitem);
