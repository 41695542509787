import React, { Component, Fragment } from "react";
import Request from "../../../api/Request";
import Url from "../../../api/Url";
import Pagination from "react-js-pagination";
import deleteIcon from "../../../assets/images/icons/delete_icon.svg";
import downloadIcon from "../../../assets/images/icons/download-icon.svg";
import loadingGif from "../../../assets/images/icons/loading.gif";
import dateFormat from "dateformat";
import Messages from "../../../helpers/Messages";
import Paginate from "../../../helpers/Paginate";
import SearchFilter from "../../../helpers/SearchFilter";
import LoadingGif from "../../../assets/images/icons/loading.gif";

class IndexBackup extends Component {
  constructor() {
    super();
    this.state = {
      listBackup: {
        backups: [],
        loading: true,
      },
      downloadLoading: false,
      pagination: Paginate.setPaginate(),
    };
  }

  componentDidMount() {
    Request.getRequest(Url.listbackups).then((result) => {
      if (result.status === 200) {
        this.setState({
          listBackup: {
            backups: result.data.backups.data,
            loading: false,
          },
          pagination: Paginate.setPaginateData(result.data.backups),
        });
      } else {
        this.setState({
          listBackup: {
            backups: [{}],
            loading: true,
          },
        });
      }
    });
  }

  handlePageChange = (pageNumber) => {
    let request = Url.listbackups;
    var searchParams = {
      page: pageNumber,
    };
    request = SearchFilter.setFilterParams(searchParams, request);
    Request.getRequest(request).then((result) => {
      this.setState({
        listBackup: {
          backups: result.data.backups.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(result.data.backups),
      });
    });

    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  gjeneroBackup(e) {
    e.preventDefault();
    this.setState({
      downloadLoading: true,
    });
    Request.getRequest(Url.listbackups + "?backup_now=").then((response) => {
      this.setState({
        downloadLoading: false,
      });
      if (response.status === 201) {
        Request.getRequest(Url.listbackups).then((result) => {
          this.setState({
            listBackup: {
              backups: result.data.backups.data,
              loading: false,
            },
            pagination: Paginate.setPaginateData(result.data.backups),
          });
        });
        Messages.swalSuccess(response.data.success);
      }
    });
  }

  download(e, id, name) {
    const link = document.createElement("a");
    Request.getRequestForDownload(Url.listbackups + "?download=" + id).then(
      (response) => {
        link.href = URL.createObjectURL(
          new Blob([response.data], { type: "text/sql" })
        );
        link.setAttribute("download", name + ".sql");
        link.click();
      }
    );
  }

  deleteBackup = (e, id, name) => {
    Messages.swalConfirmDelete(name).then((result) => {
      if (result.isConfirmed) {
        Request.deleteRequest(Url.delteBackup + id).then((response) => {
          if (response.status === 200) {
            Request.getRequest(Url.listbackups).then((result) => {
              this.setState({
                listBackup: {
                  backups: result.data.backups.data,
                  loading: false,
                },
                pagination: Paginate.setPaginateData(result.data.backups),
              });
            });
            Messages.swalSuccess(response.data.success);
          } else if (response.status === 405) {
            Messages.swalError(response.data.error);
          } else if (response.status === 404) {
            Messages.swalError(response.data.error);
          }
        });
      }
    });
  };

  render() {
    var Backuplist = "";
    if (this.state.listBackup.loading) {
      Backuplist = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      Backuplist = this.state.listBackup.backups.map((backup) => {
        return (
          <tr key={backup.id}>
            <td role="cell" data-label="Emri i backup">
              {backup.name}
            </td>
            <td role="cell" data-label="Data" className="text-center">
              {dateFormat(backup.created_at, "d-mm-yyyy")}
            </td>
            <td role="cell" data-label="Shkarko" className="text-center">
              <button
                type="button"
                onClick={(e) => this.download(e, backup.id, backup.name)}
                className="btn-unstyled"
              >
                <img src={downloadIcon} alt="Delete Icon" />
              </button>
            </td>
            <td role="cell" data-label="Fshi" className="text-center">
              <button
                type="button"
                onClick={(e) => this.deleteBackup(e, backup.id, backup.name)}
                className="btn-unstyled"
              >
                <img src={deleteIcon} alt="Delete Icon" />
              </button>
            </td>
          </tr>
        );
      });
    }

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div className="backup">
            <p className="partial-title mb-5">Backup</p>
            <div className="mb-5 download-backup">
              {this.state.downloadLoading === true ? (
                <button
                  className="btn-styled-2 ml-0"
                >
                  Duke Gjeneruar
                  <img
                    src={LoadingGif}
                    style={{
                      width: "18px",
                      margin: "-4px 0 0 7px",
                    }}
                  />
                </button>
              ) : (
                <button
                  className="btn-styled-2 ml-0"
                  onClick={(e) => this.gjeneroBackup(e)}
                >
                 Gjenero Backup
                </button>
              )}
            </div>
            <div className="table-list mb-5">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th role="columnheader" width="25%" className="text-left">
                      Emri i backup
                    </th>
                    <th role="columnheader" width="25%" className="text-center">
                      Data
                    </th>
                    <th role="columnheader" width="25%" className="text-center">
                      Shkarko
                    </th>
                    <th role="columnheader" width="25%" className="text-center">
                      Fshij
                    </th>
                  </tr>
                </thead>
                <tbody>{Backuplist}</tbody>
              </table>
            </div>
          </div>
          {this.state.pagination.totalPages > 1 && (
            <div className="d-flex justify-content-center pagination-block mb-5">
              <div className="select-page">
                <Pagination
                  activePage={this.state.pagination.activePage}
                  itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                  totalItemsCount={this.state.pagination.totalItemsCount}
                  pageRangeDisplayed={this.state.pagination.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="pagination-results">
                Faqja: {this.state.pagination.activePage} -{" "}
                {this.state.pagination.totalPages}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default IndexBackup;
