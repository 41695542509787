import React, { Component, Fragment } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import "../../assets/css/frontend.css";
import Url from "../../api/Url";
import SearchFilter from "../../helpers/SearchFilter";
import Request from "../../api/Request";
import Paginate from "../../helpers/Paginate";
import Messages from "../../helpers/Messages";
import AppConfig from "../../services/AppConfig";
import loadingGif from "../../assets/images/icons/loading.gif";
import Pagination from "react-js-pagination";
import gridViewIcon from "../../assets/images/icons/grid-view-icon.svg";
import listViewIcon from "../../assets/images/icons/list-view-icon.svg";
import toggleSidebarIcon from "../../assets/images/icons/toggle-sidebar-icon.svg";
import libra from "../../assets/images/covers/Libra.svg";
import Disertacione from "../../assets/images/covers/Disertacione.svg";
import Periodike from "../../assets/images/covers/Periodike.svg";
import Harta from "../../assets/images/covers/Harta.svg";
import Doreshkrime from "../../assets/images/covers/Doreshkrime.svg";
import Dokument from "../../assets/images/covers/Dokument.svg";
import Artikuj from "../../assets/images/covers/Artikuj.svg";
import Materiale_audio_vizuale from "../../assets/images/covers/Materiale_audio-vizuale.svg";
import Web from "../../routes/Web";
import FilterSidebar from "./partials/FilterSidebar";
import noResult from "../../assets/images/icons/no_result.svg";

class SearchResults extends Component {
  componentDidMount() {
    if (!this.props.location.state.page) {
      this.props.history.push({
        state: {
          ...this.props.location.state,
          page: 0,
        },
      });
    }

    this.getBiblioitemsSimpleSearch();

    if (localStorage.getItem("grid_view") != null) {
      this.setState({
        books_view: {
          grid_view: true,
          list_view: false,
        },
      });
    } else if (localStorage.getItem("list_view") != null) {
      this.setState({
        books_view: {
          grid_view: false,
          list_view: true,
        },
      });
    }
  }

  constructor(props) {
    super(props);
    if (!this.props.location.state) {
      window.location.href = Web.homepage;
    }
    this.state = {
      simpleSearch: {
        materialtype: this.props.location.state.materialtype,
        materialName: this.props.location.state.material_name,
        searchtext: this.props.location.state.searchtext,
      },
      filterProps: {
        author: "",
        viti_i_botimit: "",
        vendi_i_botimit: "",
        gjuha: "",
        botuesi: "",
        fjalekyce: "",
      },
      urlParams: {
        query: this.props.location.state.query,
      },
      biblioItemsFiltersId: "",
      listBiblioitems: {
        biblioitems: [],
        totalResults: "",
        loading: true,
      },
      books_view: {
        grid_view: false,
        list_view: true,
      },
      filtriSidebar: false,
      searched: false,
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.simpleSearchProps.searchtext !==
      this.props.simpleSearchProps.searchtext
    ) {
      this.setState({
        simpleSearch: {
          ...this.state.simpleSearch,
          searchtext: "",
        },
        filterProps: {
          author: "",
          viti_i_botimit: "",
          vendi_i_botimit: "",
          gjuha: "",
          botuesi: "",
          fjalekyce: "",
        },
      });

      this.props.history.push({
        state: {
          ...this.props.location.state,
          page: 0,
        },
      });

      this.getBiblioitemsSimpleSearch();
    } else if (
      prevProps.simpleSearchProps.materialtype !==
      this.props.simpleSearchProps.materialtype
    ) {
      this.setState({
        simpleSearch: {
          ...this.state.simpleSearch,
          materialtype: "",
          material_name: "",
        },
        filterProps: {
          author: "",
          viti_i_botimit: "",
          vendi_i_botimit: "",
          gjuha: "",
          botuesi: "",
          fjalekyce: "",
        },
      });

      this.props.history.push({
        state: {
          ...this.props.location.state,
          page: 0,
        },
      });

      this.getBiblioitemsSimpleSearch();
    }

    if (prevState.filterProps !== this.state.filterProps) {
      this.props.history.push({
        state: {
          ...this.props.location.state,
          page: 0,
        },
      });

      this.getBiblioitemsSimpleSearch();
    }

    if (
      prevProps.location.state &&
      prevProps.location.state.page &&
      prevProps.location.state.page !== this.props.location.state.page
    ) {
      if (this.props.location.state.page >= 1) {
        this.getBiblioitemsSimpleSearch();
      }
    }

    var current_path = window.location.pathname;

    if (current_path === Web.search) {
      if (
        this.state.pagination.activePage === 1 &&
        this.props.history.action === "POP"
      ) {
        // if (this.props.location.state.prevPath === Web.homepage){
        // 	this.props.history.push(Web.homepage);
        // }else{
        // 	this.props.history.goBack();
        // }
        this.props.history.push(this.props.location.state.prevPath);
      }
    }

    if (
      prevProps.location.state &&
      prevProps.location.state.page &&
      prevProps.location.state.page !== this.props.location.state.page
    ) {
      if (
        this.state.pagination.activePage > 1 &&
        this.props.history.action === "POP"
      ) {
        this.getBiblioitemsSimpleSearch();
      }
    }
  }

  getBiblioitemsSimpleSearch = () => {
    let request = Url.simpleSearch;

    var pageLocation = this.props.location.state.page;

    //Default search params
    var searchParams = {
      materialtype: this.state.simpleSearch.materialtype,
      searchtext: this.state.simpleSearch.searchtext,
    };

    //get simple search props
    var props_search_text = this.props.simpleSearchProps.searchtext;
    var props_material_type = this.props.simpleSearchProps.materialtype;
    var props_material_name = this.props.simpleSearchProps.materialName;

    //get filter props value
    var author = this.state.filterProps.author;
    var viti_i_botimit = this.state.filterProps.viti_i_botimit;
    var vendi_i_botimit = this.state.filterProps.vendi_i_botimit;
    var gjuha = this.state.filterProps.gjuha;
    var botuesi = this.state.filterProps.botuesi;
    var fjalekyce = this.state.filterProps.fjalekyce;

    //get filter props location
    var author_location = "";
    var viti_i_botimit_location = "";
    var vendi_i_botimit_location = "";
    var gjuha_location = "";
    var botuesi_location = "";
    var fjalekyce_location = "";

    if (this.props.location.state && this.props.location.state.filterProps) {
      author_location = this.props.location.state.filterProps.author;
      viti_i_botimit_location =
        this.props.location.state.filterProps.viti_i_botimit;
      vendi_i_botimit_location =
        this.props.location.state.filterProps.vendi_i_botimit;
      gjuha_location = this.props.location.state.filterProps.gjuha;
      botuesi_location = this.props.location.state.filterProps.botuesi;
      fjalekyce_location = this.props.location.state.filterProps.fjalekyce;
    }

    if (props_search_text.length !== 0 && props_material_type.length !== 0) {
      searchParams = {
        materialtype: props_material_type,
        searchtext: props_search_text,
      };
    } else if (props_search_text.length !== 0) {
      searchParams = {
        materialtype: this.state.simpleSearch.materialtype,
        searchtext: props_search_text,
      };

      this.props.history.push({
        state: {
          ...this.props.location.state,
          materialtype: this.state.simpleSearch.materialtype,
          material_name: this.state.simpleSearch.materialName,
          searchtext: props_search_text,
        },
      });
    } else if (props_material_type.length !== 0) {
      searchParams = {
        materialtype: props_material_type,
        searchtext: this.state.simpleSearch.searchtext,
      };

      this.props.history.push({
        state: {
          ...this.props.location.state,
          materialtype: props_material_type,
          material_name: props_material_name,
          searchtext: this.state.simpleSearch.searchtext,
        },
      });
    }

    //If there is filtering, attach these parameters to the current URL
    if (author.length !== 0) {
      Object.assign(searchParams, { author: author });
    }
    if (viti_i_botimit.length !== 0) {
      Object.assign(searchParams, { viti_i_botimit: viti_i_botimit });
    }
    if (vendi_i_botimit.length !== 0) {
      Object.assign(searchParams, { vendi_i_botimit: vendi_i_botimit });
    }
    if (gjuha.length !== 0) {
      Object.assign(searchParams, { gjuha: gjuha });
    }
    if (botuesi.length !== 0) {
      Object.assign(searchParams, { botuesi: botuesi });
    }
    if (fjalekyce.length !== 0) {
      Object.assign(searchParams, { fjalekyce: fjalekyce });
    }

    //Save the values in the url if the page changes
    if (author_location.length !== 0) {
      Object.assign(searchParams, { author: author_location });
    }
    if (viti_i_botimit_location.length !== 0) {
      Object.assign(searchParams, { viti_i_botimit: viti_i_botimit_location });
    }
    if (vendi_i_botimit_location.length !== 0) {
      Object.assign(searchParams, {
        vendi_i_botimit: vendi_i_botimit_location,
      });
    }
    if (gjuha_location.length !== 0) {
      Object.assign(searchParams, { gjuha: gjuha_location });
    }
    if (botuesi_location.length !== 0) {
      Object.assign(searchParams, { botuesi: botuesi_location });
    }
    if (fjalekyce_location.length !== 0) {
      Object.assign(searchParams, { fjalekyce: fjalekyce_location });
    }

    //If has paginate
    if (pageLocation >= 1) {
      Object.assign(searchParams, { page: pageLocation });
    }

    this.setState({
      searched: true,
    });

    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      if (response) {
        if (response.status === 200) {
          this.setState({
            listBiblioitems: {
              biblioitems: response.data.biblioItems.data,
              totalResults: response.data.totalResults,
              loading: false,
            },
            biblioItemsFiltersId: response.data.biblioItemsFiltersId,
            //pagination: Paginate.setPaginateDataProps(response.data.biblioItems,pageLocation),
          });
          if (pageLocation >= 1) {
            this.setState({
              pagination: Paginate.setPaginateDataProps(
                response.data.biblioItems,
                pageLocation
              ),
            });
          } else {
            this.setState({
              pagination: Paginate.setPaginateData(response.data.biblioItems),
            });
          }
        }
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  listView = () => {
    localStorage.setItem("list_view", "List View");
    this.setState({
      books_view: {
        grid_view: false,
        list_view: true,
      },
    });
    localStorage.removeItem("grid_view");
  };

  gridView = () => {
    localStorage.setItem("grid_view", "Grid View");
    this.setState({
      books_view: {
        grid_view: true,
        list_view: false,
      },
    });
    localStorage.removeItem("list_view");
  };

  toggleSidebar = () => {
    if (this.state.filtriSidebar === false) {
      this.setState({
        filtriSidebar: true,
      });
    } else {
      this.setState({
        filtriSidebar: false,
      });
    }
  };

  handlePageChange(pageNumber) {
    this.props.history.push({
      state: {
        ...this.props.location.state,
        page: pageNumber,
      },
    });
    this.setState({ activePage: this.props.location.state.page });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  /**
   * Checking if a book has cover , else set default cover
   * @param cover
   * @param material_type
   * @returns {string|*}
   */
  checkCover = (cover, material_type) => {
    if (cover !== null) {
      return AppConfig.biblioCoversPath + cover;
    } else if (cover === null) {
      switch (material_type) {
        case 2:
          return libra;
        case 6:
          return Disertacione;
        case 3:
          return Periodike;
        case 5:
          return Harta;
        case 4:
          return Doreshkrime;
        case 8:
          return Dokument;
        case 1:
          return Artikuj;
        case 7:
          return Materiale_audio_vizuale;
        default:
          return "";
      }
    }
  };

  getFilterAuthor = (uniqueAuthor) => {
    this.setState({
      filterProps: {
        ...this.state.filterProps,
        author: uniqueAuthor,
      },
    });
  };

  getFilterPublicationYear = (uniquePublicationYear) => {
    this.setState({
      filterProps: {
        ...this.state.filterProps,
        viti_i_botimit: uniquePublicationYear,
      },
    });
  };

  getFilterPlace = (uniquePlace) => {
    this.setState({
      filterProps: {
        ...this.state.filterProps,
        vendi_i_botimit: uniquePlace,
      },
    });
  };

  getFilterLanguage = (uniqueLanguage) => {
    this.setState({
      filterProps: {
        ...this.state.filterProps,
        gjuha: uniqueLanguage,
      },
    });
  };

  getFilterPublisher = (uniquePublisher) => {
    this.setState({
      filterProps: {
        ...this.state.filterProps,
        botuesi: uniquePublisher,
      },
    });
  };

  getFilterKeyword = (uniqueKeyword) => {
    this.setState({
      filterProps: {
        ...this.state.filterProps,
        fjalekyce: uniqueKeyword,
      },
    });
  };

  /**
   * Fshij filtrat ne Filter Sidebar
   * @param e
   * @param key
   */
  deleteFilterPropsValue = (e, key) => {
    e.preventDefault();

    // eslint-disable-next-line default-case
    switch (key) {
      case "uniqueAuthor":
        this.setState({
          filterProps: {
            ...this.state.filterProps,
            author: "",
          },
        });

        //Update props location (author)
        this.props.history.push({
          state: {
            ...this.props.location.state,
            filterProps: {
              ...this.props.location.state.filterProps,
              author: "",
            },
            page: 0,
          },
        });

        break;
      case "uniquePublicationYear":
        this.setState({
          filterProps: {
            ...this.state.filterProps,
            viti_i_botimit: "",
          },
        });

        //Update props location (viti_i_botimit)
        this.props.history.push({
          state: {
            ...this.props.location.state,
            filterProps: {
              ...this.props.location.state.filterProps,
              viti_i_botimit: "",
            },
            page: 0,
          },
        });
        break;
      case "uniquePlace":
        this.setState({
          filterProps: {
            ...this.state.filterProps,
            vendi_i_botimit: "",
          },
        });
        //Update props location (vendi_i_botimit)
        this.props.history.push({
          state: {
            ...this.props.location.state,
            filterProps: {
              ...this.props.location.state.filterProps,
              vendi_i_botimit: "",
            },
            page: 0,
          },
        });
        break;
      case "uniqueLanguage":
        this.setState({
          filterProps: {
            ...this.state.filterProps,
            gjuha: "",
          },
        });
        //Update props location (gjuha)
        this.props.history.push({
          state: {
            ...this.props.location.state,
            filterProps: {
              ...this.props.location.state.filterProps,
              gjuha: "",
            },
            page: 0,
          },
        });
        break;
      case "uniquePublisher":
        this.setState({
          filterProps: {
            ...this.state.filterProps,
            botuesi: "",
          },
        });
        //Update props location (botuesi)
        this.props.history.push({
          state: {
            ...this.props.location.state,
            filterProps: {
              ...this.props.location.state.filterProps,
              botuesi: "",
            },
            page: 0,
          },
        });
        break;
      case "uniqueKeyword":
        this.setState({
          filterProps: {
            ...this.state.filterProps,
            fjalekyce: "",
          },
        });
        //Update props location (fjalekyce)
        this.props.history.push({
          state: {
            ...this.props.location.state,
            filterProps: {
              ...this.props.location.state.filterProps,
              fjalekyce: "",
            },
            page: 0,
          },
        });
        break;
    }
  };

  render() {
    var list_view_active = "";
    var grid_view_active = "";
    var grid_container = "";
    var grid_item = "";

    if (this.state.books_view.list_view === true) {
      var list_view_active = "active";
    } else {
      var grid_view_active = "active";
      var grid_container = "grid-container";
      var grid_item = "grid-item";
    }

    var filter_based_on = true;

    var author_filter_props = this.state.filterProps.author;
    var viti_i_botimit_props = this.state.filterProps.viti_i_botimit;
    var vendi_i_botimit_props = this.state.filterProps.vendi_i_botimit;
    var gjuha_props = this.state.filterProps.gjuha;
    var botuesi_props = this.state.filterProps.botuesi;
    var fjalekyce_props = this.state.filterProps.fjalekyce;

    if (
      author_filter_props.length === 0 &&
      viti_i_botimit_props.length === 0 &&
      vendi_i_botimit_props.length === 0 &&
      gjuha_props.length === 0 &&
      botuesi_props.length === 0 &&
      fjalekyce_props.length === 0
    ) {
      filter_based_on = false;
    }

    //get filter props location
    var author_location = "";
    var viti_i_botimit_location = "";
    var vendi_i_botimit_location = "";
    var gjuha_location = "";
    var botuesi_location = "";
    var fjalekyce_location = "";

    if (this.props.location.state && this.props.location.state.filterProps) {
      author_location = this.props.location.state.filterProps.author;
      viti_i_botimit_location =
        this.props.location.state.filterProps.viti_i_botimit;
      vendi_i_botimit_location =
        this.props.location.state.filterProps.vendi_i_botimit;
      gjuha_location = this.props.location.state.filterProps.gjuha;
      botuesi_location = this.props.location.state.filterProps.botuesi;
      fjalekyce_location = this.props.location.state.filterProps.fjalekyce;

      if (
        author_location.length !== 0 ||
        viti_i_botimit_location.length !== 0 ||
        vendi_i_botimit_location.length !== 0 ||
        gjuha_location.length !== 0 ||
        botuesi_location.length !== 0 ||
        fjalekyce_location.length !== 0
      ) {
        filter_based_on = true;
      }
    }

    var biblioitemList = "";
    if (this.state.listBiblioitems.loading) {
      biblioitemList = (
        <img className="m-auto d-block" src={loadingGif} alt="loading" />
      );
    } else {
      biblioitemList = this.state.listBiblioitems.biblioitems.map(
        (biblioitem, index) => {
          var Authors = "";
          if (biblioitem.biblio.authors) {
            Authors = biblioitem.biblio.authors.map((author) => {
              return author.name + ":" + author.pivot.type;
            });
          }
          var Contributors = "";
          if (biblioitem.contributors) {
            Contributors = biblioitem.contributors.map((contributor) => {
              return contributor.name + ":" + contributor.pivot.type;
            });
          }

          return (
            <div
              key={index}
              className={"result1 " + grid_item + " result-parts row"}
            >
              <div className="cover-search-img">
                <Link
                  to={{
                    pathname: Web.detailed + biblioitem.id,
                    state: { prevUrl: this.props.history.location.pathname },
                  }}
                >
                  <img
                    src={this.checkCover(
                      biblioitem.cover,
                      biblioitem.biblio.materialtype.id
                    )}
                    alt={biblioitem.biblio.title}
                  />
                </Link>
              </div>
              <div className="search-materials info-material text-left">
                <h4 className="lloji">{biblioitem.biblio.materialtype.name}</h4>
                <h3 className="titull">
                  <Link
                    to={{
                      pathname: Web.detailed + biblioitem.id,
                      state: { prevUrl: this.props.history.location.pathname },
                    }}
                  >
                    {biblioitem.biblio.title}
                  </Link>
                </h3>
                <h5 className="autor">
                  {Authors.join("; ")} {Contributors.join("; ")}
                </h5>
              </div>
            </div>
          );
        }
      );
    }

    return (
      <Fragment>
        <div id="content">
          <div id="search-results" className="bl_page">
            <div className="b--breadcrumb mt-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb pl-0">
                  <li className="breadcrumb-item">
                    <a href="/">Biblioteka</a>
                  </li>
                  <li
                    className="breadcrumb-item active b--title"
                    aria-current="page"
                  >
                    Rezultatet e kërkimit
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <div
                className="row mx-0 my-4 justify-content-between"
                id="data-info"
              >
                <div className="results-data">
                  <div className="nr-rezulatate pl-0 text-left">
                    Faqja: {this.state.pagination.activePage} -{" "}
                    {this.state.pagination.totalPages}
                  </div>
                  {filter_based_on === true && (
                    <div
                      style={{ display: "flex" }}
                      className="bazuar text-left"
                    >
                      Bazuar në
                      {this.state.filterProps.author.length !== 0 && (
                        <div className="filter-tag">
                          Autor{" "}
                          <span
                            className={"delFilterParam"}
                            onClick={(e) =>
                              this.deleteFilterPropsValue(e, "uniqueAuthor")
                            }
                          >
                            ✕
                          </span>
                        </div>
                      )}
                      {this.state.filterProps.author.length === 0 &&
                        author_location.length !== 0 && (
                          <div className="filter-tag">
                            Autor{" "}
                            <span
                              className={"delFilterParam"}
                              onClick={(e) =>
                                this.deleteFilterPropsValue(e, "uniqueAuthor")
                              }
                            >
                              ✕
                            </span>
                          </div>
                        )}
                      {this.state.filterProps.viti_i_botimit.length !== 0 && (
                        <div className="filter-tag">
                          Vit Botimi{" "}
                          <span
                            className={"delFilterParam"}
                            onClick={(e) =>
                              this.deleteFilterPropsValue(
                                e,
                                "uniquePublicationYear"
                              )
                            }
                          >
                            ✕
                          </span>
                        </div>
                      )}
                      {this.state.filterProps.viti_i_botimit.length === 0 &&
                        viti_i_botimit_location.length !== 0 && (
                          <div className="filter-tag">
                            Vit Botimi{" "}
                            <span
                              className={"delFilterParam"}
                              onClick={(e) =>
                                this.deleteFilterPropsValue(
                                  e,
                                  "uniquePublicationYear"
                                )
                              }
                            >
                              ✕
                            </span>
                          </div>
                        )}
                      {this.state.filterProps.vendi_i_botimit.length !== 0 && (
                        <div className="filter-tag">
                          Vend Botimi{" "}
                          <span
                            className={"delFilterParam"}
                            onClick={(e) =>
                              this.deleteFilterPropsValue(e, "uniquePlace")
                            }
                          >
                            ✕
                          </span>
                        </div>
                      )}
                      {this.state.filterProps.vendi_i_botimit.length === 0 &&
                        vendi_i_botimit_location.length !== 0 && (
                          <div className="filter-tag">
                            Vend Botimi{" "}
                            <span
                              className={"delFilterParam"}
                              onClick={(e) =>
                                this.deleteFilterPropsValue(e, "uniquePlace")
                              }
                            >
                              ✕
                            </span>
                          </div>
                        )}
                      {this.state.filterProps.gjuha.length !== 0 && (
                        <div className="filter-tag">
                          Gjuhe{" "}
                          <span
                            className={"delFilterParam"}
                            onClick={(e) =>
                              this.deleteFilterPropsValue(e, "uniqueLanguage")
                            }
                          >
                            ✕
                          </span>
                        </div>
                      )}
                      {this.state.filterProps.gjuha.length === 0 &&
                        gjuha_location.length !== 0 && (
                          <div className="filter-tag">
                            Gjuhe{" "}
                            <span
                              className={"delFilterParam"}
                              onClick={(e) =>
                                this.deleteFilterPropsValue(e, "uniqueLanguage")
                              }
                            >
                              ✕
                            </span>
                          </div>
                        )}
                      {this.state.filterProps.botuesi.length !== 0 && (
                        <div className="filter-tag">
                          Botues{" "}
                          <span
                            className={"delFilterParam"}
                            onClick={(e) =>
                              this.deleteFilterPropsValue(e, "uniquePublisher")
                            }
                          >
                            ✕
                          </span>
                        </div>
                      )}
                      {this.state.filterProps.botuesi.length === 0 &&
                        botuesi_location.length !== 0 && (
                          <div className="filter-tag">
                            Botues{" "}
                            <span
                              className={"delFilterParam"}
                              onClick={(e) =>
                                this.deleteFilterPropsValue(
                                  e,
                                  "uniquePublisher"
                                )
                              }
                            >
                              ✕
                            </span>
                          </div>
                        )}
                      {this.state.filterProps.fjalekyce.length !== 0 && (
                        <div className="filter-tag">
                          Fjale Kyce{" "}
                          <span
                            className={"delFilterParam"}
                            onClick={(e) =>
                              this.deleteFilterPropsValue(e, "uniqueKeyword")
                            }
                          >
                            ✕
                          </span>
                        </div>
                      )}
                      {this.state.filterProps.fjalekyce.length === 0 &&
                        fjalekyce_location.length !== 0 && (
                          <div className="filter-tag">
                            Fjale Kyce{" "}
                            <span
                              className={"delFilterParam"}
                              onClick={(e) =>
                                this.deleteFilterPropsValue(e, "uniqueKeyword")
                              }
                            >
                              ✕
                            </span>
                          </div>
                        )}
                    </div>
                  )}
                </div>
                <div id="btnContainer">
                  <button
                    className="btn d-md-none sidebar-toggle"
                    onClick={this.toggleSidebar}
                  >
                    <img src={toggleSidebarIcon} />
                  </button>
                  <div className="d-flex">
                    <button
                      className={"btn list-view " + list_view_active}
                      onClick={this.listView}
                    >
                      <img src={listViewIcon} />
                    </button>
                    <button
                      className={"btn grid-view " + grid_view_active}
                      onClick={this.gridView}
                    >
                      <img src={gridViewIcon} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mx-0 my-4 ">
                <div
                  className={
                    "col-12 col-md-4 col-lg-3 pl-0" +
                    (this.state.filtriSidebar === true ? " show" : "")
                  }
                  id="filtri"
                >
                  <FilterSidebar
                    biblioItemsFiltersId={this.state.biblioItemsFiltersId}
                    filterPropsAuthor={this.getFilterAuthor}
                    filterPropsPublicationYear={this.getFilterPublicationYear}
                    filterPropsPlace={this.getFilterPlace}
                    filterPropsLanguage={this.getFilterLanguage}
                    filterPropsPublisher={this.getFilterPublisher}
                    filterPropsKeyword={this.getFilterKeyword}
                  />
                </div>
                <div className="col-12 col-md-8 col-lg-9 p-0">
                  <div
                    className={
                      "results " +
                      grid_container +
                      (this.state.listBiblioitems.loading === true
                        ? " d-block p-3"
                        : "")
                    }
                  >
                    {biblioitemList}
                  </div>
                  {this.state.pagination.totalItemsCount === 0 &&
                    this.state.listBiblioitems.loading === false &&
                    this.state.searched === true && (
                      <img
                        className="mx-auto d-block"
                        src={noResult}
                        alt="no result"
                      />
                    )}
                </div>

                <div className="container-fluid pt-5 pb-4">
                  <div className="d-flex justify-content-center pagination-block">
                    {this.state.pagination.totalPages > 1 && (
                      <div className="d-flex justify-content-center pagination-block mb-5">
                        <div className="select-page">
                          <Pagination
                            activePage={this.state.pagination.activePage}
                            itemsCountPerPage={
                              this.state.pagination.itemsCountPerPage
                            }
                            totalItemsCount={
                              this.state.pagination.totalItemsCount
                            }
                            pageRangeDisplayed={
                              this.state.pagination.pageRangeDisplayed
                            }
                            onChange={this.handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                        <div className="pagination-results pl-5">
                          Faqja: {this.state.pagination.activePage} -{" "}
                          {this.state.pagination.totalPages} <br />
                          Gjithsej rezultate:{" "}
                          {this.state.listBiblioitems.totalResults}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(SearchResults);
