import React, { Component, Fragment } from "react";
import Select from "react-select";
import Paginate from "../../../../helpers/Paginate";
import Url from "../../../../api/Url";
import Request from "../../../../api/Request";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import noResult from "../../../../assets/images/icons/no_result.svg";
import SearchFilter from "../../../../helpers/SearchFilter";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import TooltipComp from "../../../../helpers/Tooltip";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
class DamagedLost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listBiblioitem: {
        biblioitems: [{}],
        loading: true,
      },
      search: {
        status: "",
        from: "",
        to: "",
      },
      searched: false,
      errors: {
        list: [],
      },
      btnLoading: false,
      btnAddLoading: false,
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.listBiblioitems();
  }

  listBiblioitems = (event) => {
    let request = Url.reportDamagedLost;

    if (event) {
      event.preventDefault();
      const searchParams = {
        status: this.state.search.status,
        from: this.state.search.from,
        to: this.state.search.to,
        filtro: "",
      };
      this.setState({ searched: true });
      this.setState({ btnLoading: true });
      request = SearchFilter.setFilterParams(searchParams, request);
    }

    Request.getRequest(request).then((result) => {
      this.setState({ btnLoading: false });
      if (result.status === 200) {
        this.setState({
          listBiblioitem: {
            biblioitems: result.data.items.data,
            loading: false,
          },
          errors: {
            list: [],
          },
          pagination: Paginate.setPaginateData(result.data.items),
        });
      } else if (result.status === 422) {
        this.setState({
          errors: {
            list: result.data,
          },
          searched: false,
        });
      } else {
        this.setState({
          listBiblioitem: {
            biblioitems: [{}],
            loading: true,
          },
          searched: false,
        });
      }
    });
  };

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.search,
      [key]: selectValue,
    };
    this.setState({
      search: newForm,
    });
  };

  handleChangeD = (key) => (e) => {
    let newForm = {
      ...this.state.search,
      [key]: moment(new Date(e)).format("DD-MM-yyyy"),
    };
    this.setState({
      search: newForm,
    });
  };

  handlePageChange(pageNumber) {
    let request = Url.reportDamagedLost;
    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        status: this.state.search.status,
        from: this.state.search.from,
        to: this.state.search.to,
        filtro: "",
        page: pageNumber,
      };
    }
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listBiblioitem: {
          biblioitems: response.data.items.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.items),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  download(e) {
    e.preventDefault();
    this.setState({ btnAddLoading: true });
    let request = Url.exportDamagedLost;

    const searchParams = {
      status: this.state.search.status,
      from: this.state.search.from,
      to: this.state.search.to,
      export_damagedlost: "",
    };
    request = SearchFilter.setFilterParams(searchParams, request);

    const link = document.createElement("a");
    Request.getRequestForDownloadExcel(request).then((response) => {
      this.setState({ btnAddLoading: false });
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.setAttribute("download", "Report_Demtuar_Spastruar.xlsx");
      link.click();
    });
  }

  render() {
    var biblioitemsList = "";
    if (this.state.listBiblioitem.loading) {
      biblioitemsList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      biblioitemsList = this.state.listBiblioitem.biblioitems.map(
        (item, index) => {
          return (
            <tr key={item.id}>
              <td
                role="cell"
                data-label="LLoji i Materialit"
                className="title-borrowing"
              >
                {item.biblioitem.biblio.materialtype.name}
              </td>
              <td
                role="cell"
                data-label="Titull / Autor"
                className="title-borrowing text-center"
              >
                {TooltipComp.tooltipDisabledMobile(
                  "-title-" + index,
                  item.biblioitem.biblio.title
                )}
                <br />
                {/* autoret */}
                {item.biblioitem.biblio["authors"].map((item, index, arr) => {
                  if (index === arr.length - 1) {
                    return item["name"];
                  } else {
                    return item["name"] + "; ";
                  }
                })}
              </td>
              <td role="cell" data-label="Botuesi" className="text-center">
                {item.biblioitem.publisher
                  ? item.biblioitem.publisher.name
                  : ""}
              </td>
              <td role="cell" data-label="Issn / Isbn" className="text-center">
                {item.biblioitem.isbn ? item.biblioitem.isbn : "-"} /{" "}
                {item.biblioitem.issn ? item.biblioitem.issn : "-"}
              </td>
              <td
                role="cell"
                data-label="Kodifikimi/Nr. i inventarit"
                className="text-center"
              >
                {item.inventory_number + " / " + item.id}
              </td>
            </tr>
          );
        }
      );
    }
    return (
      <Fragment>
        <div id="report-catalog" className="table-detail-tabs mb-5">
          <div className="container-fluid small-offset-left">
            <div className="row input-items mb-2 pb-4">
              <div className="col-12">
                <form
                  onSubmit={this.listBiblioitems}
                  className="borrowing-search"
                >
                  <div className="row input-items mb-2 pb-4">
                    <div className="col-12 col-sm-6 col-md-4 pb-4">
                      <label htmlFor="status-button">Statusi:</label>
                      <Select
                        className={
                          "basic-select" +
                          (this.state.errors.list.status ? " form-error" : "")
                        }
                        classNamePrefix="select_input"
                        name="status"
                        isSearchable={false}
                        isClearable={true}
                        placeholder="Përzgjidh"
                        options={[
                          { value: "damaged", label: "Demtuar" },
                          { value: "lost", label: "Spastruar" },
                        ]}
                        onChange={this.handleChangeSelect("status")}
                      />
                      {this.state.errors.list.status && (
                        <span className="error-message left">
                          {this.state.errors.list.status[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 pb-4">
                      <label htmlFor="from">Nga Data:</label>
                      <DatePicker
                        placeholderText="Zgjidh datën"
                        name="from"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale={sq}
                        value={this.state.search.from}
                        dateFormat="DD-MM-yyyy"
                        onChange={this.handleChangeD("from")}
                        autoComplete="off"
                        className={
                          "date_input" +
                          (this.state.errors.list.from ? " form-error" : "")
                        }
                      />
                      {this.state.errors.list.from && (
                        <span className="error-message left">
                          {this.state.errors.list.from[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 pb-4">
                      <label htmlFor="to">Ne Daten:</label>
                      <DatePicker
                        placeholderText="Zgjidh datën"
                        name="to"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale={sq}
                        value={this.state.search.to}
                        dateFormat="DD-MM-yyyy"
                        onChange={this.handleChangeD("to")}
                        autoComplete="off"
                        className={
                          "date_input" +
                          (this.state.errors.list.to ? " form-error" : "")
                        }
                      />
                      {this.state.errors.list.to && (
                        <span className="error-message left">
                          {this.state.errors.list.to[0]}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="row pb-2 mb-2">
                    <div className="col-md-12 d-flex flex-wrap">
                      {this.state.btnLoading === true ? (
                        <button
                          id="filtro"
                          className="btn-styled-2 ml-0 mt-0 mb-3 px-5  mr-5 "
                          name="filtro"
                          type="button"
                        >
                          Duke Kërkuar
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                        </button>
                      ) : (
                        <button
                          id="filtro"
                          className="btn-styled-2 ml-0 mt-0 mb-3 px-5  mr-5 "
                          name="filtro"
                          type="submit"
                        >
                          Kërko
                        </button>
                      )}
                      {this.state.btnAddLoading === true ? (
                        <button
                          id="export_damagedlost"
                          className="btn-styled-2 ml-0 mt-0 mb-3 px-5 export-damaged-lost"
                          name="export_damagedlost"
                          type="button"
                        >
                          Duke Eksportuar
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                        </button>
                      ) : (
                        <button
                          id="export_damagedlost"
                          className="btn-styled-2 ml-0 mt-0 mb-3 px-5 export-damaged-lost"
                          name="export_damagedlost"
                          type="button"
                          onClick={(e) => this.download(e)}
                        >
                          Eksporto
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="table-list">
              <table role="table" className="w-100">
                <thead role="rowgroup">
                  <tr role="row">
                    <th role="columnheader" width="20%" className="text-left">
                      LLoji i materialit
                    </th>
                    <th role="columnheader" width="25%" className="text-center">
                      Titull / Autor
                    </th>
                    <th role="columnheader" width="15%" className="text-center">
                      Botuesi
                    </th>
                    <th role="columnheader" width="15%" className="text-center">
                      Issn / Isbn
                    </th>
                    <th role="columnheader" width="25%" className="text-center">
                      Kodifikimi/Nr. i inventarit
                    </th>
                  </tr>
                </thead>

                <tbody role="rowgroup">{biblioitemsList}</tbody>
              </table>
            </div>
            {this.state.pagination.totalItemsCount === 0 &&
              this.state.listBiblioitem.loading === false &&
              this.state.searched === true && (
                <img
                  className="mx-auto d-block"
                  src={noResult}
                  alt="no result"
                />
              )}

            {this.state.pagination.totalItemsCount === 0 &&
              this.state.listBiblioitem.loading === false &&
              this.state.searched === false && (
                <p className="nothing--to--show">Asgjë për të shfaqur</p>
              )}
          </div>
          {this.state.pagination.totalPages > 1 && (
            <div className="d-flex justify-content-center flex-wrap pagination-block mb-5">
              <div className="select-page">
                <Pagination
                  activePage={this.state.pagination.activePage}
                  itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                  totalItemsCount={this.state.pagination.totalItemsCount}
                  pageRangeDisplayed={this.state.pagination.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="pagination-results">
                Faqja:
                {this.state.pagination.activePage} -{" "}
                {this.state.pagination.totalPages}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default DamagedLost;
