import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/opac/partials/Header";
import Footer from "../../components/opac/partials/Footer";
import Detailed from "../../components/opac/Detailed";

class DetailedView extends Component {
	render() {
		return (
			<Fragment>
				<Helmet>
					<title>Të dhënat për materialin</title>
				</Helmet>
				<div style={{ width: 100 + "%" }}>
					<Header roleUser={this.props.roles} search={true} />
					<Detailed />
					<Footer />
				</div>
			</Fragment>
		);
	}
}
export default DetailedView;
