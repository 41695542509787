import React, { Component, Fragment } from 'react';
import '../../assets/css/sidebar.css';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';

import CancelNav from '../../assets/images/icons/cancel-navbar.svg';
import Web from '../../routes/Web';
import { menuHandler } from './Header';

class Sidebar extends Component {
  render() {
    var role = this.props.roleUser;
    var first_name = this.props.firstName;
    var last_name = this.props.lastName;
    return (
      <Fragment>
        <nav id="sidebar">
          <div className="sidebar-header d-none d-md-flex">
            <div className="d-flex">
              <div className="iniciale">
                <span className="initials-user">
                  {first_name.charAt(0) + last_name.charAt(0)}
                </span>
              </div>
              <div className="user-data">
                <span className="role-name">{role}</span>
                <span className="user-full-name">
                  {first_name + ' ' + last_name}
                </span>
              </div>
            </div>

            <button
              type="button"
              onClick={menuHandler}
              className="sidebarCollapse-menu btn-unstyled"
            >
              <img src={CancelNav} alt="" />
            </button>
          </div>

          <ul className="list-unstyled components">
            {(role === 'Administrator' ||
              role === 'Operator' ||
              role === 'It') && (
              <li>
                <a
                  href="#homeSubmenu"
                  data-toggle="collapse"
                  aria-expanded={
                    this.props.activeLink === 'catalog' ? 'true' : 'false'
                  }
                  className={
                    'dropdown-toggle' +
                    (this.props.activeLink === 'catalog' ? '' : ' collapsed')
                  }
                >
                  Katalogim
                </a>
                <ul
                  className={
                    'collapse list-unstyled ' +
                    (this.props.activeLink === 'catalog' && 'show')
                  }
                  onClick={this.handleClasses}
                  id="homeSubmenu"
                  data-parent="#sidebar"
                >
                  {(role === 'Administrator' || role === 'Operator') && (
                    <Fragment>
                      <li>
                        <NavLink to={Web.listBiblioitems}>Bibliografi</NavLink>
                      </li>
                      <li>
                        <NavLink to={Web.createBiblio}>
                          Shto bibliografi
                        </NavLink>
                      </li>
                      {role === 'Administrator' && (
                        <Fragment>
                          <li>
                            <NavLink to={Web.importMarc}>
                              Import MARC 21
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={Web.exportMarc}>
                              Export MARC 21
                            </NavLink>
                          </li>
                        </Fragment>
                      )}
                      <li>
                        <NavLink to={Web.z3950Service}>Shërbimi Z39.50</NavLink>
                      </li>
                    </Fragment>
                  )}

                  {(role === 'Administrator' || role === 'It') && (
                    <li>
                      <NavLink to={Web.z3950Server}>Serverat Z39.50</NavLink>
                    </li>
                  )}

                  {(role === 'Administrator' || role === 'Operator') && (
                    <Fragment>
                      <li>
                        <NavLink to={Web.createAuthor}>Autor</NavLink>
                      </li>
                      <li>
                        <NavLink to={Web.createPublisher}>Botues</NavLink>
                      </li>
                      <li>
                        <NavLink to={Web.createKeyWords}>Fjalë kyçe</NavLink>
                      </li>
                      <li>
                        <NavLink to={Web.createGeogreaphicalTerm}>
                          Terma Gjeografik
                        </NavLink>
                      </li>
                    </Fragment>
                  )}
                </ul>
              </li>
            )}
            {(role === 'Administrator' || role === 'Operator') && (
              <li>
                <a
                  href="#huazimSubmenu"
                  data-toggle="collapse"
                  aria-expanded={
                    this.props.activeLink === 'borrowing' ? 'true' : 'false'
                  }
                  className={
                    'dropdown-toggle' +
                    (this.props.activeLink === 'borrowing' ? '' : ' collapsed')
                  }
                >
                  Huazim
                </a>
                <ul
                  className={
                    'collapse list-unstyled ' +
                    (this.props.activeLink === 'borrowing' && 'show')
                  }
                  id="huazimSubmenu"
                  onChange={this.handleClasses}
                  data-parent="#sidebar"
                >
                  <li>
                    <NavLink to={Web.borrow}>Huazo</NavLink>
                  </li>
                  <li>
                    <NavLink to={Web.listBorrowings}>Listë Huazimesh</NavLink>
                  </li>
                  <li>
                    <NavLink to={Web.listPending}>Në Pritje</NavLink>
                  </li>
                  <li>
                    <NavLink to={Web.reservations}>Rezervime</NavLink>
                  </li>
                </ul>
              </li>
            )}
            {(role === 'Administrator' || role === 'Operator') && (
              <li>
                <a
                  href="#serialeSubmenu"
                  data-toggle="collapse"
                  aria-expanded={
                    this.props.activeLink === 'serial' ? 'true' : 'false'
                  }
                  className={
                    'dropdown-toggle' +
                    (this.props.activeLink === 'serial' ? '' : ' collapsed')
                  }
                >
                  Seriale
                </a>
                <ul
                  className={
                    'collapse list-unstyled ' +
                    (this.props.activeLink === 'serial' && 'show')
                  }
                  id="serialeSubmenu"
                  onClick={this.handleClasses}
                  data-parent="#sidebar"
                >
                  <li>
                    <NavLink to={Web.createSerial}>Serial i ri</NavLink>
                  </li>
                  <li>
                    <NavLink to={Web.listSerials}>Listë serialesh</NavLink>
                  </li>
                  <li>
                    <NavLink to={Web.frequence}>Frekuenca</NavLink>
                  </li>
                </ul>
              </li>
            )}
            {(role === 'Administrator' || role === 'Operator') && (
              <li>
                <a
                  href="#anetareSubmenu"
                  data-toggle="collapse"
                  aria-expanded={
                    this.props.activeLink === 'members' ? 'true' : 'false'
                  }
                  className={
                    'dropdown-toggle' +
                    (this.props.activeLink === 'members' ? '' : ' collapsed')
                  }
                >
                  Anëtarë
                </a>
                <ul
                  className={
                    'collapse list-unstyled ' +
                    (this.props.activeLink === 'members' && 'show')
                  }
                  id="anetareSubmenu"
                  onClick={this.handleClasses}
                  data-parent="#sidebar"
                >
                  <li>
                    <NavLink to={Web.listMembers}>Lista e anëtarëve</NavLink>
                  </li>
                  <li>
                    <NavLink to={Web.addMember}>Shto anëtarë</NavLink>
                  </li>
                </ul>
              </li>
            )}
            {role === 'Administrator' && (
              <li>
                <a
                  href="#stafSubmenu"
                  data-toggle="collapse"
                  aria-expanded={
                    this.props.activeLink === 'staff' ? 'true' : 'false'
                  }
                  className={
                    'dropdown-toggle' +
                    (this.props.activeLink === 'staff' ? '' : ' collapsed')
                  }
                >
                  Staf
                </a>
                <ul
                  className={
                    'collapse list-unstyled ' +
                    (this.props.activeLink === 'staff' && 'show')
                  }
                  id="stafSubmenu"
                  onClick={this.handleClasses}
                  data-parent="#sidebar"
                >
                  <li>
                    <NavLink to={Web.listStaff}>Lista e stafit</NavLink>
                  </li>
                  <li>
                    <NavLink to={Web.addStaff}>Shto stafin</NavLink>
                  </li>
                </ul>
              </li>
            )}
            {(role === 'Administrator' || role === 'Operator') && (
              <li>
                <a
                  href="#pasurimSubmenu"
                  data-toggle="collapse"
                  aria-expanded={
                    this.props.activeLink === 'acquisition' ? 'true' : 'false'
                  }
                  className={
                    'dropdown-toggle' +
                    (this.props.activeLink === 'acquisition'
                      ? ''
                      : ' collapsed')
                  }
                >
                  Pasurim
                </a>
                <ul
                  className={
                    'collapse list-unstyled ' +
                    (this.props.activeLink === 'acquisition' && 'show')
                  }
                  id="pasurimSubmenu"
                  onClick={this.handleClasses}
                  data-parent="#sidebar"
                >
                  <li>
                    <NavLink to={Web.listAcquisition}>
                      Lista e pasurimeve
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Web.createAcquisition}>
                      Shto një pasurim
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
            {(role === 'Administrator' || role === 'Bibliotekar') && (
              <li>
                <a
                  href="#raportSubmenu"
                  data-toggle="collapse"
                  aria-expanded={
                    this.props.activeLink === 'reports' ? 'true' : 'false'
                  }
                  className={
                    'dropdown-toggle' +
                    (this.props.activeLink === 'reports' ? '' : ' collapsed')
                  }
                >
                  Raport
                </a>
                <ul
                  className={
                    'collapse list-unstyled ' +
                    (this.props.activeLink === 'reports' && 'show')
                  }
                  id="raportSubmenu"
                  onClick={this.handleClasses}
                  data-parent="#sidebar"
                >
                  <li>
                    <NavLink to={Web.reportStats}>Statistika</NavLink>
                  </li>
                  <li>
                    <NavLink to={Web.reportCatalog}>Katalogim</NavLink>
                  </li>
                  <li>
                    <NavLink to={Web.reportBorrowing}>Huazim</NavLink>
                  </li>
                </ul>
              </li>
            )}
            {(role === 'Administrator' || role === 'It') && (
              <li>
                <a
                  href="#backupSubmenu"
                  data-toggle="collapse"
                  aria-expanded={
                    this.props.activeLink === 'backup' ? 'true' : 'false'
                  }
                  className={
                    'dropdown-toggle' +
                    (this.props.activeLink === 'backup' ? '' : ' collapsed')
                  }
                >
                  Backup
                </a>
                <ul
                  className={
                    'collapse list-unstyled ' +
                    (this.props.activeLink === 'backup' && 'show')
                  }
                  id="backupSubmenu"
                  onClick={this.handleClasses}
                  data-parent="#sidebar"
                >
                  <li>
                    <NavLink to={Web.listBackup}>Backup</NavLink>
                  </li>
                  <li>
                    <NavLink to={Web.logs}>Loge</NavLink>
                  </li>
                </ul>
              </li>
            )}
            {(role === 'Administrator' ||
              role === 'Operator' ||
              role === 'It') && (
              <li>
                <a
                  href="#blogSubmenu"
                  data-toggle="collapse"
                  aria-expanded={
                    this.props.activeLink === 'blog' ? 'true' : 'false'
                  }
                  className={
                    'dropdown-toggle' +
                    (this.props.activeLink === 'blog' ? '' : ' collapsed')
                  }
                >
                  Blog
                </a>
                <ul
                  className={
                    'collapse list-unstyled ' +
                    (this.props.activeLink === 'blog' && 'show')
                  }
                  id="blogSubmenu"
                  onClick={this.handleClasses}
                  data-parent="#sidebar"
                >
                  <li>
                    <NavLink to={Web.listBlogPosts}>Lista e artikujve</NavLink>
                  </li>
                  <li>
                    <NavLink to={Web.createBlogPost}>Shto një artikull</NavLink>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </nav>
      </Fragment>
    );
  }
}

export default withRouter(Sidebar);
