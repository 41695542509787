class Web {
  /* OPAC */
  static homepage = '/';
  static login = '/login';
  static forgotPassword = '/forgot-password';
  static resetPasswordParam = '/resetpassword/:param';
  static ativateAccountParam = '/user/registration/:param';
  static university = '/university';
  static about = '/about';
  static privacyPolicies = '/privacy-policies';
  static help = '/help';
  static contact = '/contact';
  static news = '/news';
  static newsPostTitle = '/news/:title';
  static newsPost = '/news/';
  static search = '/search';
  static detailedID = '/detailed/:id';
  static detailed = '/detailed/';
  static advanced_search = '/advanced-search';
  static most_read_books = '/most-read-books';
  static latest_books = '/latest-books';
  static digitized_books = '/digitized-books';

  /* Opac */

  /* Opac Panel */
  static opacProfile = '/profile';
  static opacChangePassword = '/change-password';
  /* Opac Panel */

  /* Dashboard */
  static dashboard = '/dashboard';

  /* Catalog */
  static listBiblioitems = '/catalog/listbiblioitems';
  static showBiblioitemID = '/catalog/listbiblioitems/showbiblioitem/:id';
  static showBiblioitem = '/catalog/listbiblioitems/showbiblioitem/';
  static editBiblioitemID = '/catalog/listbiblioitems/editbiblioitem/:id';
  static editBiblioitem = '/catalog/listbiblioitems/editbiblioitem/';

  static createBiblio = '/catalog/bibliography';
  static editBiblioID = '/catalog/bibliography/editbiblio/:id/:biblioitem_id?';
  static editBiblio = '/catalog/bibliography/editbiblio/';
  static createBiblioItemID = '/catalog/bibliography/createbiblioitem/:id';
  static createBiblioItem = '/catalog/bibliography/createbiblioitem/';
  static editBiblioitemonlyID =
    '/catalog/bibliography/editbiblioitemonly/:biblio_id/:id';
  static editBiblioitemonly = '/catalog/bibliography/editbiblioitemonly/';
  static createItemID = '/catalog/bibliography/createitem/:id';
  static createItem = '/catalog/bibliography/createitem/';
  static editItemsID = '/catalog/bibliography/edititems/:id';
  static editItems = '/catalog/bibliography/edititems/';

  static importMarc = '/catalog/uploadmarcfile';
  static confirmMarc = '/catalog/uploadmarcfile/confirmmarcrecords';
  static exportMarc = '/catalog/exportmarc';

  static z3950Service = '/catalog/z3950/service';
  static confirmZ3950 = '/catalog/z3950/service/confirmrecords';
  static z3950Server = '/catalog/z3950/server';

  static createAuthor = '/catalog/author';
  static editAuthorId = '/catalog/editauthor/:id';
  static editAuthor = '/catalog/editauthor/';

  static createKeyWords = '/catalog/keyword';
  static editKeywordId = '/catalog/editkeyword/:id';
  static editKeyword = '/catalog/editkeyword/';

  static createPublisher = '/catalog/publisher';

  static createGeogreaphicalTerm = '/catalog/geographicalterm';
  /* Catalog */

  /* Borrow */
  static borrow = '/borrowing/index';
  static searchMemberID = '/borrowing/index/item/:id/member';
  static searchMember = '/borrowing/index/item/';
  static searchMaterialID = '/borrowing/index/member/:id/item';
  static searchMaterial = '/borrowing/index/member/';

  static confirmBorrowId = '/borrowing/index/member/:member_id/item/:item_id';
  static confirmBorrow = '/borrowing/index/member/';

  static listBorrowings = '/borrowing/list';
  static borrowingDetailsID = '/borrowing/list/:id';
  static borrowingDetails = '/borrowing/list/';
  static listPending = '/borrowing/listpending';
  static reservations = '/borrowing/reservationlist';
  /* Borrow */

  /* Serial */
  static createSerial = '/serial/create';
  static listSerials = '/serial/listserials';
  static showSerialID = '/serial/listserials/show/:id';
  static showSerial = '/serial/listserials/show/';
  static editSerialID = '/serial/listserials/edit/:id';
  static editSerial = '/serial/listserials/edit/';
  static frequence = '/serial/frequence';
  /* Serial */

  /* Members */
  static listMembers = '/member/list';
  static showMemberID = '/member/list/show/:id';
  static showMember = '/member/list/show/';
  static editMemberID = '/member/list/edit/:id';
  static editMember = '/member/list/edit/';
  static addMember = '/member/invite';
  /* Members */

  /* Staff */
  static listStaff = '/staff/list';
  static showStaffID = '/staff/list/show/:id';
  static showStaff = '/staff/list/show/';
  static editStaffID = '/staff/list/edit/:id';
  static editStaff = '/staff/list/edit/';
  static addStaff = '/staff/invite';
  /* Staff */
  static editUserID = '/user/edit/:id';
  static editUser = '/user/edit/';

  /* Acquisition */
  static listAcquisition = '/acquisition/list';
  static editAcquisitionId = '/acquisition/list/edit/:id';
  static editAcquisition = '/acquisition/list/edit/';
  static showAcquisitionId = '/acquisition/list/show/:id';
  static showAcquisition = '/acquisition/list/show/';
  static createAcquisition = '/acquisition/create';
  static aproveAcquisition = '/acquisition/aprove';
  static cancelAcquisition = '/acquisition/create';
  /* Acquisition */

  /* Report */
  static reportStats = '/report/stats';
  static reportCatalog = '/report/catalog';
  static reportBorrowing = '/report/borrowing';
  /* Report */

  /* Backup */
  static listBackup = '/list/backup';
  static logs = '/logs';
  /* Backup */

  /* Blog */
  static listBlogPosts = '/blog/list';
  static editBlogPostId = '/blog/list/edit/:id';
  static editBlogPost = '/blog/list/edit/';
  static createBlogPost = '/blog/create';
  /* Blog */

  /* Change Password */
  static changePassword = '/changepassword';
  /* Change Password */
}

export default Web;
