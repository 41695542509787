import { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import Select from "react-select";
import Url from "../../../../api/Url";
import Messages from "../../../../helpers/Messages";
import Request from "../../../../api/Request";
import { withRouter } from "react-router";
import AppConfig from "../../../../services/AppConfig";
import LoadingGif from "../../../../assets/images/icons/loading.gif";

class EditSerieAddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          id: 0,
          "items[0][biblioitem_id]": this.props.biblioitemID,
          "items[0][serie_id]": this.props.serieId,
          "items[0][borrowing_type]": "",
          "items[0][inventory_number]": "",
          "items[0][place_number]": "",
          "items[0][damaged]": "0",
          "items[0][damaged_date]": "",
          "items[0][renewal_date]": "",
        },
      ],
      itemsValues: [
        {
          "items[0][biblioitem_id]": this.props.biblioitemID,
          "items[0][serie_id]": this.props.serieId,
          "items[0][borrowing_type]": "",
          "items[0][inventory_number]": "",
          "items[0][place_number]": "",
          "items[0][damaged]": "0",
          "items[0][damaged_date]": "",
          "items[0][renewal_date]": "",
        },
      ],
      formError: [],
      buttons: {
        removeBtn: false,
      },
      btnLoading: false,
    };
  }

  itemsInputCheckbox = (e, index, name, i) => {
    const inputs = this.state.items;
    const inputs1 = this.state.itemsValues;
    inputs[i][name] = e.target.value;
    inputs1[i][name] = e.target.value;
    this.setState({
      items: inputs,
      itemsValues: inputs1,
    });

    if (e.target.value === "0") {
      inputs[i][`items[${index}][damaged_date]`] = "";
      inputs[i][`items[${index}][renewal_date]`] = "";
      inputs1[0][`items[${index}][damaged_date]`] = "";
      inputs1[0][`items[${index}][renewal_date]`] = "";
      this.setState({
        items: inputs,
        itemsValues: inputs1,
      });
    }
  };

  itemsInput = (e, index, name, i) => {
    const inputs = this.state.items;
    const inputs1 = this.state.itemsValues;
    inputs[i][name] = e.target.value;
    inputs1[0][name] = e.target.value;
    this.setState({
      items: inputs,
      itemsValues: inputs1,
    });
  };

  itemsInputSelect = (e, index, name, i) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    const inputs = this.state.items;
    const inputs1 = this.state.itemsValues;
    inputs[i][name] = selectValue;
    inputs1[0][name] = selectValue;
    this.setState({
      items: inputs,
      itemsValues: inputs1,
    });
  };

  itemsInputDate = (e, index, name, i) => {
    const inputs = this.state.items;
    const inputs1 = this.state.itemsValues;
    var value = null;
    if (e !== null) {
      value = moment(e).format("yyyy-MM-DD");
    }
    inputs[i][name] = value;
    inputs1[0][name] = value;
    this.setState({
      items: inputs,
      itemsValues: inputs1,
    });
  };

  /**
   * add new serie row
   */
  addNewRow = (input_id) => {
    let indexnow = input_id + 1;
    const inputs = this.state.items;
    const inputs1 = this.state.itemsValues;
    const blankRow = {
      id: indexnow,
      [`items[${indexnow}][biblioitem_id]`]: this.props.biblioitemID,
      [`items[${indexnow}][serie_id]`]: this.props.serieId,
      [`items[${indexnow}][borrowing_type]`]: "",
      [`items[${indexnow}][inventory_number]`]: "",
      [`items[${indexnow}][place_number]`]: "",
      [`items[${indexnow}][damaged]`]: 0,
      [`items[${indexnow}][damaged_date]`]: "",
      [`items[${indexnow}][renewal_date]`]: "",
    };
    const blankRow1 = {
      [`items[${indexnow}][biblioitem_id]`]: this.props.biblioitemID,
      [`items[${indexnow}][serie_id]`]: this.props.serieId,
      [`items[${indexnow}][borrowing_type]`]: "",
      [`items[${indexnow}][inventory_number]`]: "",
      [`items[${indexnow}][place_number]`]: "",
      [`items[${indexnow}][damaged]`]: 0,
      [`items[${indexnow}][damaged_date]`]: "",
      [`items[${indexnow}][renewal_date]`]: "",
    };
    Object.assign(...inputs1, blankRow1);
    this.setState({
      items: [...inputs, blankRow],
    });
    this.setState({
      buttons: {
        removeBtn: true,
      },
    });
  };

  /**
   * remove serie row
   */
  removeRow = (input_id) => {
    const inputs = this.state.items;
    const inputs1 = this.state.itemsValues;
    if (inputs.length > 1) {
      const removeIndex = inputs.findIndex((item) => item.id === input_id);
      let indexnow = input_id;
      var removedItems = [
        [`items[${indexnow}][biblioitem_id]`],
        [`items[${indexnow}][serie_id]`],
        [`items[${indexnow}][borrowing_type]`],
        [`items[${indexnow}][inventory_number]`],
        [`items[${indexnow}][place_number]`],
        [`items[${indexnow}][damaged]`],
        [`items[${indexnow}][damaged_date]`],
        [`items[${indexnow}][renewal_date]`],
      ];

      removedItems.map((removeItem) => {
        delete inputs1[0][removeItem];
      });

      inputs.splice(removeIndex, 1);
      this.setState({
        items: inputs,
      });
    }
    if (inputs.length === 1) {
      this.setState({
        buttons: {
          removeBtn: false,
        },
      });
    }
  };

  checkSelectValue = (value) => {
    switch (value) {
      case "salle":
        return { value: "salle", label: "Sallë" };
      case "normal":
        return { value: "normal", label: "Normal" };
      case "ipadisponueshem":
        return { value: "ipadisponueshem", label: "I Padisponueshëm" };
      default:
        return "";
    }
  };

  checkSelectedDate = (value) => {
    if (value === "") {
      return null;
    } else {
      return new Date(value);
    }
  };

  updateGeneratedSerie = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const formData = new FormData();

    let newstate = this.state.itemsValues[0];

    for (const [key, value] of Object.entries(newstate)) {
      formData.append(key, value);
    }

    let request = Url.storeSerieItems;
    Request.postRequest(request, formData).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 201) {
        this.setState({
          items: [
            {
              id: 0,
              "items[0][biblioitem_id]": this.props.biblioitemID,
              "items[0][serie_id]": this.props.serieId,
              "items[0][borrowing_type]": "",
              "items[0][inventory_number]": "",
              "items[0][place_number]": "",
              "items[0][damaged]": "0",
              "items[0][damaged_date]": "",
              "items[0][renewal_date]": "",
            },
          ],
          itemsValues: [
            {
              "items[0][biblioitem_id]": this.props.biblioitemID,
              "items[0][serie_id]": this.props.serieId,
              "items[0][borrowing_type]": "",
              "items[0][inventory_number]": "",
              "items[0][place_number]": "",
              "items[0][damaged]": "0",
              "items[0][damaged_date]": "",
              "items[0][renewal_date]": "",
            },
          ],
          formError: [],
        });
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 422) {
        this.setState({
          formError: response.data,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  render() {
    if (this.state.items) {
      var multipleInputs = this.state.items.map((input, index) => {
        return (
          <Fragment key={index}>
            <div className="col-sm-12 series-items serie-item-block">
              <div className="row m-0 align-items-end">
                <div className="col-12 col-md-4 p-0 pr-md-2 pb-4">
                  <label htmlFor="Lloji i huazimit">Lloji i huazimit</label>
                  <Select
                    className={
                      "basic-select" +
                      (this.state.formError[`items.${index}.borrowing_type`]
                        ? " form-error"
                        : "")
                    }
                    classNamePrefix="select_input"
                    name={`items[${input.id}][borrowing_type]`}
                    isClearable={true}
                    placeholder="Lloji i huazimit"
                    onChange={(e) =>
                      this.itemsInputSelect(
                        e,
                        input.id,
                        "items[" + input.id + "][borrowing_type]",
                        index
                      )
                    }
                    value={this.checkSelectValue(
                      input[`items[${input.id}][borrowing_type]`]
                    )}
                    isSearchable={false}
                    options={[
                      { value: "salle", label: "Sallë" },
                      { value: "normal", label: "Normal" },
                      { value: "ipadisponueshem", label: "I Padisponueshëm" },
                    ]}
                  />
                  {this.state.formError[`items.${index}.borrowing_type`] && (
                    <span className="error-message">
                      {this.state.formError[`items.${index}.borrowing_type`]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4 p-0 px-md-2 pb-4">
                  <label htmlFor="Kodifikimi">Kodifikimi</label>
                  <input
                    placeholder="Kodifikimi"
                    className={
                      "input-form-input" +
                      (this.state.formError[`items.${index}.inventory_number`]
                        ? " form-error"
                        : "")
                    }
                    name={`items[${input.id}][inventory_number]`}
                    onChange={(e) =>
                      this.itemsInput(
                        e,
                        input.id,
                        "items[" + input.id + "][inventory_number]",
                        index
                      )
                    }
                    value={input[`items[${input.id}][inventory_number]`]}
                    type="text"
                  />
                  {this.state.formError[`items.${index}.inventory_number`] && (
                    <span className="error-message left">
                      {this.state.formError[`items.${index}.inventory_number`]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4 p-0 pl-md-2 pb-4">
                  <label htmlFor="Numri i vendit">Numri i vendit</label>
                  <input
                    placeholder="Numri i vendit"
                    className={
                      "input-form-input" +
                      (this.state.formError[`items.${index}.place_number`]
                        ? " form-error"
                        : "")
                    }
                    name={`items[${input.id}][place_number]`}
                    onChange={(e) =>
                      this.itemsInput(
                        e,
                        input.id,
                        "items[" + input.id + "][place_number]",
                        index
                      )
                    }
                    value={input[`items[${input.id}][place_number]`]}
                    type="text"
                  />
                  {this.state.formError[`items.${index}.place_number`] && (
                    <span className="error-message left">
                      {this.state.formError[`items.${index}.place_number`]}
                    </span>
                  )}
                </div>
              </div>
              {AppConfig.getRole() === "Administrator" && (
                <div className="row m-0">
                  <div className="d-block col-12 col-md-4 p-0 pr-md-2 pb-4">
                    <label htmlFor="Demtuar" className="main-label">
                      Dëmtuar:
                    </label>
                    <div className="radio-buttons d-flex">
                      <div className="radio-button mr-4">
                        <label htmlFor={"damaged_" + input.id}>Po</label>
                        <input
                          className="form-check-input"
                          name={"items[" + input.id + "][damaged]"}
                          type="radio"
                          id={"damaged_" + input.id}
                          onChange={(e) =>
                            this.itemsInputCheckbox(
                              e,
                              input.id,
                              "items[" + input.id + "][damaged]",
                              index
                            )
                          }
                          value="1"
                        />
                      </div>

                      <div className="radio-button ml-4">
                        <label htmlFor={"notdamaged_" + input.id}>Jo</label>
                        <input
                          className="form-check-input"
                          defaultChecked="checked"
                          name={"items[" + input.id + "][damaged]"}
                          onChange={(e) =>
                            this.itemsInputCheckbox(
                              e,
                              input.id,
                              "items[" + input.id + "][damaged]",
                              index
                            )
                          }
                          type="radio"
                          id={"notdamaged_" + input.id}
                          value="0"
                        />
                      </div>
                    </div>
                  </div>
                  {input[`items[${input.id}][damaged]`] === "1" && (
                    <Fragment>
                      <div className="col-12 col-md-4 p-0 px-md-2 pb-4">
                        <label htmlFor="Data e demtimit">Data e dëmtimit</label>
                        <DatePicker
                          placeholderText="Data kur është botuar"
                          name={"items[" + input.id + "][damaged_date]"}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          locale={sq}
                          dateFormat="dd-MM-yyyy"
                          selected={this.checkSelectedDate(
                            input[`items[${input.id}][damaged_date]`]
                          )}
                          onChange={(e) =>
                            this.itemsInputDate(
                              e,
                              input.id,
                              "items[" + input.id + "][damaged_date]",
                              index
                            )
                          }
                          autoComplete="off"
                          className={
                            "date_input" //+
                            //   (this.state.formError.start_subscription
                            //     ? " form-error"
                            //     : "")
                          }
                        />
                      </div>
                      <div className="col-12 col-md-4 p-0 pl-md-2 pb-4">
                        <label htmlFor="Data e rinovimit">
                          Data e rinovimit
                        </label>
                        <DatePicker
                          placeholderText="Data kur është botuar"
                          name={"items[" + input.id + "][renewal_date]"}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          locale={sq}
                          dateFormat="dd-MM-yyyy"
                          selected={this.checkSelectedDate(
                            input[`items[${input.id}][renewal_date]`]
                          )}
                          onChange={(e) =>
                            this.itemsInputDate(
                              e,
                              input.id,
                              "items[" + input.id + "][renewal_date]",
                              index
                            )
                          }
                          autoComplete="off"
                          className={
                            "date_input" //+
                            //   (this.state.formError.start_subscription
                            //     ? " form-error"
                            //     : "")
                          }
                        />
                      </div>
                    </Fragment>
                  )}
                </div>
              )}
              <div className="show-hide-btns d-flex justify-content-end">
                <button
                  className="btn-styled-2 addFields"
                  type="button"
                  id={"add_new_row"}
                  onClick={() => this.addNewRow(input.id)}
                >
                  +
                </button>
                {this.state.buttons.removeBtn === true && (
                  <button
                    type="button"
                    onClick={() => this.removeRow(input.id)}
                    id={"remove-btn"}
                    className="btn-styled-1 ml-2 removeFields"
                  >
                    -
                  </button>
                )}
              </div>
            </div>
          </Fragment>
        );
      });
    }
    return (
      <div className="col-12 p-0 mt-3 justify-content-between serie_add_items">
        <form onSubmit={this.updateGeneratedSerie}>
          <div className="col-12 col-md-10 p-0 series-items-block mb-0">
            {multipleInputs}
          </div>
          <div className="submit_add_items">
            {this.state.btnLoading === true ? (
              <button className="btn-styled-2">
                Duke u Ruajtur
                <img
                  src={LoadingGif}
                  style={{
                    width: "18px",
                    margin: "-4px 0 0 7px",
                  }}
                />
              </button>
            ) : (
              <button className="btn-styled-2">Ruaj</button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(EditSerieAddItem);
