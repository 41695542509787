import React, { Component, Fragment } from "react";
import Request from "../../../api/Request";
import Url from "../../../api/Url";
import Messages from "../../../helpers/Messages";
import AppConfig from "../../../services/AppConfig";
import loadingGif from "../../../assets/images/icons/loading.gif";
import "../../../assets/css/opac_modules.css";
import { Link } from "react-router-dom";
import Web from "../../../routes/Web";
import parse from "html-react-parser";
import sq from "date-fns/locale/sq";
import moment from "moment";
import noPostImg from "../../../assets/images/covers/Artikuj.svg";

class Mews extends Component {
  constructor() {
    super();
    this.state = {
      blogPosts: {
        data: [],
        loading: true,
      },
    };
  }

  componentDidMount() {
    this.blogPosts();
  }

  blogPosts = () => {
    let request = Url.blogPosts + 0;
    Request.getRequest(request).then((response) => {
      if (response) {
        if (response.status === 200) {
          this.setState({
            blogPosts: {
              data: response.data.posts,
              loading: false,
            },
          });
        }
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };
  render() {
    var blogPosts = "";
    if (this.state.blogPosts.loading === true) {
      blogPosts = (
        <img className="m-auto d-block mb-5" src={loadingGif} alt="loading" />
      );
    } else {
      blogPosts = this.state.blogPosts.data.map((post, index) => {
        var introImage = null;
        if (post.intro_image !== null) {
          introImage = AppConfig.blogPath + post.intro_image;
        } else {
          introImage = noPostImg;
        }
        return (
          <Fragment key={index}>
            <Link
              to={Web.newsPost + post.id + "-" + post.title.replace(/ /g, "-")}
              className="news_post"
            >
              <div
                className="img_post"
                style={{
                  backgroundImage: `url('${introImage}')`,
                }}
              ></div>

              <div className="news_main">
                <h1>{post.title}</h1>
                {post.description.length > 205
                  ? parse(post.description.substring(0, 200) + "...")
                  : parse(post.description.substring(0, 200))}
              </div>
            </Link>
          </Fragment>
        );
      });
    }

    return (
      <Fragment>
        {this.state.blogPosts.data.length !== 0 && (
          <div className="row mx-0 news_module_container">
            <h1>Lajme</h1>
            <div className="news_module">{blogPosts}</div>
            {this.state.blogPosts.data.length === 6 && (
              <Link to={Web.news} className="btn-front mt-2 mb-5 mx-auto">
                Shiko më shumë
              </Link>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

export default Mews;
