import React, { Component, Fragment } from "react";
import Paginate from "../../../helpers/Paginate";
import editIcon from "../../../assets/images/icons/edit_icon.svg";
import deleteIcon from "../../../assets/images/icons/delete_icon.svg";
import loadingGif from "../../../assets/images/icons/loading.gif";
import Url from "../../../api/Url";
import SearchFilter from "../../../helpers/SearchFilter";
import Request from "../../../api/Request";
import Pagination from "react-js-pagination";
import eyeIcon from "../../../assets/images/icons/syri.svg";
import { Link } from "react-router-dom";
import Web from "../../../routes/Web";
import Messages from "../../../helpers/Messages";
import AppConfig from "../../../services/AppConfig";

class StaffList extends Component {
  constructor() {
    super();
    this.state = {
      listStaff: {
        staff: [],
        loading: true,
      },
      pagination: Paginate.setPaginate(),
    };
  }

  componentDidMount() {
    this.listStaff();
  }

  listStaff = () => {
    let request = Url.staffList;

    const staff = Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          listStaff: {
            staff: response.data.users.data,
            loading: false,
          },
          pagination: Paginate.setPaginateData(response.data.users),
        });
      } else {
        this.setState({
          listStaff: {
            staff: [],
            loading: true,
          },
        });
      }
    });

    return staff;
  };

  delete = (e, id, name) => {
    Messages.swalConfirmDelete(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(Url.deleteStaff + id).then((response) => {
          if (response.status === 200) {
            this.listStaff();
            Messages.swalSuccess(response.data.success);
          } else if (response.status === 404) {
            Messages.swalError(response.data.error);
          } else {
            Messages.swalError(AppConfig.serverError);
          }
        });
      }
    });
  };

  handlePageChange = (pageNumber) => {
    let request = Url.staffList;
    var searchParams = {};

    searchParams = {
      page: pageNumber,
    };

    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listStaff: {
          staff: response.data.users.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.users),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    var staffList = "";
    if (this.state.listStaff.loading) {
      staffList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      staffList = this.state.listStaff.staff.map((item) => {
        return (
          <tr key={item.id}>
            <td data-label="Emër">{item.first_name}</td>
            <td data-label="Mbiemër" className="text-center ">
              {item.last_name}
            </td>
            <td data-label="Email" className="text-center email">
              {item.email}
            </td>
            <td data-label="Roli" className="text-center ">
              {item.role.name}
            </td>
            <td data-label="Statusi" className="text-center ">
              {item.token !== null ? "Jo Konfirmuar" : "Konfirmuar"}
            </td>
            <td role="cell" data-label="Veprime" className="text-center">
              <div className="row justify-content-around mr-auto ml-auto action-icons">
                <div className="col-3 show">
                  <Link to={Web.showStaff + item.id}>
                    <img src={eyeIcon} />
                  </Link>
                </div>
                <div className="col-3 edit">
                  <Link to={Web.editStaff + item.id}>
                    <button className="btn-unstyled">
                      <img src={editIcon} />
                    </button>
                  </Link>
                </div>
                <div className="col-3 delete">
                  <button
                    type="submit"
                    className="btn-unstyled"
                    onClick={(e) =>
                      this.delete(
                        e,
                        item.id,
                        item.first_name + " " + item.last_name
                      )
                    }
                  >
                    <img src={deleteIcon} />
                  </button>
                </div>
              </div>
            </td>
          </tr>
        );
      });
    }
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div>
            <p className="partial-title mb-5">Lista e stafit</p>
          </div>
          <div className="table-list mb-5">
            <table role="table" className="w-100">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" className="text-left">
                    Emër
                  </th>
                  <th role="columnheader" className="text-center">
                    Mbiemër
                  </th>
                  <th role="columnheader" className="text-center">
                    Email
                  </th>
                  <th role="columnheader" className="text-center">
                    Roli
                  </th>
                  <th role="columnheader" className="text-center">
                    Statusi
                  </th>
                  <th role="columnheader" className="text-center">
                    Veprime
                  </th>
                </tr>
              </thead>

              <tbody role="rowgroup">{staffList}</tbody>
            </table>
          </div>
          {this.state.pagination.totalPages > 1 && (
            <div className="d-flex justify-content-center flex-wrap pagination-block mb-5">
              <div className="select-page">
                <Pagination
                  activePage={this.state.pagination.activePage}
                  itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                  totalItemsCount={this.state.pagination.totalItemsCount}
                  pageRangeDisplayed={this.state.pagination.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="pagination-results">
                Faqja:
                {this.state.pagination.activePage} -{" "}
                {this.state.pagination.totalPages}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default StaffList;
