import React, { Component, Fragment } from "react";
import Url from "../../../api/Url";
import Request from "../../../api/Request";
import Select from "react-select";
import SearchFilter from "../../../helpers/SearchFilter";
import loadingGif from "../../../assets/images/icons/loading.gif";
import Paginate from "../../../helpers/Paginate";
import Pagination from "react-js-pagination";
import noResult from "../../../assets/images/icons/no_result.svg";
import { Link } from "react-router-dom";
import Web from "../../../routes/Web";
import eyeIcon from "../../../assets/images/icons/syri.svg";
import Messages from "../../../helpers/Messages";
import AppConfig from "../../../services/AppConfig";
import TooltipComp from "../../../helpers/Tooltip";
import LoadingGif from "../../../assets/images/icons/loading.gif";
class Borrow extends Component {
  constructor() {
    super();
    this.state = {
      listMaterials: {
        material_types: "",
        loading: true,
      },
      listResults: {
        results: [],
        loading: true,
      },
      search: {
        search_text: "",
        type: "",
        material_type: "",
        borrowing_type: "",
        nid: "",
      },
      search_type: {
        isMember: false,
        isMaterial: false,
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
      btnLoadingNid: false,
      hasMemberSubmit: false,
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.materialTypes();
  }

  materialTypes = () => {
    Request.getRequest(Url.materialTypes).then((response) => {
      if (response.status === 200) {
        this.setState({
          listMaterials: {
            material_types: response.data.material_types,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  searchMaterialOrMember = (e) => {
    e.preventDefault();
    this.setState({
      btnLoading: true,
    });
    const searchParams = {
      search_text: this.state.search.search_text,
      type: this.state.search.type,
      material_type: this.state.search.material_type,
      borrowing_type: this.state.search.borrowing_type,
    };

    const request = SearchFilter.setFilterParams(
      searchParams,
      Url.searchMaterialOrMember
    );

    Request.getRequest(request).then((response) => {
      this.setState({
        btnLoading: false,
      });
      if (response.status === 200) {
        this.setState({
          listResults: {
            results: response.data.results.data,
          },
          form_errors: {
            error_list: [],
          },
          pagination: Paginate.setPaginateData(response.data.results),
        });

        if (this.state.search.type === "member") {
          this.setState({
            search_type: {
              isMember: true,
              isMaterial: false,
            },
          });
        } else if (this.state.search.type === "material") {
          this.setState({
            search_type: {
              isMember: false,
              isMaterial: true,
            },
          });
        }
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          search_type: {
            isMember: false,
            isMaterial: false,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  searchMemberNID = (e) => {
    e.preventDefault();
    this.setState({
      btnLoadingNid: true,
    });
    const searchParams = {
      nid: this.state.search.nid,
    };

    const request = Url.searchNID;

    Request.postRequest(request, searchParams).then((response) => {
      this.setState({
        btnLoadingNid: false,
      });
      if (response.status === 200) {
        this.setState({
          listResults: {
            results: [response.data.user],
          },
          form_errors: {
            error_list: [],
          },
        });

        this.setState({
          search_type: {
            isMember: true,
            isMaterial: false,
          },
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          search_type: {
            isMember: false,
            isMaterial: false,
          },
        });
      } else if (response.status === 403) {
        Messages.swalError(response.data.error);
      } else if (response.status === 400) {
        Messages.swalError(response.data.error);
      } else {
        if (response.data.error) {
          Messages.swalError(response.data.error);
        } else {
          Messages.swalError(AppConfig.serverError);
        }
      }
    });
  };

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
      if (e.value === "member") {
        this.setState({
          hasMemberSubmit: true,
        });
      } else {
        this.setState({
          hasMemberSubmit: false,
        });
      }
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.search,
      [key]: selectValue,
    };
    this.setState({
      search: newForm,
    });
  };

  handleChangeInput = (key) => (e) => {
    let newForm = {
      ...this.state.search,
      [key]: e.target.value,
    };
    this.setState({
      search: newForm,
    });
  };

  handlePageChange(pageNumber) {
    let request = Url.searchMaterialOrMember;
    var searchParams = {
      search_text: this.state.search.search_text,
      type: this.state.search.type,
      material_type: this.state.search.material_type,
      borrowing_type: this.state.search.borrowing_type,
      page: pageNumber,
    };

    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listResults: {
          results: response.data.results.data,
        },
        pagination: Paginate.setPaginateData(response.data.results),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    let { search_text } = this.state.search;

    if (!this.state.listMaterials.loading) {
      var materials = this.state.listMaterials.material_types.map(
        (materialType) => {
          return { value: materialType.id, label: materialType.name };
        }
      );
    }

    var listResults = "";
    if (this.state.listResults.loading) {
      listResults = (
        <tr>
          <td colSpan="7">
            <img className="m-auto" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      listResults = this.state.listResults.results.map((result, index) => {
        if (this.state.search_type.isMember === true) {
          return (
            <tr key={index}>
              <td
                role="cell"
                data-label="Emër"
                className="pb-3 title-borrowing"
              >
                {result.first_name}
              </td>
              <td role="cell" data-label="Mbiemër" className="text-center">
                {result.last_name}
              </td>
              <td role="cell" data-label="Email" className=" text-center">
                {result.email}
              </td>
              <td role="cell" data-label="Shiko" className="text-center">
                <Link to={Web.showMember + result.id} className="">
                  <img src={eyeIcon} alt="show" />
                </Link>
              </td>
            </tr>
          );
        } else if (
          this.state.search_type.isMaterial === true &&
          this.state.hasMemberSubmit === false
        ) {
          const Authors = result.biblio.authors.map((author) => {
            return author.name;
          });

          const freeItems = result.items.map((freeItem) => {
            return freeItem.id;
          });

          return (
            <tr key={index}>
              <td role="cell" data-label="Titull" className="">
                {TooltipComp.tooltipDisabledMobile(index, result.biblio.title)}
              </td>
              <td role="cell" data-label="Isbn/Issn" className=" text-center">
                <span className="isbn-issn">{result.isbn || result.issn}</span>
              </td>
              <td role="cell" data-label="Botues" className="text-center">
                {!result.publisher ? "" : result.publisher.name}
              </td>
              <td role="cell" data-label="Autori" className="text-center">
                {Authors.join("; ")}
              </td>
              <td
                role="cell"
                data-label="Nr. i kopjeve të lira"
                className="text-center"
              >
                {result.items_count} <br />
              </td>
              <td
                role="cell"
                data-label="Përzgjidh"
                className="text-center pl-0"
              >
                {result.items_count > 0 && (
                  <Link
                    to={Web.searchMember + freeItems[0] + "/member"}
                    className="btn-styled-1 small_btn"
                  >
                    Pëzgjidh
                  </Link>
                )}

                {result.items_count === 0 && (
                  <span
                    className="d-inline-block "
                    tabIndex="0"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Nuk ka kopje të lira"
                  >
                    <button
                      className="btn-styled-1 disabled small_btn"
                      style={{ pointerEvents: "none" }}
                      type="button"
                      disabled
                    >
                      Përzgjidh
                    </button>
                  </span>
                )}
              </td>
            </tr>
          );
        }
      });
    }

    return (
      <Fragment>
        <div className="container-fluid small-offset-left list-biblioitems">
          <p className="partial-title mb-5">Kërko për material ose anëtarë</p>
          <div className="search-item">
            <form onSubmit={this.searchMaterialOrMember}>
              <div className="row">
                <div className="col-12 col-sm-6 col-md-4 pb-4 mb-2">
                  <input
                    placeholder="Kërko "
                    onChange={this.handleChangeInput("search_text")}
                    className={
                      "input-form-input" +
                      (this.state.form_errors.error_list.search_text
                        ? " form-error"
                        : "")
                    }
                    name="search_text"
                    type="text"
                    value={search_text}
                  />
                  {this.state.form_errors.error_list.search_text && (
                    <span className="error-message left">
                      {this.state.form_errors.error_list.search_text[0]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-4 pb-4 mb-2">
                  <Select
                    className={
                      "basic-select" +
                      (this.state.form_errors.error_list.type
                        ? " form-error"
                        : "")
                    }
                    classNamePrefix="select_input"
                    name="type"
                    isSearchable={false}
                    onChange={this.handleChangeSelect("type")}
                    placeholder="Specifikimi"
                    isClearable={true}
                    options={[
                      { value: "member", label: "Anetare" },
                      { value: "material", label: "Material" },
                    ]}
                  />
                  {this.state.form_errors.error_list.type && (
                    <span className="error-message left">
                      {this.state.form_errors.error_list.type[0]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-4 text-left search-sub-btn pb-4 mb-2">
                  {this.state.btnLoading === true ? (
                    <button className="btn-styled-2 px-5 ml-0">
                      Duke Kërkuar
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button className="btn-styled-2 px-5 ml-0">Kërko</button>
                  )}
                </div>
                {this.state.search.type === "material" && (
                  <div className="row box lloji1 m-0 w-100">
                    <div className="col-12 col-sm-6 col-md-4 pb-4 mb-2">
                      <Select
                        name="material_type"
                        className={
                          "basic-select" +
                          (this.state.form_errors.error_list.material_type
                            ? " form-error"
                            : "")
                        }
                        isSearchable={false}
                        isClearable={true}
                        onChange={this.handleChangeSelect("material_type")}
                        classNamePrefix="select_input"
                        placeholder="Lloji i materialit"
                        options={materials}
                      />
                      {this.state.form_errors.error_list.material_type && (
                        <span className="error-message left">
                          {this.state.form_errors.error_list.material_type[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 pb-4 mb-2">
                      <Select
                        className={
                          "basic-select" +
                          (this.state.form_errors.error_list.borrowing_type
                            ? " form-error"
                            : "")
                        }
                        classNamePrefix="select_input"
                        name="borrowing_type"
                        isSearchable={false}
                        isClearable={true}
                        onChange={this.handleChangeSelect("borrowing_type")}
                        placeholder="Lloji i huazimit"
                        options={[
                          { value: "normal", label: "Normal" },
                          { value: "salle", label: "Salle" },
                        ]}
                      />
                      {this.state.form_errors.error_list.borrowing_type && (
                        <span className="error-message left">
                          {this.state.form_errors.error_list.borrowing_type[0]}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </form>
            <div class="lined-title mt-3 mb-5">
              <span>Ose</span>
            </div>
            <form onSubmit={this.searchMemberNID}>
              <div className="row mb-4 pb-4">
                <label className="col-12">Kërko Numrin e Identifikimit</label>
                <div className="col-12 col-sm-6 col-md-4 pb-4 mb-2">
                  <input
                    placeholder="Kërko NID"
                    onChange={this.handleChangeInput("nid")}
                    className={
                      "input-form-input" +
                      (this.state.form_errors.error_list.nid
                        ? " form-error"
                        : "")
                    }
                    name="search_text"
                    type="text"
                    value={this.state.search.nid}
                  />
                  {this.state.form_errors.error_list.nid && (
                    <span className="error-message left">
                      {this.state.form_errors.error_list.nid[0]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-4 text-left search-sub-btn pb-4 mb-2">
                  {this.state.btnLoadingNid === true ? (
                    <button className="btn-styled-2 px-5 ml-0">
                      Duke Kërkuar
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button className="btn-styled-2 px-5 ml-0">Kërko</button>
                  )}
                </div>
              </div>
            </form>
          </div>

          {this.state.search_type.isMember === true && (
            <div className="table-list">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th role="columnheader" className="text-left">
                      Emër
                    </th>
                    <th role="columnheader" className="pr-2 text-center">
                      Mbiemër
                    </th>
                    <th role="columnheader" className="pr-2 text-center">
                      Email
                    </th>
                    <th role="columnheader" className="pr-2 text-center">
                      Shiko
                    </th>
                  </tr>
                </thead>
                {this.state.listResults.results.length !== 0 && (
                  <tbody>{listResults}</tbody>
                )}
              </table>
              {this.state.listResults.results.length === 0 && (
                <img
                  className="mx-auto d-block"
                  src={noResult}
                  alt="no result"
                />
              )}
            </div>
          )}

          {this.state.search_type.isMaterial === true && (
            <div className="table-list">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th width="20%" role="columnheader" className="text-left">
                      Titulli
                    </th>
                    <th
                      width="10%"
                      role="columnheader"
                      className=" text-center"
                    >
                      Isbn/Issn
                    </th>
                    <th width="15%" role="columnheader" className="text-center">
                      Botues
                    </th>
                    <th
                      width="20%"
                      role="columnheader"
                      className=" text-center"
                    >
                      Autori
                    </th>
                    <th
                      width="10%"
                      role="columnheader"
                      className=" text-center"
                    >
                      Nr. i kopjeve të lira
                    </th>
                    <th
                      width="20%"
                      role="columnheader"
                      className=" text-center"
                    >
                      Përzgjidh
                    </th>
                  </tr>
                </thead>
                <tbody>{listResults}</tbody>
              </table>
              {this.state.listResults.results.length === 0 && (
                <img
                  className="mx-auto d-block"
                  src={noResult}
                  alt="no result"
                />
              )}
            </div>
          )}

          <div className="container-fluid pt-5 pb-4">
            <div className="d-flex justify-content-center pagination-block">
              {this.state.pagination.totalPages > 1 &&
                (this.state.search_type.isMaterial === true ||
                  this.state.search_type.isMember === true) && (
                  <div className="d-flex justify-content-center pagination-block mb-5">
                    <div className="select-page">
                      <Pagination
                        activePage={this.state.pagination.activePage}
                        itemsCountPerPage={
                          this.state.pagination.itemsCountPerPage
                        }
                        totalItemsCount={this.state.pagination.totalItemsCount}
                        pageRangeDisplayed={
                          this.state.pagination.pageRangeDisplayed
                        }
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                    <div className="pagination-results">
                      Faqja: {this.state.pagination.activePage} -{" "}
                      {this.state.pagination.totalPages}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Borrow;
