import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import Request from "../../api/Request";
import Url from "../../api/Url";
import AppConfig from "../../services/AppConfig";
import parse from "html-react-parser";
import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import LinkedinIcon from "../../assets/images/icons/linkedin_icon.svg";
import FacebookIcon from "../../assets/images/icons/facebook_icon.svg";
import WhatsappIcon from "../../assets/images/icons/whatsapp_icon.svg";
import TwitterIcon from "../../assets/images/icons/twitter_icon.svg";
import loadingGif from "../../assets/images/icons/loading.gif";
import { Link } from "react-router-dom";
import Web from "../../routes/Web";
import Messages from "../../helpers/Messages";
class NewsPost extends Component {
  constructor() {
    super();
    this.state = {
      postDetails: {
        id: "",
        title: "",
        description: "",
        intro_image: "",
        loading: true,
      },
      shareUrl: "",
    };
  }

  componentDidMount() {
    this.postDetails();
  }

  postDetails = () => {
    const postTitle = this.props.match.params.title;
    let request = Url.showBlogPost + postTitle;

    Request.getRequest(request).then((response) => {
      if (response) {
        this.setState({
          postDetails: {
            id: response.data.post.id,
            title: response.data.post.title,
            description: response.data.post.description,
            intro_image: response.data.post.intro_image,
            loading: false,
          },
          shareUrl: response.data.share_url,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  render() {
    var blogPostDetails = "";
    var post = this.state.postDetails;
    if (post.loading) {
      blogPostDetails = (
        <img className="m-auto d-block" src={loadingGif} alt="loading" />
      );
    } else {
      blogPostDetails = (
        <Fragment>
          <h3 className="post_title mb-4 px-0 px-md-4">{post.title}</h3>
          <div className="post_image mb-4 px-0 px-md-4">
            {post.intro_image !== null && (
              <img
                src={AppConfig.blogPath + post.intro_image}
                alt={post.title}
                width="100%"
                height="300px"
              />
            )}
          </div>
          <div className="post_desc mb-4 px-0 px-md-4">
            {parse(post.description)}
          </div>
          <div className="share_icons px-0 px-md-4">
          <WhatsappShareButton
            url={this.state.shareUrl}
            className="mr-3 btn-unstyled"
          >
            <img src={WhatsappIcon} />
          </WhatsappShareButton>
          <TwitterShareButton
              url={this.state.shareUrl}
            className="mr-3 btn-unstyled"
          >
            <img src={TwitterIcon} />
          </TwitterShareButton>
          <FacebookShareButton
              url={this.state.shareUrl}
            className="mr-3 btn-unstyled"
          >
            <img src={FacebookIcon} />
          </FacebookShareButton>
          <LinkedinShareButton
            url={this.state.shareUrl}
            className="btn-unstyled"
          >
            <img src={LinkedinIcon} />
          </LinkedinShareButton>
        </div>
        </Fragment>
      );
    }

    return (
      <Fragment>

        <div id="content">
          <div id="news_post" className="bl_page">
            <div className="mt-4 mb-5">
              <div className="b--breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item">
                      <Link to={Web.homepage}>Biblioteka</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={Web.news}>Lajme</Link>
                    </li>
                    <li
                      className="breadcrumb-item active b--title"
                      aria-current="page"
                    >
                      {this.state.postDetails.title}
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="blog_post p-4 p-md-5">{blogPostDetails}</div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(NewsPost);
