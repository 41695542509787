import React from 'react';
import { Route, Redirect } from 'react-router';
class PrivateRoutes {
  static ProtectedRoute = ({
    auth,
    roles,
    firstName,
    lastName,
    component: Component,
    ...rest
  }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (auth) {
            return (
              <Component
                role={roles}
                firstName={firstName}
                lastName={lastName}
                {...props}
              />
            );
          }
          if (!auth) {
            return (
              <Redirect to={{ path: '/', state: { from: props.location } }} />
            );
          }
        }}
      />
    );
  };
}

export default PrivateRoutes;
