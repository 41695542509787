import React, { Component, Fragment } from "react";
import Request from "../../../../api/Request";
import Url from "../../../../api/Url";
import Pagination from "react-js-pagination";
import Messages from "../../../../helpers/Messages";
import Paginate from "../../../../helpers/Paginate";
import { withRouter } from "react-router-dom";
import SearchFilter from "../../../../helpers/SearchFilter";
import EditAuthor from "./EditAuthor";
import AppConfig from "../../../../services/AppConfig";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
import editIcon from "../../../../assets/images/icons/edit_icon.svg";
import deleteIcon from "../../../../assets/images/icons/delete_icon.svg";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import noResult from "../../../../assets/images/icons/no_result.svg";

class CreateAuthor extends Component {
  constructor() {
    super();
    this.state = {
      listAuthor: {
        authors: [],
        loading: true,
      },
      addAuthor: {
        name: "",
        error_list: [],
        isError: false,
      },
      searchAuthor: {
        author_name: "",
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
      btnAddLoading: false,
      searched: false,
      pagination: Paginate.setPaginate(),
      page: null,
      showModalPopup: "",
      popupId: "",
      popupName: "",
    };
  }

  isShowPopup = (status, modified) => {
    this.setState({ showModalPopup: status });
    if (modified) {
      this.listAuthors();
    }
  };

  componentDidMount() {
    this.listAuthors();
  }

  getAuthorData = (e, id, name) => {
    e.preventDefault();
    this.setState({
      popupId: id,
      popupName: name,
    });
  };

  listAuthors = (hasSearch) => {
    let request = Url.listAuthors;
    const searchParams = {
      author_name: this.state.searchAuthor.author_name,
    };
    if (hasSearch) {
      hasSearch.preventDefault();
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({ searched: true });
      this.setState({ btnLoading: true });
    }
    const authors = Request.getRequest(request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          listAuthor: {
            authors: response.data.authors.data,
            loading: false,
          },
          form_errors: {
            error_list: [],
          },
          pagination: Paginate.setPaginateData(response.data.authors),
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          searched: false,
        });
      } else {
        this.setState({
          searched: false,
        });
        Messages.swalError(AppConfig.serverError);
      }
    });
    return authors;
  };

  handleReset = (e) => {
    setTimeout(() => {
      this.setState({
        name: "",
      });
    }, 100);
  };

  handleInput = (e) => {
    this.setState({
      addAuthor: {
        [e.target.name]: e.target.value,
      },
    });
  };

  handlePageChange = (pageNumber) => {
    let request = Url.listAuthors;
    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        author_name: this.state.searchAuthor.author_name,
        page: pageNumber,
      };
    }
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listAuthor: {
          authors: response.data.authors.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.authors),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  saveAuthor = (e) => {
    e.preventDefault();
    this.setState({
      btnAddLoading: true,
    });
    Request.postRequest(Url.storeAuthor, this.state.addAuthor).then(
      (response) => {
        this.setState({
          btnAddLoading: false,
        });
        if (response.status === 201) {
          this.listAuthors();
          Messages.swalSuccess(response.data.success);
          this.setState({
            addAuthor: {
              name: "",
              error_list: [],
              isError: false,
            },
          });
        } else if (response.status === 422) {
          this.setState({
            addAuthor: {
              error_list: response.data.name[0],
              isError: true,
            },
          });
        }
      }
    );
  };

  searchHandler = (e) => {
    this.setState({
      searchAuthor: {
        [e.target.name]: e.target.value,
      },
    });
  };

  deleteAuthor = (e, id, name) => {
    Messages.swalConfirmDelete(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(Url.deleteAuthor + id).then((response) => {
          if (response.status === 200) {
            this.listAuthors();
            Messages.swalSuccess(response.data.success);
          } else if (response.status === 405) {
            Messages.swalError(response.data.error);
          } else if (response.status === 404) {
            Messages.swalError(response.data.error);
          }
        });
      }
    });
  };

  render() {
    var authorList = "";
    if (this.state.listAuthor.loading) {
      authorList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      authorList = this.state.listAuthor.authors.map((authorList, id) => {
        return (
          <tr key={authorList.id}>
            <td role="cell" data-label="Emri i autorit">
              {authorList.name}
            </td>
            <td role="cell" data-label="Ndrysho" className="text-center">
              <span
                className="m-auto"
                onClick={() => this.isShowPopup(authorList.id)}
              >
                <button
                  className="btn-unstyled"
                  onClick={(e) =>
                    this.getAuthorData(e, authorList.id, authorList.name)
                  }
                >
                  <img src={editIcon} alt="Edit Icon" />
                </button>
              </span>
              {this.state.showModalPopup === authorList.id && (
                <EditAuthor
                  showModalPopup={this.state.showModalPopup.toString()}
                  onPopupClose={this.isShowPopup}
                  author={authorList}
                  popupName={this.state.popupName}
                  popupId={this.state.popupId.toString()}
                />
              )}
            </td>
            <td role="cell" data-label="Fshi" className="text-center">
              <button
                type="button"
                onClick={(e) =>
                  this.deleteAuthor(e, authorList.id, authorList.name)
                }
                className="btn-unstyled"
              >
                <img src={deleteIcon} alt="Delete Icon" />
              </button>
            </td>
          </tr>
        );
      });
    }

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="create">
            <div>
              <p className="partial-title">Autor</p>
            </div>
            <div className="row m-0">
              <div className="col-12">
                <form onSubmit={this.saveAuthor}>
                  <div className="row colored-bg px-3 mb-4 pb-3">
                    <label className="pl-3">Shto autor</label>
                    <div className="col-12 col-lg-3 col-md-5 mb-4 mb-md-0 pb-4">
                      <input
                        type="text"
                        name="name"
                        value={this.state.addAuthor.name}
                        onChange={this.handleInput}
                        placeholder="Autor"
                        className={
                          "input-form-input" +
                          (this.state.addAuthor.isError === true
                            ? " form-error"
                            : "")
                        }
                      />
                      {this.state.addAuthor.isError === true && (
                        <span className="error-message left">
                          {this.state.addAuthor.error_list}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-lg-3 col-md-4">
                      {this.state.btnAddLoading === true ? (
                        <button type="button" className="btn-styled-2 ml-0">
                          Duke Shtuar
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={this.handleReset}
                          className="btn-styled-2 ml-0"
                        >
                          Shto
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                <form onSubmit={this.listAuthors}>
                  <div className="row pl-0 colored-sb pl-3 pr-3">
                    <label className="pl-3">Kërko autor</label>
                    <div className="col-12 col-lg-3 col-md-5 pb-4 mb-md-0">
                      <input
                        type="text"
                        name="author_name"
                        placeholder="Autor"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.author_name
                            ? " form-error"
                            : "")
                        }
                        onChange={this.searchHandler}
                      />
                      {this.state.form_errors.error_list.author_name && (
                        <span className="error-message left">
                          {this.state.form_errors.error_list.author_name[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-lg-3 col-md-4">
                      {this.state.btnLoading === true ? (
                        <button
                          type="button"
                          name="submit"
                          className="btn-styled-2 ml-0"
                        >
                          Duke Kërkuar
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          name="submit"
                          className="btn-styled-2 ml-0"
                        >
                          Kërko
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="table-list mt-5 mb-5">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th role="columnheader" width="70%" className="text-left">
                      Emri i autorit
                    </th>
                    <th role="columnheader" width="15%" className="text-center">
                      Ndrysho
                    </th>
                    <th role="columnheader" width="15%" className="text-center">
                      Fshi
                    </th>
                  </tr>
                </thead>
                <tbody>{authorList}</tbody>
              </table>
              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listAuthor.loading === false &&
                this.state.searched === true && (
                  <img
                    className="mx-auto d-block"
                    src={noResult}
                    alt="no result"
                  />
                )}

              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listAuthor.loading === false &&
                this.state.searched === false && (
                  <p className="nothing--to--show">Asgjë për të shfaqur</p>
                )}
            </div>
          </div>
          {this.state.pagination.totalPages > 1 && (
            <div className="d-flex justify-content-center flex-wrap pagination-block mb-5">
              <div className="select-page">
                <Pagination
                  activePage={this.state.pagination.activePage}
                  itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                  totalItemsCount={this.state.pagination.totalItemsCount}
                  pageRangeDisplayed={this.state.pagination.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="pagination-results">
                Faqja:
                {this.state.pagination.activePage} -{" "}
                {this.state.pagination.totalPages}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(CreateAuthor);
