import React, { Component, Fragment } from 'react';
import Request from '../../../api/Request';
import Url from '../../../api/Url';
import { Link, withRouter } from 'react-router-dom';
import SearchFilter from '../../../helpers/SearchFilter';
import loadingGif from '../../../assets/images/icons/loading.gif';
import Paginate from '../../../helpers/Paginate';
import Pagination from 'react-js-pagination';
import Web from '../../../routes/Web';
import noResult from '../../../assets/images/icons/no_result.svg';
import Messages from '../../../helpers/Messages';
import AppConfig from '../../../services/AppConfig';
import LoadingGif from '../../../assets/images/icons/loading.gif';
class SearchMember extends Component {
  constructor() {
    super();
    this.state = {
      materialDetails: {
        details: '',
        biblio: '',
        loading: true,
      },
      memberSearch: {
        search_text: '',
        search_nid: '',
      },
      form_errors: {
        error_list: [],
      },
      memberList: {
        members: [],
        loading: true,
      },
      btnLoading: false,
      btnLoadingNID: false,
      has_search: false,
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.materialDetails();
  }

  materialDetails = () => {
    const material_id = this.props.match.params.id;
    Request.getRequest(Url.searchMember + material_id + '/member').then(
      (response) => {
        if (response.status === 200) {
          this.setState({
            materialDetails: {
              details: response.data.item,
              biblio: response.data.item.biblioitem.biblio,
            },
          });
        } else {
          Messages.swalError(AppConfig.serverError);
        }
      }
    );
  };

  searchMember = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const material_id = this.props.match.params.id;

    const searchParams = {
      search_text: this.state.memberSearch.search_text,
    };

    const request = SearchFilter.setFilterParams(
      searchParams,
      Url.searchMember + material_id + '/member'
    );

    Request.getRequest(request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          memberList: {
            members: response.data.members.data,
          },
          form_errors: {
            error_list: [],
          },
          has_search: true,
          pagination: Paginate.setPaginateData(response.data.members),
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          has_search: false,
        });
      } else if (response.status === 404) {
        this.props.history.push(Web.borrow);
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };
  searchMemberNID = (e) => {
    e.preventDefault();
    this.setState({ btnLoadingNID: true });

    const searchParams = {
      nid: this.state.memberSearch.search_nid,
    };

    Request.postRequest(Url.searchNID, searchParams).then((response) => {
      this.setState({ btnLoadingNID: false });
      if (response.status === 200) {
        this.setState({
          memberList: {
            members: [response.data.user],
          },
          form_errors: {
            error_list: [],
          },
          has_search: true,
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          has_search: false,
        });
      } else if (response.status === 400) {
        Messages.swalError(response.data.error);
      } else if (response.status === 403) {
        Messages.swalError(response.data.error);
      } else if (response.status === 404) {
        this.props.history.push(Web.borrow);
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  handleChangeInput = (key) => (e) => {
    let newForm = {
      ...this.state.memberSearch,
      [key]: e.target.value,
    };
    this.setState({
      memberSearch: newForm,
    });
  };

  handlePageChange(pageNumber) {
    const material_id = this.props.match.params.id;

    let request = Url.searchMember + material_id + '/member';

    var searchParams = {
      search_text: this.state.memberSearch.search_text,
      page: pageNumber,
    };

    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        memberList: {
          members: response.data.members.data,
        },
        pagination: Paginate.setPaginateData(response.data.members),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render() {
    const materialDetail = this.state.materialDetails.details;

    const itemID = this.props.match.params.id;

    var memberList = '';
    if (this.state.memberList.loading) {
      memberList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      memberList = this.state.memberList.members.map((member, index) => {
        return (
          <tr key={index} role="row">
            <td role="cell" data-label="Emër" className="">
              {member.first_name}
            </td>
            <td role="cell" data-label="Mbiemër" className="text-center">
              {member.last_name}
            </td>
            <td role="cell" data-label="Email" className="text-center">
              {member.email}
            </td>
            <td role="cell" data-label="Përzgjidh" className="text-center">
              <Link
                to={Web.confirmBorrow + member.id + '/item/' + itemID}
                className="btn-styled-1 small_btn"
              >
                Pëzgjidh
              </Link>
            </td>
          </tr>
        );
      });
    }
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="">
            <p className="partial-title mb-5">Detaje Materiali</p>
            <div className="member-data row mb-5">
              <div className="list-data col-12 col-md-5 col-lg-4">
                <ul>
                  <li className="member-detail">
                    Kodifikimi : {materialDetail.inventory_number}
                  </li>
                  <li>Numri i inventarit : {materialDetail.id}</li>
                  <li>Titulli : {this.state.materialDetails.biblio.title}</li>
                </ul>
              </div>
              <div className="data-btn col-12 col-md-7 col-lg-8">
                <div className="mt-5 mt-md-0 p-0 ml-auto search--member">
                  <form onSubmit={this.searchMember}>
                    <div className="row mb-4 justify-content-end">
                      <label className="col-12">Kërko Anëtarin</label>
                      <div className="col-12 col-sm-7 col-xl-8 pb-4">
                        <input
                          placeholder="Kërko anetarin"
                          className={
                            'input-form-input' +
                            (this.state.form_errors.error_list.search_text
                              ? ' form-error'
                              : '')
                          }
                          name="search_text"
                          onChange={this.handleChangeInput('search_text')}
                          type="text"
                        />
                        {this.state.form_errors.error_list.search_text && (
                          <span className="error-message left">
                            {this.state.form_errors.error_list.search_text[0]}
                          </span>
                        )}
                      </div>
                      <div className="col-12 col-sm-5 col-xl-4">
                        {this.state.btnLoading === true ? (
                          <button className="btn-styled-2 px-5 mt-0">
                            Duke Kërkuar
                            <img
                              src={LoadingGif}
                              style={{
                                width: '18px',
                                margin: '-4px 0 0 7px',
                              }}
                            />
                          </button>
                        ) : (
                          <button className="btn-styled-2 px-5 mt-0">
                            Kërko
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                  <div class="lined-title mt-3 mb-5">
                    <span>Ose</span>
                  </div>
                  <form onSubmit={this.searchMemberNID}>
                    <div className="row mb-5 justify-content-end">
                      <label className="col-12">
                        Kërko Anëtarin me Numrin e Identifikimit
                      </label>
                      <div className="col-12 col-sm-7 col-xl-8 pb-4">
                        <input
                          placeholder="Kërko NID"
                          className={
                            'input-form-input' +
                            (this.state.form_errors.error_list.nid
                              ? ' form-error'
                              : '')
                          }
                          name="search_nid"
                          onChange={this.handleChangeInput('search_nid')}
                          type="text"
                        />
                        {this.state.form_errors.error_list.nid && (
                          <span className="error-message left">
                            {this.state.form_errors.error_list.nid[0]}
                          </span>
                        )}
                      </div>
                      <div className="col-12 col-sm-5 col-xl-4">
                        {this.state.btnLoadingNID === true ? (
                          <button className="btn-styled-2 px-5 mt-0">
                            Duke Kërkuar
                            <img
                              src={LoadingGif}
                              style={{
                                width: '18px',
                                margin: '-4px 0 0 7px',
                              }}
                            />
                          </button>
                        ) : (
                          <button className="btn-styled-2 px-5 mt-0">
                            Kërko
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {this.state.has_search === true && (
            <div className="table-list">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th
                      width="20%"
                      role="columnheader"
                      className="pr-3 header-list text-left"
                    >
                      Emër
                    </th>
                    <th
                      width="20%"
                      role="columnheader"
                      className="pr-3 header-list text-center"
                    >
                      Mbiemër
                    </th>
                    <th
                      width="20%"
                      role="columnheader"
                      className="pr-3 header-list text-center"
                    >
                      Email
                    </th>
                    <th
                      width="20%"
                      role="columnheader"
                      className="pr-3 header-list text-center"
                    >
                      Përzgjidh
                    </th>
                  </tr>
                </thead>
                <tbody>{memberList}</tbody>
              </table>
              {this.state.memberList.members.length === 0 && (
                <img
                  className="mx-auto d-block"
                  src={noResult}
                  alt="no result"
                />
              )}
            </div>
          )}

          <div className="container-fluid pt-5 pb-4">
            <div className="d-flex justify-content-center pagination-block">
              {this.state.pagination.totalPages > 1 &&
                this.state.has_search === true && (
                  <div className="d-flex justify-content-center pagination-block mb-5">
                    <div className="select-page">
                      <Pagination
                        activePage={this.state.pagination.activePage}
                        itemsCountPerPage={
                          this.state.pagination.itemsCountPerPage
                        }
                        totalItemsCount={this.state.pagination.totalItemsCount}
                        pageRangeDisplayed={
                          this.state.pagination.pageRangeDisplayed
                        }
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                    <div className="pagination-results">
                      Faqja: {this.state.pagination.activePage} -{' '}
                      {this.state.pagination.totalPages}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(SearchMember);
