import { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import Url from "../../../../api/Url";
import Messages from "../../../../helpers/Messages";
import AppConfig from "../../../../services/AppConfig";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import { withRouter } from "react-router";
import uploadIcon from "../../../../assets/images/icons/upload-icon.svg";
import Request from "../../../../api/Request";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
class EditSeriePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.serieId,
      name: this.props.pattern,
      serieUpdate: {
        serial_id: this.props.match.params.id,
        pattern: this.props.pattern,
        published_date: this.props.publishedDate,
      },
      cover: null,
      temporary_img_path: this.props.cover,
      errorList: [],
      btnLoading: false,
    };
  }

  isShowModal = (status) => {
    this.handleClose();
    this.setState({
      showModal: status,
    });
  };

  handleClose = () => {
    this.props.onPopupClose(false);
    const serieId = this.state.id;
    let request = Url.editSerie + serieId;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          name: response.data.serie.pattern,
          serieUpdate: {
            serial_id: this.props.match.params.id,
            pattern: response.data.serie.pattern,
            published_date: response.data.serie.published_date,
          },
          cover: null,
          temporary_img_path: response.data.serie.cover,
        });
      }
    });
  };

  handleInput = (e) => {
    let newForm = {
      ...this.state.serieUpdate,
      [e.target.name]: e.target.value,
    };
    this.setState({
      serieUpdate: newForm,
    });
  };

  handleChangeD = (key) => (e) => {
    var value = "";
    if (e !== null) {
      value = moment(e).format("yyyy-MM-DD");
    }
    let newForm = {
      ...this.state.serieUpdate,
      [key]: value,
    };
    this.setState({
      serieUpdate: newForm,
    });
  };

  checkSelectedDate = (value) => {
    if (value === "" || value === null) {
      return null;
    } else {
      return new Date(value);
    }
  };

  updateSerie = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const serialId = this.state.id;
    let request = Url.updateSerie + serialId;
    const formData = new FormData();
    if (this.state.cover !== null) {
      formData.append("cover", this.state.cover);
    }
    formData.append("serial_id", this.state.serieUpdate.serial_id);
    formData.append("pattern", this.state.serieUpdate.pattern);
    formData.append("published_date", this.state.serieUpdate.published_date);
    formData.append("_method", "PATCH");
    Request.postRequest(request, formData).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        Messages.swalSuccess(response.data.success);
        this.handleClose();
      } else if (response.status === 405) {
        Messages.swalInfo(response.data.info);
        this.handleClose();
      } else if (response.status === 422) {
        this.setState({
          errorList: response.data,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
        this.handleClose();
      }
    });
  };

  fileSelectHandler = (e) => {
    this.setState({
      cover: e.target.files[0],
      temporary_img_path: URL.createObjectURL(e.target.files[0]),
    });
  };

  removeImage = () => {
    this.setState({
      cover: null,
      temporary_img_path: null,
    });
  };

  deleteIntroImage = (e, id) => {
    Messages.swalConfirmDeleteImage().then((result) => {
      if (result.isConfirmed) {
        Request.deleteRequest(Url.deleteSerieCover + id).then((response) => {
          if (response.status === 200) {
            Messages.swalSuccess(response.data.success);
            const serieId = this.state.id;
            let request = Url.editSerie + serieId;
            Request.getRequest(request).then((response) => {
              if (response.status === 200) {
                this.setState({
                  cover: null,
                  temporary_img_path: response.data.serie.cover,
                });
              }
            });
            this.setState({
              temporary_img_path: null,
            });
          } else if (response.status === 404) {
            Messages.swalError(response.data.error);
          }
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <Modal
          show={this.props.showModalPopup}
          onHide={this.handleClose}
          size="md"
          centered
        >
          <Modal.Header>
            <Modal.Title id="sign-in-title">
              Ndrysho {this.state.name}
            </Modal.Title>
            <button
              className="btn-unstyled close-modal"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleClose}
            >
              ✕
            </button>
          </Modal.Header>
          <form onSubmit={this.updateSerie} className="text-center">
            <Modal.Body>
              <div>
                <div className="m-0 row">
                  <div className="col-12 pb-4">
                    <label htmlFor="published_date">Emri i Serisë</label>
                    <input
                      type="text"
                      name="pattern"
                      className={
                        "input-form-input" +
                        (this.state.errorList.pattern ? " form-error" : "")
                      }
                      onChange={this.handleInput}
                      defaultValue={this.state.serieUpdate.pattern}
                      placeholder="Emri i Serisë"
                    />
                    {this.state.errorList.pattern && (
                      <span className="error-message left">
                        {this.state.errorList.pattern[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12 pb-4">
                    <label htmlFor="published_date">Data e botimit</label>
                    <DatePicker
                      placeholderText="Data kur është botuar"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale={sq}
                      dateFormat="dd-MM-yyyy"
                      name="published_date"
                      selected={this.checkSelectedDate(
                        this.state.serieUpdate.published_date
                      )}
                      onChange={this.handleChangeD("published_date")}
                      autoComplete="off"
                      className={
                        "date_input" +
                        (this.state.errorList.published_date
                          ? " form-error"
                          : "")
                      }
                    />
                    {this.state.errorList.published_date && (
                      <span className="error-message left">
                        {this.state.errorList.published_date[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12 pb-4 file-upload">
                    <label htmlFor="published_date">Kopertina</label>
                    <div className="cover-img w-100">
                      <div className="file-upload1">
                        {this.state.cover === null &&
                          this.state.temporary_img_path === null && (
                            <div
                              className={
                                "image-upload-wrap1" +
                                (this.state.errorList.cover
                                  ? " form-error"
                                  : "")
                              }
                            >
                              <input
                                placeholder="Zgjidhni një kopertinë"
                                className="file-upload-input1"
                                accept="image/*"
                                onChange={this.fileSelectHandler}
                                name="cover"
                                type="file"
                                id="cover"
                              />
                              <div className="drag-text1">
                                <h3>
                                  Zgjidhni një kopertinë{" "}
                                  <img src={uploadIcon} alt="upload_cover" />
                                </h3>
                              </div>
                            </div>
                          )}

                        {this.state.cover === null &&
                          this.state.temporary_img_path !== null && (
                            <div className="file-upload-content">
                              <img
                                className="file-upload-image"
                                src={
                                  AppConfig.serieCoversPath +
                                  this.state.temporary_img_path
                                }
                                alt="Kopertina e serisë"
                              />
                              <button
                                type="button"
                                onClick={(e) =>
                                  this.deleteIntroImage(e, this.state.id)
                                }
                                className="remove-image btn-unstyled"
                              >
                                ✕
                              </button>
                            </div>
                          )}

                        {this.state.temporary_img_path !== null &&
                          this.state.cover !== null && (
                            <div className="file-upload-content">
                              <img
                                className="file-upload-image"
                                src={this.state.temporary_img_path}
                                alt="Kopertina e serisë"
                              />
                              <button
                                type="button"
                                onClick={this.removeImage}
                                className="remove-image btn-unstyled"
                              >
                                ✕
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                    {this.state.errorList.cover && (
                      <span className="error-message left">
                        {this.state.errorList.cover[0]}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-12 d-flex m-0 p-0 justify-content-center">
                <div className="col-6 pl-0 pr-2 pr-sm-3 justify-content-center">
                  {this.state.btnLoading === true ? (
                    <button
                      className="btn-styled-2 w-100 text-center"
                      type="button"
                    >
                      Duke u Ndryshuar
					  <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                    </button>
                  ) : (
                    <button
                      className="btn-styled-2 w-100 text-center"
                      type="submit"
                    >
                      Ndrysho
                    </button>
                  )}
                </div>
                <div className="col-6 pr-0 pl-2 pl-sm-3">
                  <button
                    className="btn-styled-1 w-100 text-center"
                    type="button"
                    onClick={this.handleClose}
                  >
                    Anullo
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </Fragment>
    );
  }
}

export default withRouter(EditSeriePopup);
