import React, { Component, Fragment } from "react";
import Url from "../../../api/Url";
import Request from "../../../api/Request";
import { Link, withRouter } from "react-router-dom";
import Messages from "../../../helpers/Messages";
import Web from "../../../routes/Web";
import AppConfig from "../../../services/AppConfig";
import loadingGif from "../../../assets/images/icons/loading.gif";
import dateFormat from "dateformat";
import Paginate from "../../../helpers/Paginate";
import SearchFilter from "../../../helpers/SearchFilter";
import Pagination from "react-js-pagination";
import TooltipComp from "../../../helpers/Tooltip";
import moment from "moment";

class Borrowings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      borrowingList: {
        borrowings: [],
        loading: true,
      },
      pagination: Paginate.setPaginate(),
    };
  }

  componentDidMount() {
    this.borrowingList();
  }

  borrowingList = () => {
    let memberID = this.props.memberID;

    Request.getRequest(Url.memberBorrowings + memberID).then((response) => {
      if (response.status === 200) {
        this.setState({
          borrowingList: {
            borrowings: response.data.borrowingList.data,
            loading: false,
          },
          pagination: Paginate.setPaginateData(response.data.borrowingList),
        });
      } else if (response.status === 404) {
        this.props.history.push(Web.listMembers);
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  handlePageChange = (pageNumber) => {
    let memberID = this.props.memberID;

    let request = Url.memberBorrowings + memberID;
    var searchParams = {
      page: pageNumber,
    };
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        borrowingList: {
          borrowings: response.data.borrowingList.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.borrowingList),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    var borrowingList = "";
    if (this.state.borrowingList.loading) {
      borrowingList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      borrowingList = this.state.borrowingList.borrowings.map(
        (borrowing, index) => {
          const Authors = borrowing.item.biblioitem.biblio.authors.map(
            (author) => {
              return author.name;
            }
          );
          return (
            <tr key={index} role="row">
              <td
                role="cell"
                data-label="Titull/Autor"
                className="text-center "
              >
                {TooltipComp.tooltipDisabledMobile(
                  index,
                  borrowing.item.biblioitem.biblio.title
                )}
                <br />
                {Authors.join("; ")}
              </td>
              <td
                role="cell"
                data-label="Kodifikimi/Nr. i inventarit"
                className="text-center "
              >
                {borrowing.item.inventory_number + " / " + borrowing.item.id}
              </td>
              <td role="cell" data-label="Date Marrje" className="text-center ">
                {borrowing.created_at &&
                  moment(borrowing.created_at).format("DD-MM-yyyy")}
              </td>
              <td
                role="cell"
                data-label="Date për tu kthyer"
                className=" text-center "
              >
                {moment(borrowing.should_return_on).format("DD-MM-yyyy")}
              </td>
              <td
                role="cell"
                data-label="Statusi"
                className="text-center status"
              >
                {borrowing.status == "Dorëzuar me vonesë" && (
                  <span className="delivered-late">Dorëzuar me vonesë</span>
                )}
                {borrowing.status == "Për tu dorëzuar" && (
                  <span className="tobedelivered">Për tu dorëzuar</span>
                )}
                {borrowing.status == "Dorëzuar në kohë" && (
                  <span className="delivered">Dorëzuar në kohë</span>
                )}
                {borrowing.status == "I pa dorëzuar" && (
                  <span className="undelivered">I pa dorëzuar</span>
                )}
              </td>
            </tr>
          );
        }
      );
    }

    return (
      <Fragment>
        <div className="table-list">
          <table role="table" className="w-100">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" className="text-center" width="20%">
                  Titull / Autor
                </th>
                <th role="columnheader" className="text-center" width="20%">
                  Kodifikimi/Nr. i inventarit
                </th>
                <th role="columnheader" className="text-center" width="20%">
                  Datë marrje
                </th>
                <th role="columnheader" className="text-center" width="20%">
                  Data për tu kthyer
                </th>
                <th role="columnheader" className="text-center" width="20%">
                  Statusi
                </th>
              </tr>
            </thead>

            <tbody role="rowgroup">{borrowingList}</tbody>
          </table>
          {this.state.pagination.totalItemsCount === 0 &&
            this.state.borrowingList.loading === false && (
              <p className="nothing--to--show">Asgjë për të shfaqur</p>
            )}
        </div>
        <div className="container-fluid pt-5 pb-4">
          <div className="d-flex justify-content-center pagination-block">
            {this.state.pagination.totalPages > 1 && (
              <div className="d-flex justify-content-center pagination-block mb-5">
                <div className="select-page">
                  <Pagination
                    activePage={this.state.pagination.activePage}
                    itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                    totalItemsCount={this.state.pagination.totalItemsCount}
                    pageRangeDisplayed={
                      this.state.pagination.pageRangeDisplayed
                    }
                    onChange={this.handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
                <div className="pagination-results">
                  Faqja: {this.state.pagination.activePage} -{" "}
                  {this.state.pagination.totalPages}
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(Borrowings);
