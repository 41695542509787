import axios from 'axios';
import AppConfig from '../services/AppConfig';
import Messages from '../helpers/Messages';

class Request {
  static getRequestfordflip = (getUrl) => {
    return axios
      .get(getUrl, AppConfig.apiConfigParams())
      .then((response) => {
        if (response.data.warning && response.data.warning.length > 0) {
          Messages.warningMsg(response.data.warning);
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return null;
        } else {
          return null;
        }
      });
  };
  static getRequest = (getUrl) => {
    return axios
      .get(getUrl, AppConfig.apiConfigParams())
      .then((response) => {
        if (response.data.warning && response.data.warning.length > 0) {
          Messages.warningMsg(response.data.warning);
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === 'Unauthenticated.'
          ) {
            AppConfig.deleteAccessToken();
            window.location.href = '/';
          } else {
            return error.response;
          }
        } else {
          return null;
        }
      });
  };

  static getRequestForDownload = (getUrl) => {
    return axios
      .get(getUrl, AppConfig.apiConfigParamsForDownload())
      .then((response) => {
        if (response.data.warning && response.data.warning.length > 0) {
          Messages.warningMsg(response.data.warning);
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === 'Unauthenticated.'
          ) {
            AppConfig.deleteAccessToken();
            window.location.href = '/';
          } else {
            return error.response;
          }
        } else if (error.request) {
          return error.request;
        } else {
          return null;
        }
      });
  };

  static getRequestForDownloadExcel = (getUrl) => {
    return axios
      .get(getUrl, AppConfig.apiConfigParamsForDownloadExcel())
      .then((response) => {
        if (response.data.warning && response.data.warning.length > 0) {
          Messages.warningMsg(response.data.warning);
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === 'Unauthenticated.'
          ) {
            AppConfig.deleteAccessToken();
            window.location.href = '/';
          } else {
            return error.response;
          }
        } else if (error.request) {
          return error.request;
        } else {
          return null;
        }
      });
  };

  static postRequest = (postUrl, postJson) => {
    return axios
      .post(postUrl, postJson, AppConfig.apiConfigParams())
      .then((response) => {
        if (response.data.warning && response.data.warning.length > 0) {
          Messages.warningMsg(response.data.warning);
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === 'Unauthenticated.'
          ) {
            AppConfig.deleteAccessToken();
            window.location.href = '/';
          } else {
            return error.response;
          }
        } else if (error.request) {
          return error.request;
        } else {
          return null;
        }
      });
  };

  static postRequestLogin = (postUrl, postJson) => {
    return axios
      .post(postUrl, postJson, AppConfig.apiConfigParamsLogin())
      .then((response) => {
        if (response.data.warning && response.data.warning.length > 0) {
          Messages.warningMsg(response.data.warning);
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        } else if (error.request) {
          return error.request;
        } else {
          return null;
        }
      });
  };

  static postRequestExport = (postUrl, postJson) => {
    return axios
      .post(postUrl, postJson, AppConfig.apiConfigParamsForExport())
      .then((response) => {
        if (response.data.warning && response.data.warning.length > 0) {
          Messages.warningMsg(response.data.warning);
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === 'Unauthenticated.'
          ) {
            AppConfig.deleteAccessToken();
            window.location.href = '/';
          } else {
            return error.response;
          }
        } else if (error.request) {
          return error.request;
        } else {
          return null;
        }
      });
  };

  static patchRequest = (patchUrl, postJson) => {
    return axios
      .patch(patchUrl, postJson, AppConfig.apiConfigParams())
      .then((response) => {
        if (response.data.warning && response.data.warning.length > 0) {
          Messages.warningMsg(response.data.warning);
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            AppConfig.deleteAccessToken();
            window.location.href = '/';
          } else {
            return error.response;
          }
        } else if (error.request) {
          return error.request;
        } else {
          return null;
        }
      });
  };

  static patchRequestUrlencoded = (patchUrl, postJson) => {
    return axios
      .patch(patchUrl, postJson, AppConfig.apiConfigParamsUrlencoded())
      .then((response) => {
        if (response.data.warning && response.data.warning.length > 0) {
          Messages.warningMsg(response.data.warning);
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === 'Unauthenticated.'
          ) {
            AppConfig.deleteAccessToken();
            window.location.href = '/';
          } else {
            return error.response;
          }
        } else if (error.request) {
          return error.request;
        } else {
          return null;
        }
      });
  };

  static deleteRequest = (patchUrl) => {
    return axios
      .delete(patchUrl, AppConfig.apiConfigParams())
      .then((response) => {
        if (response.data.warning && response.data.warning.length > 0) {
          Messages.warningMsg(response.data.warning);
        }
        return response;
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === 'Unauthenticated.'
          ) {
            AppConfig.deleteAccessToken();
            window.location.href = '/';
          } else {
            return error.response;
          }
        } else if (error.request) {
          return error.request;
        } else {
          return null;
        }
      });
  };
}

export default Request;
