import React, { Component, Fragment } from "react";
import Homepage from "../../../src/components/opac/Homepage/Homepage";
import { Helmet } from "react-helmet";
import Header from "../../components/opac/partials/Header";
import Footer from "../../components/opac/partials/Footer";

class HomepageView extends Component {
	render() {
		return (
			<Fragment>
				<Helmet>
					<title>Biblioteka</title>
				</Helmet>
				<div className="frontpage" style={{ width: 100 + "%" }}>
					<Header
						roleUser={this.props.roles}
						title={"Biblioteka Juaj"}
						desc={'"Biblioteka nuk është luks por nevojë jetësore"\n' + "\n"}
						search={true}
					/>
					<Homepage />
					<Footer />
				</div>
			</Fragment>
		);
	}
}

export default HomepageView;
