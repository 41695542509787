import React, { Component, Fragment } from "react";
import Url from "../../../api/Url";
import Request from "../../../api/Request";
import editIcon from "../../../assets/images/icons/edit_icon.svg";
import loadingGif from "../../../assets/images/icons/loading.gif";
import deleteIcon from "../../../assets/images/icons/delete_icon.svg";
import Messages from "../../../helpers/Messages";
import dateFormat from "dateformat";
import parse from "html-react-parser";
import Pagination from "react-js-pagination";
import Paginate from "../../../helpers/Paginate";
import noResult from "../../../assets/images/icons/no_result.svg";
import SearchFilter from "../../../helpers/SearchFilter";
import { Link } from "react-router-dom";
import Web from "../../../routes/Web";
import TooltipComp from "../../../helpers/Tooltip";
import LoadingGif from "../../../assets/images/icons/loading.gif";

class ListPosts extends Component {
  constructor() {
    super();
    this.state = {
      listPosts: {
        posts: [],
        loading: true,
      },
      searchPost: {
        title: "",
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
      searched: false,
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.listBlogPosts();
  }

  listBlogPosts = (hasSearch) => {
    let request = Url.listBlogPosts;
    const searchParams = {
      title: this.state.searchPost.title,
    };

    if (hasSearch) {
      this.setState({
        searched: true,
      });
      hasSearch.preventDefault();
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({ btnLoading: true });
    }

    Request.getRequest(request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          listPosts: {
            posts: response.data.blog.data,
            loading: false,
          },
          form_errors: {
            error_list: [],
          },
          pagination: Paginate.setPaginateData(response.data.blog),
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          searched: false,
        });
      } else {
        this.setState({
          listPosts: {
            posts: [],
            loading: true,
          },
          searched: false,
        });
      }
    });
  };

  seachHandler = (e) => {
    this.setState({
      searchPost: {
        [e.target.name]: e.target.value,
      },
    });
  };

  deletePost = (e, id, name) => {
    Messages.swalConfirmDelete(name).then((result) => {
      if (result.isConfirmed) {
        Request.deleteRequest(Url.deleteBlogPost + id).then((response) => {
          if (response.status === 200) {
            this.listBlogPosts();
            Messages.swalSuccess(response.data.success);
          } else if (response.status === 404) {
            Messages.swalError(response.data.error);
          }
        });
      }
    });
  };

  handlePageChange(pageNumber) {
    let request = Url.listBlogPosts;

    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        title: this.state.searchPost.title,
        page: pageNumber,
      };
    }

    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((result) => {
      this.setState({
        listPosts: {
          posts: result.data.blog.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(result.data.blog),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    var listPosts = "";
    if (this.state.listPosts.loading) {
      listPosts = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      listPosts = this.state.listPosts.posts.map((listPosts, index) => {
        return (
          <tr key={index}>
            <td data-label="Titulli">
              {TooltipComp.tooltipDisabledMobile(
                "-title-" + index,
                listPosts.title
              )}
            </td>
            <td className="text-center" data-label="Përshkrimi">
              <div className="d-flex description">
                {parse(listPosts.description.substring(0, 20) + "...")}
              </div>
            </td>
            <td className="text-center" data-label="Data e krijimit">
              {dateFormat(listPosts.created_at, "d-mm-yyyy")}
            </td>
            <td className="text-center" data-label="Veprime">
              <div className="row  justify-content-end justify-content-md-around mr-auto ml-auto action-icons">
                <div className="col-3 edit">
                  <Link to={Web.editBlogPost + listPosts.id}>
                    <img src={editIcon} alt="edit icon" />
                  </Link>
                </div>

                <div className="col-3 delete">
                  <button
                    type="button"
                    className="btn-unstyled"
                    onClick={(e) =>
                      this.deletePost(e, listPosts.id, listPosts.title)
                    }
                  >
                    <img src={deleteIcon} alt="fshi" />
                  </button>
                </div>
              </div>
            </td>
          </tr>
        );
      });
    }
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="list-of-members">
            <p className="partial-title mb-5">Lista e artikujve</p>

            <div className="input-items">
              <form onSubmit={this.listBlogPosts} id="searchPost">
                <div className="row mb-5">
                  <div className="col-12 col-sm-6 col-md-4 pb-4">
                    <input
                      placeholder="Kërko për titull "
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.title
                          ? " form-error"
                          : "")
                      }
                      name="title"
                      value={this.state.title}
                      type="text"
                      onChange={this.seachHandler}
                    />
                    {this.state.form_errors.error_list.title && (
                      <span className="error-message left">
                        {this.state.form_errors.error_list.title[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-lg-3 col-md-4">
                    {this.state.btnLoading === true ? (
                      <button className="btn-styled-2" type="button">
                        Duke Kërkuar
                        <img
                          src={LoadingGif}
                          style={{
                            width: "18px",
                            margin: "-4px 0 0 7px",
                          }}
                        />
                      </button>
                    ) : (
                      <button
                        className="btn-styled-2"
                        name="submit"
                        type="submit"
                      >
                        Kërko
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>

            <div className="table-list mb-5">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th
                      role="columnheader"
                      className="text-left"
                      style={{ width: 35 + "%" }}
                    >
                      Titulli
                    </th>
                    <th
                      role="columnheader"
                      className="text-center"
                      style={{ width: 21.66 + "%" }}
                    >
                      Përshkrimi
                    </th>
                    <th
                      role="columnheader"
                      className="text-center"
                      style={{ width: 21.66 + "%" }}
                    >
                      Data e krijimit
                    </th>
                    <th
                      role="columnheader"
                      className="text-center"
                      style={{ width: 21.66 + "%" }}
                    >
                      Veprime
                    </th>
                  </tr>
                </thead>
                <tbody>{listPosts}</tbody>
              </table>
              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listPosts.loading === false &&
                this.state.searched === true && (
                  <img
                    className="mx-auto d-block"
                    src={noResult}
                    alt="no result"
                  />
                )}

              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listPosts.loading === false &&
                this.state.searched === false && (
                  <p className="nothing--to--show">Asgjë për të shfaqur</p>
                )}
            </div>
          </div>
          {this.state.pagination.totalPages > 1 && (
            <div className="d-flex justify-content-center pagination-block mb-5">
              <div className="select-page">
                <Pagination
                  activePage={this.state.pagination.activePage}
                  itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                  totalItemsCount={this.state.pagination.totalItemsCount}
                  pageRangeDisplayed={this.state.pagination.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="pagination-results">
                Faqja: {this.state.pagination.activePage} -{" "}
                {this.state.pagination.totalPages}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default ListPosts;
