import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Web from "../../routes/Web";

class PrivacyPolicies extends Component {
	constructor() {
		super();
		this.state = {
			privacyPolicies: {
				info: false,
			},
		};
	}

	showInfo = () => {
		if (this.state.privacyPolicies.info === false) {
			this.setState({
				privacyPolicies: {
					info: true,
				},
			});
		} else {
			this.setState({
				privacyPolicies: {
					info: false,
				},
			});
		}
	};

	render() {
		var privacyPoliciesInfo = "";
		privacyPoliciesInfo = (
			<Fragment>
				<div className="info-div">
					<div
						className={
							this.state.privacyPolicies.info === false
								? "text-content text-condensed"
								: "text-content text-expanded"
						}
					>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam diam
						nisl, pharetra a feugiat sit amet, pellentesque ut ante. Praesent
						non magna ac sapien consectetur sollicitudin. Donec est sapien,
						vestibulum et mollis tristique, scelerisque non enim. Vestibulum ac
						sem eget sapien rutrum luctus. Sed ut diam eget eros eleifend
						efficitur. Morbi tincidunt, odio ut convallis vestibulum, nisi est
						aliquet arcu, non placerat nisl mauris id felis. Pellentesque eget
						vestibulum magna. Proin accumsan tellus in dolor tempus, vitae
						faucibus lorem luctus. Fusce porta tortor felis, vel viverra risus
						suscipit vitae. Maecenas risus nunc, auctor at gravida sit amet,
						aliquam at tortor. In dapibus urna ac erat consectetur, nec faucibus
						risus maximus. In ullamcorper imperdiet massa, sit amet sollicitudin
						mauris congue aliquet. Nulla ac ipsum mi. Praesent non euismod
						dolor, non lacinia ligula. Cras a magna placerat diam vulputate
						pellentesque. Duis eu justo varius, placerat velit id, feugiat
						turpis. Vestibulum arcu ex, egestas et erat mattis, pellentesque
						viverra sem. Nullam lectus felis, finibus sed mattis at, euismod
						eget urna. Morbi et sodales odio. Vestibulum pellentesque libero non
						feugiat consectetur. Donec lobortis, eros sed vestibulum bibendum,
						purus lacus porttitor massa, id aliquam magna dolor nec eros. Ut
						placerat mollis felis et finibus. Ut quis lobortis arcu, quis
						iaculis lacus. Ut rhoncus semper tellus nec mattis. Vestibulum
						pulvinar commodo molestie. Nullam nisl ligula, venenatis varius
						lobortis sit amet, consequat eu nisl.
						<br />
						<br />
						<strong>Lorem ipsum</strong>
						<br />
						<br />
						Cras ac ultricies nisi, vel pretium ex. Aliquam ac lobortis urna.
						Donec finibus, nisi vel posuere laoreet, diam felis bibendum quam,
						sed blandit nisi ipsum eu mauris. Aenean justo nibh, vulputate vitae
						velit in, lacinia sagittis justo. Etiam cursus ligula hendrerit dui
						posuere, vitae semper neque sagittis. Ut at nisl et diam fringilla
						venenatis. Curabitur finibus lorem non ullamcorper consequat.
						Vivamus vel augue vitae arcu convallis faucibus. Cras venenatis
						justo non laoreet imperdiet.
						<br /> Donec varius elementum nibh eu gravida. Mauris non fermentum
						mi. Nullam imperdiet magna id tristique blandit. Aenean diam tortor,
						rutrum ut commodo et, blandit ut metus. Quisque rutrum nisl dui.
						Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis
						nec turpis vitae odio porta porttitor nec non sapien. Fusce interdum
						fringilla feugiat. Nam vitae lectus rhoncus, dapibus leo ut, rutrum
						augue. Duis non malesuada ante. Morbi volutpat ultrices turpis,
						pulvinar consequat turpis auctor eu. Cras bibendum lorem sit amet
						eros egestas ultricies. Nunc at turpis sed lacus congue bibendum.
						Nunc fermentum ut ipsum id maximus. Aliquam nisl nunc, aliquam ut
						felis eget, luctus venenatis libero. Phasellus neque odio, gravida
						ut eros vitae, tincidunt fermentum nunc. Proin eget convallis orci,
						nec fermentum ex. Aenean in posuere diam. Sed eu augue id tortor
						dapibus tempor quis eget ante. Nam odio augue, eleifend sit amet
						fringilla quis, sodales ac ex. Vivamus condimentum, justo ut
						malesuada malesuada, orci urna tristique purus, nec facilisis mauris
						magna porttitor sapien. Praesent at sem a urna placerat vulputate eu
						eu erat. Nullam auctor lobortis purus non iaculis. Cras lorem
						tortor, sollicitudin sit amet ultricies et, facilisis eu diam.
						Maecenas dignissim libero tortor. In tempus, justo nec iaculis
						pellentesque, velit lacus tincidunt turpis, ullamcorper accumsan
						erat sem sed sem. Cras vitae lectus nunc. Etiam id mollis mi, vel
						tempor diam. Donec ornare tortor rutrum aliquam lacinia. Donec nulla
						sem, facilisis pretium ex eu, maximus placerat eros. Nullam euismod
						pretium nisi, non vestibulum lectus rutrum in. Aenean eget purus in
						magna elementum viverra. Ut congue sollicitudin semper. Donec
						porttitor at ligula at gravida. In hac habitasse platea dictumst.
						Etiam egestas erat non laoreet vehicula. Ut eleifend nisl ante.
						Fusce consequat hendrerit tempus. Pellentesque gravida eu lectus
						eget iaculis.
						<br />
						<br /> Sed pretium feugiat purus vitae maximus. Curabitur egestas
						purus nibh, in posuere arcu luctus sed. Donec pretium lacinia lorem
						non tincidunt. Cras volutpat semper elementum. Curabitur iaculis
						luctus tellus, et mollis metus malesuada at. Suspendisse potenti.
						Praesent ullamcorper dolor nec risus feugiat, ut euismod nunc
						lacinia. Donec efficitur semper sapien nec elementum. Ut vitae nibh
						nisi. Curabitur interdum fermentum convallis. Aliquam erat volutpat.
						Proin faucibus metus lacus, id tristique dolor vehicula nec. Proin
						volutpat lacinia congue. Praesent ante nibh, interdum a lacinia
						eget, pharetra eget erat. Praesent eget venenatis orci, sit amet
						eleifend eros. Etiam posuere pharetra imperdiet. Suspendisse
						efficitur massa sed est volutpat commodo. Nam mi lorem, euismod
						pretium suscipit eu, rutrum eget elit. Donec aliquet risus non
						accumsan rhoncus. Fusce consectetur venenatis lectus. Integer ornare
						felis non scelerisque porta. Maecenas eget iaculis mauris. Cras
						maximus ante magna, sit amet lacinia nisi dictum ornare. Aliquam in
						semper sem, a pharetra mauris. Mauris rhoncus id risus eu molestie.
						Proin non scelerisque ex, eu accumsan sem. Interdum et malesuada
						fames ac ante ipsum primis in faucibus. Maecenas imperdiet fermentum
						ultricies. Nam pellentesque in lorem ac vulputate. Fusce non magna
						porta, venenatis diam vitae, tincidunt nunc. Ut scelerisque ipsum
						eget risus mattis, at efficitur elit vestibulum. Aliquam dignissim
						mi metus, sed dictum orci maximus quis. Praesent pellentesque neque
						ac auctor molestie. Aenean condimentum lectus quis magna tristique,
						sed auctor velit feugiat. Maecenas ullamcorper odio quis vulputate
						sollicitudin. Sed volutpat condimentum mattis. Quisque at
						sollicitudin velit. Nullam pulvinar sem et lacus finibus, ut ornare
						nibh semper. Nullam nisl purus, sagittis eu ipsum sit amet,
						tristique vestibulum felis. Nam lobortis nibh vitae urna viverra,
						non faucibus erat dignissim. Fusce lobortis efficitur convallis.
						Nullam sodales metus velit, sed varius nibh varius ut. Nulla
						facilisi. Integer eleifend velit enim, sit amet vulputate ex
						hendrerit non. Praesent vel tortor non turpis interdum mollis. Cras
						vel nunc purus. Donec luctus neque metus, eget cursus lacus suscipit
						dapibus. Integer vehicula, libero sed tristique mollis, nisi erat
						consequat eros, eu vulputate ipsum magna non metus. Nullam nec
						dignissim lectus. Proin aliquam nec tortor vel dictum. Cras id
						volutpat diam. Donec ultricies fringilla dignissim. Donec posuere
						enim sed lacus blandit, eget laoreet urna consectetur. Etiam vitae
						mi neque. Praesent at mauris quis mi bibendum consectetur ut vitae
						ipsum. Nunc auctor tincidunt nisl, at pulvinar risus laoreet ut. Ut
						tortor elit, faucibus non malesuada a, interdum ac purus. Mauris
						tellus quam, imperdiet lobortis mauris eget, blandit fringilla arcu.
						Donec molestie sodales tellus. Nam ornare nec mi ut finibus.
						Praesent faucibus et massa sed ultricies. Nullam dui ligula, posuere
						sit amet ornare eu, finibus ac nulla.
						<br />
						<br /> Phasellus tempus est nunc, sed maximus diam suscipit nec. In
						porta tellus vel sem ornare accumsan. Aliquam cursus ipsum
						sollicitudin lorem tincidunt, eu commodo purus pellentesque. Integer
						dapibus dolor in posuere vehicula. Vestibulum tellus nisl,
						ullamcorper eu lectus ut, porta facilisis est. Vestibulum eget nulla
						ut purus faucibus suscipit auctor vel nisi. Praesent lacinia odio
						eget sagittis sagittis. Quisque sed pulvinar sapien. Duis pretium
						velit non ornare aliquam. Etiam sit amet facilisis ex. Maecenas in
						felis tincidunt, finibus ipsum nec, vehicula mi. Sed varius sem
						commodo risus vestibulum convallis. Proin efficitur finibus
						hendrerit. Cras lectus felis, porttitor vitae vulputate non, commodo
						quis nisl. Pellentesque aliquam semper erat ac commodo. Nullam vitae
						nibh tincidunt sem suscipit convallis faucibus vitae orci.
						Suspendisse fringilla, risus at placerat ullamcorper, purus mauris
						pretium diam, a pretium est libero quis leo. Integer blandit at leo
						quis egestas. Integer ultrices lectus non diam facilisis, eget
						viverra ante mollis. Mauris vitae egestas nibh, sed aliquet nisi.
						Proin sed accumsan sapien. Maecenas commodo sit amet arcu ut
						tincidunt. Etiam vel massa tincidunt eros tempor facilisis. Ut
						malesuada eros nec mauris vulputate, fermentum venenatis enim
						ullamcorper. In ullamcorper, ex sed aliquet pellentesque, ipsum
						turpis blandit magna, et aliquam nisi sem sit amet sapien. Proin
						mollis erat ligula, at vestibulum erat eleifend id. Aenean purus ex,
						dignissim in venenatis et, luctus et nisl. Cras varius lacus ut
						risus varius porta. Etiam nunc lectus, luctus nec pharetra quis,
						pellentesque eu dui. Donec dictum interdum dolor, vel pulvinar
						purus. Donec rutrum mi ac ante porttitor fermentum. Curabitur
						faucibus est eu magna condimentum malesuada. Pellentesque egestas
						pellentesque enim malesuada facilisis. Duis diam enim, egestas quis
						eros sed, pretium iaculis magna. Quisque porta dapibus nunc, eget
						faucibus risus sagittis nec. Sed consequat egestas accumsan. Vivamus
						vel dui sem. Nunc vehicula interdum tortor sit amet dignissim. Donec
						viverra, felis sed commodo faucibus, augue quam semper arcu, at
						convallis enim nibh sit amet eros. Nam tincidunt quam sit amet
						molestie facilisis. Fusce eu est tortor. Morbi ac arcu elementum
						ante pulvinar pellentesque. Mauris eu porta est. Cras sed ante
						dolor. Nunc pretium massa nisl, eget fermentum eros eleifend id. Ut
						vitae semper sapien, vitae ullamcorper eros. Vestibulum orci massa,
						volutpat aliquam mauris at, vestibulum accumsan magna. Suspendisse
						potenti. Cras vulputate vestibulum ipsum nec euismod. Nam accumsan
						ultricies lacus vitae aliquet. Nam a tortor dolor. Proin congue
						tellus at nisl volutpat cursus. Cras sit amet sagittis quam. Sed
						sollicitudin ut eros sit amet interdum. Maecenas purus lectus,
						pulvinar eu consectetur ac, rhoncus porttitor justo. Aliquam aliquam
						leo eu cursus rutrum. Donec gravida sem id nisi consequat, ut auctor
						ante tincidunt. Aliquam consectetur scelerisque nunc, a auctor ipsum
						gravida vitae. Pellentesque viverra vehicula quam. Sed nec accumsan
						est. Vestibulum pretium ex id venenatis rhoncus. Vivamus vestibulum
						sapien arcu, at imperdiet tortor consequat sed.
						<br />
						<br /> Morbi laoreet urna at ipsum sollicitudin lobortis. Integer
						vestibulum tincidunt est eget venenatis. Nullam finibus non justo ut
						fringilla. Morbi vestibulum orci pellentesque, condimentum purus ac,
						lacinia risus. Aenean turpis velit, tempus in posuere vitae, posuere
						non metus. Aliquam at nisi a diam scelerisque rutrum eu ut libero.
						Quisque id justo faucibus, porta augue in, pretium ex. Suspendisse
						eget enim nec augue lacinia cursus. Donec vel dui in ipsum ultrices
						dapibus. Nam vitae libero iaculis, tincidunt metus ac, porta urna.
						Duis libero mauris, ornare sit amet auctor sit amet, sagittis vitae
						nisi. Nullam a pellentesque magna. Praesent facilisis eleifend est,
						non varius lectus elementum et. Suspendisse elementum mi ut mauris
						efficitur, sed maximus lacus sollicitudin. Integer et euismod metus,
						vitae dapibus urna. Maecenas eget porttitor mi. Mauris quis nulla
						quam. Sed rhoncus ac neque nec pellentesque. Donec rhoncus ultricies
						rhoncus. Praesent et dui non quam lacinia consectetur. Curabitur
						vitae orci tortor. Proin arcu metus, luctus a gravida nec,
						pellentesque nec erat. Praesent mattis, odio eu molestie porta,
						dolor dolor commodo eros, faucibus interdum mi orci in nisl.
						Suspendisse vestibulum justo rhoncus ex pharetra viverra. Fusce
						congue cursus lacus vel commodo. Sed finibus orci quis nulla cursus
						scelerisque. Donec dapibus dictum turpis ac placerat. Aliquam sed
						nisi turpis. Duis augue nisl, maximus eu pulvinar nec, laoreet ac
						dui. Sed commodo augue non sem imperdiet, ac congue mauris
						tincidunt. Proin bibendum eros ante, sed dignissim nisl vestibulum
						et. Integer ac dolor eget ex placerat consequat. Aenean euismod
						lorem nec lacus porta imperdiet. Aenean semper quam lacus. Nulla et
						rutrum ex. In cursus porttitor quam sit amet euismod. Vivamus ut
						elementum mauris. Sed dapibus a augue vitae hendrerit. Fusce arcu
						lorem, maximus consequat viverra et, auctor ac felis. Mauris vel
						consectetur metus, at vulputate libero. Aliquam semper interdum
						blandit. Proin urna leo, tempus ac elit ac, feugiat rhoncus massa.
						Donec et lectus quis diam ultrices placerat sit amet nec nunc.
						Vestibulum ut eleifend enim. Aliquam interdum est eget felis
						vulputate pulvinar. Vestibulum ante ipsum primis in faucibus orci
						luctus et ultrices posuere cubilia curae; Integer ut dui at nibh
						luctus malesuada. Sed non diam et metus ultricies tempus. Nunc at
						nisl pretium, cursus ante at, ullamcorper diam. Praesent at ex
						lacus. Vestibulum suscipit eu nulla quis dignissim. Cras sed
						sollicitudin turpis. Sed non bibendum libero. Vestibulum vitae
						porttitor sapien, vel semper ligula. Integer gravida imperdiet
						ornare. Maecenas vel hendrerit ligula. Etiam felis orci, condimentum
						sit amet elit at, sodales pulvinar arcu.
					</div>
				</div>
				<div className="d-flex justify-content-end mt-4 mb-5">
					<button onClick={this.showInfo} className="btn-styled-2">
						{this.state.privacyPolicies.info === false
							? "Lexo më shumë"
							: "Lexo më pak"}
					</button>
				</div>
			</Fragment>
		);
		return (
			<Fragment>
				<div id="content">
					<div id="privacyPolicies" className="bl_page">
						<div
							className={
								this.state.privacyPolicies.loading === true ? "mt-4 mb-5" : "mt-4"
							}
						>
							<div className="b--breadcrumb">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb pl-0">
										<li className="breadcrumb-item">
											<Link to={Web.homepage}>Biblioteka</Link>
										</li>
										<li
											className="breadcrumb-item active b--title"
											aria-current="page"
										>
											Rregullore
										</li>
									</ol>
								</nav>
							</div>
							{privacyPoliciesInfo}
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default PrivacyPolicies;
