import { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Request from '../../../api/Request';
import Url from '../../../api/Url';
import dateFormat from 'dateformat';
import Web from '../../../routes/Web';
import Messages from '../../../helpers/Messages';
import dateIcon from '../../../assets/images/icons/date-icon.svg';
import AppConfig from '../../../services/AppConfig';

class ConfirmationItem extends Component {
  constructor() {
    super();
    this.state = {
      details: {
        member: '',
        role: '',
        material: '',
        biblio: '',
      },
      dates: {
        today: '',
        afterToday: '',
      },
      formData: {
        user_id: '',
        item_id: '',
        should_return_on: '',
        pending: '1',
      },
    };
  }

  componentDidMount() {
    this.details();
  }

  details = () => {
    const member_id = this.props.match.params.member_id;
    const item_id = this.props.match.params.item_id;

    Request.getRequest(
      Url.confirmBorrowing + member_id + '/item/' + item_id
    ).then((response) => {
      if (response.status === 200) {
        this.setState({
          details: {
            member: response.data.member,
            role: response.data.member.role,
            material: response.data.item,
            biblio: response.data.item.biblioitem.biblio,
          },
          dates: {
            today: response.data.today,
            afterToday: response.data.afterToday,
            itemType: response.data.item_type,
          },
          formData: {
            user_id: response.data.member.id,
            item_id: response.data.item.id,
            should_return_on: response.data.afterToday,
            pending: '1',
          },
        });
      } else if (response.status === 404) {
        this.props.history.push(Web.listBorrowings);
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  saveBorrowing = (e) => {
    e.preventDefault();

    let pending = this.state.formData.pending;

    Request.postRequest(Url.saveBorrowing, this.state.formData).then(
      (response) => {
        if (response.status === 200) {
          if (pending == 0) {
            this.props.history.push(Web.listBorrowings);
            Messages.swalSuccess(response.data.success);
          } else if (pending == 1) {
            this.props.history.push(Web.listPending);
            Messages.swalSuccess(response.data.success);
          }
        } else if (response.status === 405) {
          this.props.history.push(Web.listBorrowings);
          Messages.swalInfo(response.data.info);
        } else {
          Messages.swalError(AppConfig.serverError);
        }
      }
    );
  };

  handleChangeInput = (key) => (e) => {
    let newForm = {
      ...this.state.formData,
      [key]: e.target.value,
    };
    this.setState({
      formData: newForm,
    });
  };

  memberGender = (param) => {
    switch (param) {
      case 'M':
        return 'Mashkull';
      case 'F':
        return 'Femër';
      default:
        return '';
    }
  };

  render() {
    const member = this.state.details.member;
    const role = this.state.details.role;
    const item = this.state.details.material;
    const biblio = this.state.details.biblio;
    const today = this.state.dates.today;
    const afterToday = this.state.dates.afterToday;
    const itemType = this.state.dates.itemType;

    return (
      <Fragment>
        <div className="container-fluid small-offset-left huazo">
          <div id="show">
            <form method="POST" onSubmit={this.saveBorrowing}>
              <p className="partial-title mb-5">Huazo</p>
              <div className="row m-0 member-data member-details-bg">
                <div className="list-data col-12 col-lg-6 col-sm-12 d-flex">
                  <ul>
                    <li className="member-detail">
                      Emër Mbiemër / Roli : &nbsp;&nbsp;{' '}
                      {member.first_name +
                        ' ' +
                        member.last_name +
                        ' / ' +
                        role.name}
                    </li>
                    <li>Email : &nbsp; {member.email}</li>
                    <li>Cel : &nbsp; {member.phone}</li>
                    <li>Datëlindje : &nbsp; {member.dob}</li>
                    <li>Gjinia : &nbsp; {this.memberGender(member.gender)}</li>
                    <li>
                      Numri i Identifikimit : {member.nid ? member.nid : '-'}
                    </li>
                    <li>
                      Shteti / Qyteti : &nbsp;{' '}
                      {member.state + ' / ' + member.city}
                    </li>
                    <li>
                      Adresa / Kodi postar : &nbsp;{' '}
                      {member.address + ' / ' + member.postal_code}
                    </li>
                    <li>
                      Data e fillimit / Data e mbarimit : &nbsp;{' '}
                      {member.membership_start + ' / ' + member.membership_end}
                    </li>
                    {member.role_id === 4 && (
                      <Fragment>
                        <li>
                          Fakulteti : {member.faculty ? member.faculty : '-'}
                        </li>
                        <li>
                          Departamenti :{' '}
                          {member.department ? member.department : '-'}
                        </li>
                        <li>
                          Programi : {member.program ? member.program : '-'}
                        </li>
                        <li>
                          Niveli i edukimit :{' '}
                          {member.edulevel ? member.edulevel : '-'}
                        </li>
                        <li>
                          Koha e studimit :{' '}
                          {member.fullorparttime
                            ? member.fullorparttime === 'fulltime'
                              ? 'Me kohë të plotë'
                              : 'Me kohë të pjesshme'
                            : '-'}
                        </li>
                        <li>Viti : {member.year ? member.year : '-'}</li>
                      </Fragment>
                    )}
                  </ul>
                </div>
                <div className="list-data col-12 col-md-6 d-flex">
                  <ul>
                    <li className="member-detail">
                      Titulli : &nbsp; {biblio.title}
                    </li>
                    <li>Kodifikimi : &nbsp; {item.inventory_number}</li>
                    <li>Numri i inventarit : &nbsp; {item.id}</li>
                  </ul>
                </div>
              </div>
              <div className=" pt-5 huazo">
                <div className="row align-items-end">
                  <div className="col-12 col-md-6 col-xl-3 order-xl-2 mb-4">
                    <label htmlFor="created_at">Data e sotme</label>
                    <div className="calendar-block">
                      <input
                        placeholder="Data e sotme"
                        id="date-created"
                        className="textpicker input-form-input disable-input"
                        name="created_at"
                        type="text"
                        value={dateFormat(today, 'd-mm-yyyy h:MM:ss')}
                        readOnly
                      />

                      <img
                        className="datePickerTrigger-icon"
                        src={dateIcon}
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-3 order-xl-3 mb-4">
                    <label htmlFor="should_return_on">Data e dorëzimit</label>
                    <div className="calendar-block">
                      <input
                        placeholder="Data e dorezimit"
                        id="date-return"
                        className="textpicker input-form-input disable-input"
                        name="should_return_on"
                        type="text"
                        value={dateFormat(
                          afterToday,
                          itemType === 'salle'
                            ? 'd-mm-yyyy'
                            : 'd-mm-yyyy h:MM:ss'
                        )}
                        readOnly
                      />
                      <img
                        className="datePickerTrigger-icon"
                        src={dateIcon}
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-3 order-xl-1 mb-4">
                    <input type="hidden" name="user_id" value="10" />
                    <input type="hidden" name="item_id" value="1" />
                    <div className="radio-buttons borrow flex-column">
                      <div className="radio-button mr-2">
                        <input
                          className="form-check-input"
                          id="pending"
                          name="pending"
                          type="radio"
                          value="1"
                          defaultChecked
                          onChange={this.handleChangeInput('pending')}
                        />
                        <label htmlFor="pending">Jep hua më vonë</label>
                      </div>
                      <div className="radio-button">
                        <input
                          className="form-check-input"
                          id="paid_now"
                          name="pending"
                          type="radio"
                          value="0"
                          onChange={this.handleChangeInput('pending')}
                        />
                        <label htmlFor="paid_now">Jep hua tani</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-3 order-xl-4 mb-4">
                    <input
                      className="btn-styled-2 px-5"
                      name="hua"
                      type="submit"
                      value="Jep hua"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(ConfirmationItem);
