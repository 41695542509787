import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/layouts/Header";
import Sidebar from "../../../components/layouts/Sidebar";
import Borrow from "../../../components/administrator/borrowing/Borrow";

class BorrowView extends Component {
	render() {
		return (
			<Fragment>
				<Sidebar
					activeLink={"borrowing"}
					roleUser={this.props.role}
					firstName={this.props.firstName}
					lastName={this.props.lastName}
				/>
				<div className="w-100" id="content1">
					<Helmet>
						<title>Huazo</title>
					</Helmet>
					<Header
						roleUser={this.props.role}
						firstName={this.props.firstName}
						lastName={this.props.lastName}
					/>
					<Borrow />
				</div>
			</Fragment>
		);
	}
}

export default BorrowView;
