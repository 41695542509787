import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/opac/partials/Header";
import Footer from "../../../components/opac/partials/Footer";
import ChangePassword from "../../../components/opac/opacPanel/ChangePassword";

class ChangePasswordOpacView extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Ndrysho Fjalëkalimin</title>
        </Helmet>
        <div style={{ width: 100 + "%" }}>
          <Header roleUser={this.props.role} />
          <ChangePassword />
          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default ChangePasswordOpacView;
