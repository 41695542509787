import React, { Component, Fragment } from "react";
import Url from "../../../api/Url";
import Pagination from "react-js-pagination";
import Paginate from "../../../helpers/Paginate";
import Request from "../../../api/Request";
import loadingGif from "../../../assets/images/icons/loading.gif";
import noResult from "../../../assets/images/icons/no_result.svg";
import SearchFilter from "../../../helpers/SearchFilter";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import LoadingGif from "../../../assets/images/icons/loading.gif";

class IndexLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listLogs: {
        logs: [{}],
        loading: true,
      },
      searchLogs: {
        subject: "",
        action: "",
        object: "",
        value: "",
        object_id: "",
        from: "",
        to: "",
        error_list: [],
      },
      selectedDate: {
        from: "",
        to: "",
      },
      btnLoading: false,
      searched: false,
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  listLogs = (event) => {
    let request = Url.listlogs;

    if (event) {
      event.preventDefault();
      const searchParams = {
        subject: this.state.searchLogs.subject,
        action: this.state.searchLogs.action,
        object: this.state.searchLogs.object,
        value: this.state.searchLogs.value,
        object_id: this.state.searchLogs.object_id,
        from: this.state.searchLogs.from,
        to: this.state.searchLogs.to,
      };
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({
        searched: true,
      });
      this.setState({ btnLoading: true });
    }

    Request.getRequest(request).then((result) => {
      this.setState({ btnLoading: false });
      if (result.status === 200) {
        this.setState({
          listLogs: {
            logs: result.data.logs.data,
            loading: false,
          },
          searchLogs: {
            ...this.state.searchLogs,
            error_list: [],
          },
          pagination: Paginate.setPaginateData(result.data.logs),
        });
      } else if (result.status === 422) {
        let newForm = {
          ...this.state.searchLogs,
          error_list: result.data,
        };
        this.setState({
          searchLogs: newForm,
          searched: false,
        });
      } else {
        this.setState({
          listLogs: {
            logs: [{}],
            loading: true,
          },
          searched: false,
        });
      }
    });
  };

  componentDidMount() {
    this.listLogs();
  }

  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.searchLogs,
      [key]: e.target.value,
    };
    this.setState({
      searchLogs: newForm,
    });
  };

  handleChangeD = (key) => (e) => {
    let newForm = {
      ...this.state.searchLogs,
      [key]: moment(new Date(e)).format("DD-MM-yyyy"),
    };
    let newForm1 = {
      ...this.state.selectedDate,
      [key]: e,
    };
    this.setState({
      selectedDate: newForm1,
      searchLogs: newForm,
    });
  };
  handlePageChange(pageNumber) {
    let request = Url.listlogs;

    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        subject: this.state.searchLogs.subject,
        action: this.state.searchLogs.action,
        object: this.state.searchLogs.object,
        value: this.state.searchLogs.value,
        object_id: this.state.searchLogs.object_id,
        from: this.state.searchLogs.from,
        to: this.state.searchLogs.to,
        page: pageNumber,
      };
    }
    request = SearchFilter.setFilterParams(searchParams, request);
    Request.getRequest(request).then((result) => {
      this.setState({
        listLogs: {
          logs: result.data.logs.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(result.data.logs),
      });
    });

    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    let { subject, action, object, value, object_id } = this.state.searchLogs;

    var logsList = "";
    if (this.state.listLogs.loading) {
      logsList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      logsList = this.state.listLogs.logs.map((log) => {
        return (
          <tr key={log.id}>
            <td role="cell" className="text-center" data-label="Subjekti">
              {log.subject}
            </td>
            <td role="cell" className="text-center" data-label="Veprimi">
              {log.action}
            </td>
            <td role="cell" className="text-center" data-label="Objekti">
              {log.object}
            </td>
            <td role="cell" className="text-center" data-label="Vlera">
              {log.value}
            </td>
            <td role="cell" className="text-center" data-label="Id objekti">
              {log.object_id}
            </td>
            <td role="cell" className="text-center" data-label="Data">
              {dateFormat(log.created_at, "d-mm-yyyy")}
            </td>
          </tr>
        );
      });
    }

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="">
            <p className="partial-title mb-5">Loge</p>
          </div>
          <form onSubmit={this.listLogs}>
            <div className="row input-items mb-2">
              <div className="col-12 col-md-3 pb-4 mb-3">
                <label htmlFor="subject">Subjekti</label>
                <input
                  placeholder="Shkruani subjektin"
                  name="subject"
                  onChange={this.handleChange("subject")}
                  value={subject}
                  type="text"
                  id="subject"
                  className={
                    "input-form-input" +
                    (this.state.searchLogs.error_list.subject
                      ? " form-error"
                      : "")
                  }
                />
                {this.state.searchLogs.error_list.subject && (
                  <span className="error-message left">
                    {this.state.searchLogs.error_list.subject}
                  </span>
                )}
              </div>

              <div className="col-12 col-md-3 pb-4 mb-3">
                <label htmlFor="action">Veprimi</label>
                <input
                  placeholder="Shkruani veprimin"
                  name="action"
                  onChange={this.handleChange("action")}
                  type="text"
                  value={action}
                  id="action"
                  className={
                    "input-form-input" +
                    (this.state.searchLogs.error_list.action
                      ? " form-error"
                      : "")
                  }
                />
                {this.state.searchLogs.error_list.action && (
                  <span className="error-message left">
                    {this.state.searchLogs.error_list.action}
                  </span>
                )}
              </div>
              <div className="col-12 col-md-3 pb-4 mb-3">
                <label htmlFor="object">Objekti</label>
                <input
                  placeholder="Shkruani objektin"
                  name="object"
                  onChange={this.handleChange("object")}
                  type="text"
                  value={object}
                  id="object"
                  className={
                    "input-form-input" +
                    (this.state.searchLogs.error_list.object
                      ? " form-error"
                      : "")
                  }
                />
                {this.state.searchLogs.error_list.object && (
                  <span className="error-message left">
                    {this.state.searchLogs.error_list.object}
                  </span>
                )}
              </div>
              <div className="col-12 col-md-3 pb-4 mb-3">
                <label htmlFor="value">Vlera</label>
                <input
                  placeholder="Shkruani vlerën"
                  name="value"
                  onChange={this.handleChange("value")}
                  type="text"
                  value={value}
                  id="value"
                  className={
                    "input-form-input" +
                    (this.state.searchLogs.error_list.value
                      ? " form-error"
                      : "")
                  }
                />
                {this.state.searchLogs.error_list.object && (
                  <span className="error-message left">
                    {this.state.searchLogs.error_list.value}
                  </span>
                )}
              </div>

              <div className="col-12 col-md-4 pb-4 mb-3">
                <label htmlFor="object_id">Id Objekti</label>
                <input
                  type="number"
                  id="object_id"
                  placeholder="Shkruani ID e objektit"
                  name="object_id"
                  onChange={this.handleChange("object_id")}
                  value={object_id}
                  className={
                    "input-form-input" +
                    (this.state.searchLogs.error_list.object_id
                      ? " form-error"
                      : "")
                  }
                />
                {this.state.searchLogs.error_list.object_id && (
                  <span className="error-message left">
                    {this.state.searchLogs.error_list.object_id}
                  </span>
                )}
              </div>

              <div className="col-12 col-md-4 pb-4 mb-3">
                <label htmlFor="from">Nga Data:</label>
                <DatePicker
                  placeholderText="Zgjidh datën"
                  name="from"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale={sq}
                  dateFormat="dd-MM-yyyy"
                  selected={this.state.selectedDate.from}
                  onChange={this.handleChangeD("from")}
                  autoComplete="off"
                  className={
                    "date_input" +
                    (this.state.searchLogs.error_list.from ? " form-error" : "")
                  }
                />

                {this.state.searchLogs.error_list.from && (
                  <span className="error-message left">
                    {this.state.searchLogs.error_list.from}
                  </span>
                )}
              </div>

              <div className="col-12 col-md-4 pb-4 mb-3">
                <label htmlFor="to">Ne Datën:</label>
                <DatePicker
                  placeholderText="Zgjidh datën"
                  name="to"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale={sq}
                  dateFormat="dd-MM-yyyy"
                  selected={this.state.selectedDate.to}
                  onChange={this.handleChangeD("to")}
                  autoComplete="off"
                  className={
                    "date_input" +
                    (this.state.searchLogs.error_list.to ? " form-error" : "")
                  }
                />
                {this.state.searchLogs.error_list.to && (
                  <span className="error-message left">
                    {this.state.searchLogs.error_list.to}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-2 m-0 p-0 mb-3 pb-3">
              {this.state.btnLoading === true ? (
                <button type="submit" className="btn-styled-2">
                  Duke Filtruar
				  <img
                    src={LoadingGif}
                    style={{
                      width: "18px",
                      margin: "-4px 0 0 7px",
                    }}
                  />
                </button>
              ) : (
                <button type="submit" className="btn-styled-2">
                  Filtro
                </button>
              )}
            </div>
          </form>

          <div className="table-list mb-5">
            <table role="table" className="w-100">
              <thead>
                <tr role="row">
                  <th width="20%" role="columnheader" className="text-left">
                    Subjekti
                  </th>
                  <th width="10%" role="columnheader" className="text-center">
                    Veprimi
                  </th>
                  <th width="10%" role="columnheader" className="text-center">
                    Objekti
                  </th>
                  <th width="35%" role="columnheader" className="text-center">
                    Vlera
                  </th>
                  <th width="10%" role="columnheader" className="text-center">
                    Id objekti
                  </th>
                  <th width="15%" role="columnheader" className="text-center">
                    Data
                  </th>
                </tr>
              </thead>
              <tbody>{logsList}</tbody>
            </table>
            {this.state.pagination.totalItemsCount === 0 &&
              this.state.listLogs.loading === false &&
              this.state.searched === true && (
                <img
                  className="mx-auto d-block"
                  src={noResult}
                  alt="no result"
                />
              )}

            {this.state.pagination.totalItemsCount === 0 &&
              this.state.listLogs.loading === false &&
              this.state.searched === false && (
                <p className="nothing--to--show">Asgjë për të shfaqur</p>
              )}
          </div>

          {this.state.pagination.totalPages > 1 && (
            <div className="d-flex justify-content-center pagination-block mb-5">
              <div className="select-page">
                <Pagination
                  activePage={this.state.pagination.activePage}
                  itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                  totalItemsCount={this.state.pagination.totalItemsCount}
                  pageRangeDisplayed={this.state.pagination.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="pagination-results">
                Faqja: {this.state.pagination.activePage} -{" "}
                {this.state.pagination.totalPages}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default IndexLogs;
