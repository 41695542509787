import React, { Component, Fragment } from "react";
import Request from "../../../../api/Request";
import Url from "../../../../api/Url";
import Pagination from "react-js-pagination";
import Messages from "../../../../helpers/Messages";
import Paginate from "../../../../helpers/Paginate";
import { withRouter } from "react-router-dom";
import SearchFilter from "../../../../helpers/SearchFilter";
import EditServer from "./EditServer";
import AppConfig from "../../../../services/AppConfig";
import editIcon from "../../../../assets/images/icons/edit_icon.svg";
import deleteIcon from "../../../../assets/images/icons/delete_icon.svg";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import noResult from "../../../../assets/images/icons/no_result.svg";
import LoadingGif from "../../../../assets/images/icons/loading.gif";

class CreateServer extends Component {
  constructor() {
    super();
    this.state = {
      listOfServers: {
        servers: [],
        loading: true,
      },
      addServer: {
        name: "",
        url: "",
        port_number: "",
        database_name: "",
        error_list: [],
        isError: false,
      },
      searchServer: {
        term: "",
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
      btnAddLoading: false,
      searched: false,
      pagination: Paginate.setPaginate(),
      page: null,
      showModalPopup: "",
      popupId: "",
      popupName: "",
    };
  }

  isShowPopup = (status) => {
    this.setState({ showModalPopup: status });
    this.listServers();
  };

  componentDidMount() {
    this.listServers();
  }

  getServerData = (e, id, name) => {
    e.preventDefault();
    this.setState({
      popupId: id,
      popupName: name,
    });
  };

  listServers = (hasSearch) => {
    
    let request = Url.listServer;
    const searchParams = {
      term: this.state.searchServer.term,
    };
    if (hasSearch) {
      hasSearch.preventDefault();
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({ searched: true });
      this.setState({
      btnLoading: true,
    });
    }
    
    const servers = Request.getRequest(request).then((response) => {
      this.setState({
        btnLoading: false,
      });
      if (response.status === 200) {
        this.setState({
          listOfServers: {
            servers: response.data.servers.data,
            loading: false,
          },
          form_errors: {
            error_list: [],
          },
          pagination: Paginate.setPaginateData(response.data.servers),
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          searched: false,
        });
      } else {
        this.setState({
          searched: false,
        });
        Messages.swalError(AppConfig.serverError);
      }
    });
    return servers;
  };

  handleReset = (e) => {
    setTimeout(() => {
      this.setState({
        name: "",
        url: "",
        port_number: "",
        database_name: "",
      });
    }, 100);
  };

  handlePageChange = (pageNumber) => {
    let request = Url.listServer;
    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        term: this.state.searchServer.term,
        page: pageNumber,
      };
    }
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listOfServers: {
          servers: response.data.servers.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.servers),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  saveServer = (e) => {
    e.preventDefault();
    this.setState({
      btnAddLoading: true,
    });
    Request.postRequest(Url.storeServer, this.state.addServer).then(
      (response) => {
        this.setState({
          btnAddLoading: false,
        });
        if (response.status === 201) {
          this.listServers();
          Messages.swalSuccess(response.data.success);
          this.setState({
            addServer: {
              name: "",
              url: "",
              port_number: "",
              database_name: "",
              error_list: [],
              isError: false,
            },
          });
        } else if (response.status === 422) {
          this.setState({
            addServer: {
              ...this.state.addServer,
              error_list: response.data,
              isError: true,
            },
          });
        }
      }
    );
  };

  searchHandler = (e) => {
    this.setState({
      searchServer: {
        [e.target.name]: e.target.value,
        [e.target.url]: e.target.value,
      },
    });
  };

  deleteServer = (e, id, name) => {
    Messages.swalConfirmDelete(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(Url.deleteServer + id).then((response) => {
          if (response.status === 200) {
            this.listServers();
            Messages.swalSuccess(response.data.success);
          } else if (response.status === 405) {
            Messages.swalError(response.data.error);
          } else if (response.status === 404) {
            Messages.swalError(response.data.error);
          }
        });
      }
    });
  };

  render() {
    var serverList = "";
    if (this.state.listOfServers.loading) {
      serverList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      serverList = this.state.listOfServers.servers.map((serverList, id) => {
        return (
          <tr key={serverList.id}>
            <td role="cell" data-label="Emri i autorit">
              {serverList.name}
            </td>
            <td role="cell" data-label="Emri i autorit" className="text-center">
              {serverList.url}
            </td>
            <td role="cell" data-label="Emri i autorit" className="text-center">
              {serverList.port_number}
            </td>
            <td role="cell" data-label="Ndrysho" className="text-center">
              {serverList.database_name}
            </td>
            <td role="cell" data-label="Ndrysho" className="text-center">
              <span
                className="m-auto"
                onClick={() => this.isShowPopup(serverList.id)}
              >
                <button
                  className="btn-unstyled"
                  onClick={(e) =>
                    this.getServerData(e, serverList.id, serverList.name)
                  }
                >
                  <img src={editIcon} alt="Edit Icon" />
                </button>
              </span>
              {this.state.showModalPopup === serverList.id && (
                <EditServer
                  showModalPopup={this.state.showModalPopup.toString()}
                  onPopupClose={this.isShowPopup}
                  server={serverList}
                  popupName={this.state.popupName}
                  popupId={this.state.popupId.toString()}
                />
              )}
            </td>
            <td role="cell" data-label="Fshi" className="text-center">
              <button
                type="button"
                onClick={(e) =>
                  this.deleteServer(e, serverList.id, serverList.name)
                }
                className="btn-unstyled"
              >
                <img src={deleteIcon} alt="Delete Icon" />
              </button>
            </td>
          </tr>
        );
      });
    }

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="create">
            <div>
              <p className="partial-title">Lista e serverave</p>
            </div>
            <div className="row m-0">
              <div className="col-12">
                <form onSubmit={this.saveServer}>
                  <div className="row colored-bg px-3 mb-4 pb-3">
                    <label className="pl-3">Shto server</label>
                    <div className="col-12 col-lg-2 col-md-4 mb-4 mb-md-0 pb-4">
                      <input
                        type="text"
                        name="name"
                        value={this.state.addServer.name}
                        onChange={(e) => {
                          this.setState({
                            addServer: {
                              ...this.state.addServer,
                              name: e.target.value,
                            },
                          });
                        }}
                        placeholder="Server"
                        className={
                          "input-form-input" +
                          (this.state.addServer.isError === true &&
                          this.state.addServer.error_list.name
                            ? " form-error"
                            : "")
                        }
                      />
                      {this.state.addServer.isError === true &&
                        this.state.addServer.error_list.name && (
                          <span className="error-message left">
                            {this.state.addServer.error_list.name[0]}
                          </span>
                        )}
                    </div>
                    <div className="col-12 col-lg-2 col-md-4 mb-4 mb-md-0 pb-4">
                      <input
                        type="text"
                        name="url"
                        value={this.state.addServer.url}
                        onChange={(e) => {
                          this.setState({
                            addServer: {
                              ...this.state.addServer,
                              url: e.target.value,
                            },
                          });
                        }}
                        placeholder="Url"
                        className={
                          "input-form-input" +
                          (this.state.addServer.isError === true &&
                          this.state.addServer.error_list.url
                            ? " form-error"
                            : "")
                        }
                      />
                      {this.state.addServer.isError === true &&
                        this.state.addServer.error_list.url && (
                          <span className="error-message left">
                            {this.state.addServer.error_list.url[0]}
                          </span>
                        )}
                    </div>
                    <div className="col-12 col-lg-2 col-md-4 mb-4 mb-md-0 pb-4">
                      <input
                        type="number"
                        name="port_number"
                        min={0}
                        value={this.state.addServer.port_number}
                        onChange={(e) => {
                          this.setState({
                            addServer: {
                              ...this.state.addServer,
                              port_number: e.target.value,
                            },
                          });
                        }}
                        placeholder="Nr. i Portës"
                        className={
                          "input-form-input" +
                          (this.state.addServer.isError === true &&
                          this.state.addServer.error_list.port_number
                            ? " form-error"
                            : "")
                        }
                      />
                      {this.state.addServer.isError === true &&
                        this.state.addServer.error_list.port_number && (
                          <span className="error-message left">
                            {this.state.addServer.error_list.port_number[0]}
                          </span>
                        )}
                    </div>
                    <div className="col-12 col-lg-2 col-md-4 mb-4 mb-md-0 pb-4">
                      <input
                        type="text"
                        name="database_name"
                        value={this.state.addServer.database_name}
                        onChange={(e) => {
                          this.setState({
                            addServer: {
                              ...this.state.addServer,
                              database_name: e.target.value,
                            },
                          });
                        }}
                        placeholder="Emri i Databazës"
                        className={
                          "input-form-input" +
                          (this.state.addServer.isError === true &&
                          this.state.addServer.error_list.database_name
                            ? " form-error"
                            : "")
                        }
                      />
                      {this.state.addServer.isError === true &&
                        this.state.addServer.error_list.database_name && (
                          <span className="error-message left">
                            {this.state.addServer.error_list.database_name[0]}
                          </span>
                        )}
                    </div>
                    <div className="col-12 col-lg-3 col-md-4">
                      {this.state.btnAddLoading === true ? (
                        <button type="button" className="btn-styled-2 ml-0">
                          Duke Shtuar
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                            />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={this.handleReset}
                          className="btn-styled-2 ml-0"
                        >
                          Shto
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                <form onSubmit={this.listServers}>
                  <div className="row pl-0 colored-sb pl-3 pr-3">
                    <label className="pl-3">Kërko server</label>
                    <div className="col-12 col-lg-3 col-md-5 pb-4 mb-md-0">
                      <input
                        type="text"
                        name="term"
                        placeholder="Shkruaj emrin e Serverit ose Url"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.term
                            ? " form-error"
                            : "")
                        }
                        onChange={this.searchHandler}
                      />
                      {this.state.addServer.isError === true &&
                        this.state.form_errors.error_list.term && (
                          <span className="error-message left">
                            {this.state.form_errors.error_list.term[0]}
                          </span>
                        )}
                    </div>
                    <div className="col-12 col-lg-3 col-md-4">
                      {this.state.btnLoading === true ? (
                        <button
                          type="button"
                          className="btn-styled-2 ml-0"
                        >
                          Duke Kërkuar
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          name="submit"
                          className="btn-styled-2 ml-0"
                        >
                          Kërko
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="table-list mt-5 mb-5">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th role="columnheader" width="25%" className="text-left">
                      Emri i serverit
                    </th>
                    <th role="columnheader" width="15%" className="text-center">
                      Url e serverit
                    </th>
                    <th role="columnheader" width="15%" className="text-center">
                      Numri i portës
                    </th>
                    <th role="columnheader" width="15%" className="text-center">
                      Emri i Databazës
                    </th>
                    <th role="columnheader" width="15%" className="text-center">
                      Ndrysho
                    </th>
                    <th role="columnheader" width="15%" className="text-center">
                      Fshi
                    </th>
                  </tr>
                </thead>
                <tbody>{serverList}</tbody>
              </table>
              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listOfServers.loading === false &&
                this.state.searched === true && (
                  <img
                    className="mx-auto d-block"
                    src={noResult}
                    alt="no result"
                  />
                )}

              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listOfServers.loading === false &&
                this.state.searched === false && (
                  <p className="nothing--to--show">Asgjë për të shfaqur</p>
                )}
            </div>
          </div>
          {this.state.pagination.totalPages > 1 && (
            <div className="d-flex justify-content-center flex-wrap pagination-block mb-5">
              <div className="select-page">
                <Pagination
                  activePage={this.state.pagination.activePage}
                  itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                  totalItemsCount={this.state.pagination.totalItemsCount}
                  pageRangeDisplayed={this.state.pagination.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="pagination-results">
                Faqja:
                {this.state.pagination.activePage} -{" "}
                {this.state.pagination.totalPages}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(CreateServer);
