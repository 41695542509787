import React, { Component, Fragment } from "react";
import Paginate from "../../../../helpers/Paginate";
import Select from "react-select";
import Url from "../../../../api/Url";
import Request from "../../../../api/Request";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import noResult from "../../../../assets/images/icons/no_result.svg";
import SearchFilter from "../../../../helpers/SearchFilter";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import TooltipComp from "../../../../helpers/Tooltip";
import LoadingGif from "../../../../assets/images/icons/loading.gif";

class BorrowingInTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listBiblioitem: {
        biblioitems: [{}],
        faculties: [],
        loading: true,
      },
      search: {
        from: "",
        to: "",
        faculty: "",
      },
      searched: false,
      errors: {
        list: [],
      },
      btnLoading: false,
      btnAddLoading: false,
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handleChangeD = (key) => (e) => {
    let newForm = {
      ...this.state.search,
      [key]: moment(new Date(e)).format("DD-MM-yyyy"),
    };
    this.setState({
      search: newForm,
    });
  };

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.search,
      [key]: selectValue,
    };
    this.setState({
      search: newForm,
    });
  };

  componentDidMount() {
    this.listBiblioitems();
  }

  listBiblioitems = (event) => {
    let request = Url.reportBorrowingInTime;

    if (event) {
      event.preventDefault();
      const searchParams = {
        from: this.state.search.from,
        to: this.state.search.to,
        faculty: this.state.search.faculty,
        filtro: "",
      };
      this.setState({ searched: true });
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({ btnLoading: true });
    }

    Request.getRequest(request).then((result) => {
      this.setState({ btnLoading: false });
      if (result.status === 200) {
        this.setState({
          listBiblioitem: {
            biblioitems: result.data.biblioitems.data,
            faculties: result.data.faculties,
            loading: false,
          },
          errors: {
            list: [],
          },
          pagination: Paginate.setPaginateData(result.data.biblioitems),
        });
      } else if (result.status === 422) {
        this.setState({
          errors: {
            list: result.data,
          },
          searched: false,
        });
      } else {
        this.setState({
          listBiblioitem: {
            biblioitems: [{}],
            loading: true,
          },
          searched: false,
        });
      }
    });
  };

  handlePageChange(pageNumber) {
    let request = Url.reportBorrowingInTime;
    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        from: this.state.search.from,
        to: this.state.search.to,
        filtro: "",
        page: pageNumber,
      };
    }
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listBiblioitem: {
          biblioitems: response.data.biblioitems.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.biblioitems),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  download(e) {
    e.preventDefault();
    this.setState({ btnAddLoading: true });
    let request = Url.reportExportBorrowingInTime;

    var searchParams = {
      from: this.state.search.from,
      to: this.state.search.to,
      faculty: this.state.search.faculty,
      export_borrowings: "",
    };

    request = SearchFilter.setFilterParams(searchParams, request);

    const link = document.createElement("a");
    Request.getRequestForDownloadExcel(request).then((response) => {
      this.setState({ btnAddLoading: false });
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.setAttribute("download", "Report_Borrowings_InTime.xlsx");
      link.click();
    });
  }

  render() {
    var biblioitemsList = "";
    if (this.state.listBiblioitem.loading) {
      biblioitemsList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      var facultiesArr = [];
      if (this.state.listBiblioitem.faculties) {
        for (const [index, [, value]] of Object.entries(
          Object.entries(this.state.listBiblioitem.faculties)
        )) {
          facultiesArr.push(value);
        }
        var faculties = facultiesArr.map((item) => {
          return { value: item, label: item };
        });
      }

      biblioitemsList = this.state.listBiblioitem.biblioitems.map(
        (item, index) => {
          return (
            <tr key={item.id}>
              <td role="cell" data-label="Titull" className="title-borrowing">
                {TooltipComp.tooltipDisabledMobile(
                  "-title-" + index,
                  item.biblio.title
                )}
              </td>
              <td role="cell" data-label="Autor" className="text-center">
                {item.biblio["authors"].map((item, index, arr) => {
                  if (index === arr.length - 1) {
                    return item["name"];
                  } else {
                    return item["name"] + "; ";
                  }
                })}
              </td>
              <td role="cell" data-label="Botuesi" className="text-center">
                {item.publisher ? item.publisher.name : ""}
              </td>
              <td role="cell" data-label="Issn / Isbn" className=" text-center">
                {item.isbn} {item.isbn && item.issn && " / "} {item.issn}
              </td>
              <td
                role="cell"
                data-label="Nr. Huazimeve"
                className="text-center"
              >
                {item.borrowings_count}
              </td>
            </tr>
          );
        }
      );
    }
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <form onSubmit={this.listBiblioitems}>
            <div className="row input-items mb-2 pb-2">
              <div className="col-12 col-sm-6 col-md-4 pb-4">
                <label htmlFor="from">Nga Data:</label>
                <DatePicker
                  placeholderText="Zgjidh datën"
                  name="from"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale={sq}
                  dateFormat="DD-MM-yyyy"
                  value={this.state.search.from}
                  onChange={this.handleChangeD("from")}
                  autoComplete="off"
                  className={
                    "date_input" +
                    (this.state.errors.list.from ? " form-error" : "")
                  }
                />
                {this.state.errors.list.from && (
                  <span className="error-message left">
                    {this.state.errors.list.from}
                  </span>
                )}
              </div>

              <div className="col-12 col-sm-6 col-md-4 pb-4">
                <label htmlFor="to">Në Datën:</label>
                <DatePicker
                  placeholderText="Zgjidh datën"
                  name="to"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale={sq}
                  dateFormat="DD-MM-yyyy"
                  value={this.state.search.to}
                  onChange={this.handleChangeD("to")}
                  autoComplete="off"
                  className={
                    "date_input" +
                    (this.state.errors.list.to ? " form-error" : "")
                  }
                />
                {this.state.errors.list.to && (
                  <span className="error-message left">
                    {this.state.errors.list.to}
                  </span>
                )}
              </div>
              <div className="col-12 col-sm-6 col-md-4 pb-4">
                <label htmlFor="to">Fakulteti:</label>
                <Select
                  className={
                    "basic-select" +
                    (this.state.errors.list.faculty ? " form-error" : "")
                  }
                  classNamePrefix="select_input"
                  name="faculty"
                  isSearchable={true}
                  placeholder="Fakulteti"
                  isClearable={true}
                  options={faculties}
                  onChange={this.handleChangeSelect("faculty")}
                />
                {this.state.errors.list.faculty && (
                  <span className="error-message left">
                    {this.state.errors.list.faculty}
                  </span>
                )}
              </div>
            </div>

            <div className="row pb-2 mb-2 mt-4">
              <div className="col-md-12 d-flex flex-wrap">
                {this.state.btnLoading === true ? (
                  <button
                    className="btn-styled-2 ml-0 mt-0 mb-3 px-5  mr-5"
                    id="filtro"
                    name="filtro"
                    type="button"
                  >
                    Duke Kërkuar
                    <img
                      src={LoadingGif}
                      style={{
                        width: "18px",
                        margin: "-4px 0 0 7px",
                      }}
                    />
                  </button>
                ) : (
                  <button
                    className="btn-styled-2 ml-0 mt-0 mb-3 px-5  mr-5"
                    id="filtro"
                    name="filtro"
                    type="submit"
                  >
                    Kërko
                  </button>
                )}
                {this.state.btnAddLoading === true ? (
                  <button
                    className="btn-styled-2 ml-0 mt-0 mb-3 px-5 export-in-time"
                    name="export_borrowings"
                    id="exportintime"
                    type="button"
                  >
                    Duke Eksportuar
                    <img
                      src={LoadingGif}
                      style={{
                        width: "18px",
                        margin: "-4px 0 0 7px",
                      }}
                    />
                  </button>
                ) : (
                  <button
                    className="btn-styled-2 ml-0 mt-0 mb-3 px-5 export-in-time"
                    name="export_borrowings"
                    id="exportintime"
                    type="button"
                    onClick={(e) => this.download(e)}
                  >
                    Eksporto
                  </button>
                )}
              </div>
            </div>
          </form>
          <div className="w-100 mb-5">
            <div className="table-list">
              <table role="table" className="w-100">
                <thead role="rowgroup">
                  <tr role="row">
                    <th width="30%" role="columnheader" className="text-left">
                      Titull
                    </th>
                    <th width="20%" role="columnheader" className="text-center">
                      Autor
                    </th>
                    <th width="20%" role="columnheader" className="text-center">
                      Botuesi
                    </th>
                    <th width="15%" role="columnheader" className="text-center">
                      Issn / Isbn
                    </th>
                    <th width="15%" role="columnheader" className="text-center">
                      Nr. i Huazimeve
                    </th>
                  </tr>
                </thead>

                <tbody role="rowgroup">{biblioitemsList}</tbody>
              </table>
            </div>
            {this.state.pagination.totalItemsCount === 0 &&
              this.state.listBiblioitem.loading === false &&
              this.state.searched === true && (
                <img
                  className="mx-auto d-block"
                  src={noResult}
                  alt="no result"
                />
              )}

            {this.state.pagination.totalItemsCount === 0 &&
              this.state.listBiblioitem.loading === false &&
              this.state.searched === false && (
                <p className="nothing--to--show">Asgjë për të shfaqur</p>
              )}
            {this.state.pagination.totalPages > 1 && (
              <div className="d-flex justify-content-center flex-wrap pagination-block mt-4 mb-5">
                <div className="select-page">
                  <Pagination
                    activePage={this.state.pagination.activePage}
                    itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                    totalItemsCount={this.state.pagination.totalItemsCount}
                    pageRangeDisplayed={
                      this.state.pagination.pageRangeDisplayed
                    }
                    onChange={this.handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
                <div className="pagination-results">
                  {`Faqja: ${this.state.pagination.activePage} - ${this.state.pagination.totalPages}`}
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default BorrowingInTime;
