import React from "react";
import { Component, Fragment } from "react";
import Request from "../../../../api/Request";
import Url from "../../../../api/Url";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Messages from "../../../../helpers/Messages";
import Select from "react-select";
import Downshift from "downshift";
import AppConfig from "../../../../services/AppConfig";

class AuthorModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addAuthor: {
				name_author: "",
				type_author: "",
			},
			selectedAuthor: [],
			selectedType: [],
			showBiblios: {
				authors: this.props.authors,
			},
			showModal: false,
			errors: [],
		};
		this.downshiftOnChange = this.downshiftOnChange.bind(this);
		this.selectOnchange = this.selectOnchange.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.authors !== this.props.authors) {
			this.setState({
				...this.state,
				showBiblios: {
					authors: this.props.authors,
				},
			});
		}
	}
	selectOnchange = (e) => {
		var selectValue;
		if (e !== null) {
			selectValue = e.value;
		} else {
			selectValue = "";
		}
		this.setState({
			...this.state,
			selectedType: selectValue,
			addAuthor: {
				...this.state.addAuthor,
				type_author: selectValue,
			},
		});
	};

	inputOnChange = (e) => {
		this.setState({
			...this.state,
			addAuthor: {
				...this.state.addAuthor,
				name_author: e.target.value,
			},
			selectedAuthor: [],
		});
	};

	downshiftOnChange = (selectedAuthor) => {
		this.setState({
			selectedAuthor: selectedAuthor,
			selectedType: this.state.addAuthor.type_author,
			addAuthor: {
				...this.state.addAuthor,
				name_author: selectedAuthor.name,
			},
		});
	};

	onTrigger = (e) => {
		e.preventDefault();

		if (this.state.selectedAuthor.length === 0) {
			Request.postRequest(Url.createAuthorBiblio, this.state.addAuthor).then(
				(response) => {
					if (response.status === 201) {
						this.props.parentCallback(
							response.data.author,
							this.state.selectedType,
							"new",
						);
						this.setState({
							errors: [],
							addAuthor: {
								name_author: "",
								type_author: "",
							},
						});

						this.closeModal();
					} else if (response.status === 405) {
						Messages.swalInfo(response.data.info);
					} else if (response.status === 422) {
						this.setState({
							errors: response.data,
						});
					} else {
						Messages.swalError(AppConfig.serverError);
					}
				},
			);
		} else if (this.state.addAuthor.type_author !== "") {
			this.props.parentCallback(
				this.state.selectedAuthor,
				this.state.selectedType,
				"old",
			);

			this.setState({
				selectedAuthor: [],
				selectedType: [],
			});
			this.closeModal();
		} else if (
			this.state.addAuthor.type_author === "" &&
			this.state.inputValue !== ""
		) {
			this.setState({
				errors: {
					type_author: "Tipi është i detyrueshem",
				},
			});
		}
	};

	closeModal = () => {
		this.setState({
			errors: [],
			addAuthor: {
				name_author: "",
				type_author: "",
			},
			selectedAuthor: [],
			selectedType: [],
		});
		this.props.onPopupClose(false);
	};

	render() {
		var test = this.state.showBiblios.authors;
		return (
			<Fragment>
				<Modal
					show={this.props.showPopup}
					onHide={this.closeModal}
					size="md"
					centered
				>
					<Modal.Header>
						<Modal.Title id="sign-in-title">
							Shto {this.props.popupName}
						</Modal.Title>
						<button
							className="btn-unstyled close-modal"
							type="button"
							data-dismiss="modal"
							aria-label="Close"
							onClick={this.closeModal}
						>
							✕
						</button>
					</Modal.Header>
					<form onSubmit={this.onTrigger}>
						<Modal.Body>
							<Downshift
								onChange={this.downshiftOnChange}
								itemToString={(item) => (item ? item.name : "")}
							>
								{({
									selectedItem,
									getInputProps,
									getItemProps,
									inputValue,
									highlightedIndex,
								}) => (
									<div>
										<div className="m-0 row pb-2">
											<div className="modal-input-w w-100 pb-4 col-6 modal-authornametext-container">
												<input
													type="text"
													placeholder={this.props.popupName === 'Autor' ? this.props.popupName + ' (100/A)' :
													this.props.popupName + ' (700/A)'}
													className={
														"input-form-input" +
														(this.state.errors.name_author ? " form-error" : "")
													}
													{...getInputProps({
														onChange: this.inputOnChange,
													})}
													name="name_author"
													value={this.state.addAuthor.name_author}
												/>
												{this.state.errors.name_author && (
													<span className="error-message left">
														{this.state.errors.name_author}
													</span>
												)}
											</div>
											<div className="col-6 pb-4">
												<Select
													className={
														"basic-select" +
														(this.state.errors.type_author ? " form-error" : "")
													}
													classNamePrefix="select_input"
													name="type_author"
													placeholder={this.props.popupName === 'Autor' ? "Tipi (100/E)" :
													'Tipi (700/E)'}
													isClearable={true}
													onChange={this.selectOnchange}
													isSearchable={false}
													options={this.props.types}
												/>
												{this.state.errors.type_author && (
													<span className="error-message left">
														{this.state.errors.type_author}
													</span>
												)}
											</div>
										</div>

										<div className="col-12 author-search">
											<ul className="borderi data-list w-100 ">
												{this.state.showBiblios.authors
													.filter(
														(item) =>
															!inputValue ||
															item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
																.toLowerCase()
																.includes(inputValue.toLowerCase()),
													)
													.slice(0)
													.map((item, index) => (
														<li
															id="author-live-search-results"
															className={`pointer-li mt-1 author_${item.id}`}
															{...getItemProps({
																key: index,
																item,
															})}
															style={{
																backgroundColor:
																	selectedItem === item ||
																	highlightedIndex === index
																		? "#007aff"
																		: "#dcedff",
																color:
																	selectedItem === item ||
																	highlightedIndex === index
																		? "#fff"
																		: "#74767E",
																padding: item && "2px 15px",
															}}
														>
															{item.name}
														</li>
													))}
											</ul>
										</div>
									</div>
								)}
							</Downshift>
						</Modal.Body>
						<Modal.Footer>
							<div className="col-12 d-flex m-0 p-0 justify-content-center">
								<div className="col-6 pl-0 pr-2 pr-sm-3 justify-content-center">
									<button
										type="submit"
										className="btn-styled-2 w-100 text-center"
										onClick={this.onTrigger}
									>
										Shto
									</button>
								</div>
								<div className="col-6 pr-0 pl-2 pl-sm-3">
									<button
										className="btn-styled-1 w-100 text-center"
										type="button"
										onClick={this.closeModal}
									>
										Anullo
									</button>
								</div>
							</div>
						</Modal.Footer>
					</form>
				</Modal>
			</Fragment>
		);
	}
}
export default withRouter(AuthorModal);
