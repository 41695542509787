import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/layouts/Header";
import Sidebar from "../../../components/layouts/Sidebar";
import IndexBorrowing from "../../../components/administrator/raporte/borrowing/IndexBorrowing";

class ReportBorrowingView extends Component {
	render() {
		return (
			<Fragment>
				<Sidebar
					activeLink={"reports"}
					roleUser={this.props.role}
					firstName={this.props.firstName}
					lastName={this.props.lastName}
				/>
				<div className="w-100" id="content1">
					<Helmet>
						<title>Raporte për Huazim</title>
					</Helmet>
					<Header
						roleUser={this.props.role}
						firstName={this.props.firstName}
						lastName={this.props.lastName}
					/>
					<IndexBorrowing />
				</div>
			</Fragment>
		);
	}
}

export default ReportBorrowingView;
