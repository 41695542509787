import { Component, Fragment } from "react";
import delteIcon from "../../../../assets/images/icons/delete_icon.svg";
import editIcon from "../../../../assets/images/icons/edit_icon.svg";
import Select from "react-select";
import EditSeriePopup from "./EditSeriePopup";
import { withRouter } from "react-router";
import Periodike from "../../../../assets/images/covers/Periodike.svg";
import AppConfig from "../../../../services/AppConfig";
import Messages from "../../../../helpers/Messages";
import Url from "../../../../api/Url";
import Request from "../../../../api/Request";
import EditSerieAddItem from "./EditSerieAddItem";
import TooltipComp from "../../../../helpers/Tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import LoadingGif from "../../../../assets/images/icons/loading.gif";

class EditSerie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      biblioitemId: this.props.BiblioitemId,
      showModalPopup: false,
      showModalId: "",
      listSeries: {
        approvedSeries: this.props.ApprovedSeries,
      },
      items: [],
      itemsEdit: [],
      itemEditFormError: [],
      loadingItems: true,
      btnLoading: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ApprovedSeries !== this.props.ApprovedSeries) {
      this.editSerial();
    }
  }

  isShowPopup = (status) => {
    this.setState({
      showModalPopup: status,
    });
    const serialId = this.props.match.params.id;
    let request = Url.editSerial + serialId;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          listSeries: {
            approvedSeries: response.data.approvedSeries,
          },
        });
      }
    });
  };

  isShowPopupId = (status) => {
    this.setState({ showModalId: status });
  };

  editSerial = () => {
    const serialId = this.props.match.params.id;
    let request = Url.editSerial + serialId;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          listSeries: {
            approvedSeries: response.data.approvedSeries,
          },
        });
      }
    });
  };

  checkSelectValue = (value) => {
    switch (value) {
      case "salle":
        return { value: "salle", label: "Sallë" };
      case "normal":
        return { value: "normal", label: "Normal" };
      case "ipadisponueshem":
        return { value: "ipadisponueshem", label: "I Padisponueshëm" };
      default:
        return "";
    }
  };

  showItems = (e, id) => {
    e.preventDefault();
    let request = Url.editSerie + id;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          items: response.data.serie.items,
          itemsEdit: response.data.serie.items,
          itemsEditw: response.data.serie.items,
        });
      }
    });

    this.setState({
      loadingItems: true,
    });
    setTimeout(() => {
      this.setState({
        loadingItems: false,
      });
    }, 900);
    this.setState({
      itemId: id,
    });
  };

  addItems = (e, id) => {
    this.setState({
      addItemId: id,
    });
  };

  deleteSerie = (e, id) => {
    Messages.swalConfirmDelete().then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(Url.deleteSerie + id).then((response) => {
          if (response.status === 200) {
            this.editSerial();
            Messages.swalSuccess(response.data.success);
          } else if (response.status === 404) {
            Messages.swalError(response.data.error);
          } else if (response.status === 405) {
            Messages.swalInfo(response.data.info);
          } else {
            Messages.swalError(AppConfig.serverError);
          }
        });
      }
    });
  };

  deleteSerieItem = (e, id, serieID) => {
    Messages.swalConfirmDelete().then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(Url.deleteSerieItem + id).then((response) => {
          if (response.status === 200) {
            let request = Url.editSerie + serieID;
            Request.getRequest(request).then((response) => {
              if (response.status === 200) {
                this.setState({
                  items: response.data.serie.items,
                });
              }
            });
            Messages.swalSuccess(response.data.success);
          } else if (response.status === 404) {
            Messages.swalError(response.data.error);
          } else if (response.status === 405) {
            Messages.swalInfo(response.data.info);
          } else {
            Messages.swalError(AppConfig.serverError);
          }
        });
      }
    });
  };

  editItems = (e, serieId) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const inputs = this.state.itemsEditw;

    var itemsName = inputs.map((item, index) => {
      var damagedDate = "";
      if (item.damaged_date !== null) {
        damagedDate = item.damaged_date;
      }
      var renewalDate = "";
      if (item.renewal_date !== null) {
        renewalDate = item.renewal_date;
      }
      var lostDate = "";
      if (item.lost_date !== null) {
        lostDate = item.lost_date;
      }

      const object = {
        [`items[${index}][id]`]: item.id,
        [`items[${index}][borrowing_type]`]: item.borrowing_type,
        [`items[${index}][inventory_number]`]: item.inventory_number,
        [`items[${index}][place_number]`]: item.place_number,
        [`items[${index}][damaged]`]: item.damaged,
        [`items[${index}][damaged_date]`]: damagedDate,
        [`items[${index}][renewal_date]`]: renewalDate,
        [`items[${index}][lost]`]: item.lost,
        [`items[${index}][lost_date]`]: lostDate,
      };

      return object;
    });

    const formData = new FormData();

    var itemsMethod = { _method: "PATCH" };
    var itemsG = Object.assign(...itemsName, itemsMethod);

    for (const [key, value] of Object.entries(itemsG)) {
      formData.append(key, value);
    }

    Request.postRequest(Url.updateSerieItems + serieId, formData).then(
      (response) => {
        this.setState({ btnLoading: false });
        if (response.status === 200) {
          this.setState({
            itemEditFormError: [],
          });
          Messages.swalSuccess(response.data.success);
        } else if (response.status === 422) {
          this.setState({
            itemEditFormError: response.data,
          });
        } else {
          Messages.swalError(AppConfig.serverError);
        }
      }
    );
  };

  handleChange = (e, name, i) => {
    const inputs = this.state.itemsEdit;
    const inputs1 = this.state.itemsEditw;
    inputs[i][name] = e.target.value;
    inputs1[i][name] = e.target.value;
    this.setState({
      itemsEdit: inputs,
      itemsEditw: inputs1,
    });
  };

  handleChangeSelect = (e, name, i) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    const inputs = this.state.itemsEdit;
    const inputs1 = this.state.itemsEditw;
    inputs[i][name] = selectValue;
    inputs1[i][name] = selectValue;
    this.setState({
      itemsEdit: inputs,
      itemsEditw: inputs1,
    });
  };

  handleChangeDate = (e, name, i) => {
    const inputs = this.state.itemsEdit;
    const inputs1 = this.state.itemsEditw;
    var value = null;
    if (e !== null) {
      value = moment(e).format("yyyy-MM-DD");
    }
    inputs[i][name] = value;
    inputs1[i][name] = value;
    this.setState({
      itemsEdit: inputs,
      itemsEditw: inputs1,
    });
  };

  checkSelectedDate = (value) => {
    if (value === "" || value === null) {
      return null;
    } else {
      return new Date(value);
    }
  };

  checkBorrownigType = (borrowingType) => {
    switch (borrowingType) {
      case "salle":
        return "Sallë";
      case "normal":
        return "Normal";
      case "ipadisponueshem":
        return "I padisponueshëm";
      default:
        return "";
    }
  };

  render() {
    var serieList = "";
    serieList = this.state.listSeries.approvedSeries.map((data, index) => {
      var serieItems = "";
      var editSerieItems = "";
      if (this.state.loadingItems === true) {
        serieItems = (
          <img className="m-auto d-block" src={loadingGif} alt="loading" />
        );
      } else {
        serieItems = this.state.items.map((serieItem) => {
          return (
            <div className="col-12 mt-3 p-0 series-items">
              <div className="serie-item-info">
                {AppConfig.getRole() !== "Administrator" && (
                  <div className="col-12 col-md-4">
                    <div>
                      Lloji i huazimit :{" "}
                      {serieItem.borrowing_type
                        ? this.checkBorrownigType(serieItem.borrowing_type)
                        : "-"}
                    </div>
                    <div>Kodifikimi : {serieItem.inventory_number}</div>
                  </div>
                )}
                <div className="col-12 col-md-4">
                  {AppConfig.getRole() === "Administrator" && (
                    <Fragment>
                      <div>
                        Lloji i huazimit :{" "}
                        {serieItem.borrowing_type
                          ? this.checkBorrownigType(serieItem.borrowing_type)
                          : "-"}
                      </div>
                      <div>Kodifikimi : {serieItem.inventory_number}</div>
                    </Fragment>
                  )}

                  <div>Numri i inventarit : {serieItem.id}</div>
                  <div>Numri i vendit : {serieItem.place_number}</div>
                </div>
                {AppConfig.getRole() === "Administrator" && (
                  <Fragment>
                    <div className="col-12 col-md-4">
                      <div>
                        Spastruar : {serieItem.lost === 0 ? "Jo" : "Po"}
                      </div>
                      <div>
                        Datë spastrimi :{" "}
                        {serieItem.lost_date !== null
                          ? moment(serieItem.lost_date).format("DD-MM-yyyy")
                          : "-"}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div>
                        Dëmtuar : {serieItem.damaged === 0 ? "Jo" : "Po"}
                      </div>
                      <div>
                        Datë Dëmtimi :{" "}
                        {serieItem.damaged_date !== null
                          ? moment(serieItem.damaged_date).format("DD-MM-yyyy")
                          : "-"}
                      </div>
                      <div>
                        Datë Rinovimi :{" "}
                        {serieItem.renewal_date !== null
                          ? moment(serieItem.renewal_date).format("DD-MM-yyyy")
                          : "-"}
                      </div>
                    </div>
                  </Fragment>
                )}
                <button
                  className="btn-unstyled delete-item"
                  onClick={(e) =>
                    this.deleteSerieItem(e, serieItem.id, data.id)
                  }
                >
                  {TooltipComp.tooltip(
                    "fshi" + index,
                    <img src={delteIcon} alt="Fshi Materialin" />,
                    "Fshi Materialin"
                  )}
                </button>
              </div>
            </div>
          );
        });

        editSerieItems = this.state.itemsEdit.map((serieItem, indexSerie) => {
          return (
            <div
              key={`edit-serie-${indexSerie + 1}`}
              className="col-12 col-md-11 px-0 mt-3 series-items edit-serie-item d-flex flex-wrap mb-0"
            >
              <div className="col-12 pb-2 p-0 pb-4 m-0 d-flex flex-wrap align-items-end">
                <div className="col-12 col-md-4 m-0 pb-4">
                  <label htmlFor="Lloji i huazimit" className="main-label">
                    Lloji i huazimit
                  </label>
                  <Select
                    className={
                      "basic-select" +
                      (this.state.itemEditFormError[
                        `items.${indexSerie}.borrowing_type`
                      ]
                        ? " form-error"
                        : "")
                    }
                    classNamePrefix="select_input"
                    name={`items[${indexSerie}][borrowing_type]`}
                    placeholder="Lloji i huazimit"
                    isClearable={true}
                    value={this.checkSelectValue(serieItem.borrowing_type)}
                    isSearchable={false}
                    options={[
                      { value: "salle", label: "Sallë" },
                      { value: "normal", label: "Normal" },
                      { value: "ipadisponueshem", label: "I Padisponueshëm" },
                    ]}
                    onChange={(e) =>
                      this.handleChangeSelect(e, `borrowing_type`, indexSerie)
                    }
                  />
                  {this.state.itemEditFormError[
                    `items.${indexSerie}.borrowing_type`
                  ] && (
                    <span className="error-message left">
                      {
                        this.state.itemEditFormError[
                          `items.${indexSerie}.borrowing_type`
                        ]
                      }
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4 m-0 pb-4">
                  <label htmlFor="Inventory_number" className="main-label">
                    Kodifikimi
                  </label>
                  <input
                    placeholder="Kodifikimi"
                    className={
                      "input-form-input" +
                      (this.state.itemEditFormError[
                        `items.${indexSerie}.inventory_number`
                      ]
                        ? " form-error"
                        : "")
                    }
                    name={`items[${indexSerie}][inventory_number]`}
                    type="text"
                    value={serieItem.inventory_number}
                    onChange={(e) =>
                      this.handleChange(e, `inventory_number`, indexSerie)
                    }
                  />
                  {this.state.itemEditFormError[
                    `items.${indexSerie}.inventory_number`
                  ] && (
                    <span className="error-message left">
                      {
                        this.state.itemEditFormError[
                          `items.${indexSerie}.inventory_number`
                        ]
                      }
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4 m-0 pb-4">
                  <label htmlFor="Place_number" className="main-label">
                    Numri i vendit
                  </label>
                  <input
                    placeholder="Numri i vendit"
                    className={
                      "input-form-input" +
                      (this.state.itemEditFormError[
                        `items.${indexSerie}.place_number`
                      ]
                        ? " form-error"
                        : "")
                    }
                    name={`items[${indexSerie}][place_number]`}
                    type="text"
                    value={serieItem.place_number}
                    onChange={(e) =>
                      this.handleChange(e, `place_number`, indexSerie)
                    }
                  />
                  {this.state.itemEditFormError[
                    `items.${indexSerie}.place_number`
                  ] && (
                    <span className="error-message left">
                      {
                        this.state.itemEditFormError[
                          `items.${indexSerie}.place_number`
                        ]
                      }
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 pb-2 p-0 pb-4 m-0 d-flex flex-wrap">
                <div className="col-12 col-md-4 m-0 pb-4">
                  <label htmlFor="Inventory_number" className="main-label">
                    Numri i inventarit
                  </label>
                  <input
                    placeholder="Numri i inventarit"
                    className="input-form-input"
                    type="text"
                    defaultValue={serieItem.id}
                    disabled
                  />
                </div>
              </div>

              {AppConfig.getRole() === "Administrator" && (
                <Fragment>
                  <div className="col-12 pb-2 p-0 pb-4 m-0 d-flex flex-wrap">
                    <div className="col-12 col-md-4 m-0">
                      <label>Dëmtuar</label>
                      <div className="radio-buttons d-flex">
                        <div className="radio-button mr-4">
                          <label htmlFor={`damaged_${serieItem.id}`}>Po</label>
                          <input
                            className="form-check-input"
                            name={`items[${indexSerie}][damaged]`}
                            type="radio"
                            id={`damaged_${serieItem.id}`}
                            defaultChecked={
                              serieItem.damaged === 1 ? true : false
                            }
                            value="1"
                            onChange={(e) =>
                              this.handleChange(e, `damaged`, indexSerie)
                            }
                          />
                        </div>

                        <div className="radio-button ml-4">
                          <label htmlFor={`notdamaged_${serieItem.id}`}>
                            Jo
                          </label>
                          <input
                            className="form-check-input"
                            id={`notdamaged_${serieItem.id}`}
                            name={`items[${indexSerie}][damaged]`}
                            defaultChecked={
                              serieItem.damaged === 0 ? true : false
                            }
                            type="radio"
                            value="0"
                            onChange={(e) =>
                              this.handleChange(e, `damaged`, indexSerie)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {serieItem.damaged === "1" || serieItem.damaged === 1 ? (
                      <Fragment>
                        <div className="col-12 col-md-4 m-0">
                          <label>Data e dëmtimit</label>
                          <DatePicker
                            placeholderText="Zgjidh datën"
                            name={`items[${indexSerie}][damaged_date]`}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            locale={sq}
                            dateFormat="dd-MM-yyyy"
                            autoComplete="off"
                            selected={this.checkSelectedDate(
                              serieItem.damaged_date
                            )}
                            onChange={(e) =>
                              this.handleChangeDate(
                                e,
                                `damaged_date`,
                                indexSerie
                              )
                            }
                            className={
                              "date_input" +
                              (this.state.itemEditFormError[
                                `items.${indexSerie}.damaged_date`
                              ]
                                ? " form-error"
                                : "")
                            }
                          />
                          {this.state.itemEditFormError[
                            `items.${indexSerie}.damaged_date`
                          ] && (
                            <span className="error-message left">
                              {
                                this.state.itemEditFormError[
                                  `items.${indexSerie}.damaged_date`
                                ]
                              }
                            </span>
                          )}
                        </div>
                        <div className="col-12 col-md-4 m-0">
                          <label>Data e rinovimit</label>
                          <DatePicker
                            placeholderText="Zgjidh datën"
                            name={`items[${indexSerie}][renewal_date]`}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            locale={sq}
                            dateFormat="dd-MM-yyyy"
                            autoComplete="off"
                            selected={this.checkSelectedDate(
                              serieItem.renewal_date
                            )}
                            onChange={(e) =>
                              this.handleChangeDate(
                                e,
                                `renewal_date`,
                                indexSerie
                              )
                            }
                            className={
                              "date_input" +
                              (this.state.itemEditFormError[
                                `items.${indexSerie}.renewal_date`
                              ]
                                ? " form-error"
                                : "")
                            }
                          />
                          {this.state.itemEditFormError[
                            `items.${indexSerie}.renewal_date`
                          ] && (
                            <span className="error-message left">
                              {
                                this.state.itemEditFormError[
                                  `items.${indexSerie}.renewal_date`
                                ]
                              }
                            </span>
                          )}
                        </div>
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-12 p-0 pb-4 m-0 d-flex flex-wrap">
                    <div className="col-12 col-md-4 m-0">
                      <label>Spastruar</label>

                      <div className="radio-buttons d-flex">
                        <div className="radio-button mr-4">
                          <label htmlFor={`lost_${serieItem.id}`}>Po</label>
                          <input
                            className="form-check-input"
                            id={`lost_${serieItem.id}`}
                            name={`items[${indexSerie}][lost]`}
                            defaultChecked={serieItem.lost === 1 ? true : false}
                            type="radio"
                            value="1"
                            onChange={(e) =>
                              this.handleChange(e, `lost`, indexSerie)
                            }
                          />
                        </div>

                        <div className="radio-button ml-4">
                          <label htmlFor={`notlost_${serieItem.id}`}>Jo</label>
                          <input
                            className="form-check-input"
                            id={`notlost_${serieItem.id}`}
                            name={`items[${indexSerie}][lost]`}
                            defaultChecked={serieItem.lost === 0 ? true : false}
                            type="radio"
                            value="0"
                            onChange={(e) =>
                              this.handleChange(e, `lost`, indexSerie)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {serieItem.lost === 1 || serieItem.lost === "1" ? (
                      <div className="col-12 col-md-4 m-0">
                        <label>Data e spastrimit</label>
                        <DatePicker
                          placeholderText="Zgjidh datën"
                          name={`items[${indexSerie}][lost_date]`}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          locale={sq}
                          dateFormat="dd-MM-yyyy"
                          selected={this.checkSelectedDate(serieItem.lost_date)}
                          onChange={(e) =>
                            this.handleChangeDate(e, `lost_date`, indexSerie)
                          }
                          autoComplete="off"
                          className={
                            "date_input" +
                            (this.state.itemEditFormError[
                              `items.${indexSerie}.lost_date`
                            ]
                              ? " form-error"
                              : "")
                          }
                        />
                        {this.state.itemEditFormError[
                          `items.${indexSerie}.lost_date`
                        ] && (
                          <span className="error-message left">
                            {
                              this.state.itemEditFormError[
                                `items.${indexSerie}.lost_date`
                              ]
                            }
                          </span>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          );
        });
      }
      return (
        <div
          key={index}
          className="cols-sm-12 p-3 series-items-d edit-serie"
          id={"group-serie-" + index}
        >
          <div className="col-12 px-0 d-flex flex-wrap">
            <div className="col-12 col-sm-4 col-md-3 p-0 cover">
              <div className="cover-img">
                {data.cover !== null ? (
                  <img
                    className="file-upload-image"
                    src={AppConfig.serieCoversPath + data.cover}
                    alt="Imazhi i serisë"
                  />
                ) : (
                  <img src={Periodike} alt="Imazhi i serisë" />
                )}
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 info-serie">
              <span>{data.pattern ? data.pattern : "-"}</span>
              <span>
                {data.published_date !== null
                  ? moment(data.published_date).format("DD-MM-yyy")
                  : "-"}
              </span>
            </div>
            <div className="col-12 col-sm-6 col-md-4 item-btns">
              <button
                className="btn-styled-1"
                type="button"
                data-toggle="collapse"
                data-target={"#showItems-" + index}
                aria-expanded="false"
                aria-controls={"showItems-" + index}
                onClick={(e) => this.showItems(e, data.id)}
              >
                Shfaq Materialet
              </button>
              <button
                className="btn-styled-2 ml-0 mt-3"
                data-toggle="collapse"
                data-target={"#addItems" + index}
                aria-expanded="false"
                aria-controls={"addItems" + index}
                onClick={(e) => this.addItems(e, data.id)}
              >
                Shto materialet
              </button>
            </div>

            <div className="serie-btns">
              <div onClick={() => this.isShowPopupId(data.id)}>
                <button
                  className="btn-unstyled"
                  onClick={() => this.isShowPopup(true)}
                >
                  {TooltipComp.tooltip(
                    "edit" + data.id,
                    <img src={editIcon} alt="Ndrysho Serinë" />,
                    "Ndrysho Serinë"
                  )}
                </button>
                {this.state.showModalId === data.id && (
                  <EditSeriePopup
                    showModalPopup={this.state.showModalPopup}
                    onPopupClose={this.isShowPopup}
                    serieId={data.id}
                    pattern={data.pattern}
                    publishedDate={data.published_date}
                    cover={data.cover}
                  />
                )}
              </div>
              <button
                className="btn-unstyled ml-3"
                onClick={(e) => this.deleteSerie(e, data.id)}
              >
                {TooltipComp.tooltip(
                  "fshi" + data.id,
                  <img src={delteIcon} alt="Fshi Serinë" />,
                  "Fshi Serinë"
                )}
              </button>
            </div>
          </div>

          {this.state.addItemId === data.id && (
            <div
              className={"collapse"}
              data-parent={"#group-serie-" + index}
              id={"addItems" + index}
            >
              <EditSerieAddItem
                biblioitemID={this.state.biblioitemId}
                serieId={data.id}
              />
            </div>
          )}
          {this.state.itemId === data.id && (
            <div
              className="col-12 px-0 serie-info collapse"
              data-parent={"#group-serie-" + index}
              id={"showItems-" + index}
            >
              <div className="row m-0">
                <div className="col-11 p-0">{serieItems}</div>
                <div className="col-1 mt-3 pr-0">
                  <div
                    className="edit-button"
                    data-toggle="tooltip"
                    data-original-title="Ndrysho materialin"
                  >
                    <button
                      className="btn-unstyled"
                      type="button"
                      data-toggle="collapse"
                      data-target={"#editItems-" + index}
                      aria-expanded="false"
                      aria-controls={"editItems-" + index}
                    >
                      {TooltipComp.tooltip(
                        "ndrysho" + index,
                        <img src={editIcon} alt="Ndrysho Materialin" />,
                        "Ndrysho Materialin"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={(e) => this.editItems(e, data.id)}>
            {this.state.itemId === data.id && (
              <div
                className="collapse mt-4  edit-items edit-items-accordion col-12 px-0"
                data-parent={"#group-serie-" + index}
                id={"editItems-" + index}
              >
                <div className="mt-4 col-12 px-0">
                  <form>{editSerieItems}</form>
                </div>
                {this.state.btnLoading === true ? (
                  <button className="btn-styled-2 mt-3">
                    Duke u Ruajtur
                    <img
                      src={LoadingGif}
                      style={{
                        width: "18px",
                        margin: "-4px 0 0 7px",
                      }}
                    />
                  </button>
                ) : (
                  <button className="btn-styled-2 mt-3">Ruaj</button>
                )}
              </div>
            )}
          </form>
        </div>
      );
    });

    return <Fragment>{serieList}</Fragment>;
  }
}

export default withRouter(EditSerie);
