import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router';
import { Redirect, withRouter } from 'react-router-dom';
import Web from './Web';
import AppConfig from '../services/AppConfig';
import PageNotFoundView from '../views/PageNotFoundView';
import ScrollToTop from '../helpers/ScrollToTop';
import Request from '../api/Request';
import Url from '../api/Url';
import LoadingView from '../views/LoadingView';
import PrivateRoutes from './PrivateRoutes';
import Routes from './Routes';
import ServiceContext, { ServiceProvider } from '../helpers/Context';

class AppRouter extends Component {
  componentDidMount() {
    if (AppConfig.isLogged() === true) {
      this.profileData();
    }
  }
  constructor() {
    super();
    this.state = {
      role: '',
      firstName: '',
      lastName: '',
      services: {},
    };
  }

  profileData() {
    Request.postRequest(Url.profileData).then((response) => {
      if (response.status === 200) {
        AppConfig.setRole(response.data.role);
        this.setState({
          role: response.data.role,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
        });
      }
    });
  }

  setService = (records) => {
    this.setState({ services: records });
  };

  render() {
    const isAuth = AppConfig.isLogged();
    const role = AppConfig.getRole() ? AppConfig.getRole() : '';
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;

    const { services } = this.state;
    const { setService } = this;

    window.addEventListener('storage', (e) => {
      if (
        (e.key === 'access_token' || e.key === 'role') &&
        e.oldValue !== null &&
        e.newValue !== e.oldValue
      ) {
        Request.postRequest(Url.logout).then((response) => {
          if (response.status === 200) {
            AppConfig.deleteAccessToken();
            window.location.href = Web.homepage;
          } else {
            AppConfig.deleteAccessToken();
            window.location.href = Web.homepage;
          }
        });
      }
    });

    return (
      <ScrollToTop>
        <Switch>
          {Routes.PublicRoutes().map((route, idx) => (
            <Route
              exact
              path={route.path}
              render={(props) => <route.component roles={role} {...props} />}
              key={idx}
            />
          ))}
          {this.state.role !== '' &&
            AppConfig.isLogged() &&
            (window.location.pathname === Web.confirmZ3950 ||
            window.location.pathname === Web.z3950Service ? (
              <ServiceContext.Provider value={{ services, setService }}>
                {Routes.PrivateRoutes().map(
                  (route, idx) =>
                    route.access?.includes(role) && (
                      <PrivateRoutes.ProtectedRoute
                        exact
                        path={route.path}
                        component={route.component}
                        auth={isAuth}
                        roles={role}
                        firstName={firstName}
                        lastName={lastName}
                        key={'route' + idx}
                      />
                    )
                )}
              </ServiceContext.Provider>
            ) : (
              Routes.PrivateRoutes().map(
                (route, idx) =>
                  route.access?.includes(role) && (
                    <PrivateRoutes.ProtectedRoute
                      exact
                      path={route.path}
                      component={route.component}
                      auth={isAuth}
                      roles={role}
                      firstName={firstName}
                      lastName={lastName}
                      key={'route' + idx}
                    />
                  )
              )
            ))}

          {isAuth && this.state.role === '' && (
            <PrivateRoutes.ProtectedRoute
              exact
              component={LoadingView}
              auth={isAuth}
              roles={role}
              firstName={firstName}
              lastName={lastName}
            />
          )}

          <Route exact path="*" component={PageNotFoundView} />
        </Switch>
      </ScrollToTop>
    );
  }
}

export default withRouter(AppRouter);
