import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import dateIcon from "../../../../../assets/images/icons/date-icon.svg";
import Select from "react-select";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import "../../../../../assets/css/editmaterial.css";
import Request from "../../../../../api/Request";
import Messages from "../../../../../helpers/Messages";
import AppConfig from "../../../../../services/AppConfig";
import Url from "../../../../../api/Url";
import Web from "../../../../../routes/Web";
import right from "../../../../../assets/images/icons/arrow_right.svg";
import left from "../../../../../assets/images/icons/arrow_left.svg";
import LoadingGif from "../../../../../assets/images/icons/loading.gif";

class EditItems extends Component {
  componentDidMount() {
    const id = this.props.match.params.id;

    Request.getRequest(Url.editItems + id).then((response) => {
      if (response.status === 200) {
        this.setState({
          items: response.data.items,
          biblioitem: response.data.biblioitem,
        });
      } else if (response.status === 404) {
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  }

  constructor(state) {
    super(state);
    this.state = {
      items: [],
      biblioitem: "",
      newitems: [],
      errors: [],
      index: 0,
      deleteditems: [],
      biblio_id: "",
      btnLoading: false,
    };
  }

  checkSelectedDate = (value) => {
    if (value === "" || value === null) {
      return null;
    } else {
      return new Date(value);
    }
  };

  checkSelectValue = (value) => {
    switch (value) {
      case "salle":
        return { value: "salle", label: "Sallë" };
      case "normal":
        return { value: "normal", label: "Normal" };
      case "ipadisponueshem":
        return { value: "ipadisponueshem", label: "I Padisponueshëm" };
      default:
        return "";
    }
  };
  // items

  handleChange = (e, name, i) => {
    const inputs = this.state.items;

    var index = -2;
    inputs.map((item, key) => {
      if (item.id === i) {
        index = key;
        return key;
      }
    });

    inputs[index][name] = e.target.value;
    this.setState({
      items: inputs,
    });
  };

  handleChangeSelect = (e, name, i) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    const inputs = this.state.items;
    var index = -2;
    inputs.map((item, key) => {
      if (item.id === i) {
        index = key;
        return key;
      }
    });

    inputs[index][name] = selectValue;

    this.setState({
      items: inputs,
    });
  };

  handleChangeDate = (e, name, i) => {
    const inputs = this.state.items;

    var index = -2;
    inputs.map((item, key) => {
      if (item.id === i) {
        index = key;
        return key;
      }
    });

    var value = null;
    if (e !== null) {
      value = moment(e).format("yyyy-MM-DD");
    }
    inputs[index][name] = value;
    this.setState({
      items: inputs,
    });
  };

  deleteItem = (i) => {
    var inputs = this.state.items;
    var index = -2;
    inputs.map((item, key) => {
      if (item.id === i) {
        index = key;
        return key;
      }
    });

    var deleted = inputs.splice(index, 1);

    this.setState({
      items: inputs,
      deleteditems: [...this.state.deleteditems, i],
    });
  };

  newItem = () => {
    let newForm = {
      borrowing_type: "",
      inventory_number: "",
      place_number: "",
      damaged: 0,
      damaged_date: "",
      renewal_date: "",
      index: this.state.index,
    };
    this.setState({
      newitems: [...this.state.newitems, newForm],
      index: this.state.index + 1,
    });
  };

  handleChangeSelectNew = (e, name, i) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    const inputs = this.state.newitems;
    var index = -1;
    inputs.map((item, key) => {
      if (item.index === i) {
        index = key;
        return key;
      }
    });

    inputs[index][name] = selectValue;

    this.setState({
      newitems: inputs,
    });
  };

  handleChangeNewItems = (e, name, i) => {
    const inputs = this.state.newitems;
    var index = -1;
    inputs.map((item, key) => {
      if (item.index === i) {
        index = key;
        return key;
      }
    });

    inputs[index][name] = e.target.value;
    this.setState({
      newitems: inputs,
    });
  };

  handleChangeDateNewItems = (e, name, i) => {
    const inputs = this.state.newitems;
    var index = -1;
    inputs.map((item, key) => {
      if (item.index === i) {
        index = key;
        return key;
      }
    });

    var value = null;
    if (e !== null) {
      value = moment(e).format("yyyy-MM-DD");
    }
    inputs[index][name] = value;
    this.setState({
      newitems: inputs,
    });
  };

  removeItem = (i) => {
    var inputs = this.state.newitems;
    var index = -2;
    inputs.map((item, key) => {
      if (item.index === i) {
        index = key;
        return key;
      }
    });

    inputs.splice(index, 1);
    this.setState({
      newitems: inputs,
    });
  };

  //update

  saveItems = (e, goTo) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const id = this.props.match.params.id;

    var request = {
      items: { ...this.state.items },
      newitems: { ...this.state.newitems },
      deleteditems: { ...this.state.deleteditems },
      [goTo]: 1,
      biblioitem_id: id,
    };

    Request.patchRequest(Url.updateItems + id, request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          errors: [],
        });

        if (goTo === "forward") {
          this.props.history.push(Web.listBiblioitems);
          Messages.swalSuccess(response.data.success);
        } else {
          this.props.history.push(
            Web.editBiblioitemonly +
              response.data.biblio_id +
              "/" +
              response.data.biblioitem_id
          );
          Messages.swalSuccess(response.data.success);
        }
      } else if (response.status === 404) {
        Messages.swalError(response.data.error);
      } else if (response.status === 422) {
        this.setState({
          errors: response.data,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  render() {
    var items = this.state.items.map((item, index) => {
      return (
        <Fragment key={item.id}>
          <div className="row container-createitem items-sets m-0 mb-3">
            <div className="w-100 row m-0 mt-3 new-item-box">
              <div className="col-12 col-md-4 mb-4 pb-4">
                <label htmlFor="Lloji i huazimit">Lloji i huazimit</label>
                <Select
                  className={
                    "basic-select" +
                    (this.state.errors[`items.${index}.borrowing_type`]
                      ? " form-error"
                      : "")
                  }
                  classNamePrefix="select_input"
                  name={"items[" + item.id + "][borrowing_type]"}
                  placeholder="Përzgjidh"
                  defaultValue={
                    item.borrowing_type
                      ? this.checkSelectValue(item.borrowing_type)
                      : null
                  }
                  isSearchable={false}
                  isClearable={true}
                  options={[
                    { value: "salle", label: "Salle" },
                    { value: "normal", label: "Normal" },
                    { value: "ipadisponueshem", label: "I Padisponueshem" },
                  ]}
                  onChange={(e) =>
                    this.handleChangeSelect(e, `borrowing_type`, item.id)
                  }
                />
                {this.state.errors[`items.${index}.borrowing_type`] && (
                  <span className="error-message left">
                    {this.state.errors[`items.${index}.borrowing_type`]}
                  </span>
                )}
              </div>

              <div className="col-12 col-md-4 mb-4 pb-4">
                <label htmlFor="Kodifikimi">Kodifikimi</label>
                <input
                  placeholder="Kodifikimi"
                  className={
                    "input-form-input" +
                    (this.state.errors[`items.${index}.inventory_number`]
                      ? " form-error"
                      : "")
                  }
                  name={"items[" + item.id + "][inventory_number]"}
                  onChange={(e) =>
                    this.handleChange(e, `inventory_number`, item.id)
                  }
                  type="text"
                  defaultValue={
                    item.inventory_number ? item.inventory_number : ""
                  }
                />
                {this.state.errors[`items.${index}.inventory_number`] && (
                  <span className="error-message left">
                    {this.state.errors[`items.${index}.inventory_number`]}
                  </span>
                )}
              </div>
              <div className="col-12 col-md-4 mb-4 pb-4">
                <label htmlFor="Numri i vendit">Nr. i vendit</label>
                <input
                  placeholder="Numri i vendit"
                  className={
                    "input-form-input" +
                    (this.state.errors[`items.${index}.place_number`]
                      ? " form-error"
                      : "")
                  }
                  name={"items[" + item.id + "][place_number]"}
                  onChange={(e) =>
                    this.handleChange(e, `place_number`, item.id)
                  }
                  type="text"
                  defaultValue={item.place_number ? item.place_number : ""}
                />
                {this.state.errors[`items.${index}.place_number`] && (
                  <span className="error-message left">
                    {this.state.errors[`items.${index}.place_number`]}
                  </span>
                )}
              </div>
              <div className="row m-0 w-100">
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Numri i inventarit">Numri i inventarit</label>
                  <input
                    placeholder="Numri i inventarit"
                    className="input-form-input"
                    type="text"
                    defaultValue={item.id}
                    disabled
                  />
                </div>
              </div>
              {AppConfig.getRole() === "Administrator" && (
                <div className="row m-0 w-100">
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Demtuar">Dëmtuar</label>
                    <div className="radio-buttons d-flex">
                      <div className="radio-button mr-4">
                        <label htmlFor={`damaged_${index}`}>Po</label>
                        <input
                          className="form-check-input createitem-radiobuttons"
                          id={`damaged_${index}`}
                          name={"items[" + item.id + "][damaged]"}
                          onChange={(e) =>
                            this.handleChange(e, `damaged`, item.id)
                          }
                          type="radio"
                          value="1"
                          defaultChecked={item.damaged === 1}
                        />
                      </div>

                      <div className="radio-button mr-4">
                        <label htmlFor={`notdamaged_${index}`}>Jo</label>
                        <input
                          className="form-check-input"
                          id={`notdamaged_${index}`}
                          name={"items[" + item.id + "][damaged]"}
                          onChange={(e) =>
                            this.handleChange(e, `damaged`, item.id)
                          }
                          type="radio"
                          value="0"
                          defaultChecked={item.damaged === 0}
                        />
                      </div>
                    </div>

                    {this.state.errors[`items.${index}.damaged`] && (
                      <span className="error-message left">
                        {this.state.errors[`items.${index}.damaged`]}
                      </span>
                    )}
                  </div>

                  {(item.damaged === 1 || item.damaged === "1") && (
                    <Fragment>
                      <div className="col-12 col-md-4 mb-4 pb-4 removable-field">
                        <label htmlFor="Data e dëmtimit">Data e dëmtimit</label>
                        <DatePicker
                          placeholderText="Zgjidh datën"
                          name={"items[" + item.id + "][damaged_date]"}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          locale={sq}
                          dateFormat="dd-MM-yyyy"
                          selected={this.checkSelectedDate(item.damaged_date)}
                          onChange={(e) =>
                            this.handleChangeDate(e, `damaged_date`, item.id)
                          }
                          autoComplete="off"
                          className={
                            "date_input" +
                            (this.state.errors[`items.${index}.damaged_date`]
                              ? " form-error"
                              : "")
                          }
                        />
                        {this.state.errors[`items.${index}.damaged_date`] && (
                          <span className="error-message left">
                            {this.state.errors[`items.${index}.damaged_date`]}
                          </span>
                        )}
                      </div>
                      <div className="col-12 col-md-4 mb-4 pb-4 removable-field">
                        <label htmlFor="Data e rinovimit">
                          Data e rinovimit
                        </label>
                        <DatePicker
                          placeholderText="Zgjidh datën"
                          name={"items[" + item.id + "][renewal_date]"}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          locale={sq}
                          dateFormat="dd-MM-yyyy"
                          selected={this.checkSelectedDate(item.renewal_date)}
                          onChange={(e) =>
                            this.handleChangeDate(e, `renewal_date`, item.id)
                          }
                          autoComplete="off"
                          className={
                            "date_input" +
                            (this.state.errors[`items.${index}.renewal_date`]
                              ? " form-error"
                              : "")
                          }
                        />
                        {this.state.errors[`items.${index}.renewal_date`] && (
                          <span className="error-message left">
                            {this.state.errors[`items.${index}.renewal_date`]}
                          </span>
                        )}
                      </div>
                    </Fragment>
                  )}
                </div>
              )}
            </div>

            <div className="add-remove-buttons">
              <div>
                <button
                  type="button"
                  className="btn-styled-2 removeFields"
                  onClick={() => this.deleteItem(item.id)}
                >
                  -
                </button>
              </div>
              {index + 1 === this.state.items.length &&
                this.state.newitems.length === 0 && (
                  <Fragment>
                    <div>
                      <button
                        type="button"
                        className="btn-styled-2 mr-3 addFields"
                        onClick={this.newItem}
                      >
                        +
                      </button>
                    </div>
                  </Fragment>
                )}
            </div>
          </div>
        </Fragment>
      );
    });

    var newitems = "";
    if (this.state.newitems) {
      var newitems = this.state.newitems.map((item, index_new) => {
        return (
          <Fragment key={item.index}>
            <div className="row container-createitem border-items">
              <div className="w-100 row m-0 mt-3 new-item-box">
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Lloji i huazimit">Lloji i huazimit</label>
                  <Select
                    className={
                      "basic-select" +
                      (this.state.errors[`newitems.${index_new}.borrowing_type`]
                        ? " form-error"
                        : "")
                    }
                    classNamePrefix="select_input"
                    name={"newitems[" + item.index + "][borrowing_type]"}
                    placeholder="Përzgjidh"
                    isClearable={true}
                    menuPlacement="auto"
                    isSearchable={false}
                    options={[
                      { value: "salle", label: "Salle" },
                      { value: "normal", label: "Normal" },
                      { value: "ipadisponueshem", label: "I Padisponueshem" },
                    ]}
                    onChange={(e) =>
                      this.handleChangeSelectNew(
                        e,
                        `borrowing_type`,
                        item.index
                      )
                    }
                  />
                  {this.state.errors[
                    `newitems.${index_new}.borrowing_type`
                  ] && (
                    <span className="error-message left">
                      {
                        this.state.errors[
                          `newitems.${index_new}.borrowing_type`
                        ]
                      }
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Kodifikimi">Kodifikimi</label>
                  <input
                    placeholder="Kodifikimi"
                    className={
                      "input-form-input" +
                      (this.state.errors[
                        `newitems.${index_new}.inventory_number`
                      ]
                        ? " form-error"
                        : "")
                    }
                    name={"newitems[" + item.index + "][inventory_number]"}
                    onChange={(e) =>
                      this.handleChangeNewItems(
                        e,
                        `inventory_number`,
                        item.index
                      )
                    }
                    defaultValue={
                      item.inventory_number ? item.inventory_number : ""
                    }
                    type="text"
                  />

                  {this.state.errors[
                    `newitems.${index_new}.inventory_number`
                  ] && (
                    <span className="error-message left">
                      {
                        this.state.errors[
                          `newitems.${index_new}.inventory_number`
                        ]
                      }
                    </span>
                  )}
                </div>

                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Numri i vendit">Nr. i vendit</label>
                  <input
                    placeholder="Numri i vendit"
                    className={
                      "input-form-input" +
                      (this.state.errors[`newitems.${index_new}.place_number`]
                        ? " form-error"
                        : "")
                    }
                    name={"newitems[" + item.index + "][place_number]"}
                    onChange={(e) =>
                      this.handleChangeNewItems(e, `place_number`, item.index)
                    }
                    type="text"
                    defaultValue={item.place_number ? item.place_number : ""}
                  />
                  {this.state.errors[`newitems.${index_new}.place_number`] && (
                    <span className="error-message left">
                      {this.state.errors[`newitems.${index_new}.place_number`]}
                    </span>
                  )}
                </div>
                {AppConfig.getRole() === "Administrator" && (
                  <div className="row m-0 w-100">
                    <div className="col-12 col-md-4 mb-4 pb-4">
                      <label htmlFor="Demtuar">Dëmtuar</label>
                      <div className="radio-buttons d-flex">
                        <div className="radio-button mr-4">
                          <label htmlFor={`damaged-${index_new}`}>Po</label>
                          <input
                            className="form-check-input"
                            data-target="removable-field"
                            id={`damaged-${index_new}`}
                            name={"newitems[" + item.index + "][damaged]"}
                            onChange={(e) =>
                              this.handleChangeNewItems(
                                e,
                                `damaged`,
                                item.index
                              )
                            }
                            type="radio"
                            value="1"
                            defaultChecked={item.damaged === 1}
                          />
                        </div>
                        <div className="radio-button ml-4">
                          <label htmlFor={`notdamaged-${index_new}`}>Jo</label>
                          <input
                            className="form-check-input"
                            data-target="removable-field"
                            id={`notdamaged-${index_new}`}
                            name={"newitems[" + item.index + "][damaged]"}
                            onChange={(e) =>
                              this.handleChangeNewItems(
                                e,
                                `damaged`,
                                item.index
                              )
                            }
                            type="radio"
                            value="0"
                            defaultChecked={item.damaged === 0}
                          />
                        </div>
                      </div>

                      {this.state.errors[`newitems.${index_new}.damaged`] && (
                        <span className="error-message left">
                          {this.state.errors[`newitems.${index_new}.damaged`]}
                        </span>
                      )}
                    </div>
                    {item.damaged === 1 ||
                      (item.damaged === "1" && (
                        <Fragment>
                          <div className="col-12 col-md-4 mb-4 pb-4 removable-field">
                            <label htmlFor="Data e dëmtimit">
                              Data e dëmtimit
                            </label>
                            <div className="calendar-block">
                              <DatePicker
                                placeholderText="Zgjidh datën"
                                name={
                                  "newitems[" + item.index + "][damaged_date]"
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                locale={sq}
                                dateFormat="dd-MM-yyyy"
                                selected={this.checkSelectedDate(
                                  item.damaged_date
                                )}
                                onChange={(e) =>
                                  this.handleChangeDateNewItems(
                                    e,
                                    `damaged_date`,
                                    item.index
                                  )
                                }
                                autoComplete="off"
                                className={
                                  "date_input" +
                                  (this.state.errors[
                                    `newitems.${index_new}.damaged_date`
                                  ]
                                    ? " form-error"
                                    : "")
                                }
                              />
                              {this.state.errors[
                                `newitems.${index_new}.damaged_date`
                              ] && (
                                <span className="error-message left">
                                  {
                                    this.state.errors[
                                      `newitems.${index_new}.damaged_date`
                                    ]
                                  }
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 mb-4 pb-4 removable-field">
                            <label htmlFor="Data e rinovimit">
                              Data e rinovimit
                            </label>
                            <div className="calendar-block">
                              <DatePicker
                                placeholderText="Zgjidh datën"
                                name={
                                  "newitems[" + item.index + "][renewal_date]"
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                locale={sq}
                                dateFormat="dd-MM-yyyy"
                                selected={this.checkSelectedDate(
                                  item.renewal_date
                                )}
                                onChange={(e) =>
                                  this.handleChangeDateNewItems(
                                    e,
                                    `renewal_date`,
                                    item.index
                                  )
                                }
                                autoComplete="off"
                                className={
                                  "date_input" +
                                  (this.state.errors[
                                    `newitems.${index_new}.renewal_date`
                                  ]
                                    ? " form-error"
                                    : "")
                                }
                              />
                              {this.state.errors[
                                `newitems.${index_new}.renewal_date`
                              ] && (
                                <span className="error-message left">
                                  {
                                    this.state.errors[
                                      `newitems.${index_new}.renewal_date`
                                    ]
                                  }
                                </span>
                              )}
                            </div>
                          </div>
                        </Fragment>
                      ))}
                  </div>
                )}
              </div>
              <div className="new-item-buttons">
                <button
                  type="button"
                  className="btn-styled-2 mt-0 ml-3 removeFields"
                  name="add-fields[]"
                  onClick={() => this.removeItem(item.index)}
                >
                  -
                </button>
                {index_new + 1 === this.state.newitems.length && (
                  <Fragment>
                    <div>
                      <button
                        type="button"
                        className="btn-styled-2 mt-0 ml-3 addFields"
                        onClick={this.newItem}
                      >
                        +
                      </button>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </Fragment>
        );
      });
    }

    return (
      <Fragment>
        <div id="create-biblio" className="small-offset-left">
          <div className="row m-0 biblio-shto">
            <div className="col-7 col-sm-9 p-0 partial-title mb-5">
              Ndrysho Bibliografi
            </div>
            <div className="col-5 col-sm-3 p-0 d-flex">
              <div className="navigation">
                <button
                  className="btn-unstyled"
                  name="back"
                  onClick={(e) => this.saveItems(e, "back")}
                >
                  <img src={left} alt="back" />
                </button>
                <span>3 / 3</span>
                <button
                  className="btn-unstyled"
                  name="forward"
                  onClick={(e) => this.saveItems(e, "forward")}
                >
                  <img src={right} alt="forward" />
                </button>
              </div>
            </div>
          </div>

          <div className="bibliography_items mb-5">
            <div className="col-12 col-md-9 p-0 series-items-block mb-0">
              {items}
              {newitems}
              <div className="new-item-buttons">
                {this.state.newitems.length === 0 &&
                  this.state.items.length === 0 && (
                    <Fragment>
                      <div>
                        <button
                          type="button"
                          className="btn-styled-2 addFields float-left"
                          onClick={this.newItem}
                        >
                          +
                        </button>
                      </div>
                    </Fragment>
                  )}
              </div>
            </div>
          </div>

          <div className="row m-0">
            {this.state.btnLoading === true ? (
              <button className="btn-styled-2 ml-auto px-5">
                Duke u Ruajtur
                <img
                  src={LoadingGif}
                  style={{
                    width: "18px",
                    margin: "-4px 0 0 7px",
                  }}
                />
              </button>
            ) : (
              <button
                className="btn-styled-2 ml-auto px-5"
                onClick={(e) => this.saveItems(e, "forward")}
              >
                Ruaj
              </button>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(EditItems);
