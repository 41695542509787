import React, { Component, Fragment } from "react";
import Pagination from "react-js-pagination";
import Request from "../../../../api/Request";
import Url from "../../../../api/Url";
import Messages from "../../../../helpers/Messages";
import Paginate from "../../../../helpers/Paginate";
import SearchFilter from "../../../../helpers/SearchFilter";
import AppConfig from "../../../../services/AppConfig";
import editIcon from "../../../../assets/images/icons/edit_icon.svg";
import deleteIcon from "../../../../assets/images/icons/delete_icon.svg";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import CreateFrequence from "./CreateFrequence";
import EditFrequence from "./FrequenceEdit";

class Frequence extends Component {
  constructor() {
    super();
    this.state = {
      listFrequences: {
        frequences: [],
        loading: true,
      },
      editFrequence: {
        popupId: "",
        description: "",
        unit: "",
        issues_per_unit: "",
        units_per_issue: "",
      },
      pagination: Paginate.setPaginate(),
      page: null,
      createFrequencePopup: false,
      editFrequencePopup: false,
    };
  }

  componentDidMount() {
    this.listFrequences();
  }

  listFrequences = () => {
    let request = Url.listFrequences;

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          listFrequences: {
            frequences: response.data.frequences.data,
            loading: false,
          },
          pagination: Paginate.setPaginateData(response.data.frequences),
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  createFrequencePopup = () => {
    this.setState({
      createFrequencePopup: true,
    });
  };

  closeCreatePopup = (status) => {
    this.setState({ createFrequencePopup: status });
    this.listFrequences();
  };

  editFrequencePopup = (
    e,
    id,
    description,
    units,
    units_per_issue,
    issues_per_unit
  ) => {
    this.setState({
      editFrequencePopup: true,
      editFrequence: {
        popupId: id,
        description: description,
        unit: units,
        issues_per_unit: units_per_issue,
        units_per_issue: issues_per_unit,
      },
    });
  };

  closeEditPopup = (status) => {
    this.setState({ editFrequencePopup: status, popupId: "" });
    this.listFrequences();
  };

  deleteFrequence = (e, id, name) => {
    Messages.swalConfirmDelete(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(Url.deleteFrequence + id).then((response) => {
          if (response.status === 200) {
            this.listFrequences();
            Messages.swalSuccess(response.data.success);
          } else if (response.status === 405) {
            Messages.swalError(response.data.error);
          } else if (response.status === 404) {
            Messages.swalError(response.data.error);
          }
        });
      }
    });
  };

  handlePageChange = (pageNumber) => {
    let request = Url.listFrequences;
    var searchParams = {
      page: pageNumber,
    };
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listFrequences: {
          frequences: response.data.frequences.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.frequences),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    var frequencesList = "";
    if (this.state.listFrequences.loading) {
      frequencesList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      frequencesList = this.state.listFrequences.frequences.map((frequence) => {
        return (
          <tr key={frequence.id}>
            <td role="cell" data-label="Përshkrimi">
              {frequence.description}
            </td>
            <td role="cell" data-label="Njësia" className="text-center">
              {frequence.unit}
            </td>
            <td role="cell" data-label="Seri për njësi" className="text-center">
              {frequence.issues_per_unit}
            </td>
            <td role="cell" data-label="Njësi për seri" className="text-center">
              {frequence.units_per_issue}
            </td>
            <td role="cell" data-label="Veprime" className="text-center">
              <div className="row justify-content-around mr-auto ml-auto action-icons">
                <button
                  type="button"
                  onClick={(e) =>
                    this.editFrequencePopup(
                      e,
                      frequence.id,
                      frequence.description,
                      frequence.unit,
                      frequence.units_per_issue,
                      frequence.issues_per_unit
                    )
                  }
                  className="btn-unstyled"
                >
                  <img src={editIcon} alt="Delete Icon" />
                </button>
                {this.state.editFrequence.popupId === frequence.id && (
                  <EditFrequence
                    showEditFrequence={this.state.editFrequencePopup}
                    popupId={this.state.editFrequence.popupId}
                    description={this.state.editFrequence.description}
                    unit={this.state.editFrequence.unit}
                    issues_per_unit={this.state.editFrequence.units_per_issue}
                    units_per_issue={this.state.editFrequence.issues_per_unit}
                    onPopupClose={this.closeEditPopup}
                  />
                )}
                <button
                  type="button"
                  onClick={(e) =>
                    this.deleteFrequence(e, frequence.id, frequence.description)
                  }
                  className="btn-unstyled"
                >
                  <img src={deleteIcon} alt="Delete Icon" />
                </button>
              </div>
            </td>
          </tr>
        );
      });
    }
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="frequence">
            <p className="partial-title">Frekuenca</p>
            <div>
              <button
                onClick={this.createFrequencePopup}
                className="btn-styled-2 px-5"
              >
                Krijo Frekuencë
              </button>
              <CreateFrequence
                showFrequencePopup={this.state.createFrequencePopup}
                onPopupClose={this.closeCreatePopup}
              />
            </div>
            <div className="table-list mt-5 mb-5">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th role="columnheader" width="20%" className="text-left">
                      Përshkrimi
                    </th>
                    <th role="columnheader" width="20%" className="text-center">
                      Njësia
                    </th>
                    <th role="columnheader" width="20%" className="text-center">
                      Seri për njësi
                    </th>
                    <th role="columnheader" width="20%" className="text-center">
                      Njësi për seri
                    </th>
                    <th role="columnheader" width="20%" className="text-center">
                      Veprime
                    </th>
                  </tr>
                </thead>
                <tbody>{frequencesList}</tbody>
              </table>
              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listFrequences.loading === false && (
                  <p className="nothing--to--show">Asgjë për të shfaqur</p>
                )}
            </div>
          </div>
          {this.state.pagination.totalPages > 1 && (
            <div className="d-flex justify-content-center flex-wrap pagination-block mb-5">
              <div className="select-page">
                <Pagination
                  activePage={this.state.pagination.activePage}
                  itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                  totalItemsCount={this.state.pagination.totalItemsCount}
                  pageRangeDisplayed={this.state.pagination.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="pagination-results">
                Faqja:
                {this.state.pagination.activePage} -{" "}
                {this.state.pagination.totalPages}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default Frequence;
