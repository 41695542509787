import React, { Component, Fragment } from 'react';
import Url from '../../../api/Url';
import Request from '../../../api/Request';
import Web from '../../../routes/Web';
import Messages from '../../../helpers/Messages';
import { Link, withRouter } from 'react-router-dom';
import AppConfig from '../../../services/AppConfig';
import Borrowings from '../../../components/administrator/members/Borrowings';
import Reservations from '../../../components/administrator/members/Reservations';
// import Fines from '../../../components/administrator/members/Fines';
import TooltipComp from '../../../helpers/Tooltip';
import moment from 'moment';

class ShowMember extends Component {
  constructor() {
    super();
    this.state = {
      memberDetails: {
        details: '',
        role: '',
        loading: true,
      },
      tab: {
        selected: 'borrowings',
      },
    };
  }

  componentDidMount() {
    this.memberDetails();
  }

  memberDetails = () => {
    const memberID = this.props.match.params.id;

    let request = Url.showMember + memberID;

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          memberDetails: {
            details: response.data.member,
            role: response.data.member.role,
            loading: false,
          },
        });
      } else if (response.status === 404) {
        this.props.history.push(Web.listMembers);
        Messages.swalSuccess(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  borrowingsTab = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        selected: 'borrowings',
      },
    });
  };

  reservationsTab = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        selected: 'reservations',
      },
    });
  };

  // finesTab = (e) => {
  //   e.preventDefault();
  //   this.setState({
  //     tab: {
  //       selected: 'fines',
  //     },
  //   });
  // };

  isActive = (param) => {
    if (param === null) {
      return true;
    } else {
      return false;
    }
  };

  userGender = (param) => {
    switch (param) {
      case 'M':
        return 'Mashkull';
      case 'F':
        return 'Femër';
      default:
        return '';
    }
  };

  /**
   * If data are loading , this function return "..." , else return the data
   * @param param
   * @returns {string|*}
   */
  isLoading = (param) => {
    return this.state.memberDetails.loading ? '  ... ' : param;
  };

  /**
   * @param param
   * @returns {string|*}
   */
  isNull = (param) => {
    if (param === null) {
      return ' ';
    } else {
      return param;
    }
  };

  render() {
    const memberDetail = this.state.memberDetails.details;
    const memberRole = this.state.memberDetails.role.name;

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="show" className="table-detail-tabs">
            <p className="partial-title mb-5">Detaje Anëtari</p>
          </div>
          <div className="member-data row mb-5">
            <div className="list-data col-12 col-md-8 d-flex">
              <ul>
                <li className="member-detail">
                  Emër Mbiemër / Roli :{' '}
                  {this.isLoading(
                    memberDetail.first_name + ' ' + memberDetail.last_name
                  ) +
                    ' / ' +
                    this.isLoading(memberRole)}
                </li>
                <li>Email : {this.isLoading(memberDetail.email)}</li>
                <li>Cel : {this.isLoading(memberDetail.phone)}</li>
                <li>
                  Ditëlindje :{' '}
                  {this.isLoading(
                    memberDetail.dob &&
                      moment(memberDetail.dob).format('DD-MM-yyy')
                  )}
                </li>
                <li>
                  Gjinia :{' '}
                  {this.isLoading(this.userGender(memberDetail.gender))}
                </li>
                <li>
                  Numri i Identifikimit :{' '}
                  {memberDetail.nid ? memberDetail.nid : '-'}
                </li>
                <li>
                  Shteti / Qyteti :{' '}
                  {this.isLoading(this.isNull(memberDetail.state)) +
                    ' / ' +
                    this.isLoading(this.isNull(memberDetail.city))}
                </li>
                <li>
                  Adresa / Kodi postar :{' '}
                  {this.isLoading(this.isNull(memberDetail.address)) +
                    ' / ' +
                    this.isLoading(this.isNull(memberDetail.postal_code))}
                </li>
                {memberDetail.role_id === 4 && (
                  <Fragment>
                    <li>
                      Fakulteti :{' '}
                      {memberDetail.faculty ? memberDetail.faculty : '-'}
                    </li>
                    <li>
                      Departamenti :{' '}
                      {memberDetail.department ? memberDetail.department : '-'}
                    </li>
                    <li>
                      Programi :{' '}
                      {memberDetail.program ? memberDetail.program : '-'}
                    </li>
                    <li>
                      Niveli i edukimit :{' '}
                      {memberDetail.edulevel ? memberDetail.edulevel : '-'}
                    </li>
                    <li>
                      Koha e studimit :{' '}
                      {memberDetail.fullorparttime
                        ? memberDetail.fullorparttime === 'fulltime'
                          ? 'Me kohë të plotë'
                          : 'Me kohë të pjesshme'
                        : '-'}
                    </li>
                    <li>
                      Viti : {memberDetail.year ? memberDetail.year : '-'}
                    </li>
                  </Fragment>
                )}
                <li></li>
              </ul>
            </div>

            {this.state.memberDetails.loading === false && (
              <Fragment>
                <div className="data-btn col-12 col-md-4">
                  {this.isActive(memberDetail.token) === true ? (
                    <Link
                      to={Web.searchMaterial + memberDetail.id + '/item'}
                      className="btn-styled-2"
                    >
                      Huazo një material të ri
                    </Link>
                  ) : (
                    <Fragment>
                      {TooltipComp.disabledTooltipBtn(
                        'borrow',
                        'Huazo një material të ri',
                        'Ju nuk mund të huazoni. Ky përdorues nuk ka aktivizuar llogarinë.'
                      )}
                    </Fragment>
                  )}
                </div>
              </Fragment>
            )}
          </div>

          <div className="data-tabs tab-style1 mb-5">
            <ul className="nav nav-tabs mb-0 mb-md-5" id="myTab" role="tablist">
              <li
                id="borrowing_tab_b"
                className="nav-item col-12 col-md-6 p-0"
                role="huazime"
              >
                <a
                  className="nav-link active"
                  id="huazime-tab"
                  data-toggle="tab"
                  href="#huazime"
                  role="tab"
                  aria-controls="huazime"
                  onClick={(e) => this.borrowingsTab(e)}
                  aria-selected="true"
                >
                  Huazime
                </a>
              </li>
              <li
                id="reservation_tab_b"
                className="nav-item col-12 col-md-6 p-0"
                role="rezervime"
              >
                <a
                  className="nav-link middle-tab"
                  id="rezervime-tab"
                  data-toggle="tab"
                  href="#rezervime"
                  role="tab"
                  aria-controls="rezervime"
                  onClick={(e) => this.reservationsTab(e)}
                  aria-selected="false"
                >
                  Rezervime
                </a>
              </li>
              {/* <li
                id="fine_tab_b"
                className="nav-item col-12 col-md-4 p-0"
                role="gjoba-shenime"
              >
                <a
                  className="nav-link"
                  id="gjoba-shenime-tab"
                  data-toggle="tab"
                  href="#gjoba-shenime"
                  role="tab"
                  aria-controls="gjoba-shenime"
                  onClick={(e) => this.finesTab(e)}
                  aria-selected="false"
                >
                  Gjoba / shënime
                </a>
              </li> */}
            </ul>

            <div className="tab-content mt-5" id="myTabContent">
              {this.state.memberDetails.loading === false && (
                <Fragment>
                  {this.state.tab.selected === 'borrowings' && (
                    <Borrowings
                      memberID={this.props.match.params.id}
                    ></Borrowings>
                  )}
                  {this.state.tab.selected === 'reservations' && (
                    <Reservations
                      memberID={this.props.match.params.id}
                    ></Reservations>
                  )}
                  {/* {this.state.tab.selected === 'fines' && (
                    <Fines memberID={this.props.match.params.id}></Fines>
                  )} */}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(ShowMember);
