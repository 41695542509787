import React, { Component, Fragment } from "react";
import Select from "react-select";
import { findByRole } from "@testing-library/react";
import right from "../../../../../assets/images/icons/arrow_right.svg";
import Web from "../../../../../routes/Web";
import Downshift from "downshift";
import { Link, withRouter } from "react-router-dom";
import Url from "../../../../../api/Url";
import Messages from "../../../../../helpers/Messages";
import AppConfig from "../../../../../services/AppConfig";
import Request from "../../../../../api/Request";
import AuthorModal from "../AuthorModal";
import KeywordModal from "../KeywordModal";
import GeographicTermsModal from "../GeographicTermsModal";
import LoadingGif from "../../../../../assets/images/icons/loading.gif";

class CreateBiblio extends Component {
  constructor(props) {
    super(props);
    var title = "";
    this.props.location.title === undefined
      ? (title = "")
      : (title = this.props.location.title);
    this.state = {
      showBiblios: {
        biblios: [],
        loading: true,
        media_types: [],
        media_types_id: "",
        media_types_name: "",
        authors: [],
        key_words: [],
        geographical_terms: [],
        material_types: [],
        languages: [],
      },
      completeBiblio: {
        biblio: [],
      },
      sentBiblio: {
        title: title,
        medium: "",
        subtitle: "",
        language_id: "",
        materialtype_id: "",
        materialtype: [],
        mediatype_id: "",
        part_number: "",
        part_name: "",
        keywords: [],
        geographical_terms: [],
        authors: [],
        notes: "",
        abstract: "",
        agerestriction: "",
        year_original_publication: "",
        biblio_id: "",
      },
      materialType: {
        value: "",
        label: "",
      },
      language: {
        value: "",
        label: "",
      },
      mediaType: {
        value: "",
        label: "",
      },
      errorList: [],
      addAuthorPopup: false,
      addKeywordPopup: false,
      addGeoPopup: false,
      selectedAuthors: [],
      authorsId: [],
      selectedKeywords: [],
      keywordsId: [],
      selectedGeoTerms: [],
      GeoTermsId: [],
      author_types: [
        { value: "paresor", label: "Parësor" },
        { value: "dytesor", label: "Dytësor" },
        { value: "tretesor", label: "Tretësor" },
        { value: "autor", label: "Autor" },
      ],
      error_list: [],
      isOpenDownshift: false,
      btnLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.fetchBiblios = this.fetchBiblios.bind(this);
  }

  /*-----------------Data Fetching----------------*/

  componentDidMount() {
    this.fetchBiblios();
    this.replaceChar("Asgje te re nga fronti i perendimitc eshte");
  }

  fetchBiblios = () => {
    let request = Url.createBiblio;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          showBiblios: {
            biblios: response.data.biblios,
            loading: false,
            media_types: response.data.media_types,
            languages: response.data.languages,
            key_words: response.data.key_words,
            authors: response.data.authors,
            geographical_terms: response.data.geographical_terms,
            material_types: response.data.material_types,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  replaceChar = (string_title) => {
    if (string_title !== undefined) {
      var r = string_title.toLowerCase();

      // var non_asciis = {
      // 	'a': '[àáâãäå]',
      // 	'ae': 'æ',
      // 	'c': 'ç',
      // 	'e': '[èéêë]',
      // 	'i': '[ìíîï]',
      // 	'n': 'ñ',
      // 	'o': '[òóôõö]',
      // 	'oe': 'œ',
      // 	'u': '[ùúûűü]',
      // 	'y': '[ýÿ]'
      // };

      var non_asciis = {
        ç: "c",
        ë: "e",
      };

      for (let i in non_asciis) {
        r = r.replace(new RegExp(non_asciis[i], "g"), i);
      }
    }
  };
  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.sentBiblio,
      [key]: e.target.value,
    };
    this.setState({
      sentBiblio: newForm,
    });
  };

  handleChangeSelect = (key, state) => (e) => {
    var selectValue;
    var selectLabel;
    if (e !== null) {
      selectValue = e.value;
      selectLabel = e.label;
    } else {
      selectValue = "";
      selectLabel = "";
    }
    let newForm = {
      ...this.state.sentBiblio,
      [key]: selectValue,
    };
    let newForm1 = {
      ...this.state[state],
      value: selectValue,
      label: selectLabel,
    };
    this.setState({
      sentBiblio: newForm,
      [state]: newForm1,
    });
  };

  emptyState = () => {
    this.setState({
      sentBiblio: {
        title: "",
        medium: "",
        subtitle: "",
        language_id: "",
        materialtype_id: "",
        mediatype_id: "",
        part_number: "",
        part_name: "",
        notes: "",
        keywords: "",
        authors: "",
        geographical_terms: "",
        abstract: "",
        agerestriction: "",
        year_original_publication: "",
        biblio_id: "",
      },
      completeBiblio: {
        biblio: "",
      },
      materialType: {
        value: "",
        label: "",
      },
      language: {
        value: "",
        label: "",
      },
      mediaType: {
        value: "",
        label: "",
      },
      selectedAuthors: [],
      selectedKeywords: [],
      selectedGeoTerms: [],
    });
  };

  createBiblio = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    var keywords = [],
      geoterm = [];

    this.state.selectedKeywords.map((keyword, index) => {
      keywords[index] = keyword.id;
    });
    this.state.selectedGeoTerms.map((geot, index) => {
      geoterm[index] = geot.id;
    });

    var request = {
      biblio_id: this.state.sentBiblio.biblio_id,
      materialtype_id: this.state.sentBiblio.materialtype_id,
      mediatype_id: this.state.sentBiblio.mediatype_id,
      language_id: this.state.sentBiblio.language_id,
      biblio_language_id: this.state.sentBiblio.language_id,
      title: this.state.sentBiblio.title,
      medium: this.state.sentBiblio.medium,
      subtitle: this.state.sentBiblio.subtitle,
      part_number: this.state.sentBiblio.part_number,
      part_name: this.state.sentBiblio.part_name,
      notes: this.state.sentBiblio.notes,
      abstract: this.state.sentBiblio.abstract,
      agerestriction: this.state.sentBiblio.agerestriction,
      year_original_publication:
        this.state.sentBiblio.year_original_publication,

      key_words: keywords,
      geographical_terms: geoterm,
      authors: { ...this.state.selectedAuthors },
    };

    Request.postRequest(Url.storeBiblio, request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 201) {
        this.setState({
          error_list: [],
        });
        this.props.history.push(Web.createBiblioItem + response.data.biblio_id);
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 422) {
        this.setState({
          error_list: response.data,
        });
        if (response.data.error) {
          Messages.swalError(response.data.error);
        }
      }
    });
  };

  downshiftOnChange = (selectedBiblio) => {
    let request = Url.selectedBiblio + selectedBiblio.id;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          sentBiblio: {
            title: response.data.biblio.title,
            medium: response.data.biblio.medium,
            subtitle: response.data.biblio.subtitle,
            language_id: response.data.biblio.language_id,
            materialtype_id: response.data.biblio.materialtype_id,
            materialtype: response.data.biblio.materialtype,
            mediatype_id: response.data.biblio.mediatype_id,
            part_number: response.data.biblio.part_number,
            part_name: response.data.biblio.part_name,
            keywords: response.data.biblio.keywords,
            geographical_terms: response.data.biblio.geographicalterms,
            authors: response.data.biblio.authors,
            notes: response.data.biblio.notes,
            abstract: response.data.biblio.abstract,
            agerestriction: response.data.biblio.agerestriction,
            year_original_publication:
              response.data.biblio.year_original_publication,
            biblio_id: response.data.biblio.id,
          },
        });

        if (
          response.data.biblio.materialtype !== null ||
          response.data.biblio.materialtype
        ) {
          this.setState({
            materialType: {
              value: response.data.biblio.materialtype.id,
              label: response.data.biblio.materialtype.name,
            },
          });
        }

        if (
          response.data.biblio.language ||
          response.data.biblio.language !== null
        ) {
          this.setState({
            language: {
              value: response.data.biblio.language.id,
              label: response.data.biblio.language.name,
            },
          });
        }
        if (
          response.data.biblio.mediatype ||
          response.data.biblio.mediatype !== null
        ) {
          this.setState({
            mediaType: {
              value: response.data.biblio.mediatype.id,
              label: response.data.biblio.mediatype.name,
            },
          });
        }
      }
      if (response.status === 200) {
        this.changeBiblio(response.data.biblio);
      }
    });
  };

  /*---------------Modals----------------*/

  addAuthorPopup = () => {
    this.setState({
      addAuthorPopup: true,
    });
  };

  closeAddAuthorPopup = (status) => {
    this.setState({ addAuthorPopup: status });
  };

  addKeywordPopup = () => {
    this.setState({
      addKeywordPopup: true,
    });
  };

  closeAddKeywordPopup = (status) => {
    this.setState({ addKeywordPopup: status });
  };

  addGeoPopup = () => {
    this.setState({
      addGeoPopup: true,
    });
  };

  closeAddGeoPopup = (status) => {
    this.setState({ addGeoPopup: status });
  };

  /*------------Props From Modals(Childs)----------*/

  handleCallbackAuthor = (dataAuthor, typeAuthor, control) => {
    var author = { id: dataAuthor.id, name: dataAuthor.name, type: typeAuthor };
    this.setState({
      selectedAuthors: [...this.state.selectedAuthors, author],
    });
    if (control === "old") {
      const inputs1 = this.state.showBiblios.authors;
      const removeIndex = inputs1.findIndex(
        (item) => item.id === dataAuthor.id
      );
      inputs1.splice(removeIndex, 1);
      this.setState({
        showBiblios: {
          ...this.state.showBiblios,
          authors: inputs1,
        },
      });
    }
  };

  removeAuthor = (e, author, type) => {
    e.preventDefault();

    var authorrem = { id: author.id, name: author.name };
    this.setState({
      ...this.state,
      showBiblios: {
        ...this.state.showBiblios,
        authors: [...this.state.showBiblios.authors, authorrem],
      },
    });

    const inputs1 = this.state.selectedAuthors;
    const removeIndex = inputs1.findIndex((item) => item.id === author.id);
    inputs1.splice(removeIndex, 1);
    this.setState({
      selectedAuthors: inputs1,
    });
  };

  handleCallback = (dataKeyword, control) => {
    this.setState({
      selectedKeywords: [...this.state.selectedKeywords, dataKeyword],
    });
    if (control === "old") {
      const inputs1 = this.state.showBiblios.key_words;
      const removeIndex = inputs1.findIndex(
        (item) => item.id === dataKeyword.id
      );
      inputs1.splice(removeIndex, 1);
      this.setState({
        showBiblios: {
          ...this.state.showBiblios,
          key_words: inputs1,
        },
      });
    }
  };

  removeKeyword = (e, keyword) => {
    e.preventDefault();

    this.setState({
      ...this.state,
      showBiblios: {
        ...this.state.showBiblios,
        key_words: [...this.state.showBiblios.key_words, keyword],
      },
    });

    const inputs1 = this.state.selectedKeywords;
    const removeIndex = inputs1.findIndex((item) => item.id === keyword.id);
    inputs1.splice(removeIndex, 1);
    this.setState({
      selectedKeywords: inputs1,
    });
  };

  handleCallbackGeo = (dataGeo, control) => {
    this.setState({
      selectedGeoTerms: [...this.state.selectedGeoTerms, dataGeo],
    });
    if (control === "old") {
      const inputs1 = this.state.showBiblios.geographical_terms;
      const removeIndex = inputs1.findIndex((item) => item.id === dataGeo.id);
      inputs1.splice(removeIndex, 1);
      this.setState({
        showBiblios: {
          ...this.state.showBiblios,
          geographical_terms: inputs1,
        },
      });
    }
  };

  removeGeo = (e, geoTerm) => {
    e.preventDefault();

    this.setState({
      showBiblios: {
        ...this.state.showBiblios,
        geographical_terms: [
          ...this.state.showBiblios.geographical_terms,
          geoTerm,
        ],
      },
    });

    const inputs1 = this.state.selectedGeoTerms;
    const removeIndex = inputs1.findIndex((item) => item.id === geoTerm.id);
    inputs1.splice(removeIndex, 1);
    this.setState({
      selectedGeoTerms: inputs1,
    });
  };

  changeBiblio = (biblio) => {
    this.state.selectedKeywords.map((keyword) => {
      this.setState({
        key_words: [...this.state.showBiblios.key_words, keyword],
      });
    });
    this.state.selectedGeoTerms.map((geo) => {
      this.setState({
        geographical_terms: [...this.state.showBiblios.geographical_terms, geo],
      });
    });

    this.state.selectedAuthors.map((author) => {
      this.setState({
        authors: [...this.state.showBiblios.authors, author],
      });
    });

    this.setState({
      biblio: biblio,
      biblio_id: biblio.id,
      selectedKeywords: biblio.keywords,
      selectedGeoTerms: biblio.geographicalterms,
      selectedAuthors: [],
    });

    biblio.authors.map((author) => {
      var selected = {
        id: author.id,
        name: author.name,
        type: author.pivot.type,
      };
      this.setState({
        selectedAuthors: [...this.state.selectedAuthors, selected],
      });
    });

    const authors = this.state.showBiblios.authors;
    this.state.selectedAuthors.map((author) => {
      const removeIndex = authors.findIndex((item) => item.id === author.id);
      authors.splice(removeIndex, 1);
    });

    const inputs1 = this.state.showBiblios.key_words;
    this.state.selectedKeywords.map((keyword) => {
      const removeIndex = inputs1.findIndex((item) => item.id === keyword.id);
      inputs1.splice(removeIndex, 1);
    });

    const geoterms = this.state.showBiblios.geographical_terms;

    this.state.selectedGeoTerms.map((geot) => {
      const removeIndex = geoterms.findIndex((item) => item.id === geot.id);
      geoterms.splice(removeIndex, 1);
    });

    this.setState({
      key_words: inputs1,
      geographical_terms: geoterms,
      authors: authors,
      errors: [],
    });
  };

  showDownshift = (e) => {
    this.setState({
      isOpenDownshift: true,
    });
  };

  hideDownshift = (e) => {
    this.setState({
      isOpenDownshift: false,
    });
  };

  /*-------------Render Of The View-----------*/

  render() {
    if (this.state.selectedAuthors.length !== 0) {
      var selectedAuthorsListName = this.state.selectedAuthors.map(
        (authors, index) => {
          return (
            <span key={index} className="tag-kyce mr-2">
              {authors.name} / {authors.type}
              <button
                type="button"
                className="ml-2 mr-1 pointer-li btn-unstyled x-button"
                onClick={(e) => this.removeAuthor(e, authors, index)}
              >
                ✕
              </button>
            </span>
          );
        }
      );
    }

    if (this.state.selectedGeoTerms.length !== 0) {
      var selectedGeosListName = this.state.selectedGeoTerms.map(
        (geoTerms, index) => {
          return (
            <span key={index} className="tag-kyce mr-2">
              {geoTerms.name}
              <button
                type="button"
                className="ml-2 mr-1 pointer-li btn-unstyled x-button"
                onClick={(e) => this.removeGeo(e, geoTerms, index)}
              >
                ✕
              </button>
            </span>
          );
        }
      );
    }

    if (this.state.selectedKeywords.length !== 0) {
      var selectedKeywordListName = this.state.selectedKeywords.map(
        (keywords, index) => {
          return (
            <span key={index} className="tag-kyce mr-2">
              {keywords.name}
              <button
                type="button"
                className="ml-2 mr-1 pointer-li btn-unstyled x-button"
                onClick={(e) => this.removeKeyword(e, keywords, index)}
              >
                ✕
              </button>
            </span>
          );
        }
      );
    }

    const Media = this.state.showBiblios.media_types.map((media_types) => {
      return { value: media_types.id, label: media_types.name };
    });

    const Material = this.state.showBiblios.material_types.map((materials) => {
      return { value: materials.id, label: materials.name };
    });

    const Language = this.state.showBiblios.languages.map((language) => {
      return { value: language.id, label: language.name };
    });
    return (
      <Fragment>
        <Downshift
          onChange={this.downshiftOnChange}
          itemToString={(item) => (item ? item.title : "")}
        >
          {({
            selectedItem,
            getInputProps,
            getItemProps,
            closeMenu,
            highlightedIndex,
            isOpen,
            inputValue,
          }) => (
            <div id="create-biblio" className="small-offset-left">
              <form>
                <div className="row m-0 biblio-shto">
                  <div className="col-7 col-sm-9 float-left p-0 partial-title mb-5">
                    Shto Bibliografi
                  </div>
                  <div className="col-5 col-sm-3 p-0 d-flex">
                    <div className="navigation">
                      <span>1 / 3</span>
                      <button
                        className="btn-unstyled"
                        name="forward"
                        onClick={(e) => this.createBiblio(e)}
                      >
                        <img src={right} alt="forward" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="titull title-container mb-4">
                  <label htmlFor="Titulli">Titull (245/a)</label>
                  <div className="position-relative pb-4">
                    <input
                      {...getInputProps({
                        value:
                          this.state.sentBiblio.title !== null
                            ? this.state.sentBiblio.title
                            : "",
                        onChange: this.handleChange("title"),
                      })}
                      onFocus={this.showDownshift}
                      onBlur={this.hideDownshift}
                      onKeyUp={this.handleChange("title")}
                      type="text"
                      name="title"
                      placeholder="Titull"
                      className={
                        "input-form-input" +
                        (this.state.error_list.title ? " form-error" : "")
                      }
                    />
                    {this.state.error_list.title && (
                      <span className="error-message">
                        {this.state.error_list.title[0]}
                      </span>
                    )}
                    {this.state.sentBiblio.biblio_id.length !== 0 && (
                      <button
                        type="button"
                        className="btn-unstyled emptyState"
                        onClick={this.emptyState}
                      >
                        ✕
                      </button>
                    )}
                    {this.state.isOpenDownshift === true &&
                      this.state.sentBiblio.title.length >= 3 && (
                        <Fragment>
                          {isOpen ? (
                            <div className="downshift-dropdown">
                              {this.state.showBiblios.biblios
                                .filter(
                                  (item) =>
                                    !inputValue ||
                                    item.title
                                      .normalize("NFD")
                                      .replace(/[\u0300-\u036f]/g, "")
                                      .toLowerCase()
                                      .includes(inputValue.toLowerCase())
                                )
                                .slice()
                                .map((item, index) => (
                                  <div
                                    className="dropdown-item "
                                    {...getItemProps({
                                      key: index,
                                      index,
                                      item,
                                    })}
                                    style={{
                                      backgroundColor:
                                        highlightedIndex === index
                                          ? "#dcedff"
                                          : "white",
                                      fontWeight:
                                        selectedItem === item
                                          ? "bold"
                                          : "normal",
                                      color:
                                        selectedItem === item
                                          ? "#74767E"
                                          : "#74767E",
                                    }}
                                  >
                                    {item.title}
                                  </div>
                                ))}
                            </div>
                          ) : null}
                        </Fragment>
                      )}
                  </div>
                </div>

                <div className="row mt-4 pt-2">
                  <div className="autor col-12 pb-4 mb-4">
                    <button
                      type="button"
                      className="modal-button"
                      onClick={this.addAuthorPopup}
                    >
                      Shto Autor
                    </button>{" "}
                    <label htmlFor="Autor" style={{ width: "20px" }}>
                      (100/a)
                    </label>
                    <div className="tage w-100 borderi author-tags-container tag-container-fake-input">
                      {selectedAuthorsListName}
                    </div>
                    <AuthorModal
                      types={this.state.author_types}
                      authors={this.state.showBiblios.authors}
                      showPopup={this.state.addAuthorPopup}
                      onPopupClose={this.closeAddAuthorPopup}
                      parentCallback={this.handleCallbackAuthor}
                      popupName={"Autor"}
                    />
                    <div className="d-none author-hiddeninputfields-container"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Medium">Medium (245/h)</label>
                    <input
                      type="text"
                      name="medium"
                      placeholder="Medium"
                      onChange={this.handleChange("medium")}
                      defaultValue={
                        this.state.sentBiblio.medium !== null
                          ? this.state.sentBiblio.medium
                          : ""
                      }
                      className={
                        "input-form-input" +
                        (this.state.error_list.medium ? " form-error" : "")
                      }
                    />
                    {this.state.error_list.medium && (
                      <span className="error-message left">
                        {this.state.error_list.medium[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Nentitull">Nëntitull (245/b)</label>
                    <input
                      type="text"
                      name="subtitle"
                      onChange={this.handleChange("subtitle")}
                      placeholder="Nentitull"
                      defaultValue={
                        this.state.sentBiblio.subtitle !== null
                          ? this.state.sentBiblio.subtitle
                          : ""
                      }
                      className={
                        "input-form-input" +
                        (this.state.error_list.subtitle ? " form-error" : "")
                      }
                    />
                    {this.state.error_list.subtitle && (
                      <span className="error-message left">
                        {this.state.error_list.subtitle[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Lloji i materialit">
                      Lloji i materialit
                    </label>
                    <Select
                      className={
                        "basic-select" +
                        (this.state.error_list.materialtype_id
                          ? " form-error"
                          : "")
                      }
                      classNamePrefix="select_input"
                      name="material_type"
                      isClearable={true}
                      placeholder="Përzgjidh"
                      menuPlacement="auto"
                      defaultValue={
                        this.state.materialType.value === ""
                          ? null
                          : this.state.materialType
                      }
                      isSearchable={false}
                      options={Material}
                      onChange={this.handleChangeSelect(
                        "materialtype_id",
                        "materialType"
                      )}
                    />
                    {this.state.error_list.materialtype_id && (
                      <span className="error-message left">
                        {this.state.error_list.materialtype_id[0]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Emri i pjeses">Emri i pjesës (245/n)</label>
                    <input
                      type="text"
                      name="part_name"
                      placeholder="Emri i pjeses"
                      defaultValue={
                        this.state.sentBiblio.part_name !== null
                          ? this.state.sentBiblio.part_name
                          : ""
                      }
                      onChange={this.handleChange("part_name")}
                      className={
                        "input-form-input" +
                        (this.state.error_list.part_name ? " form-error" : "")
                      }
                    />
                    {this.state.error_list.part_name && (
                      <span className="error-message left">
                        {this.state.error_list.part_name[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Numri i pjeses">
                      Numri i pjesës (245/p)
                    </label>
                    <input
                      type="text"
                      name="part_number"
                      placeholder="Numri i pjeses"
                      className={
                        "input-form-input" +
                        (this.state.error_list.part_number ? " form-error" : "")
                      }
                      onChange={this.handleChange("part_number")}
                      defaultValue={
                        this.state.sentBiblio.part_number !== null
                          ? this.state.sentBiblio.part_number
                          : ""
                      }
                    />
                    {this.state.error_list.part_number && (
                      <span className="error-message left">
                        {this.state.error_list.part_number[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Gjuha origjinale">
                      Gjuha origjinale (041/a)
                    </label>
                    <Select
                      className={
                        "basic-select" +
                        (this.state.error_list.language_id ? " form-error" : "")
                      }
                      classNamePrefix="select_input"
                      name="language_id"
                      placeholder={"Përzgjidh"}
                      defaultValue={
                        this.state.language.value === ""
                          ? null
                          : this.state.language
                      }
                      onChange={this.handleChangeSelect(
                        "language_id",
                        "language"
                      )}
                      isSearchable={true}
                      options={Language}
                      isClearable={true}
                      menuPlacement="auto"
                    />
                    {this.state.error_list.language_id && (
                      <span className="error-message left">
                        {this.state.error_list.language_id[0]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Kufizim moshe">Kufizim moshe (521/a)</label>
                    <input
                      type="text"
                      name="agerestriction"
                      placeholder="Kufizim moshe"
                      onChange={this.handleChange("agerestriction")}
                      className={
                        "input-form-input" +
                        (this.state.error_list.agerestriction
                          ? " form-error"
                          : "")
                      }
                      defaultValue={
                        this.state.sentBiblio.agerestriction !== null
                          ? this.state.sentBiblio.agerestriction
                          : ""
                      }
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Viti i botimit origjinal">
                      Viti i botimit origjinal
                    </label>
                    <input
                      type="number"
                      name="year_original_publication"
                      placeholder="Viti i botimit origjinal"
                      onChange={this.handleChange("year_original_publication")}
                      className="input-form-input"
                      defaultValue={
                        this.state.sentBiblio.year_original_publication !== null
                          ? this.state.sentBiblio.year_original_publication
                          : ""
                      }
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Lloji i medias">Lloji i medias</label>
                    <Select
                      className={
                        "basic-select" +
                        (this.state.error_list.mediatype_id
                          ? " form-error"
                          : "")
                      }
                      classNamePrefix="select_input"
                      name="mediatype_id"
                      placeholder="Përzgjidh"
                      onChange={this.handleChangeSelect(
                        "mediatype_id",
                        "mediaType"
                      )}
                      isClearable={true}
                      menuPlacement="auto"
                      defaultValue={
                        this.state.mediaType.value === ""
                          ? null
                          : this.state.mediaType
                      }
                      isSearchable={true}
                      options={Media}
                    />
                    {this.state.error_list.mediatype_id && (
                      <span className="error-message left">
                        {this.state.error_list.mediatype_id[0]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-4 pb-4">
                    <button
                      className="modal-button"
                      type="button"
                      onClick={this.addKeywordPopup}
                    >
                      Shto Fjalë-Kyçe
                    </button>{" "}
                    <label htmlFor="Fjale Kyce" style={{ width: "20px" }}>
                      (650/a)
                    </label>
                    <div className="tage w-100 borderi key-word-tags-container tag-container-fake-input">
                      {selectedKeywordListName}
                    </div>
                    <KeywordModal
                      key_words={this.state.showBiblios.key_words}
                      showPopup={this.state.addKeywordPopup}
                      onPopupClose={this.closeAddKeywordPopup}
                      parentCallback={this.handleCallback}
                    />
                    <div className="d-none keyword-hiddeninputfields-container"></div>
                  </div>
                  <div className="col-12 col-md-6 mb-4 pb-4">
                    <button
                      className="modal-button"
                      type="button"
                      onClick={this.addGeoPopup}
                    >
                      Shto Terma Gjeografik
                    </button>{" "}
                    <label htmlFor="Term Gjeorgrafik" style={{ width: "20px" }}>
                      (651/a)
                    </label>
                    <div className="tage w-100 borderi geo-terms-tags-container tag-container-fake-input">
                      {selectedGeosListName}
                    </div>
                    <GeographicTermsModal
                      geographical_terms={
                        this.state.showBiblios.geographical_terms
                      }
                      showPopup={this.state.addGeoPopup}
                      onPopupClose={this.closeAddGeoPopup}
                      parentCallback={this.handleCallbackGeo}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-4 pb-4">
                    <label htmlFor="Abstract">Abstract (520/a)</label>
                    <textarea
                      type="textarea"
                      name="abstract"
                      cols="30"
                      rows="10"
                      placeholder="Abstract"
                      onChange={this.handleChange("abstract")}
                      className={
                        "input-form-input tage" +
                        (this.state.error_list.abstract ? " form-error" : "")
                      }
                      defaultValue={
                        this.state.sentBiblio.abstract !== null
                          ? this.state.sentBiblio.abstract
                          : ""
                      }
                    ></textarea>
                    {this.state.error_list.abstract && (
                      <span className="error-message left">
                        {this.state.error_list.abstract[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-6 mb-4 pb-4">
                    <label htmlFor="Shenime">Shënime (500/a)</label>
                    <textarea
                      name="notes"
                      cols="30"
                      rows="10"
                      placeholder="Shenime"
                      onChange={this.handleChange("notes")}
                      className={
                        "input-form-input tage" +
                        (this.state.error_list.notes ? " form-error" : "")
                      }
                      defaultValue={
                        this.state.sentBiblio.notes !== null
                          ? this.state.sentBiblio.notes
                          : ""
                      }
                    ></textarea>
                    {this.state.error_list.notes && (
                      <span className="error-message left">
                        {this.state.error_list.notes[0]}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row pb-4 mb-4 m-0">
                  {this.state.btnLoading === true ? (
                    <button
                      name="forward"
                      className="btn-styled-2 px-5 ml-auto"
                    >
                      Duke u Ruajtur
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      name="forward"
                      className="btn-styled-2 px-5 ml-auto"
                      onClick={(e) => this.createBiblio(e)}
                    >
                      Ruaj
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
        </Downshift>
      </Fragment>
    );
  }
}

export default withRouter(CreateBiblio);
