import React, { Component, Fragment } from "react";
import Request from "../../../../api/Request";
import Pagination from "react-js-pagination";
import Messages from "../../../../helpers/Messages";
import Paginate from "../../../../helpers/Paginate";
import EditGeographicalTerm from "./EditGeographicalTerm";
import SearchFilter from "../../../../helpers/SearchFilter";
import AppConfig from "../../../../services/AppConfig";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
import editIcon from "../../../../assets/images/icons/edit_icon.svg";
import deleteIcon from "../../../../assets/images/icons/delete_icon.svg";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import noResult from "../../../../assets/images/icons/no_result.svg";
import Url from "../../../../api/Url";

class CreateGeographicalTerm extends Component {
  constructor() {
    super();
    this.state = {
      listGeographicalterms: {
        geographicalterms: [],
        loading: true,
      },
      addGeographicalTerm: {
        name: "",
        error_list: [],
        isError: false,
      },
      searchGeographicalTerm: {
        geographicalterm: "",
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
      btnAddLoading: false,
      searched: false,
      pagination: Paginate.setPaginate(),
      page: null,
      showModalPopup: "",
      popupId: "",
      popupName: "",
    };
  }

  isShowPopup = (status, modified) => {
    this.setState({ showModalPopup: status });
    if (modified) {
      this.listGeographicalterms();
    }
  };

  componentDidMount() {
    this.listGeographicalterms();
  }

  getGeoData = (e, id, name) => {
    e.preventDefault();
    this.setState({ popupId: id, popupName: name });
  };

  listGeographicalterms = (hasSearch) => {
    let request = Url.listGeographicalterms;
    const searchParams = {
      geographicalterm: this.state.searchGeographicalTerm.geographicalterm,
    };
    if (hasSearch) {
      hasSearch.preventDefault();
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({ searched: true });
      this.setState({ btnLoading: true });
    }
    const geographicalterms = Request.getRequest(request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          listGeographicalterms: {
            geographicalterms: response.data.geographicalterms.data,
            loading: false,
          },
          form_errors: {
            error_list: [],
          },
          pagination: Paginate.setPaginateData(response.data.geographicalterms),
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          searched: false,
        });
      } else {
        this.setState({
          searched: false,
        });
        Messages.swalError(AppConfig.serverError);
      }
    });
    return geographicalterms;
  };

  handleReset = (e) => {
    setTimeout(() => {
      this.setState({
        name: "",
      });
    }, 100);
  };

  handleInput = (e) => {
    this.setState({
      addGeographicalTerm: {
        [e.target.name]: e.target.value,
      },
    });
  };

  handlePageChange = (pageNumber) => {
    let request = Url.listGeographicalterms;
    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        geographicalterm: this.state.searchGeographicalTerm.geographicalterm,
        page: pageNumber,
      };
    }
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listGeographicalterms: {
          geographicalterms: response.data.geographicalterms.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.geographicalterms),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  saveGeographicalTerm = (e) => {
    e.preventDefault();
    this.setState({
      btnAddLoading: true,
    });
    Request.postRequest(
      Url.storeGeographicalterm,
      this.state.addGeographicalTerm
    ).then((response) => {
      this.setState({
        btnAddLoading: false,
      });
      if (response.status === 201) {
        this.listGeographicalterms();
        Messages.swalSuccess(response.data.success);
        this.setState({
          addGeographicalTerm: {
            name: "",
            error_list: [],
            isError: false,
          },
        });
      } else if (response.status === 422) {
        this.setState({
          addGeographicalTerm: {
            error_list: response.data.name[0],
            isError: true,
          },
        });
      }
    });
  };

  seachHandler = (e) => {
    this.setState({
      searchGeographicalTerm: {
        [e.target.name]: e.target.value,
      },
    });
  };

  deleteGeographicalTerm = (e, id, name) => {
    Messages.swalConfirmDelete(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(Url.deleteGeographicalterm + id).then(
          (response) => {
            if (response.status === 200) {
              this.listGeographicalterms();
              Messages.swalSuccess(response.data.success);
            } else if (response.status === 405) {
              Messages.swalError(response.data.error);
            } else if (response.status === 404) {
              Messages.swalError(response.data.error);
            }
          }
        );
      }
    });
  };

  render() {
    var geographicalTermList = "";
    if (this.state.listGeographicalterms.loading) {
      geographicalTermList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      geographicalTermList =
        this.state.listGeographicalterms.geographicalterms.map(
          (geographicalTermList) => {
            return (
              <tr key={geographicalTermList.id}>
                <td role="cell" data-label="Term gjeografik">
                  {geographicalTermList.name}
                </td>
                <td role="cell" data-label="Ndrysho" className="text-center">
                  <span
                    className="nav-item"
                    onClick={() => this.isShowPopup(geographicalTermList.id)}
                  >
                    <button
                      className="btn-unstyled"
                      onClick={(e) =>
                        this.getGeoData(
                          e,
                          geographicalTermList.id,
                          geographicalTermList.name
                        )
                      }
                    >
                      <img src={editIcon} alt="Edit Icon" />
                    </button>
                  </span>
                  {this.state.showModalPopup === geographicalTermList.id && (
                    <EditGeographicalTerm
                      showModalPopup={this.state.showModalPopup}
                      onPopupClose={this.isShowPopup}
                      geoTerm={geographicalTermList}
                      popupName={this.state.popupName}
                      popupId={this.state.popupId}
                    />
                  )}
                </td>
                <td role="cell" data-label="Fshi" className="text-center">
                  <button
                    type="button"
                    onClick={(e) =>
                      this.deleteGeographicalTerm(
                        e,
                        geographicalTermList.id,
                        geographicalTermList.name
                      )
                    }
                    className="btn-unstyled"
                  >
                    <img src={deleteIcon} alt="Delete Icon" />
                  </button>
                </td>
              </tr>
            );
          }
        );
    }

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="create">
            <div>
              <p className="partial-title">Term Gjeografik</p>
            </div>
            <div className="row m-0">
              <div className="col-12">
                <form onSubmit={this.saveGeographicalTerm}>
                  <div className="row colored-bg px-3 mb-4 pb-3">
                    <label className="pl-3">Shto term gjeografik</label>
                    <div className="col-12 col-lg-3 col-md-5 mb-4 mb-md-0 pb-4">
                      <input
                        type="text"
                        name="name"
                        value={this.state.addGeographicalTerm.name}
                        onChange={this.handleInput}
                        placeholder="Term Gjeografik"
                        className={
                          "input-form-input" +
                          (this.state.addGeographicalTerm.isError === true
                            ? " form-error"
                            : "")
                        }
                      />
                      {this.state.addGeographicalTerm.isError === true && (
                        <span className="error-message left">
                          {this.state.addGeographicalTerm.error_list}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-lg-3 col-md-4">
                      {this.state.btnAddLoading === true ? (
                        <button type="button" className="btn-styled-2 ml-0">
                          Duke Shtuar
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={this.handleReset}
                          className="btn-styled-2 ml-0"
                        >
                          Shto
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                <form onSubmit={this.listGeographicalterms}>
                  <div className="row pl-0 colored-sb pl-3 pr-3">
                    <label className="pl-3">Kërko term gjeografik</label>
                    <div className="col-12 col-lg-3 col-md-5 pb-4 mb-md-0">
                      <input
                        type="text"
                        name="geographicalterm"
                        placeholder="Term Gjeografik"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.geographicalterm
                            ? " form-error"
                            : "")
                        }
                        onChange={this.seachHandler}
                      />
                      {this.state.form_errors.error_list.geographicalterm && (
                        <span className="error-message left">
                          {
                            this.state.form_errors.error_list
                              .geographicalterm[0]
                          }
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-lg-3 col-md-4">
                      {this.state.btnLoading === true ? (
                        <button type="button" className="btn-styled-2 ml-0">
                          Duke Kërkuar
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          name="submit"
                          className="btn-styled-2 ml-0"
                        >
                          Kërko
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="table-list mt-5 mb-5">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th role="columnheader" width="70%" className="text-left">
                      Term gjeografik
                    </th>
                    <th role="columnheader" width="15%" className="text-center">
                      Ndrysho
                    </th>
                    <th role="columnheader" width="15%" className="text-center">
                      Fshi
                    </th>
                  </tr>
                </thead>
                <tbody>{geographicalTermList}</tbody>
              </table>
              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listGeographicalterms.loading === false &&
                this.state.searched === true && (
                  <img
                    className="mx-auto d-block"
                    src={noResult}
                    alt="no result"
                  />
                )}
              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listGeographicalterms.loading === false &&
                this.state.searched === false && (
                  <p className="nothing--to--show">Asgjë për të shfaqur</p>
                )}
            </div>
          </div>
          {this.state.pagination.totalPages > 1 && (
            <div className="d-flex justify-content-center pagination-block mb-5">
              <div className="select-page">
                <Pagination
                  activePage={this.state.pagination.activePage}
                  itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                  totalItemsCount={this.state.pagination.totalItemsCount}
                  pageRangeDisplayed={this.state.pagination.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="pagination-results">
                Faqja: {this.state.pagination.activePage} -{" "}
                {this.state.pagination.totalPages}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default CreateGeographicalTerm;
