import Swal from 'sweetalert2';
import deleteIconModal from '../assets/images/icons/delete_icon_modal.svg';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

toastr.options = {
  closeButton: true,
  newestOnTop: true,
  positionClass: 'toast-top-center',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  extendedTimeOut: 0,
  timeOut: 0,
  tapToDismiss: false,
  extendedTimeOut: '0',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

class Messages {
  static swalSuccess(msg) {
    Swal.fire({
      text: msg,
      icon: 'success',
      showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        container: 'message-container',
      },
    });
  }

  static swalError(msg) {
    Swal.fire({
      text: msg,
      icon: 'error',
      showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        container: 'message-container',
      },
    });
  }

  static swalWarning(msg) {
    Swal.fire({
      text: msg,
      icon: 'warning',
      showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        container: 'message-container',
      },
    });
  }

  static swalInfo(msg) {
    Swal.fire({
      text: msg,
      icon: 'info',
      showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        container: 'message-container',
      },
    });
  }

  static swalConfirmDelete(displayText) {
    return Swal.fire({
      iconHtml: `<img src=${deleteIconModal}>`,
      title: 'Jeni i sigurt që doni të fshini?',
      text: displayText,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Po',
      cancelButtonText: 'Jo',
      customClass: {
        confirmButton: 'btn-styled-1',
        cancelButton: 'btn-styled-2',
        container: 'delete-modal',
      },
    });
  }

  static swalConfirmDeleteImage() {
    return Swal.fire({
      iconHtml: `<img src=${deleteIconModal}>`,
      title: 'Jeni i sigurt që doni të fshini imazhin?',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Po',
      cancelButtonText: 'Jo',
      customClass: {
        confirmButton: 'btn-styled-1',
        cancelButton: 'btn-styled-2',
        container: 'delete-modal',
      },
    });
  }

  static warningMsg = (message) => {
    return toastr.warning(message);
  };
}

export default Messages;
