import React, { Component, Fragment } from "react";
import Select from "react-select";
import Url from "../../../api/Url";
import SearchFilter from "../../../helpers/SearchFilter";
import Request from "../../../api/Request";
import loadingGif from "../../../assets/images/icons/loading.gif";
import Paginate from "../../../helpers/Paginate";
import Pagination from "react-js-pagination";
import noResult from "../../../assets/images/icons/no_result.svg";
import Web from "../../../routes/Web";
import Messages from "../../../helpers/Messages";
import { withRouter } from "react-router-dom";
import AppConfig from "../../../services/AppConfig";
import TooltipComp from "../../../helpers/Tooltip";
import LoadingGif from "../../../assets/images/icons/loading.gif";
class ListPending extends Component {
  constructor() {
    super();
    this.state = {
      listPending: {
        brrowings: [],
        loading: true,
      },
      searchBorrowing: {
        search_text: "",
        type: "",
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
      searched: false,
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.pendingList();
  }

  pendingList = (hasSearch) => {
    let request = Url.listPending;

    const searchParams = {
      search_text: this.state.searchBorrowing.search_text,
      type: this.state.searchBorrowing.type,
    };

    if (hasSearch) {
      this.setState({
        searched: true,
      });
      hasSearch.preventDefault();
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({ btnLoading: true });
    }

    Request.getRequest(request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          listPending: {
            borrowings: response.data.borrowings.data,
            loading: false,
          },
          form_errors: {
            error_list: [],
          },
          pagination: Paginate.setPaginateData(response.data.borrowings),
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          searched: false,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
        this.setState({
          searched: false,
        });
      }
    });
  };

  borrowMaterial = (e, id) => {
    e.preventDefault();
    const data = { id: id };
    Request.postRequest(Url.borrowPendingMaterial, data).then((response) => {
      if (response.status === 200) {
        this.props.history.push(Web.listBorrowings);
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 404) {
        this.pendingList();
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  cancelPending = (e, id) => {
    e.preventDefault();
    const data = { id: id };
    Request.postRequest(Url.cancelPending, data).then((response) => {
      if (response.status === 200) {
        this.pendingList();
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 404) {
        this.pendingList();
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.searchBorrowing,
      [key]: selectValue,
    };
    this.setState({
      searchBorrowing: newForm,
    });
  };

  handleChangeInput = (key) => (e) => {
    let newForm = {
      ...this.state.searchBorrowing,
      [key]: e.target.value,
    };
    this.setState({
      searchBorrowing: newForm,
    });
  };

  handlePageChange(pageNumber) {
    let request = Url.listPending;
    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        search_text: this.state.searchBorrowing.search_text,
        type: this.state.searchBorrowing.type,
        page: pageNumber,
      };
    }
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listPending: {
          borrowings: response.data.borrowings.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.borrowings),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    var borrowingList = "";
    if (this.state.listPending.loading) {
      borrowingList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      borrowingList = this.state.listPending.borrowings.map(
        (borrowing, index) => {
          const Authors = borrowing.item.biblioitem.biblio.authors.map(
            (author) => {
              return author.name;
            }
          );
          return (
            <tr key={index}>
              <td className="text-left" data-label="Anëtarë">
                {borrowing.user.first_name + " " + borrowing.user.last_name}
              </td>
              <td className="text-center" data-label="Titull/Autor">
                <div className="text-md-center">
                  {TooltipComp.tooltipDisabledMobile(
                    index,
                    borrowing.item.biblioitem.biblio.title
                  )}
                </div>
                {Authors.join("; ")}
              </td>
              <td className="text-center" data-label="Lloji i huazimit">
                {borrowing.item.borrowing_type}
              </td>
              <td
                className="text-center"
                data-label="Kodifikimi/Nr. i inventarit"
              >
                {borrowing.item.inventory_number + " / " + borrowing.item.id}
              </td>
              <td className="text-center" data-label="Jep hua">
                <button
                  className="btn-styled-1 small_btn"
                  onClick={(e) => this.borrowMaterial(e, borrowing.id)}
                  type="submit"
                >
                  Jep hua
                </button>
              </td>
              <td className="text-center" data-label="Anullo">
                <button
                  className="btn-styled-2 small_btn"
                  onClick={(e) => this.cancelPending(e, borrowing.id)}
                  type="submit"
                >
                  Anullo
                </button>
              </td>
            </tr>
          );
        }
      );
    }

    return (
      <Fragment>
        <div id="list-pending" className="small-offset-left">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-12">
                <p className="partial-title mb-5">Në pritje</p>
              </div>
            </div>
          </div>
          <div className="container-fluid px-0">
            <form onSubmit={this.pendingList} className="borrowing-search">
              <div className="row mb-5">
                <div className="col-12 col-sm-6 col-md-4 pb-4 mb-4">
                  <input
                    placeholder="Kërko... "
                    className={
                      "w-100 input-form-input" +
                      (this.state.form_errors.error_list.search_text
                        ? " form-error"
                        : "")
                    }
                    onChange={this.handleChangeInput("search_text")}
                    name="search_text"
                    type="text"
                  />
                  {this.state.form_errors.error_list.search_text && (
                    <span className="error-message left">
                      {this.state.form_errors.error_list.search_text[0]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-4 pb-4 mb-4">
                  <Select
                    className={
                      "basic-select" +
                      (this.state.form_errors.error_list.type
                        ? " form-error"
                        : "")
                    }
                    classNamePrefix="select_input"
                    name="type"
                    isSearchable={false}
                    isClearable={true}
                    onChange={this.handleChangeSelect("type")}
                    placeholder="Perzgjidh"
                    options={[
                      { value: "member", label: "Anetare" },
                      { value: "nr_inventory", label: "Kodifikimi" },
                      { value: "title", label: "Titull" },
                    ]}
                  />
                  {this.state.form_errors.error_list.type && (
                    <span className="error-message left">
                      {this.state.form_errors.error_list.type[0]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-4 pb-4 mb-4">
                  {this.state.btnLoading === true ? (
                    <button className="btn-styled-2 px-5 ml-0" type={"button"}>
                      Duke Kërkuar
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button className="btn-styled-2 px-5 ml-0" type={"submit"}>
                      Kërko
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="container-fluid px-0">
            <div className="table-list">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th className="text-left" width="12.5%" role="columnheader">
                      Anëtarë
                    </th>
                    <th
                      className="text-center"
                      width="12.5%"
                      role="columnheader"
                    >
                      Titull/Autor
                    </th>
                    <th
                      className="text-center"
                      width="12.5%"
                      role="columnheader"
                    >
                      Lloji i huazimit
                    </th>
                    <th
                      className="text-center"
                      width="12.5%"
                      role="columnheader"
                    >
                      Kodifikimi/Nr. i inventarit
                    </th>
                    <th
                      className="text-center"
                      width="12.5%"
                      role="columnheader"
                    >
                      Jep hua
                    </th>
                    <th
                      className="text-center"
                      width="12.5%"
                      role="columnheader"
                    >
                      Anullo
                    </th>
                  </tr>
                </thead>
                <tbody>{borrowingList}</tbody>
              </table>
              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listPending.loading === false &&
                this.state.searched === true && (
                  <img
                    className="mx-auto d-block"
                    src={noResult}
                    alt="no result"
                  />
                )}

              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listPending.loading === false &&
                this.state.searched === false && (
                  <p className="nothing--to--show">Asgjë për të shfaqur</p>
                )}
            </div>
          </div>
          <div className="container-fluid pt-5 pb-4">
            <div className="d-flex justify-content-center pagination-block">
              {this.state.pagination.totalPages > 1 && (
                <div className="d-flex justify-content-center pagination-block mb-5">
                  <div className="select-page">
                    <Pagination
                      activePage={this.state.pagination.activePage}
                      itemsCountPerPage={
                        this.state.pagination.itemsCountPerPage
                      }
                      totalItemsCount={this.state.pagination.totalItemsCount}
                      pageRangeDisplayed={
                        this.state.pagination.pageRangeDisplayed
                      }
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                  <div className="pagination-results">
                    Faqja: {this.state.pagination.activePage} -{" "}
                    {this.state.pagination.totalPages}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(ListPending);
