import React, { Component, Fragment } from "react";
import Request from "../../../../api/Request";
import Url from "../../../../api/Url";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import "../../../../assets/css/stats.css";
import { Bar } from "react-chartjs-2";
import TooltipComp from "../../../../helpers/Tooltip";

class IndexStats extends Component {
  constructor() {
    super();
    this.state = {
      materials: {
        oldest: [{}],
        the_most_borrowed: [{}],
        never_borrowed: [{}],
        best_members: [{}],
        borrowings_m: [{}],
        borrowings_y: [{}],
        total_price: "",
        loading: true,
      },
    };
  }

  componentDidMount() {
    Request.getRequest(Url.stats).then((result) => {
      if (result.status === 200) {
        this.setState({
          materials: {
            oldest: result.data.oldest,
            the_most_borrowed: result.data.the_most_borrowed,
            never_borrowed: result.data.never_borrowed,
            best_members: result.data.best_members,
            borrowings_m: result.data.borrowings_m,
            borrowings_y: result.data.borrowings_y,
            total_price: result.data.total_price,
            loading: false,
          },
        });
      } else {
        this.setState({
          materials: {
            oldest: [{}],
            the_most_borrowed: [{}],
            never_borrowed: [{}],
            best_members: [{}],
            borrowings_m: [{}],
            borrowings_y: [{}],
            loading: true,
          },
        });
      }
    });
  }

  render() {
    var oldest = "";
    var the_most_borrowed = "";
    var never_borrowed = "";
    var best_members = "";
    var borrowings_m = this.state.materials.borrowings_m;
    var borrowings_y = this.state.materials.borrowings_y;

    var monthNames = [
      "Janar",
      "Shkurt",
      "Mars",
      "Prill",
      "Maj",
      "Qershor",
      "Korrik",
      "Gusht",
      "Shtator",
      "Tetor",
      "Nentor",
      "Dhjetor",
    ];
    var listMonths = [];
    var values = [];
    var listYears = [];
    var values2 = [];

    borrowings_m.forEach((el, index) => {
      listMonths.push(monthNames[el["month"] - 1]);
      values.push(el["data"]);
    });
    borrowings_y.forEach((el, index) => {
      listYears.push(el["year"]);
      values2.push(el["data"]);
    });

    var opt = {
      responsive: true,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      plugins: {
        chart: "bar",
        title: {
          display: true,
          align: "start",
          color: "#002c3e",
          fullSize: false,
          text: "NUMRI I HUAZIMEVE SIPAS MUAJVE",
          font: {
            size: 20,
            weight: "500",
            spacing: "0.8",
          },
        },
      },
      scales: {
        x: {
          color: "black",
          grid: {
            drawBorder: true,
            lineWidth: 0,
            borderColor: "black",
            borderWidth: 2,
          },
          ticks: {
            maxRotation: 45,
            minRotation: 45,
          },
        },
        y: {
          ticks: {
            callback: function (value, index, values) {
              return value;
            },
            _suggestedMin: 0,
            precision: 0,
          },
          beginAtZero: true,
          grid: {
            drawBorder: true,
            lineWidth: 0,
            borderColor: "black",
            borderWidth: 2,
          },
        },
      },
    };
    var opt2 = JSON.parse(JSON.stringify(opt));
    opt2.plugins.title.text = "NUMRI I HUAZIMEVE SIPAS VITEVE";
    const data_m = {
      labels: listMonths,
      datasets: [
        {
          label: "Huazime",
          backgroundColor: "#007aff",
          borderColor: "steelblue",
          borderWidth: 0,
          barThickness: 30,
          data: [65, 59, 80, 81, 56],
        },
      ],
    };

    const data_y = {
      labels: listYears,
      datasets: [
        {
          label: "Huazime",
          backgroundColor: "#007aff",
          borderColor: "steelblue",
          borderWidth: 0,
          barThickness: 30,
          data: [65, 59, 80, 81, 56],
        },
      ],
    };

    if (this.state.materials.loading) {
      oldest = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );

      the_most_borrowed = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );

      never_borrowed = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );

      best_members = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      oldest = this.state.materials.oldest.map((old, index) => {
        return (
          <tr key={index} className="bg-white tr-report-stats">
            <td data-label="Titull/Autor">
              {TooltipComp.tooltipDisabledMobile("-" + index, old["title"])}
              <p className="autor">
                {old["authors"].map((item, index, arr) => {
                  if (index === arr.length - 1) {
                    return item["name"];
                  } else {
                    return item["name"] + "; ";
                  }
                })}
              </p>
            </td>
            <td className="text-center" data-label="Viti i botimit">
              {old["year"]}
            </td>
          </tr>
        );
      });

      the_most_borrowed = this.state.materials.the_most_borrowed.map(
        (item, index) => {
          return (
            <tr key={index} className="bg-white tr-report-stats">
              <td data-label="Titull/Autor">
                {TooltipComp.tooltipDisabledMobile(
                  "-title-" + index,
                  item["title"]
                )}
                <p className="autor">
                  {item["authors"].map((item_a, index, arr) => {
                    if (index === arr.length - 1) {
                      return item_a["name"];
                    } else {
                      return item_a["name"] + "; ";
                    }
                  })}
                </p>
              </td>
              <td className="text-center" data-label="Nr. huazime">
                {item["borrowings_count"]}
              </td>
            </tr>
          );
        }
      );

      never_borrowed = this.state.materials.never_borrowed.map(
        (item, index) => {
          return (
            <tr key={index} className="bg-white tr-report-stats">
              <td data-label="TITULL/AUTOR" className="text-center">
                {TooltipComp.tooltipDisabledMobile(
                  "-titull_" + index,
                  item["title"]
                )}
                <p className="autor">
                  {item["authors"].map((item_a, index, arr) => {
                    if (index === arr.length - 1) {
                      return item_a["name"];
                    } else {
                      return item_a["name"] + "; ";
                    }
                  })}
                </p>
              </td>
            </tr>
          );
        }
      );

      best_members = this.state.materials.best_members.map((item, index) => {
        return (
          <tr key={index} className="bg-white tr-report-stats">
            <td data-label="ANETAR">
              {item["first_name"]} {item["last_name"]}
            </td>
            <td data-label="NR. HUAZIME" className="text-center">
              {item["borrowings_count"]}
            </td>
          </tr>
        );
      });
    }

    return (
      <Fragment>
        <div id="report-stats" className="small-offset-left">
          <div className="">
            <div className="row">
              <div className="col-12">
                <div className="partial-title mb-5">Statistika</div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="row mb-5">
              <div className="col-12 col-xl-6 mb-4">
                <div className="stats-title font-smaller mb-4">
                  Informacione financiare
                </div>
                <div className="table-list">
                  <table role="table" className="w-100">
                    <thead role="rowgroup">
                      <tr role="row">
                        <th role="columnheader" className="text-left">
                          Vlera totale e materialeve bibliografike është{" "}
                          {this.state.materials.total_price} lekë.
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xl-6 mb-4">
                <div className="stats-title font-smaller mb-4">
                  Librat më të vjetër
                </div>
                <div className="table-list">
                  <table role="table" className="w-100">
                    <thead role="rowgroup">
                      <tr role="row">
                        <th
                          width="70%"
                          role="columnheader"
                          className="text-left"
                        >
                          Titull/Autor
                        </th>
                        <th
                          width="30%"
                          role="columnheader"
                          className="text-center"
                        >
                          Viti i botimit
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">{oldest}</tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 col-xl-6 most-borrowed">
                <div className="stats-title font-smaller mb-4">
                  Librat më të huazuar
                </div>
                <div className="table-list">
                  <table role="table" className="w-100">
                    <thead role="rowgroup">
                      <tr role="row">
                        <th
                          width="70%"
                          role="columnheader"
                          className="text-left"
                        >
                          TITULL/AUTOR
                        </th>
                        <th
                          width="30%"
                          role="columnheader"
                          className="text-center"
                        >
                          NR. HUAZIME
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">{the_most_borrowed}</tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 col-xl-6">
                <div className="stats-title font-smaller mt-5 mb-4">
                  Librat që nuk janë huazuar asnjeherë
                </div>
                <div className="table-list">
                  <table role="table" className="w-100">
                    <thead role="rowgroup">
                      <tr role="row">
                        <th role="columnheader" className="text-center">
                          TITULL/AUTOR
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">{never_borrowed}</tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 col-xl-6">
                <div className="stats-title font-smaller mt-5 mb-4">
                  Anëtarët me më shumë huazime
                </div>
                <div className="table-list">
                  <table role="table" className="w-100">
                    <thead role="rowgroup">
                      <tr role="row">
                        <th
                          width="70%"
                          role="columnheader"
                          className="text-left"
                        >
                          ANETAR
                        </th>
                        <th
                          width="30%"
                          role="columnheader"
                          className="text-center"
                        >
                          NR. HUAZIME
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">{best_members}</tbody>
                  </table>
                </div>
              </div>

              <div className="container-fluid">
                <div className="row mb-5">
                  <div className="col-12 col-md-6 mt-5" id="month">
                    <Bar data={data_m} options={opt} />
                  </div>
                  <div className="col-12 col-md-6 mt-5" id="year">
                    <Bar data={data_y} options={opt2} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default IndexStats;
