import React, { Component, Fragment } from 'react';
import Url from '../../../../api/Url';
import Request from '../../../../api/Request';
import { withRouter } from 'react-router-dom';
import Messages from '../../../../helpers/Messages';
import AppConfig from '../../../../services/AppConfig';
import loadingGif from '../../../../assets/images/icons/loading.gif';
import Select from 'react-select';
import LoadingGif from '../../../../assets/images/icons/loading.gif';
import ServiceContext from '../../../../helpers/Context';
import Web from '../../../../routes/Web';
class ServicesList extends Component {
  static contextType = ServiceContext;

  constructor() {
    super();
    this.state = {
      listOfServices: {
        services: [],
        count: '',
        loading: true,
      },
      searchService: {
        specification: '',
        term: '',
        server_id: '',
      },
      listServers: {
        servers: [],
        loading: true,
        showResults: false,
        errorMsg: '',
      },
      form_errors: {
        error_list: [],
      },
      showResults: false,
      searched: false,
      btnLoading: false,
      showModalPopup: '',
      popupId: '',
      popupName: '',
      checkedRecords: {},
      checkedRecordsValue: {},
    };
  }

  servicesList = (e) => {
    e.preventDefault();
    this.setState({
      btnLoading: true,
    });
    const searchParams = {
      specification: this.state.searchService.specification,
      term: this.state.searchService.term,
      server_id: this.state.searchService.server_id,
    };
    Request.postRequest(Url.getRecords, searchParams).then((response) => {
      this.setState({
        btnLoading: false,
      });
      if (response.status === 200) {
        this.setState({
          listOfServices: {
            services: response.data.data,
            count: response.data.count,
            loading: false,
          },
          form_errors: {
            error_list: [],
          },
          showResults: true,
        });
        this.serverTypes();
      } else if (response.status === 422) {
        this.setState({
          ...this.state.searchService,
          form_errors: {
            error_list: response.data,
          },
          searched: false,
          showResults: false,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  componentDidMount() {
    this.serverTypes();
  }

  checkBox = (e, recordIndex, recordValue) => {
    var checkedList = this.state.checkedRecords;
    var checkedRecordValues = this.state.checkedRecordsValue;
    const checkedValues = {
      [`value_${recordIndex}`]: recordIndex,
    };
    const checkedRecords = {
      [`record_${recordIndex}`]: recordValue,
    };

    if (e.target.checked === false) {
      delete checkedList[`value_${recordIndex}`];
      delete checkedRecordValues[`record_${recordIndex}`];
      this.setState({
        checkedRecords: checkedList,
        checkedRecordsValue: checkedRecordValues,
      });
    } else {
      var checkedInputs = {
        ...checkedList,
      };
      Object.assign(checkedInputs, checkedValues);
      var checkedRecordValues = {
        ...checkedRecordValues,
      };
      Object.assign(checkedRecordValues, checkedRecords);
      this.setState({
        checkedRecords: checkedInputs,
        checkedRecordsValue: checkedRecordValues,
      });
    }
  };

  serverTypes = () => {
    Request.getRequest(Url.getServers).then((response) => {
      if (response.status === 200) {
        this.setState({
          listServers: {
            servers: response.data.servers,
            loading: false,
            showResults: true,
          },
        });
      } else if (response.status === 403) {
        this.setState({
          listServers: {
            servers: [],
            loading: false,
            showResults: false,
            errorMsg: response.data.error,
          },
        });
      }
    });
  };

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = '';
    }
    let newForm = {
      ...this.state.searchService,
      [key]: selectValue,
    };
    this.setState({
      searchService: newForm,
    });
  };

  searchInput = (key) => (e) => {
    let newForm = {
      ...this.state.searchService,
      [key]: e.target.value,
    };
    this.setState({
      searchService: newForm,
    });
  };

  render() {
    const { service, setService } = this.context;

    var serviceList = '';
    var serverSelect = this.state.listServers.servers.map((serverType) => {
      return {
        value: serverType.id,
        label:
          serverType.name +
          (serverType.database_name
            ? ' (' + serverType.database_name + ')'
            : ''),
      };
    });
    if (
      !this.state.showResults &&
      this.state.listOfServices.loading &&
      this.state.searched
    ) {
      serviceList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      serviceList = this.state.listOfServices.services.map(
        (serviceDetail, index) => {
          var el = this.state.checkedRecords[`value_${index}`];
          return (
            <tr key={index}>
              <td role="cell" data-label="Emri i autorit" className="text-left">
                {serviceDetail.title ? serviceDetail.title : '-'}
              </td>
              <td
                role="cell"
                data-label="Emri i autorit"
                className="text-center"
              >
                {`${serviceDetail.isbn ? serviceDetail.isbn : '-'} / ${
                  serviceDetail.issn ? serviceDetail.issn : '-'
                }`}
              </td>
              <td
                role="cell"
                data-label="Përzgjidh"
                className="text-right choose_export"
              >
                <input
                  type="checkbox"
                  name="biblioitems[]"
                  value={index}
                  className="checkbox"
                  id={index}
                  onChange={(e) => this.checkBox(e, index, serviceDetail)}
                  defaultChecked={el == index ? true : false}
                />
                <label htmlFor={index}>Choose</label>
              </td>
            </tr>
          );
        }
      );
    }

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="create">
            <div>
              <p className="partial-title">Shërbimi Z39.50</p>
            </div>
            {this.state.listServers.showResults &&
            !this.state.listServers.loading ? (
              <Fragment>
                <div className="row">
                  <div className="col-12">
                    <form onSubmit={this.servicesList}>
                      <div className="row pl-0 colored-sb pr-3">
                        <label className="pl-3">Kërko shërbim</label>
                        <div className="col-12 col-lg-3 col-md-5 pb-4 mb-md-0">
                          <input
                            type="text"
                            name="term"
                            placeholder="Emri i Shërbimit"
                            className={
                              'input-form-input' +
                              (this.state.form_errors.error_list.term
                                ? ' form-error'
                                : '')
                            }
                            value={this.state.searchService.term}
                            onChange={this.searchInput('term')}
                          />
                          {this.state.form_errors.error_list.term && (
                            <span className="error-message left">
                              {this.state.form_errors.error_list.term[0]}
                            </span>
                          )}
                        </div>
                        <div className="col-12 col-lg-3 col-md-5 pb-4 mb-md-0">
                          <Select
                            className={
                              'basic-select' +
                              (this.state.form_errors.error_list.specification
                                ? ' form-error'
                                : '')
                            }
                            classNamePrefix="select_input"
                            name="specification"
                            placeholder="Përzgjidh"
                            isClearable={true}
                            defaultInputValue={
                              this.state.searchService.specification
                            }
                            onChange={this.handleChangeSelect('specification')}
                            isSearchable={false}
                            options={[
                              { value: 'isbn', label: 'Isbn' },
                              { value: 'ti', label: 'Titull' },
                              { value: 'au', label: 'Autor' },
                            ]}
                          />
                          {this.state.form_errors.error_list.specification && (
                            <span className="error-message left">
                              {
                                this.state.form_errors.error_list
                                  .specification[0]
                              }
                            </span>
                          )}
                        </div>
                        <div className="col-12 col-lg-3 col-md-5 pb-4 mb-md-0">
                          <Select
                            className={
                              'basic-select' +
                              (this.state.form_errors.error_list.server_id
                                ? ' form-error'
                                : '')
                            }
                            classNamePrefix="select_input"
                            name="server_id"
                            placeholder="Serveri"
                            isClearable={true}
                            defaultInputValue={
                              this.state.searchService.server_id
                            }
                            onChange={this.handleChangeSelect('server_id')}
                            isSearchable={false}
                            options={serverSelect}
                          />
                          {this.state.form_errors.error_list.server_id && (
                            <span className="error-message left">
                              {this.state.form_errors.error_list.server_id[0]}
                            </span>
                          )}
                        </div>
                        <div className="col-12 col-lg-3 col-md-4">
                          {this.state.btnLoading === true ? (
                            <button
                              type="button"
                              name="submit"
                              className="btn-styled-2 ml-0"
                            >
                              Duke kërkuar
                              <img
                                src={LoadingGif}
                                style={{
                                  width: '18px',
                                  margin: '-4px 0 0 7px',
                                }}
                              />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              name="submit"
                              className="btn-styled-2 ml-0"
                            >
                              Kërko
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {this.state.listOfServices.services.length > 0 && (
                  <div className="row mt-4">
                    <div className="col-12 col-sm-6">
                      {Object.keys(this.state.checkedRecordsValue).length >
                        0 && (
                        <div className="row pl-0 colored-sb pr-3">
                          <div className="col-12 col-lg-3 col-md-4">
                            {this.state.btnLoading === true ? (
                              <button
                                type="button"
                                className="btn-styled-2 ml-0"
                              >
                                Duke ngarkuar
                                <img
                                  src={LoadingGif}
                                  style={{
                                    width: '18px',
                                    margin: '-4px 0 0 7px',
                                  }}
                                />
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn-styled-2 ml-0"
                                onClick={() => {
                                  setService(this.state.checkedRecordsValue);
                                  Object.keys(this.state.checkedRecordsValue)
                                    .length > 0 &&
                                    this.props.history.push(Web.confirmZ3950);
                                }}
                              >
                                Ngarko
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 d-flex justify-content-end align-items-center">
                      Numri i rekordeve: {this.state.listOfServices.count}
                    </div>
                  </div>
                )}
                <div className="table-list mt-3 mb-4">
                  <table role="table" className="w-100">
                    <thead>
                      <tr role="row">
                        <th
                          role="columnheader"
                          width="30%"
                          className="text-left"
                        >
                          Titull
                        </th>
                        <th
                          role="columnheader"
                          width="60%"
                          className="text-center"
                        >
                          Isbn / Issn
                        </th>
                        <th
                          role="columnheader"
                          width="10%"
                          className="text-center"
                        >
                          Përzgjidh
                        </th>
                      </tr>
                    </thead>
                    <tbody>{serviceList}</tbody>
                  </table>
                </div>
                {this.state.listOfServices.services.length > 0 && (
                  <div className="row mt-2 mb-5">
                    <div className="col-12 col-sm-6">
                      {Object.keys(this.state.checkedRecordsValue).length >
                        0 && (
                        <div className="row pl-0 colored-sb pr-3">
                          <div className="col-12 col-lg-3 col-md-4">
                            {this.state.btnLoading === true ? (
                              <button
                                type="button"
                                className="btn-styled-2 ml-0"
                              >
                                Duke ngarkuar
                                <img
                                  src={LoadingGif}
                                  style={{
                                    width: '18px',
                                    margin: '-4px 0 0 7px',
                                  }}
                                />
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn-styled-2 ml-0"
                                onClick={() => {
                                  setService(this.state.checkedRecordsValue);
                                  Object.keys(this.state.checkedRecordsValue)
                                    .length > 0 &&
                                    this.props.history.push(Web.confirmZ3950);
                                }}
                              >
                                Ngarko
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-sm-6 d-flex justify-content-end align-items-center">
                      Numri i rekordeve: {this.state.listOfServices.count}
                    </div>
                  </div>
                )}
              </Fragment>
            ) : (
              this.state.listServers.errorMsg.length > 0 && (
                <div class="alert alert-danger" role="alert">
                  {this.state.listServers.errorMsg}
                </div>
              )
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(ServicesList);
