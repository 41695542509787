import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import Select from "react-select";
import Request from "../../../../api/Request";
import Url from "../../../../api/Url";
import "../../../../assets/css/confirmmarc.css";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
import editIcon from "../../../../assets/images/icons/edit_icon.svg";
import Messages from "../../../../helpers/Messages";
import AppConfig from "../../../../services/AppConfig";
import Web from "../../../../routes/Web";
import TooltipComp from "../../../../helpers/Tooltip";
import ServiceContext from "../../../../helpers/Context";
import { Button } from "react-bootstrap";

class ConfirmZ3950 extends Component {
  static contextType = ServiceContext;
  constructor() {
    super();
    this.state = {
      uploadedZ3950: {
        file_name: [],
        loading: true,
        fileZ3950: "",
      },
      frequencesList: {
        frequence: "",
      },
      listMaterials: {
        material_types: "",
        loading: true,
      },
      errors: {
        error: [],
        hasError: "",
      },
      btnLoading: false,
    };
  }
  componentDidMount() {
    if (Object.keys(this.context.services).length < 1) {
      this.props.history.push(Web.z3950Service);
    } else {
      this.materialTypes();
    }
  }

  materialTypes = () => {
    Request.getRequest(Url.materialTypes).then((response) => {
      if (response.status === 200) {
        this.setState({
          listMaterials: {
            material_types: response.data.material_types,
            loading: false,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };
  handleInput = (key) => (e) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  handleInputS = (key) => (e) => {
    var stateObject = function () {
      var returnObj = {};
      returnObj[key] = e.value;
      return returnObj;
    };
    this.setState(stateObject);
  };

  importZ3950 = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    var inputs = document.querySelectorAll("input");
    const formData = new FormData();
    for (let input of inputs) {
      var inputName = input.name;
      var inputValue = input.value;
      formData.append(inputName, inputValue);
    }
    Request.postRequest(Url.storeZ3950Records, formData).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 201) {
        this.props.history.push(Web.listBiblioitems);
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 422) {
        this.setState({
          errors: {
            error: response.data,
            hasError: true,
          },
        });
      }
      var errorFields = document.querySelectorAll(".error-message");
      errorFields.forEach((errorField) => {
        let errorContainer = errorField.closest(".series-div");
        if (errorContainer.classList.contains("show") === false) {
          errorContainer.classList.add("show");
        }
      });
    });
  };

  checkContributors = (contributor) => {
    switch (contributor) {
      case "perkthyes":
        return { value: "perkthyes", label: "Përkthyes" };
      case "redaktor":
        return { value: "redaktor", label: "Redaktor" };
      case "editor.":
        return { value: "redaktor", label: "Redaktor" };
      case "editor":
        return { value: "redaktor", label: "Redaktor" };
      case "recensues":
        return { value: "recensues", label: "Recensues" };
      case "pergatites":
        return { value: "pergatites", label: "Përgatitës" };
      default:
        return { value: "kontributor", label: "Person përgjegjës" };
    }
  };

  checkAuthors = (author) => {
    switch (author) {
      case "paresor":
        return { value: "paresor", label: "Parësor" };
      case "dytesor":
        return { value: "dytesor", label: "Dytësor" };
      case "tretesor":
        return { value: "tretesor", label: "Tretësor" };
      case "author.":
        return { value: "paresor", label: "Parësor" };
      case "author":
        return { value: "paresor", label: "Parësor" };
      case "autor":
        return { value: "author", label: "Autor" };
      default:
        return { value: "autor", label: "Autor" };
    }
  };

  render() {
    var uploadedZ3950List = "";
    if (this.state.listMaterials.loading === false) {
      var materials = this.state.listMaterials.material_types.map(
        (materialType) => {
          return { value: materialType.id, label: materialType.name };
        }
      );
    }

    var z3950List = [];

    for (const [index, [, value]] of Object.entries(
      Object.entries(this.context.services)
    )) {
      z3950List.push(value);
    }

    uploadedZ3950List = z3950List.map((value, index) => {
      var bibliotitem = "";
      if (value.authors.length > 0) {
        var uploadZ3950Author = value.authors.map((author, indexA) => {
          return author.name ? (
            <Fragment key={indexA}>
              <div className="col-12 col-md-6">
                <input
                  className={
                    "input-form-input mb-4" +
                    ((bibliotitem =
                      "biblioitems." +
                      index +
                      ".author." +
                      indexA +
                      ".author") && this.state.errors.error[bibliotitem]
                      ? " form-error"
                      : "")
                  }
                  name={
                    "biblioitems[" + index + "][author][" + indexA + "][author]"
                  }
                  type="text"
                  defaultValue={author.name}
                  onChange={this.handleInput(
                    "biblioitems[" + index + "][author][" + indexA + "][author]"
                  )}
                />
                {(bibliotitem =
                  "biblioitems." + index + ".author." + indexA + ".author") &&
                  this.state.errors.error[bibliotitem] && (
                    <span className="error-message">
                      {this.state.errors.error[bibliotitem]}
                    </span>
                  )}
              </div>

              <div className="col-12 col-md-6">
                <Select
                  className={
                    "basic-select mb-4" +
                    ((bibliotitem =
                      "biblioitems." +
                      index +
                      ".author." +
                      indexA +
                      ".type_author") && this.state.errors.error[bibliotitem]
                      ? " form-error"
                      : "")
                  }
                  classNamePrefix="select_input"
                  name={
                    "biblioitems[" +
                    index +
                    "][author][" +
                    indexA +
                    "][type_author]"
                  }
                  placeholder="Përzgjidh"
                  onChange={this.handleInputS(
                    "biblioitems[" +
                      index +
                      "][author][" +
                      indexA +
                      "][type_author]"
                  )}
                  isSearchable={false}
                  options={[
                    { value: "paresor", label: "Parësor" },
                    { value: "dytesor", label: "Dytësor" },
                    { value: "tretesor", label: "Tretësor" },
                    { value: "autor", label: "Autor" },
                  ]}
                  defaultValue={[this.checkAuthors(author.type)]}
                />
                {(bibliotitem =
                  "biblioitems." +
                  index +
                  ".author." +
                  indexA +
                  ".type_author") &&
                  this.state.errors.error[bibliotitem] && (
                    <span className="error-message">
                      {this.state.errors.error[bibliotitem]}
                    </span>
                  )}
              </div>
            </Fragment>
          ) : (
            ""
          );
        });

        var listAuthors = value.authors.map((author, indexA) => {
          return author.name ? author.name : "-";
        });
      }

      if (value.contributors.length > 0) {
        var uploadZ3950Contributor = value.contributors.map(
          (contributor, indexC) => {
            return contributor.name ? (
              <Fragment key={indexC}>
                <div className="col-12 col-md-6">
                  <input
                    className={
                      "input-form-input mb-4" +
                      ((bibliotitem =
                        "biblioitems." +
                        index +
                        ".contributors." +
                        indexC +
                        ".contributor") && this.state.errors.error[bibliotitem]
                        ? " form-error"
                        : "")
                    }
                    name={
                      "biblioitems[" +
                      index +
                      "][contributors][" +
                      indexC +
                      "][contributor]"
                    }
                    type="text"
                    defaultValue={contributor.name}
                    onChange={this.handleInput(
                      "biblioitems[" +
                        index +
                        "][contributors][" +
                        indexC +
                        "][contributor]"
                    )}
                  />
                  {(bibliotitem =
                    "biblioitems." +
                    index +
                    ".contributors." +
                    indexC +
                    "contributor") &&
                    this.state.errors.error[bibliotitem] && (
                      <span className="error-message">
                        {this.state.errors.error[bibliotitem]}
                      </span>
                    )}
                </div>
                <div className="col-12 col-md-6">
                  <Select
                    className={
                      "basic-select  mb-4" +
                      ((bibliotitem =
                        "biblioitems." +
                        index +
                        ".contributors." +
                        indexC +
                        ".type_contributor") &&
                      this.state.errors.error[bibliotitem]
                        ? " form-error"
                        : "")
                    }
                    classNamePrefix="select_input"
                    name={
                      "biblioitems[" +
                      index +
                      "][contributors][" +
                      indexC +
                      "][type_contributor]"
                    }
                    placeholder="Tipi i personit përgjegjës"
                    onChange={this.handleInputS(
                      "biblioitems[" +
                        index +
                        "][contributors][" +
                        indexC +
                        "][type_contributor]"
                    )}
                    isSearchable={false}
                    options={[
                      { value: "redaktor", label: "Redaktor" },
                      { value: "perkthyes", label: "Përkthyes" },
                      { value: "recensues", label: "Recensues" },
                      { value: "pergatites", label: "Përgatitës" },
                      { value: "kontributor", label: "Person përgjegjës" },
                    ]}
                    defaultValue={[this.checkContributors(contributor.type)]}
                  />
                </div>
              </Fragment>
            ) : (
              ""
            );
          }
        );
      }
      if (value.keywords_name.length > 0) {
        var uploadZ3950Keyword = value.keywords_name.map((keyword, indexK) => {
          return (
            <input
              name={"biblioitems[" + index + "][keywords][" + indexK + "]"}
              type="text"
              defaultValue={keyword}
              readOnly
              key={indexK}
            />
          );
        });
      }
      if (value.geographical_terms.length > 0) {
        var uploadZ3950GeoTerms = value.geographical_terms.map(
          (geoterms, indexG) => {
            return (
              <input
                name={"biblioitems[" + index + "][geoterms][" + indexG + "]"}
                type="text"
                defaultValue={geoterms}
                readOnly
                key={indexG}
              />
            );
          }
        );
      }
      return (
        <div key={index} className="card no-side-borders">
          <div className="card-header series-accordion" id="headingOne">
            <h5 className="mb-0">
              <div className="row items-centered btn-link">
                <div
                  className="col-12 col-md-2 text-left title-t"
                  data-label="Titulli: "
                >
                  {TooltipComp.tooltipDisabledMobile(
                    "author" + index,
                    !value.title ? "-" : value.title
                  )}
                </div>
                <div className="col-12 col-md-2" data-label="Isbn / Issn: ">
                  {value.isbn || value.issn
                    ? value.isbn || " / " + value.issn || ""
                    : "- / -"}
                </div>
                <div
                  className="col-12 col-md-2 text-center"
                  data-label="Autor: "
                >
                  {value.authors.length > 0 ? listAuthors.join("; ") : "-"}
                </div>
                <div
                  className="col-12 col-md-2 text-center"
                  data-label="Botues: "
                >
                  {!value.publisher_name ? "-" : value.publisher_name}
                </div>
                <div
                  className="col-12 col-md-2 text-center"
                  data-label="Gjuha: "
                >
                  {!value.language_name ? "-" : value.language_name}
                </div>
                <div className="col-12 col-md-2 text-center">
                  <button
                    type="button"
                    className="btn-unstyled"
                    data-toggle="collapse"
                    data-target={"#nrEntry-" + index}
                    aria-expanded="true"
                    aria-controls={"nrEntry-" + index}
                  >
                    <img src={editIcon} alt="edit" />
                  </button>
                </div>
              </div>
            </h5>
          </div>
          <div
            id={"nrEntry-" + index}
            className="collapse series-div"
            aria-labelledby={"nrEntry-" + index}
            data-parent="#accordion"
          >
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="pb-4 mb-3">
                    <label>Titulli:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".title") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][title]"}
                      type="text"
                      defaultValue={value.title}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][title]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".title") &&
                      this.state.errors.error[bibliotitem] && (
                        <Fragment>
                          <span className="error-message">
                            {this.state.errors.error[bibliotitem]}
                          </span>
                          {this.setState({
                            errors: {
                              error: true + index,
                            },
                          })}
                        </Fragment>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Medium:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".medium") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][medium]"}
                      type="text"
                      defaultValue={value.medium}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][medium]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".medium") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Nëntitulli:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".subtitle") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][subtitle]"}
                      type="text"
                      defaultValue={value.subtitle}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][subtitle]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".subtitle") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Numri i pjesës:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem =
                          "biblioitems." + index + ".part_number") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][part_number]"}
                      type="text"
                      defaultValue={value.part_number}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][part_number]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".part_number") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Emri i pjesës:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem =
                          "biblioitems." + index + ".part_name") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][part_name]"}
                      type="text"
                      defaultValue={value.part_name}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][part_name]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".part_name") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Shënime:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".notes") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][notes]"}
                      type="text"
                      defaultValue={value.notes}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][notes]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".notes") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Përmbledhje:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".abstract") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][abstract]"}
                      type="text"
                      defaultValue={value.abstract}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][abstract]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".abstract") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Lloji i materialit:</label>
                    <Select
                      className={
                        "basic-select" +
                        ((bibliotitem =
                          "biblioitems." + index + ".materialtype_id") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      classNamePrefix="select_input"
                      name={"biblioitems[" + index + "][materialtype_id]"}
                      placeholder="Zgjidh"
                      onChange={this.handleInputS(
                        "biblioitems[" + index + "][materialtype_id]"
                      )}
                      isSearchable={false}
                      options={materials}
                    />
                    {(bibliotitem =
                      "biblioitems." + index + ".materialtype_id") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="pb-4 mb-3">
                    <label>Gjuha:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem =
                          "biblioitems." + index + ".language_id") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][language_id]"}
                      type="text"
                      defaultValue={value.language_id}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][language_id]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".language_id") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Isbn:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".isbn") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][isbn]"}
                      type="text"
                      defaultValue={value.isbn}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][isbn]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".isbn") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Issn:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".issn") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][issn]"}
                      type="text"
                      defaultValue={value.issn}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][issn]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".issn") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Viti i Botimit:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem =
                          "biblioitems." + index + ".publication_year") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][publication_year]"}
                      type="number"
                      defaultValue={value.publication_year}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][publication_year]"
                      )}
                    />
                    {(bibliotitem =
                      "biblioitems." + index + ".publication_year") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Detajet fizike:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem =
                          "biblioitems." + index + ".physical_details") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][physical_details]"}
                      type="text"
                      defaultValue={value.physical_details}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][physical_details]"
                      )}
                    />
                    {(bibliotitem =
                      "biblioitems." + index + ".physical_details") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Numri i faqeve:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".pages") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][pages]"}
                      type="text"
                      defaultValue={value.pages}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][pages]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".pages") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Dimensionet fizike te materialit:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem =
                          "biblioitems." + index + ".dimensions") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][dimensions]"}
                      type="text"
                      defaultValue={value.dimensions}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][dimensions]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".dimensions") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Vendi i botimit:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".place") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][place]"}
                      type="text"
                      defaultValue={value.place}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][place]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".place") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="pb-4 mb-3">
                    <label>Çmimi (Lekë):</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".price") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][price]"}
                      type="number"
                      defaultValue={value.price}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][price]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".price") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Monedha:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".currency") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][currency]"}
                      type="text"
                      defaultValue={value.currency}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][currency]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".currency") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Klasifikimi dhjetor universal:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".kdu") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][kdu]"}
                      type="text"
                      defaultValue={value.kdu}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][kdu]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".kdu") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Numri i kontrollit kombëtar:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem =
                          "biblioitems." +
                          index +
                          ".national_control_number") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={
                        "biblioitems[" + index + "][national_control_number]"
                      }
                      type="text"
                      defaultValue={value.national_control_number}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][national_control_number]"
                      )}
                    />
                    {(bibliotitem =
                      "biblioitems." + index + ".national_control_number") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Vëllimi</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem =
                          "biblioitems." + index + ".publication_number") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][publication_number]"}
                      type="text"
                      defaultValue={value.publication_number}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][publication_number]"
                      )}
                    />
                    {(bibliotitem =
                      "biblioitems." + index + ".publication_number") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Audienca:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem =
                          "biblioitems." + index + ".agerestriction") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][agerestriction]"}
                      type="text"
                      defaultValue={value.agerestriction}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][agerestriction]"
                      )}
                    />
                    {(bibliotitem =
                      "biblioitems." + index + ".agerestriction") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>Emri i Botuesit:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem =
                          "biblioitems." + index + ".publisher_id") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][publisher_id]"}
                      type="text"
                      defaultValue={value.publisher_id}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][publisher_id]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".publisher_id") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                  <div className="pb-4 mb-3">
                    <label>LCCN:</label>
                    <input
                      className={
                        "input-form-input" +
                        ((bibliotitem = "biblioitems." + index + ".lccn") &&
                        this.state.errors.error[bibliotitem]
                          ? " form-error"
                          : "")
                      }
                      name={"biblioitems[" + index + "][lccn]"}
                      type="text"
                      defaultValue={value.lccn}
                      onChange={this.handleInput(
                        "biblioitems[" + index + "][lccn]"
                      )}
                    />
                    {(bibliotitem = "biblioitems." + index + ".lccn") &&
                      this.state.errors.error[bibliotitem] && (
                        <span className="error-message">
                          {this.state.errors.error[bibliotitem]}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="pb-4 mb-3 or-bg pl-0 pr-0 confirm-Z3950-records">
                    <label>Fjalë kyçe:</label>
                    <div className="fake-textarea">
                      {uploadZ3950Keyword}{" "}
                      {!uploadZ3950Keyword && "Nuk ka fjalë kyçe"}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="pb-4 mb-3 or-bg pl-0 pr-0 confirm-Z3950-records">
                    <label>Termat gjeografik:</label>
                    <div className="fake-textarea">
                      {uploadZ3950GeoTerms}
                      {!uploadZ3950GeoTerms && "Nuk ka term gjeografik"}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="pb-4 mb-3 py-0 pr-0 confirm-marc-records">
                    <label>Frekuenca:</label>
                    {value.frequence_id ? (
                      <Fragment>
                        <input
                          className={
                            "input-form-input" +
                            ((bibliotitem =
                              "biblioitems." + index + ".frequence_id") &&
                            this.state.errors.error[bibliotitem]
                              ? " form-error"
                              : "")
                          }
                          name={"biblioitems[" + index + "][frequence_id]"}
                          type="text"
                          defaultValue={value.frequence_id}
                          onChange={this.handleInput(
                            "biblioitems[" + index + "][frequence_id]"
                          )}
                        />
                        {(bibliotitem =
                          "biblioitems." + index + ".frequence_id") &&
                          this.state.errors.error[bibliotitem] && (
                            <span className="error-message">
                              {this.state.errors.error[bibliotitem]}
                            </span>
                          )}
                      </Fragment>
                    ) : (
                      <div className="input-form-input d-flex align-items-center">
                        Nuk ka frekuencë
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="pb-4 mb-3">
                    <label>Autori kryesor:</label>
                    <div className="row">
                      {!uploadZ3950Author ? (
                        <div className="col-12">
                          <div className="input-form-input py-2">
                            Nuk ka autor kryesor
                          </div>
                        </div>
                      ) : (
                        uploadZ3950Author
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="pb-4 mb-3">
                    <label>Personat përgjegjës:</label>
                    <div className="row">
                      {!uploadZ3950Contributor ? (
                        <div className="col-12">
                          <div className="input-form-input py-2">
                            Nuk ka persona përgjegjës
                          </div>
                        </div>
                      ) : (
                        uploadZ3950Contributor
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        <div className="small-offset-left">
          <p className="partial-title mb-5">Konfirmo rekordet Z39.50 </p>
          <form onSubmit={this.importZ3950}>
            <div className="row mb-5 m-0 marc-confirm-records">
              <div className="col-12 mb-0 p-0">
                <div className="row background-darkblue">
                  <div className="col-2 d-flex justify-content-start">
                    Titulli
                  </div>
                  <div className="col-2">Isbn/Issn</div>
                  <div className="col-2">Autor</div>
                  <div className="col-2">Botues</div>
                  <div className="col-2">Gjuha</div>
                  <div className="col-2">Ndrysho</div>
                </div>
              </div>
              <div className="col-12 pl-0 pr-0">
                <div id="accordion">
                  <div className="import--marc--file">{uploadedZ3950List}</div>
                </div>
              </div>
            </div>
            <div className="mb-5">
              {this.state.btnLoading === true ? (
                <button type="button" className="btn-styled-2 px-5">
                  Duke u Ruajtur
                  <img
                    src={LoadingGif}
                    style={{
                      width: "18px",
                      margin: "-4px 0 0 7px",
                    }}
                  />
                </button>
              ) : (
                <Button type="submit" className="btn-styled-2 px-5">
                  Ruaj
                </Button>
              )}
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ConfirmZ3950);
