import React, { Component, Fragment } from "react";
import Request from "../../../api/Request";
import Url from "../../../api/Url";
import "../../../assets/css/opac-profile.css";
import Messages from "../../../helpers/Messages";
import UserData from "./UserData";
import LoadingGif from "../../../assets/images/icons/loading.gif";

class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      changePassword: {
        current_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
    };
  }

  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.changePassword,
      [key]: e.target.value,
    };
    this.setState({
      changePassword: newForm,
    });
  };

  changePassword = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    Request.postRequest(Url.changeUserPassword, this.state.changePassword).then(
      (response) => {
        this.setState({ btnLoading: false });
        if (response.status === 200) {
          this.setState({
            changePassword: {
              current_password: "",
              new_password: "",
              new_password_confirmation: "",
            },
            form_errors: {
              error_list: [],
            },
          });
          Messages.swalSuccess(response.data.success);
        } else if (response.status === 404) {
          Messages.swalError(response.data.error);
        } else if (response.status === 422) {
          this.setState({
            form_errors: {
              error_list: response.data,
            },
          });
        }
      }
    );
  };
  render() {
    return (
      <Fragment>
        <div id="profile" className="row m-0 mb-4 pl-md-3 pl-md-0 pl-1">
          <UserData />
          <div className="col-lg-10 col-12 mt-5">
            <div className="container-fluid small-offset-left">
              <div className="change-password">
                <p className="partial-title mb-5">Ndrysho fjalëkalimin</p>
                <form onSubmit={this.changePassword}>
                  <div className="row">
                    <div className="pb-4 mb-4 col-12 col-sm-6 col-xl-3">
                      <label for="current_password">Fjalëkalimi aktual:</label>
                      <input
                        placeholder="******"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.current_password
                            ? " form-error"
                            : "")
                        }
                        name="current_password"
                        type="password"
                        id="current_password"
                        value={this.state.changePassword.current_password}
                        onChange={this.handleChange("current_password")}
                      />
                      {this.state.form_errors.error_list.current_password && (
                        <span className="error-message left">
                          {
                            this.state.form_errors.error_list
                              .current_password[0]
                          }
                        </span>
                      )}
                    </div>
                    <div className="pb-4 mb-4 col-12 col-sm-6 col-xl-3">
                      <label for="new_password">Fjalëkalimi i ri:</label>
                      <input
                        placeholder="******"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.new_password
                            ? " form-error"
                            : "")
                        }
                        name="new_password"
                        type="password"
                        id="new-password"
                        value={this.state.changePassword.new_password}
                        onChange={this.handleChange("new_password")}
                      />
                      {this.state.form_errors.error_list.new_password && (
                        <span className="error-message left">
                          {this.state.form_errors.error_list.new_password[0]}
                        </span>
                      )}
                    </div>
                    <div className="pb-4 mb-4 col-12 col-sm-6 col-xl-3">
                      <label for="new_password_confirmation">
                        Konfirmo fjalëkalimin e ri:
                      </label>
                      <input
                        placeholder="******"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list
                            .new_password_confirmation
                            ? " form-error"
                            : "")
                        }
                        name="new-password_confirmation"
                        type="password"
                        value={
                          this.state.changePassword.new_password_confirmation
                        }
                        onChange={this.handleChange(
                          "new_password_confirmation"
                        )}
                        id="new-password_confirmation"
                      />
                      {this.state.form_errors.error_list
                        .new_password_confirmation && (
                        <span className="error-message left">
                          {
                            this.state.form_errors.error_list
                              .new_password_confirmation[0]
                          }
                        </span>
                      )}
                    </div>
                    <div className="pb-4 mb-4 col-12 col-sm-6 col-xl-3 d-flex">
                      {this.state.btnLoading === true ? (
                        <button className="btn-styled-2 mt-auto px-5">
                          Duke u Ndryshuar
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                        </button>
                      ) : (
                        <button className="btn-styled-2 mt-auto px-5">
                          Ndrysho
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ChangePassword;
