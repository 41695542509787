import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import ConfirmRecords from '../../../../components/administrator/catalog/z3950/ConfirmRecords';
import Header from '../../../../components/layouts/Header';
import Sidebar from '../../../../components/layouts/Sidebar';

class ConfirmZ3950View extends Component {
  render() {
    return (
      <Fragment>
        <Sidebar
          activeLink={'catalog'}
          roleUser={this.props.role}
          firstName={this.props.firstName}
          lastName={this.props.lastName}
        />
        <div className="w-100" id="content1">
          <Helmet>
            <title>Konfirmo rekordet Z39.50</title>
          </Helmet>
          <Header
            roleUser={this.props.role}
            firstName={this.props.firstName}
            lastName={this.props.lastName}
          />
          <ConfirmRecords />
        </div>
      </Fragment>
    );
  }
}

export default ConfirmZ3950View;
