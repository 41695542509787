import React, { Component, Fragment } from "react";
import Request from "../../../api/Request";
import Url from "../../../api/Url";
import AppConfig from "../../../services/AppConfig";
import loadingGif from "../../../assets/images/icons/loading.gif";
import Libra from "../../../assets/images/covers/Libra.svg";
import "../../../assets/css/opac_modules.css";
import { Link, withRouter } from "react-router-dom";
import Web from "../../../routes/Web";
import moment from "moment";
import "moment/locale/sq";
import { Carousel } from "react-bootstrap";
import Messages from "../../../helpers/Messages";
class MostReadBooks extends Component {
  constructor() {
    super();
    this.state = {
      dataMostReadBooks: {
        mostReadBooks: [],
        loading: true,
      },
    };
  }

  componentDidMount() {
    this.mostReadBooks();
  }

  mostReadBooks = () => {
    let request = Url.mostReadBooks + 0;
    Request.getRequest(request).then((response) => {
      if (response) {
        if (response.status === 200) {
          this.setState({
            dataMostReadBooks: {
              mostReadBooks: response.data.most_read_books,
              loading: false,
            },
          });
        }
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };
  render() {
    var Slider1 = "";
    var Slider2 = "";
    var Slider3 = "";
    if (this.state.dataMostReadBooks.loading === true) {
      Slider1 = (
        <img className="m-auto d-block mb-5" src={loadingGif} alt="loading" />
      );
    } else {
      var firstSlide = this.state.dataMostReadBooks.mostReadBooks.slice(0, 6);
      Slider1 = firstSlide.map((book, index) => {
        const Authors = book.biblio.authors.map((author) => {
          return author.name;
        });
        var cover = null;
        if (book.cover !== null) {
          cover = AppConfig.biblioCoversPath + book.cover;
        } else {
          cover = Libra;
        }

        return (
          <Fragment key={index}>
            <Link
              to={{
                pathname: Web.detailed + book.id,
                state: { prevUrl: this.props.history.location.pathname },
              }}
              className="book"
            >
              <img
                className="carousel_img"
                src={cover}
                alt={book.biblio.title}
              />
              <Carousel.Caption className="details">
                <h3>{book.biblio.title}</h3>
                <h2>{Authors.join("; ")}</h2>
              </Carousel.Caption>
            </Link>
          </Fragment>
        );
      });
      var secondSlide = this.state.dataMostReadBooks.mostReadBooks.slice(6, 12);
      Slider2 = secondSlide.map((book, index) => {
        const Authors = book.biblio.authors.map((author) => {
          return author.name;
        });
        var cover = null;
        if (book.cover !== null) {
          cover = AppConfig.biblioCoversPath + book.cover;
        } else {
          cover = Libra;
        }

        return (
          <Fragment key={index}>
            <Link
              to={{
                pathname: Web.detailed + book.id,
                state: { prevUrl: this.props.history.location.pathname },
              }}
              className="book"
            >
              <img className="carousel_img" src={cover} alt="Book cover" />
              <Carousel.Caption className="details">
                <h3>{book.biblio.title}</h3>
                <h2>{Authors.join("; ")}</h2>
              </Carousel.Caption>
            </Link>
          </Fragment>
        );
      });
      var thirdSlide = this.state.dataMostReadBooks.mostReadBooks.slice(12, 18);
      Slider3 = thirdSlide.map((book, index) => {
        const Authors = book.biblio.authors.map((author) => {
          return author.name;
        });
        var cover = null;
        if (book.cover !== null) {
          cover = AppConfig.biblioCoversPath + book.cover;
        } else {
          cover = Libra;
        }

        return (
          <Fragment key={index}>
            <Link
              to={{
                pathname: Web.detailed + book.id,
                state: { prevUrl: this.props.history.location.pathname },
              }}
              className="book"
            >
              <img className="carousel_img" src={cover} alt="Book cover" />
              <Carousel.Caption className="details">
                <h3>{book.biblio.title}</h3>
                <h2>{Authors.join("; ")}</h2>
              </Carousel.Caption>
            </Link>
          </Fragment>
        );
      });
    }

    let screenWidth = window.innerWidth;

    return (
      <Fragment>
        {this.state.dataMostReadBooks.mostReadBooks.length !== 0 && (
          <div className="row mx-0 most-read-books-module">
            <div className="most-read-books">
              <div className="category_hidden">
                <span className="pb-3">
                  {moment().locale("sq").format("DD MMMM, yyyy")}
                </span>
                <h1 className="pt-3">Librat më të lexuar</h1>
                <Link to={Web.most_read_books} className="btn-front mt-4">
                  Shiko më shumë
                </Link>
              </div>
              <div className="category_books">
                <Carousel fade={true}>
                  <Carousel.Item className="slide-item">
                    {Slider1}
                  </Carousel.Item>
                  {this.state.dataMostReadBooks.mostReadBooks.length > 6 &&
                    screenWidth > 768 && (
                      <Carousel.Item className="slide-item">
                        {Slider2}
                      </Carousel.Item>
                    )}
                  {this.state.dataMostReadBooks.mostReadBooks.length > 12 &&
                    screenWidth > 768 && (
                      <Carousel.Item className="slide-item">
                        {Slider3}
                      </Carousel.Item>
                    )}
                </Carousel>
                <Link
                  to={Web.most_read_books}
                  className="btn-front mt-4 d-md-none mx-auto"
                >
                  Shiko më shumë
                </Link>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withRouter(MostReadBooks);
