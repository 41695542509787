import React, { Component, createRef, Fragment } from "react";
import Url from "../../../api/Url";
import Messages from "../../../helpers/Messages";
import Request from "../../../api/Request";
import Web from "../../../routes/Web";
import { withRouter } from "react-router-dom";
import FormData from "form-data";
import { Editor } from "@tinymce/tinymce-react";
import LoadingGif from "../../../assets/images/icons/loading.gif";

class CreatePost extends Component {
  constructor() {
    super();
    this.editorRef = createRef();
    this.state = {
      title: "",
      intro_image: "",
      temporary_img_path: null,
      description: "",
      error_list: [],
      btnLoading: false,
    };
  }

  getEditorContent = () => {
    this.setState({
      description: this.editorRef.current.getContent(),
    });
  };

  handleFormInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  fileSelectHandler = (event) => {
    if (event.target.files[0]) {
      this.setState({
        intro_image: event.target.files[0],
        temporary_img_path: URL.createObjectURL(event.target.files[0]),
      });
    }
  };

  removeImage = () => {
    this.setState({
      temporary_img_path: null,
    });
  };

  savePost = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const formData = new FormData();
    formData.append("intro_image", this.state.intro_image);
    formData.append("title", this.state.title);
    formData.append("description", this.state.description);

    Request.postRequest(Url.storeBlogPost, formData).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 201) {
        this.props.history.push(Web.listBlogPosts);
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 422) {
        this.setState({
          error_list: response.data,
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div className="create-article">
            <p className="partial-title mb-5">Krijo një artikull të ri</p>
            <form onSubmit={this.savePost}>
              <div className="row flex-column">
                <div className="col-12 col-sm-6 pb-4 mb-2">
                  <label htmlFor="title">Titull</label>
                  <input
                    type="text"
                    onChange={this.handleFormInput}
                    className={
                      "input-form-input" +
                      (this.state.error_list.title ? " form-error" : "")
                    }
                    id="title"
                    name="title"
                    value={this.state.title}
                    placeholder="Titulli i artikullit"
                  />
                  {this.state.error_list.title && (
                    <span className="error-message left">
                      {this.state.error_list.title}
                    </span>
                  )}
                </div>

                <div className="col-12 col-sm-6 pb-4 mb-2">
                  <div className="file-upload">
                    <label htmlFor="intro_image">Imazhi i artikullit</label>

                    <div className="cover-img w-100">
                      <div className="file-upload1">
                        {this.state.temporary_img_path == null && (
                          <div
                            className="image-upload-wrap1"
                            id="customInputImg"
                            style={{ display: "block" }}
                          >
                            <input
                              placeholder="Imazhi i artikullit"
                              className={
                                "file-upload-input1" +
                                (this.state.error_list.intro_image
                                  ? " form-error"
                                  : "")
                              }
                              accept="image/*"
                              onChange={this.fileSelectHandler}
                              name="intro_image"
                              type="file"
                              id="intro_image"
                            />
                            <div className="drag-text1">
                              <h3>Imazhi i artikullit</h3>
                            </div>
                          </div>
                        )}
                        {this.state.temporary_img_path !== null && (
                          <div
                            className="file-upload-content"
                            style={{ display: "block" }}
                          >
                            <img
                              className="file-upload-image"
                              src={this.state.temporary_img_path}
                              alt="Imazhi i artikullit"
                            />
                            <button
                              type="button"
                              onClick={this.removeImage}
                              className="remove-image btn-unstyled"
                            >
                              ✕
                            </button>
                          </div>
                        )}
                      </div>
                      {this.state.error_list.intro_image && (
                        <span className="error-message left">
                          {this.state.error_list.intro_image[0]}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 pb-4 mb-2">
                  <label htmlFor="description">Përshkrimi</label>
                  <div
                    className={
                      this.state.error_list.description ? " form-error" : ""
                    }
                  >
                    <Editor
                      onChange={this.getEditorContent}
                      onInit={(evt, editor) =>
                        (this.editorRef.current = editor)
                      }
                      initialValue=""
                      init={{
                        height: 500,
                        plugins: [
                          "advlist autolink link image lists charmap print preview hr anchor pagebreak",
                          "searchreplace wordcount visualblocks code fullscreen insertdatetime media nonbreaking",
                          "table emoticons template paste help",
                        ],
                        toolbar:
                          "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | " +
                          "bullist numlist outdent indent | link image | print preview media fullpage | " +
                          "forecolor backcolor emoticons | help",
                        menu: {
                          favs: {
                            title: "My Favorites",
                            items: "code visualaid | searchreplace | emoticons",
                          },
                        },
                        menubar:
                          "favs file edit view insert format tools table help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </div>
                  {this.state.error_list.description && (
                    <span className="error-message left">
                      {this.state.error_list.description[0]}
                    </span>
                  )}
                </div>

                <div className="col-12 col-sm-12 pb-4 mt-3">
                  {this.state.btnLoading === true ? (
                    <button className="btn-styled-2" type="button">
                      Duke u Ruajtur
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button className="btn-styled-2" type="submit">
                      Ruaj
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(CreatePost);
