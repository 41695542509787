import React, { Component, Fragment } from "react";
import News from "../../components/opac/News";
import Header from "../../components/opac/partials/Header";
import "../../assets/css/frontend1.css";
import { Helmet } from "react-helmet";
import Footer from "../../components/opac/partials/Footer";
class NewsView extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Lajme</title>
        </Helmet>
        <div style={{ width: 100 + "%" }}>
          <Header
            roleUser={this.props.roles}
            title={"Lajme"}
            desc={'"Biblioteka nuk është luks por nevojë jetësore"'}
          />
          <News />
          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default NewsView;
