import React, { Component, Fragment } from 'react';
import Request from '../../api/Request';
import Url from '../../api/Url';
import { Link, withRouter } from 'react-router-dom';
import Web from '../../routes/Web';
import AppConfig from '../../services/AppConfig';
import LoadingGif from '../../assets/images/icons/loading.gif';
import { BiUser } from 'react-icons/bi';
import { FiLock } from 'react-icons/fi';
import { AiOutlineEye } from 'react-icons/ai';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import Routes from '../../routes/Routes';
class Login extends Component {
  constructor() {
    super();
    this.state = {
      login: {
        userData: { email: '', password: '', remember: 0 },
        showPassword: false,
        showLogin: false,
        errorList: [],
      },
      ems: {
        userEms: {
          username: '',
          password: '',
        },
        btnLoading: false,
        btnEmsLoading: false,
        showPassword: false,
        showLogin: false,
        errorList: [],
      },
    };
  }

  onInputchange = (e) => {
    this.setState({
      ...this.state,
      login: {
        ...this.state.login,
        userData: {
          ...this.state.login.userData,
          [e.target.name]: e.target.value,
        },
      },
    });
  };
  onInputChangeCheckbox = (e) => {
    if (e.target.checked === true) {
      this.setState({
        ...this.state,
        login: {
          ...this.state.login,
          userData: {
            ...this.state.login.userData,
            [e.target.name]: 1,
          },
        },
      });
    } else {
      this.setState({
        ...this.state,
        login: {
          ...this.state.login,
          userData: {
            ...this.state.login.userData,
            [e.target.name]: 0,
          },
        },
      });
    }
  };

  onInputchangeEms = (e) => {
    this.setState({
      ...this.state,
      ems: {
        ...this.state.ems,
        userEms: {
          ...this.state.ems.userEms,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  submitLoginHandler = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    Request.postRequestLogin(Url.login, this.state.login.userData).then(
      (result) => {
        this.setState({ btnLoading: false });
        if (result.status === 200) {
          AppConfig.setAccessToken(result.data.token);
          AppConfig.setRole(result.data.user.role.name);
          this.setState({
            login: {
              ...this.state.login,
              userData: { email: '', password: '' },
              errorList: [],
            },
          });
          if (
            sessionStorage.getItem('path') &&
            Routes.PrivateRoutes().find((e) => {
              if (
                e.path.includes(
                  sessionStorage
                    .getItem('path')
                    .split('/')
                    .slice(0, 3)
                    .join('/')
                ) &&
                e.access.includes(result.data.user.role.name)
              ) {
                return true;
              } else {
                return false;
              }
            })
          ) {
            window.location.href = sessionStorage.getItem('path');
          } else {
            window.location.href = Web.dashboard;
          }
        } else if (result.status === 401) {
          this.setState({
            login: {
              ...this.state.login,
              errorList: result.data,
            },
          });
        } else if (result.status === 422) {
          this.setState({
            login: {
              ...this.state.login,
              errorList: result.data,
            },
          });
        } else if (result.status === 403) {
          this.setState({
            login: {
              ...this.state.login,
              errorList: result.data,
            },
          });
        }
      }
    );
  };

  submitEmsLoginHandler = (e) => {
    e.preventDefault();
    this.setState({ btnEmsLoading: true });
    Request.postRequestLogin(Url.emslogin, this.state.ems.userEms).then(
      (result) => {
        this.setState({ btnEmsLoading: false });
        if (result.status === 200) {
          AppConfig.setAccessToken(result.data.token);
          AppConfig.isLogged(result.data.token);
          this.setState({
            ems: {
              ...this.state.ems,
              userEms: { username: '', password: '' },
              errorList: [],
            },
          });
          window.location.href = Web.opacProfile;
        } else if (result.status === 401) {
          this.setState({
            ems: {
              ...this.state.ems,
              errorList: result.data,
            },
          });
        } else if (result.status === 422) {
          this.setState({
            ems: {
              ...this.state.ems,
              errorList: result.data,
            },
          });
        } else if (result.status === 403) {
          this.setState({
            ems: {
              ...this.state.ems,
              errorList: result.data,
            },
          });
        } else if (result.status === 500) {
          this.setState({
            ems: {
              ...this.state.ems,
              errorList: result.data,
            },
          });
        }
      }
    );
  };

  identifyClicked(e, param) {
    if (param === 'login') {
      this.setState({
        login: {
          ...this.state.login,
          showLogin: true,
        },
        ems: {
          ...this.state.ems,
          showLogin: false,
        },
      });
    } else if (param === 'ems_login') {
      this.setState({
        login: {
          ...this.state.login,
          showLogin: false,
        },
        ems: {
          ...this.state.ems,
          showLogin: true,
        },
      });
    }
  }

  showPassword(e, param) {
    if (param === 'login') {
      if (this.state.login.showPassword === false) {
        this.setState({
          login: {
            ...this.state.login,
            showPassword: true,
          },
        });
      } else if (this.state.login.showPassword === true) {
        this.setState({
          login: {
            ...this.state.login,
            showPassword: false,
          },
        });
      }
    } else if (param === 'emsLogin') {
      if (this.state.ems.showPassword === false) {
        this.setState({
          ems: {
            ...this.state.ems,
            showPassword: true,
          },
        });
      } else if (this.state.ems.showPassword === true) {
        this.setState({
          ems: {
            ...this.state.ems,
            showPassword: false,
          },
        });
      }
    }
  }

  render() {
    return (
      <Fragment>
        <div id="accordion" className="form-logins-b">
          <div className="login-b-r l-staf col-12 col-sm-9 col-md-10 col-xl-8">
            <h3 className="col-12 col-sm-12 col-lg-12 col-xl-10 mx-auto">
              Identifikohu në sistemin e bibliotekës
            </h3>
            <p className="col-12 col-sm-12 col-lg-12 col-xl-10 mx-auto ems-note">
              Nëse jeni punonjës i bibliotekës të Universitetit Aleksandër
              Xhuvani,identifikohuni nëpërmjet kredencialeve që përdorni në
              sistem
            </p>
            <div
              id="login"
              className="collapse series-div hidden-login"
              aria-labelledby="login"
              data-parent="#accordion"
            >
              <form
                className="col-12 col-sm-12 col-lg-12 col-xl-10 mx-auto"
                onSubmit={this.submitLoginHandler}
              >
                <div className="form-group position-relative pb-4 mb-2">
                  <input
                    type="text"
                    name="email"
                    placeholder="Emaili juaj"
                    className={
                      'input-form-input-log-b' +
                      (this.state.login.errorList.email ||
                      this.state.login.errorList.error
                        ? ' form-error'
                        : '')
                    }
                    id="email"
                    onChange={this.onInputchange}
                  />
                  <BiUser className="email-icon-b" />
                  {this.state.login.errorList.email && (
                    <span className="error-message">
                      {this.state.login.errorList.email[0]}
                    </span>
                  )}
                  {this.state.login.errorList.error && (
                    <span className="error-message error-login">
                      {this.state.login.errorList.error}
                    </span>
                  )}
                  {this.state.login.wrongCredentials && (
                    <span className="error-message">
                      {this.state.login.wrongCredentials}
                    </span>
                  )}
                </div>
                <div className="form-group position-relative pb-4 mb-2">
                  <input
                    type={
                      this.state.login.showPassword === false
                        ? 'password'
                        : 'text'
                    }
                    name="password"
                    placeholder="Fjalëkalimi juaj"
                    className={
                      'input-form-input-log-b' +
                      (this.state.login.errorList.password ||
                      this.state.login.errorList.error
                        ? ' form-error'
                        : '')
                    }
                    id="password"
                    autoComplete={'off'}
                    onChange={this.onInputchange}
                  />
                  <FiLock className="email-icon-b" />
                  <div onClick={(e) => this.showPassword(e, 'login')}>
                    {this.state.login.showPassword === false ? (
                      <AiOutlineEyeInvisible className="show-icon-b" />
                    ) : (
                      <AiOutlineEye className="show-icon-b" />
                    )}
                  </div>
                  {this.state.login.errorList.password && (
                    <span className="error-message">
                      {this.state.login.errorList.password[0]}
                    </span>
                  )}
                </div>
                <div className="form-check" id="form-check-b">
                  <div className="form-check-remember custom-checkbox ">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      name="remember"
                      id="remember"
                      onChange={this.onInputChangeCheckbox}
                    />
                    <label
                      className="custom-control-label chechbox-elt remember-me-b"
                      htmlFor="remember"
                    >
                      Më mbaj mend
                    </label>
                    <span></span>
                  </div>
                  <div>
                    <Link to={Web.forgotPassword}>
                      Keni harruar fjalekalimin?
                    </Link>
                  </div>
                </div>
                <div className="form-group">
                  {this.state.btnLoading === true ? (
                    <button
                      type="button"
                      className="login_btn mt-2"
                      name="login"
                    >
                      Duke u identifikuar
                      <img
                        src={LoadingGif}
                        style={{
                          width: '18px',
                          margin: '-4px 0 0 7px',
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="login_btn mt-2"
                      name="login"
                    >
                      Identifikohu
                    </button>
                  )}
                </div>
              </form>
            </div>
            {this.state.login.showLogin === false && (
              <div className="show-login-hide-me col-12 col-sm-12 col-lg-12 col-xl-10 mx-auto position-relative">
                <button
                  className="login_btn"
                  data-toggle="collapse"
                  data-target="#login"
                  aria-expanded="false"
                  aria-controls="login"
                  onClick={(e) => this.identifyClicked(e, 'login')}
                >
                  Identifikohu
                </button>
              </div>
            )}
          </div>
          <div className="login-b-r l-ems col-12 col-sm-9 col-md-10 col-xl-8">
            <h3 className="col-12 col-sm-12 col-lg-12 col-xl-10  mx-auto">
              Identifikohu nëpërmjet EMS
            </h3>
            <p className="col-12 col-sm-12 col-lg-12 col-xl-10  mx-auto ems-note">
              Nëse jeni student ose staf akademik i Universitetit Aleksandër
              Xhuvani,identifikohuni nëpërmjet kredencialeve që përdorni në
              sistemin EMS
            </p>
            <div
              id="login-ems"
              className="collapse series-div hidden-login-ems"
              aria-labelledby="login-ems"
              data-parent="#accordion"
            >
              <form
                className="col-12 col-sm-12 col-lg-12 col-xl-10 mx-auto"
                onSubmit={this.submitEmsLoginHandler}
              >
                <div className="form-group position-relative pb-4 mb-2">
                  <input
                    type="text"
                    name="username"
                    placeholder="Emri i përdoruesit"
                    onChange={this.onInputchangeEms}
                    className={
                      'input-form-input-log-b ems-username' +
                      (this.state.ems.errorList.error ? ' form-error' : '')
                    }
                    id="username"
                  />
                  <BiUser className="email-icon-b" />
                  {this.state.ems.errorList && (
                    <span className="error-message">
                      {this.state.ems.errorList.error}
                    </span>
                  )}
                </div>
                <div className="form-group position-relative pb-4 mb-2">
                  <input
                    type={
                      this.state.ems.showPassword === false
                        ? 'password'
                        : 'text'
                    }
                    name="password"
                    placeholder="Fjalëkalimi juaj"
                    onChange={this.onInputchangeEms}
                    className={
                      'input-form-input-log-b' +
                      (this.state.ems.errorList.error ? ' form-error' : '')
                    }
                    autoComplete={'off'}
                    id="password-ems"
                  />
                  <FiLock className="email-icon-b" />

                  <div onClick={(e) => this.showPassword(e, 'emsLogin')}>
                    {this.state.ems.showPassword === false ? (
                      <AiOutlineEyeInvisible className="show-icon-b" />
                    ) : (
                      <AiOutlineEye className="show-icon-b" />
                    )}
                  </div>
                </div>
                <div className="form-group">
                  {this.state.btnEmsLoading === true ? (
                    <button
                      type="button"
                      className="login_btn mt-2"
                      name="loginems"
                    >
                      Duke u identifikuar
                      <img
                        src={LoadingGif}
                        style={{
                          width: '18px',
                          margin: '-4px 0 0 7px',
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="login_btn mt-2"
                      name="loginems"
                    >
                      Identifikohu
                    </button>
                  )}
                </div>
              </form>
            </div>
            {this.state.ems.showLogin === false && (
              <div className="show-login-ems-hide-me col-12 col-sm-12 col-lg-12 col-xl-10 mx-auto position-relative">
                <button
                  className="login_btn"
                  data-toggle="collapse"
                  data-target="#login-ems"
                  aria-expanded="false"
                  aria-controls="login-ems"
                  onClick={(e) => this.identifyClicked(e, 'ems_login')}
                >
                  Identifikohu
                </button>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Login);
