class SearchFilter {

    static setFilterParams(searchParams,searchUrl){

        const searchURL = new URL(searchUrl);

        Object.keys(searchParams).forEach(function(key) {

            searchURL.searchParams.append(key,searchParams[key]);

        });

        return searchURL;
    }

}

export default SearchFilter;
