import React, { Component, Fragment } from "react";
import Url from "../../../api/Url";
import Request from "../../../api/Request";
import Web from "../../../routes/Web";
import Messages from "../../../helpers/Messages";
import { Link, withRouter } from "react-router-dom";
import AppConfig from "../../../services/AppConfig";
import loadingGif from "../../../assets/images/icons/loading.gif";
import moment from "moment";

class ShowStaff extends Component {
  constructor() {
    super();
    this.state = {
      staff: {
        details: "",
        role: "",
        loading: true,
      },
    };
  }

  componentDidMount() {
    this.staffDetails();
  }

  staffDetails = () => {
    const staffID = this.props.match.params.id;

    let request = Url.showStaff + staffID;

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          staff: {
            details: response.data.staff,
            role: response.data.staff.role,
            loading: false,
          },
        });
      } else if (response.status === 404) {
        this.props.history.push(Web.listStaff);
        Messages.swalSuccess(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  render() {
    var show = "";
    if (this.state.staff.loading) {
      show = <img className="ml-0 d-block" src={loadingGif} alt="loading" />;
    } else {
      show = (
        <ul>
          <li className="member-detail">
            Emër Mbiemër / Roli :{" "}
            {this.state.staff.details.first_name +
              " " +
              this.state.staff.details.last_name}{" "}
            / {this.state.staff.details.role.name}
          </li>
          <li>Email : {this.state.staff.details.email ? this.state.staff.details.email : '-'}</li>
          <li>Cel : {this.state.staff.details.phone ? this.state.staff.details.phone : '-'}</li>
          <li>Ditëlindje : {this.state.staff.details.dob ? (moment(this.state.staff.details.dob).format("DD-MM-yyy")) : '-'}</li>

          <li>
            Gjinia : {this.state.staff.details.gender ?  (this.state.staff.details.gender === "F" && "Femer") ||
            (this.state.staff.details.gender === "M" && "Mashkull") : '-' }
          </li>
          <li>
            Shteti / Qyteti : {this.state.staff.details.state ? this.state.staff.details.state : '-'} /{" "}
            {this.state.staff.details.city ? this.state.staff.details.city : '-'}
          </li>
          <li>
            Adresa / Kodi postar : {this.state.staff.details.address ? this.state.staff.details.address : '-'} /{" "}
            {this.state.staff.details.postal_code ? this.state.staff.details.postal_code : '-'}
          </li>
          <li></li>
        </ul>
      );
    }
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <p className="partial-title mb-5">Të dhënat e punonjësit</p>
          <div className="row mb-5">
            <div className="list-data col-12 col-md-8 d-flex">{show}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ShowStaff);
