import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import loadingGif from "../assets/images/icons/Ellipsis-loading.gif";
class LoadingView extends Component {
  render() {
    return (
      <Fragment>
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          id="content1"
          style={{ backgroundColor: `#e6f2ff` }}
        >
          <Helmet>
            <title>Loading...</title>
          </Helmet>
          <img src={loadingGif} />
        </div>
      </Fragment>
    );
  }
}

export default LoadingView;
