import React from "react";
import { Component, Fragment } from "react";
import Request from "../../../../api/Request";
import Url from "../../../../api/Url";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Messages from "../../../../helpers/Messages";
import LoadingGif from "../../../../assets/images/icons/loading.gif";

class EditFrequence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateFrequence: {
        description: this.props.description,
        unit: this.props.unit,
        issues_per_unit: this.props.issues_per_unit,
        units_per_issue: this.props.units_per_issue,
      },
      id: this.props.popupId,
      name: this.props.description,
      errorList: [],
      btnLoading: false,
    };
  }

  closeModal = () => {
    this.props.onPopupClose(false);
    let request = Url.editFrequence + this.props.popupId;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          updateFrequence: {
            description: response.data.frequence.description,
            unit: response.data.frequence.unit,
            issues_per_unit: response.data.frequence.issues_per_unit,
            units_per_issue: response.data.frequence.units_per_issue,
          },
          errorList: [],
        });
      }
    });
  };

  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.updateFrequence,
      [key]: e.target.value,
    };
    this.setState({
      updateFrequence: newForm,
    });
  };

  updateFrequence = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    let request = Url.updateFrequence;
    let frequenceId = this.state.id;
    Request.patchRequest(
      request + frequenceId,
      this.state.updateFrequence
    ).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.closeModal();
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 422) {
        this.setState({
          errorList: response.data,
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <Modal
          show={this.props.showEditFrequence}
          onHide={this.closeModal}
          centered
        >
          <Modal.Header>
            <Modal.Title>Ndrysho {this.state.name}</Modal.Title>
            <button
              className="btn-unstyled close-modal"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.closeModal}
            >
              ✕
            </button>
          </Modal.Header>
          <form onSubmit={this.updateFrequence}>
            <Modal.Body>
              <div className="row m-0 mb-2">
                <div className="col-12 pb-4">
                  <label htmlFor="description">Përshkrimi</label>
                  <input
                    placeholder="Përshkrimi..."
                    id="description"
                    name="description"
                    type="text"
                    value={this.state.updateFrequence.description}
                    onChange={this.handleChange("description")}
                    className={
                      "input-form-input" +
                      (this.state.errorList.description ? " form-error" : "")
                    }
                  />
                  {this.state.errorList.unit && (
                    <span className="error-message left">
                      {this.state.errorList.description}
                    </span>
                  )}
                </div>
              </div>
              <div className="row m-0 mb-2">
                <div className="col-12 pb-4">
                  <label htmlFor="unit">Njësia</label>
                  <input
                    placeholder="Njësia... "
                    id="unit"
                    name="unit"
                    type="text"
                    value={this.state.updateFrequence.unit}
                    onChange={this.handleChange("unit")}
                    className={
                      "input-form-input" +
                      (this.state.errorList.unit ? " form-error" : "")
                    }
                  />
                  {this.state.errorList.unit && (
                    <span className="error-message left">
                      {this.state.errorList.unit}
                    </span>
                  )}
                </div>
              </div>
              <div className="row m-0 mb-2">
                <div className="col-12 col-sm-6 pb-4">
                  <label htmlFor="issues_per_unit">Seri për njësi</label>
                  <input
                    id="issues_per_unit"
                    placeholder="Seri për njësi "
                    name="issues_per_unit"
                    type="number"
                    value={this.state.updateFrequence.issues_per_unit}
                    onChange={this.handleChange("issues_per_unit")}
                    className={
                      "input-form-input" +
                      (this.state.errorList.issues_per_unit
                        ? " form-error"
                        : "")
                    }
                  />
                  {this.state.errorList.issues_per_unit && (
                    <span className="error-message left">
                      {this.state.errorList.issues_per_unit}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 pb-4">
                  <label htmlFor="units_per_issue">Njësi për seri</label>
                  <input
                    id="units_per_issue"
                    placeholder="Njësi për seri "
                    name="units_per_issue"
                    type="number"
                    value={this.state.updateFrequence.units_per_issue}
                    onChange={this.handleChange("units_per_issue")}
                    className={
                      "input-form-input" +
                      (this.state.errorList.units_per_issue
                        ? " form-error"
                        : "")
                    }
                  />
                  {this.state.errorList.units_per_issue && (
                    <span className="error-message left">
                      {this.state.errorList.units_per_issue}
                    </span>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-12 d-flex m-0 p-0 justify-content-center">
                <div className="col-6 pl-0 pr-2 pr-sm-3 justify-content-center">
                  {this.state.btnLoading === true ? (
                    <button
                      className="btn-styled-2 w-100 text-center"
                      type="button"
                    >
                      Duke u Krijuar
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      className="btn-styled-2 w-100 text-center"
                      type="submit"
                    >
                      Krijo
                    </button>
                  )}
                </div>
                <div className="col-6 pr-0 pl-2 pl-sm-3">
                  <button
                    className="btn-styled-1 w-100 text-center"
                    type="button"
                    onClick={this.closeModal}
                  >
                    Anullo
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </Fragment>
    );
  }
}

export default withRouter(EditFrequence);
