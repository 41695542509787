import React, { Component, Fragment } from "react";
import Select from "react-select";
import Request from "../../../api/Request";
import Url from "../../../api/Url";
import { Link, withRouter } from "react-router-dom";
import SearchFilter from "../../../helpers/SearchFilter";
import Paginate from "../../../helpers/Paginate";
import loadingGif from "../../../assets/images/icons/loading.gif";
import Web from "../../../routes/Web";
import noResult from "../../../assets/images/icons/no_result.svg";
import Pagination from "react-js-pagination";
import Messages from "../../../helpers/Messages";
import AppConfig from "../../../services/AppConfig";
import TooltipComp from "../../../helpers/Tooltip";
import LoadingGif from "../../../assets/images/icons/loading.gif";

class SearchItem extends Component {
  constructor() {
    super();
    this.state = {
      memberDetails: {
        details: "",
        role: "",
        loading: true,
      },
      listMaterials: {
        material_types: "",
        loading: true,
      },
      materialList: {
        items: [],
        loading: true,
      },
      search: {
        search_text: "",
        type: "",
        material_type: "",
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
      has_search: false,
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.memberDetails();
  }

  memberDetails = () => {
    const memberID = this.props.match.params.id;

    let request = Url.searchMaterial + memberID + "/item";

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        Request.getRequest(Url.materialTypes).then((response) => {
          if (response.status === 200) {
            this.setState({
              listMaterials: {
                material_types: response.data.material_types,
              },
            });
          } else {
            Messages.swalError(AppConfig.serverError);
          }
        });

        this.setState({
          memberDetails: {
            details: response.data.member,
            role: response.data.member.role,
            loading: false,
          },
        });
      } else if (response.status === 404) {
        this.props.history.push(Web.borrow);
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  searchItem = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const member_id = this.props.match.params.id;

    const searchParams = {
      search_text: this.state.search.search_text,
      type: this.state.search.type,
      material_type: this.state.search.material_type,
    };

    const request = SearchFilter.setFilterParams(
      searchParams,
      Url.searchMaterial + member_id + "/item"
    );

    Request.getRequest(request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          materialList: {
            items: response.data.results.data,
          },
          form_errors: {
            error_list: [],
          },
          has_search: true,
          pagination: Paginate.setPaginateData(response.data.results),
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          has_search: false,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.search,
      [key]: selectValue,
    };
    this.setState({
      search: newForm,
    });
  };

  handleChangeInput = (key) => (e) => {
    let newForm = {
      ...this.state.search,
      [key]: e.target.value,
    };
    this.setState({
      search: newForm,
    });
  };

  handlePageChange(pageNumber) {
    const member_id = this.props.match.params.id;

    let request = Url.searchMaterial + member_id + "/item";

    var searchParams = {
      search_text: this.state.search.search_text,
      type: this.state.search.type,
      material_type: this.state.search.material_type,
      page: pageNumber,
    };

    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        materialList: {
          items: response.data.results.data,
        },
        has_search: true,
        pagination: Paginate.setPaginateData(response.data.results),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  userGender = (param) => {
    switch (param) {
      case "M":
        return "Mashkull";
      case "F":
        return "Femër";
      default:
        return "";
    }
  };

  /**
   * If data are loading , this function return "..." , else return the data
   * @param param
   * @returns {string|*}
   */
  isLoading = (param) => {
    return this.state.memberDetails.loading ? "  ... " : param;
  };

  /**
   * @param param
   * @returns {string|*}
   */
  isNull = (param) => {
    if (param === null) {
      return " ";
    } else {
      return param;
    }
  };

  render() {
    const memberDetail = this.state.memberDetails.details;
    const memberRole = this.state.memberDetails.role.name;

    const member_id = this.props.match.params.id;

    if (!this.state.listMaterials.loading) {
      var materials = this.state.listMaterials.material_types.map(
        (materialType) => {
          return { value: materialType.id, label: materialType.name };
        }
      );
    }

    var itemList = "";
    if (this.state.materialList.loading) {
      itemList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      itemList = this.state.materialList.items.map((item, index) => {
        const Authors = item.biblio.authors.map((author) => {
          return author.name;
        });

        const freeItems = item.items.map((freeItem) => {
          return freeItem.id;
        });

        return (
          <tr key={index} role="row">
            <td role="cell" data-label="Titull" className="">
              {TooltipComp.tooltipDisabledMobile(index, item.biblio.title)}
            </td>
            <td role="cell" data-label="Autor" className="text-center ">
              {Authors.join("; ")}
            </td>
            <td role="cell" data-label="Isbn/Issn" className="text-center ">
              <span className="isbn-issn">{item.isbn || item.issn}</span>
            </td>
            <td role="cell" data-label="Botues" className="text-center">
              {!item.publisher ? "" : item.publisher.name}
            </td>
            <td
              role="cell"
              data-label="Nr. kopjeve të lira"
              className="text-center"
            >
              {item.items_count} <br />
            </td>
            <td role="cell" data-label="Përzgjidh" className="text-center">
              {item.items_count > 0 ? (
                <Link
                  to={Web.confirmBorrow + member_id + "/item/" + freeItems[0]}
                  className="btn-styled-1"
                >
                  Pëzgjidh
                </Link>
              ) : (
                <button className="btn-styled-1">Pëzgjidh</button>
              )}
            </td>
          </tr>
        );
      });
    }

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="show">
            <p className="partial-title mb-5">Detaje Anëtari</p>

            <div className="member-data row mb-5">
              <div className="list-data col-12 col-md-4 d-flex">
                <ul>
                  <li className="member-detail">
                    Emër Mbiemër / Roli :{" "}
                    {this.isLoading(
                      memberDetail.first_name + " " + memberDetail.last_name
                    ) +
                      " / " +
                      this.isLoading(memberRole)}
                  </li>
                  <li>Email : {this.isLoading(memberDetail.email)}</li>
                  <li>Cel : {this.isLoading(memberDetail.phone)}</li>
                  <li>Datëlindje : {this.isLoading(memberDetail.dob)}</li>
                  <li>
                    Gjinia :{" "}
                    {this.isLoading(this.userGender(memberDetail.gender))}
                  </li>
                  <li>
                    Shteti / Qyteti :{" "}
                    {this.isLoading(this.isNull(memberDetail.state)) +
                      " / " +
                      this.isLoading(this.isNull(memberDetail.city))}
                  </li>
                  <li>
                    Adresa / Kodi postar :{" "}
                    {this.isLoading(this.isNull(memberDetail.address)) +
                      " / " +
                      this.isLoading(this.isNull(memberDetail.postal_code))}
                  </li>
                  <li>
                    Data e fillimit / Data e mbarimit :{" "}
                    {this.isLoading(
                      this.isNull(memberDetail.membership_start)
                    ) +
                      " / " +
                      this.isLoading(this.isNull(memberDetail.membership_end))}
                  </li>
                </ul>
              </div>

              <div className="data-btn col-12 col-md-8">
                <form onSubmit={this.searchItem}>
                  <div className="row mb-5">
                    <div className="col-12 col-lg-8 col-md-8 pb-4">
                      <input
                        placeholder="Kërko "
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.search_text
                            ? " form-error"
                            : "")
                        }
                        name="search_text"
                        type="text"
                        onChange={this.handleChangeInput("search_text")}
                      />
                      {this.state.form_errors.error_list.search_text && (
                        <span className="error-message left">
                          {this.state.form_errors.error_list.search_text[0]}
                        </span>
                      )}
                    </div>
                    <div className="row m-0 w-100">
                      <div className="col-12 col-lg-4 col-md-4 pb-4">
                        <Select
                          className={
                            "basic-select" +
                            (this.state.form_errors.error_list.type
                              ? " form-error"
                              : "")
                          }
                          classNamePrefix="select_input"
                          name="type"
                          isSearchable={false}
                          isClearable={true}
                          onChange={this.handleChangeSelect("type")}
                          placeholder="Lloji i huazimit"
                          options={[
                            { value: "normal", label: "Normal" },
                            { value: "salle", label: "Salle" },
                          ]}
                        />
                        {this.state.form_errors.error_list.type && (
                          <span className="error-message left">
                            {this.state.form_errors.error_list.type[0]}
                          </span>
                        )}
                      </div>
                      <div className="col-12 col-lg-4 col-md-4 pb-4">
                        <Select
                          name="material_type"
                          className={
                            "basic-select" +
                            (this.state.form_errors.error_list.material_type
                              ? " form-error"
                              : "")
                          }
                          isSearchable={false}
                          isClearable={true}
                          onChange={this.handleChangeSelect("material_type")}
                          classNamePrefix="select_input"
                          placeholder="Lloji i materialit"
                          options={materials}
                        />
                        {this.state.form_errors.error_list.material_type && (
                          <span className="error-message left">
                            {this.state.form_errors.error_list.material_type[0]}
                          </span>
                        )}
                      </div>
                      <div className="col-12 col-lg-3 col-md-4">
                        {this.state.btnLoading === true ? (
                          <button className="btn-styled-2 px-5" type="button">
                            Duke Kërkuar
                            <img
                              src={LoadingGif}
                              style={{
                                width: "18px",
                                margin: "-4px 0 0 7px",
                              }}
                            />
                          </button>
                        ) : (
                          <button className="btn-styled-2 px-5" type="submit">
                            Kërko
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {this.state.has_search === true && (
            <div className="table-list">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th role="columnheader" width="20%" className="text-left">
                      Titull
                    </th>
                    <th role="columnheader" width="10%" className="text-center">
                      Autor
                    </th>
                    <th
                      role="columnheader"
                      width="15%"
                      className=" text-center"
                    >
                      Isbn/Issn
                    </th>
                    <th role="columnheader" width="20%" className="text-center">
                      Botues
                    </th>
                    <th
                      role="columnheader"
                      width="10%"
                      className=" text-center"
                    >
                      Nr. kopjeve të lira
                    </th>
                    <th role="columnheader" width="20%" className="text-center">
                      Përzgjidh
                    </th>
                  </tr>
                </thead>
                <tbody>{itemList}</tbody>
              </table>
              {this.state.materialList.items.length === 0 && (
                <img
                  className="mx-auto d-block"
                  src={noResult}
                  alt="no result"
                />
              )}
            </div>
          )}

          <div className="container-fluid pt-5 pb-4">
            <div className="d-flex justify-content-center pagination-block">
              {this.state.pagination.totalPages > 1 &&
                this.state.has_search === true && (
                  <div className="d-flex justify-content-center pagination-block mb-5">
                    <div className="select-page">
                      <Pagination
                        activePage={this.state.pagination.activePage}
                        itemsCountPerPage={
                          this.state.pagination.itemsCountPerPage
                        }
                        totalItemsCount={this.state.pagination.totalItemsCount}
                        pageRangeDisplayed={
                          this.state.pagination.pageRangeDisplayed
                        }
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                    <div className="pagination-results">
                      Faqja: {this.state.pagination.activePage} -{" "}
                      {this.state.pagination.totalPages}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(SearchItem);
