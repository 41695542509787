import React, { Component, Fragment } from "react";
import Request from "../../api/Request";
import Url from "../../api/Url";
import Messages from "../../helpers/Messages";
import AppConfig from "../../services/AppConfig";
import { Link } from "react-router-dom";
import Web from "../../routes/Web";
import LoadingGif from "../../assets/images/icons/loading.gif";
class Contact extends Component {
  constructor() {
    super();
    this.state = {
      contact: {
        name: "",
        phone: "",
        email: "",
        message: "",
      },
      btnLoading: false,
      error_list: [],
    };
  }

  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.contact,
      [key]: e.target.value,
    };
    this.setState({
      contact: newForm,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    Request.postRequest(Url.storeContact, this.state.contact).then(
      (response) => {
        this.setState({ btnLoading: false });
        if (response.status === 201) {
          this.setState({
            contact: {
              name: "",
              phone: "",
              email: "",
              message: "",
            },
            error_list: [],
          });
          Messages.swalSuccess(response.data.success);
        } else if (response.status === 422) {
          this.setState({
            error_list: response.data,
          });
        } else {
          Messages.swalError(AppConfig.serverError);
        }
      }
    );
  };

  render() {
    return (
      <Fragment>
        <div id="content">
          <div id="contact" className="bl_page">
            <div className="mt-4 mb-5">
              <div className="b--breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item">
                      <Link to={Web.homepage}>Biblioteka</Link>
                    </li>
                    <li
                      className="breadcrumb-item active b--title"
                      aria-current="page"
                    >
                      Kontakt
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="info-div">
                <form onSubmit={this.submitHandler}>
                  <div className="d-flex flex-wrap">
                    <div className="col-12 col-md-6 p-0">
                      <div className="pb-4 mb-4 position-relative">
                        <label htmlFor="name">Emër Mbiemër:</label>
                        <input
                          placeholder="Emri juaj"
                          className={
                            "input-form-input" +
                            (this.state.error_list.name ? " form-error" : "")
                          }
                          value={this.state.contact.name}
                          name="name"
                          type="text"
                          id="name"
                          onChange={this.handleChange("name")}
                        />
                        {this.state.error_list.name && (
                          <span className="error-message">
                            {this.state.error_list.name}
                          </span>
                        )}
                      </div>
                      <div className="pb-4 mb-4 position-relative">
                        <label htmlFor="phone">Telefon:</label>
                        <input
                          placeholder="Numri i telefonit"
                          className={
                            "input-form-input" +
                            (this.state.error_list.phone ? " form-error" : "")
                          }
                          name="phone"
                          value={this.state.contact.phone}
                          type="text"
                          id="phone"
                          onChange={this.handleChange("phone")}
                        />
                        {this.state.error_list.phone && (
                          <span className="error-message">
                            {this.state.error_list.phone}
                          </span>
                        )}
                      </div>
                      <div className="pb-4 mb-4 position-relative">
                        <label htmlFor="email">Email:</label>
                        <input
                          placeholder="Emaili juaj"
                          className={
                            "input-form-input" +
                            (this.state.error_list.email ? " form-error" : "")
                          }
                          name="email"
                          value={this.state.contact.email}
                          type="text"
                          id="email"
                          onChange={this.handleChange("email")}
                        />
                        {this.state.error_list.email && (
                          <span className="error-message">
                            {this.state.error_list.email}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 pl-md-3 p-0">
                      <div className="pb-4 mb-4 position-relative">
                        <label htmlFor="message">Mesazhi:</label>
                        <textarea
                          placeholder="Shkruaj Mesazhin"
                          className={
                            "input-form-input h-100 pb-5" +
                            (this.state.error_list.message ? " form-error" : "")
                          }
                          name="message"
                          value={this.state.contact.message}
                          cols="50"
                          rows="10"
                          id="message"
                          onChange={this.handleChange("message")}
                        />
                        {this.state.error_list.message && (
                          <span className="error-message">
                            {this.state.error_list.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {this.state.btnLoading === true ? (
                      <button className="btn-styled-2">Duke Dërguar
					  <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
					  </button>
                    ) : (
                      <button className="btn-styled-2">Dërgo</button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Contact;
