import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Navbar from "../partials/Navbar";
import "../../../assets/css/frontend.css";
import Search from "./Search";

class Header extends Component {


  //get props from child (Search)
  getProps = (search_text,material_type,materialName) => {

    //send props to parent (SearchResultView)
    this.props.simplePropsView (
        search_text,
        material_type,
        materialName
    );

  }


  render() {

    return (
      <Fragment>
          <header className="header">
            <div className="container-fluid">
              <Navbar role={this.props.roleUser} />
              <div className="search-description">
                <div className="category_title">
                  <h1>{this.props.title}</h1>
                  <p>{this.props.desc}</p>
                </div>
                {this.props.search === true && (
                  <Search simpleProps={this.getProps} />
                )}
              </div>
            </div>
          </header>
      </Fragment>
    );
  }
}

export default withRouter(Header);
