import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import dateIcon from "../../../../../assets/images/icons/date-icon.svg";
import Select from "react-select";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import "../../../../../assets/css/editmaterial.css";
import AppConfig from "../../../../../services/AppConfig";

class EditItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
    };
  }

  checkSelectedDate = (value) => {
    if (value === "" || value === null) {
      return null;
    } else {
      return new Date(value);
    }
  };

  checkSelectValue = (value) => {
    switch (value) {
      case "salle":
        return { value: "salle", label: "Sallë" };
      case "normal":
        return { value: "normal", label: "Normal" };
      case "ipadisponueshem":
        return { value: "ipadisponueshem", label: "I Padisponueshëm" };
      default:
        return "";
    }
  };

  render() {
    var items = this.props.items.map((item, index) => {
      return (
        <Fragment key={item.id}>
          <div className="row col-md-10 container-createitem items-sets m-0 mb-3 ">
            <div className="col-12 col-md-4 mb-4 pb-4">
              <label htmlFor="Lloji i huazimit">Lloji i huazimit</label>
              <Select
                className={
                  "basic-select" +
                  (this.props.errors[`items.${index}.borrowing_type`]
                    ? " form-error"
                    : "")
                }
                classNamePrefix="select_input"
                name={"items[" + item.id + "][borrowing_type]"}
                placeholder="Përzgjidh"
                defaultValue={
                  item.borrowing_type
                    ? this.checkSelectValue(item.borrowing_type)
                    : null
                }
                isSearchable={false}
                isClearable={true}
                menuPlacement="auto"
                options={[
                  { value: "salle", label: "Salle" },
                  { value: "normal", label: "Normal" },
                  { value: "ipadisponueshem", label: "I Padisponueshem" },
                ]}
                onChange={(e) =>
                  this.props.handleChangeSelect(e, `borrowing_type`, item.id)
                }
              />
              {this.props.errors[`items.${index}.borrowing_type`] && (
                <span className="error-message left">
                  {this.props.errors[`items.${index}.borrowing_type`]}
                </span>
              )}
            </div>

            <div className="col-12 col-md-4 mb-4 pb-4">
              <label htmlFor="Kodifikimi">Kodifikimi</label>
              <input
                placeholder="Kodifikimi"
                className={
                  "input-form-input" +
                  (this.props.errors[`items.${index}.inventory_number`]
                    ? " form-error"
                    : "")
                }
                name={"items[" + item.id + "][inventory_number]"}
                onChange={(e) =>
                  this.props.handleChange(e, `inventory_number`, item.id)
                }
                type="text"
                defaultValue={
                  item.inventory_number ? item.inventory_number : ""
                }
              />
              {this.props.errors[`items.${index}.inventory_number`] && (
                <span className="error-message left">
                  {this.props.errors[`items.${index}.inventory_number`]}
                </span>
              )}
            </div>
            <div className="col-12 col-md-4 mb-4 pb-4">
              <label htmlFor="Numri i vendit">Nr. i vendit</label>
              <input
                placeholder="Numri i vendit"
                className={
                  "input-form-input" +
                  (this.props.errors[`items.${index}.place_number`]
                    ? " form-error"
                    : "")
                }
                name={"items[" + item.id + "][place_number]"}
                onChange={(e) =>
                  this.props.handleChange(e, `place_number`, item.id)
                }
                type="text"
                defaultValue={item.place_number ? item.place_number : ""}
              />
              {this.props.errors[`items.${index}.place_number`] && (
                <span className="error-message left">
                  {this.props.errors[`items.${index}.place_number`]}
                </span>
              )}
            </div>
            <div className="row m-0 w-100">
              <div className="col-12 col-md-4 mb-4 pb-4">
                <label htmlFor="Numri i vendit">Numri i inventarit</label>
                <input
                  placeholder="Numri i inventarit"
                  className="input-form-input"
                  type="text"
                  defaultValue={item.id}
                  disabled
                />
              </div>
            </div>
            {AppConfig.getRole() === "Administrator" && (
              <div className="row m-0 w-100">
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Demtuar">Dëmtuar</label>
                  <div className="radio-buttons d-flex">
                    <div className="radio-button mr-4">
                      <label htmlFor={`damaged_${index}`}>Po</label>
                      <input
                        className="form-check-input createitem-radiobuttons"
                        name={"items[" + item.id + "][damaged]"}
                        onChange={(e) =>
                          this.props.handleChange(e, `damaged`, item.id)
                        }
                        type="radio"
                        defaultValue="1"
                        id={`damaged_${index}`}
                        defaultChecked={item.damaged === 1}
                      />
                    </div>

                    <div className="radio-button mr-4">
                      <label htmlFor={`notdamaged_${index}`}>Jo</label>
                      <input
                        className="form-check-input"
                        id={`notdamaged_${index}`}
                        name={"items[" + item.id + "][damaged]"}
                        onChange={(e) =>
                          this.props.handleChange(e, `damaged`, item.id)
                        }
                        type="radio"
                        defaultValue="0"
                        defaultChecked={item.damaged === 0}
                      />
                    </div>
                  </div>

                  {this.props.errors[`items.${index}.damaged`] && (
                    <span className="error-message left">
                      {this.props.errors[`items.${index}.damaged`]}
                    </span>
                  )}
                </div>

                {(item.damaged === 1 || item.damaged === "1") && (
                  <Fragment>
                    <div className="col-12 col-md-4 mb-4 pb-4 removable-field">
                      <label htmlFor="Data e dëmtimit">Data e dëmtimit</label>
                      <DatePicker
                        placeholderText="Zgjidh datën"
                        name={"items[" + item.id + "][damaged_date]"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={
                          item.damaged_date
                            ? this.checkSelectedDate(item.damaged_date)
                            : null
                        }
                        onChange={(e) =>
                          this.props.handleChangeDate(
                            e,
                            `damaged_date`,
                            item.id
                          )
                        }
                        autoComplete="off"
                        className={
                          "date_input" +
                          (this.props.errors[`items.${index}.damaged_date`]
                            ? " form-error"
                            : "")
                        }
                      />
                      {this.props.errors[`items.${index}.damaged_date`] && (
                        <span className="error-message left">
                          {this.props.errors[`items.${index}.damaged_date`]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-md-4 mb-4 pb-4 removable-field">
                      <label htmlFor="Data e rinovimit">Data e rinovimit</label>
                      <DatePicker
                        placeholderText="Zgjidh datën"
                        name={"items[" + item.id + "][renewal_date]"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={
                          item.renewal_date
                            ? this.checkSelectedDate(item.renewal_date)
                            : null
                        }
                        onChange={(e) =>
                          this.props.handleChangeDate(
                            e,
                            `renewal_date`,
                            item.id
                          )
                        }
                        autoComplete="off"
                        className={
                          "date_input" +
                          (this.props.errors[`items.${index}.renewal_date`]
                            ? " form-error"
                            : "")
                        }
                      />
                      {this.props.errors[`items.${index}.renewal_date`] && (
                        <span className="error-message left">
                          {this.props.errors[`items.${index}.renewal_date`]}
                        </span>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
            )}

            {AppConfig.getRole() === "Administrator" && (
              <div className="row m-0 w-100">
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Spastruar">Spastruar</label>
                  <div className="radio-buttons d-flex">
                    <div className="radio-button mr-4">
                      <label htmlFor={`lost_${index}`}>Po</label>
                      <input
                        className="form-check-input"
                        id={`lost_${index}`}
                        name={"items[" + item.id + "][lost]"}
                        onChange={(e) =>
                          this.props.handleChange(e, `lost`, item.id)
                        }
                        type="radio"
                        defaultValue="1"
                        defaultChecked={item.lost === 1}
                      />
                    </div>

                    <div className="radio-button mr-4">
                      <label htmlFor={`notlost_${index}`} className="">
                        Jo
                      </label>
                      <input
                        className="form-check-input"
                        id={`notlost_${index}`}
                        name={"items[" + item.id + "][lost]"}
                        onChange={(e) =>
                          this.props.handleChange(e, `lost`, item.id)
                        }
                        type="radio"
                        defaultValue="0"
                        defaultChecked={item.lost === 0}
                      />
                    </div>
                  </div>
                  {this.props.errors[`items.${index}.lost`] && (
                    <span className="error-message left">
                      {this.props.errors[`items.${index}.lost`]}
                    </span>
                  )}
                </div>
                {(item.lost === 1 || item.lost === "1") && (
                  <Fragment>
                    <div className="col-12 col-md-4 mb-4 pb-4 removable-field2">
                      <label htmlFor="Data e spastrimit">
                        Data e spastrimit
                      </label>
                      <DatePicker
                        placeholderText="Zgjidh datën"
                        name={"items[" + item.id + "][lost_date]"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={
                          item.lost_date
                            ? this.checkSelectedDate(item.lost_date)
                            : ""
                        }
                        onChange={(e) =>
                          this.props.handleChangeDate(e, `lost_date`, item.id)
                        }
                        autoComplete="off"
                        className={
                          "date_input" +
                          (this.props.errors[`items.${index}.lost_date`]
                            ? " form-error"
                            : "")
                        }
                      />

                      {this.props.errors[`items.${index}.lost_date`] && (
                        <span className="error-message left">
                          {this.props.errors[`items.${index}.lost_date`]}
                        </span>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
            )}

            <div className="add-remove-buttons">
              <div>
                <button
                  type="button"
                  className="btn-styled-2 removeFields ml-2"
                  name="add-fields[]"
                  onClick={() => this.props.deleteItem(item.id)}
                >
                  -
                </button>
              </div>
              {index + 1 === this.props.items.length &&
                this.props.newitems.length === 0 && (
                  <Fragment>
                    <div>
                      <button
                        className="btn-styled-2 addFields"
                        type="button"
                        name="add-fields[]"
                        onClick={this.props.newItem}
                      >
                        +
                      </button>
                    </div>
                  </Fragment>
                )}
            </div>
          </div>
        </Fragment>
      );
    });

    var newitems = "";
    if (this.props.newitems) {
      var newitems = this.props.newitems.map((item, index_new) => {
        return (
          <Fragment key={item.index}>
            <div className="row col-md-10 m-0 px-0 container-createitem">
              <div className="w-100 row m-0 mt-3 new-item-box">
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Lloji i huazimit">Lloji i huazimit</label>
                  <Select
                    className={
                      "basic-select" +
                      (this.props.errors[`newitems.${index_new}.borrowing_type`]
                        ? " form-error"
                        : "")
                    }
                    classNamePrefix="select_input"
                    name={"newitems[" + item.index + "][borrowing_type]"}
                    placeholder="Përzgjidh"
                    isSearchable={false}
                    isClearable={true}
                    menuPlacement="auto"
                    options={[
                      { value: "salle", label: "Salle" },
                      { value: "normal", label: "Normal" },
                      { value: "ipadisponueshem", label: "I Padisponueshem" },
                    ]}
                    onChange={(e) =>
                      this.props.handleChangeSelectNew(
                        e,
                        `borrowing_type`,
                        item.index
                      )
                    }
                  />
                  {this.props.errors[
                    `newitems.${index_new}.borrowing_type`
                  ] && (
                    <span className="error-message left">
                      {
                        this.props.errors[
                          `newitems.${index_new}.borrowing_type`
                        ]
                      }
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Kodifikimi">Kodifikimi</label>
                  <input
                    placeholder="Kodifikimi"
                    className={
                      "input-form-input" +
                      (this.props.errors[
                        `newitems.${index_new}.inventory_number`
                      ]
                        ? " form-error"
                        : "")
                    }
                    name={"newitems[" + item.index + "][inventory_number]"}
                    onChange={(e) =>
                      this.props.handleChangeNewItems(
                        e,
                        `inventory_number`,
                        item.index
                      )
                    }
                    defaultValue={item.inventory_number}
                    type="text"
                  />

                  {this.props.errors[
                    `newitems.${index_new}.inventory_number`
                  ] && (
                    <span className="error-message left">
                      {
                        this.props.errors[
                          `newitems.${index_new}.inventory_number`
                        ]
                      }
                    </span>
                  )}
                </div>

                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Numri i vendit">Nr. i vendit</label>
                  <input
                    placeholder="Numri i vendit"
                    className={
                      "input-form-input" +
                      (this.props.errors[`newitems.${index_new}.place_number`]
                        ? " form-error"
                        : "")
                    }
                    name={"newitems[" + item.index + "][place_number]"}
                    onChange={(e) =>
                      this.props.handleChangeNewItems(
                        e,
                        `place_number`,
                        item.index
                      )
                    }
                    type="text"
                    defaultValue={item.place_number}
                  />
                  {this.props.errors[`newitems.${index_new}.place_number`] && (
                    <span className="error-message left">
                      {this.props.errors[`newitems.${index_new}.place_number`]}
                    </span>
                  )}
                </div>

                {AppConfig.getRole() === "Administrator" && (
                  <div className="row m-0 w-100">
                    <div className="col-12 col-md-4 mb-4 pb-4">
                      <label>Dëmtuar</label>
                      <div className="radio-buttons d-flex">
                        <div className="radio-button mr-4">
                          <label htmlFor={`damaged-${index_new}`}>Po</label>
                          <input
                            className="form-check-input"
                            id={`damaged-${index_new}`}
                            name={"newitems[" + item.index + "][damaged]"}
                            onChange={(e) =>
                              this.props.handleChangeNewItems(
                                e,
                                `damaged`,
                                item.index
                              )
                            }
                            type="radio"
                            defaultValue="1"
                            defaultChecked={item.damaged === 1}
                          />
                        </div>
                        <div className="radio-button ml-4">
                          <label htmlFor={`notdamaged-${index_new}`}>Jo</label>
                          <input
                            className="form-check-input"
                            data-target="removable-field"
                            id={`notdamaged-${index_new}`}
                            name={"newitems[" + item.index + "][damaged]"}
                            onChange={(e) =>
                              this.props.handleChangeNewItems(
                                e,
                                `damaged`,
                                item.index
                              )
                            }
                            type="radio"
                            defaultValue="0"
                            defaultChecked={item.damaged === 0}
                          />
                        </div>
                      </div>

                      {this.props.errors[`newitems.${index_new}.damaged`] && (
                        <span className="error-message left">
                          {this.props.errors[`newitems.${index_new}.damaged`]}
                        </span>
                      )}
                    </div>
                    {item.damaged === 1 ||
                      (item.damaged === "1" && (
                        <Fragment>
                          <div className="col-12 col-md-4 mb-4 pb-4 removable-field">
                            <label htmlFor="Data e dëmtimit">
                              Data e dëmtimit
                            </label>
                            <DatePicker
                              placeholderText="Zgjidh datën"
                              name={
                                "newitems[" + item.index + "][damaged_date]"
                              }
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              locale={sq}
                              dateFormat="dd-MM-yyyy"
                              selected={this.checkSelectedDate(
                                item.damaged_date
                              )}
                              onChange={(e) =>
                                this.props.handleChangeDateNewItems(
                                  e,
                                  `damaged_date`,
                                  item.index
                                )
                              }
                              autoComplete="off"
                              className={
                                "date_input" +
                                (this.props.errors[
                                  `newitems.${index_new}.damaged_date`
                                ]
                                  ? " form-error"
                                  : "")
                              }
                            />
                            {this.props.errors[
                              `newitems.${index_new}.damaged_date`
                            ] && (
                              <span className="error-message left">
                                {
                                  this.props.errors[
                                    `newitems.${index_new}.damaged_date`
                                  ]
                                }
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-4 mb-4 pb-4 removable-field">
                            <label htmlFor="Data e rinovimit">
                              Data e rinovimit
                            </label>
                            <DatePicker
                              placeholderText="Zgjidh datën"
                              name={
                                "newitems[" + item.index + "][renewal_date]"
                              }
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              locale={sq}
                              dateFormat="dd-MM-yyyy"
                              selected={this.checkSelectedDate(
                                item.renewal_date
                              )}
                              onChange={(e) =>
                                this.props.handleChangeDateNewItems(
                                  e,
                                  `renewal_date`,
                                  item.index
                                )
                              }
                              autoComplete="off"
                              className={
                                "date_input" +
                                (this.props.errors[
                                  `newitems.${index_new}.renewal_date`
                                ]
                                  ? " form-error"
                                  : "")
                              }
                            />
                            {this.props.errors[
                              `newitems.${index_new}.renewal_date`
                            ] && (
                              <span className="error-message left">
                                {
                                  this.props.errors[
                                    `newitems.${index_new}.renewal_date`
                                  ]
                                }
                              </span>
                            )}
                          </div>
                        </Fragment>
                      ))}
                  </div>
                )}
                <div className="new-item-buttons">
                  <button
                    type="button"
                    className="btn-styled-2 removeFields ml-2"
                    name="add-fields[]"
                    onClick={() => this.props.removeItem(item.index)}
                  >
                    -
                  </button>
                  {index_new + 1 === this.props.newitems.length && (
                    <Fragment>
                      <div>
                        <button
                          className="btn-styled-2 addFields"
                          type="button"
                          name="add-fields[]"
                          onClick={this.props.newItem}
                        >
                          +
                        </button>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        );
      });
    }

    return (
      <Fragment>
        {items}
        {newitems}
        {this.props.newitems.length === 0 && this.props.items.length === 0 && (
          <Fragment>
            <button
              className="btn-styled-2 addFields float-left"
              type="button"
              name="add-fields[]"
              onClick={this.props.newItem}
            >
              +
            </button>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default withRouter(EditItems);
