import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import Select from "react-select";
import AuthorModal from "../AuthorModal";

class EditBiblioitem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publishers: this.props.publishers,
      languages: this.props.languages,
      biblioitem: this.props.biblioitem,
      addAuthorPopup: false,
      contributor_types: [
        { value: "redaktor", label: "Redaktor" },
        { value: "perkthyes", label: "Përkthyes" },
        { value: "recensues", label: "Recensues" },
        { value: "pergatites", label: "Përgatitës" },
        { value: "kontributor", label: "Person përgjegjës" },
      ],
    };
  }

  addAuthorPopup = () => {
    this.setState({
      addAuthorPopup: true,
    });
  };

  closeAddAuthorPopup = (status) => {
    this.setState({ addAuthorPopup: status });
  };

  render() {
    if (this.props.selectedContributors.length !== 0) {
      var selectedAuthorsListName = this.props.selectedContributors.map(
        (authors, index) => {
          return (
            <span key={index} className="tag-kyce mr-2">
              {authors.name} / {authors.type}
              <button
                type="button"
                className="ml-2 mr-1 pointer-li btn-unstyled x-button"
                onClick={(e) => this.props.removeContributor(e, authors, index)}
              >
                ✕
              </button>
            </span>
          );
        }
      );
    }

    return (
      <Fragment>
        <form>
          <div className="row align-items-end">
            <div className="col-12 col-md-6 mb-4 pb-4">
              <label htmlFor="ISBN">Isbn (020/A)</label>
              <input
                placeholder="Shto ISBN"
                className={
                  "input-form-input" +
                  (this.props.errors.isbn ? " form-error" : "")
                }
                name="isbn"
                onKeyUp={this.props.onParentChange("isbn")}
                type="text"
                defaultValue={
                  this.props.biblioitem.isbn !== null
                    ? this.props.biblioitem.isbn
                    : ""
                }
              />
              {this.props.errors.isbn && (
                <span className="error-message">{this.props.errors.isbn}</span>
              )}
            </div>
            <div className="col-12 col-md-6 mb-4 pb-4">
              <label htmlFor="Issn">Issn (022/A)</label>
              <input
                placeholder="Shto ISSN"
                className={
                  "input-form-input" +
                  (this.props.errors.issn ? " form-error" : "")
                }
                name="issn"
                onKeyUp={this.props.onParentChange("issn")}
                type="text"
                defaultValue={
                  this.props.biblioitem.issn !== null
                    ? this.props.biblioitem.issn
                    : ""
                }
              />
              {this.props.errors.issn && (
                <span className="error-message">{this.props.errors.issn}</span>
              )}
            </div>
          </div>
          <div className="row align-items-end">
            <div className="col-12 col-md-4 mb-4 pb-4">
              <label htmlFor="Viti i Botimit">Viti i Botimit (260/C)</label>
              <input
                id="publication_year"
                placeholder="Shto vitin "
                className={
                  "input-form-input" +
                  (this.props.errors.publication_year ? " form-error" : "")
                }
                onKeyUp={this.props.onParentChange("publication_year")}
                name="publication_year"
                type="number"
                defaultValue={
                  this.props.biblioitem.publication_year !== null
                    ? this.props.biblioitem.publication_year
                    : ""
                }
              />
              {this.props.errors.publication_year && (
                <span className="error-message">
                  {this.props.errors.publication_year}
                </span>
              )}
            </div>
            <div className="col-12 col-md-4 mb-4 pb-4">
              <label htmlFor="Vendi i botimit">Vendi i botimit (260/A)</label>
              <input
                placeholder="Shto vendin e botimit"
                className="input-form-input"
                onKeyUp={this.props.onParentChange("place")}
                name="place"
                type="text"
                defaultValue={
                  this.props.biblioitem.place !== null
                    ? this.props.biblioitem.place
                    : ""
                }
              />
            </div>
            <div className="col-12 col-md-4 mb-4 pb-4">
              <label htmlFor="Botuesi">Botuesi (260/B)</label>
              <Select
                className={
                  "basic-select" +
                  (this.props.errors.publisher_id ? " form-error" : "")
                }
                classNamePrefix="select_input"
                value={
                  this.props.publisher.value === "" ? "" : this.props.publisher
                }
                isClearable={true}
                placeholder="Përzgjidh"
                onChange={this.props.onParentChangeSelect(
                  "publisher_id",
                  "publisher"
                )}
                isSearchable={true}
                options={this.props.publishers}
              />
              {this.props.errors.publisher_id && (
                <span className="error-message">
                  {this.props.errors.publisher_id}
                </span>
              )}
            </div>
          </div>

          <div className="row align-items-end">
            <div className="autor col-12 pb-4 mb-4">
              <button
                type="button"
                className="modal-button"
                onClick={this.addAuthorPopup}
              >
                Shto personat përgjegjës
              </button>{" "}
              <label htmlFor="Personat përgjegjës" style={{ width: "20px" }}>
                (700/a)
              </label>
              <div className="tage w-100 borderi author-tags-container tag-container-fake-input">
                {selectedAuthorsListName}
              </div>
              <AuthorModal
                types={this.state.contributor_types}
                showPopup={this.state.addAuthorPopup}
                onPopupClose={this.closeAddAuthorPopup}
                popupName={"Personat përgjegjës"}
                authors={this.props.contributors}
                parentCallback={this.props.handleCallbackContributor}
              ></AuthorModal>
              <div className="d-none author-hiddeninputfields-container"></div>
            </div>
          </div>

          <div className="row align-items-end mb-4 pb-4">
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="Ilustrime">Ilustrime</label>
              <input
                placeholder="Shto Ilustrime"
                className="input-form-input"
                name="physical_details"
                onKeyUp={this.props.onParentChange("physical_details")}
                type="text"
                defaultValue={
                  this.props.biblioitem.physical_details !== null
                    ? this.props.biblioitem.physical_details
                    : ""
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="Numri i faqeve">Numri i faqeve (300/A)</label>
              <input
                placeholder="Shto numrin e faqeve"
                className="input-form-input"
                name="pages"
                onKeyUp={this.props.onParentChange("pages")}
                type="text"
                defaultValue={
                  this.props.biblioitem.pages !== null
                    ? this.props.biblioitem.pages
                    : ""
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="Përmasat">Përmasat (300/C)</label>
              <input
                placeholder="Shto Permasat"
                className="input-form-input"
                name="dimensions"
                onKeyUp={this.props.onParentChange("dimensions")}
                type="text"
                defaultValue={
                  this.props.biblioitem.dimensions !== null
                    ? this.props.biblioitem.dimensions
                    : ""
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="Lccn">Lccn (010/A)</label>
              <input
                placeholder="Shto Lccn"
                className="input-form-input"
                name="lccn"
                type="text"
                onKeyUp={this.props.onParentChange("lccn")}
                defaultValue={
                  this.state.biblioitem.lccn !== null
                    ? this.props.biblioitem.lccn
                    : ""
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="Shfletuesi">Shfletuesi</label>
              {(this.props.biblioitem.url === null ||
                (this.props.biblioitem.url !== null &&
                  this.props.delete_url === 1)) && (
                <div
                  className="file_upload_div"
                  id="customInputImg"
                  style={{ display: "block" }}
                >
                  <input
                    placeholder="Shfletuesi"
                    className={
                      "file_upload_input" +
                      (this.props.errors.url ? " form-error" : "")
                    }
                    accept="application/pdf"
                    onChange={this.props.fileSelectHandlerUrl}
                    name="url"
                    type="file"
                    title=""
                  />
                  <div className="upload-button">+ Ngarko shfletuesin</div>
                </div>
              )}

              {this.props.url !== null && (
                <Fragment>
                  <div className="uploaded_file_div" id="customInputImg">
                    <div className="uploaded_file">{this.props.url.name}</div>
                    <button
                      type="button"
                      onClick={(e) => this.props.deleteUrl()}
                      className="remove-image btn-unstyled"
                    >
                      ✕
                    </button>
                  </div>
                </Fragment>
              )}

              {this.props.biblioitem.url !== null &&
                this.props.delete_url !== 1 &&
                this.props.url === null && (
                  <div className="uploaded_file_div" id="customInputImg">
                    <div className="uploaded_file">
                      {this.props.biblioitem.url}
                    </div>

                    <button
                      type="button"
                      onClick={(e) => this.props.deleteUrl()}
                      className="remove-image btn-unstyled"
                    >
                      ✕
                    </button>
                  </div>
                )}

              {this.props.errors.url && (
                <span className="error-message left">
                  {this.props.errors.url}
                </span>
              )}
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="kdu">Kdu (080/A)</label>
              <input
                placeholder="Shto Kdu"
                className="input-form-input"
                name="kdu"
                onKeyUp={this.props.onParentChange("kdu")}
                type="text"
                defaultValue={
                  this.props.biblioitem.kdu !== null
                    ? this.props.biblioitem.kdu
                    : ""
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="Gjuha">Gjuha (041/A)</label>
              <Select
                className={
                  "basic-select" +
                  (this.props.errors.biblioitem_language_id
                    ? " form-error"
                    : "")
                }
                classNamePrefix="select_input"
                name="language"
                placeholder="Përzgjidh"
                onChange={this.props.onParentChangeSelect(
                  "language_id",
                  "biblioitemLanguage"
                )}
                isClearable={true}
                value={
                  this.props.biblioitemLanguage.value === ""
                    ? ""
                    : this.props.biblioitemLanguage
                }
                isSearchable={true}
                options={this.props.languages}
              />
              {this.props.errors.biblioitem_language_id && (
                <span className="error-message">
                  {this.props.errors.biblioitem_language_id}
                </span>
              )}
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="Vëllimi">Vëllimi (250/A)</label>
              <input
                placeholder="Shto vëllimin"
                className="input-form-input"
                name="publication_number"
                onKeyUp={this.props.onParentChange("publication_number")}
                type="text"
                defaultValue={
                  this.props.biblioitem.publication_number !== null
                    ? this.props.biblioitem.publication_number
                    : ""
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="Nr kontrollit">
                Nr. i kontrollit kombëtar (016/A)
              </label>
              <input
                placeholder="Shto Nr. i kontrollit kombëtar "
                className="input-form-input"
                name="national_control_number"
                onKeyUp={this.props.onParentChange("national_control_number")}
                type="text"
                defaultValue={
                  this.props.biblioitem.national_control_number !== null
                    ? this.props.biblioitem.national_control_number
                    : ""
                }
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="Barkod">Barkod</label>
              <input
                placeholder="Shto Barkod"
                className={
                  "input-form-input" +
                  (this.props.errors.barcode ? " form-error" : "")
                }
                name="barcode"
                onKeyUp={this.props.onParentChange("barcode")}
                type="text"
                defaultValue={
                  this.props.biblioitem.barcode !== null
                    ? this.props.biblioitem.barcode
                    : ""
                }
              />
              {this.props.errors.barcode && (
                <span className="error-message">
                  {this.props.errors.barcode}
                </span>
              )}
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="Çmimi">Çmimi (Lekë) (365/B)</label>
              <input
                id="price"
                placeholder="Shto Çmimin"
                step="any"
                className={
                  "input-form-input" +
                  (this.props.errors.price ? " form-error" : "")
                }
                name="price"
                onKeyUp={this.props.onParentChange("price")}
                type="number"
                defaultValue={
                  this.props.biblioitem.price !== null
                    ? this.props.biblioitem.price
                    : ""
                }
              />
              {this.props.errors.price && (
                <span className="error-message">{this.props.errors.price}</span>
              )}
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="Cmimi zevendesues">
                Çmimi zëvendësues (Lekë)
              </label>
              <input
                id="replacement_price"
                placeholder="Shto çmimin zëvendësues"
                step="any"
                className={
                  "input-form-input" +
                  (this.props.errors.replacement_price ? " form-error" : "")
                }
                name="replacement_price"
                onKeyUp={this.props.onParentChange("replacement_price")}
                type="number"
                defaultValue={
                  this.props.biblioitem.replacement_price !== null
                    ? this.props.biblioitem.replacement_price
                    : ""
                }
              />
              {this.props.errors.replacement_price && (
                <span className="error-message">
                  {this.props.errors.replacement_price}
                </span>
              )}
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
              <label htmlFor="Kopertinë">Kopertinë</label>

              <div className="cover-img w-100">
                <div className="file-upload1">
                  {(this.props.biblioitem.cover === null ||
                    (this.props.biblioitem.cover !== null &&
                      this.props.delete_cover === 1)) && (
                    <div className="file_upload_div">
                      <input
                        placeholder="Kopertinë"
                        className={
                          "file_upload_input" +
                          (this.props.errors.cover ? " form-error" : "")
                        }
                        accept="image/*"
                        onChange={this.props.fileSelectHandler}
                        name="cover"
                        type="file"
                        title=""
                      />
                      <div className="upload-button">+ Ngarko kopertinë</div>
                    </div>
                  )}
                  {this.props.cover != null && (
                    <div className="uploaded_file_div">
                      <div className="uploaded_file">
                        {this.props.cover.name}
                      </div>
                      <button
                        type="button"
                        onClick={(e) => this.props.deleteCover()}
                        className="remove-image btn-unstyled"
                      >
                        ✕
                      </button>
                    </div>
                  )}

                  {this.props.biblioitem.cover != null &&
                    this.props.delete_cover !== 1 &&
                    this.props.cover === null && (
                      <div className="uploaded_file_div">
                        <div className="uploaded_file">
                          {this.props.biblioitem.cover}
                        </div>
                        <button
                          type="button"
                          onClick={(e) => this.props.deleteCover()}
                          className="remove-image btn-unstyled"
                        >
                          ✕
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default withRouter(EditBiblioitem);
