import React, { Component } from 'react';
import { withRouter } from 'react-router';
import '../assets/css/pagenotfound.css';
import Logo from '../assets/images/logo/logo-uniel.svg';
class PageNotFound extends Component {
  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <div id="error-404" className="container-fluid small-offset-left">
        <div className="error-404">
          <div className="logo">
            <img src={Logo} />
          </div>
          <div>
            <h1>
              4<span>0</span>4
            </h1>
            <h2>Faqja nuk u gjet</h2>
            <p>
              Na vjen keq, faqja që keni kërkuar nuk u gjet! Ju lutemi kthehuni
              në faqen e mëparshme!
            </p>
          </div>
          <button onClick={this.goBack} className="btn-styled-1">
            Kthehu
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(PageNotFound);
