import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import Url from '../../../api/Url';
import Request from '../../../api/Request';
import Messages from '../../../helpers/Messages';
import dateFormat from 'dateformat';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import sq from 'date-fns/locale/sq';
import moment from 'moment';
import AppConfig from '../../../services/AppConfig';
import Web from '../../../routes/Web';
import { withRouter } from 'react-router-dom';
import LoadingGif from '../../../assets/images/icons/loading.gif';
class InviteMember extends Component {
  constructor() {
    super();
    this.state = {
      memberRoles: {
        roles: [],
        loading: true,
      },
      inviteMember: {
        first_name: '',
        last_name: '',
        email: '',
        dob: '',
        role_id: '',
        membership_start: dateFormat('dd-mm-yyyy'),
        membership_end: '',
        state: '',
        city: '',
        address: '',
        postal_code: '',
        phone: '',
        gender: '',
        nid: '',
        faculty: '',
        department: '',
        program: '',
        edulevel: '',
        fullorparttime: '',
        year: '',
      },
      selectedDob: { dob: '' },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
    };
  }

  componentDidMount() {
    this.memberRoles();
  }

  memberRoles = () => {
    Request.getRequest(Url.memberRoles).then((response) => {
      if (response.status === 200) {
        this.setState({
          memberRoles: {
            roles: response.data.memberRoles,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  inviteMember = (e) => {
    e.preventDefault();
    this.setState({
      btnLoading: true,
    });

    var studentData = {};
    if (this.state.inviteMember.role_id === 4) {
      studentData = {
        faculty: this.state.inviteMember.faculty,
        department: this.state.inviteMember.department,
        program: this.state.inviteMember.program,
        edulevel: this.state.inviteMember.edulevel,
        fullorparttime: this.state.inviteMember.fullorparttime,
        year: this.state.inviteMember.year,
      };
    }

    let formData = {
      first_name: this.state.inviteMember.first_name,
      last_name: this.state.inviteMember.last_name,
      email: this.state.inviteMember.email,
      dob: this.state.inviteMember.dob,
      role_id: this.state.inviteMember.role_id,
      membership_start: dateFormat('dd-mm-yyyy'),
      membership_end: this.state.inviteMember.membership_end,
      state: this.state.inviteMember.state,
      city: this.state.inviteMember.city,
      address: this.state.inviteMember.address,
      postal_code: this.state.inviteMember.postal_code,
      phone: this.state.inviteMember.phone,
      gender: this.state.inviteMember.gender,
      nid: this.state.inviteMember.nid,
      ...studentData,
    };

    Request.postRequest(Url.storeMember, formData).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 201) {
        this.props.history.push(Web.listMembers);
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.inviteMember,
      [key]: e.target.value,
    };
    this.setState({
      inviteMember: newForm,
    });
  };

  handleChangeD = (key) => (e) => {
    var newForm;
    if (e === null) {
      newForm = {
        ...this.state.inviteMember,
        [key]: null,
      };
    } else {
      newForm = {
        ...this.state.inviteMember,
        [key]: moment(e).format('DD-MM-yyyy'),
      };
    }

    this.setState({
      selectedDob: { [key]: e },
      inviteMember: newForm,
    });
  };

  handleChangeS = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = '';
    }
    let newForm = {
      ...this.state.inviteMember,
      [key]: selectValue,
    };
    this.setState({
      inviteMember: newForm,
    });
  };

  render() {
    if (!this.state.memberRoles.loading) {
      var roles = this.state.memberRoles.roles.map((role) => {
        return { value: role.id, label: role.name };
      });
    }

    return (
      <div className="container-fluid small-offset-left">
        <div className="invite-member">
          <p className="partial-title mb-5">Shto Anëtar</p>
          <form onSubmit={this.inviteMember}>
            <div className="row">
              <div className="col-12 col-md-4 flex-column col-form">
                <div className="pb-4 mb-4">
                  <label>Emri</label>
                  <input
                    type="text"
                    name="first_name"
                    placeholder="Shto Emrin"
                    className={
                      'input-form-input' +
                      (this.state.form_errors.error_list.first_name
                        ? ' form-error'
                        : '')
                    }
                    onChange={this.handleChange('first_name')}
                  />
                  {this.state.form_errors.error_list.first_name && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.first_name[0]}
                    </span>
                  )}
                </div>
                <div className="pb-4 mb-4">
                  <label>Mbiemri</label>
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Shto Mbiemrin"
                    className={
                      'input-form-input' +
                      (this.state.form_errors.error_list.last_name
                        ? ' form-error'
                        : '')
                    }
                    onChange={this.handleChange('last_name')}
                  />
                  {this.state.form_errors.error_list.last_name && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.last_name[0]}
                    </span>
                  )}
                </div>

                <div className="pb-4 mb-4">
                  <label>Email</label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    className={
                      'input-form-input' +
                      (this.state.form_errors.error_list.email
                        ? ' form-error'
                        : '')
                    }
                    onChange={this.handleChange('email')}
                  />
                  {this.state.form_errors.error_list.email && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.email[0]}
                    </span>
                  )}
                </div>

                <div className="pb-4 mb-4">
                  <label>Ditëlindje</label>
                  <DatePicker
                    placeholderText="Zgjidh datën"
                    name="dob"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale={sq}
                    dateFormat="dd-MM-yyyy"
                    selected={this.state.selectedDob.dob}
                    onChange={this.handleChangeD('dob')}
                    autoComplete="off"
                    className={
                      'date_input' +
                      (this.state.form_errors.error_list.dob
                        ? ' form-error'
                        : '')
                    }
                  />
                  {this.state.form_errors.error_list.dob && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.dob[0]}
                    </span>
                  )}
                </div>

                <div
                  className={
                    this.state.inviteMember.role_id === 4
                      ? 'pb-4 mb-4'
                      : 'pb-4 mb-0'
                  }
                >
                  <label>Numri Identifikimit</label>
                  <input
                    type="text"
                    name="nid"
                    placeholder="NID"
                    className={
                      'input-form-input' +
                      (this.state.form_errors.error_list.nid
                        ? ' form-error'
                        : '')
                    }
                    onChange={this.handleChange('nid')}
                  />
                  {this.state.form_errors.error_list.nid && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.nid[0]}
                    </span>
                  )}
                </div>
                {this.state.inviteMember.role_id === 4 && (
                  <Fragment>
                    <div className="pb-4 mb-4">
                      <label>Viti</label>
                      <input
                        type="number"
                        name="year"
                        placeholder="Viti"
                        className={
                          'input-form-input' +
                          (this.state.form_errors.error_list.year
                            ? ' form-error'
                            : '')
                        }
                        onChange={this.handleChange('year')}
                      />
                      {this.state.form_errors.error_list.year && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.year[0]}
                        </span>
                      )}
                    </div>
                    <div className="pb-4 mb-0">
                      <label>Fakulteti</label>
                      <input
                        type="text"
                        name="faculty"
                        placeholder="Fakulteti"
                        className={
                          'input-form-input' +
                          (this.state.form_errors.error_list.faculty
                            ? ' form-error'
                            : '')
                        }
                        onChange={this.handleChange('faculty')}
                      />
                      {this.state.form_errors.error_list.nid && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.faculty[0]}
                        </span>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>

              <div className="col-12 col-md-4 flex-column col-form">
                <div className="pb-4 mb-4">
                  <label>Data e fillimit</label>
                  <input
                    type="text"
                    defaultValue={dateFormat('dd-mm-yyyy')}
                    name="membership_start"
                    placeholder="Data e fillimit"
                    className={
                      'input-form-input' +
                      (this.state.form_errors.error_list.membership_start
                        ? ' form-error'
                        : '')
                    }
                    readOnly="readonly"
                  />
                  {this.state.form_errors.error_list.membership_start && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.membership_start[0]}
                    </span>
                  )}
                </div>

                <div className="pb-4 mb-4">
                  <label>Data e mbarimit</label>
                  <Select
                    className={
                      'basic-select' +
                      (this.state.form_errors.error_list.membership_end
                        ? ' form-error'
                        : '')
                    }
                    classNamePrefix="select_input"
                    name="membership_end"
                    placeholder="Data e përfundimit të anëtarësimit"
                    isClearable={true}
                    onChange={this.handleChangeS('membership_end')}
                    isSearchable={false}
                    options={[
                      { value: '3', label: '3 Muaj' },
                      { value: '6', label: '6 Muaj' },
                      { value: '9', label: '9 Muaj' },
                      { value: '12', label: '12 Muaj' },
                    ]}
                  />
                  {this.state.form_errors.error_list.membership_end && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.membership_end[0]}
                    </span>
                  )}
                </div>

                <div className="pb-4 mb-4">
                  <label>Shteti</label>
                  <input
                    type="text"
                    name="state"
                    placeholder="Shto Shtetin"
                    className={
                      'input-form-input' +
                      (this.state.form_errors.error_list.state
                        ? ' form-error'
                        : '')
                    }
                    onChange={this.handleChange('state')}
                  />
                  {this.state.form_errors.error_list.state && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.state[0]}
                    </span>
                  )}
                </div>

                <div className="pb-4 mb-4">
                  <label>Qyteti</label>
                  <input
                    type="text"
                    name="city"
                    placeholder="Shto Qytetin"
                    className={
                      'input-form-input' +
                      (this.state.form_errors.error_list.city
                        ? ' form-error'
                        : '')
                    }
                    onChange={this.handleChange('city')}
                  />
                  {this.state.form_errors.error_list.city && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.city[0]}
                    </span>
                  )}
                </div>

                <div
                  className={
                    this.state.inviteMember.role_id === 4
                      ? 'pb-4 mb-4'
                      : 'pb-4 mb-0'
                  }
                >
                  <label>Adresa</label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Shto Adresën"
                    className={
                      'input-form-input' +
                      (this.state.form_errors.error_list.address
                        ? ' form-error'
                        : '')
                    }
                    onChange={this.handleChange('address')}
                  />
                  {this.state.form_errors.error_list.address && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.address[0]}
                    </span>
                  )}
                </div>

                {this.state.inviteMember.role_id === 4 && (
                  <div className="pb-4 mb-0">
                    <label>Departamenti</label>
                    <input
                      type="text"
                      name="department"
                      placeholder="Departamenti"
                      className={
                        'input-form-input' +
                        (this.state.form_errors.error_list.department
                          ? ' form-error'
                          : '')
                      }
                      onChange={this.handleChange('department')}
                    />
                    {this.state.form_errors.error_list.department && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.department[0]}
                      </span>
                    )}
                  </div>
                )}
              </div>

              <div className="col-12 col-md-4 d-flex flex-column col-form">
                <div className="pb-4 mb-4">
                  <label>Kodi Postar</label>
                  <input
                    type="number"
                    name="postal_code"
                    placeholder="Shto Kodin Postar"
                    className={
                      'input-form-input' +
                      (this.state.form_errors.error_list.postal_code
                        ? ' form-error'
                        : '')
                    }
                    onChange={this.handleChange('postal_code')}
                  />
                  {this.state.form_errors.error_list.postal_code && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.postal_code[0]}
                    </span>
                  )}
                </div>
                <div className="pb-4 mb-4">
                  <label>Telefon</label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Shto numrin e telefonit"
                    className={
                      'input-form-input' +
                      (this.state.form_errors.error_list.phone
                        ? ' form-error'
                        : '')
                    }
                    onChange={this.handleChange('phone')}
                  />
                  {this.state.form_errors.error_list.phone && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.phone[0]}
                    </span>
                  )}
                </div>

                <div className="pb-4 mb-4">
                  <label>Roli</label>

                  <Select
                    className={
                      'basic-select' +
                      (this.state.form_errors.error_list.role_id
                        ? ' form-error'
                        : '')
                    }
                    classNamePrefix="select_input"
                    name="role_id"
                    isClearable={true}
                    placeholder="Përzgjidh"
                    onChange={this.handleChangeS('role_id')}
                    isSearchable={false}
                    options={roles}
                  />
                  {this.state.form_errors.error_list.role_id && (
                    <span className="error-message">
                      {this.state.form_errors.error_list.role_id[0]}
                    </span>
                  )}
                </div>

                {this.state.inviteMember.role_id === 4 && (
                  <Fragment>
                    <div className="pb-4 mb-4">
                      <label>Programi</label>
                      <input
                        type="text"
                        name="program"
                        placeholder="Programi"
                        className={
                          'input-form-input' +
                          (this.state.form_errors.error_list.program
                            ? ' form-error'
                            : '')
                        }
                        onChange={this.handleChange('program')}
                      />
                      {this.state.form_errors.error_list.program && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.program[0]}
                        </span>
                      )}
                    </div>
                    <div className="pb-4 mb-4">
                      <label>Niveli i edukimit</label>
                      <input
                        type="text"
                        name="edulevel"
                        placeholder="Niveli i edukimit"
                        className={
                          'input-form-input' +
                          (this.state.form_errors.error_list.edulevel
                            ? ' form-error'
                            : '')
                        }
                        onChange={this.handleChange('edulevel')}
                      />
                      {this.state.form_errors.error_list.edulevel && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.edulevel[0]}
                        </span>
                      )}
                    </div>
                    <div className="d-block mb-4">
                      <label>Koha e studimit</label>
                      <div className="radio-buttons d-flex">
                        <div className="radio-button mr-3">
                          <label htmlFor="fulltime">Me kohë të plotë</label>
                          <input
                            className={'form-check-input'}
                            id="fulltime"
                            name="fullorparttime"
                            type="radio"
                            value="fulltime"
                            onChange={this.handleChange('fullorparttime')}
                          />
                        </div>
                        <div className="radio-button">
                          <label htmlFor="parttime">Me kohë të pjesshme</label>
                          <input
                            className="form-check-input"
                            id="parttime"
                            name="fullorparttime"
                            type="radio"
                            value="parttime"
                            onChange={this.handleChange('fullorparttime')}
                          />
                        </div>
                        {this.state.form_errors.error_list.fullorparttime && (
                          <span className="error-message">
                            {
                              this.state.form_errors.error_list
                                .fullorparttime[0]
                            }
                          </span>
                        )}
                      </div>
                    </div>
                  </Fragment>
                )}

                <div className="d-block mb-4">
                  <label>Gjinia</label>
                  <div className="radio-buttons d-flex">
                    <div className="radio-button mr-3">
                      <label htmlFor="man">Mashkull</label>
                      <input
                        className={'form-check-input'}
                        id="man"
                        name="gender"
                        type="radio"
                        value="M"
                        onChange={this.handleChange('gender')}
                      />
                    </div>
                    <div className="radio-button">
                      <label htmlFor="woman">Femër</label>
                      <input
                        className="form-check-input"
                        id="woman"
                        name="gender"
                        type="radio"
                        value="F"
                        onChange={this.handleChange('gender')}
                      />
                    </div>
                    {this.state.form_errors.error_list.gender && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.gender[0]}
                      </span>
                    )}
                  </div>
                </div>
                {this.state.btnLoading === true ? (
                  <button type="button" className="btn-styled-2 mt-auto mb-4">
                    Duke u Ruajtur
                    <img
                      src={LoadingGif}
                      style={{
                        width: '18px',
                        margin: '-4px 0 0 7px',
                      }}
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-styled-2 mt-auto mb-4"
                    style={{ width: 160 + 'px' }}
                  >
                    Ruaj
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(InviteMember);
