import React from "react";
import { Component, Fragment } from "react";
import Request from "../../../../api/Request";
import Url from "../../../../api/Url";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Messages from "../../../../helpers/Messages";
import LoadingGif from "../../../../assets/images/icons/loading.gif";

class CreateFrequence extends Component {
  constructor() {
    super();
    this.state = {
      createFrequence: {
        description: "",
        unit: "",
        issues_per_unit: "",
        units_per_issue: "",
      },
      errorList: [],
      btnLoading: false,
    };
  }

  closeModal = () => {
    this.props.onPopupClose(false);
    this.setState({
      createFrequence: {
        description: "",
        unit: "",
        issues_per_unit: "",
        units_per_issue: "",
      },
      errorList: [],
    });
  };

  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.createFrequence,
      [key]: e.target.value,
    };
    this.setState({
      createFrequence: newForm,
    });
  };

  createFrequence = (e) => {
    let request = Url.storeFrequence;
    this.setState({ btnLoading: true });
    e.preventDefault();
    Request.postRequest(request, this.state.createFrequence).then(
      (response) => {
        this.setState({ btnLoading: false });
        if (response.status === 200) {
          this.setState({
            createFrequence: {
              description: "",
              unit: "",
              issues_per_unit: "",
              units_per_issue: "",
            },
            errorList: [],
          });
          this.closeModal();
          Messages.swalSuccess(response.data.success);
        } else if (response.status === 422) {
          this.setState({
            errorList: response.data,
          });
        }
      }
    );
  };

  render() {
    return (
      <Fragment>
        <Modal
          show={this.props.showFrequencePopup}
          onHide={this.closeModal}
          centered
        >
          <Modal.Header>
            <Modal.Title id="sign-in-title">Krijo Frekuencë</Modal.Title>
            <button
              className="btn-unstyled close-modal"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.closeModal}
            >
              ✕
            </button>
          </Modal.Header>
          <form onSubmit={this.createFrequence}>
            <Modal.Body>
              <div className="row m-0 mb-2">
                <div className="col-12 pb-4">
                  <label htmlFor="description">Përshkrimi</label>
                  <input
                    placeholder="Përshkrimi..."
                    id="description"
                    name="description"
                    type="text"
                    value={this.state.createFrequence.description}
                    onChange={this.handleChange("description")}
                    className={
                      "input-form-input" +
                      (this.state.errorList.description ? " form-error" : "")
                    }
                  />
                  {this.state.errorList.unit && (
                    <span className="error-message left">
                      {this.state.errorList.description}
                    </span>
                  )}
                </div>
              </div>
              <div className="row m-0 mb-2">
                <div className="col-12 pb-4">
                  <label htmlFor="unit">Njësia</label>
                  <input
                    placeholder="Njësia... "
                    id="unit"
                    name="unit"
                    type="text"
                    value={this.state.createFrequence.unit}
                    onChange={this.handleChange("unit")}
                    className={
                      "input-form-input" +
                      (this.state.errorList.unit ? " form-error" : "")
                    }
                  />
                  {this.state.errorList.unit && (
                    <span className="error-message left">
                      {this.state.errorList.unit}
                    </span>
                  )}
                </div>
              </div>
              <div className="row m-0 mb-2">
                <div className="col-12 col-sm-6 pb-4">
                  <label htmlFor="issues_per_unit">Seri për njësi</label>
                  <input
                    id="issues_per_unit"
                    placeholder="Seri për njësi "
                    name="issues_per_unit"
                    type="number"
                    value={this.state.createFrequence.issues_per_unit}
                    onChange={this.handleChange("issues_per_unit")}
                    className={
                      "input-form-input" +
                      (this.state.errorList.issues_per_unit
                        ? " form-error"
                        : "")
                    }
                  />
                  {this.state.errorList.issues_per_unit && (
                    <span className="error-message left">
                      {this.state.errorList.issues_per_unit}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 pb-4">
                  <label htmlFor="units_per_issue">Njësi për seri</label>
                  <input
                    id="units_per_issue"
                    placeholder="Njësi për seri "
                    name="units_per_issue"
                    type="number"
                    value={this.state.createFrequence.units_per_issue}
                    onChange={this.handleChange("units_per_issue")}
                    className={
                      "input-form-input" +
                      (this.state.errorList.units_per_issue
                        ? " form-error"
                        : "")
                    }
                  />
                  {this.state.errorList.units_per_issue && (
                    <span className="error-message left">
                      {this.state.errorList.units_per_issue}
                    </span>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-12 d-flex m-0 p-0 justify-content-center">
                <div className="col-6 pl-0 pr-2 pr-sm-3 justify-content-center">
                  {this.state.btnLoading === true ? (
                    <button
                      className="btn-styled-2 w-100 text-center"
                      type="button"
                    >
                      Duke u krijuar
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      className="btn-styled-2 w-100 text-center"
                      type="submit"
                    >
                      Krijo
                    </button>
                  )}
                </div>
                <div className="col-6 pr-0 pl-2 pl-sm-3">
                  <button
                    className="btn-styled-1 w-100 text-center"
                    type="button"
                    onClick={this.closeModal}
                  >
                    Anullo
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </Fragment>
    );
  }
}

export default withRouter(CreateFrequence);
