import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import ActivateAccount from "../../components/auth/ActivateAccount";
import Web from "../../routes/Web";
import AppConfig from "../../services/AppConfig";
import HomepageView from "../opac/HomepageView";
import Auth from "./Auth";

class ActivateAccountView extends Component {
	render() {
		var isAuth = AppConfig.isLogged();
		if (isAuth === false) {
			return (
				<Fragment>
					<Helmet>
						<title>Aktivizo Llogarinë</title>
					</Helmet>
					<div
						className="d-flex flex-wrap login-view"
						style={{ width: 100 + "%" }}
					>
						<Auth />
						<div className="col-md-6 right-auth-page">
							<ActivateAccount />
						</div>
					</div>
				</Fragment>
			);
		} else {
			return <HomepageView />;
		}
	}
}

export default withRouter(ActivateAccountView);
