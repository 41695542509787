import React, { Component, Fragment } from "react";
import Select from "react-select";
import Paginate from "../../../helpers/Paginate";
import Url from "../../../api/Url";
import SearchFilter from "../../../helpers/SearchFilter";
import Request from "../../../api/Request";
import loadingGif from "../../../assets/images/icons/loading.gif";
import noResult from "../../../assets/images/icons/no_result.svg";
import Pagination from "react-js-pagination";
import Messages from "../../../helpers/Messages";
import Web from "../../../routes/Web";
import { withRouter } from "react-router-dom";
import AppConfig from "../../../services/AppConfig";
import TooltipComp from "../../../helpers/Tooltip";
import LoadingGif from "../../../assets/images/icons/loading.gif";
class Reservations extends Component {
  constructor() {
    super();
    this.state = {
      listReservations: {
        reservations: [],
        loading: true,
      },
      searchReservation: {
        search_text: "",
        type: "",
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
      searched: false,
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.reservationList();
  }

  reservationList = (hasSearch) => {
    let request = Url.listReservations;

    const searchParams = {
      search_text: this.state.searchReservation.search_text,
      type: this.state.searchReservation.type,
    };

    if (hasSearch) {
      this.setState({
        searched: true,
      });
      hasSearch.preventDefault();
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({ btnLoading: true });
    }

    Request.getRequest(request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          listReservations: {
            reservations: response.data.reservations.data,
            loading: false,
          },
          form_errors: {
            error_list: [],
          },
          pagination: Paginate.setPaginateData(response.data.reservations),
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          searched: false,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
        this.setState({
          searched: false,
        });
      }
    });
  };

  borrowMaterial = (e, id) => {
    e.preventDefault();
    const data = { id: id };
    Request.postRequest(Url.borrowReservedMaterial, data).then((response) => {
      if (response.status === 200) {
        this.props.history.push(Web.listBorrowings);
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 404) {
        this.reservationList();
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.searchReservation,
      [key]: selectValue,
    };
    this.setState({
      searchReservation: newForm,
    });
  };

  handleChangeInput = (key) => (e) => {
    let newForm = {
      ...this.state.searchReservation,
      [key]: e.target.value,
    };
    this.setState({
      searchReservation: newForm,
    });
  };

  handlePageChange(pageNumber) {
    let request = Url.listReservations;
    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        search_text: this.state.searchReservation.search_text,
        type: this.state.searchReservation.type,
        page: pageNumber,
      };
    }
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listReservations: {
          reservations: response.data.reservations.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.reservations),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    var reservationList = "";
    if (this.state.listReservations.loading) {
      reservationList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      reservationList = this.state.listReservations.reservations.map(
        (reservation, index) => {
          const Authors = reservation.item.biblioitem.biblio.authors.map(
            (author) => {
              return author.name;
            }
          );

          return (
            <tr key={index}>
              <td data-label="Anëtar">
                {reservation.user.first_name + " " + reservation.user.last_name}
              </td>
              <td className="text-center" data-label="Titull/Autor">
                <div className="text-md-center">
                  {TooltipComp.tooltipDisabledMobile(
                    "title" + index,
                    reservation.item.biblioitem.biblio.title
                  )}
                </div>
                {Authors.join("; ")}
              </td>
              <td className="text-center" data-label="Lloji i huazimit">
                {reservation.item.borrowing_type}
              </td>
              <td
                className="text-center"
                data-label="Kodifikimi/Nr. i inventarit"
              >
                {reservation.item.inventory_number +
                  " / " +
                  reservation.item.id}
              </td>
              <td className="text-center" data-label="Jep Hua">
                <form>
                  <button
                    className="btn-styled-1 small_btn"
                    onClick={(e) => this.borrowMaterial(e, reservation.id)}
                    type={"submit"}
                  >
                    Jep hua
                  </button>
                </form>
              </td>
            </tr>
          );
        }
      );
    }

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="reservations">
            <p className="partial-title mb-5">Rezervime</p>
            <div className="input-items">
              <form onSubmit={this.reservationList}>
                <div className="row mb-5">
                  <div className="col-12 col-sm-6 col-md-4 mb-4 pb-4">
                    <input
                      placeholder="Kërko... "
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.search_text
                          ? " form-error"
                          : "")
                      }
                      name="search_text"
                      onChange={this.handleChangeInput("search_text")}
                      type="text"
                    />
                    {this.state.form_errors.error_list.search_text && (
                      <span className="error-message left">
                        {this.state.form_errors.error_list.search_text[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 mb-4 pb-4">
                    <Select
                      className={
                        "basic-select" +
                        (this.state.form_errors.error_list.type
                          ? " form-error"
                          : "")
                      }
                      classNamePrefix="select_input"
                      name="type"
                      isSearchable={false}
                      isClearable={true}
                      onChange={this.handleChangeSelect("type")}
                      placeholder="Perzgjidh"
                      options={[
                        { value: "member", label: "Anetare" },
                        { value: "nr_inventory", label: "Kodifikimi" },
                        { value: "title", label: "Titull" },
                      ]}
                    />
                    {this.state.form_errors.error_list.type && (
                      <span className="error-message left">
                        {this.state.form_errors.error_list.type[0]}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 mb-4 pb-4">
                    {this.state.btnLoading === true ? (
                      <button
                        className="btn-styled-2 px-5 ml-0"
                        type={"button"}
                      >
                        Duke Kërkuar
                        <img
                          src={LoadingGif}
                          style={{
                            width: "18px",
                            margin: "-4px 0 0 7px",
                          }}
                        />
                      </button>
                    ) : (
                      <button
                        className="btn-styled-2 px-5 ml-0"
                        type={"submit"}
                      >
                        Kërko
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className="table-list">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th
                      role="columnheader"
                      className="pr-3 text-left"
                      width="20%"
                    >
                      Anëtar
                    </th>
                    <th
                      role="columnheader"
                      className="pr-3 text-center"
                      width="20%"
                    >
                      Titull/Autor
                    </th>
                    <th
                      role="columnheader"
                      className="pr-3 text-center"
                      width="20%"
                    >
                      Lloji i huazimit
                    </th>
                    <th
                      role="columnheader"
                      className="pr-3 text-center"
                      width="20%"
                    >
                      Kodifikimi/Nr. i inventarit
                    </th>
                    <th
                      role="columnheader"
                      className="pr-3 text-center"
                      width="20%"
                    >
                      Jep Hua
                    </th>
                  </tr>
                </thead>
                <tbody>{reservationList}</tbody>
              </table>
              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listReservations.loading === false &&
                this.state.searched === true && (
                  <img
                    className="mx-auto d-block"
                    src={noResult}
                    alt="no result"
                  />
                )}

              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listReservations.loading === false &&
                this.state.searched === false && (
                  <p className="nothing--to--show">Asgjë për të shfaqur</p>
                )}
            </div>

            <div className="container-fluid pt-5 pb-4">
              <div className="d-flex justify-content-center pagination-block">
                {this.state.pagination.totalPages > 1 && (
                  <div className="d-flex justify-content-center pagination-block mb-5">
                    <div className="select-page">
                      <Pagination
                        activePage={this.state.pagination.activePage}
                        itemsCountPerPage={
                          this.state.pagination.itemsCountPerPage
                        }
                        totalItemsCount={this.state.pagination.totalItemsCount}
                        pageRangeDisplayed={
                          this.state.pagination.pageRangeDisplayed
                        }
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                    <div className="pagination-results">
                      Faqja: {this.state.pagination.activePage} -{" "}
                      {this.state.pagination.totalPages}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(Reservations);
