import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import AuthorModal from "../AuthorModal";
import GeographicTermsModal from "../GeographicTermsModal";
import KeywordModal from "../KeywordModal";
import Select from "react-select";
import Downshift from "downshift";
import Url from "../../../../../api/Url";
import Request from "../../../../../api/Request";

class EditBiblio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materialtypes: this.props.materialtypes,
      mediatypes: this.props.mediatypes,
      languages: this.props.languages,
      biblio: this.props.biblio,
      geographical_terms: this.props.geographical_terms,
      authors: this.props.authors,

      addAuthorPopup: false,
      addKeywordPopup: false,
      addGeoPopup: false,
      popupName: this.props.popupName,
      popupType: this.props.popupType,
      author_types: [
        { value: "paresor", label: "Parësor" },
        { value: "dytesor", label: "Dytësor" },
        { value: "tretesor", label: "Tretësor" },
        { value: "autor", label: "Autor" },
      ],
      isOpenDownshift: false,
    };
  }

  addAuthorPopup = () => {
    this.setState({
      addAuthorPopup: true,
    });
  };

  closeAddAuthorPopup = (status) => {
    this.setState({ addAuthorPopup: status });
  };

  addKeywordPopup = () => {
    this.setState({
      addKeywordPopup: true,
    });
  };

  closeAddKeywordPopup = (status) => {
    this.setState({ addKeywordPopup: status });
  };

  addGeoPopup = () => {
    this.setState({
      addGeoPopup: true,
    });
  };

  closeAddGeoPopup = (status) => {
    this.setState({ addGeoPopup: status });
  };

  downshiftOnChange = (selectedBiblio) => {
    let request = Url.selectedBiblio + selectedBiblio.id;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.props.changeBiblio(response.data.biblio);
        this.setState({
          biblio: response.data.biblio,
        });
      }
    });
  };

  showDownshift = (e) => {
    this.setState({
      isOpenDownshift: true,
    });
  };

  hideDownshift = (e) => {
    this.setState({
      isOpenDownshift: false,
    });
  };

  render() {
    if (this.props.selectedAuthors.length !== 0) {
      var selectedAuthorsListName = this.props.selectedAuthors.map(
        (authors, index) => {
          return (
            <span key={index} className="tag-kyce mr-2">
              {authors.name} / {authors.type}
              <button
                type="button"
                className="ml-2 mr-1 pointer-li btn-unstyled x-button"
                onClick={(e) => this.props.removeAuthor(e, authors, index)}
              >
                ✕
              </button>
            </span>
          );
        }
      );
    }

    if (this.props.selectedKeywords.length !== 0) {
      var selectedKeywordListName = this.props.selectedKeywords.map(
        (keywords, index) => {
          return (
            <span key={index} className="tag-kyce mr-2">
              {keywords.name}
              <button
                type="button"
                className="ml-2 mr-1 pointer-li btn-unstyled x-button"
                onClick={(e) => this.props.removeKeyword(e, keywords)}
              >
                ✕
              </button>
            </span>
          );
        }
      );
    }

    if (this.props.selectedGeoTerms.length !== 0) {
      var selectedGeoTerms = this.props.selectedGeoTerms.map(
        (geoterm, index) => {
          return (
            <span key={index} className="tag-kyce mr-2">
              {geoterm.name}
              <button
                type="button"
                className="ml-2 mr-1 pointer-li btn-unstyled x-button"
                onClick={(e) => this.props.removeGeoTerm(e, geoterm)}
              >
                ✕
              </button>
            </span>
          );
        }
      );
    }
    return (
      <Fragment>
        <Downshift
          key={1}
          onChange={this.downshiftOnChange}
          itemToString={(item) => (item ? item.title : "")}
        >
          {({
            selectedItem,
            getInputProps,
            getItemProps,
            highlightedIndex,
            isOpen,
            inputValue,
          }) => (
            <form>
              <div className="titull title-container mb-4 pb-4">
                <label htmlFor="Titulli">Titull (245/A)</label>
                <div className="position-relative">
                  <input
                    {...getInputProps({
                      value: this.props.biblio.title
                        ? this.props.biblio.title
                        : "",
                      onChange: this.props.handleChange("title"),
                    })}
                    onFocus={this.showDownshift}
                    onBlur={this.hideDownshift}
                    onKeyUp={this.props.handleChange("title")}
                    type="text"
                    name="title"
                    placeholder="Titull"
                    className={
                      "input-form-input ui-autocomplete-input" +
                      (this.props.errors.title ? " form-error" : "")
                    }
                  />
                  {this.props.biblio_id !== "" && (
                    <Fragment>
                      <button
                        type="button"
                        className="float-right pointer-li btn-unstyled emptyState"
                        onClick={this.props.emptyState}
                      >
                        ✕
                      </button>
                    </Fragment>
                  )}

                  {this.props.errors.title && (
                    <span className="error-message pt-4">
                      {this.props.errors.title}
                    </span>
                  )}

                  {this.state.isOpenDownshift === true &&
                    this.props.biblio.title.length >= 3 && (
                      <Fragment>
                        {isOpen ? (
                          <div className="downshift-dropdown">
                            {this.props.biblios
                              .filter(
                                (item) =>
                                  !inputValue ||
                                  item.title
                                    .normalize("NFD")
                                    .replace(/[\u0300-\u036f]/g, "")
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase())
                              )
                              .slice(0, 10)
                              .map((item, index) => (
                                <div
                                  key={index}
                                  className="dropdown-item "
                                  {...getItemProps({
                                    key: index,
                                    index,
                                    item,
                                  })}
                                  style={{
                                    backgroundColor:
                                      highlightedIndex === index
                                        ? "#dcedff"
                                        : "white",
                                    fontWeight:
                                      selectedItem === item ? "bold" : "normal",
                                    color:
                                      selectedItem === item
                                        ? "#74767E"
                                        : "#74767E",
                                  }}
                                >
                                  {item.title}
                                </div>
                              ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    )}
                </div>
              </div>

              <div className="row mt-4 pt-2">
                <div className="autor col-12 pb-4 mb-4">
                  <button
                    type="button"
                    className="modal-button"
                    onClick={this.addAuthorPopup}
                  >
                    Shto Autor
                  </button>{" "}
                  <label htmlFor="Autor" style={{ width: "20px" }}>
                    (100/a)
                  </label>
                  <div className="tage w-100 borderi author-tags-container tag-container-fake-input">
                    {selectedAuthorsListName}
                  </div>
                  <AuthorModal
                    types={this.state.author_types}
                    popupName={"Autor"}
                    authors={this.props.authors}
                    showPopup={this.state.addAuthorPopup}
                    onPopupClose={this.closeAddAuthorPopup}
                    parentCallback={this.props.handleCallbackAuthor}
                  ></AuthorModal>
                  <div className="d-none author-hiddeninputfields-container"></div>
                </div>
              </div>

              <div className="row align-items-end">
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Medium">Medium (245/H)</label>
                  <input
                    type="text"
                    placeholder="Medium"
                    className={
                      "input-form-input" +
                      (this.props.errors.medium ? " form-error" : "")
                    }
                    name="medium"
                    onChange={this.props.handleChange("medium")}
                    value={
                      this.props.biblio.medium ? this.props.biblio.medium : ""
                    }
                  />
                  {this.props.errors.medium && (
                    <span className="error-message">
                      {this.props.errors.medium}
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Nentitull">Nëntitull (245/B)</label>
                  <input
                    placeholder="Shto Nentitull."
                    className={
                      "input-form-input" +
                      (this.props.errors.subtitle ? " form-error" : "")
                    }
                    name="subtitle"
                    type="text"
                    onChange={this.props.handleChange("subtitle")}
                    value={
                      this.props.biblio.subtitle
                        ? this.props.biblio.subtitle
                        : ""
                    }
                  />
                  {this.props.errors.subtitle && (
                    <span className="error-message">
                      {this.props.errors.subtitle}
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Lloji i materialit">Lloji i materialit</label>
                  <Select
                    className={
                      "basic-select" +
                      (this.props.errors.materialtype_id ? " form-error" : "")
                    }
                    classNamePrefix="select_input"
                    name="material_type"
                    placeholder="Përzgjidh"
                    value={
                      this.props.materialType.value === ""
                        ? ""
                        : this.props.materialType
                    }
                    onChange={this.props.handleChangeSelect(
                      "materialtype_id",
                      "materialType"
                    )}
                    isClearable={true}
                    menuPlacement="auto"
                    isSearchable={false}
                    options={this.state.materialtypes}
                  />
                  {this.props.errors.materialtype_id && (
                    <span className="error-message">
                      {this.props.errors.materialtype_id}
                    </span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Emri i pjeses">Emri i pjesës (245/N)</label>
                  <input
                    placeholder="Shto Emrin e Pjeses."
                    className="input-form-input"
                    name="part_name"
                    onChange={this.props.handleChange("part_name")}
                    type="text"
                    value={
                      this.props.biblio.part_name
                        ? this.props.biblio.part_name
                        : ""
                    }
                  />
                </div>
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Numri i pjeses">Numri i pjesës (245/P)</label>
                  <input
                    placeholder="Shto Numrin e Pjeses."
                    className="input-form-input"
                    name="part_number"
                    onChange={this.props.handleChange("part_number")}
                    type="text"
                    value={
                      this.props.biblio.part_number
                        ? this.props.biblio.part_number
                        : ""
                    }
                  />
                </div>
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Gjuha origjinale">
                    Gjuha origjinale (041/A)
                  </label>
                  <Select
                    className={
                      "basic-select" +
                      (this.props.errors.biblio_language_id
                        ? " form-error"
                        : "")
                    }
                    classNamePrefix="select_input"
                    name="biblio_language_id"
                    placeholder="Përzgjidh"
                    value={
                      this.props.language.value === ""
                        ? ""
                        : this.props.language
                    }
                    isClearable={true}
                    menuPlacement="auto"
                    onChange={this.props.handleChangeSelect(
                      "language_id",
                      "language"
                    )}
                    isSearchable={true}
                    options={this.state.languages}
                  />
                  {this.props.errors.biblio_language_id && (
                    <span className="error-message">
                      {this.props.errors.biblio_language_id}
                    </span>
                  )}
                </div>
              </div>

              <div className="row align-items-end">
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Kufizim moshe">Kufizim moshe (521/A)</label>
                  <input
                    placeholder="Shto Kufizim Moshe."
                    className="input-form-input"
                    name="agerestriction"
                    onChange={this.props.handleChange("agerestriction")}
                    type="text"
                    value={
                      this.props.biblio.agerestriction
                        ? this.props.biblio.agerestriction
                        : ""
                    }
                  />
                </div>
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Viti i botimit origjinal">
                    Viti i botimit origjinal
                  </label>
                  <input
                    id="year_original_publication"
                    placeholder="Shto vitin."
                    className={
                      "input-form-input" +
                      (this.props.errors.year_original_publication
                        ? " form-error"
                        : "")
                    }
                    name="year_original_publication"
                    onChange={this.props.handleChange(
                      "year_original_publication"
                    )}
                    type="number"
                    value={
                      this.props.biblio.year_original_publication
                        ? this.props.biblio.year_original_publication
                        : ""
                    }
                  />
                  {this.props.errors.year_original_publication && (
                    <span className="error-message">
                      {this.props.errors.year_original_publication}
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Lloji i medias">Lloji i medias</label>
                  <Select
                    className={
                      "basic-select" +
                      (this.props.errors.mediatype_id ? " form-error" : "")
                    }
                    classNamePrefix="select_input"
                    name="media_type"
                    placeholder="Përzgjidh"
                    onChange={this.props.handleChangeSelect(
                      "mediatype_id",
                      "mediaType"
                    )}
                    isClearable={true}
                    menuPlacement="auto"
                    value={
                      this.props.mediaType.value === ""
                        ? ""
                        : this.props.mediaType
                    }
                    isSearchable={true}
                    options={this.state.mediatypes}
                  />
                  {this.props.errors.mediatype_id && (
                    <span className="error-message">
                      {this.props.errors.mediatype_id}
                    </span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mb-4 pb-4">
                  <button
                    className="modal-button"
                    type="button"
                    onClick={this.addKeywordPopup}
                  >
                    Shto Fjalë-Kyçe
                  </button>{" "}
                  <label htmlFor="Fjale Kyce" style={{ width: "20px" }}>
                    (650/a)
                  </label>
                  <div className="tage w-100 borderi key-word-tags-container tag-container-fake-input">
                    {selectedKeywordListName}
                  </div>
                  <KeywordModal
                    key_words={this.props.key_words}
                    showPopup={this.state.addKeywordPopup}
                    onPopupClose={this.closeAddKeywordPopup}
                    parentCallback={this.props.handleKeyword}
                  />
                  <div className="d-none keyword-hiddeninputfields-container"></div>
                </div>

                <div className="col-12 col-md-6 mb-4 pb-4">
                  <button
                    className="modal-button"
                    type="button"
                    onClick={this.addGeoPopup}
                  >
                    Shto Terma Gjeografik
                  </button>{" "}
                  <label htmlFor="Terma Gjeografik" style={{ width: "20px" }}>
                    (651/a)
                  </label>
                  <div className="tage w-100 borderi geo-terms-tags-container tag-container-fake-input">
                    {selectedGeoTerms}
                  </div>
                  <GeographicTermsModal
                    geographical_terms={this.props.geographical_terms}
                    showPopup={this.state.addGeoPopup}
                    onPopupClose={this.closeAddGeoPopup}
                    parentCallback={this.props.handleGeoTerm}
                  />
                </div>
              </div>

              <div className="row align-items-end">
                <div className="col-12 col-md-6 mb-4 pb-4">
                  <label htmlFor="Abstract">Abstract (520/A)</label>
                  <textarea
                    placeholder="Shto abstract"
                    cols="30"
                    rows="10"
                    className="input-form-input tage"
                    name="abstract"
                    onChange={this.props.handleChange("abstract")}
                    value={
                      this.props.biblio.abstract
                        ? this.props.biblio.abstract
                        : ""
                    }
                  ></textarea>
                </div>
                <div className="col-12 col-md-6 mb-4 pb-4">
                  <label htmlFor="Shenime">Shënime (500/A)</label>
                  <textarea
                    placeholder="Shto shenime"
                    cols="30"
                    rows="10"
                    className="input-form-input tage"
                    name="notes"
                    onChange={this.props.handleChange("notes")}
                    value={
                      this.props.biblio.notes ? this.props.biblio.notes : ""
                    }
                  ></textarea>
                </div>
              </div>
            </form>
          )}
        </Downshift>
      </Fragment>
    );
  }
}

export default withRouter(EditBiblio);
