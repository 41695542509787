import React, { Component, Fragment } from "react";
import Url from "../../../api/Url";
import Request from "../../../api/Request";
import Messages from "../../../helpers/Messages";
import Web from "../../../routes/Web";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import LoadingGif from "../../../assets/images/icons/loading.gif";
class CreateAcquisition extends Component {
  constructor() {
    super();
    this.state = {
      createAcquisition: {
        title: "",
        author: "",
        copies: "",
        seller_donor: "",
        expected_on: "",
        comments: "",
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
    };
  }

  handleChangeInput = (key) => (e) => {
    let newForm = {
      ...this.state.createAcquisition,
      [key]: e.target.value,
    };
    this.setState({
      createAcquisition: newForm,
    });
  };
  handleChangeInputD = (key) => (e) => {
    let newForm = {
      ...this.state.createAcquisition,
      [key]: moment(new Date(e)).format("DD-MM-yyyy"),
    };
    this.setState({
      createAcquisition: newForm,
    });
  };

  saveAcquisition = (e) => {
    e.preventDefault();
    this.setState({
      btnLoading: true,
    });
    Request.postRequest(
      Url.storeAcquisition,
      this.state.createAcquisition
    ).then((response) => {
      this.setState({
        btnLoading: false,
      });
      if (response.status === 201) {
        this.props.history.push(Web.listAcquisition);
        Messages.swalSuccess(response.data.success);
        this.setState({
          createAcquisition: {
            title: "",
            author: "",
            copies: "",
            seller_donor: "",
            expected_on: "",
            comments: "",
          },
          form_errors: {
            error_list: [],
          },
        });
      } else {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div className="create-acquisition">
            <p className="partial-title big-divide-bottom">Pasurim i Ri</p>
            <form onSubmit={this.saveAcquisition}>
              <div className="row">
                <div className="col-12 col-sm-6 flex-column col-form">
                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="title">Titull</label>
                    <input
                      placeholder="Shto titull"
                      className={
                        "input-form-input " +
                        (this.state.form_errors.error_list.title
                          ? " form-error"
                          : "")
                      }
                      name="title"
                      type="text"
                      id="title"
                      onChange={this.handleChangeInput("title")}
                    />
                    {this.state.form_errors.error_list.title && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.title[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="author">Autor</label>
                    <input
                      placeholder="Shto Autor"
                      className={
                        "input-form-input " +
                        (this.state.form_errors.error_list.author
                          ? " form-error"
                          : "")
                      }
                      name="author"
                      type="text"
                      id="author"
                      onChange={this.handleChangeInput("author")}
                    />
                    {this.state.form_errors.error_list.author && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.author[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="copies">Numër kopjesh</label>
                    <input
                      id="copies"
                      placeholder="Shto numrin e kopjeve"
                      className={
                        "input-form-input " +
                        (this.state.form_errors.error_list.copies
                          ? " form-error"
                          : "")
                      }
                      name="copies"
                      type="number"
                      onChange={this.handleChangeInput("copies")}
                    />
                    {this.state.form_errors.error_list.copies && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.copies[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="seller_donor">Dhuruesi / Shitësi</label>
                    <input
                      placeholder="Shto Dhuruesin / Shitësin"
                      className={
                        "input-form-input " +
                        (this.state.form_errors.error_list.seller_donor
                          ? " form-error"
                          : "")
                      }
                      id="seller_donor"
                      name="seller_donor"
                      type="text"
                      onChange={this.handleChangeInput("seller_donor")}
                    />
                    {this.state.form_errors.error_list.seller_donor && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.seller_donor[0]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-sm-6 flex-column col-form ">
                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="expected_on">
                      Data e ardhjes
                    </label>
                    <DatePicker
                      placeholderText="Zgjidh datën"
                      name="from"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale={sq}
                      dateFormat="DD-MM-yyyy"
                      value={this.state.createAcquisition.expected_on}
                      onChange={this.handleChangeInputD("expected_on")}
                      autoComplete="off"
                      className={
                        "date_input" +
                        (this.state.form_errors.error_list.expected_on
                          ? " form-error"
                          : "")
                      }
                    />
                    {this.state.form_errors.error_list.expected_on && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.expected_on[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="comments">Komente</label>
                    <textarea
                      placeholder="Shto Komente"
                      className={
                        "input-form-input tage " +
                        (this.state.form_errors.error_list.comments
                          ? " form-error"
                          : "")
                      }
                      name="comments"
                      cols="50"
                      rows="10"
                      id="comments"
                      onChange={this.handleChangeInput("comments")}
                    />

                    {this.state.form_errors.error_list.comments && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.comments[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-3 mt-sm-3 mt-auto position-submit-acq-button offset-7">
                    {this.state.btnLoading === true ? (
                      <button
                        className="btn-styled-2 w-100"
                        type="button"
                        value=""
                      >
                        {" "}
                        Duke u Ruajtur
                        <img
                          src={LoadingGif}
                          style={{
                            width: "18px",
                            margin: "-4px 0 0 7px",
                          }}
                        />
                      </button>
                    ) : (
                      <button className="btn-styled-2 w-100" type="submit">
                        Ruaj
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(CreateAcquisition);
