import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/opac/partials/Header";
import Footer from "../../components/opac/partials/Footer";
import AdvSearchResults from "../../components/opac/AdvSearchResults";

class AdvSearchResultsView extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Rezultatet e kërkimit</title>
                </Helmet>
                <div style={{ width: 100 + "%" }}>
                    <Header
                        roleUser={this.props.roles}
                        search={true}
                    />
                    <AdvSearchResults/>
                    <Footer />
                </div>
            </Fragment>
        );
    }
}
export  default  AdvSearchResultsView;