import React, { Component, Fragment } from "react";
import Pagination from "react-js-pagination";
import Url from "../../api/Url";
import Paginate from "../../helpers/Paginate";
import AppConfig from "../../services/AppConfig";
import Messages from "../../helpers/Messages";
import Request from "../../api/Request";
import SearchFilter from "../../helpers/SearchFilter";
import {
	FacebookShareButton,
	WhatsappShareButton,
	LinkedinShareButton,
	TwitterShareButton,
} from "react-share";

import noPostImg from "../../assets/images/covers/Artikuj.svg";
import LinkedinIcon from "../../assets/images/icons/linkedin_icon.svg";
import FacebookIcon from "../../assets/images/icons/facebook_icon.svg";
import WhatsappIcon from "../../assets/images/icons/whatsapp_icon.svg";
import TwitterIcon from "../../assets/images/icons/twitter_icon.svg";
import loadingGif from "../../assets/images/icons/loading.gif";
import { Link } from "react-router-dom";
import Web from "../../routes/Web";

class News extends Component {
	constructor() {
		super();
		this.state = {
			listNews: {
				news: [],
				loading: true,
			},
			share_url:"",
			pagination: Paginate.setPaginate(),
		};
	}

	componentDidMount() {
		this.listNews();
	}

	listNews = () => {
		let paginate = 1;
		let request = Url.blogPosts;
		Request.getRequest(request + paginate).then((response) => {
			if (response) {
				if (response.status === 200) {
					this.setState({
						listNews: {
							news: response.data.posts.data,
							loading: false,
						},
						share_url: response.data.share_url,
						pagination: Paginate.setPaginateData(response.data.posts),
					});
				} else {
					Messages.swalError(AppConfig.serverError);
				}
			} else {
				Messages.swalError(AppConfig.serverError);
			}
		});
	};

	handlePageChange = (pageNumber) => {
		let request = Url.blogPosts + 1;
		var searchParams = {
			page: pageNumber,
		};
		request = SearchFilter.setFilterParams(searchParams, request);

		Request.getRequest(request).then((response) => {
			this.setState({
				listNews: {
					news: response.data.posts.data,
					loading: false,
				},
				pagination: Paginate.setPaginateData(response.data.posts),
			});
		});
		this.setState({ activePage: pageNumber });
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	render() {
		var blogList = "";
		if (this.state.listNews.loading === true) {
			blogList = (
				<img className="m-auto d-block mb-5" src={loadingGif} alt="loading" />
			);
		} else {
			blogList = this.state.listNews.news.map((post, index) => {
				return (
					<div key={index} className="row mb-5 mx-0 news_div">
						<div className="col-12 col-sm-4 px-0 pr-sm-3 news_image">
							{post.intro_image === null ? (
								<img
									className="article-img"
									src={noPostImg}
									alt={post.title}
									width="100%"
									height="300px"
								/>
							) : (
								<img
									className="file-upload-image"
									src={AppConfig.blogPath + post.intro_image}
									alt={post.title}
									width="100%"
									height="300px"
								/>
							)}
						</div>
						<div className="col-12 col-sm-8 mb-4 mb-sm-0 p-0 news_info">
							<div className="article-wrapper p-3 p-sm-5">
								<h3 className="article-title">{post.title}</h3>
								<p className="article-excerpt">
									{post.description.replace(/<[^>]+>/g, "")}
								</p>
								<div className="d-flex mt-auto flex-wrap">
									<div className="col-12 col-sm-6 p-0 mb-3 mb-sm-0">
									<WhatsappShareButton
										url={this.state.share_url+"/share/news/"+post.id}
										className="mr-3 btn-unstyled"
									>
										<img src={WhatsappIcon} />
									</WhatsappShareButton>
									<TwitterShareButton
										url={this.state.share_url+"/share/news/"+post.id}
										className="mr-3 btn-unstyled"
									>
										<img src={TwitterIcon} />
									</TwitterShareButton>
									<FacebookShareButton
										url={this.state.share_url+"/share/news/"+post.id}
										className="mr-3 btn-unstyled"
									>
										<img src={FacebookIcon} />
									</FacebookShareButton>
									<LinkedinShareButton
										url={this.state.share_url+"/share/news/"+post.id}
										className="btn-unstyled"
									>
										<img src={LinkedinIcon} />
									</LinkedinShareButton>
									</div>
									<Link
										to={
											Web.newsPost +
											post.id +
											"-" +
											post.title.replace(/ /g, "-")
										}
										className="px-5 btn-styled-2 ml-auto mr-auto mr-sm-0"
									>
										Lexo
									</Link>
								</div>
							</div>
						</div>
					</div>
				);
			});
		}
		return (
			<Fragment>
				<div id="content">
					<div id="news" className="bl_page">
						<div
							className={
								this.state.listNews.loading === true ? "mt-4 mb-5" : "mt-4"
							}
						>
							<div className="b--breadcrumb">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb pl-0">
										<li className="breadcrumb-item">
											<Link to={Web.homepage}>Biblioteka</Link>
										</li>
										<li
											className="breadcrumb-item active b--title"
											aria-current="page"
										>
											Lajme
										</li>
									</ol>
								</nav>
							</div>
							{blogList}
						</div>
						{this.state.pagination.totalPages > 1 && (
							<div className="d-flex justify-content-center flex-wrap pagination-block mb-5">
								<div className="select-page">
									<Pagination
										activePage={this.state.pagination.activePage}
										itemsCountPerPage={this.state.pagination.itemsCountPerPage}
										totalItemsCount={this.state.pagination.totalItemsCount}
										pageRangeDisplayed={
											this.state.pagination.pageRangeDisplayed
										}
										onChange={this.handlePageChange}
										itemClass="page-item"
										linkClass="page-link"
									/>
								</div>
								<div className="pagination-results">
									{"Faqja: " +
										this.state.pagination.activePage +
										" - " +
										this.state.pagination.totalPages}
									<br />
									{"Gjithsej rezultate: " +
										this.state.pagination.totalItemsCount}
								</div>
							</div>
						)}
					</div>
				</div>
			</Fragment>
		);
	}
}

export default News;
