import React, { Component } from "react";
import Url from "../../../api/Url";
import Request from "../../../api/Request";
import { withRouter } from "react-router-dom";
import loadingGif from "../../../assets/images/icons/loading.gif";
import Messages from "../../../helpers/Messages";
import AppConfig from "../../../services/AppConfig";

class FilterSidebar extends Component {
	componentDidMount() {
		//this.setSidebarData();

		//Create object in location props
		if (!this.props.location.state.filterProps) {
			this.props.history.push({
				state: {
					...this.props.location.state,
					filterProps: {
						author: "",
						viti_i_botimit: "",
						vendi_i_botimit: "",
						gjuha: "",
						botuesi: "",
						fjalekyce: "",
					},
				},
			});
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.biblioItemsFiltersId !== this.props.biblioItemsFiltersId) {
			if (this.props.biblioItemsFiltersId.length !== 0) {
				if (!this.props.location.state.filterProps) {
					this.props.history.push({
						state: {
							...this.props.location.state,
							filterProps: {
								author: "",
								viti_i_botimit: "",
								vendi_i_botimit: "",
								gjuha: "",
								botuesi: "",
								fjalekyce: "",
							},
						},
					});
				}

				this.getFilterSidebarData();
			}
		}
	}

	constructor(props) {
		super(props);
		this.state = {
			filter: {
				uniqueAuthors: [],
				uniquePublicationYears: [],
				uniquePlaces: [],
				uniqueLanguages: [],
				uniquePublishers: [],
				uniqueKeywords: [],
				loading: true,
			},
		};
	}

	getFilterSidebarData = () => {
		const request = Url.filter;

		var ids = this.props.biblioItemsFiltersId;

		const formData = new FormData();

		ids.map((id, index) => {
			formData.append(`biblioItemsFiltersId[${index}]`, id);
		});

		Request.postRequest(request, formData).then((response) => {
			if (response.status === 200) {
				this.setState({
					filter: {
						uniqueAuthors: response.data.uniqueAuthors,
						uniquePublicationYears: response.data.uniquePublicationYears,
						uniquePlaces: response.data.uniquePlaces,
						uniqueLanguages: response.data.uniqueLanguages,
						uniquePublishers: response.data.uniquePublishers,
						uniqueKeywords: response.data.uniqueKeywords,
						loading: false,
					},
				});
			}
			// else {
			//     Messages.swalError(AppConfig.serverError);
			// }
		});
	};

	setAuthor = (e, uniqueAuthor) => {
		e.preventDefault();
		this.props.filterPropsAuthor(uniqueAuthor);

		//Update props location (author)
		this.props.history.push({
			state: {
				...this.props.location.state,
				filterProps: {
					...this.props.location.state.filterProps,
					author: uniqueAuthor,
				},
				page: 0,
			},
		});
	};

	setPublicationYear = (e, uniquePublicationYear) => {
		e.preventDefault();
		this.props.filterPropsPublicationYear(uniquePublicationYear);

		//Update props location (viti_i_botimit)
		this.props.history.push({
			state: {
				...this.props.location.state,
				filterProps: {
					...this.props.location.state.filterProps,
					viti_i_botimit: uniquePublicationYear,
				},
				page: 0,
			},
		});
	};

	setPlace = (e, uniquePlace) => {
		e.preventDefault();
		this.props.filterPropsPlace(uniquePlace);

		//Update props location (vendi_i_botimit)
		this.props.history.push({
			state: {
				...this.props.location.state,
				filterProps: {
					...this.props.location.state.filterProps,
					vendi_i_botimit: uniquePlace,
				},
				page: 0,
			},
		});
	};

	setLanguage = (e, uniqueLanguage) => {
		e.preventDefault();
		this.props.filterPropsLanguage(uniqueLanguage);

		//Update props location (gjuha)
		this.props.history.push({
			state: {
				...this.props.location.state,
				filterProps: {
					...this.props.location.state.filterProps,
					gjuha: uniqueLanguage,
				},
				page: 0,
			},
		});
	};

	setPublisher = (e, uniquePublisher) => {
		e.preventDefault();
		this.props.filterPropsPublisher(uniquePublisher);

		//Update props location (botuesi)
		this.props.history.push({
			state: {
				...this.props.location.state,
				filterProps: {
					...this.props.location.state.filterProps,
					botuesi: uniquePublisher,
				},
				page: 0,
			},
		});
	};

	setKeyword = (e, uniqueKeyword) => {
		e.preventDefault();
		this.props.filterPropsKeyword(uniqueKeyword);

		//Update props location (fjalekyce)
		this.props.history.push({
			state: {
				...this.props.location.state,
				filterProps: {
					...this.props.location.state.filterProps,
					fjalekyce: uniqueKeyword,
				},
				page: 0,
			},
		});
	};

	render() {
		var biblioItem_ids = this.props.biblioItemsFiltersId.length;

		if (this.state.filter.loading) {
			return <img className="m-auto d-block" src={loadingGif} alt="loading" />;
		} else {
			var uniqueAuthors = this.state.filter.uniqueAuthors.map(
				(uniqueAuthor, index) => {
					return (
						<li key={index}>
							<a
								data-type="author"
								data-id={index}
								className="row w-100 m-display"
								onClick={(e) => this.setAuthor(e, uniqueAuthor.key)}
								href=""
							>
								<div className="col-8">{uniqueAuthor.name}</div>
								<div className="col-4 m-padding">{uniqueAuthor.value}</div>
							</a>
						</li>
					);
				},
			);

			var uniquePublicationYears = this.state.filter.uniquePublicationYears.map(
				(uniquePublicationYear, index) => {
					return (
						<li key={index}>
							<a
								data-type="uniquePublicationYear"
								data-id={index}
								className="row w-100 m-display"
								onClick={(e) =>
									this.setPublicationYear(e, uniquePublicationYear.key)
								}
								href=""
							>
								<div className="col-8">{uniquePublicationYear.key}</div>
								<div className="col-4 m-padding">
									{uniquePublicationYear.value}
								</div>
							</a>
						</li>
					);
				},
			);

			var uniquePlaces = this.state.filter.uniquePlaces.map(
				(uniquePlace, index) => {
					return (
						<li key={index}>
							<a
								data-type="uniquePlace"
								data-id={index}
								className="row w-100 m-display"
								onClick={(e) => this.setPlace(e, uniquePlace.key)}
								href="#"
							>
								<div className="col-8">{uniquePlace.key}</div>
								<div className="col-4 m-padding">{uniquePlace.value}</div>
							</a>
						</li>
					);
				},
			);

			var uniqueLanguages = this.state.filter.uniqueLanguages.map(
				(uniqueLanguage, index) => {
					return (
						<li key={index}>
							<a
								data-type="uniqueLanguage"
								data-id={index}
								className="row w-100 m-display"
								onClick={(e) => this.setLanguage(e, uniqueLanguage.key)}
								href=""
							>
								<div className="col-8">{uniqueLanguage.name}</div>
								<div className="col-4 m-padding">{uniqueLanguage.value}</div>
							</a>
						</li>
					);
				},
			);

			var uniquePublishers = this.state.filter.uniquePublishers.map(
				(uniquePublisher, index) => {
					return (
						<li key={index}>
							<a
								data-type="uniquePublisher"
								data-id={index}
								className="row w-100 m-display"
								onClick={(e) => this.setPublisher(e, uniquePublisher.key)}
								href=""
							>
								<div className="col-8">{uniquePublisher.name}</div>
								<div className="col-4 m-padding">{uniquePublisher.value}</div>
							</a>
						</li>
					);
				},
			);

			var uniqueKeywords = this.state.filter.uniqueKeywords.map(
				(uniqueKeyword, index) => {
					return (
						<li key={index}>
							<a
								data-type="uniqueKeyword"
								data-id={index}
								className="row w-100 m-display"
								onClick={(e) => this.setKeyword(e, uniqueKeyword.key)}
								href=""
							>
								<div className="col-8">{uniqueKeyword.name}</div>
								<div className="col-4 m-padding">{uniqueKeyword.value}</div>
							</a>
						</li>
					);
				},
			);
		}

		return (
			<nav id="sidebar1">
				{biblioItem_ids !== 0 && (
					<ul className="list-unstyled components">
						<li className="active">
							<a
								href="#autoretSubmenu"
								data-toggle="collapse"
								aria-expanded="false"
								className="dropdown-toggle autor-nav"
							>
								Autoret
							</a>
							<ul className="collapse list-unstyled" id="autoretSubmenu">
								{uniqueAuthors}
							</ul>
						</li>
						<li>
							<a
								href="#vitiSubmenu"
								data-toggle="collapse"
								aria-expanded="false"
								className="dropdown-toggle"
							>
								Viti i botimit
							</a>
							<ul className="collapse list-unstyled" id="vitiSubmenu">
								{uniquePublicationYears}
							</ul>
						</li>
						<li>
							<a
								href="#vendiSubmenu"
								data-toggle="collapse"
								aria-expanded="false"
								className="dropdown-toggle"
							>
								Vendi i botimit
							</a>
							<ul className="collapse list-unstyled" id="vendiSubmenu">
								{uniquePlaces}
							</ul>
						</li>
						<li>
							<a
								href="#gjuhaSubmenu"
								data-toggle="collapse"
								aria-expanded="false"
								className="dropdown-toggle"
							>
								Gjuha
							</a>
							<ul className="collapse list-unstyled" id="gjuhaSubmenu">
								{uniqueLanguages}
							</ul>
						</li>
						<li>
							<a
								href="#botuesSubmenu"
								data-toggle="collapse"
								aria-expanded="false"
								className="dropdown-toggle"
							>
								Botuesi
							</a>
							<ul className="collapse list-unstyled" id="botuesSubmenu">
								{uniquePublishers}
							</ul>
						</li>

						<li>
							<a
								href="#kyceSubmenu"
								data-toggle="collapse"
								aria-expanded="false"
								className="dropdown-toggle"
							>
								Fjale kyce
							</a>
							<ul className="collapse list-unstyled" id="kyceSubmenu">
								{uniqueKeywords}
							</ul>
						</li>
					</ul>
				)}
			</nav>
		);
	}
}

export default withRouter(FilterSidebar);
