class Paginate {

    static setPaginate(){
        var pagination = {
                activePage: 0,
                itemsCountPerPage: 0,
                totalItemsCount: 0,
                pageRangeDisplayed: 5,
                totalPages: 0,
        }
        return pagination;
    }

    static setPaginateData(param){
           var paginateData = {
               itemsCountPerPage: param.per_page,
               totalItemsCount: param.total,
               activePage: param.current_page,
               totalPages: param.last_page,
           }

           return paginateData;
    }

    static setPaginateDataProps(param,activePage){
        let paginateData = {
            itemsCountPerPage: param.per_page,
            totalItemsCount: param.total,
            activePage: activePage,
            totalPages: param.last_page,
        }

        return paginateData;
    }

}

export default Paginate;
