import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import Request from "../../api/Request";
import Url from "../../api/Url";
import Web from "../../routes/Web";
import Messages from "../../helpers/Messages";
import AppConfig from "../../services/AppConfig";
import Description from "./detailed/Description";
import ISBD from "./detailed/ISBD";
import MARC from "./detailed/MARC";
import Backward from "../../assets/images/icons/Backward-arrow-small.svg";
import BackIcon from "../../assets/images/icons/back-icon.svg";
import loadingGif from "../../assets/images/icons/loading.gif";
import libra from "../../assets/images/covers/Libra.svg";
import Disertacione from "../../assets/images/covers/Disertacione.svg";
import Periodike from "../../assets/images/covers/Periodike.svg";
import Harta from "../../assets/images/covers/Harta.svg";
import Doreshkrime from "../../assets/images/covers/Doreshkrime.svg";
import Dokument from "../../assets/images/covers/Dokument.svg";
import Artikuj from "../../assets/images/covers/Artikuj.svg";
import Materiale_audio_vizuale from "../../assets/images/covers/Materiale_audio-vizuale.svg";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import WhatsappIcon from "../../assets/images/icons/whatsapp_icon.svg";
import TwitterIcon from "../../assets/images/icons/twitter_icon.svg";
import FacebookIcon from "../../assets/images/icons/facebook_icon.svg";
import LinkedinIcon from "../../assets/images/icons/linkedin_icon.svg";
import ShareIcon from "../../assets/images/icons/share-icon.svg";

class Detailed extends Component {
  componentDidMount() {
    this.document();
    this.canreserve();
    this.getBiblioitem();
    if (this.props.location.state) {
      this.setState({
        prevUrl: this.props.location.state.prevUrl,
      });
    }
  }

  constructor() {
    super();
    this.state = {
      details: {
        biblioitem: [],
        items: [],
        loading: true,
      },
      status: "",
      id: "",
      doc: "",
      tab: {
        selected: "description",
      },
      prevUrl: "",
      shareBtn: false,
      shareUrl: "",
    };
  }

  canreserve = () => {
    const biblioitem_id = this.props.match.params.id;
    Request.getRequestfordflip(Url.getstatusreserve + biblioitem_id).then(
      (response) => {
        if (response === null) {
          this.setState({
            status: 0,
          });
        } else if (response.status === 200) {
          this.setState({
            status: response.data.status,
          });
        }
      }
    );
  };

  document = () => {
    const biblioitem_id = this.props.match.params.id;

    Request.getRequestfordflip(Url.getdocument + biblioitem_id).then(
      (response) => {
        if (response === null) {
          this.setState({
            doc: "",
          });
        } else if (response.status === 200) {
          this.setState({
            doc: response.data,
          });
          // document.getElementById('doc').setAttribute('srcDoc', response.data);
        }
      }
    );
  };

  getBiblioitem = () => {
    const biblioitem_id = this.props.match.params.id;

    Request.getRequest(Url.detailed + biblioitem_id).then((response) => {
      if (response) {
        if (response.status === 200) {
          this.setState({
            details: {
              biblioitem: response.data.biblioitem,
              items: response.data.biblioitem.items,
              loading: false,
              status: response.data.status,
            },
            shareUrl: response.data.share_url,
            id: biblioitem_id,
          });
        } else {
          Messages.swalError(AppConfig.serverError);
        }
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  descriptionTab = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        selected: "description",
      },
    });
  };

  isbdTab = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        selected: "isbd",
      },
    });
  };

  marcTab = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        selected: "marc",
      },
    });
  };

  reserve = () => {
    const biblioitem_id = this.props.match.params.id;
    var request = {
      biblioitem_id: biblioitem_id,
    };
    Request.postRequest(Url.reserve, request).then((response) => {
      if (response.status === 200) {
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 403) {
        Messages.swalSuccess(response.data.error);
      } else if (response.status === 404) {
        Messages.swalSuccess(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
    this.canreserve();
  };

  checkCover = (cover, material_type) => {
    if (cover !== null) {
      return AppConfig.biblioCoversPath + cover;
    }
    if (cover === null) {
      switch (material_type) {
        case 2:
          return libra;
        case 6:
          return Disertacione;
        case 3:
          return Periodike;
        case 5:
          return Harta;
        case 4:
          return Doreshkrime;
        case 8:
          return Dokument;
        case 1:
          return Artikuj;
        case 7:
          return Materiale_audio_vizuale;
        default:
          return "";
      }
    }
  };

  goBackPage = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  shareBook = (e) => {
    e.preventDefault();
    if (this.state.shareBtn === false) {
      this.setState({
        shareBtn: true,
      });
    } else {
      this.setState({
        shareBtn: false,
      });
    }
  };

  checkBorrownigType = (borrowingType) => {
    switch (borrowingType) {
      case "salle":
        return "Sallë";
      case "normal":
        return "Normal";
      case "ipadisponueshem":
        return "I padisponueshëm";
      default:
        return "";
    }
  };

  render() {
    var items = "";
    var authors = "";
    if (this.state.details.loading) {
      items = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      authors = this.state.details.biblioitem.biblio.authors.map((author) => {
        return author.name;
      });

      items = this.state.details.items.map((item, index) => {
        return (
          <tr key={index}>
            <td data-label="Kodifikimi">{item.inventory_number}</td>
            <td data-label="Numri i inventarit">{item.id}</td>
            <td data-label="Numri i vendit">{item.place_number}</td>
            <td data-label="Lloji i huazimit">
              {item.borrowing_type
                ? this.checkBorrownigType(item.borrowing_type)
                : "-"}
            </td>
          </tr>
        );
      });
    }

    var data = "";
    var cover = "";
    if (this.state.details.loading === true) {
      data = <img className="ml-5 d-block" src={loadingGif} alt="loading" />;
    } else {
      var materialType = this.state.details.biblioitem.biblio.materialtype.id;
      cover = this.state.details.biblioitem.cover;
      data = (
        <Fragment>
          <div className="col-12 mb-2 material-type">
            {this.state.details.biblioitem.biblio.materialtype.name}
          </div>
          <div className="col-12 col-md-10 mb-2 material-title">
            {this.state.details.biblioitem.biblio.title +
              (this.state.details.biblioitem.biblio.subtitle
                ? " : " + this.state.details.biblioitem.biblio.subtitle
                : "")}
          </div>
          <div className="col-12 material-author">{authors.join("; ")}</div>
        </Fragment>
      );

      cover = (
        <div className="col-12 mb-4 d-block d-md-none image-holder text-center">
          <img src={this.checkCover(cover, materialType)} alt="cover" />
        </div>
      );
    }

    return (
      <Fragment>
        <div className="bl_page">
          <div className="container-fluid">
            <div className="row m-0">
              <div className="table-detail-tabs w-100">
                <div className="b--breadcrumb mt-4">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pl-0">
                      <li className="breadcrumb-item">
                        <Link to={Web.homepage}>Biblioteka</Link>
                      </li>
                      {this.state.prevUrl === Web.search ||
                      this.state.prevUrl === Web.advanced_search ? (
                        <li className="breadcrumb-item">
                          <a href="" onClick={this.goBackPage}>
                            Rezultatet e kërkimit
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      <li
                        className="breadcrumb-item active b--title"
                        aria-current="page"
                      >
                        Të dhëna për materialin
                      </li>
                    </ol>
                  </nav>
                </div>

                <div className="d-flex flex-column ml-md-5 ml-lg-0 flex-md-row align-items-center position-relative">
                  <a href="" onClick={this.goBackPage} className="back-btn">
                    <img className="d-none d-md-block" src={Backward} />
                    <img className="d-md-none" src={BackIcon} />
                  </a>
                  <div className="row col-12 col-md-10 p-0 detailed-biblio-infohead">
                    {data}
                  </div>

                  {/*Share*/}
                  <div className="col-md-2 share-btn float-right">
                    {this.state.shareBtn === true && (
                      <div className="networks-share">
                        <div className="networks-share">
                          <WhatsappShareButton
                            url={this.state.shareUrl}
                            className="mr-3 btn-unstyled"
                          >
                            <img src={WhatsappIcon} />
                          </WhatsappShareButton>
                          <TwitterShareButton
                            url={this.state.shareUrl}
                            className="mr-3 btn-unstyled"
                          >
                            <img src={TwitterIcon} />
                          </TwitterShareButton>
                          <FacebookShareButton
                            url={this.state.shareUrl}
                            className="mr-3 btn-unstyled"
                          >
                            <img src={FacebookIcon} />
                          </FacebookShareButton>
                          <LinkedinShareButton
                            url={this.state.shareUrl}
                            className="btn-unstyled"
                          >
                            <img src={LinkedinIcon} />
                          </LinkedinShareButton>
                        </div>
                      </div>
                    )}

                    <div className="btn-share-b">
                      <a
                        className="btn-share-show"
                        onClick={this.shareBook}
                        href=""
                      >
                        <img src={ShareIcon} />
                        Share
                      </a>
                    </div>
                  </div>
                </div>

                <div className="data-tabs col-se mb-4">
                  {cover}
                  <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                    <li
                      className="nav-item col-12 col-md-4 p-0"
                      role="përshkrimi"
                    >
                      <a
                        className="nav-link active"
                        id="përshkrimi-tab"
                        data-toggle="tab"
                        href="#përshkrimi"
                        role="tab"
                        aria-controls="përshkrimi"
                        onClick={(e) => this.descriptionTab(e)}
                        aria-selected="true"
                      >
                        përshkrimi
                      </a>
                    </li>
                    <li className="nav-item col-12 col-md-4 p-0" role="isbd">
                      <a
                        className="nav-link middle-tab"
                        id="isbd-tab"
                        data-toggle="tab"
                        href="#isbd"
                        role="tab"
                        aria-controls="isbd"
                        onClick={(e) => this.isbdTab(e)}
                        aria-selected="false"
                      >
                        isbd
                      </a>
                    </li>
                    <li className="nav-item col-12 col-md-4 p-0" role="marc">
                      <a
                        className="nav-link"
                        id="marc-tab"
                        data-toggle="tab"
                        href="#marc"
                        role="tab"
                        aria-controls="marc"
                        onClick={(e) => this.marcTab(e)}
                        aria-selected="false"
                      >
                        marc
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="tab-content" id="myTabContent">
                  {this.state.details.loading === false && (
                    <Fragment>
                      {this.state.tab.selected === "description" && (
                        <Description
                          biblioitem_id={this.props.match.params.id}
                        ></Description>
                      )}
                      {this.state.tab.selected === "isbd" && (
                        <ISBD biblioitem_id={this.props.match.params.id}></ISBD>
                      )}
                      {this.state.tab.selected === "marc" && (
                        <MARC biblioitem_id={this.props.match.params.id}></MARC>
                      )}
                    </Fragment>
                  )}
                </div>

                {/*items */}

                <div className="specimen-data mb-md-5">
                  <div className="py-4 detailed-biblio-infohead h-table">
                    <div className="col-12 material-title">
                      Te dhëna për ekzemplarin
                    </div>
                  </div>

                  <div className="table-list-show mb-5">
                    <table role="table" className="w-100">
                      <thead role="rowgroup">
                        <tr role="row">
                          <th
                            role="columnheader"
                            width="25%"
                            className="text-center"
                          >
                            Kodifikimi
                          </th>
                          <th
                            role="columnheader"
                            width="25%"
                            className="text-center"
                          >
                            Numri i inventarit
                          </th>
                          <th
                            role="columnheader"
                            width="25%"
                            className="text-center"
                          >
                            Numri i vendit
                          </th>
                          <th
                            role="columnheader"
                            width="25%"
                            className="text-center"
                          >
                            Lloji i huazimit
                          </th>
                        </tr>
                      </thead>
                      <tbody role="rowgroup">{items}</tbody>
                    </table>
                  </div>
                  {this.state.status === 1 && (
                    <Fragment>
                      <button
                        className="btn-styled-2 ml-auto px-5"
                        onClick={(e) => this.reserve()}
                      >
                        Rezervo
                      </button>
                    </Fragment>
                  )}
                </div>

                {/*items*/}
                {this.state.doc !== "" && (
                  <div className="mb-5">
                    <iframe
                      id="doc"
                      frameBorder="0"
                      border="0"
                      scrolling="no"
                      width="100%"
                      srcDoc={this.state.doc}
                      className="pdf_reader"
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(Detailed);
