import { Component, Fragment, React } from "react";
import Url from "../../../../api/Url";
import Request from "../../../../api/Request";
import Web from "../../../../routes/Web";
import { withRouter } from "react-router";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
import uploadIcon from "../../../../assets/images/icons/upload-icon.svg";
import { FiUpload } from "react-icons/fi";
class ImportMarc extends Component {
  constructor() {
    super();
    this.state = {
      uploadMarc: {
        name: "",
        error_list: [],
        hasError: false,
      },
      btnLoading: false,
    };
  }

  uploadMarc = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const bodyFormData = new FormData();
    bodyFormData.append("marc_file", this.state.uploadMarc.name);

    Request.postRequestExport(Url.saveMarcFile, bodyFormData).then(
      (response) => {
        this.setState({ btnLoading: false });
        if (response.status === 201) {
          this.setState({
            uploadMarc: {
              name: "",
              error_list: [],
              hasError: false,
            },
          });
          this.props.history.push(Web.confirmMarc, {
            file_name: response.data.marc_name,
          });
        } else if (response.status === 422) {
          this.setState({
            uploadMarc: {
              name: "",
              error_list: response.data.marc_file,
              hasError: true,
            },
          });
        } else if (response.status === 405) {
          this.setState({
            uploadMarc: {
              name: "",
              error_list: response.data.error,
              hasError: true,
            },
          });
        }
      }
    );
  };
  removeImage = () => {
    this.setState({
      uploadMarc: {
        name: "",
      },
    });
  };
  onFileUpload = (e) => {
    this.setState({
      uploadMarc: {
        name: e.target.files[0],
      },
    });
  };

  render() {
    return (
      <Fragment>
        <div className="small-offset-left marc-file-upload">
          <div className="upload-marc-file">
            <p className="partial-title mb-5">Ngarko MARC File</p>
            <div>
              <form onSubmit={this.uploadMarc}>
                <div className="row m-0 position-relative">
                  <div className="col-12 col-sm-10 col-md-8 col-lg-5 col-xl-4 p-0 mb-4">
                    <div className="file-upload">
                      <div className="cover-img w-100">
                        <label htmlFor="ngarko">Ngarko dokumentin</label>
                        <div className="file-upload1 pb-3 mb-2">
                          <div
                            className={
                              "image-upload-wrap1" +
                              (this.state.uploadMarc.hasError
                                ? " form-error"
                                : "")
                            }
                            id="customInputImg"
                            style={{ display: "block" }}
                          >
                            {this.state.uploadMarc.name === "" && (
                              <div>
                                <input
                                  placeholder="Imazhi i artikullit"
                                  className="file-upload-input1"
                                  onChange={this.onFileUpload}
                                  name="marc_file"
                                  type="file"
                                />
                              </div>
                            )}
                            <div
                              className={
                                "drag-text1 " +
                                (this.state.uploadMarc.name === ""
                                  ? "d-flex justify-content-between align-items-center"
                                  : "")
                              }
                            >
                              <h3>
                                {this.state.uploadMarc.name === "" ? (
                                  "Dokumenti MARC 21"
                                ) : (
                                  <div className="d-flex justify-content-between">
                                    <span style={{ color: "#333333" }}>
                                      {this.state.uploadMarc.name.name}
                                    </span>
                                    <button
                                      type="button"
                                      onClick={this.removeImage}
                                      className="remove-marc btn-unstyled"
                                    >
                                      ✕
                                    </button>
                                  </div>
                                )}
                              </h3>
                              {this.state.uploadMarc.name === "" && (
                                // <img
                                //   src={uploadIcon}
                                //   style={{ marginRight: "10px" }}
                                //   alt="upload-icon"
                                // />
                                <FiUpload
                                  className="upload"
                                  style={{ marginRight: "10px" }}
                                />
                              )}
                            </div>
                          </div>
                          {this.state.uploadMarc.hasError && (
                            <span className="error-message">
                              {this.state.uploadMarc.error_list}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0">
                    {this.state.btnLoading === true ? (
                      <button type="button" className="btn-styled-2 ml-0">
                        Duke u Ngarkuar 
                        <img
                      src={LoadingGif}
                      style={{
                        width: "18px",
                        margin: "-4px 0 0 7px",
                      }}
                    />
                      </button>
                    ) : (
                      <button type="submit" className="btn-styled-2 ml-0">
                        Ngarko Dokumentin
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ImportMarc);
