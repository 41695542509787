import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import ImportMarc from "../../../../components/administrator/catalog/import/ImportMarc";
import Header from "../../../../components/layouts/Header";
import Sidebar from "../../../../components/layouts/Sidebar";

class ImportMarcView extends Component {
	render() {
		return (
			<Fragment>
				<Sidebar
					activeLink={"catalog"}
					roleUser={this.props.role}
					firstName={this.props.firstName}
					lastName={this.props.lastName}
				/>
				<div className="w-100" id="content1">
					<Helmet>
						<title>Import MARC</title>
					</Helmet>
					<Header
						roleUser={this.props.role}
						firstName={this.props.firstName}
						lastName={this.props.lastName}
					/>
					<ImportMarc />
				</div>
			</Fragment>
		);
	}
}

export default ImportMarcView;
