import React, { Component, Fragment } from "react";
import "../../../assets/css/search.css";
import Messages from "../../../helpers/Messages";
import AppConfig from "../../../services/AppConfig";
import Request from "../../../api/Request";
import Url from "../../../api/Url";
import Select from "react-select";
import { Link } from "react-router-dom";
import Web from "../../../routes/Web";
import { withRouter } from "react-router-dom";
import { Form } from "react-bootstrap";

class Search extends Component {
  componentDidMount() {
    this.materialTypes();

    var current_path = window.location.pathname;

    if (current_path === Web.search) {
      this.setState({
        anotherPage: true,
      });
    }
    this.generateLocationRefreshToken();
  }

  /**
   * listMaterials ->
   * simpleSearch -> Simple search input values
   * inputFields -> Advanced search input values
   * buttons ->
   */
  constructor(props) {
    super(props);
    this.state = {
      listMaterials: {
        material_types: [],
        loading: true,
      },
      simpleSearch: {
        materialtype: "",
        materialName: "",
        searchtext: "",
      },
      anotherPage: false,
      inputFields: [
        {
          id: 0,
          specifikimi: "",
          text: "",
        },
        {
          id: 1,
          specifikimi: "",
          text: "",
        },
      ],
      inputFieldsValues: [
        {
          operator: "or",
          free_item: false,
          digital_copy: false,
          "specifikimi[0]": "",
          "text[0]": "",
          "specifikimi[1]": "",
          "text[1]": "",
        },
      ],
      validation: false,
      buttons: {
        removeBtn: true,
      },
      advancedSearch: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    //console.log('state : ' + this.state.simpleSearch.searchtext.length);
    //console.log('history ' + this.props.location.state.searchtext.length);
  }

  /**
   * Get material types
   */
  materialTypes = () => {
    Request.getRequest(Url.materialTypes).then((response) => {
      if (response) {
        if (response.status === 200) {
          this.setState({
            listMaterials: {
              material_types: response.data.material_types,
            },
          });
        }
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  generateLocationRefreshToken = () => {
    let token = (Math.random() + 1).toString(36).substring(7);
    return token;
  };

  resetAdvSearchForm = (e) => {
    e.preventDefault();
    this.setState({
      inputFields: [
        {
          id: 0,
          specifikimi: "",
          text: "",
        },
        {
          id: 1,
          specifikimi: "",
          text: "",
        },
      ],
      inputFieldsValues: [
        {
          operator: "or",
          free_item: false,
          digital_copy: false,
          "specifikimi[0]": "",
          "text[0]": "",
          "specifikimi[1]": "",
          "text[1]": "",
        },
      ],
      buttons: {
        removeBtn: true,
      },
    });

    var add_btn = document.getElementById("add_new_row");
    add_btn.classList.remove("plus-to-left");

    document.getElementById("free_item").checked = false;
    document.getElementById("digital_copy").checked = false;
  };

  sendProps = (e) => {
    e.preventDefault();

    var inputSearchText = document.getElementById('term_simplesearch');

    if ( inputSearchText.value.length > 2 ) {
      //send this props to parent (header)
      this.props.simpleProps(
          this.state.simpleSearch.searchtext,
          this.state.simpleSearch.materialtype,
          this.state.simpleSearch.materialName
      );

      //update props location state
      this.props.history.push({
        state: {
          materialtype: this.state.simpleSearch.materialtype,
          searchtext: this.state.simpleSearch.searchtext,
          material_name:this.state.simpleSearch.materialName,
          prevPath:this.props.location.state.prevPath,
        },
      });
    }


  };

  handleChangeSelect = (key, key1) => (e) => {
    var selectValue;
    var selectLabel;
    if (e !== null) {
      selectValue = e.value;
      selectLabel = e.label;
    } else {
      selectValue = "";
      selectLabel = "";
    }
    let newForm = {
      ...this.state.simpleSearch,
      [key]: selectValue,
      [key1]: selectLabel,
    };
    this.setState({
      simpleSearch: newForm,
    });
  };

  handleChangeInput = (key) => (e) => {
    let newForm = {
      ...this.state.simpleSearch,
      [key]: e.target.value,
    };
    this.setState({
      simpleSearch: newForm,
    });
  };

  /**
   *
   * @param e
   * @param index
   * @param input_id
   * @param text
   * @param textValue
   */
  advHandleChangeInput = (e, index, input_id, text, textValue) => {
    const inputs = this.state.inputFields;
    const inputsValue = this.state.inputFieldsValues;
    inputs[index][text] = e.target.value;
    inputsValue[0][textValue] = e.target.value;
    this.setState({
      inputFields: inputs,
      inputFieldsValues: inputsValue,
    });
  };

  /**
   * Get select values
   * @param e
   * @param index
   * @param name
   */
  advHandleChangeInputSelect = (e, index, input_id, name, nameValue) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    const inputs = this.state.inputFields;
    const inputsValue = this.state.inputFieldsValues;
    inputs[index][name] = selectValue;
    inputsValue[0][nameValue] = selectValue;
    this.setState({
      inputFields: inputs,
      inputFieldsValues: inputsValue,
    });
  };

  advHandleOperatorSelect = (e, name) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    const inputsValue = this.state.inputFieldsValues;
    inputsValue[0][name] = selectValue;
    this.setState({
      inputFieldsValues: inputsValue,
    });
  };

  advHandleFreeItem = (e, name) => {
    const inputsValue = this.state.inputFieldsValues;

    let checkbox = e.target.checked;

    if (checkbox === true) {
      inputsValue[0][name] = "free_item";
      this.setState({
        inputFieldsValues: inputsValue,
      });
    } else {
      inputsValue[0][name] = e.target.checked;
      this.setState({
        inputFieldsValues: inputsValue,
      });
    }
  };
  advHandleDigitalCopy = (e, name) => {
    const inputsValue = this.state.inputFieldsValues;

    let checkbox = e.target.checked;

    if (checkbox === true) {
      inputsValue[0][name] = "digital_copy";
      this.setState({
        inputFieldsValues: inputsValue,
      });
    } else {
      inputsValue[0][name] = e.target.checked;
      this.setState({
        inputFieldsValues: inputsValue,
      });
    }
  };

  /**
   * Add new row (input)
   */
  addNewRow = (input_id) => {
    let indexnow = input_id + 1;
    let finalindex = indexnow;
    const inputs = this.state.inputFields;
    const blankRow = { id: finalindex, specifikimi: "", text: "" };

    const inputsValues = this.state.inputFieldsValues;

    const blankRow1 = {
      [`specifikimi[${indexnow}]`]: "",
      [`text[${indexnow}]`]: "",
    };

    this.setState({
      inputFields: [...inputs, blankRow],
    });

    Object.assign(...inputsValues, blankRow1);

    this.setState({
      buttons: {
        removeBtn: true,
      },
    });

    var add_btn = document.getElementById("add_new_row");
    add_btn.classList.remove("plus-to-left");
  };

  /**
   * Remove row (input)
   * @param input_id
   */
  removeRow = (input_id) => {
    const inputs = this.state.inputFields;
    const inputsValues = this.state.inputFieldsValues;

    if (inputs.length > 1) {
      const removeIndex = inputs.findIndex((item) => item.id === input_id);
      inputs.splice(removeIndex, 1);
      this.setState({
        inputFields: inputs,
      });

      //Remove inputs values
      let indexnow = input_id;
      var removedItems = [[`specifikimi[${indexnow}]`], [`text[${indexnow}]`]];

      removedItems.map((removeItem) => {
        delete inputsValues[0][removeItem];
      });
    }

    if (inputs.length === 1) {
      this.setState({
        buttons: {
          removeBtn: false,
        },
      });

      var add_btn = document.getElementById("add_new_row");
      add_btn.classList.add("plus-to-left");
    }
  };

  createUrlParams = () => {
    var params = {
      materialtype: this.state.simpleSearch.materialtype,
      searchtext: this.state.simpleSearch.searchtext,
    };

    let searchParams = new URLSearchParams(params);
    searchParams.toString();

    return searchParams;
  };

  /**
   * Show and hide advanced search
   * @param e
   */
  showHideSearch = (e) => {
    e.preventDefault();

    var advancedSearchArrow = document.getElementById("advanced-search");
    if (this.state.advancedSearch === false) {
      this.setState({
        advancedSearch: true,
      });
      advancedSearchArrow.classList.add("active");
    } else {
      this.setState({
        advancedSearch: false,
      });
      advancedSearchArrow.classList.remove("active");
    }
  };

  checkSelectValue = (value) => {
    switch (value) {
      case "specifikimi_author":
        return { value: "specifikimi_author", label: "Autorë" };
        break;
      case "specifikimi_keywords":
        return { value: "specifikimi_keywords", label: "Fjalë kyce" };
        break;
      case "specifikimi_publisher":
        return { value: "specifikimi_publisher", label: "Botuesi" };
        break;
      case "specifikimi_title":
        return { value: "specifikimi_title", label: "Titull" };
        break;
      case "specifikimi_isbn":
        return { value: "specifikimi_isbn", label: "Isbn" };
        break;
      case "specifikimi_issn":
        return { value: "specifikimi_issn", label: "Issn" };
        break;
      case "specifikimi_kdu":
        return { value: "specifikimi_kdu", label: "Kdu" };
        break;
      default:
        return "";
    }
  };

  advSearchValidate = (e) => {
    e.preventDefault();

    var advancedSearchFormData = this.state.inputFieldsValues[0];

    var current_path = window.location.pathname;

    if (current_path !== Web.advanced_search){
      var urlPrev = {
        prevPath:window.location.pathname,
      };
    }else {
      var urlPrev = {
        prevPath:this.props.location.state.urlPrev.prevPath,
      };
    }

    var advancedSearchLocationState = {
      advancedSearchFormData,
      refreshToken: this.generateLocationRefreshToken(),
      urlPrev
    };

    const inputsSpecifikimi = document.getElementsByName("specifikimi");
    const inputsText = document.getElementsByName("text");

    const validInputs = Array.from(inputsSpecifikimi).map((input) => {
      if (input.value === "") {
        return false;
      } else {
        return true;
      }
    });
    const validInputsText = Array.from(inputsText).map((input) => {
      if (input.value === "") {
        return false;
      } else {
        return true;
      }
    });

    const foundValueS = validInputs.find((element) => element === false);
    const foundValueT = validInputsText.find((element) => element === false);

    if (foundValueS !== false && foundValueT !== false) {
      this.props.history.push({
        pathname: Web.advanced_search,
        state: advancedSearchLocationState,
      });
    }
  };

  searchValidate = (e) => {
    e.preventDefault();

    var queryParams = this.createUrlParams();

    var simpleSearchData = {
      materialtype: this.state.simpleSearch.materialtype,
      material_name: this.state.simpleSearch.materialName,
      searchtext: this.state.simpleSearch.searchtext,
      query: "" + queryParams + "",
      prevPath:window.location.pathname,
    };

    if (this.state.simpleSearch.searchtext.length >= 3) {
      this.props.history.push({
        pathname: Web.search,
        state: simpleSearchData,
      });
    }
  };

  render() {
    //Old values for simple search
    //Get simple search values by props.location.state
    var searchtext = "";
    var materialtype = "";
    var materialName = "";
    if (this.props.location.state) {
      searchtext = this.props.location.state.searchtext;
      materialtype = this.props.location.state.materialtype;
      materialName = this.props.location.state.material_name;
    }

    var queryParams = this.createUrlParams();

    //Send this data to SearchResults using Link and Sate
    var simpleSearchData = {
      materialtype: this.state.simpleSearch.materialtype,
      material_name: this.state.simpleSearch.materialName,
      searchtext: this.state.simpleSearch.searchtext,
      query: "" + queryParams + "",
    };

    var advancedSearchFormData = this.state.inputFieldsValues[0];

    var advancedSearchLocationState = {
      advancedSearchFormData,
      refreshToken: this.generateLocationRefreshToken(),
    };

    // Simple search , material types Select options
    if (!this.state.listMaterials.loading) {
      var materials = this.state.listMaterials.material_types.map(
        (materialType) => {
          return { value: materialType.id, label: materialType.name };
        }
      );
    }

    //Simple search devault value if has searched
    var materialtype_value = "Katalog";
    if (materialtype) {
      materialtype_value = { value: materialtype, label: materialName };
    }

    //Advanced search , Select options
    const options = [
      { value: "specifikimi_author", label: "Autorë" },
      { value: "specifikimi_keywords", label: "Fjalë kyce" },
      { value: "specifikimi_publisher", label: "Botuesi" },
      { value: "specifikimi_title", label: "Titull" },
      { value: "specifikimi_isbn", label: "Isbn" },
      { value: "specifikimi_issn", label: "Issn" },
      { value: "specifikimi_kdu", label: "Kdu" },
    ];

    const operatorOptions = [
      { value: "or", label: "Ose" },
      { value: "and", label: "Dhe" },
    ];

    //Advanced search , inputs
    if (this.state.inputFields) {
      var multipleInputs = this.state.inputFields.map((input, index) => {
        return (
          <div key={index}>
            <div className="select--search--adv mb-4">
              <div className={"adv--select"} id={"db-input" + input.id}>
                <Select
                  className={"adv-input-select"}
                  id={"selecttest"}
                  classNamePrefix="select_input"
                  name="specifikimi"
                  isClearable={true}
                  onChange={(e) =>
                    this.advHandleChangeInputSelect(
                      e,
                      index,
                      input.id,
                      "specifikimi",
                      `specifikimi[${input.id}]`
                    )
                  }
                  placeholder={"Përzgjidh"}
                  isSearchable={false}
                  options={options}
                  value={this.checkSelectValue(input.specifikimi)}
                />
              </div>
              <div className="adv--search-input">
                <input
                  placeholder="Kërko..."
                  onChange={(e) =>
                    this.advHandleChangeInput(
                      e,
                      index,
                      input.id,
                      "text",
                      `text[${input.id}]`
                    )
                  }
                  className="w-100 adv-search-input"
                  id="text"
                  name="text"
                  value={input.text}
                  type="text"
                />
              </div>
              {this.state.buttons.removeBtn === true && (
                <button
                  type="button"
                  onClick={() => this.removeRow(input.id)}
                  id={"remove-btn"}
                  className="specific-search-remove float-left add-remove-button js-remove--adv-row remove-btn rm-btn"
                  name="removesearch[]"
                >
                  -
                </button>
              )}

              <button
                type="button"
                onClick={() => this.addNewRow(input.id)}
                id={"add_new_row"}
                className="js-add--adv-row add-btn"
              >
                +
              </button>
            </div>
          </div>
        );
      });
    }

    var advOperatorOr = [{ value: "or", label: "Ose" }];
    var advOperatorAnd = [{ value: "and", label: "Dhe" }];

    return (
      <Fragment>
        <div className="container-fluid">
          <div
            className="kerkim simple-search-div select_search"
            style={{ marginTop: 25 + "vh", marginBottom: 10 + "vh" }}
          >
            <form id="simple-search">
              <div className="simple--search row">
                <div className="select1 col-4 col-sm-12">
                  <Select
                    name="materialtype"
                    className={"search"}
                    isSearchable={false}
                    isClearable={true}
                    onChange={this.handleChangeSelect(
                      "materialtype",
                      "materialName"
                    )}
                    classNamePrefix="select_input"
                    placeholder="Katalog"
                    defaultValue={materialtype_value}
                    options={materials}
                  />
                </div>
                <div className="search--s--input col-4 col-sm-12">
                  <input
                    placeholder="Kërko..."
                    className="w-100 simple-search-input"
                    onChange={this.handleChangeInput("searchtext")}
                    id="term_simplesearch"
                    defaultValue={searchtext}
                    name="searchtext"
                    type="text"
                  />
                </div>
                <div className="search--submit col-4 col-sm-12">
                  {this.state.anotherPage === false && (
                    <button
                      id="search_button"
                      className="w-100 simple-search-button mb-3 "
                      onClick={this.searchValidate}
                    >
                      Kërko
                    </button>
                  )}
                  {this.state.anotherPage === true && (
                      <Fragment>
                          <button
                            type="button"
                            className="w-100 simple-search-button mb-3"
                            onClick={this.sendProps}
                          >
                            Kërko
                          </button>
                      </Fragment>
                  )}
                  <div>
                    <a
                      id="advanced-search"
                      onClick={this.showHideSearch}
                      className="mt-4"
                      href=""
                    >
                      Kërkim Specifik
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        {this.state.advancedSearch === true && (
          <div className="container-fluid adv-search-div">
            <div
              className="kerkim-specifik"
              style={{ height: "unset", opacity: 1 }}
            >
              <form onSubmit={this.submitAdvancedSearch} id="adv-search-form">
                <div className="adv--filter---b">
                  <div className="search--main--title">
                    <h1 className="tile_search_s  mb-4">
                      Kërkim në Katalogun Online
                    </h1>
                  </div>

                  <div className="search--adv--filter">
                    <div className="adv--search-container">
                      {multipleInputs}
                    </div>
                  </div>

                  <div className="adv-search adv-checkbox">
                    <div className="njesi pl-3">
                      <label
                        htmlFor="free_item"
                        className="thin-text search_check_box"
                      >
                        <input
                          className="selective-checkbox"
                          id="free_item"
                          onChange={(e) =>
                            this.advHandleFreeItem(e, "free_item")
                          }
                          name="free_item"
                          type="checkbox"
                          value="free_item"
                        />
                        <span className="tick"></span>
                        <span>Njësi të lira për huazim</span>
                      </label>
                    </div>
                    <div className="copies">
                      <label
                        htmlFor="digital_copy"
                        className="thin-text search_check_box"
                      >
                        <input
                          className="selective-checkbox"
                          onChange={(e) =>
                            this.advHandleDigitalCopy(e, "digital_copy")
                          }
                          id="digital_copy"
                          name="digital_copy"
                          type="checkbox"
                          value="digital_copy"
                        />
                        <span className="tick"></span>
                        <span>Kopje digjitale</span>
                      </label>
                    </div>
                    <div className="or-and-select">
                      <Select
                        className={"operator-select"}
                        classNamePrefix="select_input"
                        onChange={(e) =>
                          this.advHandleOperatorSelect(e, "operator")
                        }
                        name="operator"
                        value={
                          this.state.inputFieldsValues[0].operator === "or"
                            ? advOperatorOr
                            : advOperatorAnd
                        }
                        isSearchable={false}
                        options={operatorOptions}
                      />
                    </div>
                  </div>
                  {/*div 3 */}

                  <div className="adv--button mt-4">
                    <div className="col-12 col-md-6">
                      {/* <Link
												to={{
													pathname: Web.advanced_search,
													state: advancedSearchLocationState,
												}}
												type={"button"}
												id={"search_button"}
												className={"w-100 w-100 adv-search-button mb-4"}
											>
												Kërko
											</Link> */}
                      <button
                        id="search_button"
                        className="w-100 w-100 adv-search-button mb-4"
                        onClick={this.advSearchValidate}
                      >
                        Kërko
                      </button>
                    </div>
                    <div className="col-12 col-md-6">
                      <button
                        type="reset"
                        className="w-100 adv-search-button"
                        onClick={this.resetAdvSearchForm}
                        name="clear"
                      >
                        Pastro Filtrat
                      </button>
                    </div>
                  </div>
                  {/*div 4 */}
                  <hr
                    className="mt-5"
                    style={{ color: "#1b1e21", borderColor: "#1b1e21" }}
                  />
                </div>
              </form>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withRouter(Search);
