import React from "react";
import { Component, Fragment } from "react";
import Request from "../../../../api/Request";
import Url from "../../../../api/Url";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Web from "../../../../routes/Web";
import Messages from "../../../../helpers/Messages";
import Downshift from "downshift";
import AppConfig from "../../../../services/AppConfig";

class KeywordModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			keyword: "",
			selectedKeyword: [],
			showBiblios: {
				key_words: this.props.key_words,
			},
			showModal: false,
			errors: [],
		};
		this.downshiftOnChange = this.downshiftOnChange.bind(this);
	}

	inputOnChange = () => (e) => {
		var keyword = e.target.value;
		this.setState({
			keyword: keyword,
			selectedKeyword: [],
		});
	};

	downshiftOnChange = (selectedKeyword) => {
		this.setState({
			keyword: '',
			selectedKeyword: selectedKeyword,
		});
	};

	onTrigger = (event) => {
		event.preventDefault();
		if (this.state.selectedKeyword.length === 0) {
			var keyword = { name: this.state.keyword };
			Request.postRequest(Url.createKeywordBiblio, keyword).then((response) => {
				if (response.status === 201) {
					this.props.parentCallback(response.data.keyword, "new");
					this.setState({
						keyword: "",
						errors: [],
					});

					this.closeModal();
				} else if (response.status === 405) {
					Messages.swalInfo(response.data.info);
				} else if (response.status === 422) {
					this.setState({
						errors: response.data,
					});
				} else {
					Messages.swalError(AppConfig.serverError + response.data);
				}
			});
		} else {
			this.props.parentCallback(this.state.selectedKeyword, "old");

			this.setState({
				selectedKeyword: [],
			});
			this.closeModal();
		}
	};

	closeModal = () => {
		this.setState({
			errors: [],
			selectedKeyword: [],
			keyword: '',
		});

		this.props.onPopupClose(false);
	};

	render() {
		const Keywords = this.props.key_words.map((keyword) => {
			return <li className="tag-kyce">{keyword.name}</li>;
		});
		return (
			<Fragment>
				<Modal
					show={this.props.showPopup}
					onHide={this.closeModal}
					size="md"
					centered
				>
					<Modal.Header>
						<Modal.Title id="sign-in-title">Shto Fjalë-Kyçe</Modal.Title>
						<button
							className="btn-unstyled close-modal"
							type="button"
							data-dismiss="modal"
							aria-label="Close"
							onClick={this.closeModal}
						>
							✕
						</button>
					</Modal.Header>
					<form onSubmit={this.onTrigger}>
						<Modal.Body>
							<Downshift
								onChange={this.downshiftOnChange}
								itemToString={(item) => (item ? item.name : "")}
							>
								{({
									selectedItem,
									getInputProps,
									getItemProps,
									inputValue,
									highlightedIndex,
								}) => (
									<div>
										<div className="m-0 pb-2 row">
											<div className="modal-input-w w-100 pb-4 col-12 modal-authornametext-container">
												<input
													type="text"
													placeholder="Fjalë-Kyçe (650/A)"

													className={
														"input-form-input" +
														(this.state.errors.name ? " form-error" : "")
													}
													{...getInputProps({
														onChange: this.inputOnChange(),
														value:this.state.keyword
													})}
												/>
												{this.state.errors.name && (
													<span className="error-message left">
														{this.state.errors.name}
													</span>
												)}
											</div>
										</div>

										<div className="col-12 author-search">
											<ul className="borderi data-list w-100 ">
												{this.props.key_words
													.filter(
														(item) =>
															!inputValue ||
															item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
																.toLowerCase()
																.includes(inputValue.toLowerCase()),
													)
													.slice(0)
													.map((item, index) => (
														<li
															id="author-live-search-results"
															className={`pointer-li mt-1 keyword_${item.id}`}
															{...getItemProps({
																key: index,
																item,
															})}
															style={{
																backgroundColor:
																	selectedItem === item ||
																	highlightedIndex === index
																		? "#007aff"
																		: "#dcedff",
																color:
																	selectedItem === item ||
																	highlightedIndex === index
																		? "#fff"
																		: "#74767E",
																padding: item && "2px 15px",
															}}
														>
															{item.name}
														</li>
													))}
											</ul>
										</div>
									</div>
								)}
							</Downshift>
						</Modal.Body>
						<Modal.Footer>
							<div className="col-12 d-flex m-0 p-0 justify-content-center">
								<div className="col-6 pl-0 pr-2 pr-sm-3 justify-content-center">
									<button
										type="submit"
										className="btn-styled-2 w-100 text-center"
										onClick={this.onTrigger}
									>
										Shto
									</button>
								</div>
								<div className="col-6 pr-0 pl-2 pl-sm-3">
									<button
										className="btn-styled-1 w-100 text-center"
										type="button"
										onClick={this.closeModal}
									>
										Anullo
									</button>
								</div>
							</div>
						</Modal.Footer>
					</form>
				</Modal>
			</Fragment>
		);
	}
}

export default withRouter(KeywordModal);
