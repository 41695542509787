import React, { Component, Fragment } from "react";
import { Redirect } from "react-router";
import Request from "../../api/Request";
import Url from "../../api/Url";
import { withRouter } from "react-router-dom";
import Web from "../../routes/Web";
import AppConfig from "../../services/AppConfig";
import Messages from "../../helpers/Messages";
import LoadingGif from "../../assets/images/icons/loading.gif";
import { BsEnvelope } from "react-icons/bs";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      forgotPassword: {
        email: "",
        error_list: [],
      },
      btnLoading: false,
    };
  }

  onInputchange = (e) => {
    this.setState({
      forgotPassword: {
        ...this.state.forgotPassword,
        [e.target.name]: e.target.value,
      },
    });
  };

  forgotPassword = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    Request.postRequest(Url.resetLink, this.state.forgotPassword).then(
      (result) => {
        this.setState({ btnLoading: false });
        if (result.status === 200) {
          this.setState({
            forgotPassword: {
              email: "",
              error_list: [],
            },
          });
          Messages.swalSuccess(result.data.success);
        } else if (result.status === 422) {
          this.setState({
            forgotPassword: {
              ...this.state.forgotPassword,
              error_list: result.data,
            },
          });
        } else if (result.status === 404) {
          this.setState({
            forgotPassword: {
              ...this.state.forgotPassword,
              error_list: result.data,
            },
          });
        }
      }
    );
  };

  render() {
    return (
      <Fragment>
        <div className="form-logins-b">
          <div className="login-b-r l-staf col-12 col-sm-9 col-md-10 col-xl-8">
            <p className="col-12 col-sm-12 col-lg-12 col-xl-10 mx-auto ems-note">
              Shkruani adresën e emailit më poshtë, dhe ne do t'ju dërgojmë një
              link për të rivendosur fjalëkalimin e ri
            </p>
            <form
              className="col-12 col-sm-12 col-lg-12 col-xl-10 mx-auto"
              onSubmit={this.forgotPassword}
            >
              <div className="form-group position-relative pb-4 mb-0">
                <input
                  type="text"
                  name="email"
                  placeholder="Emaili juaj *"
                  value={this.state.forgotPassword.email}
                  className={
                    "input-form-input-log-b" +
                    (this.state.forgotPassword.error_list.email ||
                    this.state.forgotPassword.error_list.error
                      ? " form-error"
                      : "")
                  }
                  id="email"
                  onChange={this.onInputchange}
                />
                <BsEnvelope className="email-icon-b" />
                {this.state.forgotPassword.error_list.email && (
                  <span className="error-message">
                    {this.state.forgotPassword.error_list.email[0]}
                  </span>
                )}
                {this.state.forgotPassword.error_list.error && (
                  <span className="error-message">
                    {this.state.forgotPassword.error_list.error}
                  </span>
                )}
              </div>
              <div className="form-group">
                {this.state.btnLoading === true ? (
                  <button type="button" className="login_btn mt-2">
                    Duke u Dërguar
                    <img
                      src={LoadingGif}
                      style={{
                        width: "18px",
                        margin: "-4px 0 0 7px",
                      }}
                    />
                  </button>
                ) : (
                  <button type="submit" className="login_btn mt-2">
                    Dërgo kërkesën
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ForgotPassword);
