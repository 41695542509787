import React, { Component, Fragment } from "react";
import Select from "react-select";
import "../../../../assets/css/report-bibliography.css";
import Borrowing from "./Borrowings";
import BorrowingInTime from "./BorrowingsInTime";
import InLibrary from "./InLibrary";

class ReportBorrowing extends Component {
  constructor() {
    super();
    this.state = {
      tab: {
        details: "borrowings",
      },
    };
  }

  borrowings = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        details: "borrowings",
      },
    });
  };

  borrowingsInTime = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        details: "intime",
      },
    });
  };

  inLibrary = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        details: "inlibrary",
      },
    });
  };

  render() {
    return (
      <Fragment>
        <div id="report-catalog" className="table-detail-tabs">
          <div className="container-fluid small-offset-left">
            <div id="list-of-members">
              <p className="partial-title mb-5">Huazim</p>
              <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                <li className="nav-item col-12 col-md-4 p-0">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="aktuale"
                    aria-selected="true"
                    href="#"
                    onClick={(e) => this.borrowings(e)}
                  >
                    Huazime Aktuale
                  </a>
                </li>
                <li className="nav-item col-12 col-md-4 p-0">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="kohore"
                    aria-selected="true"
                    onClick={(e) => this.borrowingsInTime(e)}
                  >
                    Huazime në periudha kohore
                  </a>
                </li>
                <li className="nav-item col-12 col-md-4 p-0">
                  <a
                    className="nav-link"
                    href="#"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="librar"
                    aria-selected="true"
                    onClick={(e) => this.inLibrary(e)}
                  >
                    Në librari
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {this.state.tab.details === "borrowings" && <Borrowing></Borrowing>}
        {this.state.tab.details === "intime" && (
          <BorrowingInTime></BorrowingInTime>
        )}
        {this.state.tab.details === "inlibrary" && <InLibrary></InLibrary>}
      </Fragment>
    );
  }
}

export default ReportBorrowing;
