import { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import Select from "react-select";
import { withRouter } from "react-router";
import Url from "../../../../api/Url";
import Messages from "../../../../helpers/Messages";
import Request from "../../../../api/Request";
import uploadIcon from "../../../../assets/images/icons/upload-icon.svg";
import AppConfig from "../../../../services/AppConfig";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
class CreateSerie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generatedSerie: {
        approved: 1,
        serial_id: this.props.match.params.id,
        serie_id: this.props.NotApprovedSerieId,
        pattern: "",
        published_date: "",
        cover: null,
      },
      notApproved: [
        {
          id: 0,
          "items_not_approved_serie[0][biblioitem_id]": this.props.BiblioitemId,
          "items_not_approved_serie[0][serie_id]":
            this.props.NotApprovedSerieId,
          "items_not_approved_serie[0][borrowing_type]": "",
          "items_not_approved_serie[0][inventory_number]": "",
          "items_not_approved_serie[0][place_number]": "",
          "items_not_approved_serie[0][damaged]": "0",
          "items_not_approved_serie[0][damaged_date]": "",
          "items_not_approved_serie[0][renewal_date]": "",
        },
      ],
      notApprovedValues: [
        {
          "items_not_approved_serie[0][biblioitem_id]": this.props.BiblioitemId,
          "items_not_approved_serie[0][serie_id]":
            this.props.NotApprovedSerieId,
          "items_not_approved_serie[0][borrowing_type]": "",
          "items_not_approved_serie[0][inventory_number]": "",
          "items_not_approved_serie[0][place_number]": "",
          "items_not_approved_serie[0][damaged]": "0",
          "items_not_approved_serie[0][damaged_date]": "",
          "items_not_approved_serie[0][renewal_date]": "",
        },
      ],
      temporary_img_path: null,
      serialData: {
        approvedSeries: "",
        notApprovedSeries: "",
      },
      formError: [],
      buttons: {
        removeBtn: false,
      },
      btnLoading: false,
    };
  }

  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.generatedSerie,
      [key]: e.target.value,
    };
    this.setState({
      generatedSerie: newForm,
    });
  };

  handleChangeDate = (key) => (e) => {
    var value = null;
    if (e !== null) {
      value = moment(e).format("yyyy-MM-DD");
    }
    let newForm = {
      ...this.state.generatedSerie,
      [key]: value,
    };
    this.setState({
      generatedSerie: newForm,
    });
  };

  notApprovedInputCheckbox = (e, index, name, i) => {
    const inputs = this.state.notApproved;
    const inputs1 = this.state.notApprovedValues;
    inputs[i][name] = e.target.value;
    inputs1[0][name] = e.target.value;
    this.setState({
      notApproved: inputs,
      notApprovedValues: inputs1,
    });

    if (e.target.value === "0") {
      inputs[i][`items_not_approved_serie[${index}][damaged_date]`] = "";
      inputs[i][`items_not_approved_serie[${index}][renewal_date]`] = "";
      inputs1[0][`items_not_approved_serie[${index}][damaged_date]`] = "";
      inputs1[0][`items_not_approved_serie[${index}][renewal_date]`] = "";
      this.setState({
        notApproved: inputs,
        notApprovedValues: inputs1,
      });
    }
  };

  notApprovedInput = (e, index, name, i) => {
    const inputs = this.state.notApproved;
    const inputs1 = this.state.notApprovedValues;
    inputs[i][name] = e.target.value;
    inputs1[0][name] = e.target.value;
    this.setState({
      notApproved: inputs,
      notApprovedValues: inputs1,
    });
  };

  notApprovedInputSelect = (e, index, name, i) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    const inputs = this.state.notApproved;
    const inputs1 = this.state.notApprovedValues;
    inputs[i][name] = selectValue;
    inputs1[0][name] = selectValue;
    this.setState({
      notApproved: inputs,
      notApprovedValues: inputs1,
    });
  };

  notApprovedInputDate = (e, index, name, i) => {
    const inputs = this.state.notApproved;
    const inputs1 = this.state.notApprovedValues;
    var value = null;
    if (e !== null) {
      value = moment(e).format("yyyy-MM-DD");
    }
    inputs[i][name] = value;
    inputs1[0][name] = value;
    this.setState({
      notApproved: inputs,
      notApprovedValues: inputs1,
    });
  };

  /**
   * add and remove cover
   */

  fileSelectHandler = (e) => {
    if (e.target.files[0]) {
      let newForm = {
        ...this.state.generatedSerie,
        cover: e.target.files[0],
      };
      this.setState({
        generatedSerie: newForm,
        temporary_img_path: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  removeImage = () => {
    let newForm = {
      ...this.state.generatedSerie,
      cover: "",
    };
    this.setState({
      generatedSerie: newForm,
      temporary_img_path: null,
    });
  };

  /**
   * add new serie row
   */
  addNewRow = (input_id) => {
    let indexnow = input_id + 1;
    const inputs = this.state.notApproved;
    const inputs1 = this.state.notApprovedValues;
    const blankRow = {
      id: indexnow,
      [`items_not_approved_serie[${indexnow}][biblioitem_id]`]:
        this.props.BiblioitemId,
      [`items_not_approved_serie[${indexnow}][serie_id]`]:
        this.props.NotApprovedSerieId,
      [`items_not_approved_serie[${indexnow}][borrowing_type]`]: "",
      [`items_not_approved_serie[${indexnow}][inventory_number]`]: "",
      [`items_not_approved_serie[${indexnow}][place_number]`]: "",
      [`items_not_approved_serie[${indexnow}][damaged]`]: 0,
      [`items_not_approved_serie[${indexnow}][damaged_date]`]: "",
      [`items_not_approved_serie[${indexnow}][renewal_date]`]: "",
    };
    const blankRow1 = {
      [`items_not_approved_serie[${indexnow}][biblioitem_id]`]:
        this.props.BiblioitemId,
      [`items_not_approved_serie[${indexnow}][serie_id]`]:
        this.props.NotApprovedSerieId,
      [`items_not_approved_serie[${indexnow}][borrowing_type]`]: "",
      [`items_not_approved_serie[${indexnow}][inventory_number]`]: "",
      [`items_not_approved_serie[${indexnow}][place_number]`]: "",
      [`items_not_approved_serie[${indexnow}][damaged]`]: 0,
      [`items_not_approved_serie[${indexnow}][damaged_date]`]: "",
      [`items_not_approved_serie[${indexnow}][renewal_date]`]: "",
    };
    Object.assign(...inputs1, blankRow1);
    this.setState({
      notApproved: [...inputs, blankRow],
    });
    this.setState({
      buttons: {
        removeBtn: true,
      },
    });
  };

  /**
   * remove serie row
   */
  removeRow = (input_id) => {
    const inputs = this.state.notApproved;
    const inputs1 = this.state.notApprovedValues;
    if (inputs.length > 1) {
      const removeIndex = inputs.findIndex((item) => item.id === input_id);
      let indexnow = input_id;
      var removedItems = [
        [`items_not_approved_serie[${indexnow}][biblioitem_id]`],
        [`items_not_approved_serie[${indexnow}][serie_id]`],
        [`items_not_approved_serie[${indexnow}][borrowing_type]`],
        [`items_not_approved_serie[${indexnow}][inventory_number]`],
        [`items_not_approved_serie[${indexnow}][place_number]`],
        [`items_not_approved_serie[${indexnow}][damaged]`],
        [`items_not_approved_serie[${indexnow}][damaged_date]`],
        [`items_not_approved_serie[${indexnow}][renewal_date]`],
      ];

      removedItems.map((removeItem) => {
        delete inputs1[0][removeItem];
      });
      inputs.splice(removeIndex, 1);
      this.setState({
        notApproved: inputs,
      });
    }
    if (inputs.length === 1) {
      this.setState({
        buttons: {
          removeBtn: false,
        },
      });
    }
  };

  checkSelectValue = (value) => {
    switch (value) {
      case "salle":
        return { value: "salle", label: "Sallë" };
      case "normal":
        return { value: "normal", label: "Normal" };
      case "ipadisponueshem":
        return { value: "ipadisponueshem", label: "I Padisponueshëm" };
      default:
        return "";
    }
  };

  checkSelectedDate = (value) => {
    if (value === "") {
      return null;
    } else {
      return new Date(value);
    }
  };

  // reRenderList = () => {
  //   this.props.onCreateSerieSubmit(true);
  // };

  reRenderList = () => {
    this.props.onCreateSerieSubmit();
  };

  updateGeneratedSerie = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const formData = new FormData();
    if (this.state.generatedSerie.cover !== null) {
      formData.append("cover", this.state.generatedSerie.cover);
    }
    formData.append("approved", "1");
    formData.append("serial_id", this.state.generatedSerie.serial_id);
    formData.append("serie_id", this.state.generatedSerie.serie_id);
    formData.append("pattern", this.state.generatedSerie.pattern);
    formData.append("published_date", this.state.generatedSerie.published_date);
    formData.append("_method", "PATCH");

    let newstate = this.state.notApprovedValues[0];
    for (const [key, value] of Object.entries(newstate)) {
      formData.append(key, value);
    }
    let request = Url.updateGeneratedSerie;
    Request.postRequest(request, formData).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          generatedSerie: {
            approved: 1,
            serial_id: this.props.match.params.id,
            serie_id: this.props.NotApprovedSerieId,
            pattern: "",
            published_date: "",
            cover: null,
          },
          notApproved: [
            {
              id: 0,
              "items_not_approved_serie[0][biblioitem_id]":
                this.props.BiblioitemId,
              "items_not_approved_serie[0][serie_id]":
                this.props.NotApprovedSerieId,
              "items_not_approved_serie[0][borrowing_type]": "",
              "items_not_approved_serie[0][inventory_number]": "",
              "items_not_approved_serie[0][place_number]": "",
              "items_not_approved_serie[0][damaged]": "0",
              "items_not_approved_serie[0][damaged_date]": "",
              "items_not_approved_serie[0][renewal_date]": "",
            },
          ],
          temporary_img_path: null,
          formError: [],
        });
        Messages.swalSuccess(response.data.success);
        this.reRenderList();
      } else if (response.status === 422) {
        this.setState({
          formError: response.data,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  render() {
    if (this.state.notApproved) {
      var multipleInputs = this.state.notApproved.map((input, index) => {
        return (
          <Fragment key={index}>
            <div className="col-sm-12 series-items serie-item-block">
              <div className="row m-0 align-items-end">
                <div className="col-12 col-md-4 p-0 pr-md-2 pb-4">
                  <label htmlFor="Lloji i huazimit">Lloji i huazimit</label>
                  <Select
                    className={
                      "basic-select" +
                      (this.state.formError[
                        `items_not_approved_serie.${input.id}.borrowing_type`
                      ]
                        ? " form-error"
                        : "")
                    }
                    classNamePrefix="select_input"
                    isClearable={true}
                    name={`items_not_approved_serie[${input.id}][borrowing_type]`}
                    placeholder="Lloji i huazimit"
                    onChange={(e) =>
                      this.notApprovedInputSelect(
                        e,
                        input.id,
                        "items_not_approved_serie[" +
                          input.id +
                          "][borrowing_type]",
                        index
                      )
                    }
                    value={this.checkSelectValue(
                      input[
                        `items_not_approved_serie[${input.id}][borrowing_type]`
                      ]
                    )}
                    isSearchable={false}
                    options={[
                      { value: "salle", label: "Sallë" },
                      { value: "normal", label: "Normal" },
                      { value: "ipadisponueshem", label: "I Padisponueshëm" },
                    ]}
                  />
                  {this.state.formError[
                    `items_not_approved_serie.${input.id}.borrowing_type`
                  ] && (
                    <span className="error-message">
                      {
                        this.state.formError[
                          `items_not_approved_serie.${input.id}.borrowing_type`
                        ]
                      }
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4 p-0 px-md-2 pb-4">
                  <label htmlFor="Kodifikimi">Kodifikimi</label>
                  <input
                    placeholder="Kodifikimi"
                    className={
                      "input-form-input" +
                      (this.state.formError[
                        `items_not_approved_serie.${input.id}.inventory_number`
                      ]
                        ? " form-error"
                        : "")
                    }
                    name={`items_not_approved_serie[${input.id}][inventory_number]`}
                    onChange={(e) =>
                      this.notApprovedInput(
                        e,
                        input.id,
                        "items_not_approved_serie[" +
                          input.id +
                          "][inventory_number]",
                        index
                      )
                    }
                    value={
                      input[
                        `items_not_approved_serie[${input.id}][inventory_number]`
                      ]
                    }
                    type="text"
                  />
                  {this.state.formError[
                    `items_not_approved_serie.${input.id}.inventory_number`
                  ] && (
                    <span className="error-message left">
                      {
                        this.state.formError[
                          `items_not_approved_serie.${input.id}.inventory_number`
                        ]
                      }
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4 p-0 pl-md-2 pb-4">
                  <label htmlFor="Numri i vendit">Numri i vendit</label>
                  <input
                    placeholder="Numri i vendit"
                    className={
                      "input-form-input" +
                      (this.state.formError[
                        `items_not_approved_serie.${input.id}.place_number`
                      ]
                        ? " form-error"
                        : "")
                    }
                    name={`items_not_approved_serie[${input.id}][place_number]`}
                    onChange={(e) =>
                      this.notApprovedInput(
                        e,
                        input.id,
                        "items_not_approved_serie[" +
                          input.id +
                          "][place_number]",
                        index
                      )
                    }
                    value={
                      input[
                        `items_not_approved_serie[${input.id}][place_number]`
                      ]
                    }
                    type="text"
                  />
                  {this.state.formError[
                    `items_not_approved_serie.${input.id}.place_number`
                  ] && (
                    <span className="error-message left">
                      {
                        this.state.formError[
                          `items_not_approved_serie.${input.id}.place_number`
                        ]
                      }
                    </span>
                  )}
                </div>
              </div>
              {AppConfig.getRole() === "Administrator" && (
                <div className="row m-0">
                  <div className="d-block col-12 col-md-4 p-0 pr-md-2 pb-4">
                    <label htmlFor="Demtuar" className="main-label">
                      Dëmtuar:
                    </label>
                    <div className="radio-buttons d-flex">
                      <div className="radio-button mr-4">
                        <label htmlFor={"damaged_" + input.id}>Po</label>
                        <input
                          className="form-check-input"
                          name={
                            "items_not_approved_serie[" +
                            input.id +
                            "][damaged]"
                          }
                          type="radio"
                          id={"damaged_" + input.id}
                          onChange={(e) =>
                            this.notApprovedInputCheckbox(
                              e,
                              input.id,
                              "items_not_approved_serie[" +
                                input.id +
                                "][damaged]",
                              index
                            )
                          }
                          value="1"
                        />
                      </div>

                      <div className="radio-button ml-4">
                        <label htmlFor={"notdamaged_" + input.id}>Jo</label>
                        <input
                          className="form-check-input"
                          defaultChecked="checked"
                          name={
                            "items_not_approved_serie[" +
                            input.id +
                            "][damaged]"
                          }
                          onChange={(e) =>
                            this.notApprovedInputCheckbox(
                              e,
                              input.id,
                              "items_not_approved_serie[" +
                                input.id +
                                "][damaged]",
                              index
                            )
                          }
                          type="radio"
                          id={"notdamaged_" + input.id}
                          value="0"
                        />
                      </div>
                    </div>
                  </div>
                  {input[`items_not_approved_serie[${input.id}][damaged]`] ===
                    "1" && (
                    <Fragment>
                      <div className="col-12 col-md-4 p-0 px-md-2 pb-4">
                        <label htmlFor="Data e demtimit">Data e dëmtimit</label>
                        <DatePicker
                          placeholderText="Data kur është dëmtuar"
                          name={
                            "items_not_approved_serie[" +
                            input.id +
                            "][damaged_date]"
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          locale={sq}
                          dateFormat="dd-MM-yyyy"
                          selected={this.checkSelectedDate(
                            input[
                              `items_not_approved_serie[${input.id}][damaged_date]`
                            ]
                          )}
                          onChange={(e) =>
                            this.notApprovedInputDate(
                              e,
                              input.id,
                              "items_not_approved_serie[" +
                                input.id +
                                "][damaged_date]",
                              index
                            )
                          }
                          autoComplete="off"
                          className={
                            "date_input" +
                            (this.state.formError[
                              `items_not_approved_serie.${input.id}.damaged_date`
                            ]
                              ? " form-error"
                              : "")
                          }
                        />
                        {this.state.formError[
                          `items_not_approved_serie.${input.id}.damaged_date`
                        ] && (
                          <span className="error-message left">
                            {
                              this.state.formError[
                                `items_not_approved_serie.${input.id}.damaged_date`
                              ]
                            }
                          </span>
                        )}
                      </div>
                      <div className="col-12 col-md-4 p-0 pl-md-2 pb-4">
                        <label htmlFor="Data e rinovimit">
                          Data e rinovimit
                        </label>
                        <DatePicker
                          placeholderText="Data kur është rinovuar"
                          name={
                            "items_not_approved_serie[" +
                            input.id +
                            "][renewal_date]"
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          locale={sq}
                          dateFormat="dd-MM-yyyy"
                          selected={this.checkSelectedDate(
                            input[
                              `items_not_approved_serie[${input.id}][renewal_date]`
                            ]
                          )}
                          onChange={(e) =>
                            this.notApprovedInputDate(
                              e,
                              input.id,
                              "items_not_approved_serie[" +
                                input.id +
                                "][renewal_date]",
                              index
                            )
                          }
                          autoComplete="off"
                          className={
                            "date_input" +
                            (this.state.formError[
                              `items_not_approved_serie.${input.id}.renewal_date`
                            ]
                              ? " form-error"
                              : "")
                          }
                        />
                        {this.state.formError[
                          `items_not_approved_serie.${input.id}.renewal_date`
                        ] && (
                          <span className="error-message left">
                            {
                              this.state.formError[
                                `items_not_approved_serie.${input.id}.renewal_date`
                              ]
                            }
                          </span>
                        )}
                      </div>
                    </Fragment>
                  )}
                </div>
              )}
              <div className="show-hide-btns d-flex justify-content-end">
                <button
                  className="btn-styled-2 addFields"
                  type="button"
                  id={"add_new_row"}
                  onClick={() => this.addNewRow(input.id)}
                >
                  +
                </button>
                {this.state.buttons.removeBtn === true && (
                  <button
                    type="button"
                    onClick={() => this.removeRow(input.id)}
                    id={"remove-btn"}
                    className="btn-styled-1 ml-2 removeFields"
                  >
                    -
                  </button>
                )}
              </div>
            </div>
          </Fragment>
        );
      });
    }
    return (
      <div className="cols-sm-12 p-3 series-items input-items update-generated-serie-b">
        <span className="serie-title">Seria e gjeneruar automatikisht</span>

        <form onSubmit={this.updateGeneratedSerie}>
          <div className="col-12 d-flex p-0 m-0 mt-3">
            <div className="col-12 col-md-10 p-0 d-flex flex-wrap mb-4 align-items-baseline">
              <div className="col-12 col-md-4 p-0 pr-md-2 pb-4">
                <label htmlFor="">Emri i serisë</label>
                <input
                  placeholder="Shkruani emrin e serisë "
                  className={
                    "input-form-input" +
                    (this.state.formError.pattern ? " form-error" : "")
                  }
                  value={this.state.generatedSerie.pattern}
                  name="pattern"
                  type="text"
                  onChange={this.handleChange("pattern")}
                />
                {this.state.formError.pattern && (
                  <span className="error-message">
                    {this.state.formError.pattern}
                  </span>
                )}
              </div>
              <div className="col-12 col-md-4 p-0 px-md-2 pb-4">
                <label htmlFor="published_date">Data e botimit</label>
                <DatePicker
                  placeholderText="Data kur është botuar"
                  name="published_date"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale={sq}
                  dateFormat="dd-MM-yyyy"
                  selected={this.checkSelectedDate(
                    this.state.generatedSerie.published_date
                  )}
                  onChange={this.handleChangeDate("published_date")}
                  autoComplete="off"
                  className={
                    "date_input" +
                    (this.state.formError.published_date ? " form-error" : "")
                  }
                />
                {this.state.formError.published_date && (
                  <span className="error-message">
                    {this.state.formError.published_date}
                  </span>
                )}
              </div>
              <div className="col-12 col-md-4 p-0 pl-md-2 pb-4">
                <div className="file-upload">
                  <label htmlFor="cover-img">Kopertinë</label>
                  <div className="cover-img w-100">
                    <div className="file-upload1">
                      {this.state.temporary_img_path === null ? (
                        <div
                          className={
                            "image-upload-wrap1" +
                            (this.state.formError.cover ? " form-error" : "")
                          }
                          id="customInputImg"
                          style={{ display: "block" }}
                        >
                          <input
                            placeholder="Zgjidhni një kopertinë"
                            className={"file-upload-input1"}
                            accept="image/*"
                            onChange={this.fileSelectHandler}
                            name="cover"
                            type="file"
                            id="cover"
                          />
                          <div className="drag-text1">
                            <h3>
                              Zgjidhni një kopertinë{" "}
                              <img src={uploadIcon} alt="upload_cover" />
                            </h3>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="file-upload-content"
                          style={{ display: "block" }}
                        >
                          <img
                            className="file-upload-image"
                            src={this.state.temporary_img_path}
                            alt="Kopertina e serisë"
                          />
                          <button
                            type="button"
                            onClick={this.removeImage}
                            className="remove-image btn-unstyled"
                          >
                            ✕
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.formError.cover && (
                    <span className="error-message seriecover">
                      {this.state.formError.cover[0]}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row m-0 justify-content-between">
            <div className="col-12 col-md-10 p-0 series-items-block mb-0">
              {multipleInputs}
            </div>
            <div className="row m-0 align-items-end">
              {this.state.btnLoading === true ? (
                <button className="btn-styled-2 mt-3">
                  Duke u Aprovuar
                  <img
                    src={LoadingGif}
                    style={{
                      width: "18px",
                      margin: "-4px 0 0 7px",
                    }}
                  />
                </button>
              ) : (
                <button className="btn-styled-2 mt-3">Aprovo</button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(CreateSerie);
