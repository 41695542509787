import React from "react";
import { Component, Fragment } from "react";
import Request from "../../../../api/Request";
import Url from "../../../../api/Url";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Messages from "../../../../helpers/Messages";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
class EditAuthor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      id: this.props.popupId,
      name: this.props.popupName,
      listAuthors: this.props.listAuthors,
      error_list: [],
      isError: false,
      btnLoading: false,
    };
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  isShowModal = (status) => {
    this.handleClose();
    this.setState({ showModal: status });
  };

  handleClose = (modified) => {
    this.props.onPopupClose(false, modified);
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  updateAuthor = (e) => {
    this.setState({
      btnLoading: true,
    });
    e.preventDefault();
    const id = this.props.popupId;

    let data = {
      name: this.state.name,
    };
    Request.patchRequest(Url.updateAuthor + id, data).then((res) => {
      this.setState({
        btnLoading: false,
      });
      if (res.status === 200) {
        this.handleClose(true);
        Messages.swalSuccess(res.data.success);
      } else if (res.status === 422) {
        this.setState({
          error_list: res.data.name[0],
          isError: true,
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <Modal
          show={this.props.showModalPopup ? true : false}
          onHide={this.handleClose}
          size="md"
          centered
        >
          <Modal.Header>
            <Modal.Title id="sign-in-title">Ndrysho Autor</Modal.Title>
            <button
              className="btn-unstyled close-modal"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.handleClose}
            >
              ✕
            </button>
          </Modal.Header>
          <form onSubmit={this.updateAuthor} className="text-center">
            <Modal.Body>
              <div>
                <div className="m-0 row">
                  <div className="col-12">
                    <input
                      type="text"
                      name="name"
                      className={
                        "input-form-input mb-4" +
                        (this.state.isError === true ? " form-error" : "")
                      }
                      onChange={this.onNameChange.bind(this)}
                      value={this.state.name}
                      placeholder="Autor"
                    />
                    {this.state.isError === true && (
                      <span id="modal-edit" className="error-message left">
                        {this.state.error_list}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-12 d-flex m-0 p-0 justify-content-center">
                <div className="col-6 pl-0 pr-2 pr-sm-3 justify-content-center">
                  {this.state.btnLoading === true ? (
                    <button
                      className="btn-styled-2 w-100 text-center"
                      type="button"
                    >
                      Duke Aprovuar
                      <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                    </button>
                  ) : (
                    <button
                      className="btn-styled-2 w-100 text-center"
                      type="submit"
                    >
                      Po
                    </button>
                  )}
                </div>
                <div className="col-6 pr-0 pl-2 pl-sm-3">
                  <button
                    className="btn-styled-1 w-100 text-center"
                    type="button"
                    onClick={this.handleClose}
                  >
                    Anullo
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </Fragment>
    );
  }
}

export default withRouter(EditAuthor);
