import React, { Component, Fragment } from "react";

import { Helmet } from "react-helmet";
import Header from "../../components/opac/partials/Header";
import Footer from "../../components/opac/partials/Footer";
import LatestBooks from "../../components/opac/LatestBooks";


class LatestBooksView extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Biblioteka</title>
        </Helmet>
        <div  style={{ width: 100 + "%" }}>
          <Header
            roleUser={this.props.roles}
            title={"Librat më të fundit"}
            desc={'"Biblioteka nuk është luks por nevojë jetësore"\n' + "\n"}
          />
          <LatestBooks/>
          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default LatestBooksView;
