import AppConfig from '../services/AppConfig';
class Url {
  static apiBaseURL = AppConfig.apiBaseUrl;

  static login = this.apiBaseURL + 'login';
  static logout = this.apiBaseURL + 'logout';
  static emslogin = this.apiBaseURL + 'ems/login';
  static resetLink = this.apiBaseURL + 'sendresetlink';
  static resetPassword = this.apiBaseURL + 'resetpassword/';
  static storePassword = this.apiBaseURL + 'storeresetedpassword/';
  static activateAccount = this.apiBaseURL + 'user/registration/';
  static setPasswordAccount = this.apiBaseURL + 'user/store';
  /*----------------------------------------------------------------------------------------------------------
                                               USER
  ----------------------------------------------------------------------------------------------------------*/
  static profileData = this.apiBaseURL + 'profiledata';
  static changeUserPassword = this.apiBaseURL + 'storenewpassword';

  /*----------------------------------------------------------------------------------------------------------
                                               CATALOG
  ----------------------------------------------------------------------------------------------------------*/

  static listBiblioitems = this.apiBaseURL + 'catalog/listbiblioitems';
  static deleteBiblioitem = this.apiBaseURL + 'catalog/deletebiblioitem/';
  static showBiblioitem = this.apiBaseURL + 'catalog/showbiblioitem/';
  static editBiblioitem = this.apiBaseURL + 'catalog/editbiblioitem/';
  static updateBiblioitem = this.apiBaseURL + 'catalog/updatebiblioitem/';
  static createBiblioitem = this.apiBaseURL + 'catalog/createbiblioitem/';
  static storeBiblioitem = this.apiBaseURL + 'catalog/storebiblioitem';
  static editItems = this.apiBaseURL + 'catalog/edititems/';
  static updateItems = this.apiBaseURL + 'catalog/updateitems/';
  static editBiblioitemOnly = this.apiBaseURL + 'catalog/editbiblioitemonly/';
  static updateBiblioitemonly =
    this.apiBaseURL + 'catalog/updatebiblioitemonly/';
  static storeItems = this.apiBaseURL + 'catalog/storeitem';

  static listServer = this.apiBaseURL + 'z3950/list-of-servers';
  static storeServer = this.apiBaseURL + 'z3950/store-server';
  static deleteServer = this.apiBaseURL + 'z3950/delete-server/';
  static editServer = this.apiBaseURL + 'z3950/edit-server/';
  static updateServer = this.apiBaseURL + 'z3950/update-server/';
  static getServers = this.apiBaseURL + 'z3950/get-servers';
  static getRecords = this.apiBaseURL + 'z3950/get-data';
  static storeZ3950Records = this.apiBaseURL + 'z3950/store-data-records';

  static storeBiblio = this.apiBaseURL + 'catalog/storebiblio';

  static createBiblio = this.apiBaseURL + 'catalog/createbiblio';
  static editBiblio = this.apiBaseURL + 'catalog/editbiblio/';
  static selectedBiblio = this.apiBaseURL + 'catalog/selectbiblio/';
  static updateBiblio = this.apiBaseURL + 'catalog/updatebiblio/';

  static createKeywordBiblio = this.apiBaseURL + 'catalog/storekeywordbiblio';
  static createAuthorBiblio = this.apiBaseURL + 'catalog/storeauthorbiblio';
  static createGeotermBiblio =
    this.apiBaseURL + 'catalog/storegeographicaltermbiblio';

  static listAuthors = this.apiBaseURL + 'catalog/list/authors';
  static storeAuthor = this.apiBaseURL + 'catalog/storeauthor';
  static editAuthor = this.apiBaseURL + 'catalog/editauthor/';
  static updateAuthor = this.apiBaseURL + 'catalog/updateauthor/';
  static deleteAuthor = this.apiBaseURL + 'catalog/deleteauthor/';

  static listPublishers = this.apiBaseURL + 'catalog/list/publishers';
  static storePublisher = this.apiBaseURL + 'catalog/storepublisher';
  static editPublisher = this.apiBaseURL + 'catalog/editpublisher/';
  static updatePublisher = this.apiBaseURL + 'catalog/updatepublisher/';
  static deletePublisher = this.apiBaseURL + 'catalog/deletepublisher/';

  static listKeywords = this.apiBaseURL + 'catalog/list/keywords';
  static storeKeyword = this.apiBaseURL + 'catalog/storekeyword';
  static editKeyword = this.apiBaseURL + 'catalog/editkeyword/';
  static updateKeyword = this.apiBaseURL + 'catalog/updatekeyword/';
  static deleteKeyword = this.apiBaseURL + 'catalog/deletekeyword/';

  static listGeographicalterms =
    this.apiBaseURL + 'catalog/list/geographicalterm';
  static storeGeographicalterm =
    this.apiBaseURL + 'catalog/storegeographicalterm';
  static editGeographicalterm =
    this.apiBaseURL + 'catalog/editgeographicalterm/';
  static updateGeographicalterm =
    this.apiBaseURL + 'catalog/updategeographicalterm/';
  static deleteGeographicalterm =
    this.apiBaseURL + 'catalog/deletegeographicalterm/';

  //Import MARC
  static saveMarcFile = this.apiBaseURL + 'catalog/savemarcfile';
  static confirmMarcRecords = this.apiBaseURL + 'catalog/confirmmarcrecords/'; //marc file parameter
  static storeMarcRecords = this.apiBaseURL + 'catalog/storemarcrecords';

  //Export MARC
  static selectMarcRecords = this.apiBaseURL + 'catalog/exportmarc';
  static saveMarcRecords = this.apiBaseURL + 'catalog/savemarcrecords';

  /*----------------------------------------------------------------------------------------------------------
                                               ACQUISITION
  ----------------------------------------------------------------------------------------------------------*/

  static listAcquisitions = this.apiBaseURL + 'acquisition/list';
  static storeAcquisition = this.apiBaseURL + 'acquisition/store';
  static showAcquisition = this.apiBaseURL + 'acquisition/show/'; //acquisition id -> parameter
  static editAcquisition = this.apiBaseURL + 'acquisition/edit/'; //acquisition id -> parameter
  static updateAcquisition = this.apiBaseURL + 'acquisition/update/'; //acquisition id -> parameter
  static deleteAcquisition = this.apiBaseURL + 'acquisition/delete/'; //acquisition id -> parameter
  static approveAcquisition = this.apiBaseURL + 'acquisition/approve'; //acquisition id -> parameter
  static cancelAcquisition = this.apiBaseURL + 'acquisition/cancel'; //acquisition id -> parameter

  /*----------------------------------------------------------------------------------------------------------
                                             BLOG
  ----------------------------------------------------------------------------------------------------------*/

  static listBlogPosts = this.apiBaseURL + 'blog/list';
  static storeBlogPost = this.apiBaseURL + 'blog/store';
  static editBlogPost = this.apiBaseURL + 'blog/edit/'; //post id -> parameter
  static updateBlogPost = this.apiBaseURL + 'blog/update/'; //post id -> parameter
  static deleteBlogPost = this.apiBaseURL + 'blog/delete/'; //post id -> parameter
  static deleteIntroImage = this.apiBaseURL + 'blog/deleteimage/'; //post id -> parameter

  /*----------------------------------------------------------------------------------------------------------
                                           BORROWING
  ----------------------------------------------------------------------------------------------------------*/

  static searchMaterialOrMember = this.apiBaseURL + 'borrowing';
  static searchNID = this.apiBaseURL + 'borrowing/get-ems-profile-data-by-nid';
  static searchMaterial = this.apiBaseURL + 'borrowing/member/';
  static searchMember = this.apiBaseURL + 'borrowing/item/';
  static confirmBorrowing = this.apiBaseURL + 'borrowing/member/';
  static saveBorrowing = this.apiBaseURL + 'borrowing/store';
  static listBorrowings = this.apiBaseURL + 'borrowing/list';
  static showBorrowing = this.apiBaseURL + 'borrowing/';
  static applyFine = this.apiBaseURL + 'borrowing/applyfine';
  static payFine = this.apiBaseURL + 'borrowing/payfine';
  static postponeBorrowing = this.apiBaseURL + 'borrowing/postpone';
  static listReservations = this.apiBaseURL + 'borrowing/reservationlist';
  static listPending = this.apiBaseURL + 'borrowing/listpending';
  static cancelPending = this.apiBaseURL + 'borrowing/cancelpending';
  static borrowPendingMaterial = this.apiBaseURL + 'borrowing/storecheckout';
  static borrowReservedMaterial =
    this.apiBaseURL + 'borrowing/storecheckoutreservation';
  static returnBorrowing = this.apiBaseURL + 'borrowing/return';

  /*----------------------------------------------------------------------------------------------------------
                                             MEMBER
   ----------------------------------------------------------------------------------------------------------*/
  static listMembers = this.apiBaseURL + 'member/list';
  static inviteMember = this.apiBaseURL + 'member/invite';
  static storeMember = this.apiBaseURL + 'member/storeinvatiomember';
  static showMember = this.apiBaseURL + 'member/show/';
  static deleteMember = this.apiBaseURL + 'member/delete/';
  static memberBorrowings = this.apiBaseURL + 'member/borrowings/';
  static memberReservations = this.apiBaseURL + 'member/reservations/';
  static memberFines = this.apiBaseURL + 'member/fines/';

  /*----------------------------------------------------------------------------------------------------------
                                             SERIAL
   ----------------------------------------------------------------------------------------------------------*/

  static listSerials = this.apiBaseURL + 'serial/listserials';
  static showSerial = this.apiBaseURL + 'serial/show/';
  static createSerial = this.apiBaseURL + 'serial/create';
  static storeSerial = this.apiBaseURL + 'serial/store';
  static editSerial = this.apiBaseURL + 'serial/edit/';
  static updateSerial = this.apiBaseURL + 'serial/update/';
  static deleteSerial = this.apiBaseURL + 'serial/deleteserial/';
  static updateGeneratedSerie = this.apiBaseURL + 'serial/updategeneratedserie';
  static editSerie = this.apiBaseURL + 'serial/editserie/';
  static updateSerie = this.apiBaseURL + 'serial/updateserie/';
  static deleteSerie = this.apiBaseURL + 'serial/deleteserie/';
  static deleteSerieCover = this.apiBaseURL + 'serial/deleteseriecover/';
  static storeSerieItems = this.apiBaseURL + 'serial/storeserieitems';
  static deleteSerieItem = this.apiBaseURL + 'serial/deleteserieitem/';
  static updateSerieItems = this.apiBaseURL + 'serial/updateserieitems/';
  static listFrequences = this.apiBaseURL + 'serial/list/frequences';
  static storeFrequence = this.apiBaseURL + 'serial/storefrequence';
  static editFrequence = this.apiBaseURL + 'serial/editfrequence/';
  static updateFrequence = this.apiBaseURL + 'serial/updatefrequence/';
  static deleteFrequence = this.apiBaseURL + 'serial/deletefrequece/';

  /*----------------------------------------------------------------------------------------------------------
                                             OPAC
   ----------------------------------------------------------------------------------------------------------*/

  static latestBooks = this.apiBaseURL + 'latest-books/';
  static mostReadBooks = this.apiBaseURL + 'most-read-books/';
  static digitizedBooks = this.apiBaseURL + 'digitized-books/';
  static blogPosts = this.apiBaseURL + 'news/';
  static showBlogPost = this.apiBaseURL + 'news/show/';
  static storeContact = this.apiBaseURL + 'storecontact';
  static simpleSearch = this.apiBaseURL + 'search';
  static advancedSearch = this.apiBaseURL + 'advanced-search';
  static detailed = this.apiBaseURL + 'detailed/';
  static filter = this.apiBaseURL + 'filter';
  static getdocument = this.apiBaseURL + 'document/';
  static getstatusreserve = this.apiBaseURL + 'detailed/status/';
  // static reservematerial= this.apiBaseURL + "detailed/status/";

  /*----------------------------------------------------------------------------------------------------------
                                             OPAC STUDENT
   ----------------------------------------------------------------------------------------------------------*/

  static profile = this.apiBaseURL + 'profile';
  static listBorrowingsOpac = this.apiBaseURL + 'borrowings';
  static listReservationsOpac = this.apiBaseURL + 'reservations';
  static fines = this.apiBaseURL + 'fines';
  static profileDetails = this.apiBaseURL + 'profile/details';
  static reserve = this.apiBaseURL + 'reserve';

  /*----------------------------------------------------------------------------------------------------------
                                            BACKUP
  ----------------------------------------------------------------------------------------------------------*/

  static listbackups = this.apiBaseURL + 'backup/listbackups';
  static delteBackup = this.apiBaseURL + 'backup/delete/';
  static listlogs = this.apiBaseURL + 'logs';

  /*----------------------------------------------------------------------------------------------------------
                                            Moduli RAPORT
----------------------------------------------------------------------------------------------------------*/
  static stats = this.apiBaseURL + 'report/stats';
  static reportBibliography = this.apiBaseURL + 'report/catalog/bibliography';
  static exportCatalog = this.apiBaseURL + 'report/catalog/export_catalog';
  static exportDamagedLost =
    this.apiBaseURL + 'report/catalog/export_damagedlost';
  static reportDamagedLost = this.apiBaseURL + 'report/catalog/damagedorlost';

  static reportBorrowingNow = this.apiBaseURL + 'report/borrowing/now';
  static reportBorrowingInTime = this.apiBaseURL + 'report/borrowing/intime';
  static reportInLibrary = this.apiBaseURL + 'report/borrowing/inlibrary';

  static reportExportBorrowingNow =
    this.apiBaseURL + 'report/borrowing/export_borrowings_now';
  static reportExportBorrowingInTime =
    this.apiBaseURL + 'report/borrowing/export_borrowings';
  static reportExportInLibrary =
    this.apiBaseURL + 'report/borrowing/export_inlibrary';

  /*----------------------------------------------------------------------------------------------------------
                                          HELPERS
  ----------------------------------------------------------------------------------------------------------*/

  static materialTypes = this.apiBaseURL + 'material/types';
  static memberRoles = this.apiBaseURL + 'member/roles';

  static staffRoles = this.apiBaseURL + 'staff/roles';

  /*----------------------------------------------------------------------------------------------------------
                                            Moduli STAF
  ----------------------------------------------------------------------------------------------------------*/
  static staffList = this.apiBaseURL + 'staff/list';
  static staffInvite = this.apiBaseURL + 'staff/storeinvationstaff';
  static showStaff = this.apiBaseURL + 'staff/show/';
  static deleteStaff = this.apiBaseURL + 'staff/delete/';

  static userEdit = this.apiBaseURL + 'user/edit/';
  static updateUser = this.apiBaseURL + 'user/update/';

  static changeMembership = this.apiBaseURL + 'user/changemembershipend';
}

export default Url;
