import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import Request from "../../../../../api/Request";
import Messages from "../../../../../helpers/Messages";
import AppConfig from "../../../../../services/AppConfig";
import Url from "../../../../../api/Url";
import EditBiblio from "./EditBiblio";
import EditBiblioitem from "./EditBiblioitem";
import EditItems from "./EditItems";
import "../../../../../assets/css/editmaterial.css";
import expand from "../../../../../assets/images/icons/arrow_white_blue_expand.png";
import collapse from "../../../../../assets/images/icons/arrow_white_blue_collapse.png";
import moment from "moment";
import Web from "../../../../../routes/Web";
import LoadingGif from "../../../../../assets/images/icons/loading.gif";

class EditMaterial extends Component {
  componentDidMount() {
    const id = this.props.match.params.id;

    Request.getRequest(Url.editBiblioitem + id).then((response) => {
      if (response.status === 200) {
        this.setState({
          oldgeo: response.data.geographical_terms,
          oldkey: response.data.key_words,
          geographical_terms: response.data.geographical_terms,
          key_words: response.data.key_words,
          media_types: response.data.media_types,
          publishers: response.data.publishers,
          languages: response.data.languages,
          authors: response.data.authors,
          biblioitem: response.data.biblioitem,
          material_types: response.data.material_types,
          items: response.data.items,
          biblio: response.data.biblioitem.biblio,
          biblios: response.data.biblios,
          selectedKeywords: response.data.biblioitem.biblio.keywords,
          selectedGeoTerms: response.data.biblioitem.biblio.geographicalterms,
          selectedAuthors: [],
          selectedContributors: [],
          contributors: response.data.contributors,
        });

        this.state.biblio.authors.map((author) => {
          var selected = {
            id: author.id,
            name: author.name,
            type: author.pivot.type,
          };

          this.setState({
            selectedAuthors: [...this.state.selectedAuthors, selected],
          });
        });
        //contributors
        this.state.biblioitem.contributors.map((author) => {
          var selected = {
            id: author.id,
            name: author.name,
            type: author.pivot.type,
          };

          this.setState({
            selectedContributors: [
              ...this.state.selectedContributors,
              selected,
            ],
          });
        });

        const inputs1 = this.state.key_words;
        this.state.selectedKeywords.map((keyword) => {
          var removeIndex = inputs1.findIndex((item) => item.id === keyword.id);
          inputs1.splice(removeIndex, 1);
        });
        const geoterms = this.state.geographical_terms;
        this.state.selectedGeoTerms.map((geot) => {
          var removeIndex = geoterms.findIndex((item) => item.id === geot.id);
          geoterms.splice(removeIndex, 1);
        });

        if (
          response.data.biblioitem.biblio.materialtype !== null ||
          response.data.biblioitem.biblio.materialtype
        ) {
          this.setState({
            materialType: {
              value: response.data.biblioitem.biblio.materialtype.id,
              label: response.data.biblioitem.biblio.materialtype.name,
            },
          });
        }

        if (
          response.data.biblioitem.biblio.language ||
          response.data.biblioitem.biblio.language !== null
        ) {
          this.setState({
            language: {
              value: response.data.biblioitem.biblio.language.id,
              label: response.data.biblioitem.biblio.language.name,
            },
          });
        }
        if (
          response.data.biblioitem.biblio.mediatype ||
          response.data.biblioitem.biblio.mediatype !== null
        ) {
          this.setState({
            mediaType: {
              value: response.data.biblioitem.biblio.mediatype.id,
              label: response.data.biblioitem.biblio.mediatype.name,
            },
          });
        }

        if (
          response.data.biblioitem.language ||
          response.data.biblioitem.language !== null
        ) {
          this.setState({
            biblioitemLanguage: {
              value: response.data.biblioitem.language.id,
              label: response.data.biblioitem.language.name,
            },
          });
        }
        if (
          response.data.biblioitem.publisher ||
          response.data.biblioitem.publisher !== null
        ) {
          this.setState({
            publisher: {
              value: response.data.biblioitem.publisher.id,
              label: response.data.biblioitem.publisher.name,
            },
          });
        }

        const contributors = this.state.contributors;
        this.state.selectedContributors.map((cont) => {
          var removeIndex = contributors.findIndex(
            (item) => item.id === cont.id
          );
          contributors.splice(removeIndex, 1);
        });

        const authors = this.state.authors;
        this.state.selectedAuthors.map((author) => {
          var removeIndex = authors.findIndex((item) => item.id === author.id);
          authors.splice(removeIndex, 1);
        });

        this.setState({
          key_words: inputs1,
          geographical_terms: geoterms,
          authors: authors,
          contributors: contributors,
        });
      } else if (response.status === 404) {
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      open_biblio: false,
      open_biblioitem: true,
      open_items: false,

      biblioitem: [],
      biblio: [],
      biblios: [],

      oldgeo: [],
      oldkey: [],
      geographical_terms: [],
      key_words: [],
      media_types: [],
      publishers: [],
      languages: [],
      authors: [],

      items: [],
      material_types: [],
      errors: [],
      newitems: [],
      deleteditems: [],
      index: 0,
      selectedKeywords: [],
      biblio_id: "",
      selectedGeoTerms: [],
      cover: null,
      temporary_coverpath: "",
      url: null,
      temporary_urlpath: "",
      delete_cover: 0,
      delete_url: 0,
      materialType: {
        value: "",
        label: "",
      },
      language: {
        value: "",
        label: "",
      },
      mediaType: {
        value: "",
        label: "",
      },
      publisher: {
        value: "",
        label: "",
      },
      biblioitemLanguage: {
        value: "",
        label: "",
      },
      selectedAuthors: [],
      selectedContributors: [],
      contributors: [],
      btnLoading: false,
    };
  }

  fileSelectHandler = (event) => {
    this.setState({
      cover: event.target.files[0],
      temporary_coverpath: URL.createObjectURL(event.target.files[0]),
      delete_cover: 0,
    });
  };

  fileSelectHandlerUrl = (event) => {
    this.setState({
      url: event.target.files[0],
      temporary_urlpath: URL.createObjectURL(event.target.files[0]),
      delete_url: 0,
    });
  };

  // tabet
  changeState = (element, value) => {
    if (element === "biblio") {
      this.setState({
        open_biblio: value,
      });
    } else if (element === "biblioitem") {
      this.setState({
        open_biblioitem: value,
      });
    } else if (element === "items") {
      this.setState({
        open_items: value,
      });
    }
  };

  // childs onchange
  onChange = (key) => (e) => {
    let newForm = {
      ...this.state.biblioitem,
      [key]: e.target.value,
    };
    this.setState({
      biblioitem: newForm,
    });
  };

  handleChangeSelect = (key, state) => (e) => {
    var selectValue;
    var selectLabel;
    if (e !== null) {
      selectValue = e.value;
      selectLabel = e.label;
    } else {
      selectValue = "";
      selectLabel = "";
    }
    let newForm = {
      ...this.state.biblioitem,
      [key]: selectValue,
    };
    let newForm1 = {
      ...this.state[state],
      value: selectValue,
      label: selectLabel,
    };
    this.setState({
      biblioitem: newForm,
      [state]: newForm1,
    });
  };

  onChangeBiblio = (key) => (e) => {
    let newForm = {
      ...this.state.biblio,
      [key]: e.target.value,
    };
    this.setState({
      biblio: newForm,
    });
  };

  handleChangeSelectBiblio = (key, state) => (e) => {
    var selectValue;
    var selectLabel;
    if (e !== null) {
      selectValue = e.value;
      selectLabel = e.label;
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.biblio,
      [key]: selectValue,
    };
    let newForm1 = {
      ...this.state[state],
      value: selectValue,
      label: selectLabel,
    };
    this.setState({
      biblio: newForm,
      [state]: newForm1,
    });
  };

  // items

  handleChangeItem = (e, name, i) => {
    const inputs = this.state.items;

    var index = -2;
    inputs.map((item, key) => {
      if (item.id === i) {
        index = key;
        return key;
      }
    });

    inputs[index][name] = e.target.value;
    this.setState({
      items: inputs,
    });
  };

  handleChangeSelectItem = (e, name, i) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    const inputs = this.state.items;
    var index = -2;
    inputs.map((item, key) => {
      if (item.id === i) {
        index = key;
        return key;
      }
    });

    inputs[index][name] = selectValue;

    this.setState({
      items: inputs,
    });
  };

  handleChangeDate = (e, name, i) => {
    const inputs = this.state.items;

    var index = -2;
    inputs.map((item, key) => {
      if (item.id === i) {
        index = key;
        return key;
      }
    });

    var value = null;
    if (e !== null) {
      value = moment(e).format("yyyy-MM-DD");
    }
    inputs[index][name] = value;
    this.setState({
      items: inputs,
    });
  };

  deleteItem = (i) => {
    var inputs = this.state.items;
    var index = -2;
    inputs.map((item, key) => {
      if (item.id === i) {
        index = key;
        return key;
      }
    });

    var deleted = inputs.splice(index, 1);

    this.setState({
      items: inputs,
      deleteditems: [...this.state.deleteditems, i],
    });
  };

  // new items
  newItem = () => {
    let newForm = {
      borrowing_type: "",
      inventory_number: "",
      place_number: "",
      damaged: 0,
      damaged_date: "",
      renewal_date: "",
      index: this.state.index,
    };
    this.setState({
      newitems: [...this.state.newitems, newForm],
      index: this.state.index + 1,
    });
  };

  handleChangeSelectNewItems = (e, name, i) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    const inputs = this.state.newitems;
    var index = -1;
    inputs.map((item, key) => {
      if (item.index === i) {
        index = key;
        return key;
      }
    });

    inputs[index][name] = selectValue;

    this.setState({
      newitems: inputs,
    });
  };

  handleChangeNewItems = (e, name, i) => {
    const inputs = this.state.newitems;
    var index = -1;
    inputs.map((item, key) => {
      if (item.index === i) {
        index = key;
        return key;
      }
    });

    inputs[index][name] = e.target.value;
    this.setState({
      newitems: inputs,
    });
  };

  handleChangeDateNewItems = (e, name, i) => {
    const inputs = this.state.newitems;
    var index = -1;
    inputs.map((item, key) => {
      if (item.index === i) {
        index = key;
        return key;
      }
    });

    var value = null;
    if (e !== null) {
      value = moment(e).format("yyyy-MM-DD");
    }
    inputs[index][name] = value;
    this.setState({
      newitems: inputs,
    });
  };

  removeItem = (i) => {
    var inputs = this.state.newitems;
    var index = -2;
    inputs.map((item, key) => {
      if (item.index === i) {
        index = key;
        return key;
      }
    });

    inputs.splice(index, 1);
    this.setState({
      newitems: inputs,
    });
  };

  //   update
  update = () => {
    const id = this.props.match.params.id;
    this.setState({ btnLoading: true });
    const formData = new FormData();

    formData.append(
      "materialtype_id",
      this.state.biblio.materialtype_id === null
        ? ""
        : this.state.biblio.materialtype_id
    );
    formData.append(
      "mediatype_id",
      this.state.biblio.mediatype_id === null
        ? ""
        : this.state.biblio.mediatype_id
    );
    formData.append(
      "biblio_language_id",
      this.state.biblio.language_id === null
        ? ""
        : this.state.biblio.language_id
    );
    formData.append(
      "title",
      this.state.biblio.title === null ? "" : this.state.biblio.title
    );
    formData.append(
      "medium",
      this.state.biblio.medium === null ? "" : this.state.biblio.medium
    );
    formData.append(
      "subtitle",
      this.state.biblio.subtitle === null ? "" : this.state.biblio.subtitle
    );
    formData.append(
      "part_number",
      this.state.biblio.part_number === null
        ? ""
        : this.state.biblio.part_number
    );
    formData.append(
      "part_name",
      this.state.biblio.part_name === null ? "" : this.state.biblio.part_name
    );
    formData.append(
      "notes",
      this.state.biblio.notes === null ? "" : this.state.biblio.notes
    );

    formData.append(
      "abstract",
      this.state.biblio.abstract === null ? "" : this.state.biblio.abstract
    );
    formData.append(
      "agerestriction",
      this.state.biblio.agerestriction === null
        ? ""
        : this.state.biblio.agerestriction
    );
    formData.append(
      "year_original_publication",
      this.state.biblio.year_original_publication === null
        ? ""
        : this.state.biblio.year_original_publication
    );

    formData.append(
      "publisher_id",
      this.state.biblioitem.publisher_id === null
        ? ""
        : this.state.biblioitem.publisher_id
    );
    formData.append(
      "biblioitem_language_id",
      this.state.biblioitem.language_id === null
        ? ""
        : this.state.biblioitem.language_id
    );
    formData.append(
      "isbn",
      this.state.biblioitem.isbn === null ? "" : this.state.biblioitem.isbn
    );
    formData.append(
      "issn",
      this.state.biblioitem.issn === null ? "" : this.state.biblioitem.issn
    );
    formData.append(
      "publication_year",
      this.state.biblioitem.publication_year === null
        ? ""
        : this.state.biblioitem.publication_year
    );
    formData.append(
      "physical_details",
      this.state.biblioitem.physical_details === null
        ? ""
        : this.state.biblioitem.physical_details
    );
    formData.append(
      "pages",
      this.state.biblioitem.pages === null ? "" : this.state.biblioitem.pages
    );

    formData.append(
      "dimensions",
      this.state.biblioitem.dimensions === null
        ? ""
        : this.state.biblioitem.dimensions
    );
    formData.append(
      "place",
      this.state.biblioitem.place === null ? "" : this.state.biblioitem.place
    );
    formData.append(
      "lccn",
      this.state.biblioitem.lccn === null ? "" : this.state.biblioitem.lccn
    );
    formData.append(
      "kdu",
      this.state.biblioitem.kdu === null ? "" : this.state.biblioitem.kdu
    );

    formData.append(
      "publication_number",
      this.state.biblioitem.publication_number === null
        ? ""
        : this.state.biblioitem.publication_number
    );
    formData.append(
      "national_control_number",
      this.state.biblioitem.national_control_number === null
        ? ""
        : this.state.biblioitem.national_control_number
    );
    formData.append(
      "price",
      this.state.biblioitem.price === null ? "" : this.state.biblioitem.price
    );
    formData.append(
      "replacement_price",
      this.state.biblioitem.replacement_price === null
        ? ""
        : this.state.biblioitem.replacement_price
    );

    formData.append(
      "currency",
      this.state.biblioitem.currency === null
        ? ""
        : this.state.biblioitem.currency
    );
    formData.append(
      "ean",
      this.state.biblioitem.ean === null ? "" : this.state.biblioitem.ean
    );
    formData.append(
      "barcode",
      this.state.biblioitem.barcode === null
        ? ""
        : this.state.biblioitem.barcode
    );
    formData.append(
      "biblio_id",
      this.state.biblio_id === null ? "" : this.state.biblioitem.biblio_id
    );

    formData.append("cover", this.state.cover === null ? "" : this.state.cover);

    formData.append("url", this.state.url === null ? "" : this.state.url);

    this.state.items.map((item, index) => {
      formData.append(
        "items[" + index + "][id]",
        item.id === null ? "" : item.id
      );
      formData.append(
        "items[" + index + "][borrowing_type]",
        item.borrowing_type === null ? "" : item.borrowing_type
      );
      formData.append(
        "items[" + index + "][inventory_number]",
        item.inventory_number === null ? "" : item.inventory_number
      );
      formData.append(
        "items[" + index + "][place_number]",
        item.place_number === null ? "" : item.place_number
      );
      formData.append(
        "items[" + index + "][damaged]",
        item.damaged === null ? "" : item.damaged
      );
      formData.append(
        "items[" + index + "][damaged_date]",
        item.damaged_date === null ? "" : item.damaged_date
      );
      formData.append(
        "items[" + index + "][renewal_date]",
        item.renewal_date === null ? "" : item.renewal_date
      );

      formData.append(
        "items[" + index + "][lost]",
        item.lost === null ? "" : item.lost
      );
      formData.append(
        "items[" + index + "][lost_date]",
        item.lost_date === null ? "" : item.lost_date
      );
    });

    this.state.newitems.map((item, index) => {
      formData.append(
        "newitems[" + index + "][borrowing_type]",
        item.borrowing_type === null ? "" : item.borrowing_type
      );
      formData.append(
        "newitems[" + index + "][inventory_number]",
        item.inventory_number === null ? "" : item.inventory_number
      );
      formData.append(
        "newitems[" + index + "][place_number]",
        item.place_number === null ? "" : item.place_number
      );
      formData.append(
        "newitems[" + index + "][damaged]",
        item.damaged === null ? "" : item.damaged
      );
      formData.append(
        "newitems[" + index + "][damaged_date]",
        item.damaged_date === null ? "" : item.damaged_date
      );
      formData.append(
        "newitems[" + index + "][renewal_date]",
        item.renewal_date === null ? "" : item.renewal_date
      );
    });

    this.state.selectedKeywords.map((keyword, index) => {
      formData.append("key_words[" + index + "]", keyword.id);
    });
    this.state.selectedGeoTerms.map((geot, index) => {
      formData.append("geographical_terms[" + index + "]", geot.id);
    });

    this.state.selectedAuthors.map((author, index) => {
      formData.append("authors[" + index + "][author_id]", author.id);
      formData.append("authors[" + index + "][type]", author.type);
    });

    this.state.selectedContributors.map((author, index) => {
      formData.append("contributors[" + index + "][author_id]", author.id);
      formData.append("contributors[" + index + "][type]", author.type);
    });

    this.state.deleteditems.map((item, index) => {
      formData.append("deleteditems[" + index + "]", item);
    });

    if (this.state.cover === null && this.state.delete_cover === 1) {
      formData.append("delete_cover", 1);
    }

    if (this.state.url === null && this.state.delete_url === 1) {
      formData.append("delete_url", 1);
    }

    formData.append("_method", "PATCH");

    Request.postRequest(Url.updateBiblioitem + id, formData).then(
      (response) => {
        this.setState({ btnLoading: false });
        if (response.status === 200) {
          this.props.history.push(Web.listBiblioitems);
          Messages.swalSuccess(response.data.success);
          this.setState({
            errors: [],
          });
        } else if (response.status === 405) {
          Messages.swalInfo(response.data.info);
        } else if (response.status === 422) {
          Messages.swalError(response.data.error);
          this.setState({
            errors: response.data,
          });
        } else {
          Messages.swalError(AppConfig.serverError + response.data);
        }
      }
    );
  };

  //delete cover and file

  deleteCover = () => {
    this.setState({
      cover: null,
      temporary_coverpath: null,
      delete_cover: 1,
    });
  };

  deleteUrl = () => {
    this.setState({
      url: null,
      temporary_urlpath: null,
      delete_url: 1,
    });
  };

  // kewywords
  handleKeyword = (dataKeyword, control) => {
    this.setState({
      selectedKeywords: [...this.state.selectedKeywords, dataKeyword],
    });
    if (control === "old") {
      const inputs1 = this.state.key_words;
      const removeIndex = inputs1.findIndex(
        (item) => item.id === dataKeyword.id
      );
      inputs1.splice(removeIndex, 1);
      this.setState({
        key_words: inputs1,
      });
    }
  };

  removeKeyword = (e, keyword) => {
    e.preventDefault();

    this.setState({
      key_words: [...this.state.key_words, keyword],
    });

    const inputs1 = this.state.selectedKeywords;
    const removeIndex = inputs1.findIndex((item) => item.id === keyword.id);
    inputs1.splice(removeIndex, 1);
    this.setState({
      selectedKeywords: inputs1,
    });
  };
  //geoterms
  handleGeoTerm = (dataGeoTerm, control) => {
    this.setState({
      selectedGeoTerms: [...this.state.selectedGeoTerms, dataGeoTerm],
    });
    if (control === "old") {
      const inputs1 = this.state.geographical_terms;
      const removeIndex = inputs1.findIndex(
        (item) => item.id === dataGeoTerm.id
      );
      inputs1.splice(removeIndex, 1);
      this.setState({
        geographical_terms: inputs1,
      });
    }
  };

  removeGeoTerm = (e, geoterm) => {
    e.preventDefault();

    this.setState({
      geographical_terms: [...this.state.geographical_terms, geoterm],
    });

    const inputs1 = this.state.selectedGeoTerms;
    const removeIndex = inputs1.findIndex((item) => item.id === geoterm.id);
    inputs1.splice(removeIndex, 1);
    this.setState({
      selectedGeoTerms: inputs1,
    });
  };

  //change biblio
  changeBiblio = (biblio) => {
    this.setState({
      materialType: {
        value: biblio.materialtype ? biblio.materialtype.id : "",
        label: biblio.materialtype ? biblio.materialtype.name : "",
      },

      language: {
        value: biblio.language ? biblio.language.id : "",
        label: biblio.language ? biblio.language.name : "",
      },
      mediaType: {
        value: biblio.mediatype ? biblio.mediatype.id : "",
        label: biblio.mediatype ? biblio.mediatype.name : "",
      },
    });

    this.state.selectedKeywords.map((keyword) => {
      this.setState({
        key_words: [...this.state.key_words, keyword],
      });
    });
    this.state.selectedGeoTerms.map((geo) => {
      this.setState({
        geographical_terms: [...this.state.geographical_terms, geo],
      });
    });

    this.state.selectedAuthors.map((author) => {
      this.setState({
        authors: [...this.state.authors, author],
      });
    });
    this.setState({
      biblio: biblio,
      biblio_id: biblio.id,
      selectedKeywords: biblio.keywords,
      selectedGeoTerms: biblio.geographicalterms,
      selectedAuthors: [],
    });

    biblio.authors.map((author) => {
      var selected = {
        id: author.id,
        name: author.name,
        type: author.pivot.type,
      };
      this.setState({
        selectedAuthors: [...this.state.selectedAuthors, selected],
      });
    });

    const authors = this.state.authors;
    this.state.selectedAuthors.map((author) => {
      const removeIndex = authors.findIndex((item) => item.id === author.id);
      authors.splice(removeIndex, 1);
    });

    const inputs1 = this.state.key_words;
    this.state.selectedKeywords.map((keyword) => {
      const removeIndex = inputs1.findIndex((item) => item.id === keyword.id);
      inputs1.splice(removeIndex, 1);
    });

    const geoterms = this.state.geographical_terms;

    this.state.selectedGeoTerms.map((geot) => {
      const removeIndex = geoterms.findIndex((item) => item.id === geot.id);
      geoterms.splice(removeIndex, 1);
    });

    this.setState({
      key_words: inputs1,
      geographical_terms: geoterms,
      errors: [],
    });
  };

  emptyState = () => {
    this.setState({
      biblio: this.state.biblioitem.biblio,
      biblio_id: "",
    });
    this.componentDidMount();
  };

  //author
  handleCallbackAuthor = (dataAuthor, typeAuthor, control) => {
    var author = { id: dataAuthor.id, name: dataAuthor.name, type: typeAuthor };
    this.setState({
      selectedAuthors: [...this.state.selectedAuthors, author],
    });
    if (control === "old") {
      const inputs1 = this.state.authors;
      const removeIndex = inputs1.findIndex(
        (item) => item.id === dataAuthor.id
      );
      inputs1.splice(removeIndex, 1);
      this.setState({
        authors: inputs1,
      });
    }
  };

  removeAuthor = (e, author, type) => {
    e.preventDefault();

    var authorrem = { id: author.id, name: author.name };
    this.setState({
      authors: [...this.state.authors, authorrem],
    });

    const inputs1 = this.state.selectedAuthors;
    const removeIndex = inputs1.findIndex((item) => item.id === author.id);
    inputs1.splice(removeIndex, 1);
    this.setState({
      selectedAuthors: inputs1,
    });
  };

  //contributor
  handleCallbackContributor = (dataAuthor, typeAuthor, control) => {
    var author = { id: dataAuthor.id, name: dataAuthor.name, type: typeAuthor };
    this.setState({
      selectedContributors: [...this.state.selectedContributors, author],
    });
    if (control === "old") {
      const inputs1 = this.state.contributors;
      const removeIndex = inputs1.findIndex(
        (item) => item.id === dataAuthor.id
      );
      inputs1.splice(removeIndex, 1);
      this.setState({
        contributors: inputs1,
      });
    }
  };

  removeContributor = (e, author, type) => {
    e.preventDefault();

    var authorrem = { id: author.id, name: author.name };
    this.setState({
      contributors: [...this.state.contributors, authorrem],
    });

    const inputs1 = this.state.selectedContributors;
    const removeIndex = inputs1.findIndex((item) => item.id === author.id);
    inputs1.splice(removeIndex, 1);
    this.setState({
      selectedContributors: inputs1,
    });
  };

  render() {
    if (this.state.biblio.materialtype) {
      var materialtype_value = {
        value: this.state.biblio.materialtype.id,
        label: this.state.biblio.materialtype.name,
      };
    }
    if (this.state.biblio.language) {
      var language_value = {
        value: this.state.biblio.language.id,
        label: this.state.biblio.language.name,
      };
    }
    if (this.state.biblio.mediatype) {
      var mediatype = {
        value: this.state.biblio.mediatype.id,
        label: this.state.biblio.mediatype.name,
      };
    }

    const media = this.state.media_types.map((media_types) => {
      return { value: media_types.id, label: media_types.name };
    });

    const material = this.state.material_types.map((materials) => {
      return { value: materials.id, label: materials.name };
    });

    const languages = this.state.languages.map((languages) => {
      return { value: languages.id, label: languages.name };
    });

    const publishers = this.state.publishers.map((publishers) => {
      return { value: publishers.id, label: publishers.name };
    });

    return (
      <Fragment>
        <div id="edit-biblioitem" className="small-offset-left">
          <p className="partial-title mb-5">Ndrysho Bibliografi</p>
          <div>
            <div className="fake-row">
              <div className="pjesa">
                <p className="b-item">Pjesa e parë e një bibliografie</p>
              </div>
              <div className="float-right margin-dropdown">
                {this.state.open_biblio === false && (
                  <Fragment>
                    <button
                      className="dropdown-b butoni-hap"
                      onClick={() => this.changeState("biblio", true)}
                    >
                      <img src={expand} alt="icon_expand" />
                    </button>
                  </Fragment>
                )}
                {this.state.open_biblio && (
                  <Fragment>
                    <button
                      className="dropdown-b butoni-hap"
                      onClick={() => this.changeState("biblio", false)}
                    >
                      <img src={collapse} alt="icon_expand" />
                    </button>
                  </Fragment>
                )}
              </div>
              <div className="w-100">
                {this.state.open_biblio && (
                  <EditBiblio
                    materialtypes={material}
                    mediatypes={media}
                    languages={languages}
                    biblio={this.state.biblio}
                    geographical_terms={this.state.geographical_terms}
                    key_words={this.state.key_words}
                    authors={this.state.authors}
                    handleChange={this.onChangeBiblio}
                    handleChangeSelect={this.handleChangeSelectBiblio}
                    errors={this.state.errors}
                    handleKeyword={this.handleKeyword}
                    selectedKeywords={this.state.selectedKeywords}
                    removeKeyword={this.removeKeyword}
                    biblios={this.state.biblios}
                    changeBiblio={this.changeBiblio}
                    emptyState={this.emptyState}
                    materialtype_value={materialtype_value}
                    language_value={language_value}
                    mediatype={mediatype}
                    handleGeoTerm={this.handleGeoTerm}
                    removeGeoTerm={this.removeGeoTerm}
                    selectedGeoTerms={this.state.selectedGeoTerms}
                    biblio_id={this.state.biblio_id}
                    materialType={this.state.materialType}
                    language={this.state.language}
                    mediaType={this.state.mediaType}
                    handleCallbackAuthor={this.handleCallbackAuthor}
                    removeAuthor={this.removeAuthor}
                    selectedAuthors={this.state.selectedAuthors}
                  />
                )}
              </div>
            </div>
            <div className="fake-row">
              <div className="pjesa">
                <p className="b-item">Pjesa e dytë e një bibliografie</p>
              </div>
              <div className="float-right margin-dropdown">
                {this.state.open_biblioitem === false && (
                  <Fragment>
                    <button
                      className="dropdown-b butoni-hap"
                      onClick={() => this.changeState("biblioitem", true)}
                    >
                      <img src={expand} alt="icon_expand" />
                    </button>
                  </Fragment>
                )}
                {this.state.open_biblioitem && (
                  <Fragment>
                    <button
                      className="dropdown-b butoni-hap"
                      onClick={() => this.changeState("biblioitem", false)}
                    >
                      <img src={collapse} alt="icon_expand" />
                    </button>
                  </Fragment>
                )}
              </div>
              <div>
                {this.state.open_biblioitem && (
                  <EditBiblioitem
                    languages={languages}
                    publishers={publishers}
                    biblioitem={this.state.biblioitem}
                    onParentChange={this.onChange}
                    onParentChangeSelect={this.handleChangeSelect}
                    fileSelectHandler={this.fileSelectHandler}
                    cover={this.state.cover}
                    temp={this.state.temporary_coverpath}
                    fileSelectHandlerUrl={this.fileSelectHandlerUrl}
                    url={this.state.url}
                    tempurl={this.state.temporary_urlpath}
                    errors={this.state.errors}
                    deleteCover={this.deleteCover}
                    deleteUrl={this.deleteUrl}
                    delete_url={this.state.delete_url}
                    delete_cover={this.state.delete_cover}
                    publisher={this.state.publisher}
                    biblioitemLanguage={this.state.biblioitemLanguage}
                    handleCallbackContributor={this.handleCallbackContributor}
                    removeContributor={this.removeContributor}
                    selectedContributors={this.state.selectedContributors}
                    contributors={this.state.contributors}
                  />
                )}
              </div>
            </div>
            <div className="fake-row">
              <div className="pjesa">
                <p className="b-item">Pjesa e tretë e një bibliografie</p>
              </div>
              <div className="float-right margin-dropdown">
                {this.state.open_items === false && (
                  <Fragment>
                    <button
                      className="dropdown-b butoni-hap"
                      onClick={() => this.changeState("items", true)}
                    >
                      <img src={expand} alt="icon_expand" />
                    </button>
                  </Fragment>
                )}
                {this.state.open_items && (
                  <Fragment>
                    <button
                      className="dropdown-b butoni-hap"
                      onClick={() => this.changeState("items", false)}
                    >
                      <img src={collapse} alt="icon_expand" />
                    </button>
                  </Fragment>
                )}
              </div>
              <div className="w-100">
                {this.state.open_items && (
                  <EditItems
                    items={this.state.items}
                    handleChange={this.handleChangeItem}
                    handleChangeSelect={this.handleChangeSelectItem}
                    handleChangeDate={this.handleChangeDate}
                    deleteItem={this.deleteItem}
                    newItem={this.newItem}
                    newitems={this.state.newitems}
                    handleChangeSelectNew={this.handleChangeSelectNewItems}
                    handleChangeNewItems={this.handleChangeNewItems}
                    handleChangeDateNewItems={this.handleChangeDateNewItems}
                    removeItem={this.removeItem}
                    errors={this.state.errors}
                  />
                )}
              </div>
            </div>

            <div className="row pb-4 mb-4 mt-3 m-0">
              {this.state.btnLoading === true ? (
                <button name="forward" className="btn-styled-2 px-5 ml-auto">
                  Duke u Ruajtur
                  <img
                    src={LoadingGif}
                    style={{
                      width: "18px",
                      margin: "-4px 0 0 7px",
                    }}
                  />
                </button>
              ) : (
                <button
                  name="forward"
                  className="btn-styled-2 px-5 ml-auto"
                  onClick={this.update}
                >
                  Ruaj
                </button>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(EditMaterial);
