import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Url from "../../../../api/Url";
import Request from "../../../../api/Request";
import Messages from "../../../../helpers/Messages";
import AppConfig from "../../../../services/AppConfig";
import Description from "./Description";
import Isbd from "./Isbd";
import Marc from "./Marc";
import xIcon from "../../../../assets/images/icons/xIcon.svg";
import Accept from "../../../../assets/images/icons/accept.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import Paginate from "../../../../helpers/Paginate";
import SearchFilter from "../../../../helpers/SearchFilter";
import Pagination from "react-js-pagination";
import Web from "../../../../routes/Web";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
import noResult from "../../../../assets/images/icons/no_result.svg";
import libra from "../../../../assets/images/covers/Libra.svg";
import Disertacione from "../../../../assets/images/covers/Disertacione.svg";
import Periodike from "../../../../assets/images/covers/Periodike.svg";
import Harta from "../../../../assets/images/covers/Harta.svg";
import Doreshkrime from "../../../../assets/images/covers/Doreshkrime.svg";
import Dokument from "../../../../assets/images/covers/Dokument.svg";
import Artikuj from "../../../../assets/images/covers/Artikuj.svg";
import Materiale_audio_vizuale from "../../../../assets/images/covers/Materiale_audio-vizuale.svg";
import loadingGif from "../../../../assets/images/icons/loading.gif";

class ShowSerial extends Component {
  constructor() {
    super();
    this.state = {
      showSerial: {
        biblioitem: [],
        biblio: [],
        approvedSeries: [],
        loading: true,
      },
      searchSeries: {
        from: "",
        to: "",
      },
      selectedDate: {
        from: "",
        to: "",
      },
      searched: false,
      formError: [],
      pagination: Paginate.setPaginate(),
      tab: {
        selected: "description",
      },
      btnLoading: false,
    };
  }

  componentDidMount() {
    this.serialShow();
  }

  serialShow = (hasSearch) => {
    const serialId = this.props.match.params.id;
    let request = Url.showSerial + serialId;

    if (hasSearch) {
      hasSearch.preventDefault();
      var searchParams = {
        from: this.state.searchSeries.from,
        to: this.state.searchSeries.to,
      };
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({
        searched: true,
      });
      this.setState({ btnLoading: true });
    }

    Request.getRequest(request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          showSerial: {
            biblioitem: response.data.serial.biblioitem,
            biblio: response.data.serial.biblioitem.biblio,
            approvedSeries: response.data.approvedSeries.data,
            loading: false,
          },
          pagination: Paginate.setPaginateData(response.data.approvedSeries),
        });
      } else if (response.status === 404) {
        this.props.history.push(Web.listSerials);
        Messages.swalError(response.data.error);
      } else if (response.status === 422) {
        this.setState({
          formError: response.data,
        });
      } else {
        this.props.history.push(Web.listSerials);
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  handleChangeD = (key) => (e) => {
    let newForm = {
      ...this.state.searchSeries,
      [key]: moment(new Date(e)).format("DD-MM-yyyy"),
    };
    let newForm1 = {
      ...this.state.selectedDate,
      [key]: e,
    };
    this.setState({
      selectedDate: newForm1,
      searchSeries: newForm,
    });
  };

  descriptionTab = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        selected: "description",
      },
    });
  };

  isbdTab = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        selected: "isbd",
      },
    });
  };

  marcTab = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        selected: "marc",
      },
    });
  };

  handlePageChange = (pageNumber) => {
    const serialId = this.props.match.params.id;
    let request = Url.showSerial + serialId;
    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        from: this.state.searchSeries.from,
        to: this.state.searchSeries.to,
        page: pageNumber,
      };
    }
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        showSerial: {
          biblioitem: response.data.serial.biblioitem,
          biblio: response.data.serial.biblioitem.biblio,
          approvedSeries: response.data.approvedSeries.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.approvedSeries),
      });
    });
    this.setState({ activePage: pageNumber });
  };

  checkCoverSerie = (cover) => {
    if (cover !== null) {
      return AppConfig.serieCoversPath + cover;
    } else {
      return Periodike;
    }
  };

  checkCover = (cover, material_type) => {
    if (cover !== null) {
      return AppConfig.biblioCoversPath + cover;
    }
    if (cover === null) {
      switch (material_type) {
        case 2:
          return libra;
        case 6:
          return Disertacione;
        case 3:
          return Periodike;
        case 5:
          return Harta;
        case 4:
          return Doreshkrime;
        case 8:
          return Dokument;
        case 1:
          return Artikuj;
        case 7:
          return Materiale_audio_vizuale;
        default:
          return "";
      }
    }
  };

  checkBorrownigType = (borrowingType) => {
    switch (borrowingType) {
      case "salle":
        return "Sallë";
      case "normal":
        return "Normal";
      case "ipadisponueshem":
        return "I padisponueshëm";
      default:
        return "";
    }
  };

  render() {
    var approvedSeries = "";
    if (this.state.showSerial.loading === true) {
      approvedSeries = (
        <img className="m-auto d-block" src={loadingGif} alt="loading" />
      );
    } else {
      approvedSeries = this.state.showSerial.approvedSeries.map(
        (serie, index) => {
          var imageSerie = (
            <div className="image-holder">
              <img src={this.checkCoverSerie(serie.cover)} alt="cover" />
            </div>
          );
          var serieItems = serie.items.map((items, i) => {
            return (
              <div key={i} className="row m-0 mb-3">
                <div className="w-100">
                  <div className="serie-copy-title">Kopja {i + 1}</div>
                  <div className="item-attributes-summary">
                    <div className="col-12 col-md-4">
                      <div>Kodifikimi : {items.inventory_number}</div>
                      <div>Numri i inventarit : {items.id}</div>
                      {AppConfig.getRole() === "Administrator" && (
                        <Fragment>
                          <div>Nr.Vendi : {items.place_number}</div>
                          <div>
                            Lloji i huazimit :{" "}
                            {items.borrowing_type
                              ? this.checkBorrownigType(items.borrowing_type)
                              : "-"}
                          </div>
                        </Fragment>
                      )}
                    </div>
                    {AppConfig.getRole() === "Administrator" ? (
                      <Fragment>
                        <div className="col-12 col-md-4">
                          <div>
                            {items.lost === 0 ? (
                              <img src={xIcon} alt="Spastruar" />
                            ) : (
                              <img src={Accept} alt="Ska spastruar" />
                            )}{" "}
                            Spastruar
                          </div>
                          <div>
                            Datë spastrimi:{" "}
                            {items.lost_date !== null
                              ? moment(items.lost_date).format("DD-MM-yyyy")
                              : "-"}
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div>
                            {items.damaged === 0 ? (
                              <img src={xIcon} alt="Jo Demtuar" />
                            ) : (
                              <img src={Accept} alt="Demtuar" />
                            )}{" "}
                            Dëmtuar
                          </div>
                          <div>
                            Datë Dëmtimi:{" "}
                            {items.damaged_date !== null
                              ? moment(items.damaged_date).format("DD-MM-yyyy")
                              : "-"}
                          </div>
                          <div>
                            Datë Rinovimi:{" "}
                            {items.renewal_date !== null
                              ? moment(items.renewal_date).format("DD-MM-yyyy")
                              : "-"}
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <div className="col-12 col-md-4">
                        <div>Nr.Vendi : {items.place_number}</div>
                        <div>
                          Lloji i huazimit :{" "}
                          {items.borrowing_type
                            ? this.checkBorrownigType(items.borrowing_type)
                            : "-"}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="col-12 pr-0 text-right">
                      {items.borrowed === 0 &&
                      items.pending === 0 &&
                      items.reserved === 0 ? (
                        <span className="item-available pl-2 pr-2">E lirë</span>
                      ) : (
                        ""
                      )}

                      {items.borrowed === 1 ? (
                        <span className="item-borrowed pl-2 pr-2">
                          E huazuar
                        </span>
                      ) : (
                        ""
                      )}
                      {items.pending === 1 ? (
                        <span className="item-pending pl-2 pr-2">
                          Në pritje për tu dhënë hua
                        </span>
                      ) : (
                        ""
                      )}
                      {items.reserved === 1 ? (
                        <span className="item-reserved pl-2 pr-2">
                          E rezervuar
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          });
          return (
            <div key={index} className="card no-side-borders">
              <div
                className="items-centered py-2 collapsed"
                data-toggle="collapse"
                data-target={"#nrSerie-" + index}
                aria-expanded="false"
                aria-controls={"nrSerie-" + index}
              >
                <div className="col-12 col-md-4 pl-0" data-label="Patern">
                  {serie.pattern ? serie.pattern : "-"}
                </div>
                <div className="col-12 col-md-4" data-label="Data e botimit">
                  {serie.published_date !== null
                    ? moment(serie.published_date).format("DD-MM-yyyy")
                    : "-"}
                </div>
                <div className="col-12 col-md-4" data-label="Status">
                  {serie.approved === 1 ? "Aprovuar" : "Joaprovuar"}
                </div>
              </div>
              <div
                id={"nrSerie-" + index}
                className="series-div collapse"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <div className="row">
                    {imageSerie}
                    <div className="info-acc">{serieItems}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      );
      var materialType = this.state.showSerial.biblio.materialtype.name;
      var authors = this.state.showSerial.biblio.authors.map((author) => {
        return author.name;
      });
      var Authors = authors.join("; ");
    }
    var data = "";
    var cover = "";
    if (this.state.showSerial.loading === true) {
      data = <img className="ml-5 d-block" src={loadingGif} alt="loading" />;
    } else {
      data = (
        <Fragment>
          <div className="col-12 mb-2 material-type">{materialType}</div>
          <div className="col-12 col-md-10 mb-2 material-title">
            {this.state.showSerial.biblio.title +
              (this.state.showSerial.biblio.subtitle !== null
                ? " : " + this.state.showSerial.biblio.subtitle
                : "")}
          </div>
          <div className="col-12 material-author">{Authors}</div>
        </Fragment>
      );
      cover = (
        <div className="col-12 mb-4 d-block d-md-none image-holder text-center">
          <img
            src={this.checkCover(
              this.state.showSerial.biblioitem.cover,
              this.state.showSerial.biblio.materialtype.id
            )}
            alt="cover"
          />
        </div>
      );
    }
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="biblio-show" className="table-detail-tabs">
            <p className="partial-title mb-5">Shfaq Serial</p>
            <div className="d-flex flex-column ml-md-5 ml-lg-0 flex-md-row align-items-center">
              <div className="col-12 p-0 detailed-biblio-infohead">{data}</div>
            </div>
            <div className="mt-4">
              {cover}
              <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                <li className="nav-item col-12 col-md-4 p-0">
                  <div
                    className="nav-link active"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="description"
                    aria-selected="true"
                    onClick={(e) => this.descriptionTab(e)}
                  >
                    Përshkrimi
                  </div>
                </li>
                <li className="nav-item col-12 col-md-4 p-0">
                  <div
                    className="nav-link"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="isbd"
                    aria-selected="true"
                    onClick={(e) => this.isbdTab(e)}
                  >
                    Isbd
                  </div>
                </li>
                <li className="nav-item col-12 col-md-4 p-0">
                  <div
                    className="nav-link"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="marc"
                    aria-selected="true"
                    onClick={(e) => this.marcTab(e)}
                  >
                    Marc
                  </div>
                </li>
              </ul>
            </div>
            {this.state.tab.selected === "description" && (
              <Description></Description>
            )}
            {this.state.tab.selected === "isbd" && <Isbd></Isbd>}
            {this.state.tab.selected === "marc" && <Marc></Marc>}
          </div>
          <div className="specimen-data mb-5 detailed-table1">
            <div className="py-4 detailed-biblio-infohead h-table">
              <div className="col-12 material-title">Te dhëna mbi seritë</div>
            </div>
            <div className="row mt-5 serie_info">
              <form onSubmit={this.serialShow}>
                <div className="col-12 col-sm-6 col-lg-3 pb-4">
                  <DatePicker
                    placeholderText="Nga data"
                    name="from"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale={sq}
                    dateFormat="dd-MM-yyyy"
                    selected={this.state.selectedDate.from}
                    onChange={this.handleChangeD("from")}
                    autoComplete="off"
                    className={
                      "date_input" +
                      (this.state.formError.from ? " form-error" : "")
                    }
                  />
                  {this.state.formError.from && (
                    <span className="error-message left">
                      {this.state.formError.from[0]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pb-4">
                  <DatePicker
                    placeholderText="Deri në datën"
                    name="to"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale={sq}
                    dateFormat="dd-MM-yyyy"
                    selected={this.state.selectedDate.to}
                    onChange={this.handleChangeD("to")}
                    autoComplete="off"
                    className={
                      "date_input" +
                      (this.state.formError.to ? " form-error" : "")
                    }
                  />
                  {this.state.formError.to && (
                    <span className="error-message left">
                      {this.state.formError.to[0]}
                    </span>
                  )}
                </div>
                {this.state.btnLoading === true ? (
                  <button className="btn-styled-2 px-5 ml-3 mb-4" type="button">
                    Duke Kërkuar
                    <img
                      src={LoadingGif}
                      style={{
                        width: "18px",
                        margin: "-4px 0 0 7px",
                      }}
                    />
                  </button>
                ) : (
                  <button className="btn-styled-2 px-5 ml-3 mb-4" type="submit">
                    Kërko
                  </button>
                )}
              </form>
              <div className="table-acc my-5">
                <div className="table-header-acc">
                  <div className="col-4">Patern</div>
                  <div className="col-4">Data e botimit</div>
                  <div className="col-4">Status</div>
                </div>
                <div id="accordion" className="table-list-acc">
                  {this.state.pagination.totalItemsCount === 0 &&
                    this.state.showSerial.loading === false &&
                    this.state.searched === true && (
                      <img
                        className="mx-auto d-block"
                        src={noResult}
                        alt="no result"
                      />
                    )}

                  {this.state.pagination.totalItemsCount === 0 &&
                    this.state.showSerial.loading === false &&
                    this.state.searched === false && (
                      <p className="nothing--to--show py-3">
                        Asgjë për të shfaqur
                      </p>
                    )}
                  {approvedSeries}
                </div>
                {this.state.pagination.totalPages > 1 && (
                  <div className="d-flex justify-content-center flex-wrap pagination-block mt-5">
                    <div className="select-page">
                      <Pagination
                        activePage={this.state.pagination.activePage}
                        itemsCountPerPage={
                          this.state.pagination.itemsCountPerPage
                        }
                        totalItemsCount={this.state.pagination.totalItemsCount}
                        pageRangeDisplayed={
                          this.state.pagination.pageRangeDisplayed
                        }
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                    <div className="pagination-results">
                      Faqja:
                      {this.state.pagination.activePage} -{" "}
                      {this.state.pagination.totalPages}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ShowSerial);
