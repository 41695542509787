import React, { Component, Fragment } from 'react';
import unielLogo from '../../../assets/images/uniel-logo-footer.svg';
import instagram from '../../../assets/images/icons/instagram-white.svg';
import youtube from '../../../assets/images/icons/youtube-white.svg';
import facebook from '../../../assets/images/icons/facebook-white.svg';
import { Link } from 'react-router-dom';
import Web from '../../../routes/Web';
import LogoWhite from '../../../assets/images/logo/uniel-logo-white.svg';

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row col-12 m-0">
              <div className="col-12 logo-uniel mb-3">
                <img className="float-left" src={LogoWhite} />
              </div>
            </div>
            <div className="row col-12 m-0">
              <div className="col-12 col-sm-6 col-md-4 first-column">
                <ul>
                  <li>Adresa: Rruga "Ismail Zyma"</li>
                  <li>Elbasan, Shqipëri</li>
                  <li>Tel: +355 54 252593</li>
                  <li>E-mail: info@uniel.edu.al</li>
                </ul>
              </div>
              <div className="col-12 col-sm-6 col-md-4 second-column">
                <ul className="mr-5">
                  <li>
                    <Link to={Web.university}>Universiteti</Link>
                  </li>
                  <li>
                    <Link to={Web.about}>Rreth Bibliotekës</Link>
                  </li>
                  <li>
                    <Link to={Web.privacyPolicies}>Rregullore</Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to={Web.news}>Lajme</Link>
                  </li>
                  <li>
                    <Link to={Web.help}>Ndihmë</Link>
                  </li>
                  <li>
                    <Link to={Web.contact}>Kontakt</Link>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-4 last-column">
                <div className="social-media-icons pb-2">
                  <a
                    href="https://www.instagram.com/university_of_elbasan/"
                    className="mr-4"
                    target="_blank"
                  >
                    <img className="instagram-icon" src={instagram} />
                  </a>
                  <a
                    href="https://www.facebook.com/pages/category/College---University/Universiteti-Aleksander-Xhuvani-Elbasan-Faqja-Zyrtare-764665406996189/"
                    className="mr-4"
                    target="_blank"
                  >
                    <img className="youtube-icon" src={youtube} />
                  </a>
                  <a href="#">
                    <img className="facebook-icon" src={facebook} />
                  </a>
                </div>

                <div className="copyright">
                  <div>
                    &copy; Biblioteka e Universitetit të Elbasanit "Aleksandër
                    Xhuvani" 2021
                  </div>
                  <div>
                    Designed and Developed by{' '}
                    <a href="https://abp.al/en/" target="_blank">
                      abp.al
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </Fragment>
    );
  }
}

export default Footer;
