import React, { Component, Fragment } from "react";
import Paginate from "../../../../helpers/Paginate";
import Request from "../../../../api/Request";
import Url from "../../../../api/Url";
import Web from "../../../../routes/Web";
import Messages from "../../../../helpers/Messages";
import AppConfig from "../../../../services/AppConfig";
import SearchFilter from "../../../../helpers/SearchFilter";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import TooltipComp from "../../../../helpers/Tooltip";
import { Link } from "react-router-dom";
import libra from "../../../../assets/images/covers/Libra.svg";
import Disertacione from "../../../../assets/images/covers/Disertacione.svg";
import Periodike from "../../../../assets/images/covers/Periodike.svg";
import Harta from "../../../../assets/images/covers/Harta.svg";
import Doreshkrime from "../../../../assets/images/covers/Doreshkrime.svg";
import Dokument from "../../../../assets/images/covers/Dokument.svg";
import Artikuj from "../../../../assets/images/covers/Artikuj.svg";
import Materiale_audio_vizuale from "../../../../assets/images/covers/Materiale_audio-vizuale.svg";
import Pagination from "react-js-pagination";
import moment from "moment";

class ListReservations extends Component {
  constructor() {
    super();
    this.state = {
      reservationList: {
        reservations: [],
        loading: true,
      },
      pagination: Paginate.setPaginate(),
    };
  }

  componentDidMount() {
    this.reservationList();
  }

  reservationList = () => {
    let request = Url.listReservationsOpac;

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          reservationList: {
            reservations: response.data.reservations.data,
            loading: false,
          },
          pagination: Paginate.setPaginateData(response.data.reservations),
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  checkCover = (cover, material_type) => {
    if (cover !== null) {
      return AppConfig.biblioCoversPath + cover;
    } else if (cover === null) {
      switch (material_type) {
        case 2:
          return libra;
        case 6:
          return Disertacione;
        case 3:
          return Periodike;
        case 5:
          return Harta;
        case 4:
          return Doreshkrime;
        case 8:
          return Dokument;
        case 1:
          return Artikuj;
        case 7:
          return Materiale_audio_vizuale;
        default:
          return "";
      }
    }
  };

  handlePageChange = (pageNumber) => {
    let request = Url.listReservationsOpac;

    var searchParams = {
      page: pageNumber,
    };

    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        reservationList: {
          reservations: response.data.reservations.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.reservations),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    var reservationList = "";
    if (this.state.reservationList.loading) {
      reservationList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      reservationList = this.state.reservationList.reservations.map(
        (reservation, index) => {
          const Authors = reservation.item.biblioitem.biblio.authors.map(
            (author) => {
              return author.name;
            }
          );
          return (
            <tr role="row">
              <td
                role="cell"
                className="pb-3 pl-0 smaller-sc-title keep-visible"
                style={{
                  textAlign: "left!important",
                  paddingLeft: 0 + "px!important",
                }}
              >
                <div className="row bordered-tr bordered">
                  <div className="col-lg-12">
                    <div className="kopertina">
                      <div className="d-inline-block kopertina1 image-vertical-aligment">
                        <img
                          src={this.checkCover(
                            reservation.item.biblioitem.cover,
                            reservation.item.biblioitem.biblio.materialtype.id
                          )}
                          alt="cover"
                        />
                      </div>
                      <div className="info-material d-inline-block">
                        <div
                          className="tooltip-title title-link title-t"
                          style={{ "max-width": 100 + "%" }}
                        >
                          <Link
                            to={Web.detailed + reservation.item.biblioitem.id}
                          >
                            {reservation.item.biblioitem.biblio.title}
                          </Link>
                        </div>
                        <p className="autor mb-0">{Authors.join("; ")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td
                role="cell"
                data-label="Numri i inventarit"
                className="text-center "
              >
                {reservation.item.id}
              </td>
              <td
                role="cell"
                data-label="Datë rezervimi"
                className="text-center "
              >
                {reservation.created_at &&
                  moment(reservation.created_at).format("DD-MM-yyyy")}
              </td>
            </tr>
          );
        }
      );
    }

    return (
      <Fragment>
        <div className="profile-bg-table">
          <div className="table-list1">
            <table role="table" className="w-100">
              <thead role="rowgroup">
                <tr role="row">
                  <th
                    width="60%"
                    role="columnheader"
                    className="text-left pl-0 colored-th"
                  >
                    Titull/ Autor
                  </th>
                  <th
                    width="20%"
                    role="columnheader"
                    className="text-center colored-th"
                  >
                    Numri i inventarit
                  </th>
                  <th
                    width="20%"
                    role="columnheader"
                    className="text-center colored-th"
                  >
                    Datë Rezervimi
                  </th>
                </tr>
              </thead>

              <tbody role="rowgroup">{reservationList}</tbody>
            </table>
            {this.state.pagination.totalItemsCount === 0 &&
              this.state.reservationList.loading === false && (
                <p className="nothing--to--show">Asgjë për të shfaqur</p>
              )}
          </div>
          {this.state.pagination.totalPages > 1 ? (
            <div className="container-fluid pt-5 pb-4">
              <div className="d-flex justify-content-center pagination-block">
                {this.state.pagination.totalPages > 1 && (
                  <div className="d-flex justify-content-center pagination-block mb-5">
                    <div className="select-page">
                      <Pagination
                        activePage={this.state.pagination.activePage}
                        itemsCountPerPage={
                          this.state.pagination.itemsCountPerPage
                        }
                        totalItemsCount={this.state.pagination.totalItemsCount}
                        pageRangeDisplayed={
                          this.state.pagination.pageRangeDisplayed
                        }
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                    <div className="pagination-results">
                      Faqja: {this.state.pagination.activePage} -{" "}
                      {this.state.pagination.totalPages}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Fragment>
    );
  }
}
export default ListReservations;
