import React, { Component, Fragment } from "react";
import Select from "react-select";
import "../../../../assets/css/report-bibliography.css";
import AppConfig from "../../../../services/AppConfig";
import Bibliography from "./Bibliography";
import DamagedLost from "./DamagedLost";

class ReportBibliography extends Component {
  constructor() {
    super();
    this.state = {
      tab: {
        details: "bibliography",
      },
    };
  }

  damagedOrLost = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        details: "damaged",
      },
    });
  };

  bibliography = (e) => {
    e.preventDefault();
    this.setState({
      tab: {
        details: "bibliography",
      },
    });
  };

  render() {
    return (
      <Fragment>
        <div id="report-catalog" className="table-detail-tabs">
          <div className="container-fluid small-offset-left">
            <div id="list-of-members">
              <p className="partial-title mb-5">Katalogim</p>
              <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                <li
                  className={
                    AppConfig.getRole() === "Administrator"
                      ? "nav-item col-12 col-md-6 p-0"
                      : "nav-item col-12 p-0"
                  }
                >
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="bibliografi"
                    aria-selected="true"
                    href="#"
                    onClick={(e) => this.bibliography(e)}
                  >
                    Bibliografi
                  </a>
                </li>
                {AppConfig.getRole() === "Administrator" && (
                  <li className="nav-item col-12 col-md-6  p-0">
                    <a
                      className="nav-link"
                      href="#"
                      data-toggle="tab"
                      role="tab"
                      id="lost-damaged"
                      aria-controls="demtuar"
                      aria-selected="false"
                      onClick={(e) => this.damagedOrLost(e)}
                    >
                      Dëmtuar/ Spastruar
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {this.state.tab.details === "bibliography" && (
          <Bibliography></Bibliography>
        )}
        {this.state.tab.details === "damaged" &&
          AppConfig.getRole() === "Administrator" && <DamagedLost />}
      </Fragment>
    );
  }
}

export default ReportBibliography;
