import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/bootstrap.min.css";
import "./assets/css/fonts.css";
import "./assets/css/style1.css";
import "./assets/css/style2.css";
import AppRouter from "./routes/AppRouter";

const App = () => {
  return (
    <div className="wrapper">
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </div>
  );
};

export default App;
