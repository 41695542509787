import React, { Component, Fragment } from "react";
import Url from "../../../api/Url";
import { withRouter } from "react-router-dom";
import Request from "../../../api/Request";
import Messages from "../../../helpers/Messages";
import AppConfig from "../../../services/AppConfig";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import LoadingGif from "../../../assets/images/icons/loading.gif";

class EditMember extends Component {
  constructor() {
    super();
    this.state = {
      edit: {
        role_id: "",
        role_name: "",
        email: "",
        first_name: "",
        last_name: "",
        dob: "",
        gender: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        postal_code: "",
        membership_start: "",
        membership_end: "",
      },
      roles: {
        list: [],
      },
      changeMembershipEnd: {
        membership_end: "",
      },
      membership_status: "",
      form_errors: {
        error_list: [],
      },
      form_errors_membershipstatus: {
        error_list: [],
      },
      membership_end: "",
      selectedDob: {
        dob: "",
      },
      btnLoading: false,
      btnChangeLoading: false,
      loading: true,
      PageLoading: true,
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    Request.getRequest(Url.userEdit + id).then((response) => {
      if (response.status === 200) {
        var dob = null;
        if (response.data.user.dob !== null) {
          dob = new Date(response.data.user.dob);
        }
        console.log(response.data);
        this.setState({
          edit: {
            role_id: response.data.user.role_id,
            role_name: response.data.user.role.name,
            first_name: response.data.user.first_name,
            last_name: response.data.user.last_name,
            email: response.data.user.email,
            nid: response.data.user.nid,
            year: response.data.user.year,
            faculty: response.data.user.faculty,
            department: response.data.user.department,
            program: response.data.user.program,
            edulevel: response.data.user.edulevel,
            fullorparttime: response.data.user.fullorparttime,
            dob: response.data.user.dob,
            gender: response.data.user.gender,
            phone: response.data.user.phone,
            address: response.data.user.address,
            city: response.data.user.city,
            state: response.data.user.state,
            postal_code: response.data.user.postal_code,
            membership_start: response.data.user.membership_start,
            membership_end: response.data.months,
          },
          roles: {
            list: response.data.roles,
          },
          membership_status: response.data.membership_status,
          selectedDob: {
            dob: dob,
          },
          loading: false,
          PageLoading: false,
        });
      } else if (response.status === 404) {
        this.props.history.goBack();
        // this.props.history.push(Web.listAcquisition);
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  }

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    var selectLabel;
    if (e !== null) {
      selectValue = e.value;
      selectLabel = e.label;
    } else {
      selectValue = "";
      selectLabel = "";
    }
    let newForm = {
      ...this.state.edit,
      [key]: selectValue,
      role_name: selectLabel,
    };
    this.setState({
      edit: newForm,
    });
  };

  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.edit,
      [key]: e.target.value,
    };
    this.setState({
      edit: newForm,
    });
  };

  handleChangeMembership = (key) => (e) => {
    this.setState({
      changeMembershipEnd: {
        membership_end: e.target.value,
      },
    });
  };

  handleChangeD = (key) => (e) => {
    var newForm;
    if (e === null) {
      newForm = {
        ...this.state.edit,
        [key]: null,
      };
    } else {
      newForm = {
        ...this.state.edit,
        [key]: moment(e).format("DD-MM-yyyy"),
      };
    }

    let newForm1 = {
      ...this.state.selectedDob,
      [key]: e,
    };
    this.setState({
      selectedDob: newForm1,
      edit: newForm,
    });
  };

  update = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const id = this.props.match.params.id;
    Request.patchRequest(Url.updateUser + id, this.state.edit).then(
      (response) => {
        this.setState({ btnLoading: false });
        if (response.status === 200) {
          this.props.history.goBack();
          Messages.swalSuccess(response.data.success);
        } else if (response.status === 404) {
          this.props.history.goBack();
          Messages.swalError(response.data.error);
        } else {
          this.setState({
            form_errors: {
              error_list: response.data,
            },
          });
        }
      }
    );
  };

  changeMembership = (e) => {
    e.preventDefault();
    this.setState({ btnChangeLoading: true });
    const id = this.props.match.params.id;

    let formData = {
      id: id,
      membership_end: this.state.changeMembershipEnd.membership_end,
    };

    Request.postRequest(Url.changeMembership, formData).then((response) => {
      this.setState({ btnChangeLoading: false });
      if (response.status === 200) {
        this.props.history.goBack();
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 404) {
        this.props.history.goBack();
        Messages.swalError(response.data.error);
      } else if (response.status === 422) {
        this.setState({
          form_errors_membershipstatus: {
            error_list: response.data,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  render() {
    var roles = "";
    if (!this.state.loading) {
      roles = this.state.roles.list.map((item) => {
        return { value: item.id, label: item.name };
      });
    }

    var PageLoading = "";

    if (this.state.PageLoading === false) {
      PageLoading = (
        <Fragment>
          <div className="container-fluid small-offset-left">
            <div className="invite-member">
              <p className="partial-title mb-5">Ndrysho të dhënat</p>

              <form onSubmit={this.update}>
                <input type="hidden" name="id" value="4" />
                <div className="row">
                  <div className="col-12 col-md-4 flex-column col-form">
                    <div className="pb-4 mb-4 position-relative">
                      <label htmlFor="first_name">Emri:</label>
                      <input
                        placeholder="Shto Emrin"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.first_name
                            ? " form-error"
                            : "")
                        }
                        name="first_name"
                        type="text"
                        value={this.state.edit.first_name}
                        id="first_name"
                        onChange={this.handleChange("first_name")}
                      />
                      {this.state.form_errors.error_list.first_name && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.first_name[0]}
                        </span>
                      )}
                    </div>
                    <div className="pb-4 mb-4">
                      <label htmlFor="last_name">Mbiemri:</label>
                      <input
                        placeholder="Shto Mbiemrin"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.last_name
                            ? " form-error"
                            : "")
                        }
                        name="last_name"
                        type="text"
                        value={this.state.edit.last_name}
                        id="last_name"
                        onChange={this.handleChange("last_name")}
                      />
                      {this.state.form_errors.error_list.last_name && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.last_name[0]}
                        </span>
                      )}
                    </div>
                    <div className="pb-4 mb-4">
                      <label htmlFor="email">Email:</label>
                      <input
                        placeholder="Email"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.email
                            ? " form-error"
                            : "")
                        }
                        name="email"
                        type="text"
                        value={this.state.edit.email}
                        readOnly
                        id="email"
                      />
                      {this.state.form_errors.error_list.email && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.email[0]}
                        </span>
                      )}
                    </div>
                    <div className="pb-4 mb-4">
                      <label htmlFor="dob">Ditëlindje:</label>
                      <DatePicker
                        placeholderText="Zgjidh datën"
                        name="dob"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={this.state.selectedDob.dob}
                        onChange={this.handleChangeD("dob")}
                        autoComplete="off"
                        className={
                          "date_input" +
                          (this.state.form_errors.error_list.dob
                            ? " form-error"
                            : "")
                        }
                      />
                      {this.state.form_errors.error_list.dob && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.dob[0]}
                        </span>
                      )}
                    </div>
                    <div className="pb-4 mb-4">
                      <label>Numri Identifikimit</label>
                      <input
                        type="text"
                        name="nid"
                        placeholder="NID"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.nid
                            ? " form-error"
                            : "")
                        }
                        value={this.state.edit.nid ? this.state.edit.nid : ""}
                        onChange={this.handleChange("nid")}
                      />
                      {this.state.form_errors.error_list.nid && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.nid[0]}
                        </span>
                      )}
                    </div>
                    <div className="pb-4 mb-4">
                      <label htmlFor="role_id">Roli:</label>
                      <Select
                        className={
                          "basic-select" +
                          (this.state.form_errors.error_list.role_id
                            ? " form-error"
                            : "")
                        }
                        classNamePrefix="select_input"
                        name="role_id"
                        isClearable={true}
                        isSearchable={false}
                        placeholder="Përzgjidh"
                        value={[
                          {
                            value: this.state.edit.role_id,
                            label: this.state.edit.role_name,
                          },
                        ]}
                        options={roles}
                        onChange={this.handleChangeSelect("role_id")}
                      />
                      {this.state.form_errors.error_list.role_id && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.role_id[0]}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-4 flex-column col-form">
                    {this.state.edit.membership_start !== null && (
                      <Fragment>
                        <div className="pb-4 mb-4">
                          <label htmlFor="membership_start">
                            Data e fillimit:
                          </label>
                          <DatePicker
                            placeholderText="Zgjidh datën"
                            name="from"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            locale={sq}
                            dateFormat="DD-MM-yyyy"
                            value={this.state.edit.membership_start}
                            readOnly
                            autoComplete="off"
                            className="date_input"
                          />
                        </div>
                        <div className="pb-4 mb-4">
                          <label htmlFor="membership_end-button">
                            Data e mbarimit:
                          </label>
                          <input
                            placeholder="Kohezgjatja"
                            className="input-form-input"
                            name="email"
                            type="text"
                            value={this.state.edit.membership_end + " Muaj"}
                            readOnly
                            id="email"
                          />
                        </div>
                      </Fragment>
                    )}

                    <div className="pb-4 mb-4">
                      <label htmlFor="state">Shteti:</label>
                      <input
                        placeholder="Shto Shtetin"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.state
                            ? " form-error"
                            : "")
                        }
                        name="state"
                        type="text"
                        value={this.state.edit.state}
                        id="state"
                        onChange={this.handleChange("state")}
                      />

                      {this.state.form_errors.error_list.state && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.state[0]}
                        </span>
                      )}
                    </div>

                    <div className="pb-4 mb-4">
                      <label htmlFor="city">Qyteti:</label>
                      <input
                        placeholder="Shto Qytetin"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.city
                            ? " form-error"
                            : "")
                        }
                        name="city"
                        type="text"
                        value={this.state.edit.city}
                        id="city"
                        onChange={this.handleChange("city")}
                      />
                      {this.state.form_errors.error_list.city && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.city[0]}
                        </span>
                      )}
                    </div>

                    <div className="pb-4 mb-4">
                      <label htmlFor="address">Adresa:</label>
                      <input
                        placeholder="Shto Adresën"
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.address
                            ? " form-error"
                            : "")
                        }
                        name="address"
                        type="text"
                        value={this.state.edit.address}
                        id="address"
                        onChange={this.handleChange("address")}
                      />
                      {this.state.form_errors.error_list.address && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.address[0]}
                        </span>
                      )}
                    </div>
                    {this.state.edit.membership_start === null && (
                      <Fragment>
                        <div className="pb-4 mb-4">
                          <label htmlFor="postal_code">Kodi Postar:</label>
                          <input
                            placeholder="Shto Kodin Postar"
                            className={
                              "input-form-input" +
                              (this.state.form_errors.error_list.postal_code
                                ? " form-error"
                                : "")
                            }
                            name="postal_code"
                            type="number"
                            id="postal_code"
                            value={this.state.edit.postal_code}
                            onChange={this.handleChange("postal_code")}
                          />
                          {this.state.form_errors.error_list.postal_code && (
                            <span className="error-message">
                              {this.state.form_errors.error_list.postal_code[0]}
                            </span>
                          )}
                        </div>
                        <div className="pb-4 mb-4">
                          <label htmlFor="phone">Telefon:</label>
                          <input
                            id="phone"
                            placeholder="Shto numrin e telefonit"
                            className={
                              "input-form-input" +
                              (this.state.form_errors.error_list.phone
                                ? " form-error"
                                : "")
                            }
                            name="phone"
                            type="text"
                            value={this.state.edit.phone}
                            onChange={this.handleChange("phone")}
                          />
                          {this.state.form_errors.error_list.phone && (
                            <span className="error-message">
                              {this.state.form_errors.error_list.phone[0]}
                            </span>
                          )}
                        </div>
                      </Fragment>
                    )}

                    {this.state.edit.role_id === 4 && (
                      <div className="pb-4 mb-4">
                        <label>Departamenti</label>
                        <input
                          type="text"
                          name="department"
                          placeholder="Departamenti"
                          className={
                            "input-form-input" +
                            (this.state.form_errors.error_list.department
                              ? " form-error"
                              : "")
                          }
                          value={this.state.edit.department}
                          onChange={this.handleChange("department")}
                        />
                        {this.state.form_errors.error_list.department && (
                          <span className="error-message">
                            {this.state.form_errors.error_list.department[0]}
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col-12 col-md-4 d-flex flex-column col-form">
                    {this.state.edit.membership_start !== null && (
                      <Fragment>
                        <div className="pb-4 mb-4">
                          <label htmlFor="postal_code">Kodi Postar:</label>
                          <input
                            placeholder="Shto Kodin Postar"
                            className={
                              "input-form-input" +
                              (this.state.form_errors.error_list.postal_code
                                ? " form-error"
                                : "")
                            }
                            name="postal_code"
                            type="number"
                            id="postal_code"
                            value={this.state.edit.postal_code}
                            onChange={this.handleChange("postal_code")}
                          />
                          {this.state.form_errors.error_list.postal_code && (
                            <span className="error-message">
                              {this.state.form_errors.error_list.postal_code[0]}
                            </span>
                          )}
                        </div>
                        <div className="pb-4 mb-4">
                          <label htmlFor="phone">Telefon:</label>
                          <input
                            id="phone"
                            placeholder="Shto numrin e telefonit"
                            className={
                              "input-form-input" +
                              (this.state.form_errors.error_list.phone
                                ? " form-error"
                                : "")
                            }
                            name="phone"
                            type="text"
                            value={this.state.edit.phone}
                            onChange={this.handleChange("phone")}
                          />
                          {this.state.form_errors.error_list.phone && (
                            <span className="error-message">
                              {this.state.form_errors.error_list.phone[0]}
                            </span>
                          )}
                        </div>
                      </Fragment>
                    )}

                    {this.state.edit.role_id === 4 && (
                      <Fragment>
                        <div className="pb-4 mb-4">
                          <label>Viti</label>
                          <input
                            type="number"
                            name="year"
                            placeholder="Viti"
                            className={
                              "input-form-input" +
                              (this.state.form_errors.error_list.year
                                ? " form-error"
                                : "")
                            }
                            value={this.state.edit.year}
                            onChange={this.handleChange("year")}
                          />
                          {this.state.form_errors.error_list.year && (
                            <span className="error-message">
                              {this.state.form_errors.error_list.year[0]}
                            </span>
                          )}
                        </div>
                        <div className="pb-4 mb-4">
                          <label>Fakulteti</label>
                          <input
                            type="text"
                            name="faculty"
                            placeholder="Fakulteti"
                            className={
                              "input-form-input" +
                              (this.state.form_errors.error_list.faculty
                                ? " form-error"
                                : "")
                            }
                            value={this.state.edit.faculty}
                            onChange={this.handleChange("faculty")}
                          />
                          {this.state.form_errors.error_list.nid && (
                            <span className="error-message">
                              {this.state.form_errors.error_list.faculty[0]}
                            </span>
                          )}
                        </div>
                      </Fragment>
                    )}

                    {this.state.edit.role_id === 4 && (
                      <Fragment>
                        <div className="pb-4 mb-4">
                          <label>Programi</label>
                          <input
                            type="text"
                            name="program"
                            placeholder="Programi"
                            className={
                              "input-form-input" +
                              (this.state.form_errors.error_list.program
                                ? " form-error"
                                : "")
                            }
                            value={this.state.edit.program}
                            onChange={this.handleChange("program")}
                          />
                          {this.state.form_errors.error_list.program && (
                            <span className="error-message">
                              {this.state.form_errors.error_list.program[0]}
                            </span>
                          )}
                        </div>
                        <div className="pb-4 mb-4">
                          <label>Niveli i edukimit</label>
                          <input
                            type="text"
                            name="edulevel"
                            placeholder="Niveli i edukimit"
                            className={
                              "input-form-input" +
                              (this.state.form_errors.error_list.edulevel
                                ? " form-error"
                                : "")
                            }
                            value={this.state.edit.edulevel}
                            onChange={this.handleChange("edulevel")}
                          />
                          {this.state.form_errors.error_list.edulevel && (
                            <span className="error-message">
                              {this.state.form_errors.error_list.edulevel[0]}
                            </span>
                          )}
                        </div>
                        <div className="d-block mb-5">
                          <label>Koha e studimit</label>
                          <div className="radio-buttons d-flex">
                            <div className="radio-button mr-3">
                              <label htmlFor="fulltime">Me kohë të plotë</label>
                              <input
                                className={"form-check-input"}
                                id="fulltime"
                                name="fullorparttime"
                                type="radio"
                                value="fulltime"
                                checked={
                                  this.state.edit.fullorparttime ===
                                    "fulltime" ||
                                  this.state.edit.fullorparttime ===
                                    "Kohe te plote"
                                }
                                onChange={this.handleChange("fullorparttime")}
                              />
                            </div>
                            <div className="radio-button">
                              <label htmlFor="parttime">
                                Me kohë të pjesshme
                              </label>
                              <input
                                className="form-check-input"
                                id="parttime"
                                name="fullorparttime"
                                type="radio"
                                value="parttime"
                                checked={
                                  this.state.edit.fullorparttime ===
                                    "parttime" ||
                                  this.state.edit.fullorparttime ===
                                    "Kohe te pjesshme"
                                }
                                onChange={this.handleChange("fullorparttime")}
                              />
                            </div>
                            {this.state.form_errors.error_list
                              .fullorparttime && (
                              <span className="error-message">
                                {
                                  this.state.form_errors.error_list
                                    .fullorparttime[0]
                                }
                              </span>
                            )}
                          </div>
                        </div>
                      </Fragment>
                    )}
                    <div className="d-block mt-lg-3 mb-4">
                      <label htmlFor="gender" className="main-label">
                        Gjinia:
                      </label>

                      <div className="radio-buttons d-flex">
                        <div className="radio-button mr-2">
                          <label htmlFor="mashkull">Mashkull</label>
                          <input
                            className="form-check-input"
                            id="mashkull"
                            name="gender"
                            type="radio"
                            value="M"
                            checked={this.state.edit.gender === "M"}
                            onChange={this.handleChange("gender")}
                          />
                        </div>
                        <div className="radio-button ml-3">
                          <label htmlFor="femer">Femër</label>
                          <input
                            className="form-check-input"
                            id="femer"
                            name="gender"
                            type="radio"
                            value="F"
                            checked={this.state.edit.gender === "F"}
                            onChange={this.handleChange("gender")}
                          />
                        </div>
                        {this.state.form_errors.error_list.gender && (
                          <span className="error-message">
                            {this.state.form_errors.error_list.gender[0]}
                          </span>
                        )}
                      </div>
                    </div>
                    {this.state.btnLoading === true ? (
                      <button className="btn-styled-2 px-5 mt-auto mb-4">
                        Duke u Ndryshuar
                        <img
                          src={LoadingGif}
                          style={{
                            width: "18px",
                            margin: "-4px 0 0 7px",
                          }}
                          alt="loading"
                        />
                      </button>
                    ) : (
                      <button className="btn-styled-2 px-5 mt-auto mb-4">
                        Ndrysho
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>

            {/* shtyrja e antaresimit */}
            <br />
            <br />

            {this.state.edit.membership_start !== null && (
              <Fragment>
                <div
                  style={{ borderTop: 2 + "px solid #6bb9fc" }}
                  className="invite-member"
                >
                  <p className="partial-title mb-4 mt-4 pt-4">
                    Shty anëtarësimin
                  </p>

                  <div className="row">
                    <form onSubmit={this.changeMembership}>
                      {this.state.membership_status === 1 && (
                        <span className="ml-3">
                          Afati i anëtarësimit të këtij përdoruesi ka mbaruar!
                        </span>
                      )}
                      <div className="col-12 col-form d-flex align-items-end mb-5 mt-3">
                        <div className="d-block mb-4">
                          <label
                            htmlFor="membership_end-button"
                            className="main-label mb-3"
                          >
                            Data e mbarimit:
                          </label>

                          <div className="radio-buttons d-flex">
                            <div className="radio-button mr-4">
                              <label htmlFor="3_muaj">3 muaj</label>
                              <input
                                className="form-check-input"
                                id="3_muaj"
                                name="membership_end"
                                type="radio"
                                onChange={this.handleChangeMembership(
                                  "membership_end"
                                )}
                                value="3"
                              />
                            </div>
                            <div className="radio-button mr-4 ml-4">
                              <label htmlFor="6_muaj">6 muaj</label>
                              <input
                                className="form-check-input"
                                id="6_muaj"
                                name="membership_end"
                                type="radio"
                                onChange={this.handleChangeMembership(
                                  "membership_end"
                                )}
                                value="6"
                              />
                            </div>
                            <div className="radio-button mr-4 ml-4">
                              <label htmlFor="9_muaj">9 muaj</label>
                              <input
                                className="form-check-input"
                                id="9_muaj"
                                name="membership_end"
                                type="radio"
                                onChange={this.handleChangeMembership(
                                  "membership_end"
                                )}
                                value="9"
                              />
                            </div>
                            <div className="radio-button mr-4 ml-4">
                              <label htmlFor="12_muaj">12 muaj</label>
                              <input
                                className="form-check-input"
                                id="12_muaj"
                                name="membership_end"
                                type="radio"
                                onChange={this.handleChangeMembership(
                                  "membership_end"
                                )}
                                value="12"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="ml-5 mb-4">
                          {this.state.btnChangeLoading === true ? (
                            <button
                              type="button"
                              className="btn-styled-2 mt-0 mr-3"
                            >
                              Duke u Aprovuar
                              <img
                                src={LoadingGif}
                                style={{
                                  width: "18px",
                                  margin: "-4px 0 0 7px",
                                }}
                                alt="loading"
                              />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn-styled-2 mt-0 mr-3"
                            >
                              Shty anëtarësimin
                            </button>
                          )}
                        </div>
                        {this.state.form_errors_membershipstatus.error_list
                          .membership_end && (
                          <span className="error-message left">
                            {
                              this.state.form_errors_membershipstatus.error_list
                                .membership_end[0]
                            }
                          </span>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </Fragment>
      );
    }
    return PageLoading;
  }
}

export default withRouter(EditMember);
