import React, { Component, Fragment } from "react";
import Select from "react-select";
import Url from "../../../api/Url";
import SearchFilter from "../../../helpers/SearchFilter";
import Request from "../../../api/Request";
import Messages from "../../../helpers/Messages";
import Pagination from "react-js-pagination";
import Paginate from "../../../helpers/Paginate";
import editIcon from "../../../assets/images/icons/edit_icon.svg";
import deleteIcon from "../../../assets/images/icons/delete_icon.svg";
import loadingGif from "../../../assets/images/icons/loading.gif";
import noResult from "../../../assets/images/icons/no_result.svg";
import eyeIcon from "../../../assets/images/icons/syri.svg";
import AppConfig from "../../../services/AppConfig";
import { Link } from "react-router-dom";
import Web from "../../../routes/Web";
import LoadingGif from "../../../assets/images/icons/loading.gif";
class MembersList extends Component {
  constructor() {
    super();
    this.state = {
      memberRoles: {
        roles: [],
        loading: true,
      },
      listMembers: {
        members: [],
        loading: true,
      },
      searchMember: {
        search_text: "",
        role: "",
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
      searched: false,
      pagination: Paginate.setPaginate(),
    };
  }
  componentDidMount() {
    this.listMembers();
  }

  listMembers = (hasSearch) => {
    let request = Url.listMembers;

    const searchParams = {
      search_text: this.state.searchMember.search_text,
      role: this.state.searchMember.role,
    };

    if (hasSearch) {
      hasSearch.preventDefault();
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({
        searched: true,
      });
      this.setState({ btnLoading: true });
    }

    Request.getRequest(request).then((response) => {
      this.setState({
        btnLoading: false,
      });
      if (response.status === 200) {
        this.memberRoles();
        this.setState({
          listMembers: {
            members: response.data.members.data,
            loading: false,
          },
          form_errors: {
            error_list: [],
          },
          pagination: Paginate.setPaginateData(response.data.members),
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          searched: false,
        });
      } else {
        this.setState({
          searched: false,
        });
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  memberRoles = () => {
    Request.getRequest(Url.memberRoles).then((response) => {
      if (response.status === 200) {
        this.setState({
          memberRoles: {
            roles: response.data.memberRoles,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  searchHandler = (key) => (e) => {
    let newForm = {
      ...this.state.searchMember,
      [key]: e.target.value,
    };
    this.setState({
      searchMember: newForm,
    });
  };

  searchHandlerS = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.searchMember,
      [key]: selectValue,
    };
    this.setState({
      searchMember: newForm,
    });
  };

  deleteMember = (e, id, name) => {
    Messages.swalConfirmDelete(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(Url.deleteMember + id).then((response) => {
          if (response.status === 200) {
            this.listMembers();
            Messages.swalSuccess(response.data.success);
          } else if (response.status === 404) {
            Messages.swalError(response.data.error);
          } else {
            Messages.swalError(AppConfig.serverError);
          }
        });
      }
    });
  };

  handlePageChange = (pageNumber) => {
    let request = Url.listMembers;

    var searchParams = {};

    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        search_text: this.state.searchMember.search_text,
        role: this.state.searchMember.role,
        page: pageNumber,
      };
    }

    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listMembers: {
          members: response.data.members.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.members),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    if (!this.state.memberRoles.loading) {
      var roles = this.state.memberRoles.roles.map((role) => {
        return { value: role.id, label: role.name };
      });
    }

    var membersList = "";
    if (this.state.listMembers.loading) {
      membersList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      membersList = this.state.listMembers.members.map((membersList) => {
        return (
          <tr key={membersList.id}>
            <td role="cell" data-label="Emër">
              {membersList.first_name}
            </td>
            <td role="cell" data-label="Mbiemër" className="text-center">
              {membersList.last_name}
            </td>
            <td role="cell" data-label="Email" className="text-center">
              {membersList.email}
            </td>
            <td role="cell" data-label="Roli" className="text-center">
              {membersList.role_id === 4 ? "Student" : ""}
              {membersList.role_id === 5 ? "Staf akademik" : ""}
              {membersList.role_id === 6 ? "Personalitet" : ""}
            </td>
            <td role="cell" data-label="Statusi" className="text-center">
              {membersList.token !== null ? "Jo Konfirmuar" : "Konfirmuar"}
            </td>
            <td role="cell" data-label="Fshi" className="text-center">
              <div className="row justify-content-around mr-auto ml-auto action-icons">
                <div className="col-3 show">
                  <Link to={Web.showMember + membersList.id}>
                    <img src={eyeIcon} />
                  </Link>
                </div>
                <div className="col-3 edit">
                  <Link to={Web.editMember + membersList.id}>
                    <button className="btn-unstyled">
                      <img src={editIcon} />
                    </button>
                  </Link>
                </div>
                <div className="col-3 delete">
                  <button
                    type="submit"
                    className="btn-unstyled"
                    onClick={(e) =>
                      this.deleteMember(
                        e,
                        membersList.id,
                        membersList.first_name + " " + membersList.last_name
                      )
                    }
                  >
                    <img src={deleteIcon} />
                  </button>
                </div>
              </div>
            </td>
          </tr>
        );
      });
    }

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="list-of-members">
            <p className="partial-title mb-5">Lista e anëtarëve</p>
            <div className="input-items">
              <form onSubmit={this.listMembers}>
                <div className="row mb-5">
                  <div className="col-12 col-sm-6 col-md-4 pb-4">
                    <input
                      placeholder="Kërko për emër ose email "
                      className="input-form-input"
                      name="search_text"
                      type="text"
                      onChange={this.searchHandler("search_text")}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 pb-4">
                    <Select
                      className={
                        "basic-select" +
                        (this.state.form_errors.error_list.role
                          ? " form-error"
                          : "")
                      }
                      isClearable={true}
                      classNamePrefix="select_input"
                      name="borrowing_type"
                      placeholder="Përzgjidh"
                      onChange={this.searchHandlerS("role")}
                      isSearchable={false}
                      options={roles}
                    />
                    {this.state.form_errors.error_list.role && (
                      <span className="error-message left">
                        {this.state.form_errors.error_list.role}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-sm-6 col-md-4">
                    {this.state.btnLoading === true ? (
                      <button type="button" className="btn-styled-2 ml-0">
                        Duke Kërkuar
                        <img
                          src={LoadingGif}
                          style={{
                            width: "18px",
                            margin: "-4px 0 0 7px",
                          }}
                        />
                      </button>
                    ) : (
                      <button type="submit" className="btn-styled-2 ml-0">
                        Kërko
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>

            <div className="table-list mt-5 mb-5">
              <table role="table" className="w-100">
                <thead role="rowgroup">
                  <tr role="row">
                    <th role="columnheader" className="text-left">
                      Emër
                    </th>
                    <th role="columnheader" className="text-center">
                      Mbiemër
                    </th>
                    <th role="columnheader" className="text-center">
                      Email
                    </th>
                    <th role="columnheader" className="text-center">
                      Roli
                    </th>
                    <th role="columnheader" className="text-center">
                      Statusi
                    </th>
                    <th role="columnheader" className="text-center">
                      Veprime
                    </th>
                  </tr>
                </thead>
                <tbody role="rowgroup">{membersList}</tbody>
              </table>
              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listMembers.loading === false &&
                this.state.searched === true && (
                  <img
                    className="mx-auto d-block"
                    src={noResult}
                    alt="no result"
                  />
                )}

              {this.state.pagination.totalItemsCount === 0 &&
                this.state.listMembers.loading === false &&
                this.state.searched === false && (
                  <p className="nothing--to--show">Asgjë për të shfaqur</p>
                )}
            </div>
          </div>
          <div className="container-fluid pt-5 pb-4">
            <div className="d-flex justify-content-center pagination-block">
              {this.state.pagination.totalPages > 1 && (
                <div className="d-flex justify-content-center pagination-block mb-5">
                  <div className="select-page">
                    <Pagination
                      activePage={this.state.pagination.activePage}
                      itemsCountPerPage={
                        this.state.pagination.itemsCountPerPage
                      }
                      totalItemsCount={this.state.pagination.totalItemsCount}
                      pageRangeDisplayed={
                        this.state.pagination.pageRangeDisplayed
                      }
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                  <div className="pagination-results">
                    Faqja: {this.state.pagination.activePage} -{" "}
                    {this.state.pagination.totalPages}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MembersList;
