import React, { Component, Fragment } from "react";
import Url from "../../../api/Url";
import Request from "../../../api/Request";
import Paginate from "../../../helpers/Paginate";
import Pagination from "react-js-pagination";
import Web from "../../../routes/Web";
import SearchFilter from "../../../helpers/SearchFilter";
import Messages from "../../../helpers/Messages";
import Select from "react-select";
import dateFormat from "dateformat";
import { Link, withRouter } from "react-router-dom";
import loadingGif from "../../../assets/images/icons/loading.gif";
import editIcon from "../../../assets/images/icons/edit_icon.svg";
import deleteIcon from "../../../assets/images/icons/delete_icon.svg";
import aproveIcon from "../../../assets/images/icons/icon-accept.svg";
import cancelIcon from "../../../assets/images/icons/icon-reject.svg";
import noResult from "../../../assets/images/icons/no_result.svg";
import AppConfig from "../../../services/AppConfig";
import TooltipComp from "../../../helpers/Tooltip";
import LoadingGif from "../../../assets/images/icons/loading.gif";

class ListAcquisitions extends Component {
  constructor() {
    super();
    this.state = {
      listAcuisitions: {
        acquisitions: [],
        loading: true,
      },
      searchAcuisitions: {
        search_text: "",
        type: "",
        status: "",
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
      pagination: Paginate.setPaginate(),
      searched: false,
    };
  }

  componentDidMount() {
    this.listAcquisitions();
  }

  listAcquisitions = (hasSearch) => {
    let request = Url.listAcquisitions;

    const searchParams = {
      search_text: this.state.searchAcuisitions.search_text,
      type: this.state.searchAcuisitions.type,
      status: this.state.searchAcuisitions.status,
    };

    if (hasSearch) {
      hasSearch.preventDefault();
      request = SearchFilter.setFilterParams(searchParams, request);
      this.setState({
        searched: true,
      });
      this.setState({ btnLoading: true });
    }

    const acquisitions = Request.getRequest(request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          listAcuisitions: {
            acquisitions: response.data.acquisitions.data,
            loading: false,
          },
          form_errors: {
            error_list: [],
          },
          pagination: Paginate.setPaginateData(response.data.acquisitions),
        });
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
          searched: false,
        });
      } else {
        this.setState({
          searched: false,
        });
        Messages.swalError(AppConfig.serverError);
      }
    });

    return acquisitions;
  };

  handleChangeInput = (key) => (e) => {
    let newForm = {
      ...this.state.searchAcuisitions,
      [key]: e.target.value,
    };
    this.setState({
      searchAcuisitions: newForm,
    });
  };

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.searchAcuisitions,
      [key]: selectValue,
    };
    this.setState({
      searchAcuisitions: newForm,
    });
  };

  deleteAcquision = (e, id, name) => {
    Messages.swalConfirmDelete(name).then((result) => {
      if (result.isConfirmed) {
        Request.deleteRequest(Url.deleteAcquisition + id).then((response) => {
          if (response.status === 200) {
            this.listAcquisitions();
            Messages.swalSuccess(response.data.success);
          } else if (response.status === 404) {
            Messages.swalError(response.data.error);
          }
        });
      }
    });
  };

  approveAcquision = (e, id) => {
    e.preventDefault();
    const data = { id: id };
    Request.postRequest(Url.approveAcquisition, data).then((response) => {
      if (response.status === 200) {
        this.props.history.push({
          pathname: Web.createBiblio,
          title: response.data.acquisition_title,
        });
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 404) {
        Messages.swalError(response.data.error);
      }
    });
  };

  cancelAcquision = (e, id) => {
    e.preventDefault();
    const data = { id: id };
    Request.postRequest(Url.cancelAcquisition, data).then((response) => {
      if (response.status === 200) {
        this.listAcquisitions();
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 404) {
        Messages.swalError(response.data.error);
      }
    });
  };

  handlePageChange = (pageNumber) => {
    let request = Url.listAcquisitions;
    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        search_text: this.state.searchAcuisitions.search_text,
        type: this.state.searchAcuisitions.type,
        status: this.state.searchAcuisitions.status,
        page: pageNumber,
      };
    }

    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((result) => {
      this.setState({
        listAcuisitions: {
          acquisitions: result.data.acquisitions.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(result.data.acquisitions),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    var acquisitions = "";
    if (this.state.listAcuisitions.loading) {
      acquisitions = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      acquisitions = this.state.listAcuisitions.acquisitions.map(
        (acquisitions, index) => {
          return (
            <tr key={index}>
              <td data-label="Titulli">
                <Link to={Web.showAcquisition + acquisitions.id}>
                  {TooltipComp.tooltipDisabledMobile(
                    "title" + index,
                    acquisitions.title
                  )}
                </Link>
              </td>
              <td data-label="Autor" className=" text-center ">
                {acquisitions.author}
              </td>
              <td data-label="Kopje" className=" text-center ">
                {acquisitions.copies}
              </td>
              <td
                data-label="Dt. kur pritet të vijnë"
                className=" text-center to-right"
              >
                {acquisitions.expected_on !== null &&
                  dateFormat(acquisitions.expected_on, "d-mm-yyyy")}
              </td>
              <td className=" text-center" data-label="Veprime">
                <div className="row justify-content-around mr-auto ml-auto action-icons">
                  <div
                    className="col-3 aprovo acq-tooltip"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Aprovo"
                  >
                    {acquisitions.status !== "Aprovuar" &&
                      acquisitions.status !== "Anulluar" && (
                        <button
                          type="submit"
                          className="btn-unstyled"
                          onClick={(e) =>
                            this.approveAcquision(e, acquisitions.id)
                          }
                        >
                          <img src={aproveIcon} />
                          {this.state.listAcuisitions.status}
                        </button>
                      )}
                  </div>
                  <div
                    className="col-3 anullo acq-tooltip"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Anullo"
                  >
                    {acquisitions.status !== "Anulluar" &&
                      acquisitions.status !== "Aprovuar" && (
                        <button
                          type="submit"
                          className="btn-unstyled"
                          onClick={(e) =>
                            this.cancelAcquision(e, acquisitions.id)
                          }
                        >
                          <img src={cancelIcon} />
                        </button>
                      )}
                  </div>
                  <div className="col-3 edit">
                    <Link to={Web.editAcquisition + acquisitions.id}>
                      <button className="btn-unstyled">
                        <img src={editIcon} />
                      </button>
                    </Link>
                  </div>
                  <div className="col-3 delete">
                    <button
                      type="submit"
                      className="btn-unstyled"
                      onClick={(e) =>
                        this.deleteAcquision(
                          e,
                          acquisitions.id,
                          acquisitions.title
                        )
                      }
                    >
                      <img src={deleteIcon} alt="fshi" />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          );
        }
      );
    }
    return (
      <Fragment>
        <div className="container-fluid small-offset-left w-100">
          <p className="partial-title  mb-5">Listë Pasurimi</p>
          <div className="input-items">
            <form onSubmit={this.listAcquisitions} id="request">
              <div className="row mb-5">
                <div className="col-12 col-sm-6 col-md-3 pb-4">
                  <input
                    placeholder="Kërko"
                    className={
                      "input-form-input" +
                      (this.state.form_errors.error_list.search_text
                        ? " form-error"
                        : "")
                    }
                    name="search_text"
                    type="text"
                    onChange={this.handleChangeInput("search_text")}
                  />
                  {this.state.form_errors.error_list.search_text && (
                    <span className="error-message left">
                      {this.state.form_errors.error_list.search_text[0]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-3 pb-4">
                  <Select
                    className={
                      "basic-select" +
                      (this.state.form_errors.error_list.type
                        ? " form-error"
                        : "")
                    }
                    classNamePrefix="select_input"
                    name="type"
                    isClearable={true}
                    onChange={this.handleChangeSelect("type")}
                    isSearchable={false}
                    placeholder="Përzgjidh"
                    options={[
                      { value: "title", label: "Titull" },
                      { value: "author", label: "Autor" },
                    ]}
                  />

                  {this.state.form_errors.error_list.type && (
                    <span className="error-message left">
                      {this.state.form_errors.error_list.type[0]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-3 pb-4">
                  <Select
                    className="basic-select"
                    classNamePrefix="select_input"
                    name="status"
                    isClearable={true}
                    onChange={this.handleChangeSelect("status")}
                    isSearchable={false}
                    placeholder="Përzgjidh"
                    options={[
                      { value: "Regjistruar", label: "Regjistruar" },
                      { value: "Aprovuar", label: "Aprovuar" },
                      { value: "Anulluar", label: "Anulluar" },
                    ]}
                  />
                  {this.state.form_errors.error_list.status && (
                    <span className="error-message left">
                      {this.state.form_errors.error_list.status[0]}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-3 pb-4">
                  {this.state.btnLoading === true ? (
                    <button className="btn-styled-2">
                      Duke Kërkuar
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button className="btn-styled-2">Kërko</button>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="table-list mb-5">
            <table role="table" className="w-100">
              <thead role="rowgroup">
                <tr role="row">
                  <th
                    role="columnheader"
                    width="20%"
                    className="pr-3 text-left"
                  >
                    Titulli
                  </th>
                  <th
                    role="columnheader"
                    width="20%"
                    className="pr-3 text-center"
                  >
                    Autor
                  </th>
                  <th
                    role="columnheader"
                    width="20%"
                    className="pr-3 text-center"
                  >
                    Nr. kopjesh
                  </th>
                  <th
                    role="columnheader"
                    width="20%"
                    className="pr-3 text-center"
                  >
                    Dt. kur pritet të vijnë
                  </th>
                  <th
                    role="columnheader"
                    width="20%"
                    className="pr-3 text-center"
                  >
                    Veprime
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">{acquisitions}</tbody>
            </table>
            {this.state.pagination.totalItemsCount === 0 &&
              this.state.listAcuisitions.loading === false &&
              this.state.searched === true && (
                <img
                  className="mx-auto d-block"
                  src={noResult}
                  alt="no result"
                />
              )}

            {this.state.pagination.totalItemsCount === 0 &&
              this.state.listAcuisitions.loading === false &&
              this.state.searched === false && (
                <p className="nothing--to--show">Asgjë për të shfaqur</p>
              )}
          </div>
          {this.state.pagination.totalPages > 1 && (
            <div className="d-flex justify-content-center flex-wrap pagination-block mb-5">
              <div className="select-page">
                <Pagination
                  activePage={this.state.pagination.activePage}
                  itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                  totalItemsCount={this.state.pagination.totalItemsCount}
                  pageRangeDisplayed={this.state.pagination.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="pagination-results">
                {`Faqja: ${this.state.pagination.activePage} - ${this.state.pagination.totalPages}`}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ListAcquisitions);
