import { Fragment } from "react";
import ReactTooltip from "react-tooltip";

class TooltipComp {
  static tooltipDisabledMobile = (index, title) => {
    let screenWidth = window.innerWidth;
    let titleLength = title.length;

    if (screenWidth < 768) {
      return (
        <div
          className={"tooltip-text pl-0 d-inline-block title-tooltip-" + index}
          id={"tooltip" + index}
        >
          {title}
        </div>
      );
    } else if (screenWidth > 767 && titleLength >= 18) {
      return (
        <div
          className={"tooltip-text pl-0 d-inline-block title-tooltip-" + index}
          id={"tooltip" + index}
          data-tip
          data-for={"toolTip" + index}
        >
          {title.slice(0, 15) + "..."}
          <ReactTooltip id={"toolTip" + index} place="top" effect="solid">
            {title}
          </ReactTooltip>
        </div>
      );
    } else if (screenWidth > 767 && titleLength < 18) {
      return (
        <div
          className={"tooltip-text pl-0 d-inline-block title-tooltip-" + index}
        >
          {title}
        </div>
      );
    }
  };

  static tooltip = (index, title, text) => {
    return (
      <div id={"tooltip" + index} data-tip data-for={"toolTip" + index}>
        {title}
        <ReactTooltip id={"toolTip" + index} place="top" effect="solid">
          {text}
        </ReactTooltip>
      </div>
    );
  };

  static disabledTooltipBtn = (id, title, text) => {
    return (
      <Fragment>
        <a className="btn-styled-2" data-tip data-for={id} disabled="disabled">
          {title}
        </a>
        <ReactTooltip id={id} place="top" effect="solid">
          {text}
        </ReactTooltip>
      </Fragment>
    );
  };
}

export default TooltipComp;
