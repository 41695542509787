class AppConfig {
  static serverError = 'Dicka shkoi keq! Ju lutemi provoni më vonë.';

  static setAccessToken = (access_token) => {
    localStorage.setItem('access_token', access_token);
  };

  static deleteAccessToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('role');
  };

  static setRole = (role) => {
    localStorage.setItem('role', role);
  };

  static getRole = () => {
    return localStorage.getItem('role');
  };

  static baseUrl = process.env.REACT_APP_BASE_URL;
  static reactUrl = process.env.REACT_APP_URL;

  //Storage path
  static blogPath = this.baseUrl + 'storage/blog/images/';
  static marcPath = this.baseUrl + 'storage/marc/';
  static biblioCoversPath = this.baseUrl + 'storage/covers/';
  static serieCoversPath = this.baseUrl + 'storage/seriecovers/';
  static bookCovers = this.baseUrl + 'storage/images/covers/';

  // API Services
  static apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  static apiKey = process.env.REACT_APP_BIBLIOTEKA_API_KEY;
  static apiAccessToken = localStorage.getItem('access_token');

  static apiConfigParams = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${this.apiAccessToken}`,
        'api-key': this.apiKey,
        'content-type': 'application/json',
      },
    };
    return config;
  };

  static apiConfigParamsLogin = () => {
    const config = {
      headers: {
        'api-key': this.apiKey,
        'content-type': 'application/json',
      },
    };
    return config;
  };

  static apiConfigParamsUrlencoded = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${this.apiAccessToken}`,
        'api-key': this.apiKey,
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    };
    return config;
  };

  static apiConfigParamsForDownload = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${this.apiAccessToken}`,
        'api-key': this.apiKey,
        responseType: 'blob',
      },
    };
    return config;
  };

  static apiConfigParamsForDownloadExcel = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${this.apiAccessToken}`,
        'api-key': this.apiKey,
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    };
    return config;
  };

  static apiConfigParamsForExport = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${this.apiAccessToken}`,
        'api-key': this.apiKey,
        'Content-Type': 'multipart/form-data',
      },
    };
    return config;
  };

  static isLogged = () => {
    if (localStorage.getItem('access_token')) {
      return true;
    } else {
      return false;
    }
  };
}

export default AppConfig;
