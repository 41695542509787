import React, { Component, Fragment } from "react";
import Request from "../../api/Request";
import Url from "../../api/Url";
import { withRouter } from "react-router-dom";
import Web from "../../routes/Web";
import Messages from "../../helpers/Messages";
import LoadingGif from "../../assets/images/icons/loading.gif";
import { FiLock } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";

class ActivateAccount extends Component {
  constructor() {
    super();
    this.state = {
      setPassword: {
        id: "",
        password: "",
        password_confirmation: "",
      },
      password: false,
      confirmPassword: false,
      userData: {
        firstName: "",
        lastName: "",
        email: "",
      },
      error_list: [],
      btnLoading: false,
    };
  }

  componentDidMount() {
    Request.getRequest(
      Url.activateAccount + this.props.match.params.param
    ).then((result) => {
      if (result.status === 200) {
        this.setState({
          ...this.state,
          setPassword: {
            ...this.state.setPassword,
            id: result.data.user.id,
          },
          userData: {
            firstName: result.data.user.first_name,
            lastName: result.data.user.last_name,
            email: result.data.user.email,
          },
        });
      } else {
        this.props.history.push(Web.login);
      }
    });
  }

  onInputchange = (e) => {
    this.setState({
      setPassword: {
        ...this.state.setPassword,
        [e.target.name]: e.target.value,
      },
    });
  };

  showPassword(e, param) {
    if (param === "password") {
      if (this.state.password === false) {
        this.setState({
          ...this.state,
          password: true,
        });
      } else if (this.state.password === true) {
        this.setState({
          ...this.state,
          password: false,
        });
      }
    } else if (param === "confirmPassword") {
      if (this.state.confirmPassword === false) {
        this.setState({
          ...this.state,
          confirmPassword: true,
        });
      } else if (this.state.confirmPassword === true) {
        this.setState({
          ...this.state,
          confirmPassword: false,
        });
      }
    }
  }

  setPassword = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    Request.postRequest(Url.setPasswordAccount, this.state.setPassword).then(
      (result) => {
        this.setState({ btnLoading: false });
        if (result.status === 200) {
          this.setState({
            setPassword: {
              password: "",
              password_confirmation: "",
            },
            userData: {
              firstName: "",
              lastName: "",
              email: "",
            },
            error_list: [],
          });
          this.props.history.push(Web.login);
          Messages.swalSuccess(result.data.success);
        } else if (result.status === 422) {
          this.setState({
            ...this.state,
            error_list: result.data,
          });
        } else if (result.status === 404) {
          this.setState({
            error_list: result.data,
          });
        }
      }
    );
  };

  render() {
    return (
      <Fragment>
        <div className="form-logins-b">
          <div className="login-b-r l-staf col-12 col-sm-9 col-md-10 col-xl-8">
            <div className="col-12 col-sm-12 col-lg-12 col-xl-10 mx-auto">
              <div className="initials-user">
                <span className="initials-span">
                  {this.state.userData.firstName.charAt(0) +
                    this.state.userData.lastName.charAt(0)}
                </span>
              </div>
              <div className="name">
                {`${this.state.userData.firstName} ${this.state.userData.lastName}`}
              </div>
              <div className="email">{`${this.state.userData.email}`}</div>
            </div>
            <form
              className="col-12 col-sm-12 col-lg-12 col-xl-10 mx-auto"
              onSubmit={this.setPassword}
            >
              <label>Fjalëkalimi</label>
              <div className="form-group position-relative pb-4 mb-0">
                <input
                  type={this.state.password === false ? "password" : "text"}
                  name="password"
                  placeholder="Vendosni fjalëkalimin"
                  value={this.state.setPassword.password}
                  className={
                    "input-form-input-log-b" +
                    (this.state.error_list.password ? " form-error" : "")
                  }
                  id="email"
                  onChange={this.onInputchange}
                />
                <FiLock className="email-icon-b" />
                <div onClick={(e) => this.showPassword(e, "password")}>
                  {this.state.password === false ? (
                    <AiOutlineEyeInvisible className="show-icon-b" />
                  ) : (
                    <AiOutlineEye className="show-icon-b" />
                  )}
                </div>
                {this.state.error_list.password && (
                  <span className="error-message">
                    {this.state.error_list.password[0]}
                  </span>
                )}
              </div>
              <label>Konfirmo Fjalëkalimin</label>
              <div className="form-group position-relative pb-4 mb-0">
                <input
                  type={
                    this.state.confirmPassword === false ? "password" : "text"
                  }
                  name="password_confirmation"
                  placeholder="Konfirmo fjalëkalimin"
                  value={this.state.setPassword.password_confirmation}
                  className={"input-form-input-log-b"}
                  id="email"
                  onChange={this.onInputchange}
                />
                <FiLock className="email-icon-b" />
                <div onClick={(e) => this.showPassword(e, "confirmPassword")}>
                  {this.state.confirmPassword === false ? (
                    <AiOutlineEyeInvisible className="show-icon-b" />
                  ) : (
                    <AiOutlineEye className="show-icon-b" />
                  )}
                </div>
              </div>
              <div className="form-group">
                {this.state.btnLoading === true ? (
                  <button type="button" className="login_btn mt-2">
                    Duke u Aktivizuar
                    <img
                      src={LoadingGif}
                      style={{
                        width: "18px",
                        margin: "-4px 0 0 7px",
                      }}
                    />
                  </button>
                ) : (
                  <button type="submit" className="login_btn mt-2">
                    Aktivizo
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ActivateAccount);
