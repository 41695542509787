import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import AuthorModal from "../AuthorModal";
import GeographicTermsModal from "../GeographicTermsModal";
import KeywordModal from "../KeywordModal";
import Select from "react-select";
import Downshift from "downshift";
import Url from "../../../../../api/Url";
import Request from "../../../../../api/Request";
import Messages from "../../../../../helpers/Messages";
import AppConfig from "../../../../../services/AppConfig";
import right from "../../../../../assets/images/icons/arrow_right.svg";
import left from "../../../../../assets/images/icons/arrow_left.svg";
import Web from "../../../../../routes/Web";
import LoadingGif from "../../../../../assets/images/icons/loading.gif";

class EditBiblio extends Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    const biblioitem = this.props.match.params.biblioitem_id;

    var url = Url.editBiblio + id;
    if (biblioitem !== null) {
      url = url + "/" + biblioitem;
    }
    Request.getRequest(url).then((response) => {
      if (response.status === 200) {
        this.setState({
          geographical_terms: response.data.geographical_terms,
          key_words: response.data.key_words,
          media_types: response.data.media_types,
          publishers: response.data.publishers,
          languages: response.data.languages,
          authors: response.data.authors,
          biblioitem_id: biblioitem,
          material_types: response.data.material_types,
          biblio: response.data.biblio,
          biblios: response.data.biblios,
          selectedKeywords: response.data.biblio.keywords,
          selectedGeoTerms: response.data.biblio.geographicalterms,
          biblioorig: response.data.biblio,
          selectedAuthors: [],
        });

        this.state.biblio.authors.map((author) => {
          const selected = {
            id: author.id,
            name: author.name,
            type: author.pivot.type,
          };

          this.setState({
            selectedAuthors: [...this.state.selectedAuthors, selected],
          });
        });

        const authors = this.state.authors;
        this.state.selectedAuthors.map((author) => {
          const removeIndex = authors.findIndex(
            (item) => item.id === author.id
          );
          authors.splice(removeIndex, 1);
        });

        const inputs1 = this.state.key_words;
        this.state.selectedKeywords.map((keyword) => {
          const removeIndex = inputs1.findIndex(
            (item) => item.id === keyword.id
          );
          inputs1.splice(removeIndex, 1);
        });
        const geoterms = this.state.geographical_terms;
        this.state.selectedGeoTerms.map((geot) => {
          const removeIndex = geoterms.findIndex((item) => item.id === geot.id);
          geoterms.splice(removeIndex, 1);
        });
        this.setState({
          key_words: inputs1,
          geographical_terms: geoterms,
        });

        if (
          response.data.biblio.materialtype !== null ||
          response.data.biblio.materialtype
        ) {
          this.setState({
            materialType: {
              value: response.data.biblio.materialtype.id,
              label: response.data.biblio.materialtype.name,
            },
          });
        }

        if (
          response.data.biblio.language ||
          response.data.biblio.language !== null
        ) {
          this.setState({
            language: {
              value: response.data.biblio.language.id,
              label: response.data.biblio.language.name,
            },
          });
        }
        if (
          response.data.biblio.mediatype ||
          response.data.biblio.mediatype !== null
        ) {
          this.setState({
            mediaType: {
              value: response.data.biblio.mediatype.id,
              label: response.data.biblio.mediatype.name,
            },
          });
        }
      } else if (response.status === 404) {
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  }

  constructor(state) {
    super(state);
    this.state = {
      biblio: [],
      biblios: [],
      geographical_terms: [],
      key_words: [],
      media_types: [],
      languages: [],
      authors: [],
      material_types: [],
      errors: [],
      selectedKeywords: [],
      biblio_id: "",
      selectedGeoTerms: [],
      biblioitem_id: "",
      biblioorig: [],
      selectedAuthors: [],
      addAuthorPopup: false,
      addKeywordPopup: false,
      addGeoPopup: false,
      materialType: {
        value: "",
        label: "",
      },
      language: {
        value: "",
        label: "",
      },
      mediaType: {
        value: "",
        label: "",
      },
      author_types: [
        { value: "paresor", label: "Parësor" },
        { value: "dytesor", label: "Dytësor" },
        { value: "tretesor", label: "Tretësor" },
        { value: "autor", label: "Autor" },
      ],
      isOpenDownshift: false,
      btnLoading: false,
    };
  }

  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.biblio,
      [key]: e.target.value,
    };
    this.setState({
      biblio: newForm,
    });
  };

  handleChangeSelect = (key, state) => (e) => {
    var selectValue;
    var selectLabel;
    if (e !== null) {
      selectValue = e.value;
      selectLabel = e.label;
    } else {
      selectValue = "";
      selectLabel = "";
    }
    let newForm = {
      ...this.state.biblio,
      [key]: selectValue,
    };
    let newForm1 = {
      ...this.state[state],
      value: selectValue,
      label: selectLabel,
    };
    this.setState({
      biblio: newForm,
      [state]: newForm1,
    });
  };

  addAuthorPopup = () => {
    this.setState({
      addAuthorPopup: true,
    });
  };

  closeAddAuthorPopup = (status) => {
    this.setState({ addAuthorPopup: status });
  };

  addKeywordPopup = () => {
    this.setState({
      addKeywordPopup: true,
    });
  };

  closeAddKeywordPopup = (status) => {
    this.setState({ addKeywordPopup: status });
  };

  addGeoPopup = () => {
    this.setState({
      addGeoPopup: true,
    });
  };

  closeAddGeoPopup = (status) => {
    this.setState({ addGeoPopup: status });
  };

  downshiftOnChange = (selectedBiblio) => {
    let request = Url.selectedBiblio + selectedBiblio.id;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.changeBiblio(response.data.biblio);
        this.setState({
          materialType: {
            value: "",
            label: "",
          },
          language: {
            value: "",
            label: "",
          },
          mediaType: {
            value: "",
            label: "",
          },
        });
        if (
          response.data.biblio.materialtype !== null ||
          response.data.biblio.materialtype
        ) {
          this.setState({
            materialType: {
              value: response.data.biblio.materialtype.id,
              label: response.data.biblio.materialtype.name,
            },
          });
        }

        if (
          response.data.biblio.language ||
          response.data.biblio.language !== null
        ) {
          this.setState({
            language: {
              value: response.data.biblio.language.id,
              label: response.data.biblio.language.name,
            },
          });
        }
        if (
          response.data.biblio.mediatype ||
          response.data.biblio.mediatype !== null
        ) {
          this.setState({
            mediaType: {
              value: response.data.biblio.mediatype.id,
              label: response.data.biblio.mediatype.name,
            },
          });
        }
      }
    });
  };

  // kewywords
  handleKeyword = (dataKeyword, control) => {
    this.setState({
      selectedKeywords: [...this.state.selectedKeywords, dataKeyword],
    });
    if (control === "old") {
      const inputs1 = this.state.key_words;
      const removeIndex = inputs1.findIndex(
        (item) => item.id === dataKeyword.id
      );
      inputs1.splice(removeIndex, 1);
      this.setState({
        key_words: inputs1,
      });
    }
  };

  removeKeyword = (e, keyword) => {
    e.preventDefault();

    this.setState({
      key_words: [...this.state.key_words, keyword],
    });

    const inputs1 = this.state.selectedKeywords;
    const removeIndex = inputs1.findIndex((item) => item.id === keyword.id);
    inputs1.splice(removeIndex, 1);
    this.setState({
      selectedKeywords: inputs1,
    });
  };

  //geoterms
  handleGeoTerm = (dataGeoTerm, control) => {
    this.setState({
      selectedGeoTerms: [...this.state.selectedGeoTerms, dataGeoTerm],
    });
    if (control === "old") {
      const inputs1 = this.state.geographical_terms;
      const removeIndex = inputs1.findIndex(
        (item) => item.id === dataGeoTerm.id
      );
      inputs1.splice(removeIndex, 1);
      this.setState({
        geographical_terms: inputs1,
      });
    }
  };

  removeGeoTerm = (e, geoterm) => {
    e.preventDefault();

    this.setState({
      geographical_terms: [...this.state.geographical_terms, geoterm],
    });

    const inputs1 = this.state.selectedGeoTerms;
    const removeIndex = inputs1.findIndex((item) => item.id === geoterm.id);
    inputs1.splice(removeIndex, 1);
    this.setState({
      selectedGeoTerms: inputs1,
    });
  };

  //author
  handleCallbackAuthor = (dataAuthor, typeAuthor, control) => {
    var author = { id: dataAuthor.id, name: dataAuthor.name, type: typeAuthor };
    this.setState({
      selectedAuthors: [...this.state.selectedAuthors, author],
    });
    if (control === "old") {
      const inputs1 = this.state.authors;
      const removeIndex = inputs1.findIndex(
        (item) => item.id === dataAuthor.id
      );
      inputs1.splice(removeIndex, 1);
      this.setState({
        authors: inputs1,
      });
    }
  };

  removeAuthor = (e, author, type) => {
    e.preventDefault();

    var authorrem = { id: author.id, name: author.name };
    this.setState({
      authors: [...this.state.authors, authorrem],
    });

    const inputs1 = this.state.selectedAuthors;
    const removeIndex = inputs1.findIndex((item) => item.id === author.id);
    inputs1.splice(removeIndex, 1);
    this.setState({
      selectedAuthors: inputs1,
    });
  };

  //change biblio
  changeBiblio = (biblio) => {
    this.state.selectedKeywords.map((keyword) => {
      this.setState({
        key_words: [...this.state.key_words, keyword],
      });
    });
    this.state.selectedGeoTerms.map((geo) => {
      this.setState({
        geographical_terms: [...this.state.geographical_terms, geo],
      });
    });

    this.state.selectedAuthors.map((author) => {
      this.setState({
        authors: [...this.state.authors, author],
      });
    });

    this.setState({
      biblio: biblio,
      biblio_id: biblio.id,
      selectedKeywords: biblio.keywords,
      selectedGeoTerms: biblio.geographicalterms,
      selectedAuthors: [],
    });

    biblio.authors.map((author) => {
      var selected = {
        id: author.id,
        name: author.name,
        type: author.pivot.type,
      };
      this.setState({
        selectedAuthors: [...this.state.selectedAuthors, selected],
      });
    });

    const authors = this.state.authors;
    this.state.selectedAuthors.map((author) => {
      const removeIndex = authors.findIndex((item) => item.id === author.id);
      authors.splice(removeIndex, 1);
    });

    const inputs1 = this.state.key_words;
    this.state.selectedKeywords.map((keyword) => {
      const removeIndex = inputs1.findIndex((item) => item.id === keyword.id);
      inputs1.splice(removeIndex, 1);
    });

    const geoterms = this.state.geographical_terms;

    this.state.selectedGeoTerms.map((geot) => {
      const removeIndex = geoterms.findIndex((item) => item.id === geot.id);
      geoterms.splice(removeIndex, 1);
    });

    this.setState({
      key_words: inputs1,
      geographical_terms: geoterms,
      authors: authors,
      errors: [],
    });
  };

  emptyState = () => {
    this.setState({
      materialType: {
        value: "",
        label: "",
      },
      language: {
        value: "",
        label: "",
      },
      mediaType: {
        value: "",
        label: "",
      },
    });
    this.setState({
      biblio: this.state.biblioorig,
      biblio_id: "",
    });
    this.componentDidMount();
  };

  //update

  update = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const id = this.props.match.params.id;
    const biblioitem = this.props.match.params.biblioitem_id;

    var keywords = [],
      geoterm = [];

    this.state.selectedKeywords.map((keyword, index) => {
      keywords[index] = keyword.id;
    });
    this.state.selectedGeoTerms.map((geot, index) => {
      geoterm[index] = geot.id;
    });

    var request = {
      biblio_id: this.state.biblio_id,
      biblioitem_id: this.state.biblioitem_id,

      materialtype_id: this.state.biblio.materialtype_id,
      mediatype_id: this.state.biblio.mediatype_id,
      biblio_language_id: this.state.biblio.language_id,
      title: this.state.biblio.title,
      medium: this.state.biblio.medium,
      subtitle: this.state.biblio.subtitle,
      part_number: this.state.biblio.part_number,
      part_name: this.state.biblio.part_name,
      notes: this.state.biblio.notes,
      abstract: this.state.biblio.abstract,
      agerestriction: this.state.biblio.agerestriction,
      year_original_publication: this.state.biblio.year_original_publication,

      key_words: keywords,
      geographical_terms: geoterm,
      authors: { ...this.state.selectedAuthors },
    };

    Request.patchRequest(Url.updateBiblio + id, request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          errors: [],
        });

        if (response.data.biblioitem_id !== "") {
          this.props.history.push(
            Web.editBiblioitemonly +
              response.data.biblio_id +
              "/" +
              response.data.biblioitem_id
          );
          Messages.swalSuccess(response.data.success);
        } else {
          this.props.history.push(
            Web.createBiblioItem + response.data.biblio_id
          );
          Messages.swalSuccess(response.data.success);
        }
      } else if (response.status === 404) {
        Messages.swalError(response.data.error);
      } else if (response.status === 422) {
        this.setState({
          errors: response.data,
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  showDownshift = (e) => {
    this.setState({
      isOpenDownshift: true,
    });
  };

  hideDownshift = (e) => {
    this.setState({
      isOpenDownshift: false,
    });
  };

  render() {
    if (this.state.selectedAuthors.length !== 0) {
      var selectedAuthorsListName = this.state.selectedAuthors.map(
        (authors, index) => {
          return (
            <span key={index} className="tag-kyce mr-2">
              {authors.name} / {authors.type}
              <button
                type="button"
                className="ml-2 mr-1 pointer-li btn-unstyled x-button"
                onClick={(e) => this.removeAuthor(e, authors, index)}
              >
                ✕
              </button>
            </span>
          );
        }
      );
    }

    if (this.state.selectedKeywords.length !== 0) {
      var selectedKeywordListName = this.state.selectedKeywords.map(
        (keywords, index) => {
          return (
            <span key={index} className="tag-kyce mr-2">
              {keywords.name}
              <button
                type="button"
                className="ml-2 mr-1 pointer-li btn-unstyled x-button"
                onClick={(e) => this.removeKeyword(e, keywords)}
              >
                ✕
              </button>
            </span>
          );
        }
      );
    }

    if (this.state.selectedGeoTerms.length !== 0) {
      var selectedGeoTerms = this.state.selectedGeoTerms.map(
        (geoterm, index) => {
          return (
            <span key={index} className="tag-kyce mr-2">
              {geoterm.name}
              <button
                type="button"
                className="ml-2 mr-1 pointer-li btn-unstyled x-button"
                onClick={(e) => this.removeGeoTerm(e, geoterm)}
              >
                ✕
              </button>
            </span>
          );
        }
      );
    }

    const media = this.state.media_types.map((media_types) => {
      return { value: media_types.id, label: media_types.name };
    });

    const material = this.state.material_types.map((materials) => {
      return { value: materials.id, label: materials.name };
    });

    const languages = this.state.languages.map((languages) => {
      return { value: languages.id, label: languages.name };
    });

    return (
      <Fragment>
        <Downshift
          onChange={this.downshiftOnChange}
          itemToString={(item) => (item ? item.title : "")}
        >
          {({
            selectedItem,
            getInputProps,
            getItemProps,
            highlightedIndex,
            isOpen,
            inputValue,
          }) => (
            <div
              id="create-biblio"
              className="container-fluid small-offset-left"
            >
              <form>
                <div className="row m-0 biblio-shto">
                  <div className="col-7 col-sm-9 p-0 partial-title mb-5">
                    Ndrysho Bibliografi
                  </div>
                  <div className="col-5 col-sm-3 p-0 d-flex">
                    <div className="navigation">
                      <span>1 / 3</span>
                      <button
                        className="btn-unstyled"
                        name="forward"
                        onClick={(e) => this.update(e)}
                      >
                        <img src={right} alt="forward" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="titull title-container mb-4">
                  <label htmlFor="Titulli">Titull (245/a)</label>
                  <div className="position-relative pb-4">
                    <input
                      {...getInputProps({
                        value: this.state.biblio.title
                          ? this.state.biblio.title
                          : "",
                        onChange: this.handleChange("title"),
                      })}
                      onFocus={this.showDownshift}
                      onBlur={this.hideDownshift}
                      onKeyUp={this.handleChange("title")}
                      type="text"
                      name="title"
                      placeholder="Titull"
                      className={
                        "input-form-input" +
                        (this.state.errors.title ? " form-error" : "")
                      }
                    />
                    {this.state.biblio_id !== "" && (
                      <Fragment>
                        <button
                          type="button"
                          className="btn-unstyled emptyState"
                          onClick={this.emptyState}
                        >
                          ✕
                        </button>
                      </Fragment>
                    )}
                    {this.state.isOpenDownshift === true &&
                      this.state.biblio.title.length >= 3 && (
                        <Fragment>
                          {isOpen ? (
                            <div className="downshift-dropdown">
                              {this.state.biblios
                                .filter(
                                  (item) =>
                                    !inputValue ||
                                    item.title
                                      .normalize("NFD")
                                      .replace(/[\u0300-\u036f]/g, "")
                                      .toLowerCase()
                                      .includes(inputValue.toLowerCase())
                                )
                                .slice(0, 10)
                                .map((item, index) => (
                                  <div
                                    className="dropdown-item "
                                    {...getItemProps({
                                      key: index,
                                      index,
                                      item,
                                    })}
                                    style={{
                                      backgroundColor:
                                        highlightedIndex === index
                                          ? "#dcedff"
                                          : "white",
                                      fontWeight:
                                        selectedItem === item
                                          ? "bold"
                                          : "normal",
                                      color:
                                        selectedItem === item
                                          ? "#74767E"
                                          : "#74767E",
                                    }}
                                  >
                                    {item.title}
                                  </div>
                                ))}
                            </div>
                          ) : null}
                        </Fragment>
                      )}
                    {this.state.errors.title && (
                      <span className="error-message">
                        {this.state.errors.title}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="autor col-12 pb-4 mb-4">
                    <button
                      type="button"
                      className="btn-primary modal-button"
                      onClick={this.addAuthorPopup}
                    >
                      Shto Autor
                    </button>{" "}
                    <label htmlFor="Autor" style={{ width: "20px" }}>
                      (100/a)
                    </label>
                    <div className="tage author-tags-container tag-container-fake-input">
                      {selectedAuthorsListName}
                    </div>
                    <AuthorModal
                      types={this.state.author_types}
                      popupName={"Autor"}
                      authors={this.state.authors}
                      showPopup={this.state.addAuthorPopup}
                      onPopupClose={this.closeAddAuthorPopup}
                      parentCallback={this.handleCallbackAuthor}
                    ></AuthorModal>
                    <div className="d-none author-hiddeninputfields-container"></div>
                  </div>
                </div>

                <div className="row align-items-end">
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Medium">Medium (245/h)</label>
                    <input
                      placeholder="Shto Medium."
                      className={
                        "input-form-input" +
                        (this.state.errors.medium ? " form-error" : "")
                      }
                      name="medium"
                      type="text"
                      onKeyUp={this.handleChange("medium")}
                      defaultValue={
                        this.state.biblio.medium !== null
                          ? this.state.biblio.medium
                          : ""
                      }
                    />
                    {this.state.errors.medium && (
                      <span className="error-message left">
                        {this.state.errors.medium}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Nentitull">Nëntitull (245/b)</label>
                    <input
                      placeholder="Shto Nentitull."
                      className={
                        "input-form-input" +
                        (this.state.errors.subtitle ? " form-error" : "")
                      }
                      name="subtitle"
                      type="text"
                      onKeyUp={this.handleChange("subtitle")}
                      defaultValue={
                        this.state.biblio.subtitle !== null
                          ? this.state.biblio.subtitle
                          : ""
                      }
                    />
                    {this.state.errors.subtitle && (
                      <span className="error-message left">
                        {this.state.errors.subtitle}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Lloji i materialit">
                      Lloji i materialit
                    </label>
                    <Select
                      className={
                        "basic-select" +
                        (this.state.errors.materialtype_id ? " form-error" : "")
                      }
                      classNamePrefix="select_input"
                      name="material_type"
                      placeholder="Përzgjidh"
                      value={
                        this.state.materialType.value === ""
                          ? ""
                          : this.state.materialType
                      }
                      isClearable={true}
                      menuPlacement="auto"
                      onChange={this.handleChangeSelect(
                        "materialtype_id",
                        "materialType"
                      )}
                      isSearchable={false}
                      options={material}
                    />
                    {console.log(this.state.materialType)}
                    {this.state.errors.materialtype_id && (
                      <span className="error-message left">
                        {this.state.errors.materialtype_id}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row align-items-end">
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Emri i pjeses">Emri i pjesës (245/n)</label>
                    <input
                      placeholder="Shto Emrin e Pjeses."
                      className="input-form-input"
                      name="part_name"
                      onKeyUp={this.handleChange("part_name")}
                      type="text"
                      defaultValue={
                        this.state.biblio.part_name !== null
                          ? this.state.biblio.part_name
                          : ""
                      }
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Numri i pjeses">
                      Numri i pjesës (245/p)
                    </label>
                    <input
                      placeholder="Shto Numrin e Pjeses."
                      className="input-form-input"
                      name="part_number"
                      onKeyUp={this.handleChange("part_number")}
                      type="text"
                      defaultValue={
                        this.state.biblio.part_number !== null
                          ? this.state.biblio.part_number
                          : ""
                      }
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Gjuha origjinale">
                      Gjuha origjinale (041/a)
                    </label>
                    <Select
                      className={
                        "basic-select" +
                        (this.state.errors.biblio_language_id
                          ? " form-error"
                          : "")
                      }
                      classNamePrefix="select_input"
                      name="biblio_language_id"
                      placeholder="Përzgjidh"
                      value={
                        this.state.language.value === ""
                          ? ""
                          : this.state.language
                      }
                      isClearable={true}
                      menuPlacement="auto"
                      onChange={this.handleChangeSelect(
                        "language_id",
                        "language"
                      )}
                      isSearchable={true}
                      options={languages}
                    />
                    {this.state.errors.biblio_language_id && (
                      <span className="error-message left">
                        {this.state.errors.biblio_language_id}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row align-items-end">
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Kufizim moshe">Kufizim moshe (521/a)</label>
                    <input
                      placeholder="Shto Kufizim Moshe."
                      className="input-form-input"
                      name="agerestriction"
                      onKeyUp={this.handleChange("agerestriction")}
                      type="text"
                      defaultValue={
                        this.state.biblio.agerestriction !== null
                          ? this.state.biblio.agerestriction
                          : ""
                      }
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Viti i botimit origjinal">
                      Viti i botimit origjinal
                    </label>
                    <input
                      id="year_original_publication"
                      placeholder="Shto vitin."
                      className={
                        "input-form-input" +
                        (this.state.errors.year_original_publication
                          ? " form-error"
                          : "")
                      }
                      name="year_original_publication"
                      onKeyUp={this.handleChange("year_original_publication")}
                      type="number"
                      defaultValue={
                        this.state.biblio.year_original_publication !== null
                          ? this.state.biblio.year_original_publication
                          : ""
                      }
                    />
                    {this.state.errors.year_original_publication && (
                      <span className="error-message left">
                        {this.state.errors.year_original_publication}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-4 pb-4">
                    <label htmlFor="Lloji i medias">Lloji i medias</label>
                    <Select
                      className={
                        "basic-select" +
                        (this.state.errors.mediatype_id ? " form-error" : "")
                      }
                      isClearable={true}
                      menuPlacement="auto"
                      classNamePrefix="select_input"
                      name="media_type"
                      placeholder="Përzgjidh"
                      onChange={this.handleChangeSelect(
                        "mediatype_id",
                        "mediaType"
                      )}
                      value={
                        this.state.mediaType.value === ""
                          ? ""
                          : this.state.mediaType
                      }
                      isSearchable={true}
                      options={media}
                    />
                    {this.state.errors.mediatype_id && (
                      <span className="error-message left">
                        {this.state.errors.mediatype_id}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 mb-4 pb-4">
                    <button
                      className="btn-primary modal-button"
                      type="button"
                      onClick={this.addKeywordPopup}
                    >
                      Shto Fjalë-Kyçe{" "}
                    </button>{" "}
                    <label htmlFor="Term Gjeorgrafik" style={{ width: "20px" }}>
                      (650/a)
                    </label>
                    <div className="tage w-100 borderi key-word-tags-container tag-container-fake-input">
                      {selectedKeywordListName}
                    </div>
                    <KeywordModal
                      key_words={this.state.key_words}
                      showPopup={this.state.addKeywordPopup}
                      onPopupClose={this.closeAddKeywordPopup}
                      parentCallback={this.handleKeyword}
                    ></KeywordModal>
                    <div className="d-none keyword-hiddeninputfields-container"></div>
                  </div>
                  <div className="col-12 col-md-6 mb-4 pb-4">
                    <button
                      className="btn-primary modal-button"
                      type="button"
                      onClick={this.addGeoPopup}
                    >
                      Shto Terma Gjeografik
                    </button>{" "}
                    <label htmlFor="Term Gjeorgrafik" style={{ width: "20px" }}>
                      (651/a)
                    </label>
                    <div className="tage geo-terms-tags-container tag-container-fake-input">
                      {selectedGeoTerms}
                    </div>
                    <GeographicTermsModal
                      geographical_terms={this.state.geographical_terms}
                      showPopup={this.state.addGeoPopup}
                      onPopupClose={this.closeAddGeoPopup}
                      parentCallback={this.handleGeoTerm}
                    ></GeographicTermsModal>
                    <div className="d-none geoterms-hiddeninputfields-container"></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 mb-4 pb-4">
                    <label htmlFor="Abstract">Abstract (520/a)</label>
                    <textarea
                      type="textarea"
                      name="abstract"
                      cols="30"
                      rows="10"
                      placeholder="Abstract"
                      onKeyUp={this.handleChange("abstract")}
                      className="input-form-input tage"
                      defaultValue={
                        this.state.biblio.abstract !== null
                          ? this.state.biblio.abstract
                          : ""
                      }
                    ></textarea>
                  </div>
                  <div className="col-12 col-md-6 mb-4 pb-4">
                    <label htmlFor="Shenime">Shënime (500/a)</label>
                    <textarea
                      name="notes"
                      cols="30"
                      rows="10"
                      placeholder="Shenime"
                      onKeyUp={this.handleChange("notes")}
                      className={"input-form-input tage"}
                      defaultValue={
                        this.state.biblio.notes !== null
                          ? this.state.biblio.notes
                          : ""
                      }
                    ></textarea>
                  </div>
                </div>

                <div className="row pb-4 mb-4 m-0">
                  {this.state.btnLoading === true ? (
                    <button
                      name="forward"
                      className="btn-styled-2 px-5 ml-auto"
                    >
                      Duke u Ruajtur
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      name="forward"
                      className="btn-styled-2 px-5 ml-auto"
                      onClick={(e) => this.update(e)}
                    >
                      Ruaj
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
        </Downshift>
      </Fragment>
    );
  }
}

export default withRouter(EditBiblio);
