import React, { Component, Fragment } from "react";
import Select from "react-select";
import Url from "../../../api/Url";
import Request from "../../../api/Request";
import Messages from "../../../helpers/Messages";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sq from "date-fns/locale/sq";
import moment from "moment";
import AppConfig from "../../../services/AppConfig";
import Web from "../../../routes/Web";
import { withRouter } from "react-router-dom";
import LoadingGif from "../../../assets/images/icons/loading.gif";

class CreateStaff extends Component {
  constructor() {
    super();
    this.state = {
      staffRoles: {
        roles: [],
        loading: true,
      },
      inviteStaff: {
        first_name: "",
        last_name: "",
        email: "",
        dob: "",
        role_id: "",
        state: "",
        city: "",
        address: "",
        postal_code: "",
        phone: "",
        gender: "",
      },
      form_errors: {
        error_list: [],
      },
      selectedDob: {
        dob: "",
      },
      btnLoading: false,
    };
  }

  componentDidMount() {
    this.staffRoles();
  }

  staffRoles = () => {
    Request.getRequest(Url.staffRoles).then((response) => {
      if (response.status === 200) {
        this.setState({
          staffRoles: {
            roles: response.data.roles,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  inviteStaff = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    let formData = {
      first_name: this.state.inviteStaff.first_name,
      last_name: this.state.inviteStaff.last_name,
      email: this.state.inviteStaff.email,
      dob: this.state.inviteStaff.dob,
      role_id: this.state.inviteStaff.role_id,
      state: this.state.inviteStaff.state,
      city: this.state.inviteStaff.city,
      address: this.state.inviteStaff.address,
      postal_code: this.state.inviteStaff.postal_code,
      phone: this.state.inviteStaff.phone,
      gender: this.state.inviteStaff.gender,
    };

    Request.postRequest(Url.staffInvite, formData).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 201) {
        this.props.history.push(Web.listStaff);
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 422) {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  handleChange = (key) => (e) => {
    let newForm = {
      ...this.state.inviteStaff,
      [key]: e.target.value,
    };
    this.setState({
      inviteStaff: newForm,
    });
  };

  handleChangeD = (key) => (e) => {
    var newForm;
    if (e === null) {
      newForm = {
        ...this.state.inviteStaff,
        [key]: null,
      };
    } else {
      newForm = {
        ...this.state.inviteStaff,
        [key]: moment(e).format("DD-MM-yyyy"),
      };
    }

    let newForm1 = {
      ...this.state.selectedDob,
      [key]: e,
    };
    this.setState({
      selectedDob: newForm1,
      inviteStaff: newForm,
    });
  };

  handleChangeS = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = "";
    }
    let newForm = {
      ...this.state.inviteStaff,
      [key]: selectValue,
    };
    this.setState({
      inviteStaff: newForm,
    });
  };

  render() {
    if (!this.state.staffRoles.loading) {
      var roles = this.state.staffRoles.roles.map((role) => {
        return { value: role.id, label: role.name };
      });
    }

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div id="invite-staff-main">
            <p className="partial-title mb-5">Shto Staf</p>
            <form onSubmit={this.inviteStaff}>
              <div className="row">
                <div className="col-12 col-md-4 flex-column col-form">
                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="first_name">Emri</label>
                    <input
                      placeholder="Shto Emrin"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.first_name
                          ? " form-error"
                          : "")
                      }
                      name="first_name"
                      type="text"
                      id="first_name"
                      onChange={this.handleChange("first_name")}
                    />
                    {this.state.form_errors.error_list.first_name && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.first_name[0]}
                      </span>
                    )}
                  </div>
                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="last_name">Mbiemri</label>
                    <input
                      placeholder="Shto Mbiemrin"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.last_name
                          ? " form-error"
                          : "")
                      }
                      name="last_name"
                      type="text"
                      id="last_name"
                      onChange={this.handleChange("last_name")}
                    />
                    {this.state.form_errors.error_list.last_name && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.last_name[0]}
                      </span>
                    )}
                  </div>
                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="email">Email</label>
                    <input
                      placeholder="Shto Email"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.email
                          ? " form-error"
                          : "")
                      }
                      name="email"
                      type="text"
                      id="email"
                      onChange={this.handleChange("email")}
                    />
                    {this.state.form_errors.error_list.email && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.email[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="dob">Ditëlindje</label>
                    <DatePicker
                      placeholderText="Zgjidh datën"
                      name="dob"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale={sq}
                      dateFormat="dd-MM-yyyy"
                      autoComplete="off"
                      selected={this.state.selectedDob.dob}
                      className={
                        "date_input" +
                        (this.state.form_errors.error_list.dob
                          ? " form-error"
                          : "")
                      }
                      onChange={this.handleChangeD("dob")}
                    />
                    {this.state.form_errors.error_list.dob && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.dob[0]}
                      </span>
                    )}
                  </div>
                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="role_id">Roli</label>
                    <Select
                      className={
                        "basic-select" +
                        (this.state.form_errors.error_list.role_id
                          ? " form-error"
                          : "")
                      }
                      classNamePrefix="select_input"
                      name="role_id"
                      isClearable={true}
                      placeholder="Përzgjidh"
                      onChange={this.handleChangeS("role_id")}
                      isSearchable={false}
                      options={roles}
                    />
                    {this.state.form_errors.error_list.role_id && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.role_id[0]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12  col-md-4 flex-column col-form">
                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="state">Shteti</label>
                    <input
                      placeholder="Shto Shtetin"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.state
                          ? " form-error"
                          : "")
                      }
                      name="state"
                      type="text"
                      id="state"
                      onChange={this.handleChange("state")}
                    />
                    {this.state.form_errors.error_list.state && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.state[0]}
                      </span>
                    )}
                  </div>
                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="city">Qyteti</label>
                    <input
                      placeholder="Shto Qytetin"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.city
                          ? " form-error"
                          : "")
                      }
                      name="city"
                      type="text"
                      id="city"
                      onChange={this.handleChange("city")}
                    />
                    {this.state.form_errors.error_list.city && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.city[0]}
                      </span>
                    )}
                  </div>
                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="address">Adresa</label>
                    <input
                      placeholder="Shto Adresën"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.address
                          ? " form-error"
                          : "")
                      }
                      name="address"
                      type="text"
                      id="address"
                      onChange={this.handleChange("address")}
                    />
                    {this.state.form_errors.error_list.address && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.address[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="postal_code">Kodi Postar</label>
                    <input
                      placeholder="Shto Kodin Postar"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.postal_code
                          ? " form-error"
                          : "")
                      }
                      name="postal_code"
                      type="number"
                      id="postal_code"
                      onChange={this.handleChange("postal_code")}
                    />
                    {this.state.form_errors.error_list.postal_code && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.postal_code[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="phone">Telefon</label>
                    <input
                      id="phone"
                      placeholder="Shto numrin e telefonit"
                      className={
                        "input-form-input" +
                        (this.state.form_errors.error_list.phone
                          ? " form-error"
                          : "")
                      }
                      name="phone"
                      type="text"
                      onChange={this.handleChange("phone")}
                    />
                    {this.state.form_errors.error_list.phone && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.phone[0]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-4 d-flex flex-column col-form">
                  <div className="d-block mb-4">
                    <label htmlFor="gender" className="main-label">
                      Gjinia
                    </label>
                    <div className="radio-buttons d-flex">
                      <div className="radio-button mr-2">
                        <label htmlFor="mashkull">Mashkull</label>
                        <input
                          className="form-check-input"
                          id="mashkull"
                          name="gender"
                          type="radio"
                          value="M"
                          onChange={this.handleChange("gender")}
                        />
                      </div>
                      <div className="radio-button ml-3">
                        <label htmlFor="femer">Femër</label>
                        <input
                          className="form-check-input"
                          id="femer"
                          name="gender"
                          type="radio"
                          value="F"
                          onChange={this.handleChange("gender")}
                        />
                      </div>
                    </div>
                    {this.state.form_errors.error_list.gender && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.gender[0]}
                      </span>
                    )}
                  </div>
                  <div className="mt-auto pb-3">
                    {this.state.btnLoading === true ? (
                      <button
                        type="button"
                        className="btn-styled-2 mt-auto mb-4"
                      >
                        Duke u Ruajtur
                        <img
                          src={LoadingGif}
                          style={{
                            width: "18px",
                            margin: "-4px 0 0 7px",
                          }}
                        />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn-styled-2 mt-auto mb-4"
                        style={{ width: 160 + "px" }}
                      >
                        Ruaj
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(CreateStaff);
