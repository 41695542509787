import React, { Component, Fragment } from 'react';
import UserData from '../UserData';
import '../../../../assets/css/opac-profile.css';
// import Borrowings from '../../../administrator/members/Borrowings';
import ProfileDetails from './ProfileDetails';
import ListBorrowings from './ListBorrowings';
import ListReservations from './ListReservations';
// import ListFines from './ListFines';
import SuggestedBooks from './SuggestedBooks';

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      tab: {
        selected: 'userDetails',
      },
    };
  }

  chooseTab = (e, tab) => {
    e.preventDefault();
    this.setState({
      tab: {
        selected: tab,
      },
    });
  };

  render() {
    return (
      <Fragment>
        <div id="profile" className="row m-0 mb-4 pl-md-3 pl-md-0 pl-1">
          <UserData />
          <div className="col-md-12 col-lg-10 col-xl-10 col-12 mt-5 justify-content-center padding-large-sc">
            <div className="user-profile justify-content-center">
              <ul className="nav nav-tabs mb-5" id="myTab" role="tablist">
                <li
                  className="nav-item text-center col-md-4 center-content-li col-12"
                  role="user-profile"
                >
                  <a
                    className="nav-link active  profile-info "
                    id="user-profile-tab"
                    data-toggle="tab"
                    href="#user-profile"
                    role="tab"
                    aria-controls="user-profile"
                    aria-selected="true"
                    onClick={(e) => this.chooseTab(e, 'userDetails')}
                  >
                    Të dhëna personale
                  </a>
                </li>
                <li
                  id="user_borrowings_tab_b"
                  className="nav-item text-center center-content-li col-md-4 col-12 mt-sm-4 mt-md-0"
                  role="user-borrowings"
                >
                  <a
                    className="nav-link middle-tab profile-info "
                    id="user-borrowings-tab"
                    data-toggle="tab"
                    href="#user-borrowings"
                    role="tab"
                    aria-controls="user-borrowings"
                    aria-selected="false"
                    onClick={(e) => this.chooseTab(e, 'borrowings')}
                  >
                    Lista e huazimeve
                  </a>
                </li>
                <li
                  id="user_reservation_tab_b"
                  className="nav-item text-center center-content-li col-md-4 col-12 mt-md-4 mt-lg-0 mt-sm-4"
                  role="user-reservations"
                >
                  <a
                    className="nav-link middle-tab profile-info "
                    data-toggle="tab"
                    role="tab"
                    aria-controls="user-reservations"
                    aria-selected="false"
                    onClick={(e) => this.chooseTab(e, 'reservations')}
                  >
                    Rezervimet e mia
                  </a>
                </li>
                {/* <li id="user_fines_tab_b" className="nav-item text-center center-content-li col-lg-3 col-md-6 mt-md-4 mt-lg-0 mt-sm-4" role="user-fines">
                  <a className="nav-link middle-tab profile-info " id="user-fines-tab" data-toggle="tab" href="#user-fines" role="tab" aria-controls="user-fines" aria-selected="false" onClick={(e) => this.chooseTab(e, "fines")}>
                    Gjoba dhe shënime
                  </a>
                </li> */}
              </ul>

              <div className="tab-content" id="myTabContent">
                <Fragment>
                  {this.state.tab.selected === 'userDetails' && (
                    <ProfileDetails />
                  )}
                  {this.state.tab.selected === 'borrowings' && (
                    <ListBorrowings />
                  )}
                  {this.state.tab.selected === 'reservations' && (
                    <ListReservations />
                  )}
                  {/* {this.state.tab.selected === "fines" && (
                        <ListFines/>
                    )} */}
                </Fragment>
              </div>
            </div>
          </div>

          <SuggestedBooks />
        </div>
      </Fragment>
    );
  }
}

export default Profile;
