import React, { Component, Fragment } from 'react';
import '../../assets/css/header.css';
import AppConfig from '../../services/AppConfig';
import Web from '../../routes/Web';
import { Link, withRouter } from 'react-router-dom';

import MenuIcon from '../../assets/images/icons/menu-blue-icon.svg';
import SettingIcon from '../../assets/images/icons/settings_icon.svg';
import MobileMenuIcon from '../../assets/images/icons/mobile-menu-icon.svg';
import CancelNavbar from '../../assets/images/icons/cancel-navbar.svg';
import Request from '../../api/Request';
import Url from '../../api/Url';
import Logo from '../../assets/images/logo/logo-uniel.svg';

export const menuHandler = () => {
  document.querySelector('#sidebar').classList.toggle('active');
  document.querySelector('body').classList.toggle('overflow-hidden');
  document.querySelector('.open-navbar').classList.toggle('d-none');
  document.querySelector('.cancel-navbar').classList.toggle('d-block');
};

class Header extends Component {
  constructor() {
    super();
    this.state = {
      mobileSidebar: {
        sidebar: false,
      },
    };
  }

  logout = () => {
    Request.postRequest(Url.logout).then((response) => {
      if (response.status === 200) {
        AppConfig.deleteAccessToken();
        window.location.href = Web.homepage;
      }
    });
    // AppConfig.deleteAccessToken();
    // window.location.href = Web.homepage;
  };

  menuHandlerMobile = () => {
    if (this.state.mobileSidebar.sidebar === false) {
      this.setState({
        mobileSidebar: {
          sidebar: true,
        },
      });
      document.querySelector('#sidebar').classList.toggle('active');
      document.querySelector('body').classList.toggle('overflow-hidden');
    } else {
      this.setState({
        mobileSidebar: {
          sidebar: false,
        },
      });
      document.querySelector('#sidebar').classList.toggle('active');
      document.querySelector('body').classList.toggle('overflow-hidden');
    }
  };

  render() {
    var role = this.props.roleUser;
    var first_name = this.props.firstName;
    var last_name = this.props.lastName;
    return (
      <Fragment>
        <nav id="sidebar-mobile" className="d-md-none small-offset-left">
          <div className="sidebar-header">
            <div className="iniciale">
              <span className="initials-user">
                {first_name.charAt(0) + last_name.charAt(0)}
              </span>
            </div>
            <div className="user-data">
              <span className="role-name">{role}</span>
              <span className="user-full-name">
                {first_name + ' ' + last_name}
              </span>
            </div>
          </div>

          <button
            type="button"
            className="sidebarCollapse-menu mobile-menu btn-unstyled"
            onClick={this.menuHandlerMobile}
          >
            <img
              className={
                'open-navbar ' +
                (this.state.mobileSidebar.sidebar === true && ' d-none')
              }
              src={MobileMenuIcon}
              alt=""
            />
            <img
              className={
                'cancel-navbar' +
                (this.state.mobileSidebar.sidebar === true
                  ? ' d-block'
                  : ' d-none')
              }
              src={CancelNavbar}
              alt=""
            />
          </button>
        </nav>

        <nav className="navbar navbar-expand-lg navbar-light small-offset-left">
          <div className="d-flex flex-nowrap justify-content-between align-items-center w-100">
            <div className="logo-uniel">
              <Link to={Web.homepage} target="_blank">
                <img src={Logo} />
              </Link>
            </div>

            <div className="d-flex ml-auto nav-btns">
              <button
                type="button"
                onClick={this.menuHandlerMobile}
                className="sidebarCollapse-menu btn-unstyled"
              >
                <img src={MenuIcon} alt="" />
              </button>
              <Link to={Web.changePassword}>
                <img
                  src={SettingIcon}
                  alt="settings_menu"
                  className="big-navi-icon"
                />
              </Link>
            </div>
            <div className="logout-button">
              <button
                type="button"
                className="btn-styled-1 logout-btn"
                onClick={this.logout}
              >
                Dil
              </button>
            </div>
          </div>
        </nav>
      </Fragment>
    );
  }
}

export default withRouter(Header);
