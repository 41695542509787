import { Component, Fragment } from 'react';
import '../../../assets/css/frontend.css';
import { Link } from 'react-router-dom';
import Web from '../../../routes/Web';
import AppConfig from '../../../services/AppConfig';
import { FiUser } from 'react-icons/fi';
import { FaLock } from 'react-icons/fa';
import { RiLogoutBoxFill } from 'react-icons/ri';
import { IoSettingsSharp } from 'react-icons/io5';
import Request from '../../../api/Request';
import Url from '../../../api/Url';
import LogoWhite from '../../../assets/images/logo/uniel-logo-white.svg';

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      mobileMenu: false,
    };
  }
  logout = () => {
    Request.postRequest(Url.logout).then((response) => {
      if (response.status === 200) {
        AppConfig.deleteAccessToken();
        window.location.href = Web.homepage;
      }
    });
  };

  mobileMenu = (e) => {
    e.preventDefault();
    if (this.state.mobileMenu === false) {
      this.setState({
        mobileMenu: true,
      });
      document.querySelector('body').classList.add('overflow-hidden');
    } else if (this.state.mobileMenu === true) {
      this.setState({
        mobileMenu: false,
      });
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  };

  render() {
    var panelUrl = '';
    var changePassword = '';
    var role = this.props.role;
    if (role === 'Administrator') {
      panelUrl = Web.listBiblioitems;
      changePassword = Web.changePassword;
    } else if (role === 'It') {
      panelUrl = Web.listBackup;
      changePassword = Web.changePassword;
    } else if (role === 'Operator') {
      panelUrl = Web.listBiblioitems;
      changePassword = Web.changePassword;
    } else if (role === 'Bibliotekar') {
      panelUrl = Web.reportStats;
      changePassword = Web.changePassword;
    } else if (role === 'Staf akademik') {
      panelUrl = Web.opacProfile;
      changePassword = Web.opacChangePassword;
    } else if (role === 'Student') {
      panelUrl = Web.opacProfile;
      changePassword = Web.opacChangePassword;
    } else if (role === 'Personalitet') {
      panelUrl = Web.opacProfile;
      changePassword = Web.opacChangePassword;
    }
    return (
      <Fragment>
        <div className="row m-0">
          <header className="main-menu">
            <nav role="navigation" className="px-3">
              <ul className="nav-wrapper px-0 px-md-3">
                <li className="navsection-logo">
                  <Link to={Web.homepage}>
                    <img src={LogoWhite} alt="Uniel Logo" />
                  </Link>
                </li>
                <li className="navsection-primary">
                  <ul>
                    <li>
                      <Link to={Web.university}>Universiteti</Link>
                    </li>
                    <li>
                      <Link to={Web.about}>Rreth Bibliotekës</Link>
                    </li>
                    <li>
                      <Link to={Web.privacyPolicies}>Rregullore</Link>
                    </li>
                    <li>
                      <Link to={Web.news}>Lajme</Link>
                    </li>
                    <li>
                      <Link to={Web.help}>Ndihmë</Link>
                    </li>
                    <li>
                      <Link to={Web.contact}>Kontakt</Link>
                    </li>
                    {!AppConfig.isLogged() && role === '' ? (
                      <li>
                        <Link to={Web.login} className="login-button">
                          Hyr
                        </Link>
                      </li>
                    ) : (
                      <Fragment>
                        <div className="logged-user">
                          <button
                            type="button"
                            className="btn-unstyled logged-user-btn"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <FiUser />
                          </button>
                          <div className="user-actions dropdown-menu dropdown-menu-right">
                            <li>
                              <Link to={panelUrl}>
                                <IoSettingsSharp />
                                Paneli
                              </Link>
                            </li>
                            <li>
                              <Link to={changePassword}>
                                <FaLock /> Ndrysho Fjalëkalimin
                                {AppConfig.roleStatus}
                              </Link>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="btn-unstyled"
                                onClick={this.logout}
                              >
                                <RiLogoutBoxFill /> Dil
                              </button>
                            </li>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </ul>
                </li>
                <li className="navsection-mobile">
                  <div
                    className={
                      'mobilemenu-wrapper' +
                      (this.state.mobileMenu === true ? ' active' : '') +
                      (AppConfig.isLogged() === true ? ' loggedin' : '')
                    }
                    onClick={this.mobileMenu}
                  >
                    <span className="bars"></span>
                    <span className="bars"></span>
                    <span className="bars"></span>
                  </div>
                  <div
                    className={
                      'overlay' +
                      (this.state.mobileMenu === true ? ' open' : '')
                    }
                    id="overlay"
                  >
                    <div className="menu-div">
                      <ul>
                        <li className="main-li">
                          {AppConfig.isLogged() === false && role === '' ? (
                            <Link to={Web.login} className="login-button">
                              Hyr
                            </Link>
                          ) : (
                            <Fragment>
                              <div className="logged-in">
                                <ul className="user-actions">
                                  <li>
                                    <Link to={panelUrl}>
                                      <IoSettingsSharp />
                                      Paneli
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={changePassword}>
                                      <FaLock /> Ndrysho Fjalëkalimin
                                      {AppConfig.roleStatus}
                                    </Link>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className="btn-unstyled"
                                      onClick={this.logout}
                                    >
                                      <RiLogoutBoxFill /> Dil
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </Fragment>
                          )}
                        </li>
                      </ul>
                      <ul className="menu-mob">
                        <li>
                          <Link to={Web.university}>Universiteti</Link>
                        </li>
                        <li>
                          <Link to={Web.about}>Rreth Bibliotekës</Link>
                        </li>
                        <li>
                          <Link to={Web.privacyPolicies}>Rregullore</Link>
                        </li>
                        <li>
                          <Link to={Web.news}>Lajme</Link>
                        </li>
                        <li>
                          <Link to={Web.help}>Ndihmë</Link>
                        </li>
                        <li>
                          <Link to={Web.contact}>Kontakt</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </header>
        </div>
      </Fragment>
    );
  }
}

export default Navbar;
