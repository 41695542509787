import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import Request from "../../../../../api/Request";
import right from "../../../../../assets/images/icons/arrow_right.svg";
import left from "../../../../../assets/images/icons/arrow_left.svg";
import Select from "react-select";
import AuthorModal from "../AuthorModal";
import Messages from "../../../../../helpers/Messages";
import AppConfig from "../../../../../services/AppConfig";
import Url from "../../../../../api/Url";
import Web from "../../../../../routes/Web";
import LoadingGif from "../../../../../assets/images/icons/loading.gif";

class EditBiblioitem extends Component {
  componentDidMount() {
    const biblio_id = this.props.match.params.biblio_id;
    const id = this.props.match.params.id;

    Request.getRequest(Url.editBiblioitemOnly + biblio_id + "/" + id).then(
      (response) => {
        if (response.status === 200) {
          this.setState({
            publishers: response.data.publishers,
            languages: response.data.languages,
            authors: response.data.contributors,
            biblioitem: response.data.biblioitem,
            selectedContributors: [],
            items: response.data.items,
          });

          //contributors
          this.state.biblioitem.contributors.map((author) => {
            var selected = {
              id: author.id,
              name: author.name,
              type: author.pivot.type,
            };

            this.setState({
              selectedContributors: [
                ...this.state.selectedContributors,
                selected,
              ],
            });
          });

          if (
            response.data.biblioitem.language ||
            response.data.biblioitem.language !== null
          ) {
            this.setState({
              biblioitemLanguage: {
                value: response.data.biblioitem.language.id,
                label: response.data.biblioitem.language.name,
              },
            });
          }
          if (
            response.data.biblioitem.publisher ||
            response.data.biblioitem.publisher !== null
          ) {
            this.setState({
              publisher: {
                value: response.data.biblioitem.publisher.id,
                label: response.data.biblioitem.publisher.name,
              },
            });
          }

          const authors = this.state.authors;
          this.state.selectedContributors.map((author) => {
            var removeIndex = authors.findIndex(
              (item) => item.id === author.id
            );
            authors.splice(removeIndex, 1);
          });

          this.setState({
            authors: authors,
          });
        } else if (response.status === 404) {
          Messages.swalError(response.data.error);
        } else {
          Messages.swalError(AppConfig.serverError);
        }
      }
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      biblioitem: [],
      publishers: [],
      languages: [],
      authors: [],
      errors: [],
      cover: null,
      temporary_coverpath: "",
      url: null,
      temporary_urlpath: "",
      delete_cover: 0,
      delete_url: 0,
      language: {
        value: "",
        label: "",
      },
      publisher: {
        value: "",
        label: "",
      },
      biblioitemLanguage: {
        value: "",
        label: "",
      },
      selectedContributors: [],
      addAuthorPopup: false,
      contributor_types: [
        { value: "redaktor", label: "Redaktor" },
        { value: "perkthyes", label: "Përkthyes" },
        { value: "recensues", label: "Recensues" },
        { value: "pergatites", label: "Përgatitës" },
        { value: "kontributor", label: "Person përgjegjës" },
      ],
      items: 0,
      btnLoading: false,
    };
  }

  addAuthorPopup = () => {
    this.setState({
      addAuthorPopup: true,
    });
  };

  closeAddAuthorPopup = (status) => {
    this.setState({ addAuthorPopup: status });
  };

  //delete cover and file

  deleteCover = () => {
    this.setState({
      cover: null,
      temporary_coverpath: "",
      delete_cover: 1,
    });
  };

  deleteUrl = () => {
    this.setState({
      url: null,
      temporary_urlpath: "",
      delete_url: 1,
    });
  };

  onChange = (key) => (e) => {
    let newForm = {
      ...this.state.biblioitem,
      [key]: e.target.value,
    };
    this.setState({
      biblioitem: newForm,
    });
  };

  onChangeSelect = (key, state) => (e) => {
    var selectValue;
    var selectLabel;
    if (e !== null) {
      selectValue = e.value;
      selectLabel = e.label;
    } else {
      selectValue = "";
      selectLabel = "";
    }
    let newForm = {
      ...this.state.biblioitem,
      [key]: selectValue,
    };
    let newForm1 = {
      ...this.state[state],
      value: selectValue,
      label: selectLabel,
    };
    this.setState({
      biblioitem: newForm,
      [state]: newForm1,
    });
  };

  fileSelectHandler = (event) => {
    this.setState({
      cover: event.target.files[0],
      temporary_coverpath: URL.createObjectURL(event.target.files[0]),
      delete_cover: 0,
    });
  };

  fileSelectHandlerUrl = (event) => {
    this.setState({
      url: event.target.files[0],
      temporary_urlpath: URL.createObjectURL(event.target.files[0]),
      delete_url: 0,
    });
  };

  //contributor
  handleCallbackContributor = (dataAuthor, typeAuthor, control) => {
    var author = { id: dataAuthor.id, name: dataAuthor.name, type: typeAuthor };
    this.setState({
      selectedContributors: [...this.state.selectedContributors, author],
    });
    if (control === "old") {
      const inputs1 = this.state.authors;
      const removeIndex = inputs1.findIndex(
        (item) => item.id === dataAuthor.id
      );
      inputs1.splice(removeIndex, 1);
      this.setState({
        authors: inputs1,
      });
    }
  };

  removeContributor = (e, author, type) => {
    e.preventDefault();

    var authorrem = { id: author.id, name: author.name };
    this.setState({
      authors: [...this.state.authors, authorrem],
    });

    const inputs1 = this.state.selectedContributors;
    const removeIndex = inputs1.findIndex((item) => item.id === author.id);
    inputs1.splice(removeIndex, 1);
    this.setState({
      selectedContributors: inputs1,
    });
  };

  update = (e, goto) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const biblio_id = this.props.match.params.biblio_id;
    const id = this.props.match.params.id;
    const formData = new FormData();
    formData.append(
      "publisher_id",
      this.state.biblioitem.publisher_id === null
        ? ""
        : this.state.biblioitem.publisher_id
    );
    formData.append(
      "biblioitem_language_id",
      this.state.biblioitem.language_id === null
        ? ""
        : this.state.biblioitem.language_id
    );
    formData.append(
      "isbn",
      this.state.biblioitem.isbn === null ? "" : this.state.biblioitem.isbn
    );
    formData.append(
      "issn",
      this.state.biblioitem.issn === null ? "" : this.state.biblioitem.issn
    );
    formData.append(
      "publication_year",
      this.state.biblioitem.publication_year === null
        ? ""
        : this.state.biblioitem.publication_year
    );
    formData.append(
      "physical_details",
      this.state.biblioitem.physical_details === null
        ? ""
        : this.state.biblioitem.physical_details
    );
    formData.append(
      "pages",
      this.state.biblioitem.pages === null ? "" : this.state.biblioitem.pages
    );

    formData.append(
      "dimensions",
      this.state.biblioitem.dimensions === null
        ? ""
        : this.state.biblioitem.dimensions
    );
    formData.append(
      "place",
      this.state.biblioitem.place === null ? "" : this.state.biblioitem.place
    );
    formData.append(
      "lccn",
      this.state.biblioitem.lccn === null ? "" : this.state.biblioitem.lccn
    );
    formData.append(
      "kdu",
      this.state.biblioitem.kdu === null ? "" : this.state.biblioitem.kdu
    );

    formData.append(
      "publication_number",
      this.state.biblioitem.publication_number === null
        ? ""
        : this.state.biblioitem.publication_number
    );
    formData.append(
      "national_control_number",
      this.state.biblioitem.national_control_number === null
        ? ""
        : this.state.biblioitem.national_control_number
    );
    formData.append(
      "price",
      this.state.biblioitem.price === null ? "" : this.state.biblioitem.price
    );
    formData.append(
      "replacement_price",
      this.state.biblioitem.replacement_price === null
        ? ""
        : this.state.biblioitem.replacement_price
    );

    formData.append(
      "currency",
      this.state.biblioitem.currency === null
        ? ""
        : this.state.biblioitem.currency
    );
    formData.append(
      "ean",
      this.state.biblioitem.ean === null ? "" : this.state.biblioitem.ean
    );
    formData.append(
      "barcode",
      this.state.biblioitem.barcode === null
        ? ""
        : this.state.biblioitem.barcode
    );
    formData.append("cover", this.state.cover === null ? "" : this.state.cover);

    formData.append("url", this.state.url === null ? "" : this.state.url);

    this.state.selectedContributors.map((author, index) => {
      formData.append("contributors[" + index + "][author_id]", author.id);
      formData.append("contributors[" + index + "][type]", author.type);
    });

    if (this.state.cover === null && this.state.delete_cover === 1) {
      formData.append("delete_cover", 1);
    }

    if (this.state.url === null && this.state.delete_url === 1) {
      formData.append("delete_url", 1);
    }
    formData.append("biblio_id", biblio_id);
    formData.append(goto, 1);

    formData.append("_method", "PATCH");

    Request.postRequest(Url.updateBiblioitemonly + id, formData).then(
      (response) => {
        this.setState({ btnLoading: false });
        if (response.status === 200) {
          this.setState({
            errors: [],
          });

          if (goto === "back") {
            this.props.history.push(Web.editBiblio + biblio_id + "/" + id);
            Messages.swalSuccess(response.data.success);
          } else {
            if (this.state.items === 0) {
              this.props.history.push(Web.createItem + id);
              Messages.swalSuccess(response.data.success);
            } else {
              this.props.history.push(Web.editItems + id);
              Messages.swalSuccess(response.data.success);
            }
          }
        } else if (response.status === 404) {
          Messages.swalError(response.data.error);
        } else if (response.status === 422) {
          this.setState({
            errors: response.data,
          });
        } else {
          Messages.swalError(AppConfig.serverError);
        }
      }
    );
  };

  render() {
    const languages = this.state.languages.map((languages) => {
      return { value: languages.id, label: languages.name };
    });

    const publishers = this.state.publishers.map((publishers) => {
      return { value: publishers.id, label: publishers.name };
    });

    if (this.state.selectedContributors.length !== 0) {
      var selectedAuthorsListName = this.state.selectedContributors.map(
        (authors, index) => {
          return (
            <span key={index} className="tag-kyce mr-2">
              {authors.name} / {authors.type}
              <button
                type="button"
                className="ml-2 mr-1 pointer-li btn-unstyled x-button"
                onClick={(e) => this.removeContributor(e, authors, index)}
              >
                ✕
              </button>
            </span>
          );
        }
      );
    }
    if (this.state.biblioitem.publisher) {
      var publisher_value = {
        value: this.state.biblioitem.publisher.id,
        label: this.state.biblioitem.publisher.name,
      };
    }

    if (this.state.biblioitem.language) {
      var language_value = {
        value: this.state.biblioitem.language.id,
        label: this.state.biblioitem.language.name,
      };
    }
    return (
      <Fragment>
        <div id="create-biblio" className="small-offset-left">
          <div className="row m-0 biblio-shto">
            <div className="col-7 col-sm-9 partial-title p-0 mb-5">
              Ndrysho Bibliografi
            </div>
            <div className="col-5 col-sm-3 p-0 d-flex">
              <div className="navigation">
                <button
                  className="btn-unstyled"
                  name="back"
                  onClick={(e) => this.update(e, "back")}
                >
                  <img src={left} alt="back" />
                </button>
                <span>2 / 3</span>
                <button
                  className="btn-unstyled"
                  name="forward"
                  onClick={(e) => this.update(e, "forward")}
                >
                  <img src={right} alt="forward" />
                </button>
              </div>
            </div>
          </div>
          <div className="row m-0">
            <form>
              <div className="row align-items-end">
                <div className="col-12 col-md-6 mb-4 pb-4">
                  <label htmlFor="ISBN">Isbn (020/a)</label>
                  <input
                    placeholder="Isbn"
                    className={
                      "input-form-input" +
                      (this.state.errors.isbn ? " form-error" : "")
                    }
                    name="isbn"
                    onKeyUp={this.onChange("isbn")}
                    type="text"
                    defaultValue={
                      this.state.biblioitem.isbn !== null
                        ? this.state.biblioitem.isbn
                        : ""
                    }
                  />
                  {this.state.errors.isbn && (
                    <span className="error-message left">
                      {this.state.errors.isbn}
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-6 mb-4 pb-4">
                  <label htmlFor="Issn">Issn (022/a)</label>
                  <input
                    placeholder="Issn"
                    className={
                      "input-form-input" +
                      (this.state.errors.issn ? " form-error" : "")
                    }
                    name="issn"
                    onKeyUp={this.onChange("issn")}
                    type="text"
                    defaultValue={
                      this.state.biblioitem.issn !== null
                        ? this.state.biblioitem.issn
                        : ""
                    }
                  />
                  {this.state.errors.issn && (
                    <span className="error-message left">
                      {this.state.errors.issn}
                    </span>
                  )}
                </div>
              </div>
              <div className="row align-items-end">
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Viti i Botimit">Viti i Botimit (260/c)</label>
                  <input
                    id="publication_year"
                    placeholder="Viti i Botimit"
                    className={
                      "input-form-input" +
                      (this.state.errors.publication_year ? " form-error" : "")
                    }
                    onKeyUp={this.onChange("publication_year")}
                    name="publication_year"
                    type="number"
                    defaultValue={
                      this.state.biblioitem.publication_year !== null
                        ? this.state.biblioitem.publication_year
                        : ""
                    }
                  />
                  {this.state.errors.publication_year && (
                    <span className="error-message left">
                      {this.state.errors.publication_year}
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Vendi i botimit">
                    Vendi i botimit (260/a)
                  </label>
                  <input
                    placeholder="Vendi i botimit"
                    className="input-form-input"
                    onKeyUp={this.onChange("place")}
                    name="place"
                    type="text"
                    defaultValue={
                      this.state.biblioitem.place !== null
                        ? this.state.biblioitem.place
                        : ""
                    }
                  />
                </div>
                <div className="col-12 col-md-4 mb-4 pb-4">
                  <label htmlFor="Botuesi">Botuesi (260/b)</label>
                  <Select
                    className={
                      "basic-select" +
                      (this.state.errors.publisher_id ? " form-error" : "")
                    }
                    classNamePrefix="select_input"
                    value={
                      this.state.publisher.value === ""
                        ? ""
                        : this.state.publisher
                    }
                    placeholder="Përzgjidh"
                    isClearable={true}
                    menuPlacement="auto"
                    onChange={this.onChangeSelect("publisher_id", "publisher")}
                    isSearchable={true}
                    options={publishers}
                  />
                  {this.state.errors.publisher_id && (
                    <span className="error-message left">
                      {this.state.errors.publisher_id}
                    </span>
                  )}
                </div>
              </div>

              <div className="row align-items-end">
                <div className="autor col-12 pb-4 mb-4">
                  <button
                    type="button"
                    className="modal-button"
                    onClick={this.addAuthorPopup}
                  >
                    Shto personat përgjegjës
                  </button>{" "}
                  <label
                    htmlFor="Personat përgjegjës"
                    style={{ width: "20px" }}
                  >
                    (700/a)
                  </label>
                  <div className="tage author-tags-container tag-container-fake-input">
                    {selectedAuthorsListName}
                  </div>
                  <AuthorModal
                    types={this.state.contributor_types}
                    showPopup={this.state.addAuthorPopup}
                    onPopupClose={this.closeAddAuthorPopup}
                    popupName={"Personat përgjegjës"}
                    authors={this.state.authors}
                    parentCallback={this.handleCallbackContributor}
                  ></AuthorModal>
                  <div className="d-none author-hiddeninputfields-container"></div>
                </div>
              </div>

              <div className="row align-items-end mb-4 pb-4">
                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="Ilustrime">Ilustrime</label>
                  <input
                    placeholder="Ilustrime"
                    className="input-form-input"
                    name="physical_details"
                    onKeyUp={this.onChange("physical_details")}
                    type="text"
                    defaultValue={
                      this.state.biblioitem.physical_details !== null
                        ? this.state.biblioitem.physical_details
                        : ""
                    }
                  />
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="Numri i faqeve">Numri i faqeve (300/a)</label>
                  <input
                    placeholder="Numri i faqeve"
                    className="input-form-input"
                    name="pages"
                    onKeyUp={this.onChange("pages")}
                    type="text"
                    defaultValue={
                      this.state.biblioitem.pages !== null
                        ? this.state.biblioitem.pages
                        : ""
                    }
                  />
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="Përmasat">Përmasat (300/c)</label>
                  <input
                    placeholder="Përmasat"
                    className="input-form-input"
                    name="dimensions"
                    onKeyUp={this.onChange("dimensions")}
                    type="text"
                    defaultValue={
                      this.state.biblioitem.dimensions !== null
                        ? this.state.biblioitem.dimensions
                        : ""
                    }
                  />
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="Lccn">Lccn (010/a)</label>
                  <input
                    placeholder="Lccn"
                    className="input-form-input"
                    name="lccn"
                    type="text"
                    onKeyUp={this.onChange("lccn")}
                    defaultValue={
                      this.state.biblioitem.lccn !== null
                        ? this.state.biblioitem.lccn
                        : ""
                    }
                  />
                </div>

                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="Shfletuesi">Shfletuesi</label>
                  {(this.state.biblioitem.url === null &&
                    this.state.url === null) ||
                  (this.state.biblioitem.url !== null &&
                    this.state.delete_url === 1) ? (
                    <div className="file_upload_div">
                      <input
                        placeholder="Imazhi i artikullit"
                        className={
                          "file_upload_input" +
                          (this.state.errors.url ? " form-error" : "")
                        }
                        accept="application/pdf"
                        onChange={this.fileSelectHandlerUrl}
                        name="url"
                        type="file"
                        title=""
                      />
                      <div className="upload-button">+ Ngarko shfletuesin</div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.url !== null && (
                    <div className="uploaded_file_div">
                      <div className="uploaded_file">{this.state.url.name}</div>
                      <button
                        type="button"
                        onClick={(e) => this.deleteUrl()}
                        className="remove-image btn-unstyled"
                      >
                        ✕
                      </button>
                    </div>
                  )}

                  {this.state.biblioitem.url !== null &&
                    this.state.delete_url !== 1 &&
                    this.state.url === null && (
                      <div className="uploaded_file_div">
                        <div className="uploaded_file">
                          {this.state.biblioitem.url}
                        </div>
                        <button
                          type="button"
                          onClick={(e) => this.deleteUrl()}
                          className="remove-image btn-unstyled"
                        >
                          ✕
                        </button>
                      </div>
                    )}

                  {this.state.errors.url && (
                    <span className="error-message left">
                      {this.state.errors.url}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="kdu">Kdu (080/a)</label>
                  <input
                    placeholder="Kdu"
                    className="input-form-input"
                    name="kdu"
                    onKeyUp={this.onChange("kdu")}
                    type="text"
                    defaultValue={
                      this.state.biblioitem.kdu !== null
                        ? this.state.biblioitem.kdu
                        : ""
                    }
                  />
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="Gjuha">Gjuha (041/a)</label>
                  <Select
                    className={
                      "basic-select" +
                      (this.state.errors.biblioitem_language_id
                        ? " form-error"
                        : "")
                    }
                    classNamePrefix="select_input"
                    name="language"
                    isClearable={true}
                    menuPlacement="auto"
                    placeholder="Përzgjidh"
                    onChange={this.onChangeSelect(
                      "language_id",
                      "biblioitemLanguage"
                    )}
                    value={
                      this.state.biblioitemLanguage.value === ""
                        ? ""
                        : this.state.biblioitemLanguage
                    }
                    isSearchable={true}
                    options={languages}
                  />
                  {this.state.errors.biblioitem_language_id && (
                    <span className="error-message left">
                      {this.state.errors.biblioitem_language_id}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="Vëllimi">Vëllimi (250/a)</label>
                  <input
                    placeholder="Vëllimi"
                    className="input-form-input"
                    name="publication_number"
                    onKeyUp={this.onChange("publication_number")}
                    type="text"
                    defaultValue={
                      this.state.biblioitem.publication_number !== null
                        ? this.state.biblioitem.publication_number
                        : ""
                    }
                  />
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="Nr kontrollit">
                    Nr. i kontrollit kombëtar (016/a)
                  </label>
                  <input
                    placeholder="Numri i kontrollit kombëtar"
                    className="input-form-input"
                    name="national_control_number"
                    onKeyUp={this.onChange("national_control_number")}
                    type="text"
                    defaultValue={
                      this.state.biblioitem.national_control_number !== null
                        ? this.state.biblioitem.national_control_number
                        : ""
                    }
                  />
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="Barkod">Barkod</label>
                  <input
                    placeholder="Barkod"
                    className={
                      "input-form-input" +
                      (this.state.errors.barcode ? " form-error" : "")
                    }
                    name="barcode"
                    onKeyUp={this.onChange("barcode")}
                    type="text"
                    defaultValue={
                      this.state.biblioitem.barcode !== null
                        ? this.state.biblioitem.barcode
                        : ""
                    }
                  />
                  {this.state.errors.barcode && (
                    <span className="error-message left">
                      {this.state.errors.barcode}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="Çmimi">Çmimi (Lekë) (365/b)</label>
                  <input
                    id="price"
                    placeholder="Çmimi"
                    step="any"
                    className={
                      "input-form-input" +
                      (this.state.errors.price ? " form-error" : "")
                    }
                    name="price"
                    onKeyUp={this.onChange("price")}
                    type="number"
                    defaultValue={
                      this.state.biblioitem.price !== null
                        ? this.state.biblioitem.price
                        : ""
                    }
                  />
                  {this.state.errors.price && (
                    <span className="error-message left">
                      {this.state.errors.price}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="Çmimi zëvendësues">
                    Çmimi zëvendësues (Lekë)
                  </label>
                  <input
                    id="replacement_price"
                    placeholder="Çmimi zëvendësues"
                    step="any"
                    className={
                      "input-form-input" +
                      (this.state.errors.replacement_price ? " form-error" : "")
                    }
                    name="replacement_price"
                    onKeyUp={this.onChange("replacement_price")}
                    type="number"
                    defaultValue={
                      this.state.biblioitem.replacement_price !== null
                        ? this.state.biblioitem.replacement_price
                        : ""
                    }
                  />
                  {this.state.errors.replacement_price && (
                    <span className="error-message left">
                      {this.state.errors.replacement_price}
                    </span>
                  )}
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-4 pb-4">
                  <label htmlFor="Kopertinë">Kopertinë</label>
                  {(this.state.biblioitem.cover !== null &&
                    this.state.delete_cover === 1) ||
                  (this.state.cover === null &&
                    this.state.biblioitem.cover === null) ? (
                    <div className="file_upload_div">
                      <input
                        placeholder="Kopertinë"
                        className={
                          "file_upload_input" +
                          (this.state.errors.cover ? " form-error" : "")
                        }
                        accept="image/*"
                        onChange={this.fileSelectHandler}
                        name="cover"
                        type="file"
                        title=""
                      />

                      <div className="upload-button">+ Ngarko kopertinë</div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.cover != null && (
                    <div className="uploaded_file_div">
                      <div className="uploaded_file">
                        {this.state.cover.name}
                      </div>
                      <button
                        type="button"
                        onClick={(e) => this.deleteCover()}
                        className="remove-image btn-unstyled"
                      >
                        ✕
                      </button>
                    </div>
                  )}

                  {this.state.biblioitem.cover != null &&
                    this.state.delete_cover !== 1 &&
                    this.state.cover === null && (
                      <div className="uploaded_file_div">
                        <div className="uploaded_file">
                          {this.state.biblioitem.cover}
                        </div>
                        <button
                          type="button"
                          onClick={(e) => this.deleteCover()}
                          className="remove-image btn-unstyled"
                        >
                          ✕
                        </button>
                      </div>
                    )}
                  {this.state.errors.cover && (
                    <span className="error-message left">
                      {this.state.errors.cover}
                    </span>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="row m-0">
            {this.state.btnLoading === true ? (
              <button className="btn-styled-2 ml-auto mb-5 px-5">
                Duke u Ruajtur
                <img
                  src={LoadingGif}
                  style={{
                    width: "18px",
                    margin: "-4px 0 0 7px",
                  }}
                />
              </button>
            ) : (
              <button
                className="btn-styled-2 ml-auto mb-5 px-5"
                onClick={(e) => this.update(e, "forward")}
              >
                Ruaj
              </button>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(EditBiblioitem);
