import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import "../../../assets/css/borrowingdetail.css";
import Request from "../../../api/Request";
import Url from "../../../api/Url";
import dateFormat from "dateformat";
import Messages from "../../../helpers/Messages";
import AppConfig from "../../../services/AppConfig";
import Web from "../../../routes/Web";
import moment from "moment";
import LoadingGif from "../../../assets/images/icons/loading.gif";

class Details extends Component {
  constructor() {
    super();
    this.state = {
      details: {
        borrowing: "",
        item: "",
        biblio: "",
        biblioitem: "",
        member: "",
        authors: [],
        publisher: "",
        loading: true,
      },
      // fine: {
      //   details: '',
      // },
      // applyFine: {
      //   borrowing_id: '',
      //   amount: '',
      //   type: '',
      //   paid: '0',
      //   notes: '',
      // },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
      btnPostponeLoading: false,
      // btnFineLoading: false,
    };
  }

  componentDidMount() {
    this.borrowingDetails();
  }

  borrowingDetails = () => {
    const borrowing_id = this.props.match.params.id;

    Request.getRequest(Url.showBorrowing + borrowing_id).then((response) => {
      if (response.status === 200) {
        this.setState({
          details: {
            borrowing: response.data.borrowing,
            item: response.data.borrowing.item,
            biblio: response.data.borrowing.item.biblioitem.biblio,
            biblioitem: response.data.borrowing.item.biblioitem,
            member: response.data.borrowing.user,
            authors: response.data.borrowing.item.biblioitem.biblio.authors,
            publisher: response.data.borrowing.item.biblioitem.publisher,
            loading: false,
          },
          // fine: {
          //   details: response.data.fine,
          // },
          // applyFine: {
          //   amount: '',
          //   type: '',
          //   paid: '0',
          //   notes: '',
          // },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  // handleChangeSelect = (key) => (e) => {
  //   var selectValue;
  //   if (e !== null) {
  //     selectValue = e.value;
  //   } else {
  //     selectValue = '';
  //   }
  //   let newForm = {
  //     ...this.state.applyFine,
  //     [key]: selectValue,
  //   };
  //   this.setState({
  //     applyFine: newForm,
  //   });
  // };

  // handleChangeInput = (key) => (e) => {
  //   let newForm = {
  //     ...this.state.applyFine,
  //     [key]: e.target.value,
  //   };
  //   this.setState({
  //     applyFine: newForm,
  //   });
  // };

  /**
   * Apliko gjoben
   */
  // applyFine = (e) => {
  //   const borrowings_id = this.props.match.params.id;
  //   this.setState({ btnLoading: true });
  //   let fineData = {
  //     borrowings_id: borrowings_id,
  //     amount: this.state.applyFine.amount,
  //     type: this.state.applyFine.type,
  //     paid: this.state.applyFine.paid,
  //     notes: this.state.applyFine.notes,
  //   };

  //   e.preventDefault();
  //   Request.postRequest(Url.applyFine, fineData).then((response) => {
  //     this.setState({ btnLoading: false });
  //     if (response.status === 200) {
  //       Messages.swalSuccess(response.data.success);
  //       this.borrowingDetails();
  //       this.setState({
  //         form_errors: {
  //           error_list: [],
  //         },
  //       });
  //     } else if (response.status === 422) {
  //       this.setState({
  //         form_errors: {
  //           error_list: response.data,
  //         },
  //       });
  //     } else {
  //       Messages.swalError(AppConfig.serverError);
  //     }
  //   });
  // };

  /**
   * Paguaj Gjoben
   */
  // payFine = (e) => {
  //   const fineID = this.state.fine.details.id;
  //   this.setState({ btnFineLoading: true });
  //   let formData = {
  //     id: fineID,
  //   };

  //   e.preventDefault();
  //   Request.postRequest(Url.payFine, formData).then((response) => {
  //     this.setState({ btnFineLoading: false });
  //     if (response.status === 200) {
  //       Messages.swalSuccess(response.data.success);
  //       this.borrowingDetails();
  //     } else if (response.status === 404) {
  //       Messages.swalError(response.data.error);
  //       this.borrowingDetails();
  //     } else {
  //       Messages.swalError(AppConfig.serverError);
  //     }
  //   });
  // };

  /**
   * Shtyj rezervimin
   */
  postpone = (e) => {
    const borrowings_id = this.props.match.params.id;
    this.setState({ btnPostponeLoading: true });
    let formData = {
      id: borrowings_id,
    };

    e.preventDefault();

    Request.postRequest(Url.postponeBorrowing, formData).then((response) => {
      this.setState({ btnPostponeLoading: false });
      if (response.status === 200) {
        this.props.history.push(Web.listBorrowings);
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 404) {
        Messages.swalError(response.data.error);
        this.borrowingDetails();
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  /**
   * Kthe materialin
   */
  return = (e) => {
    const borrowings_id = this.props.match.params.id;

    let formData = {
      id: borrowings_id,
    };

    e.preventDefault();

    Request.postRequest(Url.returnBorrowing, formData).then((response) => {
      if (response.status === 200) {
        this.props.history.push(Web.listBorrowings);
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 404) {
        Messages.swalError(response.data.error);
        this.borrowingDetails();
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  // fineType = (param) => {
  //   switch (param) {
  //     case 'lost':
  //       return 'Spastruar';
  //     case 'damaged':
  //       return 'Dëmtuar';
  //     case 'not_returned_on_time':
  //       return 'Nuk është kthyer në kohë';
  //     default:
  //       return '';
  //   }
  // };

  userGender = (param) => {
    switch (param) {
      case "M":
        return "Mashkull";
      case "F":
        return "Femër";
      default:
        return "";
    }
  };

  /**
   * If data are loading , this function return "..." , else return the data
   * @param param
   * @returns {string|*}
   */
  isLoading = (param) => {
    return this.state.details.loading ? "  ... " : param;
  };

  /**
   * @param param
   * @returns {string|*}
   */
  isNull = (param) => {
    if (param === null) {
      return " ";
    } else {
      return param;
    }
  };

  render() {
    const item = this.state.details.item;
    var created_at = "";
    created_at = moment(this.state.details.borrowing.created_at).format(
      "DD-MM-yyyy"
    );
    var returned_on = "";
    returned_on = moment(this.state.details.borrowing.returned_on).format(
      "DD-MM-yyyy"
    );
    var should_return_on = "";
    should_return_on = moment(
      this.state.details.borrowing.should_return_on
    ).format("DD-MM-yyyy");
    const biblio = this.state.details.biblio;
    const biblioitem = this.state.details.biblioitem;
    const member = this.state.details.member;
    const borrowing = this.state.details.borrowing;
    // const fine = this.state.fine.details;
    const authors = this.state.details.authors.map((author) => {
      return author.name;
    });
    const publisher = this.state.details.publisher;

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div className="search-item">
            <p className="partial-title mb-5">Detajet e Huazimit</p>

            <div className="borrowing-details">
              <div className="member">
                <div className="b-header">
                  <h3>Anëtari</h3>
                </div>
                <div className="b-details">
                  <span className="attr-col">Emri Mbiemri : </span> &nbsp;&nbsp;{" "}
                  <span className="attr-val">
                    {this.isLoading(member.first_name + " " + member.last_name)}{" "}
                  </span>
                  <br></br>
                  <span className="attr-col">Email : </span> &nbsp;&nbsp;{" "}
                  <span className="attr-val">
                    {this.isLoading(member.email)}
                  </span>
                  <br></br>
                  <span className="attr-col">Nr. Tel : </span> &nbsp;&nbsp;
                  <span className="attr-val">
                    {this.isLoading(this.isNull(member.phone))}
                  </span>
                  <br></br>
                  <span className="attr-col">Gjinia : </span> &nbsp;&nbsp;{" "}
                  <span className="attr-val">
                    {this.isLoading(this.userGender(member.gender))}
                  </span>
                  <br></br>
                  <span className="attr-col">Qytet/ Shtet : </span> &nbsp;&nbsp;
                  <span className="attr-val">
                    {this.isLoading(this.isNull(member.state)) +
                      " / " +
                      this.isLoading(this.isNull(member.city))}
                  </span>
                  <br></br>
                  <span className="attr-col">Adresa/ Kodi Postar : </span>{" "}
                  &nbsp;&nbsp;{" "}
                  <span className="attr-val">
                    {this.isLoading(this.isNull(member.address)) +
                      " / " +
                      this.isLoading(this.isNull(member.postal_code))}{" "}
                  </span>
                  <br></br>
                </div>
              </div>
              <div className="material">
                <div className="b-header">
                  <h3>Materiali</h3>
                </div>
                <div className="b-details">
                  <span className="attr-col">Titull : </span> &nbsp;&nbsp;
                  <span className="attr-val">
                    {this.isLoading(biblio.title)}
                  </span>
                  <br></br>
                  <span className="attr-col">Autor : </span> &nbsp;&nbsp;
                  <span className="attr-val">{authors.join("; ")}</span>
                  <br></br>
                  <span className="attr-col">Botues : </span> &nbsp;&nbsp;
                  <span className="attr-val">
                    {!publisher ? " ...  " : publisher.name}
                  </span>
                  <br></br>
                  <span className="attr-col">
                    Kodifikimi :{" "}
                  </span> &nbsp;&nbsp;{" "}
                  <span className="attr-val">
                    {this.isLoading(item.inventory_number)}
                  </span>
                  <br></br>
                  <span className="attr-col">Numri i inventarit : </span>{" "}
                  &nbsp;&nbsp; <span className="attr-val">{item.id}</span>
                  <br></br>
                  <span className="attr-col">Lloji i Huazimit : </span>{" "}
                  &nbsp;&nbsp;{" "}
                  <span className="attr-val">
                    {this.isLoading(item.borrowing_type)}
                  </span>
                  <br></br>
                  <span className="attr-col">
                    Nr. Vendi :{" "}
                  </span> &nbsp;&nbsp;{" "}
                  <span className="attr-val">
                    {this.isLoading(this.isNull(item.place_number))}
                  </span>
                  <br></br>
                  <span className="attr-col">Viti i Botimit : </span>{" "}
                  &nbsp;&nbsp;{" "}
                  <span className="attr-val">
                    {this.isLoading(this.isNull(biblioitem.publication_year))}
                  </span>
                  <br></br>
                </div>
              </div>
              <div className="borrowing">
                <div className="b-header">
                  <h3>Detajet e huazimit</h3>
                </div>
                <div className="b-details">
                  <span className="attr-col">Data e Huazimit : </span>{" "}
                  <span className="attr-val">
                    {borrowing.created_at !== null && created_at}
                  </span>
                  <br></br>
                  <span className="attr-col">Data e Dorezimit : </span>{" "}
                  <span className="attr-val">
                    {borrowing.returned_on !== null && returned_on}
                  </span>
                  <br></br>
                  <span className="attr-col">
                    Data Kur Duhej Dorezuar :{" "}
                  </span>{" "}
                  <span className="attr-val">
                    {borrowing.should_return_on !== null && should_return_on}
                  </span>
                  <br></br>
                </div>
              </div>
            </div>

            {/* <div className="search-item member-details-bg mt-4">
              {fine === null && (
                <form onSubmit={this.applyFine}>
                  <div className="row m-0">
                    <div className="list-data m-0 pl-0 col-12">
                      <ul>
                        <li className="member-detail text-uppercase">
                          Apliko Gjobë
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 col-md-4 pl-0 pb-4 mb-3">
                      <input
                        id="price"
                        placeholder="Vlera "
                        className={
                          "input-form-input" +
                          (this.state.form_errors.error_list.amount
                            ? " form-error"
                            : "")
                        }
                        name="amount"
                        onChange={this.handleChangeInput("amount")}
                        type="text"
                      />
                      {this.state.form_errors.error_list.amount && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.amount[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-md-4 pl-0 pb-4 mb-3">
                      <Select
                        name="type"
                        className={
                          "basic-select" +
                          (this.state.form_errors.error_list.type
                            ? " form-error"
                            : "")
                        }
                        isSearchable={false}
                        isClearable={true}
                        onChange={this.handleChangeSelect("type")}
                        classNamePrefix="select_input"
                        placeholder="Lloji i gjobes"
                        options={[
                          { value: "damaged", label: "Demtuar" },
                          { value: "lost", label: "Spastruar" },
                          {
                            value: "not_returned_on_time",
                            label: "Nuk eshte kthyer ne kohe",
                          },
                        ]}
                      />
                      {this.state.form_errors.error_list.type && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.type[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-md-4 pl-0 mb-4">
                      <div className="radio-buttons borrow flex-column mb-0">
                        <div className="radio-button mr-2">
                          <input
                            className="form-check-input"
                            id="later"
                            name="paid"
                            type="radio"
                            defaultChecked
                            onChange={this.handleChangeInput("paid")}
                            value="0"
                          />
                          <label htmlFor="later">Paguaj më vonë</label>
                        </div>
                        <div className="radio-button">
                          <input
                            className={"form-check-input"}
                            id="paid_now"
                            name="paid"
                            type="radio"
                            onChange={this.handleChangeInput("paid")}
                            value="1"
                          />
                          <label htmlFor="paid_now">Paguaj tani</label>
                        </div>
                        {this.state.form_errors.error_list.paid && (
                          <span className="error-message">
                            {this.state.form_errors.error_list.paid[0]}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-8 pl-0 mb-5">
                      <textarea
                        placeholder="Shenime "
                        className={
                          "input-form-input tage" +
                          (this.state.form_errors.error_list.notes
                            ? " form-error"
                            : "")
                        }
                        name="notes"
                        onChange={this.handleChangeInput("notes")}
                        cols="50"
                        rows="10"
                      ></textarea>
                      {this.state.form_errors.error_list.notes && (
                        <span className="error-message">
                          {this.state.form_errors.error_list.notes[0]}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-md-4 pl-0">
                      {this.state.btnLoading === true ? (
                        <button className="btn-styled-2 px-5" type="button">
                          Duke u Aplikuar
                          <img
                            src={LoadingGif}
                            style={{
                              width: "18px",
                              margin: "-4px 0 0 7px",
                            }}
                          />
                        </button>
                      ) : (
                        <button className="btn-styled-2 px-5" type="submit">
                          Apliko
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              )}

              {fine !== null && fine.paid === 0 && (
                <Fragment>
                  <div className="list-data m-0 pl-0 col-12">
                    <ul>
                      <li className="member-detail text-uppercase">
                        Detajet e Gjobës
                      </li>
                    </ul>
                  </div>
                  <div className="list-data pl-0 col-12">
                    <ul>
                      <li style={{ color: "orange" }}>
                        Ky perdorues ka gjobë dhe pritet që të paguhet.
                      </li>
                      <li>
                        Data e aplikimit të gjobës : &nbsp;
                        {fine.created_at !== null &&
                          dateFormat(fine.created_at, "d-mm-yyyy")}
                      </li>

                      <li>Tipi i gjobës : {this.fineType(fine.type)}</li>
                      <li>Shuma : {fine.amount}</li>
                      <li>Shënime : {fine.notes}</li>
                    </ul>
                  </div>
                </Fragment>
              )}

              {fine !== null && fine.paid === 1 && (
                <Fragment>
                  <div className="list-data m-0 pl-0 col-12">
                    <ul>
                      <li className="member-detail text-uppercase">
                        Detajet e Gjobës
                      </li>
                    </ul>
                  </div>
                  <div className="list-data pl-0 col-12">
                    <ul>
                      <li style={{ color: "green" }}>
                        Ky përdorues ka pasur gjobë dhe është paguar.
                      </li>
                      <li>
                        Data e aplikimit të gjobës : &nbsp;
                        {fine.created_at !== null &&
                          dateFormat(fine.created_at, "d-mm-yyyy")}
                      </li>
                      <li>Tipi i gjobës : {this.fineType(fine.type)}</li>
                      <li>Shuma : {fine.amount}</li>
                      <li>Shënime : {fine.notes}</li>
                    </ul>
                  </div>
                </Fragment>
              )}
            </div> */}
          </div>
          <div className="row my-5 mx-0 borrowing-btns">
            {!this.state.details.loading && borrowing.returned_on === null && (
              <Fragment>
                <div className="pr-3">
                  <form onSubmit={this.return}>
                    <button className={"btn-styled-1 px-4"} type={"submit"}>
                      Kthe
                    </button>
                  </form>
                </div>
                <div>
                  <form onSubmit={this.postpone}>
                    {this.state.btnPostponeLoading === true ? (
                      <button className={"btn-styled-2 px-4"} type={"button"}>
                        Duke u Aplikuar
                        <img
                          src={LoadingGif}
                          style={{
                            width: "18px",
                            margin: "-4px 0 0 7px",
                          }}
                          alt=""
                        />
                      </button>
                    ) : (
                      <button className={"btn-styled-2 px-4"} type={"submit"}>
                        Shty rezervimin
                      </button>
                    )}
                  </form>
                </div>
              </Fragment>
            )}
            {/* {fine !== null &&
              fine.paid === 1 &&
              borrowing.returned_on === null && (
                <div>
                  <form onSubmit={this.postpone}>
                    {this.state.btnPostponeLoading === true ? (
                      <button className={"btn-styled-2 px-4"} type={"button"}>
                        Duke u Aplikuar
                        <img
                          src={LoadingGif}
                          style={{
                            width: "18px",
                            margin: "-4px 0 0 7px",
                          }}
                        />
                      </button>
                    ) : (
                      <button className={"btn-styled-2 px-4"} type={"submit"}>
                        Shty rezervimin
                      </button>
                    )}
                  </form>
                </div>
              )}
            {fine == null && borrowing.returned_on === null && (
              <div>
                <form onSubmit={this.postpone}>
                  {this.state.btnPostponeLoading === true ? (
                    <button className={"btn-styled-2 px-4"} type={"button"}>
                      Duke u Aplikuar
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button className={"btn-styled-2 px-4"} type={"submit"}>
                      Shty rezervimin
                    </button>
                  )}
                </form>
              </div>
            )}
            {fine !== null && fine.paid !== 1 && (
              <div>
                <form onSubmit={this.payFine}>
                  {this.state.btnFineLoading === true ? (
                    <button className={"btn-styled-2 px-4"} type={"button"}>
                      Duke u paguar
                      <img
                        src={LoadingGif}
                        style={{
                          width: "18px",
                          margin: "-4px 0 0 7px",
                        }}
                      />
                    </button>
                  ) : (
                    <button className={"btn-styled-2 px-4"} type={"submit"}>
                      Paguaj Gjoben
                    </button>
                  )}
                </form>
              </div>
            )} */}
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(Details);
