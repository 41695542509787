import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Url from "../../../api/Url";
import Request from "../../../api/Request";
import AppConfig from "../../../services/AppConfig";
import libra from "../../../assets/images/covers/Libra.svg";
import Disertacione from "../../../assets/images/covers/Disertacione.svg";
import Periodike from "../../../assets/images/covers/Periodike.svg";
import Harta from "../../../assets/images/covers/Harta.svg";
import Doreshkrime from "../../../assets/images/covers/Doreshkrime.svg";
import Dokument from "../../../assets/images/covers/Dokument.svg";
import Artikuj from "../../../assets/images/covers/Artikuj.svg";
import Materiale_audio_vizuale from "../../../assets/images/covers/Materiale_audio-vizuale.svg";
import loadingGif from "../../../assets/images/icons/loading.gif";

class Isbd extends Component {
  constructor() {
    super();
    this.state = {
      showIsbd: {
        biblioitem: [],
        loading: true,
      },
      tab: {
        selected: "description",
      },
    };
  }

  componentDidMount() {
    this.isbdShow();
  }

  isbdShow = () => {
    const biblioitem_id = this.props.biblioitem_id;

    let request = Url.detailed + biblioitem_id;

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          showIsbd: {
            biblioitem: response.data.biblioitem,
            loading: false,
          },
        });
      }
    });
  };

  checkCover = (cover, material_type) => {
    if (cover !== null) {
      return AppConfig.biblioCoversPath + cover;
    } else if (cover === null) {
      switch (material_type) {
        case 2:
          return libra;
        case 6:
          return Disertacione;
        case 3:
          return Periodike;
        case 5:
          return Harta;
        case 4:
          return Doreshkrime;
        case 8:
          return Dokument;
        case 1:
          return Artikuj;
        case 7:
          return Materiale_audio_vizuale;
        default:
          return "";
      }
    }
  };

  checkContributors = (contributor) => {
    switch (contributor) {
      case "perkthyes":
        return "përkth.";
      case "redaktor":
        return "red.";
      case "recensues":
        return "rec.";
      case "pergatites":
        return "përg.";
      default:
        return "";
    }
  };

  render() {
    var isbd = "";
    var biblioitem = this.state.showIsbd.biblioitem;
    if (this.state.showIsbd.loading === true) {
      isbd = <img className="m-auto d-block" src={loadingGif} alt="loading" />;
    } else {
      var authorsReversed = biblioitem.biblio.authors.map((author) => {
        return author.name.split(",").reverse().join(" ");
      });

      var contributorReversed = biblioitem.contributors.map((contributor) => {
        return (
          this.checkContributors(contributor.pivot.type) +
          " " +
          contributor.name.split(",").reverse().join(" ")
        );
      });
      var Keywords = biblioitem.biblio.keywords.map((keyword) => {
        return keyword.name;
      });
      var Geoterms = biblioitem.biblio.geographicalterms.map(
        (geographicalterm) => {
          return geographicalterm.name;
        }
      );

      return (isbd = (
        <div
          className="tab-pane fade show active"
          id="përshkrimi"
          role="tabpanel"
          aria-labelledby="përshkrimi-tab"
        >
          <div className="row mb-5 pershkrim">
            <div className="col-12 col-sm-4 d-none d-md-block image-holder text-left">
              <img
                src={this.checkCover(
                  biblioitem.cover,
                  biblioitem.biblio.materialtype.id
                )}
                alt="cover"
              />
            </div>
            <div className="col-12 col-md-8 description-holder">
              <div>
                <div className="row m-0 name-item">
                  {biblioitem.biblio.title && biblioitem.biblio.title}
                  {biblioitem.biblio.title && biblioitem.biblio.subtitle
                    ? ": "
                    : ""}
                  {biblioitem.biblio.subtitle && biblioitem.biblio.subtitle}
                  {authorsReversed.length > 0 &&
                    " / " + authorsReversed.join("; ")}
                  {contributorReversed.length > 0 && authorsReversed.length <= 0
                    ? " / " + contributorReversed.join("; ")
                    : contributorReversed.length > 0 &&
                      authorsReversed.length > 0 &&
                      "; " + contributorReversed.join("; ")}

                  {biblioitem.biblio.part_number &&
                    " / " + biblioitem.biblio.part_number}
                  {biblioitem.biblio.part_name &&
                    " / " + biblioitem.biblio.part_name}
                  {biblioitem.publication_number &&
                    ".- " + biblioitem.publication_number}
                  {biblioitem.place && ".- " + biblioitem.place}
                  {biblioitem.publisher && " : " + biblioitem.publisher.name}
                  {biblioitem.publication_year &&
                    ", " + biblioitem.publication_year}
                  {biblioitem.pages && ".- " + biblioitem.pages}
                  {biblioitem.physical_details &&
                    "; " + biblioitem.physical_details}
                  {biblioitem.dimensions && "; " + biblioitem.dimensions + "."}
                </div>

                {biblioitem.biblio.notes && (
                  <div className="row m-0 name-item">
                    {biblioitem.biblio.notes && biblioitem.biblio.notes}
                  </div>
                )}
                {biblioitem.issn && (
                  <div className="row m-0 name-item">
                    ISSN: {biblioitem.issn && biblioitem.issn}
                  </div>
                )}
                {biblioitem.isbn && (
                  <div className="row m-0 name-item">
                    ISBN: {biblioitem.isbn && biblioitem.isbn}
                  </div>
                )}
                {Keywords.length !== 0 && (
                  <div className="row m-0 mt-3 mb-3">
                    <div className="col-12 pl-0">
                      <span className="name-desc">Fjale kyçe:</span>
                    </div>
                    <div className="col-12 pl-0 name-item">
                      {Keywords.join(", ")}
                    </div>
                  </div>
                )}

                {Geoterms.length !== 0 && (
                  <div className="row m-0 mb-3">
                    <span className="name-desc col-12 p-0">
                      Terma gjeografikë:{" "}
                    </span>
                    <div className="name-item">{Geoterms.join(", ")}</div>
                  </div>
                )}

                {biblioitem.kdu && (
                  <Fragment>
                    <div className="row m-0">
                      <span className="name-desc">
                        Klasifikimi dhjetor universal:{" "}
                      </span>
                    </div>
                    <div className="row m-0 name-item">
                      {biblioitem.kdu && biblioitem.kdu}
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      ));
    }
    return <Fragment>{isbd}</Fragment>;
  }
}

export default withRouter(Isbd);
