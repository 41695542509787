import React, { Component, Fragment } from "react";
import Header from "../../components/opac/partials/Header";
import "../../assets/css/frontend1.css";
import NewsPost from "../../components/opac/NewsPost";
import Footer from "../../components/opac/partials/Footer";
class NewsPostView extends Component {
  render() {
    return (
      <Fragment>
        <div style={{ width: 100 + "%" }}>
          <Header
            roleUser={this.props.roles}
            title={"Lajme"}
            desc={'"Biblioteka nuk është luks por nevojë jetësore"'}
          />
          <NewsPost />
          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default NewsPostView;
