import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import Web from './Web';
import ProfileView from '../views/opac/OpacPanel/ProfileView';
import ChangePasswordOpacView from '../views/opac/OpacPanel/ChangePasswordView';
import BiblioitemsView from '../views/administrator/catalog/BiblioitemsView';
import ShowBiblioitemView from '../views/administrator/catalog/ShowBiblioitemView';
import EditBiblioitemView from '../views/administrator/catalog/EditBiblioitemView';
import CreateBiblioView from '../views/administrator/catalog/CreateBiblioView';
import CreateBiblioitemView from '../views/administrator/catalog/CreateBiblioitemView';
import CreateItemView from '../views/administrator/catalog/CreateItemView';
import EditBiblioView from '../views/administrator/catalog/EditBiblioView';
import EditBiblioitemOnlyView from '../views/administrator/catalog/EditBiblioitemOnlyView';
import EditItemsView from '../views/administrator/catalog/EditItemsView';
import ImportMarcView from '../views/administrator/catalog/marc/ImportView';
import ConfirmMarcView from '../views/administrator/catalog/marc/ConfirmMarc';
import ExportMarcView from '../views/administrator/catalog/marc/ExportView';
import CreateAuthorView from '../views/administrator/catalog/CreateAuthorView';
import CreatePublisherView from '../views/administrator/catalog/CreatePublisherView';
import CreateKeywordView from '../views/administrator/catalog/CreateKeywordView';
import CreateGeographicalTermView from '../views/administrator/catalog/CreateGeographicalTermView';
import BorrowView from '../views/administrator/borrowing/BorrowView';
import SearchMemberView from '../views/administrator/borrowing/SearchMemberView';
import SearchItemView from '../views/administrator/borrowing/SearchItemView';
import ConfirmationItemView from '../views/administrator/borrowing/ConfirmationItemView';
import BorrowingListView from '../views/administrator/borrowing/BorrowingListView';
import ListPendingView from '../views/administrator/borrowing/ListPendingView';
import ReservationsView from '../views/administrator/borrowing/ReservationsView';
import DetailsView from '../views/administrator/borrowing/DetailsView';
import CreateSerialView from '../views/administrator/serial/CreateSerialView';
import ListSerialView from '../views/administrator/serial/ListSerialView';
import ShowSerialView from '../views/administrator/serial/ShowSerialView';
import FrequenceView from '../views/administrator/serial/FrequenceView';
import EditSerialView from '../views/administrator/serial/EditSerialView';
import ListMemberView from '../views/administrator/member/ListMemberView';
import CreateMemberView from '../views/administrator/member/CreateMemberView';
import ShowMemberView from '../views/administrator/member/ShowMemberView';
import EditMemberView from '../views/administrator/member/EditMemberView';
import ListStaffView from '../views/administrator/staff/ListStaffView';
import AddStaffView from '../views/administrator/staff/CreateStaffView';
import ShowStaffView from '../views/administrator/staff/ShowStaffView';
import EditStaffView from '../views/administrator/staff/EditStaffView';
import EditUserView from '../views/administrator/users/EditUserView';
import AcquisitionListView from '../views/administrator/acquisition/AcquisitionListView';
import AddAcquisitionView from '../views/administrator/acquisition/AddAcquisitionView';
import ShowAcquisitionView from '../views/administrator/acquisition/ShowAcquisitionView';
import EditAcquisitionView from '../views/administrator/acquisition/EditAcquisitionView';
import ReportStatsView from '../views/administrator/report/StatsView';
import ReportCatalogView from '../views/administrator/report/CatalogView';
import ReportBorrowingView from '../views/administrator/report/BorrowingView';
import IndexBackupView from '../views/administrator/backup/IndexBackupView';
import IndexLogsView from '../views/administrator/backup/IndexLogsView';
import ListPostsView from '../views/administrator/blog/ListPostsView';
import EditPostView from '../views/administrator/blog/EditPostView';
import CreatePostView from '../views/administrator/blog/CreatePostView';
import ChangePasswordView from '../views/administrator/users/ChangePasswordView';
import DigitizedBooksView from '../views/opac/DigitizedBooksView';
import LatestBooksView from '../views/opac/LatestBooksView';
import MostReadBooksView from '../views/opac/MostReadBooksView';
import DetailedView from '../views/opac/DetailedView';
import AdvSearchResultsView from '../views/opac/AdvSearchResultsView';
import SearchResultsView from '../views/opac/SearchResultsView';
import ActivateAccountView from '../views/auth/ActivateAccountView';
import ResetPasswordView from '../views/auth/ResetPasswordView';
import ForgotPasswordView from '../views/auth/ForgotPasswordView';
import LoginView from '../views/auth/LoginView';
import ContactView from '../views/opac/ContactView';
import HelpView from '../views/opac/HelpView';
import NewsPostView from '../views/opac/NewsPostView';
import NewsView from '../views/opac/NewsView';
import PrivacyPoliciesView from '../views/opac/PrivacyPoliciesView';
import AboutView from '../views/opac/AboutView';
import UniversityView from '../views/opac/UniversityView';
import HomepageView from '../views/opac/HomepageView';
import CreateServerView from '../views/administrator/catalog/z3950/CreateServerView';
import ServicesListView from '../views/administrator/catalog/z3950/ServicesListView';
import ConfirmZ3950View from '../views/administrator/catalog/z3950/ConfirmZ3950View';

class Routes extends Component {
  static PrivateRoutes = () => {
    return [
      {
        path:
          Web.login ||
          Web.forgotPassword ||
          Web.resetPasswordParam ||
          Web.ativateAccountParam,
        access: [
          'Administrator',
          'Operator',
          'It',
          'Bibliotekar',
          'Student',
          'Staf akademik',
          'Personalitet',
        ],
        component: () => <Redirect to={Web.dashboard} />,
      },
      {
        path: Web.dashboard,
        access: ['Administrator', 'Operator'],
        component: () => <Redirect to={Web.listBiblioitems} />,
      },
      {
        path: Web.dashboard,
        access: ['It'],
        component: () => <Redirect to={Web.listBackup} />,
      },
      {
        path: Web.dashboard,
        access: ['Bibliotekar'],
        component: () => <Redirect to={Web.reportStats} />,
      },
      {
        path: Web.dashboard,
        access: ['Student', 'Staf akademik', 'Personalitet'],
        component: () => <Redirect to={Web.opacProfile} />,
      },
      {
        path: Web.opacProfile,
        access: ['Personalitet', 'Staf akademik', 'Student'],
        component: ProfileView,
      },
      {
        path: Web.opacChangePassword,
        access: ['Personalitet', 'Staf akademik', 'Student'],
        component: ChangePasswordOpacView,
      },
      {
        path: Web.listBiblioitems,
        access: ['Administrator', 'Operator'],
        component: BiblioitemsView,
      },
      {
        path: Web.showBiblioitemID,
        access: ['Administrator', 'Operator'],
        component: ShowBiblioitemView,
      },
      {
        path: Web.editBiblioitemID,
        access: ['Administrator', 'Operator'],
        component: EditBiblioitemView,
      },
      {
        path: Web.createBiblio,
        access: ['Administrator', 'Operator'],
        component: CreateBiblioView,
      },
      {
        path: Web.createBiblioItemID,
        access: ['Administrator', 'Operator'],
        component: CreateBiblioitemView,
      },
      {
        path: Web.createItemID,
        access: ['Administrator', 'Operator'],
        component: CreateItemView,
      },
      {
        path: Web.editBiblioID,
        access: ['Administrator', 'Operator'],
        component: EditBiblioView,
      },
      {
        path: Web.editBiblioitemonlyID,
        access: ['Administrator', 'Operator'],
        component: EditBiblioitemOnlyView,
      },
      {
        path: Web.editItemsID,
        access: ['Administrator', 'Operator'],
        component: EditItemsView,
      },
      {
        path: Web.importMarc,
        access: ['Administrator'],
        component: ImportMarcView,
      },
      {
        path: Web.confirmMarc,
        access: ['Administrator'],
        component: ConfirmMarcView,
      },
      {
        path: Web.exportMarc,
        access: ['Administrator'],
        component: ExportMarcView,
      },
      {
        path: Web.z3950Server,
        access: ['Administrator', 'It'],
        component: CreateServerView,
      },
      {
        path: Web.confirmZ3950,
        access: ['Administrator', 'Operator', 'Bibliotekar'],
        component: ConfirmZ3950View,
      },
      {
        path: Web.z3950Service,
        access: ['Administrator', 'Operator', 'Bibliotekar'],
        component: ServicesListView,
      },
      {
        path: Web.createAuthor,
        access: ['Administrator', 'Operator'],
        component: CreateAuthorView,
      },
      {
        path: Web.createPublisher,
        access: ['Administrator', 'Operator'],
        component: CreatePublisherView,
      },
      {
        path: Web.createKeyWords,
        access: ['Administrator', 'Operator'],
        component: CreateKeywordView,
      },
      {
        path: Web.createGeogreaphicalTerm,
        access: ['Administrator', 'Operator'],
        component: CreateGeographicalTermView,
      },
      {
        path: Web.borrow,
        access: ['Administrator', 'Operator'],
        component: BorrowView,
      },
      {
        path: Web.searchMemberID,
        access: ['Administrator', 'Operator'],
        component: SearchMemberView,
      },
      {
        path: Web.searchMaterialID,
        access: ['Administrator', 'Operator'],
        component: SearchItemView,
      },
      {
        path: Web.confirmBorrowId,
        access: ['Administrator', 'Operator'],
        component: ConfirmationItemView,
      },
      {
        path: Web.listBorrowings,
        access: ['Administrator', 'Operator'],
        component: BorrowingListView,
      },
      {
        path: Web.listPending,
        access: ['Administrator', 'Operator'],
        component: ListPendingView,
      },
      {
        path: Web.reservations,
        access: ['Administrator', 'Operator'],
        component: ReservationsView,
      },
      {
        path: Web.borrowingDetailsID,
        access: ['Administrator', 'Operator'],
        component: DetailsView,
      },
      {
        path: Web.createSerial,
        access: ['Administrator', 'Operator'],
        component: CreateSerialView,
      },
      {
        path: Web.listSerials,
        access: ['Administrator', 'Operator'],
        component: ListSerialView,
      },
      {
        path: Web.showSerialID,
        access: ['Administrator', 'Operator'],
        component: ShowSerialView,
      },
      {
        path: Web.frequence,
        access: ['Administrator', 'Operator'],
        component: FrequenceView,
      },
      {
        path: Web.editSerialID,
        access: ['Administrator', 'Operator'],
        component: EditSerialView,
      },
      {
        path: Web.listMembers,
        access: ['Administrator', 'Operator'],
        component: ListMemberView,
      },
      {
        path: Web.addMember,
        access: ['Administrator', 'Operator'],
        component: CreateMemberView,
      },
      {
        path: Web.showMemberID,
        access: ['Administrator', 'Operator'],
        component: ShowMemberView,
      },
      {
        path: Web.editMemberID,
        access: ['Administrator', 'Operator'],
        component: EditMemberView,
      },
      {
        path: Web.listStaff,
        access: ['Administrator'],
        component: ListStaffView,
      },
      {
        path: Web.addStaff,
        access: ['Administrator'],
        component: AddStaffView,
      },
      {
        path: Web.showStaffID,
        access: ['Administrator'],
        component: ShowStaffView,
      },
      {
        path: Web.editStaffID,
        access: ['Administrator'],
        component: EditStaffView,
      },
      {
        path: Web.editUserID,
        access: ['Administrator'],
        component: EditUserView,
      },
      {
        path: Web.listAcquisition,
        access: ['Administrator', 'Operator'],
        component: AcquisitionListView,
      },
      {
        path: Web.createAcquisition,
        access: ['Administrator', 'Operator'],
        component: AddAcquisitionView,
      },
      {
        path: Web.showAcquisitionId,
        access: ['Administrator', 'Operator'],
        component: ShowAcquisitionView,
      },
      {
        path: Web.editAcquisitionId,
        access: ['Administrator', 'Operator'],
        component: EditAcquisitionView,
      },
      {
        path: Web.reportStats,
        access: ['Administrator', 'Bibliotekar'],
        component: ReportStatsView,
      },
      {
        path: Web.reportCatalog,
        access: ['Administrator', 'Bibliotekar'],
        component: ReportCatalogView,
      },
      {
        path: Web.reportBorrowing,
        access: ['Administrator', 'Bibliotekar'],
        component: ReportBorrowingView,
      },
      {
        path: Web.listBackup,
        access: ['Administrator', 'It'],
        component: IndexBackupView,
      },
      {
        path: Web.logs,
        access: ['Administrator', 'It'],
        component: IndexLogsView,
      },
      {
        path: Web.listBlogPosts,
        access: ['Administrator', 'It', 'Operator'],
        component: ListPostsView,
      },
      {
        path: Web.editBlogPostId,
        access: ['Administrator', 'It', 'Operator'],
        component: EditPostView,
      },
      {
        path: Web.createBlogPost,
        access: ['Administrator', 'It', 'Operator'],
        component: CreatePostView,
      },
      {
        path: Web.changePassword,
        access: ['Administrator', 'It', 'Operator'],
        component: ChangePasswordView,
      },
    ];
  };

  static PublicRoutes = () => {
    var privateRoutes = [];

    if (!localStorage.getItem('access_token')) {
      privateRoutes = this.PrivateRoutes().map((item) => ({
        path: item.path,
        component: () => <Redirect to={Web.login} />,
      }));
    }

    privateRoutes.splice(0, 5);

    let publicArr = [
      {
        path: Web.homepage,
        component: HomepageView,
      },
      {
        path: Web.university,
        component: UniversityView,
      },
      {
        path: Web.about,
        component: AboutView,
      },
      {
        path: Web.privacyPolicies,
        component: PrivacyPoliciesView,
      },
      {
        path: Web.news,
        component: NewsView,
      },
      {
        path: Web.newsPostTitle,
        component: NewsPostView,
      },
      {
        path: Web.help,
        component: HelpView,
      },
      {
        path: Web.contact,
        component: ContactView,
      },
      {
        path: Web.login,
        component: LoginView,
      },
      {
        path: Web.forgotPassword,
        component: ForgotPasswordView,
      },
      {
        path: Web.resetPasswordParam,
        component: ResetPasswordView,
      },
      {
        path: Web.ativateAccountParam,
        component: ActivateAccountView,
      },
      {
        path: Web.search,
        component: SearchResultsView,
      },
      {
        path: Web.advanced_search,
        component: AdvSearchResultsView,
      },
      {
        path: Web.detailedID,
        component: DetailedView,
      },
      {
        path: Web.most_read_books,
        component: MostReadBooksView,
      },
      {
        path: Web.latest_books,
        component: LatestBooksView,
      },
      {
        path: Web.digitized_books,
        component: DigitizedBooksView,
      },
    ];

    var publicRoutes = publicArr.map((item) => ({
      path: item.path,
      component: item.component,
    }));

    let allRoutesArr = [...privateRoutes, ...publicRoutes];

    let pathname = location.pathname;
    if (
      this.PrivateRoutes().find((e) =>
        e.path.includes(pathname.split('/')[1])
      ) &&
      !publicArr.find((e) => e.path.includes(pathname))
    ) {
      sessionStorage.setItem('path', pathname);
    }

    if (
      localStorage.getItem('access_token') &&
      sessionStorage.getItem('path')
    ) {
      sessionStorage.removeItem('path');
    }
    return allRoutesArr;
  };
}

export default withRouter(Routes);
