import React, { Component, Fragment } from 'react';
import CreatePublisher from '../../../components/administrator/catalog/publisher/CreatePublisher';
import { Helmet } from 'react-helmet';
import Header from '../../../components/layouts/Header';
import Sidebar from '../../../components/layouts/Sidebar';

class CreatePublisherView extends Component {
  render() {
    return (
      <Fragment>
        <Sidebar
          activeLink={'catalog'}
          roleUser={this.props.role}
          firstName={this.props.firstName}
          lastName={this.props.lastName}
        />
        <div className="w-100" id="content1">
          <Helmet>
            <title>Botues</title>
          </Helmet>
          <Header
            roleUser={this.props.role}
            firstName={this.props.firstName}
            lastName={this.props.lastName}
          />
          <CreatePublisher />
        </div>
      </Fragment>
    );
  }
}

export default CreatePublisherView;
