import React, { Component, Fragment } from 'react';
import Url from '../../../api/Url';
import Web from '../../../routes/Web';
import { withRouter } from 'react-router-dom';
import Request from '../../../api/Request';
import Messages from '../../../helpers/Messages';
import AppConfig from '../../../services/AppConfig';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import sq from 'date-fns/locale/sq';
import moment from 'moment';
import LoadingGif from '../../../assets/images/icons/loading.gif';
class EditAcquisition extends Component {
  constructor() {
    super();
    this.state = {
      editAcquisition: {
        title: '',
        author: '',
        copies: '',
        seller_donor: '',
        expected_on: '',
        comments: '',
        status: '',
      },
      form_errors: {
        error_list: [],
      },
      btnLoading: false,
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    Request.getRequest(Url.editAcquisition + id).then((response) => {
      if (response.status === 200) {
        this.setState({
          editAcquisition: {
            title: response.data.acquisition.title,
            author: response.data.acquisition.author,
            copies: response.data.acquisition.copies,
            seller_donor: response.data.acquisition.seller_donor,
            expected_on: moment(response.data.acquisition.expected_on).format(
              'DD-MM-yyyy'
            ),
            comments: response.data.acquisition.comments,
            status: response.data.acquisition.status,
          },
        });
      } else if (response.status === 404) {
        this.props.history.push(Web.listAcquisition);
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  }

  handleChangeInput = (key) => (e) => {
    let newForm = {
      ...this.state.editAcquisition,
      [key]: e.target.value,
    };
    this.setState({
      editAcquisition: newForm,
    });
  };
  handleChangeInputD = (key) => (e) => {
    let newForm = {
      ...this.state.editAcquisition,
      [key]: moment(new Date(e)).format('DD-MM-yyyy'),
    };
    this.setState({
      editAcquisition: newForm,
    });
  };

  handleChangeSelect = (key) => (e) => {
    var selectValue;
    if (e !== null) {
      selectValue = e.value;
    } else {
      selectValue = '';
    }
    let newForm = {
      ...this.state.editAcquisition,
      [key]: selectValue,
    };
    this.setState({
      editAcquisition: newForm,
    });
  };

  updateAcquisition = (e) => {
    e.preventDefault();
    this.setState({ btnLoading: true });
    const id = this.props.match.params.id;
    Request.patchRequest(
      Url.updateAcquisition + id,
      this.state.editAcquisition
    ).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.props.history.push(Web.listAcquisition);
        Messages.swalSuccess(response.data.success);
        this.setState({
          editAcquisition: {
            title: '',
            author: '',
            copies: '',
            seller_donor: '',
            expected_on: '',
            comments: '',
            status: '',
          },
          form_errors: {
            error_list: [],
          },
        });
      } else if (response.status === 404) {
        this.props.history.push(Web.listAcquisition);
        Messages.swalError(response.data.error);
      } else {
        this.setState({
          form_errors: {
            error_list: response.data,
          },
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div className="create-acquisition">
            <p className="partial-title big-divide-bottom">Ndrysho Pasurimin</p>
            <form onSubmit={this.updateAcquisition}>
              <input name="_method" type="hidden" value="PATCH" />
              <div className="row">
                <div className="col-12 col-sm-6 flex-column col-form">
                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="title">Titull</label>
                    <input
                      placeholder="Shto titull"
                      className={
                        'input-form-input ' +
                        (this.state.form_errors.error_list.title
                          ? ' form-error'
                          : '')
                      }
                      name="title"
                      type="text"
                      value={
                        this.state.editAcquisition.title !== null
                          ? this.state.editAcquisition.title
                          : ''
                      }
                      id="title"
                      onChange={this.handleChangeInput('title')}
                    />
                    {this.state.form_errors.error_list.title && (
                      <span
                        className={
                          'error-message ' +
                          (this.state.form_errors.error_list.title
                            ? ' form-error'
                            : '')
                        }
                      >
                        {this.state.form_errors.error_list.title[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="author">Autor</label>
                    <input
                      placeholder="Shto Autor"
                      className={
                        'input-form-input ' +
                        (this.state.form_errors.error_list.author
                          ? ' form-error'
                          : '')
                      }
                      name="author"
                      type="text"
                      value={
                        this.state.editAcquisition.author !== null
                          ? this.state.editAcquisition.author
                          : ''
                      }
                      id="author"
                      onChange={this.handleChangeInput('author')}
                    />
                    {this.state.form_errors.error_list.author && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.author[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="copies">Numër kopjesh</label>
                    <input
                      id="copies"
                      placeholder="Shto numrin e kopjeve"
                      className={
                        'input-form-input ' +
                        (this.state.form_errors.error_list.copies
                          ? ' form-error'
                          : '')
                      }
                      name="copies"
                      type="number"
                      value={
                        this.state.editAcquisition.copies !== null
                          ? this.state.editAcquisition.copies
                          : ''
                      }
                      onChange={this.handleChangeInput('copies')}
                    />
                    {this.state.form_errors.error_list.copies && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.copies[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="seller_donor">Dhuruesi / Shitësi</label>
                    <input
                      placeholder="Shto Dhuruesin / Shitësin"
                      className={
                        'input-form-input ' +
                        (this.state.form_errors.error_list.seller_donor
                          ? ' form-error'
                          : '')
                      }
                      id=""
                      name="seller_donor"
                      type="text"
                      value={
                        this.state.editAcquisition.seller_donor !== null
                          ? this.state.editAcquisition.seller_donor
                          : ''
                      }
                      onChange={this.handleChangeInput('seller_donor')}
                    />
                    {this.state.form_errors.error_list.seller_donor && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.seller_donor[0]}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-sm-6 flex-column col-form ">
                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="expected_on">Data e ardhjes</label>
                    <DatePicker
                      placeholderText="Zgjidh datën"
                      name="from"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      locale={sq}
                      dateFormat="DD-MM-yyyy"
                      value={
                        this.state.editAcquisition.expected_on !== null
                          ? this.state.editAcquisition.expected_on
                          : ''
                      }
                      onChange={this.handleChangeInputD('expected_on')}
                      autoComplete="off"
                      className={
                        'date_input' +
                        (this.state.form_errors.error_list.expected_on
                          ? ' form-error'
                          : '')
                      }
                    />
                    {this.state.form_errors.error_list.expected_on && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.expected_on[0]}
                      </span>
                    )}
                  </div>
                  <div className="pb-4 mb-3 position-relative">
                    <label htmlFor="status-button">Statusi</label>
                    <Select
                      className="basic-select"
                      classNamePrefix="select_input"
                      name="status"
                      isSearchable={false}
                      placeholder="Përzgjidh"
                      onChange={this.handleChangeSelect('status')}
                      isClearable={true}
                      value={[
                        {
                          value: this.state.editAcquisition.status,
                          label: this.state.editAcquisition.status,
                        },
                      ]}
                      options={[
                        { value: 'Regjistruar', label: 'Regjistruar' },
                        { value: 'Aprovuar', label: 'Aprovuar' },
                        { value: 'Anulluar', label: 'Anulluar' },
                      ]}
                    />
                    {this.state.form_errors.error_list.status && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.status[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 position-relative">
                    <label htmlFor="comments">Komente</label>
                    <textarea
                      placeholder="Shto Komente"
                      className={
                        'input-form-input tage' +
                        (this.state.form_errors.error_list.comments
                          ? ' form-error'
                          : '')
                      }
                      name="comments"
                      cols="50"
                      rows="10"
                      id="comments"
                      onChange={this.handleChangeInput('comments')}
                      value={
                        this.state.editAcquisition.comments !== null
                          ? this.state.editAcquisition.comments
                          : ''
                      }
                    />
                    {this.state.form_errors.error_list.comments && (
                      <span className="error-message">
                        {this.state.form_errors.error_list.comments[0]}
                      </span>
                    )}
                  </div>

                  <div className="pb-4 mb-3 mt-auto position-submit-acq-button offset-7">
                    {this.state.btnLoading === true ? (
                      <button className="btn-styled-2 w-100">
                        Duke u Ndryshuar
                        <img
                          src={LoadingGif}
                          style={{
                            width: '18px',
                            margin: '-4px 0 0 7px',
                          }}
                        />
                      </button>
                    ) : (
                      <button className="btn-styled-2 w-100">Ndrysho</button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(EditAcquisition);
