import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/layouts/Header";
import Sidebar from "../../../components/layouts/Sidebar";
import EditMember from "../../../components/administrator/members/EditMember";

class EditMemberView extends Component {
  render() {
    return (
      <Fragment>
        <Sidebar
          activeLink={"members"}
          roleUser={this.props.role}
          firstName={this.props.firstName}
          lastName={this.props.lastName}
        />
        <div className="w-100" id="content1">
          <Helmet>
            <title>Ndrysho të dhënat</title>
          </Helmet>
          <Header
            roleUser={this.props.role}
            firstName={this.props.firstName}
            lastName={this.props.lastName}
          />
          <EditMember />
        </div>
      </Fragment>
    );
  }
}

export default EditMemberView;
