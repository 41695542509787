import React, { Component, Fragment } from "react";
import Url from "../../../api/Url";
import Request from "../../../api/Request";
import Web from "../../../routes/Web";
import Messages from "../../../helpers/Messages";
import AppConfig from "../../../services/AppConfig";
import loadingGif from "../../../assets/images/icons/loading.gif";
import moment from "moment";
import Paginate from "../../../helpers/Paginate";
import SearchFilter from "../../../helpers/SearchFilter";
import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import TooltipComp from "../../../helpers/Tooltip";

class Reservations extends Component {
  constructor() {
    super();
    this.state = {
      reservationList: {
        reservations: [],
        loading: true,
      },
      pagination: Paginate.setPaginate(),
    };
  }

  componentDidMount() {
    this.reservationList();
  }

  reservationList = () => {
    let memberID = this.props.memberID;

    Request.getRequest(Url.memberReservations + memberID).then((response) => {
      if (response.status === 200) {
        this.setState({
          reservationList: {
            reservations: response.data.reservations.data,
            loading: false,
          },
          pagination: Paginate.setPaginateData(response.data.reservations),
        });
      } else if (response.status === 404) {
        this.props.history.push(Web.listMembers);
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  borrowMaterial = (e, id) => {
    e.preventDefault();
    const data = { id: id };
    Request.postRequest(Url.borrowReservedMaterial, data).then((response) => {
      if (response.status === 200) {
        this.props.history.push(Web.listBorrowings);
        Messages.swalSuccess(response.data.success);
      } else if (response.status === 404) {
        this.reservationList();
        Messages.swalError(response.data.error);
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  handlePageChange = (pageNumber) => {
    let memberID = this.props.memberID;

    let request = Url.memberReservations + memberID;
    var searchParams = {
      page: pageNumber,
    };
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        reservationList: {
          reservations: response.data.reservations.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.reservations),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    var reservationList = "";
    if (this.state.reservationList.loading) {
      reservationList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      reservationList = this.state.reservationList.reservations.map(
        (reservation, index) => {
          const Authors = reservation.item.biblioitem.biblio.authors.map(
            (author) => {
              return author.name;
            }
          );
          return (
            <tr key={index} role="row">
              <td role="cell" data-label="Titull/Autor" className="text-center">
                {TooltipComp.tooltipDisabledMobile(
                  index,
                  reservation.item.biblioitem.biblio.title
                )}
                <br />
                {Authors.join("; ")}
              </td>
              <td
                role="cell"
                data-label="Kodifikimi/Nr. i inventarit"
                className="text-center "
              >
                {reservation.item.inventory_number +
                  " / " +
                  reservation.item.id}
              </td>
              <td
                role="cell"
                data-label="Datë rezervimi"
                className="text-center "
              >
                {reservation.created_at &&
                  moment(reservation.created_at).format("DD-MM-yyyy")}
              </td>
              <td
                role="cell"
                data-label="Jep hua"
                className="text-center status"
              >
                <form>
                  <button
                    className="btn-styled-1"
                    onClick={(e) => this.borrowMaterial(e, reservation.id)}
                    type={"submit"}
                  >
                    Jep hua
                  </button>
                </form>
              </td>
            </tr>
          );
        }
      );
    }

    return (
      <Fragment>
        <div className="table-list">
          <table role="table" className="w-100">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" className="text-center" width="25%">
                  Titull / Autor
                </th>
                <th role="columnheader" className="text-center" width="25%">
                  Kodifikimi/Nr. i inventarit
                </th>
                <th role="columnheader" className="text-center" width="25%">
                  Datë rezervimi
                </th>
                <th role="columnheader" className="text-center" width="25%">
                  Jep hua
                </th>
              </tr>
            </thead>
            <tbody role="rowgroup">{reservationList}</tbody>
          </table>
          {this.state.pagination.totalItemsCount === 0 &&
            this.state.reservationList.loading === false && (
              <p className="nothing--to--show">
                Asgjë për të shfaqur reservations
              </p>
            )}
        </div>
        <div className="container-fluid pt-5 pb-4">
          <div className="d-flex justify-content-center pagination-block">
            {this.state.pagination.totalPages > 1 && (
              <div className="d-flex justify-content-center pagination-block mb-5">
                <div className="select-page">
                  <Pagination
                    activePage={this.state.pagination.activePage}
                    itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                    totalItemsCount={this.state.pagination.totalItemsCount}
                    pageRangeDisplayed={
                      this.state.pagination.pageRangeDisplayed
                    }
                    onChange={this.handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
                <div className="pagination-results">
                  Faqja: {this.state.pagination.activePage} -{" "}
                  {this.state.pagination.totalPages}
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withRouter(Reservations);
