import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Url from "../../../../../api/Url";
import Request from "../../../../../api/Request";
import Messages from "../../../../../helpers/Messages";
import AppConfig from "../../../../../services/AppConfig";
import libra from "../../../../../assets/images/covers/Libra.svg";
import Disertacione from "../../../../../assets/images/covers/Disertacione.svg";
import Periodike from "../../../../../assets/images/covers/Periodike.svg";
import Harta from "../../../../../assets/images/covers/Harta.svg";
import Doreshkrime from "../../../../../assets/images/covers/Doreshkrime.svg";
import Dokument from "../../../../../assets/images/covers/Dokument.svg";
import Artikuj from "../../../../../assets/images/covers/Artikuj.svg";
import Materiale_audio_vizuale from "../../../../../assets/images/covers/Materiale_audio-vizuale.svg";
import loadingGif from "../../../../../assets/images/icons/loading.gif";

class Description extends Component {
  constructor() {
    super();
    this.state = {
      showBiblioitem: {
        biblioitem: [],
        loading: true,
      },
      tab: {
        selected: "description",
      },
    };
  }

  componentDidMount() {
    this.biblioitemShow();
  }

  biblioitemShow = () => {
    const biblioitemID = this.props.match.params.id;
    Request.getRequest(Url.showBiblioitem + biblioitemID).then((response) => {
      if (response.status === 200) {
        this.setState({
          showBiblioitem: {
            biblioitem: response.data.biblioitem,
            loading: false,
          },
        });
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  checkCover = (cover, material_type) => {
    if (cover !== null) {
      return AppConfig.biblioCoversPath + cover;
    }
    if (cover === null) {
      switch (material_type) {
        case 2:
          return libra;
        case 6:
          return Disertacione;
        case 3:
          return Periodike;
        case 5:
          return Harta;
        case 4:
          return Doreshkrime;
        case 8:
          return Dokument;
        case 1:
          return Artikuj;
        case 7:
          return Materiale_audio_vizuale;
        default:
          return "";
      }
    }
  };

  checkContributors = (contributor) => {
    switch (contributor) {
      case "perkthyes":
        return "përkth.";
      case "redaktor":
        return "red.";
      case "recensues":
        return "rec.";
      case "pergatites":
        return "përg.";
      default:
        return "";
    }
  };

  render() {
    var description = "";
    var biblioitem = this.state.showBiblioitem.biblioitem;
    if (this.state.showBiblioitem.loading === true) {
      description = (
        <img className="m-auto d-block" src={loadingGif} alt="loading" />
      );
    } else {
      var authors = biblioitem.biblio.authors.map((author) => {
        return author.name;
      });
      var authorsReversed = biblioitem.biblio.authors.map((author) => {
        return author.name.split(",").reverse().join(" ");
      });
      var contributor = biblioitem.contributors.map((contributor) => {
        return contributor.name;
      });
      var contributorReversed = biblioitem.contributors.map((contributor) => {
        return (
          this.checkContributors(contributor.pivot.type) +
          " " +
          contributor.name.split(",").reverse().join(" ")
        );
      });
      var Keywords = biblioitem.biblio.keywords.map((keyword, index) => {
        return (
          <span key={index} className="media-tag">
            {keyword.name}
          </span>
        );
      });
      var GeographicalTerms = biblioitem.biblio.geographicalterms.map(
        (terms, index) => {
          return (
            <span key={index} className="media-tag">
              {terms.name}
            </span>
          );
        }
      );
      return (description = (
        <div
          className="tab-pane fade show active"
          id="përshkrimi"
          role="tabpanel"
          aria-labelledby="përshkrimi-tab"
        >
          <div className="row mb-5 pershkrim">
            <div className="col-12 col-sm-4 d-none d-md-block image-holder text-left">
              <img
                src={this.checkCover(
                  biblioitem.cover,
                  biblioitem.biblio.materialtype.id
                )}
                alt="cover"
              />
            </div>
            <div className="col-12 col-md-8 description-holder">
              {biblioitem.biblio.title !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Titulli
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {`${
                      biblioitem.biblio.title +
                      (biblioitem.biblio.subtitle !== null
                        ? " : " + biblioitem.biblio.subtitle
                        : "") +
                      (authorsReversed.length > 0
                        ? " / " + authorsReversed.join("; ")
                        : "") +
                      (contributorReversed.length > 0 &&
                      authorsReversed.length <= 0
                        ? " / " + contributorReversed.join("; ")
                        : contributorReversed.length > 0 &&
                          authorsReversed.length > 0
                        ? "; " + contributorReversed.join("; ")
                        : "")
                    }`}
                  </div>
                </div>
              )}
              {authors.length !== 0 && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Autorët
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {authors.join("; ")}
                  </div>
                </div>
              )}
              {biblioitem.isbn !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    ISBN
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {`${biblioitem.isbn !== null ? biblioitem.isbn : ""}`}
                  </div>
                </div>
              )}
              {biblioitem.issn !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    ISSN
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {`${biblioitem.issn !== null ? biblioitem.issn : ""}`}
                  </div>
                </div>
              )}
              {biblioitem.biblio.medium !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Medium
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.biblio.medium}
                  </div>
                </div>
              )}
              {biblioitem.publisher !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Botuesi
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.publisher.name}
                  </div>
                </div>
              )}
              {biblioitem.publication_year !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Viti i botimit
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.publication_year}
                  </div>
                </div>
              )}
              {biblioitem.place !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Vendi i botimit
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.place}
                  </div>
                </div>
              )}
              {contributor.length !== 0 && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Personat përgjegjës
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {contributor.join("; ")}
                  </div>
                </div>
              )}

              {biblioitem.pages !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Faqet
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.pages}
                  </div>
                </div>
              )}
              {biblioitem.physical_details !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Detajet fizike
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.physical_details}
                  </div>
                </div>
              )}
              {biblioitem.dimensions !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Përmasat
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.dimensions}
                  </div>
                </div>
              )}
              {biblioitem.biblio.language !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Gjuha origjinale e botimit
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.biblio.language.name}
                  </div>
                </div>
              )}

              {biblioitem.language !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Gjuha
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.language.name}
                  </div>
                </div>
              )}

              {Keywords.length !== 0 && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Fjalët kyçe
                  </div>
                  <div className="col-12 col-md-8 name-item">{Keywords}</div>
                </div>
              )}

              {GeographicalTerms.length !== 0 && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Terma gjeografik
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {GeographicalTerms}
                  </div>
                </div>
              )}

              {biblioitem.biblio.part_name !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Emri i pjesës
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.biblio.part_name}
                  </div>
                </div>
              )}

              {biblioitem.biblio.part_number !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Numri i pjesës
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.biblio.part_number}
                  </div>
                </div>
              )}

              {biblioitem.publication_number !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Vëllimi
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.publication_number}
                  </div>
                </div>
              )}

              {biblioitem.lccn !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Lccn
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.lccn}
                  </div>
                </div>
              )}

              {biblioitem.ean !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Ean
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.ean}
                  </div>
                </div>
              )}

              {biblioitem.biblio.agerestriction !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Agerestriction
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.biblio.agerestriction}
                  </div>
                </div>
              )}
              {biblioitem.biblio.year_original_publication !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Viti origjinal i botimit
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.biblio.year_original_publication}
                  </div>
                </div>
              )}
              {biblioitem.national_control_number !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Numri i kontrollit kombëtar
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.national_control_number}
                  </div>
                </div>
              )}
              {biblioitem.biblio.notes !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Shënime
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.biblio.notes}
                  </div>
                </div>
              )}
              {biblioitem.biblio.abstract !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Abstract
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.biblio.abstract}
                  </div>
                </div>
              )}
              {biblioitem.kdu !== null && (
                <div className="row">
                  <div className="col-12 col-md-4 text-left text-md-right name-desc">
                    Klasifikimi dhjetor universal
                  </div>
                  <div className="col-12 col-md-8 name-item">
                    {biblioitem.kdu}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ));
    }
    return <Fragment>{description}</Fragment>;
  }
}

export default withRouter(Description);
