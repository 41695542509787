import React, { Component, Fragment } from "react";
import Paginate from "../../../../helpers/Paginate";
import Url from "../../../../api/Url";
import Request from "../../../../api/Request";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import SearchFilter from "../../../../helpers/SearchFilter";
import Pagination from "react-js-pagination";
// import dateFormat from "dateformat";
import TooltipComp from "../../../../helpers/Tooltip";
import moment from "moment";
import LoadingGif from "../../../../assets/images/icons/loading.gif";

class Borrowing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listBiblioitem: {
        biblioitems: [{}],
        loading: true,
      },
      btnAddLoading: false,
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.listBiblioitems();
  }

  listBiblioitems = (event) => {
    let request = Url.reportBorrowingNow;

    Request.getRequest(request).then((result) => {
      if (result.status === 200) {
        this.setState({
          listBiblioitem: {
            biblioitems: result.data.items.data,
            loading: false,
          },
          pagination: Paginate.setPaginateData(result.data.items),
        });
      } else {
        this.setState({
          listBiblioitem: {
            biblioitems: [{}],
            loading: true,
          },
        });
      }
    });
  };

  handlePageChange(pageNumber) {
    let request = Url.reportBorrowingNow;
    var searchParams = {};

    searchParams = {
      page: pageNumber,
    };
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        listBiblioitem: {
          biblioitems: response.data.items.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.items),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  download(e) {
    e.preventDefault();
    this.setState({ btnAddLoading: true });
    let request = Url.reportExportBorrowingNow;
    const link = document.createElement("a");
    Request.getRequestForDownloadExcel(request).then((response) => {
      this.setState({ btnAddLoading: false });
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.setAttribute("download", "Report_Borrowings_Now.xlsx");
      link.click();
    });
  }

  render() {
    var biblioitemsList = "";
    if (this.state.listBiblioitem.loading) {
      biblioitemsList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      biblioitemsList = this.state.listBiblioitem.biblioitems.map(
        (item, index) => {
          var should_return_on = "";
          should_return_on = moment(item.borrowings[0].should_return_on).format(
            "DD-MM-yyyy"
          );
          return (
            <tr key={item.id}>
              <td role="cell" data-label="Titull" className="title-borrowing">
                {TooltipComp.tooltipDisabledMobile(
                  "-title-" + index,
                  item.biblioitem.biblio.title
                )}
              </td>
              <td role="cell" data-label="Autor" className="text-center">
                {item.biblioitem.biblio["authors"].map((item, index, arr) => {
                  if (index === arr.length - 1) {
                    return item["name"];
                  } else {
                    return item["name"] + "; ";
                  }
                })}
              </td>
              <td role="cell" data-label="Anetar" className="text-center">
                {item.borrowings[0].user_with_trashed.first_name}{" "}
                {item.borrowings[0].user_with_trashed.last_name}
              </td>
              <td
                role="cell"
                data-label="Kodifikimi/Nr. i inventarit"
                className=" text-center"
              >
                {item.inventory_number + " / " + item.id}
              </td>
              <td role="cell" data-label="Vonese" className="text-center">
                {new Date() >= new Date(item.borrowings[0].should_return_on)
                  ? "PO"
                  : "JO"}
              </td>
              <td
                role="cell"
                data-label="Dt. kur duhet kthyer"
                className="text-center"
              >
                {should_return_on}
              </td>
            </tr>
          );
        }
      );
    }

    return (
      <Fragment>
        <div className="container-fluid small-offset-left">
          <div className="container ml-0">
            <div className="row input-items pt-4 mt-4 mb-4 pb-4">
              {this.state.btnAddLoading === true ? (
                <button
                  id="export_catalog"
                  className="btn-styled-2 ml-0 mt-0 mb-3 px-5  mr-5 export-bibliography"
                  name="export_catalog"
                  type="button"
                >
                  Duke Eksportuar
                  <img
                    src={LoadingGif}
                    style={{
                      width: "18px",
                      margin: "-4px 0 0 7px",
                    }}
                  />
                </button>
              ) : (
                <button
                  id="export_catalog"
                  className="btn-styled-2 ml-0 mt-0 mb-3 px-5  mr-5 export-bibliography"
                  name="export_catalog"
                  type="button"
                  onClick={(e) => this.download(e)}
                >
                  Eksporto
                </button>
              )}
            </div>
          </div>
          <div className="w-100 mb-5">
            <div className="table-list">
              <table role="table" className="w-100">
                <thead role="rowgroup">
                  <tr role="row">
                    <th width="30%" role="columnheader" className="text-left">
                      Titull
                    </th>
                    <th width="20%" role="columnheader" className="text-center">
                      Autor
                    </th>
                    <th width="15%" role="columnheader" className="text-center">
                      Anëtar
                    </th>
                    <th width="10%" role="columnheader" className="text-center">
                      Kodifikimi/Nr. i inventarit
                    </th>
                    <th width="10%" role="columnheader" className="text-center">
                      Vonesë
                    </th>
                    <th width="15%" role="columnheader" className="text-center">
                      Dt. kur duhet kthyer
                    </th>
                  </tr>
                </thead>
                <tbody role="rowgroup">{biblioitemsList}</tbody>
              </table>
            </div>
            {this.state.pagination.totalItemsCount === 0 &&
              this.state.listBiblioitem.loading === false && (
                <p className="nothing--to--show">Asgjë për të shfaqur</p>
              )}
            {this.state.pagination.totalPages > 1 && (
              <div className="d-flex justify-content-center flex-wrap pagination-block mt-4 mb-5">
                <div className="select-page">
                  <Pagination
                    activePage={this.state.pagination.activePage}
                    itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                    totalItemsCount={this.state.pagination.totalItemsCount}
                    pageRangeDisplayed={
                      this.state.pagination.pageRangeDisplayed
                    }
                    onChange={this.handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
                <div className="pagination-results">
                  {`Faqja: ${this.state.pagination.activePage} - ${this.state.pagination.totalPages}`}
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Borrowing;
