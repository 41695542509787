import React, { Component, Fragment } from 'react';
import '../../assets/css/frontend.css';
import Url from '../../api/Url';
import Request from '../../api/Request';
import Pagination from 'react-js-pagination';
import Paginate from '../../helpers/Paginate';
import loadingGif from '../../assets/images/icons/loading.gif';
import AppConfig from '../../services/AppConfig';
import libra from '../../assets/images/covers/Libra.svg';
import Disertacione from '../../assets/images/covers/Disertacione.svg';
import Harta from '../../assets/images/covers/Harta.svg';
import Artikuj from '../../assets/images/covers/Artikuj.svg';
import Periodike from '../../assets/images/covers/Periodike.svg';
import Dokument from '../../assets/images/covers/Dokument.svg';
import Materiale_audio_vizuale from '../../assets/images/covers/Materiale_audio-vizuale.svg';
import Doreshkrime from '../../assets/images/covers/Doreshkrime.svg';
import Web from '../../routes/Web';
import { Link, withRouter } from 'react-router-dom';
import TooltipComp from '../../helpers/Tooltip';
import Messages from '../../helpers/Messages';

class LatestBooks extends Component {
  constructor() {
    super();
    this.state = {
      dataLatestbooks: {
        latest: [],
        loading: true,
      },
      pagination: Paginate.setPaginate(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.latestBooks();
  }

  latestBooks = () => {
    let request = Url.latestBooks + 1;
    Request.getRequest(request).then((response) => {
      if (response) {
        if (response.status === 200) {
          this.setState({
            dataLatestbooks: {
              latest: response.data.latest_books.data,
              loading: false,
            },
            pagination: Paginate.setPaginateData(response.data.latest_books),
          });
        }
      } else {
        Messages.swalError(AppConfig.serverError);
      }
    });
  };

  handlePageChange(pageNumber) {
    let request = Url.latestBooks + 1 + '?page=' + pageNumber;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        this.setState({
          dataLatestbooks: {
            latest: response.data.latest_books.data,
            loading: false,
          },
          pagination: Paginate.setPaginateData(response.data.latest_books),
        });
      }
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  checkCover = (cover, material_type) => {
    if (cover !== null) {
      return AppConfig.biblioCoversPath + cover;
    }
    if (cover === null) {
      switch (material_type) {
        case 2:
          return libra;
        case 6:
          return Disertacione;
        case 3:
          return Periodike;
        case 5:
          return Harta;
        case 4:
          return Doreshkrime;
        case 8:
          return Dokument;
        case 1:
          return Artikuj;
        case 7:
          return Materiale_audio_vizuale;
        default:
          return '';
      }
    }
  };

  render() {
    var items = '';
    if (this.state.dataLatestbooks.loading) {
      items = <img className="m-auto d-block" src={loadingGif} alt="loading" />;
    } else {
      items = this.state.dataLatestbooks.latest.map((item, index) => {
        return (
          <div key={index} className="col-6 col-md-4 col-lg-3 p-0 pb-5">
            <div className="cover-most-read-books  pb-2">
              <Link
                to={{
                  pathname: Web.detailed + item.id,
                  state: { prevUrl: this.props.history.location.pathname },
                }}
              >
                <img
                  src={this.checkCover(item.cover, item.biblio.materialtype.id)}
                  alt={item.biblio.title}
                />
              </Link>
            </div>

            <div className="most-read-books-detail d-flex flex-column">
              <span className="type-m pb-2">
                {item.biblio.materialtype.name}
              </span>
              <span className="title-m pb-2">
                <Link
                  to={{
                    pathname: Web.detailed + item.id,
                    state: { prevUrl: this.props.history.location.pathname },
                  }}
                >
                  {TooltipComp.tooltipDisabledMobile(
                    item.id,
                    item.biblio.title
                  )}
                </Link>
              </span>
              <span className="author-m">
                {item.biblio.authors.length !== 0 &&
                  item.biblio['authors'][0]['name']}
              </span>
            </div>
          </div>
        );
      });
    }

    return (
      <Fragment>
        <div id="content">
          <div id="most-read-books" className="bl_page">
            <div className="b--breadcrumb mt-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb pl-0">
                  <li className="breadcrumb-item">
                    <Link to={Web.homepage}>Biblioteka</Link>
                  </li>

                  <li
                    className="breadcrumb-item active b--title"
                    aria-current="page"
                  >
                    Librat më të fundit
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex flex-wrap item-desc mt-4">{items}</div>
            {this.state.pagination.totalPages > 1 && (
              <div className="container-fluid pt-5 pb-4">
                <div className="d-flex justify-content-center pagination-block">
                  <div className="d-flex justify-content-center pagination-block mb-5">
                    <div className="select-page">
                      <Pagination
                        activePage={this.state.pagination.activePage}
                        itemsCountPerPage={
                          this.state.pagination.itemsCountPerPage
                        }
                        totalItemsCount={this.state.pagination.totalItemsCount}
                        pageRangeDisplayed={
                          this.state.pagination.pageRangeDisplayed
                        }
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                    <div className="pagination-results">
                      {`Faqja: ${this.state.pagination.activePage} - ${this.state.pagination.totalPages}`}
                      <br />
                      {`Rezultate gjithsej: ${this.state.pagination.totalItemsCount}`}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(LatestBooks);
