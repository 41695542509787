import { Component, Fragment } from "react";
import Url from "../../../../api/Url";
import Request from "../../../../api/Request";
import Select from "react-select";
import SearchFilter from "../../../../helpers/SearchFilter";
import Paginate from "../../../../helpers/Paginate";
import Pagination from "react-js-pagination";
import LoadingGif from "../../../../assets/images/icons/loading.gif";
import loadingGif from "../../../../assets/images/icons/loading.gif";
import noResult from "../../../../assets/images/icons/no_result.svg";
import Messages from "../../../../helpers/Messages";
import TooltipComp from "../../../../helpers/Tooltip";
class ExportMarc extends Component {
  constructor() {
    super();
    this.state = {
      exportMarcList: {
        marcData: [],
        error_list: [],
        hasError: false,
        loading: true,
      },
      searchMarc: {
        text: "",
        specification: "",
        error_list: [],
      },
      btnLoading: false,
      btnAddLoading: false,
      checkedFiles: {},
      searched: false,
      pagination: Paginate.setPaginate(),
    };
  }

  componentDidMount() {
    this.listMarc();
  }

  listMarc = (hasSearch) => {
    let request = Url.selectMarcRecords;
    sessionStorage.clear();
    if (hasSearch) {
      hasSearch.preventDefault();
      this.setState({
        searched: true,
      });
      const searchParams = {
        text: this.state.searchMarc.text,
        specification: this.state.searchMarc.specification,
      };
      this.setState({ btnLoading: true });
      request = SearchFilter.setFilterParams(searchParams, request);
    }

    const marc = Request.getRequest(request).then((response) => {
      this.setState({ btnLoading: false });
      if (response.status === 200) {
        this.setState({
          exportMarcList: {
            marcData: response.data.biblioitems.data,
            loading: false,
          },
          searchMarc: {
            ...this.state.searchMarc,
            error_list: [],
          },
          pagination: Paginate.setPaginateData(response.data.biblioitems),
        });

        var myStorage = window.sessionStorage;
        var checked = document.querySelectorAll(
          'input[name="biblioitems[]"]:checked'
        );
        checked.forEach((element) => {
          var el = myStorage.getItem("value" + element.value);
          if (el === null) {
            myStorage.setItem("value" + element.value, element.value);
          }
        });
      } else if (response.status === 422) {
        this.setState({
          searchMarc: {
            ...this.state.searchMarc,
            error_list: response.data,
          },
          searched: false,
        });
      } else {
        this.setState({
          exportMarcList: {
            marcData: [],
            loading: true,
          },
          searched: false,
        });
      }
    });
    return marc;
  };

  checkAll = (e) => {
    var checkboxs = document.querySelectorAll(".checkbox");

    for (var i = 0; i < checkboxs.length; i++) {
      var checkedList = this.state.checkedFiles;
      var checkedValues = {
        ...checkedValues,
        [`value_${checkboxs[i].value}`]: checkboxs[i].value,
      };

      if (e.target.checked === true) {
        checkboxs[i].checked = true;
        const checkedInputs = {
          ...checkedValues,
          ...checkedList,
        };
        Object.assign(checkedInputs, checkedValues);
        this.setState({
          checkedFiles: checkedInputs,
        });
      } else {
        checkboxs[i].checked = false;

        delete checkedList[`value_${checkboxs[i].value}`];
        this.setState({
          checkedFiles: checkedList,
        });
      }
    }
  };

  checkBox = (e, value) => {
    var checkedList = this.state.checkedFiles;
    const checkedValues = {
      [`value_${value}`]: value,
    };

    if (e.target.checked === false) {
      delete checkedList[`value_${value}`];
      this.setState({
        checkedFiles: checkedList,
      });
    } else {
      var checkedInputs = {
        ...checkedList,
      };
      Object.assign(checkedInputs, checkedValues);
      this.setState({
        checkedFiles: checkedInputs,
      });
    }
  };

  exportMarc = (e) => {
    e.preventDefault();
    this.setState({ btnAddLoading: true });
    const checkedItems = Object.values(this.state.checkedFiles);

    const formData = new FormData();
    for (let i = 0; i < checkedItems.length; i++) {
      formData.append("biblioitems[" + i + "]", checkedItems[i]);
    }
    var checkboxs = document.querySelectorAll(".checkbox");
    Request.postRequestExport(Url.saveMarcRecords, formData).then(
      (response) => {
        this.setState({ btnAddLoading: false });
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "marc.mrc");
          document.body.appendChild(link);
          link.click();

          for (var i = 0; i < checkboxs.length; i++) {
            checkboxs[i].checked = false;
          }
          this.setState({
            checkedFiles: {},
          });
          Messages.swalSuccess("Materiali u eksportua me sukses!");
        } else if (response.status === 404) {
          Messages.swalWarning(response.data.error);
        } else {
          Messages.swalError(response.data.error);
        }
      }
    );
  };

  handlePageChange = (pageNumber) => {
    var checkAll = document.getElementById("choose_all");
    checkAll.checked = false;

    let request = Url.selectMarcRecords;
    var searchParams = {};
    if (this.state.searched === false) {
      searchParams = {
        page: pageNumber,
      };
    } else {
      searchParams = {
        text: this.state.searchMarc.text,
        specification: this.state.searchMarc.specification,
        page: pageNumber,
      };
    }
    request = SearchFilter.setFilterParams(searchParams, request);

    Request.getRequest(request).then((response) => {
      this.setState({
        exportMarcList: {
          marcData: response.data.biblioitems.data,
          loading: false,
        },
        pagination: Paginate.setPaginateData(response.data.biblioitems),
      });
    });
    this.setState({ activePage: pageNumber });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  searchHandler = (key) => (e) => {
    let newForm = {
      ...this.state.searchMarc,
      [key]: e.target.value,
    };
    this.setState({
      searchMarc: newForm,
    });
  };

  searchHandlerS = (key) => (e) => {
    let newForm = {
      ...this.state.searchMarc,
      [key]: e.value,
    };
    this.setState({
      searchMarc: newForm,
    });
  };

  render() {
    var marcList = "";
    if (this.state.exportMarcList.loading) {
      marcList = (
        <tr>
          <td colSpan="7">
            <img className="m-auto d-block" src={loadingGif} alt="loading" />
          </td>
        </tr>
      );
    } else {
      marcList = this.state.exportMarcList.marcData.map((marcList) => {
        var el = this.state.checkedFiles[`value_${marcList.id}`];
        const marcListAuthors = marcList.biblio.authors.map((author) => {
          return author.name;
        });
        return (
          <tr key={marcList.id}>
            <td role="cell" data-label="Titull">
              {TooltipComp.tooltipDisabledMobile(
                "title" + marcList.id,
                marcList.biblio.title
              )}
            </td>
            <td role="cell" data-label="Isbn/Issn" className="text-center">
              {marcList.isbn} {marcList.issn !== null && "/"} {marcList.issn}
            </td>
            <td role="cell" data-label="Botuesi" className="text-center">
              {marcList.publisher !== null && marcList.publisher.name}
            </td>
            <td role="cell" data-label="Autori" className="text-center">
              {marcListAuthors.join("; ")}
            </td>
            <td
              role="cell"
              data-label="Zgjidh për eksport"
              className="text-right choose_export"
            >
              <input
                type="checkbox"
                name="biblioitems[]"
                value={marcList.id}
                className="checkbox"
                id={marcList.id}
                onChange={(e) => this.checkBox(e, marcList.id)}
                defaultChecked={el == marcList.id ? true : false}
              />
              <label htmlFor={marcList.id}>Choose</label>
            </td>
          </tr>
        );
      });
    }
    return (
      <Fragment>
        <div className="small-offset-left marc-file-upload">
          <div className="upload-marc-file">
            <p className="partial-title mb-5">Export Marc 21</p>
            <div className="input-items mb-5">
              <form onSubmit={this.listMarc}>
                <div className="row mb-5">
                  <div className="col-12 col-md-4 pb-4 mb-3">
                    <input
                      placeholder="Kërko"
                      className={
                        "input-form-input" +
                        (this.state.searchMarc.error_list.text
                          ? " form-error"
                          : "")
                      }
                      name="text"
                      type="text"
                      onChange={this.searchHandler("text")}
                    />
                    {this.state.searchMarc.error_list.text && (
                      <span className="error-message left">
                        {this.state.searchMarc.error_list.text}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-4 pb-4 mb-3">
                    <Select
                      className={
                        "basic-select" +
                        (this.state.searchMarc.error_list.specification
                          ? " form-error"
                          : "")
                      }
                      classNamePrefix="select_input"
                      name="borrowing_type"
                      placeholder="Përzgjidh"
                      onChange={this.searchHandlerS("specification")}
                      isSearchable={false}
                      options={[
                        { value: "title", label: "Titull" },
                        { value: "author", label: "Autor" },
                        { value: "isbn/issn", label: "Isbn/Issn" },
                        { value: "contributors", label: "Personat përgjegjës" },
                        { value: "publisher", label: "Botues" },
                      ]}
                    />
                    {this.state.searchMarc.error_list.specification && (
                      <span className="error-message left">
                        {this.state.searchMarc.error_list.specification}
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-4">
                    {this.state.btnLoading === true ? (
                      <button type="button" className="btn-styled-2 ml-0">
                        Duke Kërkuar
                        <img
                          src={LoadingGif}
                          style={{
                            width: "18px",
                            margin: "-4px 0 0 7px",
                          }}
                        />
                      </button>
                    ) : (
                      <button type="submit" className="btn-styled-2 ml-0">
                        Kërko
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <form onSubmit={this.exportMarc}>
              <div className="choose_export d-md-none mb-2">
                Zgjidhi të gjitha
                <input
                  type="checkbox"
                  id="choose_all_m"
                  onChange={this.checkAll}
                  className="checkbox_m"
                />
                <label className="ml-1" htmlFor="choose_all_m"></label>
              </div>
              <div className="table-list mb-4">
                <table role="table" className="w-100">
                  <thead>
                    <tr role="row">
                      <th role="columnheader" width="20%" className="text-left">
                        Titull
                      </th>
                      <th
                        role="columnheader"
                        width="20%"
                        className="text-center"
                      >
                        Isbn/Issn
                      </th>
                      <th
                        role="columnheader"
                        width="20%"
                        className="text-center"
                      >
                        Botuesi
                      </th>
                      <th
                        role="columnheader"
                        width="20%"
                        className="text-center"
                      >
                        Autori
                      </th>
                      <th
                        role="columnheader"
                        width="20%"
                        className="text-right choose_export"
                      >
                        Zgjidhi të gjitha
                        <input
                          type="checkbox"
                          id="choose_all"
                          name="allSelect"
                          onChange={this.checkAll}
                        />
                        <label
                          htmlFor="choose_all"
                          className="choose_all"
                        ></label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{marcList}</tbody>
                </table>
                {this.state.pagination.totalItemsCount === 0 &&
                  this.state.exportMarcList.loading === false &&
                  this.state.searched === true && (
                    <img
                      className="mx-auto d-block"
                      src={noResult}
                      alt="no result"
                    />
                  )}
                {this.state.pagination.totalItemsCount === 0 &&
                  this.state.exportMarcList.loading === false &&
                  this.state.searched === false && (
                    <p className="nothing--to--show">Asgjë për të shfaqur</p>
                  )}
              </div>
              <div className="col-12 d-flex justify-content-start justify-content-lg-end pr-0 mb-5">
                {this.state.btnAddLoading === true ? (
                  <button className="btn-styled-2 px-5" type="submit">
                    Duke u Eksportuar
                    <img
                      src={LoadingGif}
                      style={{
                        width: "18px",
                        margin: "-4px 0 0 7px",
                      }}
                    />
                  </button>
                ) : (
                  <button className="btn-styled-2 px-5" type="submit">
                    Eksporto
                  </button>
                )}
              </div>
            </form>
          </div>
          {this.state.pagination.totalPages > 1 && (
            <div className="d-flex justify-content-center flex-wrap pagination-block mb-5">
              <div className="select-page">
                <Pagination
                  activePage={this.state.pagination.activePage}
                  itemsCountPerPage={this.state.pagination.itemsCountPerPage}
                  totalItemsCount={this.state.pagination.totalItemsCount}
                  pageRangeDisplayed={this.state.pagination.pageRangeDisplayed}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
              <div className="pagination-results">
                {`Faqja: ${this.state.pagination.activePage} - ${this.state.pagination.totalPages}`}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default ExportMarc;
